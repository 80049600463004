import React, { useEffect, useState } from "react";
import Board, { moveCard, moveColumn } from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import "./kanben.css";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { Tooltip, Typography } from "@mui/material";
import { FontStyle, componentTitle } from "app/pages/widgets/CRMStyles";
import { toast } from "react-toastify";
import { CRMSeparateDateTime, CustomScrollbarBox, Div, formatDateTime, } from "app/pages/widgets/StylesComponents";
import { TaskCUD, useTaskAllList, useTaskMyList } from "../task-api/taskAPI";
import { BsClockHistory } from "react-icons/bs";
import { Spin } from "antd";
import { AutoHeight } from "app/redux/auth/localStorage";

const TaskStatusKanben = () => {
  const [board, setBoard] = useState({
    columns: [
      { id: 1, title: "Not Started", cards: [] },
      { id: 2, title: "In Progress", cards: [] },
      { id: 3, title: "Completed", cards: [] },
    ],
  });

  const [loading, setIsLoading] = useState(false);

  const { taskAllData, isLoadingTaskAllData, refetchTaskAllData } = useTaskAllList();
  const { taskMyData, isLoadingTaskMyData, refetchTaskMyData } = useTaskMyList();


  const handleColumnMove = (_card, source, destination) => {
    const updatedBoard = moveColumn(board, source, destination);
    setBoard(updatedBoard);
    console.log("Card moved : ", updatedBoard);
  };

  const handleCardMove = (_card, source, destination) => {
    setIsLoading(destination?.toColumnId);
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    updateCard({ _card, source, destination });
    console.log("Card moved2 : ", _card, source, destination);
  };

  const getGradient = (card) => {
    // const column = getColumn(card);
    // const title = column?.title;
    return {
      background: "white",
      boxShadow: "1px 1px 8px gray",
    };
  };

  const updateCard = async (update) => {
    let status = update?.destination?.toColumnId;
    if (update?.source?.fromColumnId !== status) {
      let payload = JSON.parse(JSON.stringify(update?._card));

      payload.Updated_By = parseInt(localStorage.getItem("UserId"));
      payload.Created_By = update?._card?.Created_By?.id;
      payload.Organization_Id = parseInt(localStorage.getItem("OrganizationId"));
      payload.Project = update?._card?.Project?.id;
      payload.Assigned_By = update?._card?.Assigned_By?.id;
      payload.Completion = status == 1 ? "Not Started" : status == 2 ? "50%" : "100%";

      let res = await TaskCUD("put", payload);

      if (!res?.error) {
        toast.success(`Task update status successfully`);
      } else {
        toast.error(`${res?.error} Task update status Unsuccessfully`);
        setBoard(board);
      }
      await refetchTaskAllData();
      await refetchTaskMyData();
      setIsLoading(false);
    }
  };

  const FetchDatas = async () => {
    let list = {
      columns: [
        { id: 1, title: "Not Started", cards: [] },
        { id: 2, title: "In Progress", cards: [] },
        { id: 3, title: "Completed", cards: [] },
      ],
    };

    taskAllData?.results?.map((data) => {
      if (data?.Completion === "100%") {
        list?.columns?.[2]?.cards?.push(data);
      } else if (data?.Completion === "Not Started") {
        list?.columns?.[0]?.cards?.push(data);
      } else {
        list?.columns?.[1]?.cards?.push(data);
      }
    });

    console.log("asdsadasdhsahdna", list);

    setBoard(list);
  };

  useEffect(() => {
    if (taskAllData?.results?.length > 0) {
      FetchDatas();
    }
  }, [taskAllData]);

  return (
    <JumboContentLayoutMain>
      <Typography sx={[componentTitle]} className="mb-2">
        Task Status
      </Typography>
      <CustomScrollbarBox scrollHeight={AutoHeight(1.4)}>
        <div className="w-full">
          <Board
            allowAddColumn
            allowRenameColumn
            allowRemoveCard
            onCardDragEnd={!loading && handleCardMove}
            onColumnDragEnd={!loading && handleColumnMove}
            renderCard={(props) => {
              return (
                <div
                  className="kanban-card min-w-[350px] w-[40vh]"
                  style={getGradient(props)}
                >
                  <div className="min-w-[350px] w-[40vh] items-center">
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 800 }}
                      className="text-gray-950"
                    >
                      {props?.TaskName}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "14px", fontWeight: 500 }}
                      className="text-gray-950"
                    >
                      <Tooltip title={props?.Task_Desc}>
                        {props?.Task_Desc?.length > 20
                          ? props?.Task_Desc?.substring(
                            0, 20
                          ) + "..."
                          : props?.Task_Desc}
                      </Tooltip>
                    </Typography>
                  </div>
                  <Div className="flex items-center justify-between my-3">
                    <div className="flex items-center gap-1">
                      <BsClockHistory
                        className="text-cyan-600"
                        style={{ fontSize: 22 }}
                      />
                      <Typography
                        sx={[FontStyle]}
                        className="text-gray-700 dark:text-gray-700"
                      >
                        {/* {defaultFormatDate(props?.Start_Date, defaultDate)}{" "} */}
                        {/* {formatDateTime(props?.Start_Date, "datetime")} */}
                        {CRMSeparateDateTime(props?.Start_Date)}
                      </Typography>
                    </div>
                    <Typography
                      sx={[FontStyle]}
                      className="text-gray-700 dark:text-gray-700"
                    >
                      -
                    </Typography>
                    <Typography
                      sx={[FontStyle]}
                      className="text-gray-700 dark:text-gray-700"
                    >
                      {/* {defaultFormatDate(props?.Due_Date, defaultDate)}{" "} */}
                      {/* {formatDateTime(props?.Due_Date, "datetime")} */}
                      {CRMSeparateDateTime(props?.Due_Date)}
                    </Typography>
                  </Div>
                  <Typography
                    className="text-right text-gray-700"
                    sx={{ fontSize: "14px" }}
                  >
                    Priority :{" "}
                    <span className="text-red-600 font-bold">
                      {" "}
                      {props?.Priority}
                    </span>
                  </Typography>
                </div>
              );
            }}
            renderColumnHeader={(props) => (
              <div
                className={`column-header min-w-[350px] w-[30vh] ${props?.title === "Not Started"
                  ? "bg-slate-600"
                  : props?.title === "In Progress"
                    ? "bg-blue-600"
                    : "bg-orange-600"
                  }  flex items-center justify-center`}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600 }}
                  className="text-white"
                >
                  {props?.title}{" "}
                  {loading == props?.id && (
                    <Spin style={{ color: "white" }} className="text-white " />
                  )}
                </Typography>
                {/* <i class="bi bi-clipboard2-check" style={{ fontSize: '20px', color: '#fff' }}></i> */}
              </div>
            )}
          >
            {board}
          </Board>
        </div>
      </CustomScrollbarBox>
      <br />
    </JumboContentLayoutMain>
  );
};

export default TaskStatusKanben;
