import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useStateContext } from "app/contexts/ContextProvider";
import { Colors, FontStyle } from "app/pages/widgets/CRMStyles";
import {
  AvatarCircle,
  Customgrid,
  Div,
  formatDate,
} from "app/pages/widgets/StylesComponents";
import { BASE_URL, socket } from "app/services/auth-services";
import React, { memo, useEffect, useRef, useState } from "react";
import { HiMiniUserGroup, HiOutlineUserGroup } from "react-icons/hi2";
import { MdGroups } from "react-icons/md";
import { PiPhoneCall } from "react-icons/pi";
import ChatElement from "../ChatElement";
import axios from "axios";
import { toast } from "react-toastify";
import { useChatContext } from "../../context/Chatcontext";

const ProfileInfo = ({ open, setOpen }) => {
  const { selectUser, setUsersList } = useChatContext();
  const [tab, setTab] = useState(0);
  let Username = localStorage.getItem("Username");
  let currentUser = JSON.parse(localStorage.getItem("UserDetails"));
  currentUser.username = "You";
  console.log("Profile : ", currentUser, selectUser);

  const [base64, setbase64] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");

  // image upload function
  const handleChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = handleReaderLoaded;
      setImage(reader.readAsBinaryString(file));
      setImageName(
        (selectUser?.Group_Name || selectUser?.username) + file?.name
      );
      console.log("file uploaded: ", e.target.files[0]);
    }
  };

  const handleReaderLoaded = (e) => {
    // console.log("file uploaded 2: ", e);
    let binaryString = e.target.result;
    setbase64(btoa(binaryString));
  };

  const token = localStorage.getItem("accesstoken");
  const UserId = localStorage.getItem("UserId");
  const Organization_Id = localStorage.getItem("OrganizationId");
  const item = "crmuseradmin1";

  const handleSubmit = async () => {
    const payload = {
      id: selectUser?.id,
      Organization_Id: Organization_Id,
      Group_Name: selectUser?.Group_Name,
      Assign_User_Name: selectUser?.Assign_User_Name,
      User_Photo: base64,
      File_Name: imageName,
      Description: selectUser?.Description,
      Assign_User: selectUser?.Assign_User?.map((assign) => assign.id),
      Created_By: UserId,
      Created_Date: new Date().toISOString(),
      Updated_By: UserId,
      Updated_Date: new Date().toISOString(),
    };

    try {
      const response = await axios.put(
        `${BASE_URL}/user/UserChatGroupCRUD/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response) {
        socket.emit("ChatUserGroupList", {
          db: BASE_URL,
          id: UserId,
        });

        socket.on("ChatUserGroupList", (data) => {
          console.log("ChatUserGroupList all list: ", data);
          setUsersList(data);
        });

        setOpen(false);
        setbase64("");
        setImageName("");
        setImage("");
      }
    } catch (error) {
      console.error("Error Update group:", error);
    }
  };

  useEffect(() => {
    console.log("base64 base64 encoded", base64, image, imageName);
    if (Boolean(base64) && Boolean(imageName)) {
      handleSubmit();
    }
  }, [base64, image, imageName]);

  const profile = () => {
    return (
      <>
        <Box className=" p-2 ">
          <Div className="flex items-center justify-center mt-6">
            <Div>
              {/* <label title="Click upload Image" htmlFor={"profile_pics"}>
                <AvatarCircle
                  fontSize="20px"
                  size="100px"
                  className={"border-2 border-cyan-400"}
                  title={
                    (!Boolean(selectUser?.User_Photo) &&
                      selectUser?.username) ||
                    selectUser?.Group_Name
                  }
                  image={
                    Boolean(selectUser?.User_Photo) &&
                    BASE_URL + selectUser?.User_Photo
                  }
                />
              </label> */}

              <label title="Click upload Image" htmlFor={"profile_pics"}>
                <Avatar
                  className={"border-2 border-cyan-400"}
                  alt={
                    selectUser?.username?.substring(0, 1) ||
                    selectUser?.Group_Name?.substring(0, 1)
                  }
                  src={
                    Boolean(base64)
                      ? `data:image/png;base64,${base64}`
                      : Boolean(selectUser?.User_Photo) &&
                        BASE_URL + selectUser?.User_Photo
                  }
                  title={
                    (!Boolean(selectUser?.User_Photo) &&
                      selectUser?.username) ||
                    selectUser?.Group_Name
                  }
                  sx={{
                    width: 100,
                    height: 100,
                    verticalAlign: "middle",
                    boxShadow: (theme) => theme.shadows[3],
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "grey",
                    },
                  }}
                />
              </label>

              <input
                type="file"
                onChange={handleChangeImage}
                style={{ display: "none" }}
                id="profile_pics"
              />

              <Typography
                sx={{ fontSize: "16px" }}
                className="text-center mt-1 font-bold"
              >
                {selectUser?.username || selectUser?.Group_Name}
              </Typography>
            </Div>
          </Div>
          <Div className="mt-2">
            <Grid container spacing={1}>
              {Boolean(selectUser?.email) && (
                <Grid item xs={12}>
                  <Div className="bg-[#f9f9f9]  p-1 my-1 ">
                    <Typography sx={{ fontSize: "14px" }}>E-mail</Typography>
                    <Typography sx={{ fontSize: "14px " }} className=" mt-1 ">
                      {selectUser?.email}
                    </Typography>
                  </Div>
                </Grid>
              )}
              {Boolean(selectUser?.PhoneNo) && (
                <Grid item xs={12}>
                  <Div className="bg-[#f9f9f9]  p-1 my-1 ">
                    <Typography sx={{ fontSize: "14px" }}>
                      Phone Number
                    </Typography>
                    <Typography sx={{ fontSize: "14px " }} className=" mt-1 ">
                      {selectUser?.PhoneNo}
                    </Typography>
                  </Div>
                </Grid>
              )}
              <Grid item xs={12}>
                <Div className="bg-[#f9f9f9]  p-1 my-1">
                  <Typography sx={{ fontSize: "14px" }}>Designation</Typography>
                  <Typography sx={{ fontSize: "14px " }} className=" mt-1 ">
                    {selectUser?.Designation ||
                      (selectUser?.Description
                        ? "No Description"
                        : "No Designation")}
                  </Typography>
                </Div>
              </Grid>
              <Grid item xs={12}>
                <Div className="bg-[#f9f9f9]  p-1 my-1 ">
                  <Typography sx={{ fontSize: "14px" }}>
                    Created Date
                  </Typography>
                  <Typography sx={{ fontSize: "14px " }} className=" mt-1 ">
                    {formatDate(selectUser?.date_joined) ||
                      formatDate(
                        selectUser?.Created_Date?.toISOString()?.split("T")
                      ) ||
                      formatDate(selectUser?.Created_Date) ||
                      formatDate(new Date()) ||
                      " - "}
                  </Typography>
                </Div>
              </Grid>
            </Grid>
          </Div>
        </Box>
      </>
    );
  };

  const groupMembers = () => {
    return (
      <>
        {" "}
        <Box className="w-full p-2 h-[449px] overflow-y-auto">
          <Typography sx={{ fontSize: "14px" }}>
            Total Members ({selectUser?.Assign_User?.length || 1})
          </Typography>
          <Div className="mt-2">
            <ChatElement
              avatarSize={"35px"}
              user={currentUser}
              person={"person"}
            />
            {selectUser?.Assign_User?.length > 0 &&
              selectUser?.Assign_User?.map((use) => {
                if (use?.username !== Username) {
                  return (
                    <ChatElement
                      avatarSize={"35px"}
                      user={use}
                      person={"person"}
                      key={use?.id}
                    />
                  );
                }
              })}
          </Div>
        </Box>
      </>
    );
  };

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={() => setOpen(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ borderRadius: "5px !important" }}
    >
      <Box className="flex w-[400px] h-[450px] ">
        <Div className="w-12 p-2 h-full flex justify-center bg-gray-100">
          <Div>
            <Div className={` mb-[5px] `}>
              <IconButton onClick={() => setTab(0)}>
                <AvatarCircle
                  size="25px"
                  bgColor={tab === 0 && Colors?.Mblue}
                />
              </IconButton>
            </Div>
            {Boolean(selectUser?.Group_Name) && (
              <Div className={` mt-[5px] `}>
                <IconButton onClick={() => setTab(1)}>
                  <HiOutlineUserGroup
                    style={{
                      fontSize: "25px",
                      backgroundColor:
                        tab === 1 ? Colors?.lightSkyBlueV2 : "lightgray",
                      color: tab === 1 && "white",
                      padding: "4px",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
              </Div>
            )}
          </Div>
        </Div>

        <Div className="w-full h-full">
          {tab === 0 && profile()}
          {tab === 1 && groupMembers()}
        </Div>
      </Box>
    </Popover>
  );
};

export default memo(ProfileInfo);
