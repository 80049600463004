// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    width: 109.3%;
    min-height: 114%;
    padding: 40px;
    border-radius: 1px;
    border: none;
    margin-top: -35px;
    margin-bottom: -55px;
    margin-left: -47.5px;
    z-index: 0 !important;
}

.card-content {
    width: 100%;
    min-height: 350px;
    /* padding-right: 50px !important; */
    padding-bottom: 30px;
}

option {
    border: none;
    border-color: transparent;
}


.table {
    font-size: 14px !important;
}

.form-control {
    padding: 20px;
}

.transaction-approval-checkbox {
    margin-left: -60px !important;
}

.modify-transaction-table {
    margin-left: -170px;
}

.dx-datagrid .dx-row>td {
    padding: 12px !important;
}

.dx-row.dx-header-row {
    background-color: #eeeded !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/settings/Setting.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,oCAAoC;IACpC,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;;AAGA;IACI,0BAA0B;AAC9B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".box {\n    width: 109.3%;\n    min-height: 114%;\n    padding: 40px;\n    border-radius: 1px;\n    border: none;\n    margin-top: -35px;\n    margin-bottom: -55px;\n    margin-left: -47.5px;\n    z-index: 0 !important;\n}\n\n.card-content {\n    width: 100%;\n    min-height: 350px;\n    /* padding-right: 50px !important; */\n    padding-bottom: 30px;\n}\n\noption {\n    border: none;\n    border-color: transparent;\n}\n\n\n.table {\n    font-size: 14px !important;\n}\n\n.form-control {\n    padding: 20px;\n}\n\n.transaction-approval-checkbox {\n    margin-left: -60px !important;\n}\n\n.modify-transaction-table {\n    margin-left: -170px;\n}\n\n.dx-datagrid .dx-row>td {\n    padding: 12px !important;\n}\n\n.dx-row.dx-header-row {\n    background-color: #eeeded !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
