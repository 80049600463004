import { memo } from "react";

const {
  useTerritoryList,
  useTotalSalesTarget,
  useUserList,
  useGetSalesPersonTarget,
  useSalesInvoiceAllAdditional,
} = require("../TerritoryAPI");

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SalesTargetViewList = () => {
  const arraysEqual = (arr1, arr2) =>
    JSON.stringify(arr1) === JSON.stringify(arr2);

  const { salesInvoiceAllList } = useSalesInvoiceAllAdditional();
  const { territoryList, isLoading } = useTerritoryList();
  const { SalesTargetList } = useTotalSalesTarget();
  const { salesPersonTarget } = useGetSalesPersonTarget();

  const territoryTree = (territoryData) => {
    const territoryMap = new Map();
    territoryData?.data?.forEach((territory) => {
      territory.children = [];
      territory.Acheivement = {};
      let person = arraysEqual(
        territory?.AssignSalesPersons,
        territory?.User_Id
      );
      if (!person) {
        territory?.AssignSalesPersons?.map((item, ind) => {
          let don = {
            id: item,
            salesperson: territory?.Assign_Users[ind],
            Target: {},
            Acheivement: {},
            Variance: {},
          };
          Months?.map((month, i) => {
            don.Target[month] = 0;
            don.Acheivement[month] = 0;
            don.Variance[month] = 0;
          });
          territory?.children.push(don);
        });
      }
      Months?.map((month, i) => {
        territory.Acheivement[month] = 0;
      });

      territoryMap.set(territory.id, territory);
    });

    // Assign targets to children (salespeople) from salesPersonTarget
    salesPersonTarget?.forEach((SalesPerson) => {
      const parentTerritory = territoryMap.get(SalesPerson?.Territery_Id);

      console.log("parentTerritory", parentTerritory);
      if (parentTerritory) {
        parentTerritory.children = SalesPerson?.Target;
        parentTerritory?.children?.map((person) => {
          let target = salesInvoiceAllList?.find(
            (invoice) => invoice?.SalesUser_Id === person?.id
          );
          if (target) {
            let mont = Months[new Date(target?.Created_Date)?.getMonth()];
            console.log(
              "target---------------------------------------------------",
              mont,
              target
            );
            if (!person.Acheivement) {
              person.Acheivement = {};
              Months.map((mo) => {
                parentTerritory.Acheivement[mo] = 0;
                person.Acheivement[mo] = 0;
              });
            }
            if (mont) {
              person.Acheivement[mont] =
                person.Acheivement[mont] + Number(target?.Net_Amount || 0);
              parentTerritory.Acheivement[mont] += person.Acheivement[mont];
            }
          }
        });
      }
    });

    // Assign targets to territories from SalesTargetList
    SalesTargetList?.forEach((SalesTarget) => {
      const targetFind = territoryMap.get(SalesTarget?.Territery_Id);
      if (targetFind) {
        console.log("targetFind : ", targetFind);
        targetFind.Target = { ...SalesTarget.Target };
      }
    });

    console.log("salesInvoiceAllList : ", salesInvoiceAllList);
    console.log("territoryData : ", territoryData);
    console.log("SalesTargetList : ", SalesTargetList);
    console.log("salesPersonTarget : ", salesPersonTarget);

    return territoryList?.data;
  };

  // territoryTree(territoryList?.data);

  return { isLoading: isLoading, acheivement: territoryTree(territoryList) };
};

export default SalesTargetViewList;
