// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inboxTableRow>th {
    border-right: 2px solid #8a7f7f;
}

.inboxTableRow>td {
    border-right: 2px solid #8a7f7f;
}

.view-message-div {
    /* background-color: #ece9e9; */
    height: 700px;
    padding: 20px;
}


.MuiTableCell-body:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.gmail-snippet {
    color: #999;
    white-space: nowrap;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;    
    max-width: 1200px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/GmailAPI/GmailInbox.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+BAA+B;IAC/B,aAAa;IACb,aAAa;AACjB;;;AAGA;IACI,wCAAwC;IACxC,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".inboxTableRow>th {\n    border-right: 2px solid #8a7f7f;\n}\n\n.inboxTableRow>td {\n    border-right: 2px solid #8a7f7f;\n}\n\n.view-message-div {\n    /* background-color: #ece9e9; */\n    height: 700px;\n    padding: 20px;\n}\n\n\n.MuiTableCell-body:hover {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    cursor: pointer;\n}\n\n.gmail-snippet {\n    color: #999;\n    white-space: nowrap;\n    width: 700px;\n    overflow: hidden;\n    text-overflow: ellipsis;    \n    max-width: 1200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
