// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .sidebar {
  width: 25%;
  padding: 1rem;
  grid-area: 1 / 1 / 2 / 2;
}

.sidebar__menu {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
} */

.sidebar {
  width: 350px;
  padding: 1rem;
  grid-area: 1 / 1 / 2 / 2;
  height: 67vh;
  overflow-y: auto;
  background-color: transparent;
  transition: all 0.3s ease;
  background-color: rgba(249, 249, 249, 0.418);
  backdrop-filter: blur(20px);
  .ant-menu-light {
    background-color: transparent !important;
    border: 0px !important ;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/OutlookAPI/Components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;;;;;;;;;;GAUG;;AAEH;EACE,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,4CAA4C;EAC5C,2BAA2B;EAC3B;IACE,wCAAwC;IACxC,uBAAuB;EACzB;AACF","sourcesContent":["/* .sidebar {\n  width: 25%;\n  padding: 1rem;\n  grid-area: 1 / 1 / 2 / 2;\n}\n\n.sidebar__menu {\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-items: center;\n} */\n\n.sidebar {\n  width: 350px;\n  padding: 1rem;\n  grid-area: 1 / 1 / 2 / 2;\n  height: 67vh;\n  overflow-y: auto;\n  background-color: transparent;\n  transition: all 0.3s ease;\n  background-color: rgba(249, 249, 249, 0.418);\n  backdrop-filter: blur(20px);\n  .ant-menu-light {\n    background-color: transparent !important;\n    border: 0px !important ;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
