import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import {
  DisplayFlex,
  FontStyle,
  componentTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import React, { memo, useEffect, useState } from "react";
import {
  postSalesPersonTarget,
  postSalesTarget,
  useGetSalesPersonTarget,
  useTerritoryList,
  useTotalSalesTarget,
} from "../TerritoryAPI";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import {
  Cancelbutton,
  CustomButton,
  fetchTransactionSeries,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import SalesTargetList, { Months } from "./SalesDatas";
import Div from "@jumbo/shared/Div";
import { BASE_URL } from "app/services/config";
import axios from "axios";

const CreateSalesTarget = ({ open, setOpen, update }) => {
  const token = localStorage.getItem("accesstoken");

  const [territoryName, setTerritoryName] = useState("");
  const [territoryFilter, setTerritoryFilter] = useState([]);
  const { territoryList } = useTerritoryList();
  // const { datas, isLoading } = SalesTargetList();
  const { SalesTargetList } = useTotalSalesTarget();
  const { salesPersonTarget } = useGetSalesPersonTarget();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const [data, setData] = useState({});
  const [personData, setPersonData] = useState([]);
  const [showPerson, setShowPerson] = useState(false);
  const [companyDetails, setCompanyDetails] = useState("");
  const [seriesValue, setSeriesValue] = useState("");
  const [targetId, setTargetId] = useState("");

  const queriyClient = useQueryClient();

  // console.log("territoryFilter : ", territoryFilter);
  const reset = () => {
    Months?.forEach((month) => {
      setData((prev) => ({ ...prev, [month]: 0 }));
    });
  };

  // const salesPersonTargetUpdate = async () => {
  //   let personPayload = {
  //     id: update?.id,
  //     Territery_Name: territoryName?.Territery_Name,
  //     Target: data,
  //     Territery_Id: territoryName?.Territery_Id,
  //     Target_Id: territoryName?.id,
  //     Is_Deleted: false,
  //     User_Id: data?.map((itm) => itm?.id),
  //     Organization_Id: localStorage.getItem("OrganizationId"),
  //     Created_By: localStorage.getItem("UserId"),
  //     Updated_By: localStorage.getItem("UserId"),
  //   };
  // };

  const handleSubmit = async () => {
    const error = {};

    Object.entries(data)?.map(([kay, val], i) => {
      if (!Boolean(val)) {
        error[kay] = true;
      }
    });

    if (Boolean(seriesValue !== "Automatic") && !Boolean(targetId)) {
      error.targetId = "Target Id is required";
    } else if (
      Boolean(seriesValue !== "Automatic") &&
      !Boolean(targetId?.length == 11)
    ) {
      error.targetId = "Target Id must be 11 digits.";
    }

    setError(error);

    if (Object.keys(error)?.length === 0) {
      let payload = {};
      if (open === "Create") {
        payload = {
          Target_Id: seriesValue === "Manual" && targetId,
          Territery_Name: territoryName?.TerritoryName,
          Target: data,
          Year: companyDetails?.Fiscal_Year?.Calendar_FinancialYear,
          Territery_Id: territoryName?.id,
          Is_Deleted: false,
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
      } else {
        payload = {
          id: update?.id,
          Territery_Name: territoryName?.TerritoryName,
          Target: data,
          Year: update?.Year,
          Territery_Id: territoryName?.id,
          Is_Deleted: false,
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
      }

      setLoading(true);
      if (open?.toLowerCase() === "create") {
        const res = await postSalesTarget("post", payload);

        if (res) {
          toast.success("Created successfully");
          queriyClient.invalidateQueries("updateSalesTargetList");
          setLoading(false);
          setOpen("list");
          setShowPerson(false);
          setPersonData([]);
        } else {
          toast.error("Created Unsuccessful");
        }
      }
      //  else if (open?.toLowerCase() === "edit") {
      //   const res = await postSalesTarget("put", payload);
      //   if (res) {
      //     toast.success("Update successfully");
      //     queriyClient.invalidateQueries("updateSalesTargetList");
      //     setLoading(false);
      //     setOpen("list");
      //     setShowPerson(false);
      //     setPersonData([]);
      //   } else {
      //     toast.error("Update Unsuccessful");
      //   }
      // }
    } else {
      toast.warning(
        `The value should not be 0 value ${Object.keys(error)?.map(
          (key) => key
        )}`
      );
      setLoading(false);
    }
  };

  // handle Sales Person Target
  const handleInputOnChange = (e, month, i) => {
    const value = Number(e.target.value);
    let toValue = Number(data?.[month]);
    let totalTargetValue = 0;
    personData?.map((item, ind) => {
      if (ind !== i) {
        totalTargetValue += Number(item?.Target?.[month]) || 0;
      }
    });
    console.log("toValue : ", data, toValue, month, i);
    if (data?.[month] < totalTargetValue + value) {
      toast.warning("The value should not be greater than sales target ");
    } else {
      setPersonData((prev) => {
        const updatedData = [...prev];
        const userData = updatedData[i];
        if (userData && userData?.Target) {
          userData.Target[month] = value;
        }
        return updatedData;
      });
    }
  };

  // handle Sales Target
  const handleSalesOnChange = (e, month, i) => {
    let totalTargetValue = 0;
    personData?.map((item, ind) => {
      totalTargetValue += Number(item?.Target?.[month]) || 0;
    });

    console.log("Sales on change : ", totalTargetValue, e?.target.value, month);

    if (totalTargetValue > Number(e.target.value)) {
      toast.warning("The value should not be less than sales person target ");
    } else {
      setData((prev) => ({
        ...prev,
        [Months[i]]: Number(e.target.value),
      }));
    }
    setShowPerson(true);
  };

  useEffect(() => {
    if (open === "Create") {
      let filter = [];

      territoryList?.data?.forEach((terr) => {
        let matchFound = false;

        SalesTargetList?.forEach((item) => {
          if (
            item?.Territery_Name?.toLowerCase() ===
            terr?.TerritoryName?.toLowerCase()
          ) {
            matchFound = true;
          }
          // console.log("skjfsdf : ", item, terr);
        });

        if (!matchFound) {
          filter.push(terr);
        }
      });

      setTerritoryFilter(filter);

      reset();
    }
    // else if (open === "Edit") {
    //   let filter = [];

    //   territoryList?.data?.forEach((item) => {
    //     SalesTargetList?.map((terr) => {
    //       console.log("filter wq : ", item, terr);

    //       if (
    //         item?.Territory_Name?.toLowerCase() !==
    //           terr?.Territory_Name?.toLowerCase() ||
    //         terr?.Territory_Name?.toLowerCase() ===
    //           update?.Territory_Name?.toLowerCase()
    //       ) {
    //         filter.push(item);
    //       }
    //     });
    //   });

    //   let salesPerson = salesPersonTarget?.find(
    //     (s) => s?.Territery_Id === update?.Territery_Id
    //   );
    //   console.log(
    //     "filteredSalesTargetList : ",
    //     update,
    //     salesPerson,
    //     salesPersonTarget,
    //     filter
    //   );
    //   setTerritoryName(salesPerson);
    //   setTerritoryFilter(filter);
    //   setData(update?.Target || {});
    //   setPersonData(salesPerson?.Target || []);
    // }
  }, [open]);

  const getCompany = async () => {
    try {
      let res = await axios.get(
        `${BASE_URL}/useradmin/CRMorganizationCRUD/?id=${localStorage.getItem(
          "OrganizationId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("resssss: ", res?.data);
      setCompanyDetails(res?.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(async () => {
    getCompany();
    const series = await fetchTransactionSeries("Sales_Target");
    console.log("Product_Offer series", series);
    setSeriesValue(series);
  }, []);

  return (
    <>
      <Box>
        <Typography sx={[componentTitle]} className="mt-1 mb-4">
          {open} Sales Target
        </Typography>
        {/* <div className="my-4  flex items-center gap-2 justify-between">
          <Div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <Typography sx={[input_label]}>Sales Target Id <span className="required">{seriesValue == "Automatic" ? "" : "*"}</span></Typography>

              <FormControl>
                <TextField
                  sx={{
                    "& fieldset": {
                      borderRadius: "5px",
                    },
                    width: "100%"
                  }}
                  className={`col-12 input-box2`}
                  name='TargetId'
                  id="outlined-basic"
                  value={targetId}
                  onChange={(e) => { setTargetId(e.target.value) }}
                  placeholder="# Target Id"
                  variant="outlined"
                  disabled={seriesValue == "Automatic" ? true : ""}
                />
                {error?.targetId && (
                  <Div style={{ color: "red" }}>{error?.targetId}</Div>
                )}
              </FormControl>
            </div>
            <div className="flex items-center gap-10">
              <Typography sx={[input_label]}>Select Territory</Typography>

              <FormControl>
                <Autocomplete
                  sx={{ minWidth: { xs: "250px", md: "190px" } }}
                  className="search-select3 col-12"
                  type="search"
                  options={territoryFilter || []}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.TerritoryName) {
                      return option?.TerritoryName;
                    }
                    return "";
                  }}
                  value={territoryName?.TerritoryName}
                  onChange={(event, newValue) => {
                    setTerritoryName({
                      ...newValue,
                      TerritoryName: newValue?.TerritoryName,
                      id: newValue?.id,
                    });
                    console.log("newValue", newValue);
                    !Boolean(newValue) && reset();
                  }}
                  autoComplete="off"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      placeholder="Choose a territory"
                    />
                  )}
                />
              </FormControl>
            </div>
          </Div>
          <div className="flex items-center gap-2">
            <Cancelbutton
              label="Cancel"
              Icon="cancelIcon"
              onClick={() => {
                setOpen("list");
                reset();
                setTerritoryName({});
              }}
            />
            <CustomButton
              label={
                loading ? "Wait..." : open === "Create" ? "Save" : "Update"
              }
              Icon={loading ? "" : "saveIcon"}
              disabled={loading || !Boolean(territoryName)}
              onClick={!loading && handleSubmit}
              type="submit"
            />
          </div>
        </div> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6.1}>
            <Div sx={[DisplayFlex]}>
              <Typography
                id="Typography-root"
                sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                className="col-3"
              >
                Sales Target Id
              </Typography>
              <TextField
                sx={{
                  "& fieldset": {
                    borderRadius: "5px",
                  },
                }}
                className={`col-6 input-box2`}
                name="TargetId"
                id="outlined-basic"
                value={targetId}
                onChange={(e) => {
                  setTargetId(e.target.value);
                }}
                placeholder="# Target Id"
                variant="outlined"
                disabled={seriesValue == "Automatic" ? true : ""}
              />
              {error?.targetId && (
                <Div style={{ color: "red" }}>{error?.targetId}</Div>
              )}
            </Div>
          </Grid>
          <Grid item xs={12} md={6} lg={6.1}>
            <Div sx={[DisplayFlex]}>
              <Typography
                id="Typography-root"
                sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                className="col-3"
              >
                Select Territory
              </Typography>
              <Autocomplete
                sx={{ minWidth: { xs: "250px", md: "190px" } }}
                className="search-select3 col-6"
                type="search"
                options={territoryFilter || []}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.TerritoryName) {
                    return option?.TerritoryName;
                  }
                  return "";
                }}
                value={territoryName?.TerritoryName}
                onChange={(event, newValue) => {
                  setTerritoryName({
                    ...newValue,
                    TerritoryName: newValue?.TerritoryName,
                    id: newValue?.id,
                  });
                  console.log("newValue", newValue);
                  !Boolean(newValue) && reset();
                }}
                autoComplete="off"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    placeholder="Choose a territory"
                  />
                )}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={6} lg={6.1}>
            <Div sx={[DisplayFlex]}>
              <Typography
                id="Typography-root"
                sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                className="col-3"
              >
                Financial Year
              </Typography>
              <TextField
                sx={{
                  "& fieldset": {
                    borderRadius: "5px",
                  },
                }}
                className={`col-6 input-box2`}
                name="TargetId"
                id="outlined-basic"
                value={companyDetails?.Fiscal_Year?.Calendar_FinancialYear}
                variant="outlined"
                placeholder="Financial Year"
              />
            </Div>
          </Grid>
        </Grid>

        <Box>
          <Typography sx={[FontStyle]} className="my-3">
            Sales Revenue
          </Typography>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={200}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table>
              <TableHead>
                <CRMTableRow>
                  {Months?.map((month, i) => (
                    <CRMTableCell
                      style={{ color: error?.[month] && "tomato" }}
                      key={i}
                    >
                      {month}
                    </CRMTableCell>
                  ))}
                  <CRMTableCell
                    style={{
                      color: Object.keys(error)?.length > 0 && "tomato",
                    }}
                  >
                    Total
                  </CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                <CRMTableRow>
                  {Object?.keys(data)?.length > 0 &&
                    Object?.entries(data)?.map(([month, val], i) => {
                      // console.log("keys", key, val);
                      // if (Object.keys(territoryName).length === 0) {
                      //   return (
                      //     <CRMTableCell key={i}>
                      //       {" "}
                      //       {toLocaleCurrency(val[key] || 0)}
                      //     </CRMTableCell>
                      //   );
                      // }
                      return (
                        <CRMTableCell key={i}>
                          <TextField
                            className=" input-box2 w-full my-1"
                            type="number"
                            sx={{
                              caret: "unset",
                              minWidth: {
                                xs: "140px !important",
                                md: "80px !important",
                              },
                            }}
                            onChange={(e) =>
                              handleSalesOnChange(e, Months[i], i)
                            }
                            inputProps={{ min: 0 }}
                            value={data?.[Months[i]] || 0}
                          />
                        </CRMTableCell>
                      );
                    })}
                  <CRMTableCell>
                    {toLocaleCurrency(
                      Object?.values(data)?.reduce(
                        (acc, val) => Number(acc) + Number(val),
                        0
                      )
                    )}
                  </CRMTableCell>
                </CRMTableRow>
              </TableBody>
            </Table>
          </JumboScrollbar>
        </Box>

        {showPerson && personData?.length > 0 && (
          <Box>
            <Typography sx={FontStyle} className="mb-2">
              Sales Person Revenue
            </Typography>
            {
              <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={200}
                autoHide={true}
                hideTracksWhenNotNeeded
                id="no-more-tables"
              >
                <Table>
                  <TableHead>
                    <CRMTableRow>
                      <CRMTableCell width={"140px"}>
                        Sales Person Name
                      </CRMTableCell>
                      {Months?.map((month, i) => (
                        <CRMTableCell key={i}>{month}</CRMTableCell>
                      ))}
                      <CRMTableCell>Total</CRMTableCell>
                    </CRMTableRow>
                  </TableHead>
                  <TableBody>
                    {personData?.map((user, i) => {
                      // console.log("keys", user, i);
                      return (
                        <CRMTableRow key={i}>
                          <CRMTableCell>{user?.salesperson}</CRMTableCell>
                          {Months?.map((month, ci) => (
                            <CRMTableCell key={ci}>
                              <TextField
                                color="danger"
                                className="input-box2 w-full my-1"
                                type="number"
                                sx={{
                                  caret: "unset",
                                  minWidth: {
                                    xs: "140px !important",
                                    md: "80px !important",
                                  },
                                }}
                                onChange={(e) =>
                                  handleInputOnChange(e, month, i)
                                }
                                inputProps={{ min: 0 }}
                                value={user?.Target?.[month] || 0}
                              />
                            </CRMTableCell>
                          ))}
                          <CRMTableCell>
                            {toLocaleCurrency(
                              Object.values(user?.Target || {}).reduce(
                                (acc, val) => Number(acc) + Number(val),
                                0
                              )
                            )}
                          </CRMTableCell>
                        </CRMTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </JumboScrollbar>
            }
          </Box>
        )}

        <Div className="flex items-center gap-3">
          <Cancelbutton
            label="Cancel"
            Icon="cancelIcon"
            onClick={() => {
              setOpen("list");
              reset();
              setTerritoryName({});
            }}
          />
          <CustomButton
            label={loading ? "Wait..." : open === "Create" ? "Save" : "Update"}
            Icon={loading ? "" : "saveIcon"}
            disabled={loading || !Boolean(territoryName)}
            onClick={!loading && handleSubmit}
            type="submit"
          />
        </Div>
      </Box>
    </>
  );
};

export default memo(CreateSalesTarget);
