import React, { useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import './SendMail.css'
import { useForm } from 'react-hook-form'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import { BASE_URL } from "app/services/auth-services";
import useStateRef from 'react-usestateref';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const SendMail = ({ close, sendFormData, messages }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const [CcVisible, setCcVisible] = useState(false)
    const fileInputRef = useRef(null)
    const [message, setMessage, messageRef] = useStateRef('')
    const [fileName, setFileName] = useState('')

    const onSubmit = (formData) => {
        console.log("data", formData)
        const item = "crmuseradmin1";
        const token = localStorage.getItem("accesstoken");
        // const payload = {
        //     "MailSubject": formData.subject,
        //     "Organization_Id": 1,
        //     "Cc": formData.Cc ? [formData.Cc] : [],
        //     "MailBody": mailBodyContent,
        //     "file": fileName,
        //     "RecevierMailId": formData.to,
        //     "UserId": 1,
        //     "Contact_Id": 1,
        // }
        const payload = {
            "subject": formData.subject,
            "Organization_Id": localStorage.getItem("UserId"),
            "body": mailBodyContent,
            "file": fileName,
            "To": formData.to,
            "id": localStorage.getItem("UserId"),
            "Cc": formData.Cc ? [formData.Cc] : []
        }
        const headers = {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }

        const APIUrl = `${BASE_URL}/user/UserGMailsendingSpecifiedEvent/`

        axios.post(APIUrl, payload, {
            headers: headers
        }).then((res) => {
            console.log("Mail Sent")
            toast.success("Mail Sent successfully", {
                position: "top-right",
                autoClose: 1000,
            });
            reset()
            close()
        }).catch((error)=>{
            
        })

        // sendFormData(formData)
    }

    const mailBodyContent = `<div class='card'>
    <div class='card-body'>
      <h4 className="card-subtitle mb-2 text-muted">Dear Sir,</h4>
      <div class='card-text'>
        <p>${messageRef.current}</p>
        <p>Click the button to download the attachment </p>
        <div><a href="{{url}}"> 
        <button type='button' class='btn btn-primary' style='background-color: '#3c7c33'; color: #fff; border: none; border-radius: 4px; padding: 8px 16px; cursor: pointer; text-decoration: none;'>Download Attachment</button></a>
        </div>
            </div>
    </div>
    </div>`;



    const handleAttachmentClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log("fileee", file)
        if (file) {
            console.log("file", file)
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                const filename = file.name;
                const filetype = file.type;
                setFileName(file.name);
                const item = "crmuseradmin1";
                const token = localStorage.getItem("accesstoken");

                const payload = {
                    Organization_Id: localStorage.getItem("OrganizationId"),
                    File: base64String,
                    FileName: filename,
                    Urls: "",
                    Description: "Upload File",
                    Created_By: localStorage.getItem("UserId"),
                    Updated_By: localStorage.getItem("UserId"),

                };
                axios
                    .post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
                        headers: {
                            db: item,
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("response", res)
                    })
                    .catch((error) => {
                        console.error("Error while uploading a file", error);
                    });
            }
            reader.readAsDataURL(file);
        }
    }
    return (
        <div className='sendMail'>
            <div className='sendMail_header'>
                <h3>New Message</h3>
                <CloseIcon className='sendMail_close' onClick={() => { close() }} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type='text' style={{ position: 'relative' }} placeholder='To' {...register("to", { required: true })} />
                {!CcVisible ? <span style={{ position: 'absolute', right: '8px', cursor: 'pointer', padding: '5px' }} onClick={() => setCcVisible(true)}>Cc</span> : ''}
                {errors && errors.to && <p className='sendMail_error'>To is Required!</p>}
                {CcVisible ? <input type='text' placeholder='Cc' {...register("Cc")} /> : ''}
                <input type='text' placeholder='Subject' {...register("subject", { required: true })} />
                {errors && errors.subject && <p className='sendMail_error'>Subject is Required!</p>}
                <input type='text' placeholder='Message'  {...register("message", { required: true })} className='sendMail_message' />
                {errors && errors.message && <p className='sendMail_error'>Message is Required!</p>}
                <div className='sendMail_options'>
                    <Button className='sendMail_send' color='primary' variant='contained' type='submit'>Send</Button>
                    <AttachFileIcon sx={{ cursor: 'pointer' }} onClick={handleAttachmentClick}></AttachFileIcon>
                    <input type='file' style={{ display: 'none' }}  {...register("file")} onChange={handleFileChange} ref={fileInputRef} />
                </div>
            </form>
        </div>
    )
}

export default SendMail