import Div from "@jumbo/shared/Div";
import { Box, Grid, Typography } from "@mui/material";
import {
  DisplayFlex,
  FontStyle,
  avatarColors,
  cardHeight,
} from "app/pages/widgets/CRMStyles";
import Counter from "app/pages/widgets/StylesComponents";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MdCallEnd, MdDoNotTouch, MdOutlineScoreboard } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { FiActivity } from "react-icons/fi";
import { IoIosMailOpen } from "react-icons/io";
import { GiDiscussion } from "react-icons/gi";
import '../Lead.css';

const LeadStageMovement = () => {
  {/*
    const [datas, setDatas] = useState(Array.from({ length: 6 }).fill(""));

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const headers = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const LeadetData = async () => {
    try {
      const [leadRes1, leadRes2, mailRes, callRes, meetingRes] =
        await Promise.all([
          axios.get(`${BASE_URL}/user/ActiveLeaddashboard/`, headers),
          axios.put(`${BASE_URL}/user/LeadDashboard/`, {}, headers),
          axios.get(`${BASE_URL}/user/LeadDashboard/`, headers),
          axios.post(`${BASE_URL}/user/ActiveLeaddashboard/`, {}, headers),
          axios.post(`${BASE_URL}/user/LeadDashboard/`, {}, headers),
        ]);
      if (leadRes1) {
        let list = [
          {
            label: "Total Activities",
            value: leadRes1?.data?.Active_Leads_For_Month || 0,
          },
          {
            label: "Untouched Leads",
            value: leadRes1?.data?.In_Active_Leads || 0,
          },
          {
            label: "Avg Leads Score",
            value: leadRes2.data?.Average_Score || 0,
          },
          { label: "Total Mail Sent", value: mailRes?.data?.Total_Mail_send },
          { label: "Calls Made", value: callRes?.data?.Lead_Calls_Count },
          { label: "Client Meetings", value: meetingRes?.data?.Meeting_Count },
        ];
        return {
          datas: list,
        };
      }
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };

  const {
    data: LeadStageMovelists,
    isLoading: LeadStageMovelistsLoading,
    isError: LeadStageMovelistsError,
    refetch: LeadStageMovelistsRefetch,
  } = useQuery(["LeadStageMovelistsLists"], LeadetData, {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (LeadStageMovelists) {
      // setOption(LeadStageMovelists?.option)
      setDatas(LeadStageMovelists?.datas);
      // setTotal(LeadStageMovelists?.total)
    }
  }, [LeadStageMovelists]);

  console.log("datasoptions", LeadStageMovelists);
  */}

  return (
    <div className="container-fluid">
      <div class="row mt-3">
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card custom-card bg-primary-transparent border-0 shadow-none">
            <div class="card-body p-4">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <div class="main-card-icon primary">
                  <div
                    class="avatar avatar-lg bg-primary-transparent border border-primary border-opacity-10">
                    <div class="avatar avatar-sm svg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div> <span class="d-block ,mb-1">TOTAL ACTIVITIES</span>
                  <h5 class="fw-medium d-block mb-0">6<span
                    class="text-success fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                      class="ri-arrow-up-s-line align-middle mx-1"></i></span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card custom-card bg-secondary-transparent border-0 shadow-none">
            <div class="card-body p-4">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <div class="main-card-icon secondary">
                  <div
                    class="avatar avatar-lg bg-secondary-transparent border border-secondary border-opacity-10">
                    <div class="avatar avatar-sm svg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ban" viewBox="0 0 16 16">
                        <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div> <span class="d-block ,mb-1">UNTOUCHED LEADS</span>
                  <h5 class="fw-medium d-block mb-0">1<span
                    class="text-danger fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                      class="ri-arrow-down-s-line align-middle mx-1"></i></span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card custom-card bg-success-transparent border-0 shadow-none">
            <div class="card-body p-4">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <div class="main-card-icon success">
                  <div
                    class="avatar avatar-lg bg-success-transparent border border-success border-opacity-10">
                    <div class="avatar avatar-sm svg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard2-data-fill" viewBox="0 0 16 16">
                        <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5" />
                        <path d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585q.084.236.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5q.001-.264.085-.5M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm4-3a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V9a1 1 0 0 1 1-1" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div> <span class="d-block ,mb-1">AVG LEADS</span>
                  <h5 class="fw-medium d-block mb-0">8<span
                    class="text-success fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                      class="ri-arrow-up-s-line align-middle mx-1"></i></span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card custom-card bg-orange-transparent border-0 shadow-none">
            <div class="card-body p-4"> 
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <div class="main-card-icon orange">
                  <div class="avatar avatar-lg bg-orange-transparent border border-orange border-opacity-10">
                    <div class="avatar avatar-sm svg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-arrow-up-fill" viewBox="0 0 16 16">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zm.192 8.159 6.57-4.027L8 9.586l1.239-.757.367.225A4.49 4.49 0 0 0 8 12.5c0 .526.09 1.03.256 1.5H2a2 2 0 0 1-1.808-1.144M16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z" />
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354 1.25 1.25a.5.5 0 0 1-.708.708L13 12.207V14a.5.5 0 0 1-1 0v-1.717l-.28.305a.5.5 0 0 1-.737-.676l1.149-1.25a.5.5 0 0 1 .722-.016" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div> <span class="d-block ,mb-1">TOTAL MAIL</span>
                  <h5 class="fw-medium d-block mb-0">29<span
                    class="text-success fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                      class="ri-arrow-up-s-line align-middle mx-1"></i></span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card custom-card bg-secondary1-transparent border-0 shadow-none">
            <div class="card-body p-4">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <div class="main-card-icon secondary1">
                  <div
                    class="avatar avatar-lg bg-secondary1-transparent border border-secondary1 border-opacity-10">
                    <div class="avatar avatar-sm svg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div> <span class="d-block ,mb-1">CALLS MADE</span>
                  <h5 class="fw-medium d-block mb-0">1<span
                    class="text-danger fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                      class="ri-arrow-down-s-line align-middle mx-1"></i></span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="card custom-card bg-success1-transparent border-0 shadow-none">
            <div class="card-body p-4">
              <div class="d-flex align-items-center gap-3 flex-wrap">
                <div class="main-card-icon success1">
                  <div
                    class="avatar avatar-lg bg-success1-transparent border border-success1 border-opacity-10">
                    <div class="avatar avatar-sm svg-white">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div> <span class="d-block ,mb-1">CLIENT MEETINGS</span>
                  <h5 class="fw-medium d-block mb-0">11<span
                    class="text-success fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                      class="ri-arrow-up-s-line align-middle mx-1"></i></span></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default LeadStageMovement;


{/*
        <Box>
      <Grid container spacing={2}>
        {datas?.map((item, idx) => {
          return (
            <>
              <Grid key={idx} item xs={12} lg={1.9} sm={4}>
                <Div
                  sx={{
                    borderRadius: "10px",
                    p: 3,
                    backgroundColor: avatarColors[idx + 3],
                    height: cardHeight,
                    boxSizing: "border-box !important",
                  }}
                >
                  <Div sx={[DisplayFlex, { gap: "10px" }]}>
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          justifyContent: "center",
                          height: "60px",
                          width: "90px",
                          borderRight: "1px solid lightgray",
                          // backgroundColor: avatarLightColors[idx],
                        },
                      ]}
                    >
                      {item?.label === "Total Actives" && (
                        <FiActivity style={{ color: "white", fontSize: 30 }} />
                      )}
                      {item?.label === "Untouched Leads" && (
                        <MdDoNotTouch
                          style={{ color: "white", fontSize: 30 }}
                        />
                      )}
                      {item?.label === "Avg Leads Score" && (
                        <MdOutlineScoreboard
                          style={{ color: "white", fontSize: 30 }}
                        />
                      )}
                      {item?.label === "Total Mail Sent" && (
                        <IoIosMailOpen
                          style={{ color: "white", fontSize: 30 }}
                        />
                      )}
                      {item?.label === "Calls Made" && (
                        <MdCallEnd style={{ color: "white", fontSize: 30 }} />
                      )}
                      {item?.label === "Client Meetings" && (
                        <GiDiscussion
                          style={{ color: "white", fontSize: 30 }}
                        />
                      )}
                    </Div>
                    <Div sx={{ width: "70%" }}>
                      <Typography
                        id="Typography-root"
                        sx={{
                          color: "whitesmoke",
                          mt: 1,
                          fontWeight: 600,
                          textAlign: "center",
                          fontSize: { xs: "20px", md: "25px", lg: "30px" },
                        }}
                      >
                        {item?.value ? (
                          <Counter maximum={item?.value} speed={50} />
                        ) : (
                          0
                        )}
                      </Typography>
                      <Typography
                        sx={[
                          {
                            textAlign: "center",
                            // transform: "translateX(50%)",
                            color: "whitesmoke",
                            mt: 1,
                            fontWeight: 600,
                            fontSize: "13px !important",
                          },
                        ]}
                      >
                        {item?.label?.toUpperCase()}
                      </Typography>
                    </Div>
                  </Div>
                </Div>
              </Grid>
              {idx == 2 && (
                <Grid
                  key={idx}
                  item
                  xs={0}
                  md={0.6}
                  sx={[
                    DisplayFlex,
                    {
                      display: { xs: "none !important", lg: "flex !important" },
                      justifyContent: "center",
                    },
                  ]}
                >
                  <Div
                    sx={{
                      backgroundColor: "lightgray",
                      width: "2px",
                      height: "60%",
                    }}
                  ></Div>
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
    </Box>
      */}