// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rotate-img{
    border-radius: 8px;
    animation: move 2s infinite linear;
}

@keyframes move{
    from { 
        transform: rotate(0deg); 
    }
    to  { 
        transform: rotate(360deg); 
    }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/JumboCustomizer/ThemeSetting.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".rotate-img{\n    border-radius: 8px;\n    animation: move 2s infinite linear;\n}\n\n@keyframes move{\n    from { \n        transform: rotate(0deg); \n    }\n    to  { \n        transform: rotate(360deg); \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
