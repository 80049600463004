import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Popover, Select, TextField, Typography } from '@mui/material'
import { DateCalendar, DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Dayjs } from 'dayjs';


const CreateEventPopover = ({ open, close, anchorEl }) => {
    const gapi = window.gapi
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [timeValues, setTimeValues] = useState([])
    const [attendees, setAttendees] = useState()
    const [location, setLocation] = useState()
    const [description, setDescription] = useState()
    const [notification, setNotification] = useState()


    //loop for timeValues
    const getTimeValues = () => {
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = (hour > 12 ? hour - 12 : hour).toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                const ampm = hour < 12 ? 'am' : 'pm';
                const time = `${formattedHour}:${formattedMinute} ${ampm}`;
                timeValues.push(time);
            }
        }
    }
    useEffect(() => {
        getTimeValues()
    }, [])


    const handleCreateClick = () => {
        var event = {
            'summary': title,
            'location': location,
            'description': description,
            'start': {
                'dateTime': convertDateTimeFormat(date, startTime),
                'timeZone': 'UTC',
            },
            'end': {
                'dateTime': convertDateTimeFormat(date, endTime),
                'timeZone': 'UTC',
            },
            'attendees': [
                { 'email': attendees, 'responseStatus': 'needsAction' },
            ],
            'reminders': {
                'useDefault': notification ? false : true,

                'overrides': notification && [
                    {
                        'method': 'popup',
                        'minutes': notification
                    }
                ]
            },
            "guestsCanSeeOtherGuests": true,
        };
        console.log("event", event)
        var request = gapi.client.calendar.events.insert({ 'calendarId': 'primary', 'resource': event, 'sendUpdates': 'all' });
        request.execute((event) => {
            window.open(event.htmlLink);
        }, (error) => {
            console.error(error);
        });
        close()
    }

    const convertDateTimeFormat = (date, time) => { //date: object {"$L": "en"} //time 10:00 am
        console.log("In ConvertDateFormat")        
        const dateObject = new Date(date)
        console.log("dateObject", dateObject)
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth()
        const day = dateObject.getDate()
        const trimmedTime = time.trim();
        const timeParts = trimmedTime.match(/(\d+):(\d+)\s?([APap][Mm])/);
        if (timeParts) {
            const customHours = parseInt(timeParts[1], 10);
            const customMinutes = parseInt(timeParts[2], 10);
            const ampm = timeParts[3].toLowerCase();

            const adjustedHours = ampm === 'pm' ? customHours + 12 : customHours;

            const newDate = new Date(year, month, day, adjustedHours, customMinutes);
            const isoString = newDate.toISOString()
            return isoString
        }
    }
    return (
        <div>
            <Popover
                id=''
                open={open}
                anchorEl={anchorEl}
                onClose={close}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className='p-3'>
                    <div className='mb-2'><TextField label="Add Title" variant="standard" value={title} onChange={e => { setTitle(e.target.value) }} /></div>
                    <div className='mb-2'>
                        {/* <Box component="span" sx={{ display: 'block' }}></Box> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker value={date} onChange={(e) => { setDate(e) }} />
                        </LocalizationProvider>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="startTimeLabel">start time</InputLabel>
                            <Select
                                labelId="startTimeLabel"
                                id="startTime"
                                value={startTime}
                                label="start time"
                                onChange={(e) => { setStartTime(e.target.value) }}
                            >
                                {timeValues && timeValues.map((value, index) => {
                                    return (
                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="endTimeLabel">end time</InputLabel>
                            <Select
                                labelId="endTimeLabel"
                                id="endTime"
                                value={endTime}
                                label="end time"
                                onChange={(e) => { setEndTime(e.target.value) }}
                            >
                                {timeValues && timeValues.map((value, index) => {
                                    return (
                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='mb-2'>
                        <TextField label="Add guests" value={attendees} onChange={e => { setAttendees(e.target.value) }} variant="standard" />
                    </div>
                    <div className='mb-2'>
                        <TextField label="Add location" value={location} onChange={e => { setLocation(e.target.value) }} variant="standard" />
                    </div>
                    <div className='mb-2'>
                        <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => { setDescription(e.target.value) }}
                        />
                    </div>
                    <div className='mb-2'>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="notificationLabel">Notification</InputLabel>
                            <Select
                                labelId="notificationLabel"
                                value={notification}
                                label="notification"
                                onChange={(e) => { setNotification(e.target.value) }}
                            >
                                <MenuItem value={5}>{'5 minutes before'}</MenuItem>
                                <MenuItem value={10}>{'10 minutes before'}</MenuItem>
                                <MenuItem value={15}>{'15 minutes before'}</MenuItem>
                                <MenuItem value={30}>{'30 minutes before'}</MenuItem>
                                <MenuItem value={60}>{'1 hour before'}</MenuItem>
                                <MenuItem value={1440}>{'1 day before'}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='mb-2'>
                        <Button variant="contained" color="primary" onClick={handleCreateClick}>
                            Create
                        </Button>
                    </div >
                </div>

            </Popover></div>
    )
}

export default CreateEventPopover