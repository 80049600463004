import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  Grid,
  ListItem,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  Card,
  Box,
  InputLabel,
  Stack,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

import React, { useEffect, useContext, useCallback } from "react";
import * as yup from "yup";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Country, State, City } from "country-state-city";
import CloseIcon from "@mui/icons-material/Close";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  input_label,
  dialogFontSize,
} from "../widgets/CRMStyles";
import { useQuery, useQueryClient } from "react-query";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
  fetchTransactionSeries,
} from "../widgets/StylesComponents";
import { AutoHeight } from "app/redux/auth/localStorage";

const CreateQualifiedLeads = ({
  scrollHeight,
  getQualifiedDataListsRefetch,
  setOpenAddLead,
  setSave,
  tabValue,
  getAllData,
  getQualifiedData,
}) => {
  //  Initilization For Country,state,city

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [cname, setCname] = useState("");
  const [phone, setPhone] = useState("");
  const [url, setUrl] = useState("");
  const [leadtype, setLeadType] = useState("");
  const [leadStatusName, setleadStatusName] = useState("");
  const [leadCycleStage, setleadCycleStage] = useState("");
  const [selectedCountry1, setSelectedCountry1] = useState("");
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedCity1, setSelectedCity1] = useState("");
  const [areaname, setAreaName] = useState("");
  const [strname, setStrname] = useState("");
  const [email, setEmail] = useState("");
  const [BuildingNo, setBuildingNo] = useState("");
  const [des, setDes] = useState("");
  const [turnover, setTurnOver] = useState("");
  const [noofemployee, setNoofEmployee] = useState("");
  const [location, setLocation] = useState("");
  const [leadsource1, setLeadscource1] = useState("");
  const [leadindustry1, setLeadindustry1] = useState("");
  const [jobtitle1, setJobtitle1] = useState("");
  const [sal, setSal] = useState("");
  const [Decissionmaker, setDecissionmaker] = useState("");
  const [seriesValue, setSeriesValue] = useState("");
  const [LeadId, setLeadId] = useState("");

  // For Validation Purpose
  const validationSchema = yup.object().shape({
    fname: yup.string().required("Lead First Name is required"),
    lname: yup.string().required("Lead Last Name is required"),
    cname: yup.string().required("Company Name is Required"),
    phone: yup.string().required("Mobile Number is required"),
    // url: yup.string().required("Website  is required"),
    selectedCity1: yup.string().required("City is required"),
    selectedCountry1: yup.string().required("Country is Required"),
    selectedState1: yup.string().required("State is Required"),
    areaname: yup.string().required("Area Name is required"),
    strname: yup.string().required("Street Name is required"),
    email: yup.string().required("Email is required"),
    BuildingNo: yup.string().required("Buildingt Name is required"),
    // des: yup.string().required("Description is required"),
    // location: yup.string().required("Location is required"),
    // leadtype: yup.string().required("Lead Type is Required"),
    Decissionmaker: yup.string().required("Decission is required"),
    jobtitle1: yup.string().required("Job Tittle is Required"),
    leadsource1: yup.string().required("Lead Source is Required"),
    leadindustry1: yup.string().required("Lead Industry is Required"),
    // leadstatus1: yup.string().required("Lead Industry is Required"),
    turnover: yup.string().required("Lead Company Turn Over is Required"),
    noofemployee: yup.string().required("Lead No Of Employees is Required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const onsubmit = async () => {
    var updatepayload = {
      LeadId: seriesValue === "Manual" && LeadId,
      Salutation: sal,
      LeadFirstName: fname,
      LeadLastName: lname,
      CompanyName: cname,
      PhoneNo: phone,
      EmailId: email,
      WebsiteURL: url,
      Lead_Status: leadStatusName,
      LifeCycleStage: leadCycleStage,
      BuildingDoorNo: BuildingNo,
      StreetName: strname,
      AreaName: areaname,
      City: selectedCity1,
      State: selectedState1,
      Country: selectedCountry1,
      LeadSource: leadsource1,
      Lead_Industry: leadindustry1,
      Job_Title: jobtitle1,
      LeadLocation: location,
      LeadCompanyTurnOver: parseInt(turnover),
      LeadNoOfEmployees: noofemployee,
      Decissionmaker: Decissionmaker,
      LeadMLQuestions: {},
      LeadSLQuestions: {},
      LeadDescription: des,
      LeadType: leadtype,
      LeadMLQStatus: true,
      LeadSLQStatus: true,
      LeadGrowth: "Down",
      LeadQualifyStatus: true,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };

    console.log("payload", updatepayload);

    try {
      const res = await axios.post(
        `${BASE_URL}/user/UserQualifiedLeadsCRUD/`,
        updatepayload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("res.data", res.data);
      setOpenAddLead("leads");
      // getQualifiedDataListsRefetch(1);
      if (tabValue === 0) {
        getAllData(1);
      } else if (tabValue === 1) {
        getQualifiedData(1);
      }
      toast.success("Created successfully", {
        position: "top-right",
        autoClose: 100,
      });
    } catch (error) {
      if (error.response) {
        const errorDetails = error.response.data;

        if (errorDetails.PhoneNo) {
          toast.error("Mobile number already exists", {
            position: "top-right",
            autoClose: 100,
          });
        }

        if (errorDetails.EmailId) {
          toast.error("Email ID already exists", {
            position: "top-right",
            autoClose: 100,
          });
        }

        if (error.response.status === 403) {
          const errorMessage =
            errorDetails.details || "You don't have permission to do it";
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        }
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  const LeadDropdown = [
    "From Contact",
    "From Web",
    "From SocialMedia",
    "From Campaign",
  ];

  const DecisionDropdown = ["Yes", "No"];

  const [jobtitle, setJobtitle] = useState([]);
  const [leadstatus, setLeadStatus] = useState([]);
  const [leadindustry, setLeadindustry] = useState([]);
  const [leadsource, setLeadscource] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  useEffect(() => {
    if (getUserLoopUp) {
      setLeadindustry(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type") || []
      );
      setLeadStatus(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Status") || []
      );
      setJobtitle(
        getUserLoopUp?.find((op) => op?.Lookupname === "Job Title") || []
      );
      setLeadscource(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Source") || []
      );
    }
  }, [getUserLoopUp]);

  useEffect(async () => {
    const series = await fetchTransactionSeries("Leads");
    console.log("Leads series", series);
    setSeriesValue(series);
  }, []);

  const cancelFunc = () => {
    if (
      Boolean(fname?.trim()) ||
      Boolean(lname?.trim()) ||
      Boolean(cname?.trim()) ||
      Boolean(phone) ||
      Boolean(url?.trim()) ||
      Boolean(leadtype?.trim()) ||
      Boolean(selectedCountry1?.trim()) ||
      Boolean(selectedState1?.trim()) ||
      Boolean(selectedCity1?.trim()) ||
      Boolean(areaname?.trim()) ||
      Boolean(strname?.trim()) ||
      Boolean(email?.trim()) ||
      Boolean(BuildingNo?.trim()) ||
      Boolean(des?.trim()) ||
      Boolean(turnover) ||
      Boolean(noofemployee) ||
      Boolean(location?.trim()) ||
      Boolean(jobtitle1?.trim()) ||
      Boolean(leadsource1?.trim()) ||
      Boolean(leadindustry1?.trim()) ||
      Boolean(Decissionmaker === true || Decissionmaker === false) ||
      Boolean(leadCycleStage?.trim()) ||
      Boolean(leadStatusName?.trim())
    ) {
      setSave("create");
    } else {
      setOpenAddLead("leads");
    }
  };

  return (
    <>
      <Typography sx={[dialogFontSize, { mb: 3 }]}>Create Lead</Typography>

      <Box
        sx={{
          bgcolor: "white",
          padding: { xs: 1, md: 4 },
          borderRadius: "5px",
          border: "1px solid lightgray",
        }}
      >
        <form onSubmit={handleSubmit(onsubmit)}>
          <JumboScrollbar
            autoHeight={true}
            autoHeightMin={AutoHeight(1.7)}
            autoHideTimeOut={4000}
          >
            <Grid container spacing={2}>
              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography sx={[input_label]}>
                    Lead Id{" "}
                    <span className="required">
                      {seriesValue == "Automatic" ? "" : "*"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    className={`col-12 input-box2`}
                    id="outlined-basic"
                    placeholder="Lead Id"
                    value={LeadId}
                    onChange={(e) => {
                      setLeadId(e.target.value);
                    }}
                    disabled={seriesValue == "Automatic" ? true : ""}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    First Name <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    className={`col-12 input-box2  ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    {...register("fname")}
                    placeholder="Lead First Name"
                    value={fname}
                    onChange={(e) => {
                      setFname(e.target.value);
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.fname?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Last Name <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("lname")}
                    className={`col-12 input-box2 ${
                      errors.lname ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead Last Name"
                    value={lname}
                    onChange={(e) => {
                      setLname(e.target.value);
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.lname?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Job Title <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("jobtitle1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value || value === ""
                    }
                    value={jobtitle1}
                    options={jobtitle?.Values ?? []}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setJobtitle1(newValue?.value);
                      setValue("jobtitle1", newValue?.value);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "10px",
                        }}
                        placeholder="Job Title"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.jobtitle1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Lead Status <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    options={[
                      { status: "Open" },
                      { status: "In Progress" },
                      { status: "Nurture" },
                      { status: "Droped" },
                      { status: "Hot" },
                      { status: "Warm" },
                      { status: "Cool" },
                    ]}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.status) {
                        return option?.status;
                      }
                      return "";
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.status === value || value === ""
                    }
                    value={leadStatusName}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setleadStatusName(newValue?.status);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "10px",
                        }}
                        placeholder="Lead Status"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.jobtitle1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Life Cycle Stage <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    options={[
                      { status: "Contact" },
                      { status: "Subscriber" },
                      { status: "Lead" },
                      { status: "Opportunity" },
                      { status: "Customer" },
                      { status: "Demographic" },
                    ]}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.status) {
                        return option?.status;
                      }
                      return "";
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.status === value || value === ""
                    }
                    value={leadCycleStage}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setleadCycleStage(newValue?.status);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "10px",
                        }}
                        placeholder="Cycle Stage"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.jobtitle1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Company Name <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("cname")}
                    className={`col-12 input-box2  ${
                      errors.cname ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Company Name"
                    onChange={(e) => {
                      setCname(e.target.value);
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.cname?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Phone Number <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("phone")}
                    className={`col-12 input-box2 ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Phone No"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.phone?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Email Id <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("email")}
                    className={`col-12 input-box2 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Email Id"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.email?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Building Name / No <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("BuildingNo")}
                    className={`col-12 input-box2  ${
                      errors.BuildingNo ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Building DoorNo"
                    value={BuildingNo}
                    onChange={(e) => {
                      setBuildingNo(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",

                        width: "100%",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.BuildingNo?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Street Name <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("strname")}
                    className={`col-12 input-box2 ${
                      errors.strname ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Street Name"
                    value={strname}
                    onChange={(e) => {
                      setStrname(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",

                        width: "100%",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.strname?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Area Name <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("areaname")}
                    className={`col-12 input-box2  ${
                      errors.BuildingNo ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Area Name"
                    value={areaname}
                    onChange={(e) => {
                      setAreaName(e.target.value);
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.areaname?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Country <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("selectedCountry1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    options={Country.getAllCountries()}
                    value={selectedCountry1}
                    onChange={(e, newValue) => {
                      setValue("selectedCountry1", newValue?.name);
                      console.log("newValue", newValue);
                      setSelectedCountry(newValue);
                      setSelectedCountry1(newValue?.name);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Country" />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.selectedCountry1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    State <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("selectedState1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    value={selectedState1}
                    options={State.getStatesOfCountry(selectedCountry?.isoCode)}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setSelectedState(newValue);
                      setValue("selectedState1", newValue?.name);
                      setSelectedState1(newValue?.name);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value || value === ""
                    }
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "5px",
                          fontWeight: 300,
                          width: "100%",
                        }}
                        placeholder="State"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.selectedState1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    City <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("selectedCity1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    options={City.getCitiesOfState(
                      selectedState?.countryCode,
                      selectedState?.isoCode
                    )}
                    value={selectedCity1}
                    onChange={(e, newValue) => {
                      setValue("selectedCity1", newValue?.name);
                      console.log("newValue", newValue);
                      setSelectedCity(newValue);
                      setSelectedCity1(newValue?.name);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="City" />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.selectedCity1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Source <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("leadsource1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value || value === ""
                    }
                    value={leadsource1}
                    options={leadsource?.Values ?? []}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setLeadscource1(newValue?.value);
                      setValue("leadsource1", newValue?.value);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "10px",
                        }}
                        placeholder="Lead Source"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.leadsource1?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Company Turn Over <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("turnover")}
                    className={`col-12 input-box2 ${
                      errors.turnover ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead CompanyTurn Over"
                    name="turnover"
                    value={turnover}
                    onChange={(e) => {
                      setTurnOver(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",

                        width: "100%",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.turnover?.message}</Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Decission maker <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("Decissionmaker")}
                    id="outlined-basic"
                    options={DecisionDropdown ?? []}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      if (newValue === "Yes") {
                        console.log("Check");
                        setDecissionmaker(true);
                        setValue("Decissionmaker", true);
                      } else {
                        setDecissionmaker(false);
                        setValue("Decissionmaker", false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                        }}
                        placeholder="Decission maker"
                      />
                    )}
                  ></Autocomplete>
                  <Div style={{ color: "red" }}>
                    {errors.Decissionmaker?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    No Of Employees <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <TextField
                    {...register("noofemployee")}
                    className={`col-12 input-box2 ${
                      errors.noofemployee ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead No Of Employees"
                    name="noofemployee"
                    value={noofemployee}
                    onChange={(e) => {
                      setNoofEmployee(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "100%",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.noofemployee?.message}
                  </Div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                md={6}
                // lg={4}
                sx={{ py: 0.5, px: 4 }}
              >
                <Grid item xs={12} sm={3.5}>
                  <Typography
                    //   id="Typography-root"
                    sx={[input_label]}
                  >
                    Lead Industry <span className="required">*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8.5}>
                  <Autocomplete
                    className="col-12 search-select3"
                    {...register("leadindustry1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    value={leadindustry1}
                    options={leadindustry?.Values ?? []}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setLeadindustry1(newValue?.value);
                      setValue("leadindustry1", newValue?.value);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "10px",
                        }}
                        placeholder="Lead Industry"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.leadindustry1?.message}
                  </Div>
                </Grid>
              </Grid>
            </Grid>
          </JumboScrollbar>
          <Grid container mt={2} align="center" spacing={2} pb={2}>
            <Grid item align="right" xs={6}>
              <CustomButton label="Save" type="submit" Icon="saveIcon" />
            </Grid>
            <Grid item align="left" xs={6}>
              <Cancelbutton onClick={cancelFunc} />
            </Grid>
          </Grid>
        </form>
      </Box>
      {/* close dialog box */}
    </>
  );
};

export default CreateQualifiedLeads;
