// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-logo{
    /* border-radius: 10px; */
    padding: 3px;
}



.short-logo{
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/Logo/Logo.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;;;;AAIA;IACI,mBAAmB;AACvB","sourcesContent":[".full-logo{\n    /* border-radius: 10px; */\n    padding: 3px;\n}\n\n\n\n.short-logo{\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
