const {
  useTerritoryList,
  useTotalSalesTarget,
  useUserList,
  useGetSalesPersonTarget,
} = require("../TerritoryAPI");

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SalesTargetLists = () => {
  const { territoryList, isLoading } = useTerritoryList();
  const { SalesTargetList } = useTotalSalesTarget();
  const { salesPersonTarget } = useGetSalesPersonTarget();

  const buildCustomTerritoryTree = (territories) => {
    const territoryMap = new Map();
    // Create a map of territories by ID and initialize children array
    territories?.forEach((territory) => {
      // Initialize children array for the territory
      territory.children = [];

      // Find the sales target for the territory
      const salesTarget = SalesTargetList?.find(
        (sales) => sales?.Territery_Id === territory?.id
      );
      if (salesTarget) {
        territory.Target = salesTarget?.Target;
      }

      // Process assigned users and their targets
      if (territory?.Assign_Users && territory?.Assign_Users?.length > 0) {
        territory?.Assign_Users?.forEach((user, index) => {
          const userTargetData = salesPersonTarget
            ?.find((sales) => sales?.Territery_Id === territory?.id)
            ?.Sales_Person_Target?.Target?.find(
              (userTarget) => userTarget?.username === user
            );

          let monthlyTargets = {};

          // console.log(
          //   "User Target Data: ",
          //   monthlyTargets,
          //   salesPersonTarget,
          //   userTargetData
          // );

          Months?.forEach((month, monthIndex) => {
            monthlyTargets[month] = userTargetData
              ? userTargetData?.values[monthIndex]
              : 0;
          });

          // Add the user target data to the territory's children
          territory.children.push({
            salesperson: user,
            id: territory?.AssignSalesPersons[index],
            Target: monthlyTargets,
          });
        });
      }

      // Add the processed territory to the territory map
      territoryMap.set(territory.id, territory);
    });

    const roots = [];

    // Link each territory to its parent
    territories?.forEach((territory) => {
      roots.push(territory);
    });

    // Remove empty children arrays recursively
    const cleanTerritories = (territory) => {
      if (territory?.children?.length === 0) {
        delete territory.children;
      } else {
        territory.children?.forEach((child) => cleanTerritories(child));
      }
    };

    roots?.forEach((root) => cleanTerritories(root));
    console.log("Rootss : ", SalesTargetList, roots);
    return roots;
  };

  const datas = {
    TerritoryName: territoryList?.TerritoryName,
    data: buildCustomTerritoryTree(territoryList?.data),
  };
  return { datas, isLoading };
};

export default SalesTargetLists;
