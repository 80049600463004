// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feed1 {
    flex: 0.6 1;
    margin: 0 20px;
    width: 600px;
}

.feed_inputContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    /* background-color: whitesmoke; */
}

.feed_input {
    border: 1px solid lightgray;
    border-radius: 30px;
    display: flex;
    padding: 10px;
    color: gray;
    padding-left: 15px;
    min-width: 500px;
    margin-top: 20px;
}

.feed_input>form {
    display: flex;
    width: 100%;
}

.feed_input>form>input {
    border: none;
    flex: 1 1;
    margin-left: 10px;
    outline-width: 0;
    font-weight: 600;
    height: auto !important;
    outline: none;
    background-color: whitesmoke;
}

.feed_input>form>button {
    /* display: none; */
}

.feed_inputOptions {
    display: flex;
    justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/InstagramAPI/insta.css"],"names":[],"mappings":"AAAA;IACI,WAAS;IACT,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,6BAA6B;IAC7B,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,SAAO;IACP,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC","sourcesContent":[".feed1 {\n    flex: 0.6;\n    margin: 0 20px;\n    width: 600px;\n}\n\n.feed_inputContainer {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: space-evenly;\n    padding-bottom: 20px;\n    border-radius: 10px;\n    margin-bottom: 20px;\n    /* background-color: whitesmoke; */\n}\n\n.feed_input {\n    border: 1px solid lightgray;\n    border-radius: 30px;\n    display: flex;\n    padding: 10px;\n    color: gray;\n    padding-left: 15px;\n    min-width: 500px;\n    margin-top: 20px;\n}\n\n.feed_input>form {\n    display: flex;\n    width: 100%;\n}\n\n.feed_input>form>input {\n    border: none;\n    flex: 1;\n    margin-left: 10px;\n    outline-width: 0;\n    font-weight: 600;\n    height: auto !important;\n    outline: none;\n    background-color: whitesmoke;\n}\n\n.feed_input>form>button {\n    /* display: none; */\n}\n\n.feed_inputOptions {\n    display: flex;\n    justify-content: space-evenly;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
