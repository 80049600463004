import {
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  List,
  Grid,
  ListItem,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
  Stack,
  Pagination,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Popover,
  Select,
  TableContainer,
  TablePagination,
  Box,
  Tabs,
  Tab,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import * as yup from "yup";
import { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactEdit from "./ContactEdit";
import { BASE_URL, Tenantname } from "app/services/auth-services";
import useStyles from "@jumbo/vendors/sweetalert2/style";
import JumboIconify from "@jumbo/components/JumboIconify/index";
import { TabContext, TabPanel } from "@mui/lab";
import {
  AntTab,
  AntTabs,
  AvatarFont,
  ButtonStyle,
  CRMTableHead,
  Colors,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  avatarColors,
  avatarLightColors,
  componentTitle,
  customHeight,
  deletedWarnigMsg,
  dialogFontSize,
  dialogTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { IoCallOutline } from "react-icons/io5";
import SearchBar from "app/pages/widgets/SearchField";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import {
  AvatarCircle,
  Cancelbutton,
  DialogBox,
  Closebutton,
  Confirmbutton,
  CreateButton,
  CustomButton,
  DeletedIcon,
  EditIcon,
  SaveButton,
  Dragabledialog,
  Customgrid,
  SelectDropdown,
  Div,
  avatarColorFun,
  CustomIcons,
  errorFilter,
  formatDate,
  fetchTransactionSeries,
} from "app/pages/widgets/StylesComponents";
import {
  CallEnd,
  KeyboardDoubleArrowUp,
  Mic,
  MicOff,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import moment from "moment";
import { AutoHeight, UserId } from "app/redux/auth/localStorage";
import { GrPowerReset } from "react-icons/gr";
import { BiFilterAlt } from "react-icons/bi";
import { useQuery, useQueryClient } from "react-query";
import NotesModel from "app/pages/contacts/extra-components/NotesModel";
import ActivityModel from "app/pages/contacts/extra-components/ActivityModel";
import AppointmentModel from "app/pages/contacts/extra-components/AppointmentModel";
import ComposeEmail from "app/pages/contacts/extra-components/ComposeEmail";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { TiEdit } from "react-icons/ti";
import { FaGear } from "react-icons/fa6";
import { MdOutlineNoteAdd } from "react-icons/md";
import { RiMailSendLine } from "react-icons/ri";
import { TbActivity } from "react-icons/tb";
import TodayIcon from "@mui/icons-material/Today";
import { useCallback } from "react";
import "../dashboard.css";

const contacts = [
  {
    industry_type: "Product Bsaed",
  },
  {
    industry_type: "Service Bsaed",
  },
];

const AllContactsList = ({ scrollHeight }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const files = acceptedFiles.map((file) => (
    <ListItem selected key={file.path} sx={{ width: "auto", mr: 1 }}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));
  const matches = useMediaQuery("(min-width:600px)", { noSsr: true });
  const queryClient = useQueryClient();
  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const navigate = useNavigate();
  const location = useLocation();
  // const { datas1 } = useNotification();
  const [anchorEl, setAnchorEl] = useState(null);
  const [Loading, setLoading] = useState(false);

  const [dealId, setDealId] = useState("");
  const [showAllDeals, setShowAllDeals] = useState("deals");
  const [fadeEdit, setFadeEdit] = useState(false);
  const [openAnchor, setOpenAnchor] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [openDeal, setOpenDeal] = useState(false);
  const [openEditDeal, setOpenEditDeal] = useState(false);
  const [update, setUpdate] = useState({});
  const [number, setNumber] = useState(1);
  const [showFilter, setShowFilter] = useState(false);

  const [openNotes, setOpenNotes] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [save, setSave] = useState(false);

  const [contry, setContry] = useState("");
  const [cty, setCty] = useState("");
  const [indus, setIndus] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setToDate] = useState("");
  const [statefil, setStatefil] = useState("");
  const [seriesValue, setSeriesValue] = useState("");

  const [isSortName, setIsSortName] = useState("");

  const classes = useStyles();

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Company_Name", label: "Company Name", bool: true },
    { sort: "Phoene", label: "Phone", bool: true },
    { sort: "Deal_Name", label: "Deal Name", bool: true },
    { sort: "Amount", label: "Deal Amount", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "Deal_Probablity", label: "Deal Probability", bool: true },
    { sort: "Deal_Stage", label: "Deal Stage", bool: true },
    { sort: "Contact_Person", label: "Last Contacted By", bool: true },
  ]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOverviewContact = (data) => {
    navigate(`/app/dealcontacts/overview/${data?.id}`);
    // setOpenOverviewContact(true);
    setShowAllDeals(false);
  };

  const dialogFilter = (event) => {
    setShowFilter(event.currentTarget);
    setPage(1);
  };

  const closeFilter = () => {
    setShowFilter(null);
  };

  const RefreshData = () => {
    setfilter(false);
    setfilterDealStage("");
    setfilterDealOwner("");
    setfilterDealType("");
    setFromdate("");
    setToDate("");
    setfilterDealname("");
    setPage(1); // Reset the page to 1

    // Depending on the tab value, call the appropriate data fetching function
    if (tabValue === 0 && filter == false) {
      getData(1);
    } else if (tabValue === 1 && filter == false) {
      getMyData(1);
    }

    setShowFilter(false);
  };

  // const countries = Country.getAllCountries();
  // //  console.log("countries",countries)

  //Function for the Add Contact

  //Initialize the useState For add Contact

  const [name, setName] = useState(true);
  const [phone, setPhone] = useState(false);
  const [dealAmount, setDealAmount] = useState(true);
  const [dealProbability, setDealProbability] = useState(true);
  const [dealStage, setDealStage] = useState(true);
  const [LastContactAt, setLastContactAt] = useState(true);
  const [LastContactBy, setLastContactBy] = useState(false);
  const [colors, setColors] = useState(["#f4a4d1", "#ddda1f"]);
  const [deleteSave, setDeleteSave] = useState(false);
  const [lookupDatas, setLookupDatas] = useState([]);
  const [leadSource, setLeadSource] = useState([]);

  const [userNameLists, setUserNameLists] = useState([]);

  const [addField, setAddField] = useState({
    dealName: "",
    dealType: "",
    accId: "",
    accName: "",
    contactName: "",
    leadName: "",
    leadSource: "",
    dealAmount: "",
    turnAround: "",
    dealStage: "",
    dealOwner: "",
    dealProbability: "",
    dealNote: "",
    leadId: "",
  });

  const [filterDealname, setfilterDealname] = useState("");
  const [filterDealType, setfilterDealType] = useState("");
  const [filterDealOwner, setfilterDealOwner] = useState("");
  const [filterDealStage, setfilterDealStage] = useState("");

  const [datas, setDatas] = useState([]);
  const [errors, setErrors] = useState({});

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    mail: yup.string().required("Mail is required"),
    mobilenumber: yup
      .string()
      .required("Mobile Number is Required")
      .min(9, "Minium 9 digits Required")
      .max(15, "Maximum 15 digits Required"),
    code: yup.string().required("Mobile Code is required"),
    orgname: yup.string().required("Organization Name is required"),
    selectedCity: yup.string().required("City is required"),
    selectedCountry: yup.string().required(" Country is Required"),
    selectedState: yup.string().required("State is Required"),
    industry: yup.string().required("Industry is required"),
  });

  // tabs
  const [tabValue, setTabValue] = useState(0);
  const [filter, setfilter] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { error },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const clear = () => {
    setAddField({
      dealName: "",
      dealType: "",
      accId: "",
      accName: "",
      contactName: "",
      leadName: "",
      leadSource: "",
      dealAmount: "",
      turnAround: "",
      dealStage: "",
      dealProbability: "",
      dealNote: "",
      leadId: "",
    });
  };

  const nameReg = /^[a-zA-Z0-9 .,-]{1,}$/;

  // Get Contact List

  const option = [
    "Connected",
    "Customer Missed",
    "Voice mail",
    "Busy",
    "Disconnect",
    "Missed call",
    "Call waiting",
    "Confidence call",
    "Call attended",
    "Do Not Disturb Turned On",
    "Call Not Picked",
    "Called Back",
  ];

  const contaxtoptions = [
    "Solving Problem",
    "Making Decision",
    "Solution Achieved",
  ];
  const callconvoptions = [
    "Casual",
    "Tension",
    "Not Interested",
    "Friendly",
    "Confidancial",
  ];

  const BusyHroptions = ["Morning", "Evening", "After Noon"];

  const [showDial, setShowDial] = useState(false);
  const [calldata, setcalldata] = useState();
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [notes, setNotes] = useState("");
  const [statusc, setStatusc] = useState("");
  const [nextdate, setNextDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [callcontext, setCallContext] = useState("");
  const [busy, setBusy] = useState("");
  const [callconvstatus, setCallconvstatus] = useState("");
  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const [loading, setloading] = useState(false);

  const hasMounted = useRef(false);

  const filterSubmit = async () => {
    console.log("fromdate", todate, fromdate);

    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    setloading(true);

    const payload = {
      Deal_Name: filterDealname,
      Deal_Stage: filterDealStage,
      Deal_Owner: filterDealOwner,
      Deal_Type: filterDealType,
      Created_Date: [fromdate, todate],
      page: page,
    };

    try {
      const res = await axios.post(`${BASE_URL}/user/DealFilter/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("filterdata", res.data);
      if (res?.data?.results) {
        setfilter(true);

        setDatas((prevDatas) => {
          return page === 1
            ? res.data.results
            : Array.from(new Set([...prevDatas, ...res.data.results]));
        });

        setCount(res.data.count);
      }

      // setDatas(res?.data?.results || []);
      setShowFilter(false);
    } catch (error) {
      console.error("Error filtering data:", error);
    } finally {
      setloading(false);
    }
  };

  const getData = async (page) => {
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    setloading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/user/DealAdditional/?Is_Deleted=False&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setDatas((prevDatas) => {
        const updatedDatas = new Set([...prevDatas, ...res?.data?.results]);
        return Array.from(updatedDatas);
      });

      setCount(res?.data?.count);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setloading(false);
    }
  };

  const getMyData = async (page) => {
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    setloading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/user/DealMyAdditional/?Is_Deleted=False&Created_By=${UserId}&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setDatas((prevDatas) => {
        const updatedDatas = new Set([...prevDatas, ...res?.data?.results]);
        return Array.from(updatedDatas);
      });
      setCount(res?.data?.count);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setloading(false);
    }
  };

  // get lookup datas
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  const onsubmit = async () => {
    let errors = errorFilter(addField);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      let payload = {
        Deal_Id: seriesValue === "Manual" && dealId,
        Deal_Name: addField?.dealName,
        Deal_Type: addField?.dealType,
        Deal_Owner: addField?.dealOwner,
        Account_Id: addField?.accId,
        Account_Name: addField?.accName,
        Lead_Id: addField?.leadId,
        Lead_Name: addField?.leadName,
        // "Lead_Source": addField?.leadSource,
        Contact_Person: addField?.contactName,
        Amount: parseInt(addField?.dealAmount),
        MinimumTurnOver: parseInt(addField?.turnAround),
        Deal_Stage: addField?.dealStage,
        Deal_Probablity: addField?.dealProbability,
        Deal_Note: addField?.dealNote,
        Active_Flag: false,
        Is_Deleted: false,
        Created_Date: new Date().toISOString().split("T")[0],
        Updated_Date: new Date().toISOString().split("T")[0],
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };

      console.log("Payloads---", payload);
      try {
        await axios
          .post(`${BASE_URL}/user/DealCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            //  console.log(res.data);
            toast.success("Created successfully", {
              position: "top-right",
              autoClose: 5000,
            });
            // setOpenAddDeal(false);
            setOpenDeal(false);
            setDatas([]);
            clear();
            setPage(1); // Reset the page to 1

            // Depending on the tab value, call the appropriate data fetching function
            if (tabValue === 0) {
              getData(1);
            } else {
              getMyData(1);
            }
            // DealsListsRefetch(page);
            queryClient.invalidateQueries("getMyActivityLists");
            queryClient.invalidateQueries("getLineChartsLists");
            queryClient.invalidateQueries("getDealCountsLists");
            queryClient.invalidateQueries("getPyramidLists");
            // pipeline
            queryClient.invalidateQueries("pipeLineChartLists");
            setErrors({});

            let editUser = localStorage.getItem("Username");
            let histotypayload = {
              Document_Id: res?.data?.Deal_Id,
              Updated_Person: `${editUser} Created this Deal.`,
              Updated_Time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
              Module_Name: "Deal",
              Deal_Id: res?.data?.id,
              Updated_By: parseInt(localStorage.getItem("UserId")),
            };
            console.log("histotypayload", histotypayload);
            axios
              .post(`${BASE_URL}/user/DealHistorycrud/`, histotypayload, header)
              .then((res) => {
                console.log("Result", res.data);
              })
              .catch((res) => {
                console.log(res.message);
              });
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      }
    }
  };

  // Search Functionality

  const SearchData = (e) => {
    console.log(
      "handleSearchange:",
      e.target.value,
      Boolean(e.target.value !== "")
    );
    Boolean(e.target.value !== "")
      ? handleSearch(e.target.value)
      : tabValue == 0
      ? getData(page)
      : getMyData(page);
  };

  const handleSearch = (search) => {
    axios
      .get(`${BASE_URL}/user/DealSearch/?search=${search}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log(res.data);
        setDatas(res.data || []);
        setCount(res?.data?.length || 0);
      });
  };

  // Delete the Function

  const Deletedata = (data) => {
    axios
      .delete(`${BASE_URL}/user/DealCRUD/?id=${update?.id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log(res.data);
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 5000,
        });
        // DealsListsRefetch();
        queryClient.invalidateQueries("getMyActivityLists");
        queryClient.invalidateQueries("getLineChartsLists");
        queryClient.invalidateQueries("getDealCountsLists");
        queryClient.invalidateQueries("getPyramidLists");
        // pipeline
        queryClient.invalidateQueries("pipeLineChartLists");
        // handleCloseAction();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      });
  };

  const handleUpdate = (data) => {
    //  console.log("data",data);
    setUpdate(data);
  };

  const handleReset = () => {
    setValue("selectedCountry", "");
    setValue("selectedState", "");
    setValue("selectedCity", "");
    setValue("industry", "");
  };

  //  For Dialog Popup
  const [showDialog, setShowDialog] = useState(false);
  // For Intilization for the Dialog

  //  For Pagination Table
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = datas.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // For jetsi Calling

  const handleOpenCallClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddDeal = () => {
    setOpenDeal(true);
  };

  // Meeting Link

  const meetingLink = async (data) => {
    try {
      let payload = {
        RecevierMail: data?.Email_Id,
        Contact_Id: data?.id,
        UserId: localStorage.getItem("UserId"),
      };
      //  console.log("payload",payload);
      await axios
        .post(`${BASE_URL}/user/CRMUserContactMeetingCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          //  console.log(res.data);
          toast
            .success("Meeting Link Sent successfully", {
              position: "top-right",
              closeButton: true, // Allow manual close
              closeOnClick: true,
            })
            .catch((error) => {
              if (error.response && error.response.status === 403) {
                const errorMessage =
                  error.response.data.details ||
                  "You don't have permission to do it";
                console.log(errorMessage);
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 5000,
                });
              } else {
                toast.error(`${error}`, {
                  position: "top-right",
                  autoClose: 5000,
                });
              }
            });
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  // get accounts
  const [accounts, setAccounts] = useState([]);

  const headers = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  //  get accouts list
  const getAccounts = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/AccountAdditional/?Is_Deleted=False`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("get Accounts List : ", res.data?.results);
      if (res.data.results) {
        // setAccounts(res.data?.results);
        return res?.data?.results;
      }
    } catch (error) {
      console.error(error);
    }
  };
  // get contact persons
  const [contactPersons, setContactPersons] = useState([]);

  const getContactPersons = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/ContactAdditional/?Is_Deleted=False`,
        headers
      );
      console.log("get Contact Persons List : ", res.data?.results);
      if (res.data?.results) {
        return res.data?.results;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // get leads
  const [leads, setLeads] = useState([]);

  // get leads
  const getLeads = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/UserQualifiedLeadsAdditional/?Is_Deleted=False`,
        headers
      );
      console.log("get Leads List : ", res.data?.results);
      if (res.data.results) {
        // setLeads(res.data?.results);
        return res?.data?.results;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // add deals
  const getDealAlldata = () => {
    // setOpenAddDeal(true);
    getAccounts();
    getContactPersons();
    getLeads();
    // getDealStage()
  };

  // get Lists All Data
  // const {
  //   data: DealsLists,
  //   isLoading: DealsListsLoading,
  //   isError: DealsListsError,
  //   refetch: DealsListsRefetch,
  // } = useQuery(
  //   ["DealsLists", tabValue, page],
  //   async () => {
  //     if (tabValue === 0) {
  //       return await getData(page);
  //     } else if (tabValue === 1) {
  //       return await getMyData(page);
  //     }
  //   },
  //   { staleTime: Infinity }
  // );

  // leads
  const {
    data: getLeadsLists,
    isLoading: getLeadsListsLoading,
    isError: getLeadsListsError,
    refetch: getLeadsListsRefetch,
  } = useQuery(["getQualifiedDataLists"], getLeads, { staleTime: Infinity });

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  const {
    data: getContactPerson,
    isLoading: getContactPersonLoading,
    isError: getContactPersonError,
    refetch: getContactPersonRefetch,
  } = useQuery(["getContactPersonLists"], getContactPersons, {
    staleTime: Infinity,
  });

  const {
    data: getAccountsLists,
    isLoading: getAccountsListsLoading,
    isError: getAccountsListsError,
    refetch: getAccountsListsRefetch,
  } = useQuery(["getAccountsLists"], getAccounts, { staleTime: Infinity });

  // useEffect(() => {
  //   if (DealsLists) {
  //     console.log("DealsLists?.results", DealsLists)
  //     setDatas(DealsLists?.results || []);
  //     setCount(DealsLists?.count || 0);
  //   }
  // }, [DealsLists]);

  // useEffect(() => {
  //   if (tabValue === 0 || tabValue === 1) {
  //     DealsListsRefetch();
  //   }
  // }, [tabValue]);

  const [knowlaritydatas, setKnowlaritydatas] = useState([]);
  const [agentdetails, setagentdetails] = useState([]);

  const getKnowlarityConfiguration = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res?.data", res.data);
        setKnowlaritydatas(res?.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const getagentnumber = async (data) => {
    await axios
      .get(`https://kpi.knowlarity.com/Basic/v1/account/agent`, {
        headers: {
          // "Accept": "application/json",
          // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
          // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

          Accept: "application/json",
          "x-api-key": knowlaritydatas?.Header_Key,
          Authorization: knowlaritydatas?.API_Key,
        },
      })
      .then((res) => {
        console.log("agentres", res);
        const agentnum = res.data?.objects?.find(
          (item) => item?.phone === userDetails?.PhoneNo
        );
        // setagentdetails(agentnum)

        onGoingCallApi(data, agentnum);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  //  OnGoing Call API

  const onGoingCallApi = async (data, agentnum) => {
    let knopayload = {
      // k_number: "+918037125874",
      // agent_number: "+919344312970",
      // customer_number: "+918870791912",
      // caller_id: "+918040210001",
      // caller_name: datas?.Name,
      k_number: knowlaritydatas?.Knowlarity_Number,
      agent_number: agentnum?.country_code + agentnum?.phone,
      customer_number: "+91" + data?.Account_Id?.Phone_Number,
      caller_id: knowlaritydatas?.Knowlarity_CallerId,
      caller_name: data?.Account_Name,
    };
    console.log("knopayload", knopayload);
    await axios
      .post(
        `https://kpi.knowlarity.com/Basic/v1/account/call/makecall`,
        knopayload,
        {
          headers: {
            // "Accept": "application/json",
            // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
            // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

            Accept: "application/json",
            "x-api-key": knowlaritydatas?.Header_Key,
            Authorization: knowlaritydatas?.API_Key,
          },
        }
      )
      .then((res) => {
        console.log("callres", res);
        setShowDial(true);
        setcalldata(data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  console.log("agentdetails", agentdetails?.phone, agentdetails?.country_code);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/useradmin/UsersList/`, {
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Users List", response.data);
      // setUserNameLists(response.data);
      return response?.data;
    } catch (error) {
      console.log("Error in getting user list", error);
    }
  };

  const calllogs = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityCallLogsCRUD/?start_Date=2020-04-16&end_Date=&UserId=${localStorage.getItem(
          "UserId"
        )}&Module=Deal`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("calllogsresss", res.data);
        // setKnowlaritydatas(res?.data);
        // setcallogsdetails(res.data)
        const mynumberList = res?.data?.filter((item) =>
          item.Agent_Number.includes(userDetails?.PhoneNo)
        );

        const lastIndexData = mynumberList[0];
        console.log("lastIndexData", mynumberList, lastIndexData);

        setDuration(lastIndexData?.Call_Duration);

        if (lastIndexData?.Agent_Number?.includes("User Disconnected")) {
          setStatusc("User Disconnected");
        } else if (lastIndexData?.Agent_Number?.includes("Customer Missed")) {
          setStatusc("Customer Missed");
        } else {
          setStatusc("Connected");
        }

        setLoading(false);
        setAnchorEl(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    var payload = {
      Company_Name: calldata?.Account_Id?.Organization_Name,
      Receiver_Name: calldata?.Account_Name,
      Contact_Number: calldata?.Account_Id?.Phone_Number,
      Selected_Date: moment().format("YYYY-MM-DD"),
      Next_SheduledTime: nextdate,
      Call_Duration: duration,
      Call_Context: callcontext,
      Call_Con_Status: callconvstatus,
      Call_Busy_hour: busy,
      Activity_Type: "Call",
      Activity_relates: "Contact",
      Details: notes,
      Select_Status: statusc,
      Deal_Id: Number(calldata?.id),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };

    axios
      .post(`${BASE_URL}/user/CallcenterActivityCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("check", res);
        toast.success("Created successfully", {
          position: "top-right",
          autoClose: 5000,
        });
        setShowDial(false);
        handleClose();
        // for Updating the Contact List
        if (calldata?.No_of_Calls == null || "" || NaN) {
          var x = 1;
        } else {
          var x = parseInt(calldata?.No_of_Calls) + 1;
        }
        let payload1 = {
          id: Number(calldata?.id),
          Last_Call_Date: moment().format("YYYY-MM-DD"),
          Last_Called_By: userDetails && userDetails?.username,
          No_of_Calls: x,
        };
        console.log("check", payload1);
        axios
          .patch(`${BASE_URL}/user/ContactCRUD/`, payload1, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((re) => {
            console.log("res", re);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // For Updateing the History of Changes
        let editUser = localStorage.getItem("Username");
        let histotypayload = {
          Document_Id: res.data.CallCenter_Id,
          Updated_Person: `${editUser} Created this Contact Phone History.`,
          Module_Name: "Contact Phone History",
          Contact_Id: Number(calldata?.id),
          Updated_By: parseInt(localStorage.getItem("UserId")),
        };
        console.log("histotypayload", histotypayload);
        axios
          .post(`${BASE_URL}/user/Updaterecordscrud/`, histotypayload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("Result", res.data);
          })
          .catch((res) => {
            console.log(res.message);
          });
        // Create Task
        // CreateTask(editUser);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      });
  };

  const {
    data: getTerUserLists,
    isLoading: getTerUserListsLoading,
    isError: getTerUserListsError,
    refetch: getTerUserListsRefetch,
  } = useQuery(
    ["getTerUserLists"],
    fetchUserData, // Assuming 1 as the initial page number
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (getTerUserLists) {
      setUserNameLists(getTerUserLists);
    }
  }, [getTerUserLists]);

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas]?.sort((a, b) =>
      value === "A-Z"
        ? a?.Account_Name?.localeCompare(b?.Account_Name)
        : b?.Account_Name?.localeCompare(a?.Account_Name)
    );
    setDatas(sortedData);
    // console.log("sortedData", value, "Ascending", sortedData);
    setIsSortName(value);
  };

  useEffect(async () => {
    const series = await fetchTransactionSeries("Deal");
    console.log("series", series);
    setSeriesValue(series);
  }, []);

  useEffect(() => {
    if (Loading) {
      const timer = setTimeout(() => {
        calllogs();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [Loading]);

  // useEffect(() => {
  //   if (DealsLists) {
  //     // setDatas((prevDatas) => new Set([...prevDatas, ...(getContactsLists.results || [])]));
  //     setDatas((prevDatas) => {
  //       const updatedDatas = new Set([
  //         ...prevDatas,
  //         ...DealsLists?.results,
  //       ]);
  //       return Array.from(updatedDatas);
  //     });
  //     let Counts =
  //       DealsLists?.results?.length == 0
  //         ? datas?.length
  //         : DealsLists?.count || 0;
  //     setCount(Counts);
  //   }
  // }, [DealsLists]);

  useEffect(() => {
    if (getUserLoopUp) {
      let dealType = getUserLoopUp?.find(
        (item) => item?.Lookupname === "Deal Type"
      );
      let leadSource = getUserLoopUp?.find(
        (item) => item?.Lookupname === "Lead Source"
      );
      console.log("getLookupDatas ", getUserLoopUp);
      setLeadSource(leadSource?.Values);
      setLookupDatas(dealType?.Values);
    }
  }, [getUserLoopUp]);

  useEffect(() => {
    if (getContactPerson) {
      setContactPersons(getContactPerson?.results);
    }
  }, [getContactPerson]);

  useEffect(() => {
    getKnowlarityConfiguration();
  }, []);

  useEffect(() => {
    if (getAccountsLists) {
      setAccounts(getAccountsLists);
    }
  }, [getAccountsLists]);

  useEffect(() => {
    if (getLeadsLists) {
      setLeads(getLeadsLists);
    }
  }, [getLeadsLists]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100 &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      setPage(1);
    }
  }, []);

  useEffect(() => {
    setDatas([]);
    setPage(1);
  }, [tabValue]);

  useEffect(() => {
    if (tabValue === 0 && filter == false) {
      console.log("page", { page });

      getData(page);
    } else if (tabValue == 1 && filter == false) {
      console.log("page", { page });

      getMyData(page);
    } else if (filter == true) {
      console.log("page", { page });

      filterSubmit();
    }
  }, [page, tabValue, filter]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  console.log("updatessssssss", update);
  console.log("datas", { datas });

  return (
    <>
      <JumboContentLayoutMain>
        <ToastContainer />
        <Box>
          <Box
            sx={{
              display:
                showAllDeals === "deals" && location?.pathname === "/deals"
                  ? ""
                  : "none",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Box
                className="flex items-center justify-between flex-wrap"
                style={{
                  backgroundColor: "#efefef",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={[
                    componentTitle,
                    { color: Colors?.gray, fontWeight: 700 },
                  ]}
                >
                  Deal List
                </Typography>

                <Div className="flex items-center justify-between gap-2">
                  <SelectDropdown
                    className="search-select3"
                    defaultValue={"All Deals"}
                    onChange={(e, value) => {
                      setTabValue(value === "All Deals" ? 0 : 1);
                      console.log("value", value);
                    }}
                    options={["All Deals", "My Deals"]}
                  />

                  <SelectDropdown
                    className="search-select3"
                    defaultValue={"A-Z"}
                    onChange={sortData}
                    options={["A-Z", "Z-A"]}
                    width="70px"
                    clearButton={false}
                  />
                  {matches && (
                    <IconButton
                      onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    >
                      <FaGear style={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                </Div>
              </Box>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={10.5}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <IconButton
                    sx={{ color: Colors?.Mblue }}
                    onClick={dialogFilter}
                  >
                    <BiFilterAlt size={22} />
                  </IconButton>
                  <Typography
                    sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                  >
                    ( {count} ) Deals
                  </Typography>
                </Grid>
                {!matches && (
                  <Grid item xs={1} md={1}>
                    <IconButton
                      onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    >
                      <FaGear style={{ fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                )}

                {/* Filter popover  */}

                <Popover
                  open={Boolean(showFilter)}
                  anchorEl={showFilter}
                  onClose={closeFilter}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
                >
                  <Box>
                    <Div
                      sx={[
                        DisplayFlex,
                        CRMTableHead,
                        dialogTitle,
                        { px: 2, justifyContent: "space-between" },
                      ]}
                    >
                      <Typography sx={[dialogTitle]}>Filters</Typography>
                      <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                        <GrPowerReset size={16} />
                      </IconButton>
                    </Div>
                    <Div style={{ padding: "1rem" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Deal Name
                          </Typography>

                          <TextField
                            className={`col-12 input-box2`}
                            id="outlined-basic"
                            placeholder="Enter Deal Name"
                            value={filterDealname}
                            onChange={(e) => setfilterDealname(e.target.value)}
                            InputProps={{
                              style: {
                                borderRadius: "3px",
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Deal Type
                          </Typography>
                          {/* <Autocomplete
                            className=" col-12 search-select3"
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.name) {
                                return option?.name;
                              }
                              return "";
                            }}
                            value={statefil?.name}
                            options={
                              State.getStatesOfCountry(contry?.isoCode) || []
                            }
                            onChange={(e, newValue) => {
                              setStatefil(newValue);
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value || value === ""
                            }
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="State" />
                            )}
                          /> */}

                          <Autocomplete
                            className="search-select3 col-12"
                            // {...register("selectedCountry1")}
                            options={lookupDatas}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.value) {
                                return option?.value;
                              }
                              return "";
                            }}
                            value={filterDealType}
                            onChange={(e, newValue) => {
                              setfilterDealType(newValue?.value);
                            }}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Deal Type" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Deal Owner
                          </Typography>

                          <Typography sx={[FontStyle]}></Typography>
                          <Autocomplete
                            className="search-select3 col-12"
                            // {...register("selectedCountry1")}
                            options={userNameLists}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.username) {
                                return option?.username;
                              }
                              return "";
                            }}
                            value={filterDealOwner}
                            onChange={(e, newValue) => {
                              setfilterDealOwner(newValue?.username);
                            }}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Deal Stage" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Deal Stage
                          </Typography>

                          <Autocomplete
                            className="search-select3 col-12"
                            // {...register("selectedCountry1")}
                            options={[
                              { stage: "Deals Enquiry Stage" },
                              { stage: "Deals Qualifying Stage" },
                              { stage: "Deals Proposal Stage" },
                              { stage: "Deals Demo Stage" },
                              { stage: "Deals Negotiation Stage" },
                              { stage: "Deals Lost Stage" },
                            ]}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.stage) {
                                return option?.stage;
                              }
                              return "";
                            }}
                            value={filterDealStage}
                            onChange={(e, newValue) => {
                              setfilterDealStage(newValue?.stage);
                            }}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Deal Stage" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            From Date
                          </Typography>
                          <TextField
                            type="date"
                            className="col-12 input-box2"
                            value={fromdate}
                            onChange={(e) => {
                              setFromdate(e.target.value);
                            }}
                            id="createdDate"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            To Date
                          </Typography>
                          <TextField
                            value={todate}
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            type="date"
                            className="col-12 input-box2"
                            id="lastDate"
                          />
                        </Grid>
                      </Grid>

                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          gap: "10px",
                        }}
                      >
                        {/* <Button
                    type="submit"
                    sx={[DialogBoxSaveButton, ButtonStyle, FontStyle]}
                  >
                    Apply
                  </Button> */}
                        <Cancelbutton onClick={closeFilter} />
                        <CustomButton
                          onClick={filterSubmit}
                          type="submit"
                          label="Apply"
                          Icon="filterIcon"
                        />
                      </Div>
                    </Div>
                  </Box>
                </Popover>

                {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}

                <Grid item xs={12} md={6}>
                  <Div
                    className="gap-3 flex-wrap"
                    sx={[DisplayFlex, { justifyContent: "right" }]}
                  >
                    <SearchBar
                      // SearchData={SearchData}
                      SearchData={(e) => SearchData(e)}
                      width={"250px !important"}
                      color={"black"}
                      className=""
                    />

                    <CustomButton
                      onClick={() => setOpenDeal(true)}
                      label="Create"
                      Icon="createIcon"
                      endIcon={true}
                    />
                  </Div>
                </Grid>
              </Grid>
            </Box>

            <List sx={{ mt: 0 }}>
              {datas?.length > 0 &&
                datas?.map((data, idx) => {
                  // if (idx < 2) {

                  let image =
                    (BASE_URL +
                      data?.Account_Id?.File?.split(
                        "https://backend.crmfarm.app"
                      )[1] ||
                      data?.Account_Id?.File) ??
                    "";

                  return (
                    <Div
                      onMouseOver={() => setFadeEdit(idx)}
                      onMouseLeave={() => setFadeEdit(null)}
                      className=""
                      key={idx}
                      sx={{
                        minHeight: "124px",

                        p: { xs: 0.5, sm: 2 },
                        px: { xs: 0.5, md: 7 },
                        my: 2,

                        bgcolor: "white",
                        borderRadius: { xs: "5px", md: "10px" },
                        ":hover": {
                          backgroundColor: Colors?.lightgray,
                        },
                        cursor: "default",
                      }}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        spacing={2}
                        sx={{ pr: "5%" }}
                      >
                        <Grid item xs={12} md={1.5}>
                          <div
                            style={{ marginTop: "6px" }}
                            onClick={() => {
                              handleClickOverviewContact(data);
                              setUpdate(data);
                            }}
                          >
                            <AvatarCircle
                              size="90px"
                              fontSize="30px"
                              bgColor={avatarColorFun(idx)}
                              title={
                                !Boolean(data?.Account_Id?.File) &&
                                data?.Account_Name
                              }
                              image={
                                Boolean(data?.Account_Id?.File) ? image : false
                              }
                            />
                          </div>
                        </Grid>

                        <Grid container spacing={1} item xs={12} md={10.5}>
                          <Grid item xs={12}>
                            <Box
                              sx={[
                                DisplayFlex,
                                {
                                  justifyContent: "space-between",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Typography
                                sx={[
                                  {
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    color: `${Colors?.Mblue}`,
                                  },
                                ]}
                              >
                                {data?.Account_Name}
                              </Typography>
                              <Div sx={[DisplayFlex]}>
                                <Div
                                  className={` ${
                                    idx === fadeEdit ? "fade-in-right" : ""
                                  } `}
                                  sx={{
                                    display: {
                                      xs: "none !important",
                                      sm:
                                        fadeEdit === idx
                                          ? "flex !important"
                                          : "none !important",
                                    },
                                  }}
                                >
                                  <Div
                                    sx={[
                                      FontStyle,
                                      DisplayFlex,
                                      {
                                        cursor: "pointer",
                                        transform: "translateX(-50px)",
                                        color: Colors?.Mblue,
                                      },
                                    ]}
                                    onClick={() => {
                                      setUpdate(data);
                                      setOpenEditDeal(true);
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: "25px !important",
                                        color: Colors?.Mblue,
                                      }}
                                    >
                                      <TiEdit
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 900,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          sx={[
                                            FontStyle,
                                            { color: Colors?.Mblue },
                                          ]}
                                        >
                                          Edit Deal
                                        </Typography>
                                      }
                                    />
                                  </Div>
                                </Div>
                                <CustomButton
                                  onClick={(e) => {
                                    setOpenAnchor(e.target);
                                    setUpdate(data);
                                  }}
                                  sx={{ borderRadius: "25px" }}
                                  label="Action"
                                  bgColor={Colors?.Mblue}
                                  Icon="downArrowIcon"
                                  endIcon={true}
                                />
                              </Div>
                            </Box>
                          </Grid>
                          {columnMenuItems?.map(
                            (Menu, subIdx) =>
                              Menu?.bool && (
                                <Grid key={subIdx} item xs={12} md={4}>
                                  <Typography
                                    sx={[
                                      { fontSize: "14px", color: "#828282" },
                                    ]}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        color: "#5e5e5e",
                                      }}
                                    >
                                      {Menu?.label} :
                                    </span>{" "}
                                    {Menu?.sort === "Amount"
                                      ? data[Menu?.sort]?.toLocaleString() +
                                          ".00" ?? "0.00"
                                      : Menu?.sort === "Phoene"
                                      ? data?.Account_Id?.Phone_Number || " - "
                                      : Menu?.sort === "Created_Date" ||
                                        Menu?.sort === "Updated_Date"
                                      ? formatDate(data[Menu?.sort])
                                      : Menu?.sort === "Company_Name"
                                      ? data?.Account_Id?.Organization_Name ||
                                        " - "
                                      : data[Menu?.sort] || "-"}
                                  </Typography>
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Grid>
                    </Div>
                  );
                  // }
                })}
            </List>
            {/* gear options */}
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
              sx={{
                mt: 5,
                maxHeight: "300px",
                scrollBehavior: "smooth",
              }}
            >
              <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
                Choose Fields
              </Typography>
              <FormGroup sx={{ p: 2 }}>
                {columnMenuItems?.length > 0 &&
                  columnMenuItems?.map((column, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            color="info"
                            size="small"
                            checked={column?.bool}
                            onChange={(e) => {
                              let value = [...columnMenuItems];
                              value[index].bool = !column?.bool;
                              setColumnMenuItems(value);
                            }}
                          />
                        }
                        label={
                          <Typography sx={[FontStyle]}>
                            {column?.label}
                          </Typography>
                        }
                      />
                    );
                  })}
              </FormGroup>
            </Menu>

            {/* openAnchor Action popover */}
            <Popover
              id={Boolean(openAnchor) ? "simple-popover" : undefined}
              open={Boolean(openAnchor)}
              anchorEl={openAnchor}
              onClose={() => {
                setOpenAnchor(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List sx={{ p: 1 }}>
                <ListItemButton
                  sx={[
                    FontStyle,
                    {
                      transform: {
                        xs: "translateX(0px)",
                        sm: "translateX(-50px)",
                      },
                      color: Colors?.Mblue,
                      display: {
                        sm: "none !important",
                        xs: "flex !important",
                      },
                    },
                  ]}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px !important",
                      color: Colors?.Mblue,
                    }}
                  >
                    <TiEdit
                      style={{ fontSize: { xs: 18, md: 25 }, fontWeight: 900 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Edit Contact</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenNotes(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <MdOutlineNoteAdd style={{ fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Notes</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenSendMail(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <RiMailSendLine style={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Send Mail</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenActivity(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TbActivity style={{ fontSize: 18 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Activity</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenAppointment(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TodayIcon sx={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Appointment</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setShowDelete(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "25px !important", color: "tomato" }}
                  >
                    {CustomIcons?.deleteIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                  />
                </ListItemButton>
              </List>
            </Popover>
          </Box>
        </Box>

        {/* Create Contact */}
        {/* <Dragabledialog
        maxWidth="md"
        open={showAllLead === "create"}
        // onClose={() => setOpenNotes(false)}
      > */}

        {/* Add Note Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openNotes}
          // onClose={() => setOpenNotes(false)}
        >
          <NotesModel
            endpoint={"DealNotesCRUD"}
            name={{ label: "Deal Name", value: update?.Account_Name }}
            extraPayload={{ Deal_Id: update?.id }}
            historyEndpoint={{
              point: "DealHistorycrud",
              payload: { Deal_Id: update?.id },
            }}
            data={update}
            setOpenNotes={setOpenNotes}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Activities Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openActivity}
          // onClose={() => setOpenActivity(false)}
        >
          <ActivityModel
            name={{ label: "Deal Name", value: update?.Account_Name }}
            endpoint={"DealActivitiesCRUD"}
            extraPayload={{ Deal_Id: update?.id }}
            historyEndpoint={{
              point: "DealHistorycrud",
              payload: { Deal_Id: update?.id },
            }}
            data={update}
            setOpenActivity={setOpenActivity}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Appointment Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openAppointment}
          // onClose={() => setOpenAppointment(false)}
        >
          <AppointmentModel
            name={{ label: "Deal Name", value: update?.Account_Name }}
            historyEndpoint={{
              point: "DealHistorycrud",
              payload: { Deal_Id: update?.id },
            }}
            extraPayload={{ Deal_Id: update?.id }}
            endpoint={"DealMeetingCRUD"}
            data={update}
            setOpenAppointment={setOpenAppointment}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Send Mail Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openSendMail}
          // onClose={() => setOpenSendMail(false)}
        >
          {console.log("Send Mail", update?.Account_Id)}
          <ComposeEmail
            extraPayload={{ Deal_Id: update?.id }}
            endpoint={"DealMailMasterCRUD"}
            data={{ ...update, Email_Id: update?.Account_Id?.Mail }}
            setOpenSendMail={setOpenSendMail}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Deal */}
        <Dragabledialog
          maxWidth="lg"
          open={openDeal}
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Create Deal
            {/* <IconButton sx={{ mr: -2.5 }} onClick={() => setSave(true)}>
            <CloseIcon size={22} color="gray" />
          </IconButton> */}
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <Customgrid columnCount={4}>
              <Grid item>
                <Typography sx={[input_label]}>
                  {" "}
                  Deal Id{" "}
                  <span className="required">
                    {seriesValue == "Automatic" ? "" : "*"}
                  </span>{" "}
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="# Deal Id"
                  value={dealId}
                  onChange={(e) => setDealId(e.target.value)}
                  disabled={seriesValue == "Automatic" ? true : ""}
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealName && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealName} deal Id{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}> Deal Name </Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Enter Deal Name"
                  value={addField.dealName}
                  onChange={(e) =>
                    setAddField({ ...addField, dealName: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealName && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealName} deal name{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Customer Name </Typography>
              </Grid>

              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={accounts}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.Contact_Name) {
                        return option?.Contact_Name;
                      }
                      return "";
                    }}
                    value={addField?.accName}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        accId: newValue?.id,
                        accName: newValue?.Contact_Name,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Customer Name" />
                    )}
                  />
                </FormControl>
                {errors?.accName && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.accName} account name
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}> Lead Name </Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={leads}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.LeadFirstName) {
                        return option?.LeadFirstName;
                      }
                      return "";
                    }}
                    value={addField?.leadName}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        leadSource: newValue?.LeadSource,
                        leadId: newValue?.id,
                        leadName: newValue?.LeadFirstName,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Lead Name" />
                    )}
                  />
                </FormControl>
                {errors?.lead && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.lead} deal name{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Lead Source</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <TextField
                    className={`col-12 input-box2`}
                    id="outlined-basic"
                    placeholder="Lead Source"
                    value={addField.leadSource}
                    // onChange={(e) => setAddField({ ...addField, l: e.target.value })}
                    InputProps={{
                      style: {
                        borderRadius: "3px",
                      },
                    }}
                  />
                </FormControl>
                {errors?.leadSource && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.leadSource} lead source{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Amount</Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Deal Amount"
                  value={addField.dealAmount}
                  onChange={(e) =>
                    setAddField({ ...addField, dealAmount: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealAmount && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealAmount} deal amount{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Stage</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={[
                      { stage: "Deals Enquiry Stage" },
                      { stage: "Deals Qualifying Stage" },
                      { stage: "Deals Proposal Stage" },
                      { stage: "Deals Demo Stage" },
                      { stage: "Deals Negotiation Stage" },
                      { stage: "Deals Lost Stage" },
                    ]}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.stage) {
                        return option?.stage;
                      }
                      return "";
                    }}
                    value={addField?.dealStage}
                    onChange={(e, newValue) => {
                      setAddField({ ...addField, dealStage: newValue?.stage });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Deal Stage" />
                    )}
                  />
                </FormControl>
                {errors?.dealStage && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealStage} deal stage{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Owner</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Typography sx={[FontStyle]}></Typography>
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={userNameLists}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.username) {
                        return option?.username;
                      }
                      return "";
                    }}
                    value={addField?.dealOwner}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        dealOwner: newValue?.username,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Deal Stage" />
                    )}
                  />
                </FormControl>
                {errors?.dealStage && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealStage} deal stage{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Type</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={lookupDatas}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    value={addField?.dealType}
                    onChange={(e, newValue) => {
                      setAddField({ ...addField, dealType: newValue?.value });
                    }}
                    sx={[FontStyle]}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Deal Type"
                        sx={[FontStyle]}
                      />
                    )}
                  />
                </FormControl>

                {errors?.dealType && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealType} deal type{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Contact Person</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Contact Name"
                  value={addField.contactName}
                  onChange={(e) =>
                    setAddField({ ...addField, contactName: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />

                {errors?.contactName && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.contactName} Contact Name{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Minimum turn around</Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Minimum turn around"
                  value={addField.turnAround}
                  onChange={(e) =>
                    setAddField({ ...addField, turnAround: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.turnAround && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.turnAround} turn around{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Probability %</Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Deal Probability %"
                  value={addField.dealProbability}
                  onChange={(e) =>
                    setAddField({
                      ...addField,
                      dealProbability: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealProbability && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealProbability} deal probability{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Note</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Deal Note"
                  value={addField.dealNote}
                  onChange={(e) =>
                    setAddField({ ...addField, dealNote: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealNote && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors?.dealNote} deal note{" "}
                  </Typography>
                )}
              </Grid>
            </Customgrid>

            <Grid container align="center" mt={2} spacing={2} pb={2}>
              <Grid item align="right" xs={6}>
                <CustomButton label="Save" Icon="saveIcon" onClick={onsubmit} />
              </Grid>
              <Grid item align="left" xs={6}>
                <Cancelbutton onClick={() => setSave("create")} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dragabledialog>

        {/* Edit Deal */}
        <Dragabledialog
          maxWidth="lg"
          open={openEditDeal}
          // onClose={() => setOpenAppointment(false)}
        >
          <ContactEdit
            // DealsListsRefetch={DealsListsRefetch}
            setOpenEditContact={setOpenEditDeal}
            update={update}
            setSave={setSave}
            setPage={setPage}
            tabValue={tabValue}
            getData={getData}
            getMyData={getMyData}
            setDatas={setDatas}
          />
        </Dragabledialog>

        {/* delete popup */}

        <DialogBox
          open={showDelete}
          Content={deletedWarnigMsg}
          onClickNo={() => setShowDelete(false)}
          onClickYes={() => {
            // setSave(false);
            setShowDelete(false);
            // reset();
            Deletedata();
            setOpenAnchor(null);
          }}
        />

        {/* Close dialog box */}
        <DialogBox
          open={save}
          onClickNo={() => setSave(false)}
          onClickYes={() => {
            switch (save) {
              case "note":
                setOpenNotes(false);
                break;
              case "activity":
                setOpenActivity(false);
                break;
              case "appointment":
                setOpenAppointment(false);
                break;
              case "email":
                setOpenSendMail(false);
                break;

              case "create":
                setOpenDeal(false);
                break;
              case "edit":
                setOpenEditDeal(false);
                break;
            }
            setSave(false);
          }}
        />
      </JumboContentLayoutMain>
    </>
  );
};

export default AllContactsList;
