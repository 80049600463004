import React, { useEffect, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Business";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

import { Tree, TreeNode } from "react-organizational-chart";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop, DndProvider } from "react-dnd";

import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { componentTitle, deletedWarnigMsg } from "app/pages/widgets/CRMStyles";
import CreateAndEdit from "./CreateAndEdit";
import { useTerritoryList, useTotalSalesTarget } from "../TerritoryAPI";
import { FaUserLarge } from "react-icons/fa6";
import { IoCreate } from "react-icons/io5";
import { HiMiniPlus } from "react-icons/hi2";
import { DialogBox } from "app/pages/widgets/StylesComponents";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import axios from "axios";
import { MdDelete } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16,
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#ECECF4",
  },
}));

function Organization({ org, onCollapse, collapsed }) {
  console.log("org", org);
  const [isTerritoryFormOpen, setIsTerritoryForm] = useState(null);
  const [save, setSave] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { TerritoryListRefetch } = useTerritoryList();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "children",
    drop: () => ({ name: org?.TerritoryName || org?.organisation }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;
  let backgroundColor = "white";
  if (isActive) {
    backgroundColor = "#ddffd2";
  } else if (canDrop) {
    backgroundColor = "#ffeedc";
  }

  // delete record
  const token = localStorage.getItem("accesstoken");
  const handleDeleteSalesTarget = async () => {
    try {
      await axios
        .delete(`${BASE_URL}/user/TerritoryManagementCRUD/`, {
          params: {
            id: save?.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          toast.success("Deleted ", save?.Territery_Name, " Territory ", {
            position: "top-right",
            autoClose: 100,
          });
          setAnchorEl(null);
          setSave(false);
          TerritoryListRefetch();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        variant="outlined"
        className={`${classes.root} item-center`}
        ref={drop}
        style={{ backgroundColor }}
      >
        <CardHeader
          avatar={
            <Tooltip
              title={`${_.size(org?.children || org?.data)} Children`}
              arrow
            >
              <Badge
                style={{ cursor: "pointer" }}
                color="secondary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                showZero
                invisible={!collapsed}
                overlap="circle"
                badgeContent={_.size(org?.children || org?.data)}
                onClick={onCollapse}
              >
                <Avatar className={classes?.avatar}>
                  <BusinessIcon color="primary" />
                </Avatar>
              </Badge>
            </Tooltip>
          }
          title={org?.organisation || org?.TerritoryName}
          action={
            <IconButton size="small" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          }
        />

        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setIsTerritoryForm("Create")}>
            <ListItemIcon>
              <HiMiniPlus style={{ fontSize: 18 }} color="primary" />
            </ListItemIcon>
            <ListItemText primary="Create Territory" />
          </MenuItem>
          {!Boolean(org?.organisation) && <MenuItem onClick={() => setIsTerritoryForm("Edit")}>
            <ListItemIcon>
              <IoCreate style={{ fontSize: 18 }} color="success" />
            </ListItemIcon>
            <ListItemText primary="Edit Territory" />
          </MenuItem>}

          {!Boolean(org?.organisation) && <MenuItem onClick={() => setSave(org)}>
            <ListItemIcon>
              <MdDelete style={{ fontSize: 18 }} color="danger" />
            </ListItemIcon>
            <ListItemText primary="Delete Territory" />
          </MenuItem>}
        </Menu>

        {org?.data?.length > 0 && (
          <IconButton
            size="small"
            onClick={onCollapse}
            className={clsx(classes.expand, {
              [classes.expandOpen]: !collapsed,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Card>
      {/* Create Territory */}
      <CreateAndEdit
        open={isTerritoryFormOpen}
        setOpen={setIsTerritoryForm}
        update={org}
      />

      {/* Delete dialogbox */}
      <DialogBox
        Content={deletedWarnigMsg}
        open={save}
        onClickNo={() => setSave(false)}
        onClickYes={() => {
          handleDeleteSalesTarget();
        }}
      />
    </>
  );
}

function Account({ a }) {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag({
    item: { name: a?.TerritoryName, type: "children" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        alert(`You moved ${item?.name} to ${dropResult?.name}`);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  return (
    <Card
      variant="outlined"
      className={classes.root}
      ref={drag}
      style={{ cursor: "pointer", opacity }}
    >
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <FaUserLarge color="secondary" />
          </Avatar>
        }
        title={a?.TerritoryName || a?.organisation}
      />
    </Card>
  );
}

function Product({ p }) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent>
        <Typography variant="subtitle2">{p?.TerritoryName}</Typography>
      </CardContent>
    </Card>
  );
}

function Node({ o, parent }) {
  const [collapsed, setCollapsed] = React.useState(o?.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  React.useEffect(() => {
    o.collapsed = collapsed;
  });
  const T = parent
    ? TreeNode
    : (props) => (
      <Tree
        {...props}
        lineWidth={"2px"}
        lineColor={"#bbc"}
        lineBorderRadius={"12px"}
      >
        {props.children}
      </Tree>
    );
  return collapsed ? (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    />
  ) : (
    <T
      label={
        <Organization
          org={o}
          onCollapse={handleCollapse}
          collapsed={collapsed}
        />
      }
    >
      {/* {_.map(o?.children, (a) => {
        console.log("a", a);
        if (a?.Assign_Users?.length > 0) {
          return (
            <>
              {a?.Assign_Users?.map((item) => (
                <TreeNode label={<Account a={item} />} />
              ))}
              <TreeNode label={<Node o={a} parent={o?.children} />} />
            </>
          );
        }
      })}
       */}
      {_.map(o?.children, (a) => {
        return <Node o={a} parent={o?.children} />;
      })}

      {_.map(o?.data, (c) => (
        <Node o={c} parent={o} />
      ))}
    </T>
  );
}

export default function Hierarchy(props) {
  const {
    territoryList,
    TerritoryListIsLoading,
    TerritoryListIsError,
    TerritoryListRefetch,
  } = useTerritoryList();

  const { SalesTargetList } = useTotalSalesTarget();

  const buildCustomTerritoryTree = (territories) => {
    const territoryMap = new Map();

    // Create a map of territories by ID and initialize children array
    territories?.forEach((territory) => {
      territory.children = [];
      territoryMap.set(territory.id, territory);
    });

    const roots = [];

    // Link each territory to its parent
    territories?.forEach((territory) => {
      if (territory?.ParentTerritory && territory?.ParentTerritory !== territory?.id) {
        const parentTerritory = territoryMap.get(parseInt(territory?.ParentTerritory));
        if (parentTerritory) {
          parentTerritory.children.push(territory);
        }
      } else {
        roots.push(territory);
      }
    });

    // Remove empty children arrays recursively
    const cleanTerritories = (territory) => {
      if (territory.children.length === 0) {
        delete territory.children;
      } else {
        territory?.children?.forEach(cleanTerritories);
      }
    };

    roots.forEach(cleanTerritories);

    return roots;
  };

  const datas = {
    organisation: territoryList?.TerritoryName,
    data: buildCustomTerritoryTree(territoryList?.data),
  };

  console.log("territoryListsss", territoryList);


  return (
    <JumboContentLayoutMain>
      <Typography sx={[componentTitle, { mb: 2 }]}>
        Territory Hirarchy
      </Typography>
      <Box className="w-full overflow-x-auto">
        <Box bgcolor="background" padding={4} height="80vh">
          <DndProvider backend={HTML5Backend}>
            <Node o={datas || {}} />
          </DndProvider>
        </Box>
      </Box>
    </JumboContentLayoutMain>
  );
}
