import React, { useState } from "react";
import "./Login.css";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import { BASE_URL, Tenantname } from "../../../services/auth-services";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import arrow from "../../../assets/images/right-arrow.gif";
import CRMlogo from "../../../assets/images/crmlogo.png";
import {
  Colors,
  DisplayFlex,
  FontStyle,
  generateRandomColor,
} from "app/pages/widgets/CRMStyles";
import { useEffect } from "react";
import ForgotPassword from "../forgot-password/ForgotPassword";
import Cookies from "js-cookie";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

export let uniqueID = generateRandomColor().split("#")[1];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthToken } = useJumboAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpNumber, setOtpNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showLoginButton, setShowLoginButton] = useState(true);
  const [validData, setValidData] = useState([]);
  const [role, setRole] = useState("");
  const [progress, setProgress] = useState(300);
  const [progressInterval, setProgressInterval] = useState(null);
  const [error, setErrors] = useState({});
  const [status, setStatus] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [LoginPageView, setLoginPageView] = useState(true);
  const [ForgotPageView, setForgotPageView] = useState(false);

  useEffect(() => {
    const savedUsername = Cookies.get("LUserName");
    const savedPassword = Cookies.get("LPassword");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const validationSchema = yup.object({
    username: yup
      .string("Enter username")
      .required("Email is required")
      .max(30, "Username is strong"),
    password: yup
      .string("Enter your password")
      .required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const startProgressInterval = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress - 1;
        if (newProgress === 0) {
          setShowLoginButton(true);
          setShowOtpBox(false);
          clearInterval(interval);
          stopProgressInterval();
        }
        return newProgress;
      });
    }, 1000);
    setProgressInterval(interval);
  };

  const stopProgressInterval = () => {
    if (progressInterval) {
      clearInterval(progressInterval);
      setProgressInterval(null);
      setOtp("");
      setOtpNumber("");
    }
  };

  const generateOTP = () => {
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    console.log("OTP: ", OTP);
    return OTP;
  };

  const handleGenerateOtp = () => {
    let errors = {};
    if (!username.trim()) {
      errors.username = "Email is required";
    }
    if (!password.trim()) {
      errors.password = "Password is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const otp = generateOTP();
      const payload = {
        username,
        subject: "OTP Verification",
        MailContent: `<div className="card">
          <div className="card-body">
            <h4 className="card-title">Hi,</h4>
            <p className="card-text">
              To ensure the security of your account, we require you to verify your identity with a One-Time Password (OTP).
            </p>
            <br/>
            <p>Here is your OTP: <span style="font-size: 18px; font-weight: 600;">${otp}</span></p>
            <br/>
            <p>
              Please enter this OTP on the login page within the next 10 minutes to complete the verification process and gain access to your account.
            </p>
            <br/>
            <p>
              If you did not request this OTP or have any concerns about your account's security, please contact our support team immediately.
            </p>
            <br/>
            <div>CRMFarm Team</div>
          </div>
        </div>`,
      };

      axios
        .post(`${BASE_URL}/user/GenerateOtp/`, payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          setShowOtpBox(true);
          setShowLoginButton(false);
          setOtpNumber(otp);
          setOtp("");
          setProgress(300);
          startProgressInterval();
          localStorage.setItem("OTP", otp);
        })
        .catch((error) => {
          console.error("Error generating OTP:", error);
        });
    }
  };

  const Check_isactiveTenant = (e) => {
    console.log("Entering into Check_isactiveTenant ");
    var userpayload = {
      username: username,
      password: password,
    };
    console.log("userpayload:", userpayload);
    axios
      .get(`${BASE_URL}/useradmin/UserCRUD_GET/?username=${username}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("res Login Check:", res);
        if (res?.data?.is_Tenantactive == true) {
          handleResetPassword();
        } else {
          toast.error("Your Subscription is inactive", {
            position: "top-right",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        toast.error("Enter valid username and password", {
          position: "top-right",
          autoClose: 100,
        });
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const updateLoginStatus = async () => {
    try {
      const token = localStorage.getItem("accesstoken");
      const res = await axios.patch(
        `${BASE_URL}/useradmin/UserCRUD/`,
        {
          User_Login_Status: true,
          username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: "crmuseradmin1",
          },
        }
      );

      if (res) {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error updating login status:", error);
    }
  };

  const handleResetPassword = () => {
    const userpayload = {
      username,
      password,
    };

    axios
      .post(`${BASE_URL}/auth_token`, userpayload, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200 && username !== "demouser") {
          if (rememberMe) {
            Cookies.set("LUserName", username);
            Cookies.set("LPassword", password);
          }
          handleGenerateOtp();
        }
        localStorage.setItem("accesstoken", res.data.token);
        localStorage.setItem("selectedItems", JSON.stringify([]));
        localStorage.setItem("selectedItems1", JSON.stringify([]));
        localStorage.setItem("isLoggedIn", "true");

        axios
          .get(`${BASE_URL}/useradmin/UserCRUD/?username=${username}`, {
            headers: {
              Authorization: `Bearer ${res.data.token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            const userData = response.data;
            setStatus(userData);
            localStorage.setItem("UserType", userData.UserType);
            localStorage.setItem("UserDetails", JSON.stringify(userData));
            localStorage.setItem("Username", userData.username);
            localStorage.setItem("UserId", userData.id);
            localStorage.setItem("OrganizationId", userData.Organization_Id);
            localStorage.setItem("Designation", userData.Designation);
            localStorage.setItem("DBName", "crmuseradmin1");
            setRole(userData.Designation);

            setValidData(userData);
            if (username === "demouser") {
              if (rememberMe) {
                Cookies.set("LUserName", username);
                Cookies.set("LPassword", password);
              }
              navigate(`/home`);
              toast.success("Login successfully", {
                position: "top-right",
                autoClose: 100,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
            toast.error("Enter valid username and password", {
              position: "top-right",
              autoClose: 100,
            });
            navigate("/");
            reset();
          });
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
        toast.error("Enter valid username and password", {
          position: "top-right",
          autoClose: 100,
        });
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    handleResetPassword();
  };

  const validateOtp = async (e) => {
    if (otp == otpNumber) {
      await updateLoginStatus();

      toast.success("OTP is Verified");
      toast.success("Login successfully", {
        position: "top-right",
      });

      for (let i = 0; i < 2; i++) {
        navigate("/home");
      }
    } else {
      toast.error("Please enter valid OTP");
    }
  };

  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
    // console.log("Remember me:", event.target.checked);
  };

  return (
    <>
      {/* <Grow in> */}
      <div id="main-wrapper" className="oxyy-login-register">
        <div className="container-fluid px-0">
          <div className="row g-0 min-vh-100">
            <div className="col-md-6">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="hero-mask"></div>
                <div className="hero-bg hero-bg-scroll"></div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="container my-auto py-5">
                <div className="row g-0">
                  <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                    <div style={{ textAlign: "-webkit-center" }}>
                      <img src={CRMlogo} width={100}></img>
                    </div>
                    <h3 className="fw-600 mb-4">Log In</h3>
                    <form
                      id="loginForm"
                      onSubmit={handleSubmit(Check_isactiveTenant)}
                      // onSubmit={handleSubmit(Check_isactiveTenant)}
                      style={{ textAlign: "left", color: "white" }}
                      noValidate
                      autoComplete="off"
                    >
                      <div className="mb-3">
                        <label htmlFor="Username" className="form-label">
                          Username
                        </label>
                        <input
                          className="form-control"
                          id="emailAddress"
                          required
                          fullWidth
                          type="text"
                          autoComplete="off"
                          // className={` ${
                          //   errors.username ? "is-invalid" : ""
                          // }`}
                          {...register("username")}
                          value={username}
                          onChange={(e) => {
                            setUsername(e.target.value);
                          }}
                          placeholder="Enter username"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="password"
                          className="form-label"
                          id="loginPassword"
                        >
                          Password
                        </label>
                        <div className="relative">
                          <input
                            className="form-control"
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            // className="input-box"
                            // autoComplete="off"
                            // // className={` ${
                            // //   errors.password ? "is-invalid" : ""
                            // // }`}
                            {...register("password")}
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            placeholder="Enter Your Password"
                          />
                          {password?.length > 0 && (
                            <span
                              onClick={handleClickShowPassword}
                              className="cursor-pointer absolute top-[50%] transform -translate-y-[50%] right-4"
                            >
                              {showPassword ? (
                                <EyeOutlined
                                  style={{ fontSize: "18px" }}
                                  className="text-[#3fa9ff]"
                                />
                              ) : (
                                <EyeInvisibleOutlined
                                  style={{ fontSize: "18px" }}
                                  className="text-[#3fa9ff]"
                                />
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      <Div
                        sx={{
                          display: showOtpBox === true ? "block" : "none",
                          justifyContent: "center",
                          mt: 3,
                        }}
                      >
                        <Typography
                          sx={[FontStyle, { mt: 2, color: "#102C57" }]}
                        >
                          OTP will expire within {progress} sec
                        </Typography>
                        <Div className="mt-1">
                          <input
                            className="form-control"
                            id="emailAddress"
                            required
                            fullWidth
                            type="text"
                            autoComplete="off"
                            // className={` ${
                            //   errors.password ? "is-invalid" : ""
                            // }`}
                            value={otp}
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                            placeholder="Enter OTP"
                          />
                        </Div>
                      </Div>
                      <div className="row mt-4">
                        <div className="col">
                          <div className="form-check">
                            <input
                              id="remember-me"
                              name="remember"
                              className="form-check-input"
                              type="checkbox"
                              checked={rememberMe}
                              onChange={handleCheckboxChange}
                            />
                            <label
                              className="form-check-label text-black"
                              htmlFor="remember-me"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className="col text-end fn">
                          <a
                            sx={{
                              // color: "black !important",
                              fontSize: "12px !important",
                            }}
                            onClick={() => {
                              setLoginPageView(false);
                              setForgotPageView(true);
                            }}
                          >
                            <label
                              className="form-check-label text-[#60b6fc]"
                              htmlFor="remember-me"
                            >
                              Forgot Password ?
                            </label>
                          </a>
                        </div>
                      </div>
                      <div className="d-grid my-2">
                        {/* <button className="btn btn-primary" type="submit">Login</button> */}
                        <Div
                          sx={{
                            display: showLoginButton === true ? "flex" : "none",
                            justifyContent: "center",
                            mt: "4rem",
                            my: "1.5rem",
                          }}
                        >
                          <Button
                            type="submit"
                            sx={{
                              background: "rgb(31 109 175)",
                              width: "100%",
                              padding: "10px",
                              ":hover": {
                                background: "rgb(71 137 193)",
                              },
                              borderRadius: "5px !important",
                            }}
                          >
                            <Typography
                              sx={[
                                {
                                  fontSize: "15px !important",
                                  color: "white !important",
                                },
                              ]}
                            >
                              Login
                            </Typography>
                          </Button>
                        </Div>

                        <Div
                          sx={{
                            display: showOtpBox === true ? "block" : "none",
                            justifyContent: "center",
                            mt: "4rem",
                            my: "1.5rem",
                          }}
                        >
                          <Div
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Button
                              type="submit"
                              onClick={validateOtp}
                              sx={{
                                background: "rgb(31 109 175)",
                                width: "100%",
                                padding: "10px",
                                ":hover": {
                                  background: "rgb(71 137 193)",
                                },
                                borderRadius: "5px !important",
                              }}
                            >
                              <Typography
                                sx={[
                                  {
                                    fontSize: "15px !important",
                                    color: "white !important",
                                  },
                                ]}
                              >
                                Submit
                              </Typography>
                            </Button>
                          </Div>
                        </Div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Grow> */}
    </>
  );
};
export default Login;
