import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Country } from "country-state-city";
import { apiConfig } from "app/services/config";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import {
  ButtonStyle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  FontStyle,
  input_label,
} from "../../widgets/CRMStyles";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
  fetchTransactionSeries,
} from "app/pages/widgets/StylesComponents";

const CreateAddon = ({ setCreateAddon, setListAddon, scrollHeight }) => {
  const [addonname, setaddonname] = useState("");
  const [currency, setcurrency] = useState("");
  const [addonnouser, setaddonnouser] = useState("");
  const [addonprice, setaddonprice] = useState("");
  const [seriesValue, setSeriesValue] = useState("");
  const [seriesId, setSeriesId] = useState("");

  const [save, setSave] = useState(false);

  let Countries = Country.getAllCountries();

  const handleClickBack = () => {
    setCreateAddon(false);
    setListAddon(true);
  };

  const handlePostAddonPrice = () => {
    if (
      addonname === "" ||
      addonname === undefined ||
      addonname === null ||
      addonnouser === "" ||
      addonnouser === undefined ||
      addonnouser === null ||
      addonprice === "" ||
      addonprice === undefined ||
      addonprice === null
    ) {
      toast.error("Invalid Datas");
    } else {
      let payload = {
        Name: addonname,
        Addon_Id: seriesValue == "Automatic" ? "" : seriesId,
        // Currency_code: currency,
        Is_Active: true,
        Price: addonprice,
        Is_Deleted: false,
        NoofUsers: addonnouser,
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };
      console.log("payload", payload);
      axios
        .post(`${BASE_URL}/superadmin/Addon/`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          if (res) {
            toast.success("Created Successfully");
            setcurrency("");
            setaddonname("");
            setaddonnouser("");
            setaddonprice("");
            handleClickBack();
          } else {
            toast.error("Check your data");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Somthing Went Wrong");
        });
    }
  };

  useEffect(() => {
    const transactionSeries = async () => {
      const series = await fetchTransactionSeries("Addons");
      console.log("series", series);
      setSeriesValue(series);
    };
    transactionSeries();
  }, []);

  return (
    <>
      <Grid container columns={12} spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Addon Id</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={seriesValue}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="# Addon ID"
            variant="outlined"
            disabled={seriesValue == "Automatic"}
            onChange={(e) => {
              setSeriesId(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Addon Name</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={addonname}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Addon Name"
            variant="outlined"
            onChange={(e) => {
              setaddonname(e.target.value);
            }}
          />
        </Grid>

        {/* <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Currency</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <FormControl className="w-100  ">
            <Autocomplete
              className="search-select3 col-12 w-100"
              // {...register("selectedCountry1")}
              options={Countries}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.currency) {
                  return option?.currency;
                }
                return "";
              }}
              value={Countries.find((item) => item?.currency === currency)}
              onChange={(item, newvalue) => {
                setcurrency(newvalue?.currency);
                console.log(newvalue?.currency);
              }}
              id="outlined-basic"
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Currency" />
              )}
            />
          </FormControl>
        </Grid> */}

        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>No of Users</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={addonnouser}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="No of Users"
            variant="outlined"
            onChange={(e) => {
              setaddonnouser(e.target.value);
            }}
          ></TextField>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Price</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={addonprice}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            type="number"
            placeholder="Price"
            variant="outlined"
            onChange={(e) => {
              setaddonprice(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Div
        sx={{
          my: 3,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 3,
        }}
      >
        <CustomButton
          label="Save"
          Icon="saveIcon"
          onClick={handlePostAddonPrice}
        />
        <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
      </Div>

      <DialogBox
        open={save}
        onClickYes={() => {
          setSave(false);
          handleClickBack();
        }}
        onClickNo={() => setSave(false)}
      />

      {/* <ToastContainer /> */}
    </>
  );
};

export default CreateAddon;
