import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'
import './GmailInbox.css'
import EmailDetail from './EmailDetail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Buffer } from 'buffer';

const GmailInbox = ({ messages, messageResultArray }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [emailDetailOpen, setEmailDetailOpen] = useState(false);
    const [emailDetailData, setEmailDetailData] = useState('');
    const [headers, setHeaders] = useState([]);
    const [messageBody, setMessageBody] = useState('');
    const [headersArray, setHeadersArray] = useState([]);
    const [fromName, setFromName] = useState('');


    const handleEmailClick = (partsArray, headersArray) => {
        console.log("partsArray", partsArray)
        partsArray?.map((obj) => {
            if (obj.mimeType == 'text/html') {
                const bodyData = Buffer.from(obj.body.data, 'base64').toString('utf-8');
                console.log("bodyData", bodyData)
                setMessageBody(bodyData)
                setHeadersArray(headersArray)
            }

        })
        // setEmailDetailData(data)
        setEmailDetailOpen(true)
    };
    const handleCloseEmailDetail = () => {
        setEmailDetailOpen(false)
    }
    const handleBackClicked = () => {
        setMessageBody('')
        setEmailDetailOpen(false)
    }

    const decodeHTMLEntities = (text) => {
        const doc = new DOMParser().parseFromString(text, 'text/html');
        return doc.documentElement.textContent;
    }

    

    return (
        <div>
            {console.log("messageResultArray", messageResultArray)}
            {console.log("headers", headers)}
            {console.log("messagesInInbox", messages)}
            {!emailDetailOpen ?
                <div>
                    
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead >
                                <TableRow className='inboxTableRow' sx={{ backgroundColor: '#e6d9d9' }}>
                                    <TableCell align="left">From</TableCell>
                                    <TableCell align="left">Message</TableCell>
                                    <TableCell align="left">time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    messageResultArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((message, index) => {
                                        return (<>
                                            {console.log("message", message)}
                                            {/* {console.log("headers in Inbox", message.payload.headers[21]?.name == "Subject" ? message.payload.headers[21].value : '')} */}
                                            {console.log(message.payload.parts)}
                                            <TableRow className='inboxTableRow' key={index}>
                                                {/* {message.headers} */}
                                                <TableCell  >{message.payload.headers.map((obj) => { return obj.name === "From" ? (obj.value.match(/([^<]+)<[^>]+>/) ? obj.value.match(/([^<]+)<[^>]+>/)[1].trim() : obj.value) : '' })}</TableCell>
                                                <TableCell onClick={() => handleEmailClick(message.payload.parts, message.payload.headers)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <p>{message.payload.headers.map((obj) => { return obj.name === "Subject" ? obj.value : '' })}</p>
                                                        <p className='gmail-snippet'>{`-${decodeHTMLEntities(message.snippet)}`}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell sx={{ width: '12%' }}>
                                                    {message.payload.headers.map((obj) => {
                                                        if (obj.name === "Date") {
                                                            const parts = obj.value.split(' ');
                                                            const month = parts[2];
                                                            const day = parts[1];
                                                            const originalTime = new Date(obj.value)
                                                            const formattedTime = originalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                            return `${month}${' '}${day}${', '}${formattedTime}`
                                                        }
                                                    }
                                                    )}</TableCell>
                                            </TableRow>
                                        </>

                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={messages.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={(e) => {
                            setRowsPerPage(parseInt(e.target.value, 10));
                            setPage(0);
                        }}
                    />
                </div>
                : ''}
            {emailDetailOpen ?
                <div className='view-message-div'>
                    <ArrowBackIcon onClick={handleBackClicked} sx={{ marginBottom: '20px' }}></ArrowBackIcon>
                    <EmailDetail data={messageBody} open={handleCloseEmailDetail} headersArray={headersArray} ></EmailDetail>
                </div> : ''}
        </div >
    )
}

export default GmailInbox