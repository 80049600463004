import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain'
import { Button, Grid, Switch, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ComponentHeading, DisplayFlex } from '../widgets/CRMStyles'
import Div from '@jumbo/shared/Div'
import Funnel, {
    Title,
    Margin,
    Export,
    Tooltip,
    Item,
    Border,
    Label,
    Font,
} from 'devextreme-react/funnel';


function LeadPerformance() {
    const [toggleButton, setToggleButtton] = useState(true)

    const dataSource = [
        { argument: 'MQL', value: 9152 },
        { argument: 'SQL', value: 3879 },
        { argument: 'Deals', value: 3121 },
        { argument: 'Customers', value: 3224 },
    ];


    return (
        <JumboContentLayoutMain>
            <Typography sx={[ComponentHeading, { mb: 2 }]}>Lead Performance</Typography>
            <Grid container spacing={2} >
                <Grid item xs={12} md={3} mt={1}>
                    <Div className='card' sx={[{ height: { md: "530px" }, p: 1, boxShadow: "0px 0px 4px lightgray", border: "1px solid white" }]} >
                        <Div sx={[DisplayFlex,{flexWrap:'wrap',gap:'20px',alignItems:'center !important'}]}>
                            <Div sx={[DisplayFlex, {display:{md:'inline-block !important'} ,flexWrap: 'wrap' }]}>
                                <Div sx={{ ":hover": { bgcolor: 'gray', color: "white" }, ":focus-within": { bgcolor: 'gray', }, bgcolor: 'ghostwhite', my: 0.5, p: 1, cursor: 'pointer' }}><Typography>Current Month</Typography></Div>
                                <Div sx={{ ":hover": { bgcolor: 'gray', color: "white" }, ":focus-within": { bgcolor: 'gray', }, bgcolor: 'ghostwhite', my: 0.5, p: 1, cursor: 'pointer' }}><Typography>Last Month</Typography></Div>
                                <Div sx={{ ":hover": { bgcolor: 'gray', color: "white" }, ":focus-within": { bgcolor: 'gray', }, bgcolor: 'ghostwhite', my: 0.5, p: 1, cursor: 'pointer' }}><Typography>Last 3 Month</Typography></Div>
                            </Div>

                            <Div sx={[DisplayFlex, { flexWrap: 'wrap', mt: { md: 4 } }]}>
                                <Div sx={{ my: 1 }} >
                                    <Typography sx={{ mb: 0.5 }}>From</Typography>
                                    <TextField id="time" type="date" sx={{width:'100%'}} className='input-box' />
                                </Div>
                                <Div sx={{ my: 1 }} >
                                    <Typography sx={{ mb: 0.5 }}>To</Typography>
                                    <TextField id="time" type="date" sx={{width:'100%'}} className='input-box' />
                                </Div>
                            </Div>

                            <Div sx={[DisplayFlex, { flexWrap: 'wrap', mt: { md: 4 } }]}>
                                <Typography sx={{ mb: 0.5 }}>Show glossary</Typography>
                                <Switch onValueChange={() => setToggleButtton(!toggleButton)} value={toggleButton} defaultValue={toggleButton} />
                            </Div>

                        </Div>
                    </Div>
                </Grid>

                <Grid container item xs={12} md={9} mt={1}>
                    <Grid item xs={12} >
                        <Div className='card' sx={[{ height: { md: "100px" }, p: 1, boxShadow: "0px 0px 4px lightgray", border: "1px solid white" }]} >
                            <Grid container sx={{ mt: { md: 0 } }} alignItems={'center'} spacing={2}>
                                <Grid item xs={6} md={3}  >
                                    <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#000', fontSize: "16px" }]}>Audiance</Typography>
                                </Grid>

                                <Grid item xs={6} md={3}  >
                                    <Div>
                                        <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 15,000</Typography>
                                        <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Visitor</Typography>
                                    </Div>
                                </Grid>

                                <Grid item xs={6} md={3}  >
                                    <Div>
                                        <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 15,000</Typography>
                                        <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Social Media Followers</Typography>
                                    </Div>
                                </Grid>

                                <Grid item xs={6} md={3}  >
                                    <Div>
                                        <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 1,200</Typography>
                                        <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Email Subscribers</Typography>
                                    </Div>
                                </Grid>
                            </Grid>
                        </Div>
                    </Grid>

                    <Grid item xs={12} mt={1.5}>
                        <Div className='card' sx={[{ height: { md: "415px" }, p: 1, boxShadow: "0px 0px 4px lightgray", border: "1px solid white" }]} >
                            <Grid container sx={{ mt: { md: 0 } }} spacing={2}>
                                <Grid container spacing={2} item xs={12} md={6}  >
                                    <Grid item xs={12} >
                                        <Typography sx={[{ mb: 1, fontWeight: 600, color: '#000', fontSize: "16px" }]}>Prospects</Typography>
                                    </Grid>

                                    <Grid item xs={6} md={4}  >
                                        <Div>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>135</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Organinc Leads</Typography>
                                        </Div>
                                    </Grid>

                                    <Grid item xs={6} md={4}  >
                                        <Div>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>652</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>CPC Leads</Typography>
                                        </Div>
                                    </Grid>

                                    <Grid item xs={6} md={4}  >
                                        <Div>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>14</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>PPL Leads</Typography>
                                        </Div>
                                    </Grid>


                                    <Grid item xs={12} sx={{ mt: 4 }}>
                                        <Typography sx={[{ mb: 1, fontWeight: 400, color: '#000', fontSize: "12px" }]}>Closing Percentage Per Lead Category</Typography>
                                    </Grid>

                                    <Grid item xs={6} md={4}  >
                                        <Div sx={{ m: 1 }}>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>9.36%</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Organinc Leads</Typography>
                                        </Div>
                                    </Grid>

                                    <Grid item xs={6} md={4}  >
                                        <Div sx={{ m: 1 }}>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>1.92%</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>CPC Leads</Typography>
                                        </Div>
                                    </Grid>

                                    <Grid item xs={6} md={4.1}  >
                                        <Div sx={{ m: 1 }}>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>93.09%</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>PPL Leads</Typography>
                                        </Div>
                                    </Grid>

                                    <Grid item xs={6} md={4}  >
                                        <Div>
                                            <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "14px" }]}>14.28%</Typography>
                                            <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>SQL</Typography>
                                        </Div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6}  >
                                    <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "13px" }]}>Lead conversion funnel</Typography>
                                    <Funnel
                                        id="funnel"
                                        dataSource={dataSource}
                                        palette="Soft Pastel"
                                        argumentField="argument"
                                        valueField="value"
                                        height={330}
                                    >

                                        <Export enabled={false} />
                                        <Tooltip
                                            enabled={true}
                                            format="fixedPoint"
                                        />
                                        <Label
                                            visible={true}
                                            position="inside"
                                            backgroundColor="none"
                                        />
                                    </Funnel>
                                </Grid>
                            </Grid>
                        </Div>
                    </Grid>

                </Grid>

                <Grid item xs={12} >
                    <Div className='card' sx={[{ height: { md: "100px" }, p: 1, boxShadow: "0px 0px 4px lightgray", border: "1px solid white" }]} >
                        <Grid container sx={{ mt: { md: 0 } }} alignItems={'center'} spacing={2}>
                            <Grid item xs={6} md={2.4}  >
                                <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 600, color: '#000', fontSize: "16px" }]}>Financial KPIs</Typography>
                            </Grid>

                            <Grid item xs={6} sx={{ display: { xs: 'block', md: 'none' } }} md={2.4}  ></Grid>


                            <Grid item xs={6} md={2.4}  >
                                <Div>
                                    <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 15,000</Typography>
                                    <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Cost Per Lead</Typography>
                                </Div>
                            </Grid>

                            <Grid item xs={6} md={2.4}  >
                                <Div>
                                    <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 15,000</Typography>
                                    <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Cost Per Customer</Typography>
                                </Div>
                            </Grid>

                            <Grid item xs={6} md={2.4}  >
                                <Div>
                                    <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 1,200</Typography>
                                    <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Customer Lift Time Value</Typography>
                                </Div>
                            </Grid>

                            <Grid item xs={6} md={2.4}  >
                                <Div>
                                    <Typography sx={[{ mb: 1, textAlign: 'center', fontWeight: 800, color: '#fa8c02', fontSize: "16px" }]}>$ 1,200</Typography>
                                    <Typography sx={[{ textAlign: 'center', fontWeight: 400, color: '#000', fontSize: "12px" }]}>Return On Marketing Investment</Typography>
                                </Div>
                            </Grid>
                        </Grid>
                    </Div>
                </Grid>
            </Grid>
        </JumboContentLayoutMain>
    )
}

export default LeadPerformance
