import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain'
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Select,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import Setting from '../Setting';
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import React, { useEffect, useState } from 'react'
import { MdDelete } from "react-icons/md";
import { TableBody } from '@material-ui/core';
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { ButtonStyle, CRMTableHead, ComponentHeading, DialogBoxCancelButton, DialogBoxSaveButton, DisplayFlex, FontStyle } from 'app/pages/widgets/CRMStyles';
import { CRMTableCell, CRMTableRow } from 'app/pages/widgets/CRMTable';
import { Cancelbutton, CustomButton } from 'app/pages/widgets/StylesComponents';

function LeadPrediction() {
  const [datas, setdatas] = useState([])
  const [rows, setrows] = useState([])

  useEffect(() => {
    Predictionrecords();
  }, []);

  const createrId = localStorage.getItem("UserId");
  const token = localStorage.getItem("accesstoken");

  const Predictionrecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/UserAdminPredictionQuestionsAdditional/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "db": localStorage.getItem("DBName")
        },
      })
      .then((res) => {
        console.log("prediction", res.data.results);
        setdatas(res.data.results)
        var Id = res.data.results
        if (Id.length !== 0) {
          let arr = res.data.results
          let valueArr = []

          arr.map((item, index) => {
            const newitem = {
              id: item.id,
              fromscore: item.ScoreRange[0],
              toscore: item.ScoreRange[1],
              prediction: item.prediction,
              recommendedaction: item.Recommended_Action,
              allow: false,

            };
            // const newlist=[...itemList];
            // newlist.push(newitem);
            // setItemList(newlist);
            valueArr.push(newitem)

          })
          setrows(valueArr);
          console.log("Rows", rows);


          // setrows({...rows,fromscore:res.data.ScoreRange[0]})

        } else {
          const newitem = {
            allow: true,
            fromscore: "",
            toscore: "",
            prediction: "",
            recommendedaction: "",
          }
          setrows([...rows, newitem]);
        }
        console.log("Final rows", rows);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  }






  const handleAddRow = () => {
    const newitem = {
      fromscore: "",
      toscore: "",
      prediction: "",
      recommendedaction: "",
      allow: true

    };
    // const newlist=[...itemList];
    // newlist.push(newitem);
    // setItemList(newlist);

    setrows([...rows, newitem]);
  };

  const handleRemoveRow = (index, data) => {
    handleDeleteprediction(data)
    // console.log(rows)
    // alert(index)
    // setrows(oldValues => {
    //   return oldValues.filter((_, i) => i !== index)
    // })
  };

  const handleAddChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setrows(updatedRows);
  };

  const handlesaveprediction = (e) => {
    e.preventDefault()
    // console.log(rows)
    // console.log(datas)
    let arr = []
    rows.map((item1, index1) => {
      if (item1.id === undefined) {
        arr.push(item1)
      }
    })
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (item.fromscore === "" || item.fromscore === undefined || item.fromscore === null ||
          item.toscore === "" || item.toscore === undefined || item.toscore === null ||
          item.prediction === "" || item.prediction === undefined || item.prediction === null ||
          item.recommendedaction === "" || item.recommendedaction === undefined || item.recommendedaction === null) {
          toast.error("Invalid Data ");
        } else {
          let playload = {
            "ScoreRange": [
              item.fromscore,
              item.toscore
            ],
            "Recommended_Action": item.recommendedaction,
            "prediction": item.prediction,
            "Created_By": createrId,
            "Updated_By": createrId
          }
          console.log(playload)
          axios
            .post(`${BASE_URL}/useradmin/UserAdminPredictionQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success(" Created Successfully");
                // setrows([]);
                Predictionrecords()
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      })
    }



  }

  const handleDeleteprediction = (data) => {
    console.log(data.id)
    if (data.id !== undefined) {
      axios
        .delete(`${BASE_URL}/useradmin/UserAdminPredictionQuestionsCRUD/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "db": localStorage.getItem("DBName")
          },
        })
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            // setrows([]);
            Predictionrecords()
            toast.success(res.data);
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)

        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  }

  const handleupdateprediction = (index, data) => {
    // console.log(data)
    if (data.id !== undefined) {
      if (data.id == "" || data.fromscore === "" || data.toscore === "" || data.prediction === "" || data.recommendedaction === "") {
        toast.error("Invalid Data ");
      } else {
        let playload = {
          id: data.id,
          "ScoreRange": [
            data.fromscore,
            data.toscore
          ],
          "Recommended_Action": data.recommendedaction,
          "prediction": data.prediction,
          "Created_By": createrId,
          "Updated_By": createrId
        }
        axios
          .put(`${BASE_URL}/useradmin/UserAdminPredictionQuestionsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              "db": localStorage.getItem("DBName")
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              // Predictionrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)

          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  }







  return (
    <>
      <JumboContentLayoutMain>
        <Setting />
        <Typography sx={[ComponentHeading]}>
          Lead Prediction Rule
        </Typography>
        <form >
          <div>
            <Div sx={{
              display: "flex",
              justifyContent: "end",
              mt: -3,
              mr: 0.5,
              height: "40px",
            }}
            >

            </Div>
            <div>
              <Div sx={{ m: 1, ml: 0, mr: 0, p: 2, minHeight: "366px", }} >
                <Div sx={{ display: "block", }}>
                  <form >
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMin={286}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                      id="no-more-tables"
                    >
                      <TableContainer >
                        <Table stickyHeader className="table" sx={{ mt: 2 }}>
                          <TableHead sx={[CRMTableHead]} >
                            <CRMTableRow >
                              <CRMTableCell sx={{ fontWeight: 600, }}>
                                From Score
                              </CRMTableCell>
                              <CRMTableCell sx={{ fontWeight: 600 }}>
                                To Score
                              </CRMTableCell>
                              <CRMTableCell sx={{ fontWeight: 600 }}>
                                Predicted days
                              </CRMTableCell>
                              <CRMTableCell sx={{ fontWeight: 600 }}>
                                Recommended Actions
                              </CRMTableCell>
                              <CRMTableCell sx={{ fontWeight: 600 }}>
                                Action
                              </CRMTableCell>


                            </CRMTableRow>
                          </TableHead>
                          <TableBody>
                            {rows.length > 0 && rows?.map((data, index) => {
                              console.log("data2 : ", data)
                              return (
                                <CRMTableRow key={index} hover>
                                  <CRMTableCell data-title="From Score">
                                    <TextField
                                      sx={{ height: "35px", width: "120px" }}
                                      className="col-md-5 input-box"
                                      id="outlined-basic"
                                      variant="outlined"
                                      autoComplete="off"
                                      name='fromscore'
                                      value={data?.fromscore}
                                      onChange={(e) => { handleAddChange(index, e) }}
                                    // disabled={data?.allow}  
                                    />
                                  </CRMTableCell>

                                  <CRMTableCell data-title="To Score">
                                    <TextField
                                      sx={{ height: "35px", width: "120px" }}
                                      className="col-md-5 input-box"
                                      id="outlined-basic"
                                      variant="outlined"
                                      autoComplete="off"
                                      name='toscore'
                                      value={data?.toscore}
                                      onChange={(e) => { handleAddChange(index, e) }}
                                    // disabled={data.allow}

                                    />
                                  </CRMTableCell>

                                  <CRMTableCell data-title="Predicted days">
                                    <TextField
                                      sx={{ height: "35px", width: "120px" }}
                                      className="col-md-5 input-box"
                                      id="outlined-basic"
                                      variant="outlined"
                                      autoComplete="off"
                                      name='prediction'
                                      value={data?.prediction?.split("D")[0]}
                                      onChange={(e) => { handleAddChange(index, e) }}
                                    // disabled={data.allow}
                                    />
                                  </CRMTableCell>

                                  <CRMTableCell data-title="Recommended Actions">
                                    <TextField
                                      sx={{ width: "360px" }}
                                      className="input-box"
                                      variant="outlined"
                                      autoComplete="off"
                                      // multiline
                                      rows={2}
                                      name='recommendedaction'
                                      value={data?.recommendedaction}

                                      onChange={(e) => { handleAddChange(index, e) }}
                                    // disabled={data.allow}
                                    />


                                  </CRMTableCell>


                                  <CRMTableCell data-title="Action">
                                    <Div >
                                      <IconButton size="small" className="edit-icon"
                                        onClick={(e) => { handleupdateprediction(index, data) }}
                                        disabled={data?.allow}
                                      >
                                        <BiEdit />
                                      </IconButton>

                                      <Button
                                        color="error"
                                        onClick={(e) => handleRemoveRow(index, data)}

                                      >
                                        <MdDelete size={24} />
                                      </Button>
                                    </Div>
                                  </CRMTableCell>



                                </CRMTableRow>
                              )


                            })}
                          </TableBody>

                        </Table>
                      </TableContainer>

                    </JumboScrollbar>
                    <Div sx={[DisplayFlex, { justifyContent: 'flex-start' }]}>
                      <Button sx={{ mt: 1, color: '#10BBE5' }} onClick={handleAddRow}>
                        + Add More Lines
                      </Button>
                    </Div>

                    <Div sx={{ mt: 0, justifyContent: 'end', alignItems: 'center', display: 'flex', mr: 5, mb: 4 }}>
                      <CustomButton
                        onClick={handlesaveprediction}
                        label="Save"
                        type="submit"
                        Icon="saveIcon"
                        sx={{ mr: 1 }}
                      />
                      <Cancelbutton
                        label="Cancel"
                      />

                    </Div>
                  </form>

                </Div>


              </Div>
            </div>
          </div>
        </form>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>

  )
}

export default LeadPrediction