import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Menu,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  DialogContentText,
  Popover,
  Box,
} from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { useState } from "react";
import { BiEdit, BiFilterAlt, BiSolidDetail } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import { FaFileInvoice, FaFileInvoiceDollar } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePayment,
  MdOutlineFileUpload,
  MdOutlineDataSaverOn,
  MdOutlinePayments,
} from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { RiAiGenerate, RiRefreshFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { apiConfig } from "app/services/config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "app/services/auth-services";
import { SettingsIcon } from "app/icons/PngIcons";
import { BsArrowDownUp } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  ButtonStyle,
  CRMTableHead,
  Colors,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  componentTitle,
  deletedWarnigMsg,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { DataArray } from "@mui/icons-material";
import SearchBar from "app/pages/widgets/SearchField";
import {
  Cancelbutton,
  CustomButton,
  CustomTooltip,
  DeletedIcon,
  DialogBox,
  Dragabledialog,
  EditIcon,
  errorFilter,
  NoRecordsPage,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import { FaGear } from "react-icons/fa6";
import { GrPowerReset } from "react-icons/gr";
import { AutoHeight } from "app/redux/auth/localStorage";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import {
  AddonsubscriptionFilterState,
  licenseFilterState,
  reducerFilter,
} from "app/pages/settings/filter-reducers/FilterReducers";
import { IoMdPrint } from "react-icons/io";
import OverviewPage from "app/pages/components/OverviewPage";
import CreatePayments from "app/pages/sales/payments/CreatePayments";
import SubscriptionInvoice from "app/pages/sales/subscription/SubscriptionInvoice";

const AddonSubscription = ({ scrollHeight }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Updated_Date", label: "Date", bool: true },
    { sort: "Subscription_Id", label: "Subscription Id", bool: true },
    { sort: "Addonsubscriptionname", label: "Subscription Name", bool: true },
    // { sort: "Addon_Id", label: "Addon Id", bool: false },
    // { sort: "Invoice_Id", label: "Invoice Id", bool: false },
    // { sort: "Subscription_Id", label: "Subscription Id", bool: true },
    { sort: "Plan_Name", label: "Plan Name", bool: true },
    { sort: "SubscriptionDate", label: "Subscription date", bool: true },
    // { sort: "Renewaldate", label: "Renewal date", bool: false },
    // { sort: "Noofusers", label: "No of users", bool: false },
    // { sort: "Amountperuser", label: "Amount( Per User )", bool: true },
    // { sort: "Status", label: "Status", bool: true },
    // { sort: "Invoicegenflg", label: "status", bool: true },
  ]);

  const [showGenerateInvoice, setShowGenerateInvoice] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showOverview, setShowOverview] = useState(false);
  const [save, setSave] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [searchFiled, setSearchFiled] = useState("");
  const [deleteItem, setDeleteItem] = useState(false);

  const [datas, setdatas] = useState([]);
  const [editdatas, seteditdatas] = useState([]);
  const [invoicedatas, setinvoicedatas] = useState([]);
  const [viewdatas, setviewdatas] = useState([]);

  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [value, setValue] = useState("1");
  const [listChange, setListChange] = useState(true);
  const [changeinvoice, setchangeinvoice] = useState(false);
  const [changeplan, setChangePlan] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [sendAddRequest, setSendAddRequest] = useState(false);
  const [Modaldata1, setModaldata1] = useState([]);

  const [status, setstatus] = useState("Generate Invoice");

  // For Asecnding Order
  const [showsort, setShowsort] = useState(true);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = count;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    axios
      .get(`${BASE_URL}/superadmin/getList/?page=${newPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        // console.log(res.data.results);
        setdatas(res.data.results);
        setCount(res.data.count);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  // Filter Start --------------------------------------------------------

  const [filterState, filterDispatch] = useReducer(
    reducerFilter,
    AddonsubscriptionFilterState
  );

  const resetFilter = () => {
    filterDispatch({
      SubscriptionName: "",
      Subscriptiondate: "",
      Fromdate: "",
      Todate: "",
    });
  };

  const handleFilterNameChange = (e) => {
    const { name, value } = e.target;
    filterDispatch({ type: name, payload: value });
    console.log("Filter : ", name, value);
  };

  const filterData = async () => {
    let payload = {
      Addonsubscriptionname: filterState?.SubscriptionName,
      Subscriptiondate: filterState?.Subscriptiondate,
      fromdate: filterState?.Fromdate,
      todate: filterState?.Todate,
      Created_Date: new Date().toISOString().split("T")[0],
      Updated_Date: new Date().toISOString().split("T")[0],
      page: page,
    };

    let error = errorFilter(payload);

    if (Object.keys(error).length > 0) {
      toast.warning(
        `some fields are empty ${Object.keys(error)?.map((item) => item)}${
          Object.keys(error)?.length > 0 && ", "
        }`,
        {
          position: "top-right",
          autoClose: 500,
        }
      );
    } else {
      await axios
        .post(`${BASE_URL}/superadmin/Addonsubscriptionfilter/`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          let list =
            res.data?.results.map((item) => ({
              ...item,
              SubscriptionDate: item?.Subscription_Id?.SubscriptionDate || "",
              Plan_Name: item?.Subscription_Id?.Plan_Name || "",
            })) || [];

          console.log("change & upgradeplan", list);
          // Set the cloned and modified list to state
          setdatas(list);
          setCount(res.data.count);
          setShowFilter(false);
          setSearchFiled("");
        })
        .catch((error) => {
          console.log("ds", error);
          setdatas([]);
        });
    }
  };

  const RefreshData = () => {
    setShowFilter(null);
    resetFilter();
    getList();
  };

  // Filter End ------------------------------------------------------------

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    setVisitedPages(visitedPages);
  };

  const ShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const ChangePlan = (data) => {
    seteditdatas(data);
    setChangePlan(true);
    setListChange(false);
  };

  // code for settings open
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  //code for table sorting
  const sortData = (key, direction) => {
    const sortedData = [...datas];

    sortedData.sort((a, b) => {
      const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setdatas(sortedData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
    setShowsort(false);
  };

  const getList = () => {
    axios
      .get(BASE_URL + `/superadmin/addonsubscriptionlist/?page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((response) => {
        let list =
          response.data?.results.map((item) => ({
            ...item,
            SubscriptionDate: item?.Subscription_Id?.SubscriptionDate || "",
            Plan_Name: item?.Subscription_Id?.Plan_Name || "",
          })) || [];

        console.log("change & upgradeplan", list);
        // Set the cloned and modified list to state
        setdatas(list);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const handleDelete = (data) => {
    // console.log(data)
    if (!Boolean(data.id)) {
      toast.warning("id miss match ! ");
    } else {
      axios
        .delete(`${BASE_URL}/superadmin/addonsubscription/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("Delete", res.data);
          if (res.data.id === data.id) {
            toast.success("Deleted Sucessfully");
            getList();
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // generateInvoice function
  const generateInvoice = async (inv) => {
    let InvoiceAmount = Number(inv?.Addon_Id?.[0]?.Price);
    let NetAmount =
      Number(InvoiceAmount) + Number(inv?.Subscription_Id?.TaxAmount);

    let payload = {
      Invoice_Id: "",
      Subscription_Id: inv?.Subscription_Id?.id,
      InvoiceAmount: InvoiceAmount,
      NetAmount: NetAmount,
      TaxAmount: inv?.Subscription_Id?.TaxAmount,
      InvoiceFor: "invoice",
      Invoice_Code: "invoice",
      Created_Date: new Date().toISOString(),
      Updated_Date: new Date().toISOString(),
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };

    console.log("inv : ", inv, InvoiceAmount, NetAmount);
    try {
      const res = await axios.post(`${BASE_URL}/superadmin/Invoice/`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      });
      if (res.data) {
        toast.success("Invoice is Created successfully");
        if (res.data) {
          const patchres = await axios.patch(
            `${BASE_URL}/superadmin/addonsubscription/`,
            { id: inv?.id, Invoice_Id: res.data?.id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                Db: localStorage.getItem("DBName"),
              },
            }
          );

          getList();
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  // Function to fetch payment records based on Invoice_Id
  const getPaymentRecords = async (invoiceId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/superadmin/PaymentsAdditional/`,
        {
          params: { page: 1 },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );

      // Find and return the specific payment record by Invoice_Id
      return (
        response.data.results?.find(
          (record) => record?.Invoice_Id === invoiceId
        ) || {}
      );
    } catch (error) {
      console.error("Error fetching payment records:", error);
      return {};
    }
  };

  // onClick handler function
  const handlePaymentClick = async (data) => {
    try {
      const paymentData = await getPaymentRecords(data?.Invoice_Id?.id);

      const paymentDetails = {
        "Payment Id": paymentData?.Payment_Id || "",
        "Customer Name": paymentData?.Customer_Name || "",
        "Transaction No": paymentData?.Transaction_No || "",
        "Payment Mode": paymentData?.Payment_Mode || "",
        "Payment Date": paymentData?.Payment_Date || "",
        "Invoice Amount": toLocaleCurrency(paymentData?.InvoiceAmount) || "",
        "Paid Amount": toLocaleCurrency(paymentData?.paid_amount) || "",
        "Payment Details": paymentData?.Payment_details || "",
        "Updated Date": paymentData?.Updated_Date?.split("T")[0] || "",
      };

      console.log("Payment Details:", paymentDetails);

      setShowOverview(paymentDetails);
    } catch (error) {
      console.error("Error handling payment click:", error);
    }
  };

  useEffect(() => {
    getList();
    // getAddonSubscriptionRecords();
  }, []);

  const handleSearch = async (searchstring) => {
    await axios
      .get(
        `${BASE_URL}/superadmin/Addonsubscriptionsearch/?search=${searchstring}&page=${page}&Is_Deleted=False`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        }
      )
      .then((response) => {
        let list =
          response.data?.results.map((item) => ({
            ...item,
            SubscriptionDate: item?.Subscription_Id?.SubscriptionDate || "",
            Plan_Name: item?.Subscription_Id?.Plan_Name || "",
          })) || [];

        console.log("change & upgradeplan", list);
        // Set the cloned and modified list to state
        setdatas(list);
        setCount(response.data?.count);
      })
      .catch((error) => {
        setdatas([]);
        console.log("ds", error);
      });
  };

  useEffect(() => {
    resetFilter();
    if (tabValue == 0) {
      setSearchFiled("");
      getList();
    }
  }, [tabValue]);

  useEffect(() => {
    if (
      Object.values(filterState)?.some((state) => Boolean(state)) &&
      !Boolean(searchFiled)
    ) {
      setSearchFiled("");
      filterData();
    } else if (tabValue === 0) {
      getList();
    } else if (tabValue === 1) {
      if (Object.values(filterState)?.some((state) => Boolean(state))) {
        resetFilter();
      }
      handleSearch(searchFiled);
    }
  }, [page, searchFiled]);

  return (
    <>
      <JumboContentLayoutMain>
        <Box sx={{ mb: 2 }}>
          <Div className="flex items-center justify-between">
            <Typography sx={[componentTitle]}>
              Addon Subscription Request
            </Typography>
          </Div>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={10.5} md={6} display={"flex"} alignItems={"center"}>
              <IconButton
                sx={{ color: Colors?.Mblue }}
                onClick={(e) => setShowFilter(e.currentTarget)}
              >
                <BiFilterAlt size={22} />
              </IconButton>
              <Typography
                sx={[FontStyle, { color: Colors?.gray, fontWeight: 400 }]}
              >
                ( {count} ) Subscription
              </Typography>
            </Grid>
            {/* {!matches && (
              <Grid item xs={1} md={1}>
                <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <FaGear style={{ fontSize: 18 }} />
                </IconButton>
              </Grid>
            )} */}

            {/* Filter popover  */}
            <Popover
              open={Boolean(showFilter)}
              anchorEl={showFilter}
              onClose={ShowFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
            >
              <Box>
                <Div
                  sx={[
                    DisplayFlex,
                    CRMTableHead,
                    dialogTitle,
                    { px: 2, justifyContent: "space-between" },
                  ]}
                >
                  <Typography id="Typography-root" sx={[dialogTitle]}>
                    Filters
                  </Typography>
                  <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                    <GrPowerReset size={16} />
                  </IconButton>
                </Div>

                <Grid container spacing={2} sx={{ padding: "1rem" }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      id="Typography-root"
                      sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    >
                      Subscription Name
                    </Typography>
                    <TextField
                      type="text"
                      className={`col-12 input-box2`}
                      autoComplete="off"
                      placeholder="Addon subscription name"
                      name="SubscriptionName"
                      value={filterState?.SubscriptionName}
                      onChange={handleFilterNameChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      id="Typography-root"
                      sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    >
                      Subscription Date
                    </Typography>
                    <TextField
                      className={`col-12 input-box2`}
                      autoComplete="off"
                      name="Subscriptiondate"
                      type="date"
                      placeholder="Subscription Date"
                      value={filterState?.Subscriptiondate}
                      onChange={handleFilterNameChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      id="Typography-root"
                      sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    >
                      From Date
                    </Typography>
                    <TextField
                      type="date"
                      className={`col-12 input-box2`}
                      autoComplete="off"
                      placeholder=" From Date"
                      name="Fromdate"
                      value={filterState?.Fromdate}
                      onChange={handleFilterNameChange}
                      id="createdDate"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <Typography
                      id="Typography-root"
                      sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    >
                      To Date
                    </Typography>
                    <TextField
                      type="date"
                      className={`col-12 input-box2`}
                      autoComplete="off"
                      placeholder="  To Date"
                      id="lastDate"
                      name="Todate"
                      value={filterState?.Todate}
                      onChange={handleFilterNameChange}
                      disabled={!Boolean(filterState?.Fromdate)}
                      inputProps={{
                        min:
                          Boolean(filterState?.Fromdate) &&
                          new Date(filterState?.Fromdate)
                            ?.toISOString()
                            ?.split("T")[0],
                      }}
                    />
                  </Grid>
                </Grid>

                <Div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: 2,
                    gap: "10px",
                  }}
                >
                  <Cancelbutton onClick={ShowFilter} />

                  <CustomButton
                    onClick={() => filterData()}
                    type="submit"
                    label="Apply"
                    Icon="filterIcon"
                  />
                </Div>
              </Box>
            </Popover>

            <Grid item xs={12} md={6}>
              <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                <SearchBar
                  width={"250px !important"}
                  // value={searchFiled}
                  border={"1px solid lightgray"}
                  SearchData={(e) => {
                    setSearchFiled(e.target.value);
                    setTabValue(1);
                    if (!Boolean(e.target.value)) {
                      setTabValue(0);
                    }
                  }}
                />
              </Div>
            </Grid>
          </Grid>
        </Box>

        <Div>
          <Div sx={{ mt: { xs: 0, md: 1.5 } }}>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={AutoHeight(1.6)}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table stickyHeader sx={{ mt: 2, mb: 2 }}>
                <CustomTableHeader
                  headerMenuItems={columnMenuItems}
                  setHeadermenuItems={setColumnMenuItems}
                  setDatas={setdatas}
                  sortDatas={datas}
                />
                {datas?.length > 0 && (
                  <TableBody sx={{ mt: 2 }}>
                    {datas
                      ?.slice(visitedPages * 10, visitedPages * 10 + 10)
                      ?.map((data, index) => {
                        let invoicedata = Boolean(data?.Invoice_Id);
                        return (
                          <CRMTableRow key={index}>
                            {columnMenuItems?.map((item) => {
                              if (item?.bool) {
                                return (
                                  <CRMTableCell
                                    data-title={item?.label}
                                    align="left"
                                    style={{
                                      color:
                                        item?.label?.includes("Status") &&
                                        (!data?.[item?.sort]
                                          ? "orangered"
                                          : "green"),
                                    }}
                                  >
                                    {item?.label?.includes("Date")
                                      ? data?.[item?.sort]?.split("T")[0] || ""
                                      : item?.label?.includes("Id")
                                      ? data?.[item?.sort]?.[item?.sort]
                                      : item?.label?.includes("Status")
                                      ? data?.[item?.sort]
                                        ? "Paid"
                                        : "Unpaid"
                                      : data?.[item?.sort] || ""}
                                  </CRMTableCell>
                                );
                              }
                            })}

                            <CRMTableCell
                              align="center"
                              data-title="Action"
                              sx={[
                                DisplayFlex,
                                {
                                  cursor: "pointer",
                                  mb: 1,
                                  // justifyContent: matches ? "center" : "left",
                                },
                              ]}
                            >
                              {!invoicedata ? (
                                <CustomTooltip
                                  value="Invoice Generator"
                                  type="children"
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setShowGenerateInvoice(true);
                                      seteditdatas(data);
                                    }}
                                  >
                                    <RiAiGenerate
                                      style={{
                                        fontSize: 16,
                                        color: Colors?.Mblue,
                                      }}
                                    />
                                  </IconButton>
                                </CustomTooltip>
                              ) : (
                                <CustomTooltip
                                  value="Print Invoice"
                                  type="children"
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      seteditdatas({
                                        Customer_Id:
                                          data?.Subscription_Id?.Customer_Id,
                                        ...data?.Invoice_Id,
                                      });
                                      setOpenInvoice(true);
                                    }}
                                  >
                                    <IoMdPrint
                                      color={Colors?.Mblue}
                                      style={{ fontSize: 16 }}
                                    />
                                  </IconButton>
                                </CustomTooltip>
                              )}

                              {!data?.Invoicegenflg ? (
                                <CustomTooltip
                                  value="Create Payment"
                                  type="children"
                                >
                                  <IconButton
                                    disabled={!invoicedata}
                                    size="small"
                                    onClick={() => {
                                      setOpenCreateModal({
                                        Invoice_Id: data?.id,
                                        data: data?.Invoice_Id,
                                      });
                                    }}
                                  >
                                    <MdOutlinePayment
                                      color={
                                        invoicedata
                                          ? Colors?.Mblue
                                          : "lightgray"
                                      }
                                      style={{ fontSize: 16 }}
                                    />
                                  </IconButton>
                                </CustomTooltip>
                              ) : (
                                <CustomTooltip
                                  value="View Payment"
                                  type="children"
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => handlePaymentClick(data)}
                                  >
                                    <MdOutlinePayments
                                      color={Colors?.Mblue}
                                      style={{ fontSize: 16 }}
                                    />
                                  </IconButton>
                                </CustomTooltip>
                              )}

                              <CustomTooltip value="Apply" type="children">
                                <IconButton
                                  disabled={!data?.Invoicegenflg}
                                  size="small"
                                  onClick={() => setOpenApply(data)}
                                >
                                  <MdOutlineDataSaverOn
                                    color={
                                      data?.Invoicegenflg
                                        ? Colors?.Mblue
                                        : "lightgray"
                                    }
                                    style={{ fontSize: 16 }}
                                  />
                                </IconButton>
                              </CustomTooltip>

                              {/* <DeletedIcon
                              onClick={(e) => {
                                setDeleteItem(data);
                              }}
                            /> */}
                            </CRMTableCell>
                          </CRMTableRow>
                        );
                      })}
                  </TableBody>
                )}

                {/* code for Delete Action */}
                <DialogBox
                  open={deleteItem}
                  Content={deletedWarnigMsg}
                  onClickYes={() => {
                    setDeleteItem(false);
                    handleDelete(deleteItem);
                  }}
                  onClickNo={() => setDeleteItem(false)}
                />
              </Table>
              {datas?.length === 0 && (
                <NoRecordsPage title={"Additional Subscription Request"} />
              )}
            </JumboScrollbar>
          </Div>

          {/* code for pagination */}
          <Div
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Pagination
              color="success"
              count={totalPages <= 10 ? 1 : totalPages / 10}
              page={page}
              onChange={(e, newValue) => handleChangePage(e, newValue)}
              size="small"
              showFirstButton
              showLastButton
            />
          </Div>
        </Div>

        <Dragabledialog
          maxWidth="lg"
          open={openInvoice}
          title="Invoice Preview"
          onClose={() => setOpenInvoice(false)}
        >
          <SubscriptionInvoice
            openInvoice={openInvoice}
            setOpenInvoice={setOpenInvoice}
            datas={editdatas}
          />
        </Dragabledialog>

        {/* Create Payments modal */}
        <Dragabledialog
          maxWidth="lg"
          open={openCreateModal}
          title={
            openCreateModal?.type === "put" ? "Edit Payment" : "Create Payment"
          }
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogContent sx={{ p: 1 }}>
            <CreatePayments
              setOpen={setOpenCreateModal}
              // setDatas={setListSubscription}
              type={"invoice"}
              datas={openCreateModal?.data}
              GetRecords={async () => {
                const patchres = await axios.patch(
                  `${BASE_URL}/superadmin/addonsubscription/`,
                  { id: openCreateModal?.Invoice_Id, Invoicegenflg: true },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "accesstoken"
                      )}`,
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Db: localStorage.getItem("DBName"),
                    },
                  }
                );
                getList();
              }}
            />
          </DialogContent>
        </Dragabledialog>

        {/* paymnet overview */}
        <OverviewPage
          label={"Payment Details"}
          datas={showOverview}
          open={Boolean(showOverview)}
          setOpen={setShowOverview}
          onClose={() => setShowOverview(false)}
        />

        <DialogBox
          open={showGenerateInvoice}
          Content={"Do you want to generate an invoice for this subscription?"}
          onClickYes={() => {
            setShowGenerateInvoice(false);
            generateInvoice(editdatas);
          }}
          onClickNo={() => setShowGenerateInvoice(false)}
        />

        {/* Apply modal */}
        <Dragabledialog
          maxWidth="md"
          open={openApply}
          title="Addon Subscription Request"
          onClose={() => setOpenApply(false)}
        >
          <Grid container gap={1} sx={{ mt: 3 }}>
            <Grid item xs={12} md={12} className="row">
              <Typography className="input-label col-sm-12 col-md-4">
                Subscription Id{" "}
              </Typography>

              <TextField
                name="Subscription_Id"
                value={openApply?.Subscription_Id?.Subscription_Id}
                placeholder="# Subscription Id"
                className="input-box2 col-md-8"
              />
            </Grid>

            <Grid item xs={12} md={12} className="row">
              <Typography className="input-label col-sm-12 col-md-4">
                Subscription Name{" "}
              </Typography>

              <TextField
                name="Addonsubscriptionname"
                value={openApply?.Addonsubscriptionname}
                placeholder="Subscription Name"
                className="input-box2 col-md-8"
              />
            </Grid>

            <Grid item xs={12} md={12} className="row">
              <Typography className="input-label col-sm-12 col-md-4">
                Plan Name
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Plan Name"
                variant="outlined"
                value={openApply?.Plan_Name}
                // onChange={(e) =>
                //   setFormData((prevState) => ({
                //     ...prevState,
                //     Planname: e.target.value,
                //   }))
                // }
                name="Planname"
                className="input-box2 col-md-8"
              />
            </Grid>

            <Grid item xs={12} md={12} className="row">
              <Typography className="input-label col-sm-12 col-md-4">
                Subscription Date
              </Typography>
              <TextField
                id="outlined-basic"
                placeholder="Subscription Date"
                variant="outlined"
                className="input-box2 col-md-8"
                value={openApply?.SubscriptionDate}
                // onChange={(e) =>
                //   setFormData((updState) => ({
                //     ...updState,
                //     Subscription_Id: e.target.value,
                //   }))
                // }
                name="SubscriptionDate"
              />
            </Grid>
          </Grid>

          <Div
            sx={{
              my: 3,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              gap: 3,
            }}
          >
            <CustomButton
              label={"Apply"}
              Icon="saveIcon"
              onClick={async () => {
                const patchres = await axios.patch(
                  `${BASE_URL}/superadmin/addonsubscription/`,
                  {
                    id: openApply?.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "accesstoken"
                      )}`,
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Db: localStorage.getItem("DBName"),
                    },
                  }
                );

                if (patchres?.data) {
                  const payload = {
                    Created_By: parseInt(localStorage.getItem("UserId")),
                    To: openApply?.Subscription_Id?.Customer_Id?.Mail,
                    subject: "Your Addons Request Has Been Approved", // Use template literals for string interpolation
                    Body: `<p>Dear ${
                      openApply?.Subscription_Id?.Customer_Id?.Contact_Name
                    },</p>
                            <p>We are pleased to inform you that your request has been approved, and your Addons will be added.</p>
                            <p>Thank you for your continued partnership.</p>
                            <p>Best regards,<br>
                            ${localStorage.getItem("Username")} <br>
                             ${localStorage.getItem("Designation")} <br>
                             ${localStorage.getItem("Organizationname")}</p>`,
                  };

                  console.log("mailpayload", payload);

                  const response = await axios.post(
                    `${BASE_URL}/user/AddonMailSending/`,
                    payload,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "accesstoken"
                        )}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Db: localStorage.getItem("DBName"),
                      },
                    }
                  );

                  if (!response?.data?.Error) {
                    toast.success("Mail Sent Successfully");
                  } else {
                    toast.error("Mail isn't Sent Successfully");
                  }
                  setOpenApply(false);
                  getList();
                }
              }}
            />
            <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
          </Div>

          <DialogBox
            open={save}
            onClickYes={() => {
              setSave(false);
              setOpenApply(false);
            }}
            onClickNo={() => setSave(false)}
          />
        </Dragabledialog>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>
  );
};

export default AddonSubscription;
