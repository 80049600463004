import React, { memo, useEffect, useCallback, useMemo } from "react";
import { Box, Badge, Stack, Avatar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RiCheckLine } from "react-icons/ri";
import useResponsive from "../hook/useResponsive";
import { useStateContext } from "app/contexts/ContextProvider";
import { BASE_URL, socket } from "app/services/auth-services";
import { AvatarCircle } from "app/pages/widgets/StylesComponents";
import { useChatContext } from "../context/Chatcontext";

const truncateText = (text, length) => {
  return text?.length > length ? `${text.slice(0, length)}...` : text;
};

const StyledChatBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
  borderRadius: theme.shape.borderRadius,
  boxShadow: "2px 2px 5px 0px #d6dedf",
  padding: theme.spacing(2, 1),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: -0.7,
      left: -0.7,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(0.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ChatElement = ({
  user,
  check,
  createGroup,
  online,
  handleSelectPerson,
  person,
  avatarSize = "50px",
}) => {
  const isDesktop = useResponsive("up", "md");
  const { selectUser, setSelectUser, setConversations } = useChatContext();

  const handleSelectUser = async () => {
    if (createGroup !== "create group") {
      const userId = user?.id || user?.Assign_User;
      socket.emit("send_to_customer", {
        message: {
          db: "demo",
          RecevierId: userId,
          SenderId: Number(localStorage.getItem("UserId")),
        },
      });

      socket.on("receive_notification_message", (data) => {
        console.log("Received notification 2", user, data);
        if (data) {
          const currentUserId = Number(localStorage.getItem("UserId"));
          const selectedUserId = Number(user?.id);
          const { senderId, receiverId } = data;

          console.log(
            "User selected : ",
            selectedUserId === receiverId && currentUserId === senderId,
            selectedUserId === senderId && currentUserId === receiverId
          );

          if (
            (selectedUserId === receiverId && currentUserId === senderId) ||
            (selectedUserId === senderId && currentUserId === receiverId)
          ) {
            setSelectUser(user);
            setConversations(data?.results);
          }
        }
      });
    } else if (!person) {
      handleSelectPerson(user);
    }
  };

  const renderBadge = () => {
    if (check === true) {
      return (
        <Badge
          badgeContent={
            <RiCheckLine
              color="white"
              style={{ backgroundColor: "green", borderRadius: "50%" }}
            />
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            "& .MuiBadge-badge": {
              transform: "translate(5px)",
              padding: 0,
              bottom: -2,
              cursor: "pointer",
            },
          }}
        >
          <AvatarCircle
            size={avatarSize}
            title={!user?.User_Photo && user?.username}
            image={user?.User_Photo && `${BASE_URL}${user?.User_Photo}`}
          />
        </Badge>
      );
    }

    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant={
          createGroup !== "create group" && user?.User_Login_Status
            ? "dot"
            : undefined
        }
      >
        <AvatarCircle
          size={avatarSize}
          title={user?.username || user?.Group_Name}
          image={user?.User_Photo && `${BASE_URL}${user?.User_Photo}`}
        />
      </StyledBadge>
    );
  };

  return (
    <StyledChatBox onClick={() => handleSelectUser()}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          {!online ? (
            renderBadge()
          ) : (
            <AvatarCircle
              size={avatarSize}
              title={user?.username || user?.Group_Name}
              image={user?.User_Photo && `${BASE_URL}${user?.User_Photo}`}
            />
          )}
          <Stack spacing={0.3}>
            <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
              {user?.username || user?.Group_Name}
            </Typography>
            <Typography sx={{ fontSize: "10px" }}>
              {truncateText(user?.Designation, 20) ||
                (user?.Group_Name ? "no description" : "no designation")}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2} alignItems="center">
          <Typography sx={{ fontWeight: 600 }} variant="caption">
            {user?.time}
          </Typography>
          <Badge
            className="unread-count"
            color="primary"
            badgeContent={user?.unread}
          />
        </Stack>
      </Stack>
    </StyledChatBox>
  );
};

export default memo(ChatElement);
