import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import NoChat from "../components/chatHeaderAndFooter/NoChat";
import ChatComponent from "./ChatComponent";
import ChatLeftSide from "./ChatLeftSide";
import { useChatContext } from "../context/Chatcontext";
import { chatUsersList } from "../api/chatAPI";

function GeneralApp() {
  const { usersList, setUsersList } = useChatContext();
  const hasSelectedUser =
    Boolean(usersList) && Object.keys(usersList).length > 0;

  return (
    <Stack sx={{ width: "100%", height: "100%", mb: 5 }}>
      <Grid container>
        {/* Left Side */}
        <Grid item xs={12} md={4}>
          <ChatLeftSide />
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={8}>
          {hasSelectedUser ? (
            <ChatComponent />
          ) : (
            <Stack
              sx={{ height: "100%", width: "100%" }}
              alignItems="center"
              justifyContent="center"
            >
              <NoChat />
            </Stack>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default GeneralApp;
