import Div from "@jumbo/shared/Div";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "app/services/auth-services";

const ImageDialog = ({
  isImageDialogOpen,
  setIsImageDialogOpen,
  pageAccessToken,
}) => {
  const [open, setOpen] = useState(isImageDialogOpen);
  const fileInputRef = useRef(null);
  const [imageFile, setImageFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [token, setToken] = useState(pageAccessToken);

  useEffect(() => {
    setToken(pageAccessToken);
  }, [pageAccessToken]);

  const handleClose = () => {
    setIsImageDialogOpen(false);
  };

  const handlePost = async () => {
    console.log("urllll", `${BASE_URL}${filePath}`, pageAccessToken);
    const Headers = {
      Authorization: `Bearer ${pageAccessToken}`,
      "Content-Type": "application/json",
    };
    const payload = {
      url: `${BASE_URL}${filePath}`,
      published: "true",
    };
    await axios
      .post(
        "https://graph.facebook.com/v18.0/251682828032268/photos",
        payload,
        {
          headers: Headers,
        }
      )
      .then((res) => {
        console.log("fb post response", res);
        if (res?.status == 200) {
          toast.success("Posted Successfully");
          handleClose();
        }
      })
      .catch((error) => {
        console.error("FB Post error", error);
      });
  };

  const getFileUrlByFileName = (fileName) => {
    console.log("get file clicked");
    let token = localStorage.getItem("accesstoken");

    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    axios
      .get(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, {
        params: {
          FileName: fileName,
        },
        headers: headers,
      })
      .then((res) => {
        console.log("get file", res);
        if (res?.status === 200) {
          setFilePath(res.data?.File);
        }
      })
      .catch((error) => {});
  };

  const handleFileUpload = async (imageUrl, fileName) => {
    let token = localStorage.getItem("accesstoken");
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex);
    const uniqueFileName = `${nameWithoutExtension}-${Math.random()
      .toString(36)
      .substring(2, 8)}${extension}`;
    const payload = {
      FileName: uniqueFileName,
      File: imageUrl,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Urls: "",
      Description: "Test File",
      Created_By: localStorage.getItem("OrganizationId"),
      Updated_By: localStorage.getItem("OrganizationId"),
    };
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: headers,
      })
      .then((res) => {
        console.log("Image upload response", res);
        if (res) {
          getFileUrlByFileName(res?.data?.FileName);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("fileee", file);
    if (file) {
      const reader = new FileReader();
      console.log("readerrr", reader);
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        console.log("base64", base64);
        handleFileUpload(base64, file?.name);

        setImageFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const previewURL = URL.createObjectURL(file);
    console.log("previewURL", previewURL);
    setImagePreview(previewURL);
    setIsImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setIsImagePreviewOpen(false);
    setImagePreview(null);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { minWidth: "500px", minHeight: "300px" } }}
    >
      <DialogTitle className="dialog-head">Post Image</DialogTitle>
      {console.log("imageFile", imageFile)}
      <DialogContent
        sx={{ marginTop: "40px", display: "flex", justifyContent: "center" }}
      >
        <Div sx={{ border: "1px solid gray", width: "50%" }}>
          <Div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              cursor: "pointer",
              position: "relative",
            }}
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Image"
                style={{ width: "100%", height: "100%" }}
              ></img>
            ) : (
              <AddBoxIcon
                onClick={() => {
                  fileInputRef.current.click();
                }}
              />
            )}
            {isImagePreviewOpen && (
              <IconButton
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "white",
                }}
                onClick={handleCloseImagePreview}
              >
                <CancelIcon />
              </IconButton>
            )}
          </Div>
        </Div>
        <Input
          type="file"
          inputRef={fileInputRef}
          sx={{ display: "none" }}
          onChange={handleFileChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button className="dialog-close-btn" onClick={handleClose}>
          Close
        </Button>
        <Button className="dialog-save-btn" onClick={handlePost}>
          Post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;
