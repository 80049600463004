import React, { useEffect, memo } from "react";
import GeneralApp from "./dashboard/GeneralApp";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { ChatProvider, useChatContext } from "./context/Chatcontext"; // Correct import path

function MainChat() {
  return (
    <ChatProvider>
      <JumboContentLayoutMain>
        <GeneralApp />
      </JumboContentLayoutMain>
    </ChatProvider>
  );
}

export default memo(MainChat);
