import Div from '@jumbo/shared/Div'
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const FacebookLogin = ({ isLoginOpen, setIsLoginOpen, FBpageAccessToken, setFBPageAccessToken }) => {
    
    const permissionsArray = ['pages_manage_cta', 'pages_show_list', 'pages_read_engagement', 'pages_manage_metadata', 'pages_manage_ads', 'pages_manage_posts', 'pages_manage_engagement', 'pages_manage_instant_articles', 'pages_messaging', 'pages_read_user_content']
    const [open, setOpen] = useState(isLoginOpen)
    const [clientId, setClientId] = useState('254942504251096')
    const [clientSecret, setClientSecret] = useState('373bee3f2b183a495e6ea72e9a783d95')
    const [redirectUri, setRedirectUri] = useState('http://localhost:3000/facebook')
    const [permissions, setPermissions] = useState(permissionsArray)
    const [pageAccessToken, setPageAccessToken] = useState(FBpageAccessToken)
    const [userAccessToken, setUserAccessToken] = useState('')
    const [longLivedUserAccessToken, setLongLivedUserAccessToken] = useState('')


    const handleClose = () => {
        setIsLoginOpen(false)
    }

    useEffect(() => {
        setPageAccessToken(FBpageAccessToken)
    }, [FBpageAccessToken])

    
    const getAccessToken = async (code) => {
        // console.log("urlString", urlString)
        // const url = new URL(urlString);
        // const code = url.searchParams.get("code");
        console.log("code", code)
        const params = {
            "client_id": clientId,
            "redirect_uri": redirectUri,
            "client_secret": clientSecret,
            "code": code
        }
        await axios.get('https://graph.facebook.com/v18.0/oauth/access_token', { params: params })
            .then((res) => {
                console.log("res for token", res)
                if (res?.status == 200) {
                    setUserAccessToken(res?.data?.access_token)
                    // setFBPageAccessToken(res?.data?.access_token)
                }
            })
            .catch((error) => { console.error("error while getting token", error) })
    }

    // useEffect(() => {
    //     // const params = {
    //     //     "access_token": userAccessToken
    //     // }
    //     // axios.get('https://graph.facebook.com/me/accounts', { params: params })
    //     //     .then((res) => {
    //     //         console.log("page access token response", res)
    //     //         if (res?.status == 200) {
    //     //             let pageAccToken = res?.data?.data
    //     //             pageAccToken?.map((item) => {
    //     //                 setFBPageAccessToken(item.access_token)
    //     //             })
    //     //         }
    //     //     })
    //     //     .catch((error) => {
    //     //         console.error("Error", error)
    //     //     })

    //     const params = {
    //         access_token: userAccessToken
    //     }
    //     axios.get('https://graph.facebook.com/me/accounts', { params: params })
    //         .then((res) => {
    //             console.log("long lived user access token", res)
    //             if (res?.status == 200) {
    //                 let data = res?.data?.data
    //                 data?.map((item) => {
    //                     setLongLivedUserAccessToken(item.access_token)
    //                 })
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error", error)
    //         })
    // }, [userAccessToken])


    // useEffect(() => {
    //     // const params = {
    //     //     "grant_type": "fb_exchange_token",
    //     //     "client_id": clientId,
    //     //     "client_secret": clientSecret,
    //     //     "fb_exchange_token": longLivedUserAccessToken
    //     // }
    //     // axios.get('https://graph.facebook.com/v18.0/oauth/access_token', { params: params })
    //     //     .then((res) => {
    //     //         console.log("page access token response", res)
    //     //         if (res?.status == 200) {
    //     //             setFBPageAccessToken(res?.data?.access_token)
    //     //         }
    //     //     })
    //     //     .catch((error) => {
    //     //         console.error("Error", error)
    //     //     })

    //     //for storing in database
    //     let token = localStorage.getItem('accesstoken')
    //     const payload = {
    //         "client_id": clientId,
    //         "client_secret": clientSecret,
    //         "fb_exchange_token": longLivedUserAccessToken,
    //         "App_Id": clientId,
    //         "Secret_Id": clientSecret,
    //         // "Created_By": localStorage.getItem("UserId"),
    //         // "Updated_By": localStorage.getItem("UserId"),
    //         // "UserId": localStorage.getItem("UserId"),
    //         "Created_By": 1,
    //         "Updated_By": 1,
    //         "UserId": 1

    //     }
    //     const headers = {
    //         "db": localStorage.getItem('DBName'),
    //         Authorization: `Bearer ${token}`,
    //         "Content-Type": "application/json",
    //         Accept: "application/json",
    //     }
    //     axios.post('http://127.0.0.1:8000/user/FBAccessToken/', payload, {
    //         headers: headers
    //     })
    //         .then((res) => {
    //             console.log("page access token response", res)
    //             if (res?.status == 200) {
    //                 setFBPageAccessToken(res?.data?.access_token)
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error", error)
    //         })
    // }, [longLivedUserAccessToken])


    const handleLogin = () => {
        try {
            const auth_url = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${permissions}`;
            window.location.href = auth_url
        }
        catch (error) {
            console.error(error)
        }

    }

    // useEffect(() => {
    //     console.log("triggered useEffect")
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const code = urlParams.get('code');

    //     if (code) {
    //         getAccessToken(code);
    //     }
    // }, [])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "600px", minHeight: '300px' } }}
        >
            <DialogTitle className='dialog-head' >Facebook Login</DialogTitle>
            {console.log("permissions", permissions)}
            <DialogContent sx={{ marginTop: '10px' }}>
                <Div>
                    <Div className='row mt-3 '>
                        <Div className='col-4'>
                            <Typography
                                mb={0.3}
                                sx={{ fontWeight: 600, fontSize: "14px", }}
                            >
                                App Id
                            </Typography>
                        </Div>
                        <Div className="col-6">
                            <TextField value={clientId} type='text' onChange={(e) => { setClientId(e.target.value) }}></TextField>
                        </Div>
                    </Div>
                </Div>
                <Div>
                    <Div className='row mt-3 '>
                        <Div className='col-4'>
                            <Typography
                                mb={0.3}
                                sx={{ fontWeight: 600, fontSize: "14px", }}
                            >
                                Client Secret
                            </Typography>
                        </Div>
                        <Div className="col-6">
                            <TextField value={clientSecret} type='text' onChange={(e) => { setClientSecret(e.target.value) }}></TextField>
                        </Div>
                    </Div>
                </Div>
                <Div>
                    <Div className='row mt-3 '>
                        <Div className='col-4'>
                            <Typography
                                mb={0.3}
                                sx={{ fontWeight: 600, fontSize: "14px", }}
                            >
                                Permissions
                            </Typography>
                        </Div>
                        <Div className="col-6">
                            {/* <TextField value={permissions} type='text' onChange={(e) => { setPermissions(e.target.value) }}></TextField> */}
                            <FormControl fullWidth >
                                <Autocomplete
                                    multiple
                                    id="size-small-standard-multi"
                                    size="small"
                                    options={permissionsArray}
                                    getOptionLabel={(option) => {
                                        if (typeof option === "string") {
                                            return option;
                                        }
                                        return "";
                                    }}
                                    value={permissions}
                                    onChange={(e, newValue) => {
                                        setPermissions(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Div>
                    </Div>
                </Div>
            </DialogContent >
            <DialogActions>
                <Button className='dialog-cancel-btn' onClick={handleClose}>Cancel</Button>
                <Button className='dialog-save-btn' onClick={handleLogin}>Login</Button>
            </DialogActions>
        </Dialog >
    )
}

export default FacebookLogin