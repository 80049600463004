// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sendMail {
    position: absolute;
    bottom: 0px;
    right: 50px;
    background-color: #e6d9d9;
    width: 40%;
    height: 30%;
    max-width: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid whitesmoke;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 24);
}

.sendMail_header {
    padding: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: 171616;
}

.sendMail_send {
    text-transform: capitalize;
    margin: 15px;
}

.sendMail_close {
    cursor: pointer;
}

.sendMail>form {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.sendMail>form>input {
    height: 30px;
    padding: 15px;
    border: none;
    border-bottom: 1px solid #f0e5e5;
    outline: none;
}

.sendMail_message {
    flex: 1 1;
}

.sendMail_error {
    background-color: white;
    color: red;
    text-align: right;
    padding: 2px;
}

.sendMail_options>button {
    background-color: #534b4b;
    margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/GmailAPI/SendMail.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,4BAA4B;IAC5B,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".sendMail {\n    position: absolute;\n    bottom: 0px;\n    right: 50px;\n    background-color: #e6d9d9;\n    width: 40%;\n    height: 30%;\n    max-width: 500px;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    border: 1px solid whitesmoke;\n    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 24);\n}\n\n.sendMail_header {\n    padding: 13px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: 171616;\n}\n\n.sendMail_send {\n    text-transform: capitalize;\n    margin: 15px;\n}\n\n.sendMail_close {\n    cursor: pointer;\n}\n\n.sendMail>form {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n}\n\n.sendMail>form>input {\n    height: 30px;\n    padding: 15px;\n    border: none;\n    border-bottom: 1px solid #f0e5e5;\n    outline: none;\n}\n\n.sendMail_message {\n    flex: 1;\n}\n\n.sendMail_error {\n    background-color: white;\n    color: red;\n    text-align: right;\n    padding: 2px;\n}\n\n.sendMail_options>button {\n    background-color: #534b4b;\n    margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
