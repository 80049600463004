import React, { useEffect } from "react";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TextField,
  Typography,
  Pagination,
  Autocomplete,
  Checkbox,
  Box,
  Popover,
  useMediaQuery,
  FormControl,
  FormGroup,
  Menu,
  ListItemText,
  ListItemIcon,
  List,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";

import { BiFilterAlt } from "react-icons/bi";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  CRMTableHead,
  Colors,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  avatarColors,
  componentTitle,
  dialogFontSize,
  input_label,
} from "../widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "../widgets/CRMTable";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import {
  AvatarCircle,
  Cancelbutton,
  CustomButton,
  Customgrid,
  Dragabledialog,
  EditIcon,
  LoadingFullPageSkel,
  NoRecordsPage,
  SelectDropdown,
  avatarColorFun,
  formatDate,
} from "../widgets/StylesComponents";
import CustomTableHeader from "../widgets/CustomTableHeader";
import { AutoHeight } from "app/redux/auth/localStorage";
import { GrPowerReset } from "react-icons/gr";
import { useQuery, useQueryClient } from "react-query";
import { TiEdit } from "react-icons/ti";
import { FaGear } from "react-icons/fa6";
import "./Lead.css";

const UnAsignedLeads = () => {
  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const matches = useMediaQuery("(min-width:759px)");
  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "LeadId", label: "Lead Id", bool: true },
    { sort: "EmailId", label: "Email Id", bool: true },
    { sort: "PhoneNo", label: "Phone No", bool: true },
    { sort: "LeadType", label: "Lead Source", bool: true },
    { sort: "Owner", label: "Lead Owner", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "Lead_Status", label: "Lead Status", bool: true },
    { sort: "LeadLocation", label: "Lead Location", bool: true },
    { sort: "CompanyName", label: "Company Name", bool: false },
    { sort: "LeadIndustry", label: "Industry Type", bool: true },
    { sort: "City", label: "City", bool: false },
    { sort: "State", label: "State", bool: false },
    { sort: "Country", label: "Country", bool: true },
  ]);

  const [page, setPage] = useState(1);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contacts, setContacts] = useState([]); // initial state with one empty input
  const [age, setAge] = React.useState("");

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [fadeEdit, setFadeEdit] = useState(null);
  const [openAssignLead, setOpenAssignLead] = useState(null);

  const [leanOwnerName, setLeanOwnerName] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //  For Filter
  const [showFilter, setShowFilter] = useState(false);

  // Refresh Data
  const [datas, setDatas] = useState([]);

  // For Asecnding Order
  const [showsort, setShowsort] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  //code for table sorting
  // const sortData = (key, direction) => {
  //   const sortedData = [...datas];

  //   sortedData.sort((a, b) => {
  //     const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
  //     const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

  //     if (valueA < valueB) {
  //       return direction === "ascending" ? -1 : 1;
  //     }
  //     if (valueA > valueB) {
  //       return direction === "ascending" ? 1 : -1;
  //     }
  //     return 0;
  //   });

  //   setDatas(sortedData);
  // };

  // const handleSort = (key) => {
  //   let direction = "ascending";
  //   if (sortConfig.key === key && sortConfig.direction === "ascending") {
  //     direction = "descending";
  //   }
  //   setSortConfig({ key, direction });
  //   sortData(key, direction);
  //   setShowsort(false);
  // };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  //  Show Purpose
  const [notificationActive, setNotificationActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");
  const item = "crmuseradmin1";
  const [notificationMessage, setNotificationMessage] = useState(null); // State to hold the notification message
  const [number, setNumber] = useState(null);
  const [leadindustry, setLeadindustry] = useState([]);

  const [userNameLists, setUserNameLists] = useState([]);

  const [getSalesperson_name, setgetSalesperson_name] = useState("");

  // const getData = async () => {
  //   try {
  //     const res1 = await axios.get(
  //       `${BASE_URL}/user/InboxEmailStoring/?UserId=${localStorage.getItem("UserId")}&MailId=ravimech476@gmail.com`,
  //       {
  //         headers: {
  //           db: item,
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       }
  //     );

  //     const res2 = await axios.get(`${BASE_URL}/user/InboxEmailCRUD/`, {
  //       headers: {
  //         db: item,
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     });

  //     setDatas1(res2.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (isLoggedIn === "true") {
  //     getData();

  //     const interval = setInterval(() => {
  //       getData();
  //     }, 5000);

  //     return () => clearInterval(interval);
  //   }
  // }, [isLoggedIn]);

  // useEffect(() => {
  //   if (isLoggedIn === "true") {
  //     const currentEmailCount = datas1.length;
  //     if (currentEmailCount > prevEmailCountRef.current) {
  //       const newlyAddedData = datas1.slice(prevEmailCountRef.current);
  //       showNotification(newlyAddedData);
  //     }
  //     prevEmailCountRef.current = currentEmailCount;
  //   }
  // }, [datas1, isLoggedIn]);

  // const closeNotification = () => {
  //   setNotificationActive(false);
  //   setNewEmailData(null);
  // };

  // Get Uqualified Lead List

  const UnqualifiedLead = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/CombinedAssignedList/`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("UnqualifiedLead response", response?.data);
      // setDatas(response?.data);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // For Delete Functionality
  const Deletedata = () => {
    axios
      .delete(`${BASE_URL}/user/UserLeadfromcontactsCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
        console.log("error", error);
      });
  };

  // Geting the Job Tittle From UserAdmin Table
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  const [tabValue, setTabValue] = useState("");

  const handleChange = async (e, newValue) => {
    try {
      if (!Boolean(newValue)) {
        getUnqualifiedLeadRefetch();
        setTabValue(newValue);
        return;
      }

      console.log("newValue", newValue);
      setTabValue(newValue);
      const response = await axios.get(
        `${BASE_URL}/user/CombinedLeadFilterSearch/?Created_By=${userId}&Industry_Type=${newValue?.value}`,
        {
          headers: {
            db: "crmuseradmin",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setDatas(response?.data);
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  // For Checking the Checkbox Value
  const [selecteduser, setSelecteduser] = useState([]);

  const selectedData = (data) => {
    let updatedata = [...selecteduser];

    let bool = selecteduser?.findIndex(
      (user) => user.id == data.id && user.LeadType == data.LeadType
    );
    if (bool == -1) {
      updatedata.push(data);
    } else {
      updatedata.splice(bool, 1);
    }
    setSelecteduser(updatedata);

    console.log("updatedata", updatedata, data);
  };

  // Send Mail Functionality
  const handleSendQuestions = async () => {
    var mailContent = `<div style="width: 100%; font-family: Arial, sans-serif;">
    <h4 className="mb-2 text-muted">Lead Qualify Questions</h4>
    <br/>
    <p>
      Click here <a href="{{url}}">Click Here</a>.
    </p>
    <br/>
    <p>Best Regards,</p>
    <p>${userDetails && userDetails?.username},</p>
    <p>${userDetails && userDetails?.Designation},</p>
    </div>`;
    var payload = {
      user: selecteduser,
      mailhtml: mailContent,
      UserId: localStorage.getItem("UserId"),
    };
    await axios
      .post(`${BASE_URL}/user/SendLeadQualifyQuestions/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log("res", res);
        toast.success("Mail Send Successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setTabValue("");
        setSelecteduser([]);
        getUnqualifiedLeadRefetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/useradmin/UsersList/`, {
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Users List", response.data);
      // setUserNameLists(response.data);
      return response?.data;
    } catch (error) {
      console.log("Error in getting user list", error);
    }
  };

  const handleUpdate = async (data) => {
    if (data?.LeadType === "From Contact") {
      try {
        var payload = {
          id: data.id,
          Lead_Owner: leanOwnerName?.username,
        };
        axios
          .patch(`${BASE_URL}/user/UserLeadfromcontactsCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            toast.success("Lead Owner Asigned successfully", {
              position: "top-right",
              closeButton: true, // Allow manual close
              closeOnClick: true,
            });

            getUnqualifiedLeadRefetch();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        console.log("Error in getting user list", error);
      }
    } else if (data?.LeadType === "From Enquiry") {
      try {
        var payload = {
          id: data.id,
          Lead_Owner: leanOwnerName?.username,
        };
        axios
          .patch(`${BASE_URL}/user/UserLeadfromenquiryCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            toast.success("Lead Owner Asigned successfully", {
              position: "top-right",
              closeButton: true, // Allow manual close
              closeOnClick: true,
            });
            getUnqualifiedLeadRefetch();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        console.log("Error in getting user list", error);
      }
    } else if (data?.LeadType === "From Web") {
      try {
        var payload = {
          id: data.id,
          Lead_Owner: leanOwnerName?.username,
        };
        axios
          .patch(`${BASE_URL}/user/LeadCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            toast.success("Lead Owner Asigned successfully", {
              position: "top-right",
              closeButton: true, // Allow manual close
              closeOnClick: true,
            });
            getUnqualifiedLeadRefetch();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        console.log("Error in getting user list", error);
      }
    } else if (data?.LeadType === "From SocialMedia") {
      try {
        var payload = {
          id: data.id,
          Lead_Owner: leanOwnerName?.username,
        };
        axios
          .patch(`${BASE_URL}/user/UserLeadfromSocialMediaCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            toast.success("Lead Owner Asigned successfully", {
              position: "top-right",
              closeButton: true, // Allow manual close
              closeOnClick: true,
            });
            getUnqualifiedLeadRefetch();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        console.log("Error in getting user list", error);
      }
    } else if (data?.LeadType === "From Campaign") {
      try {
        var payload = {
          id: data.id,
          Lead_Owner: leanOwnerName?.username,
        };
        axios
          .patch(`${BASE_URL}/user/UserLeadfromCampaignCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then(() => {
            toast.success("Lead Owner Asigned successfully", {
              position: "top-right",
              closeButton: true, // Allow manual close
              closeOnClick: true,
            });

            getUnqualifiedLeadRefetch();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        console.log("Error in getting user list", error);
      }
    }

    setOpenAssignLead(false);
    setLeanOwnerName({});
  };

  // For Filter Functionality
  const [contry, setContry] = useState("");
  const [cty, setCty] = useState("");
  const [indus, setIndus] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setToDate] = useState("");
  const [statefil, setStatefil] = useState("");

  const {
    data: getTerUserLists,
    isLoading: getTerUserListsLoading,
    isError: getTerUserListsError,
    refetch: getTerUserListsRefetch,
  } = useQuery(
    ["getTerUserLists"],
    fetchUserData, // Assuming 1 as the initial page number
    { staleTime: Infinity }
  );

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  const {
    data: getUnqualifiedLead,
    isLoading: getUnqualifiedLeadLoading,
    isError: getUnqualifiedLeadError,
    refetch: getUnqualifiedLeadRefetch,
  } = useQuery(["getUnqualifiedLeadLists"], UnqualifiedLead, {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (getUserLoopUp) {
      setLeadindustry(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type") || []
      );
    }
  }, [getUserLoopUp]);

  useEffect(() => {
    if (getUnqualifiedLead !== undefined && getUnqualifiedLead !== null) {
      setDatas(getUnqualifiedLead);
      // setCount(getUnqualifiedLead?.count)
    }
  }, [getUnqualifiedLead]);

  useEffect(() => {
    if (getTerUserLists) {
      setUserNameLists(getTerUserLists);
    }
  }, [getTerUserLists]);

  const handleChangeItem = (event, newValue, index, data) => {
    const updatedRows = { ...data, Lead_Owner: newValue?.username || "" };
    console.log("updatedRows[index]", updatedRows, data);
    // setasignOwner(updatedRows);
  };

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas]?.sort((a, b) =>
      value === "A-Z"
        ? a?.LeadFirstName?.localeCompare(b?.LeadFirstName)
        : b?.LeadFirstName?.localeCompare(a?.LeadFirstName)
    );
    setDatas(sortedData);
    console.log("sortedData", value, "A-Z", sortedData);
    // setIsSortName(value);
  };

  return (
    <JumboContentLayoutMain>
      <ToastContainer />
      <Box sx={{ p: 2 }}>
        <Box sx={{}}>
          <Box sx={{ mb: 2 }} id="heads">
            <Box className="flex items-center justify-between flex-wrap">
              <Typography
                sx={[componentTitle, { color: Colors?.gray, fontWeight: 700 }]}
              >
                Unassigned Lead List
              </Typography>

              <Div className="flex items-center justify-between">
                <SelectDropdown
                  className="search-select3"
                  defaultValue={"A-Z"}
                  onChange={sortData}
                  options={["A-Z", "Z-A"]}
                  width="70px"
                />

                {matches && (
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Div>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={10.5}
                md={6}
                display={"flex"}
                alignItems={"center"}
              >
                {/* <IconButton
                  sx={{ color: Colors?.Mblue }}
                  onClick={dialogFilter}
                >
                  <BiFilterAlt size={22} />
                </IconButton> */}
                <Typography
                  sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                >
                  ( {datas?.length} ) Unassigned Leads
                </Typography>
              </Grid>
              {!matches && (
                <Grid item xs={1} md={1}>
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              )}

              {/* Filter popover  */}

              {/* <Popover
                open={Boolean(showFilter)}
                anchorEl={showFilter}
                onClose={() => setShowFilter(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
              >
                <Box>
                  <Div
                    sx={[
                      DisplayFlex,
                      CRMTableHead,
                      dialogTitle,
                      { px: 2, justifyContent: "space-between" },
                    ]}
                  >
                    <Typography sx={[dialogTitle]}>Filters</Typography>
                    <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                      <GrPowerReset size={16} />
                    </IconButton>
                  </Div>

                  <form style={{ padding: "1rem" }} onSubmit={filterSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Country
                        </Typography>
                        <Autocomplete
                          className="col-12 search-select33"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={Country.getAllCountries() || []}
                          value={contry?.name}
                          onChange={(e, newValue) => {
                            setContry(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          State
                        </Typography>
                        <Autocomplete
                          className=" col-12 search-select33"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          value={statefil?.name}
                          options={
                            State.getStatesOfCountry(contry?.isoCode) || []
                          }
                          onChange={(e, newValue) => {
                            setStatefil(newValue);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value || value === ""
                          }
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="State" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          City
                        </Typography>
                        <Autocomplete
                          className="col-12 search-select33"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={
                            City.getCitiesOfState(
                              statefil?.countryCode,
                              statefil?.isoCode
                            ) || []
                          }
                          value={cty?.name}
                          onChange={(e, newValue) => {
                            setCty(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="City" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Industry Type
                        </Typography>
                        <TextField
                          type="text"
                          className="col-12 input-box2"
                          autoComplete="off"
                          placeholder="Industry Type"
                          value={indus}
                          onChange={(e) => {
                            setIndus(e.target.value);
                          }}
                          sx={{ mt: -0.5 }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          From Date
                        </Typography>
                        <TextField
                          type="date"
                          className="col-12 input-box2"
                          value={fromdate}
                          onChange={(e) => {
                            setFromdate(e.target.value);
                          }}
                          id="createdDate"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          To Date
                        </Typography>
                        <TextField
                          value={todate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          type="date"
                          className="col-12 input-box2"
                          id="lastDate"
                        />
                      </Grid>
                    </Grid>

                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        gap: "10px",
                      }}
                    >
                    
                      <Cancelbutton onClick={() => setShowFilter(null)} />
                      <CustomButton
                        type="submit"
                        label="Apply"
                        Icon="filterIcon"
                      />
                    </Div>
                  </form>
                </Box>
              </Popover> */}

              {/* <Grid item xs={12} md={4}>
              <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                <SearchBar
                  SearchData={SearchData}
                  width={"100%"}
                  color={"black"}
                />
              </Div>
            </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                  <CustomButton
                    onClick={() => setShowAccounts("create")}
                    label="Create"
                    Icon="createIcon"
                    endIcon={true}
                  />
                </Div>
              </Grid> */}
            </Grid>
          </Box>
          <List sx={{ mt: 0 }}>
            {datas?.length > 0 &&
              datas?.map((data, idx) => {
                // if (idx < 2) {

                let image =
                  (BASE_URL +
                    data?.File?.split("https://backend.crmfarm.app")[1] ||
                    data?.File) ??
                  "";

                return (
                  <Div
                    // onMouseOver={() => setFadeEdit(idx)}
                    // onMouseLeave={() => setFadeEdit(null)}
                    className=""
                    key={idx}
                    sx={{
                      minHeight: "124px",
                      p: { xs: 0.5, sm: 2 },
                      px: { xs: 0.5, md: 7 },
                      my: 2,
                      bgcolor: "white",
                      borderRadius: { xs: "5px", md: "10px" },
                      ":hover": {
                        boxsShadow:
                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
                      },
                      cursor: "default",
                    }}
                  >
                    <Grid
                      container
                      alignItems={"center"}
                      spacing={2}
                      sx={{ pr: "5%" }}
                    >
                      <Grid item xs={12} md={1.5}>
                        <div
                          style={{ marginTop: "6px" }}
                          // onClick={() => handleClickOverviewNotification(data)}
                        >
                          <AvatarCircle
                            size="90px"
                            fontSize="30px"
                            bgColor={avatarColorFun(idx)}
                            title={!Boolean(data?.File) && data?.LeadFirstName}
                            image={Boolean(data?.File) ? image : false}
                          />
                        </div>
                      </Grid>

                      <Grid container spacing={1} item xs={12} md={10.5}>
                        <Grid item xs={12}>
                          <Box
                            sx={[
                              DisplayFlex,
                              {
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                position: "relative",
                              },
                            ]}
                          >
                            <Typography
                              sx={[
                                {
                                  fontSize: "18px",
                                  fontWeight: 700,
                                  color: `${Colors?.Mblue}`,
                                },
                              ]}
                            >
                              {data?.LeadFirstName + " " + data?.LeadLastName ??
                                ""}
                            </Typography>
                            <Div sx={[DisplayFlex]}>
                              {/* <Div
                                className={` ${
                                  idx == fadeEdit ? "fade-in-right" : ""
                                } `}
                                sx={{
                                  display: {
                                    xs: "none !important",
                                    sm:
                                      fadeEdit === idx
                                        ? "flex !important"
                                        : "none !important",
                                  },
                                  position: { md: "absolute" },
                                  right: { md: "-10px" },
                                  top: { md: "0px" },
                                }}
                              >
                                <Div
                                  sx={[
                                    FontStyle,
                                    DisplayFlex,
                                    {
                                      cursor: "pointer",
                                      transform: "translateX(-50px)",
                                      color: Colors?.Mblue,
                                    },
                                  ]}
                                  onClick={() => {
                                    // handleClickAction(data);
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: "25px !important",
                                      color: Colors?.Mblue,
                                    }}
                                  >
                                    <TiEdit
                                      style={{
                                        fontSize: 18,
                                        fontWeight: 900,
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Typography
                                        sx={[
                                          FontStyle,
                                          { color: Colors?.Mblue },
                                        ]}
                                      >
                                        Assign lead
                                      </Typography>
                                    }
                                  />
                                </Div>
                              </Div> */}
                              <CustomButton
                                onClick={(e) => {
                                  setOpenAssignLead(data);
                                }}
                                sx={{ borderRadius: "25px" }}
                                label="Assign Lead"
                                bgColor={Colors?.Mblue}
                                Icon=""
                              />
                            </Div>
                          </Box>
                        </Grid>
                        {columnMenuItems?.map(
                          (Menu, subIdx) =>
                            Menu?.bool && (
                              <Grid
                                key={subIdx}
                                item
                                xs={12}
                                md={4}
                                sx={[DisplayFlex]}
                              >
                                <Typography
                                  sx={[{ fontSize: "14px", color: "#828282" }]}
                                >
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      color: "#5e5e5e",
                                    }}
                                  >
                                    {Menu?.label} :
                                  </span>{" "}
                                </Typography>
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "14px",
                                      color:
                                        Menu?.sort === "Owner"
                                          ? Colors?.red
                                          : "#5e5e5e",
                                    },
                                  ]}
                                >
                                  {Menu?.sort === "Created_Date" ||
                                  Menu?.sort === "Updated_Date"
                                    ? formatDate(data[Menu?.sort])
                                    : data[Menu?.sort] || "Not Assigned"}
                                </Typography>
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Grid>
                  </Div>
                );
                // }
              })}
          </List>
          {/* gear options */}
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            sx={{
              mt: 5,
              maxHeight: "300px",
              scrollBehavior: "smooth",
            }}
          >
            <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
              Choose Fields
            </Typography>
            <FormGroup sx={{ p: 2 }}>
              {columnMenuItems?.length > 0 &&
                columnMenuItems?.map((column, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="info"
                          size="small"
                          checked={column?.bool}
                          onChange={(e) => {
                            let value = [...columnMenuItems];
                            value[index].bool = !column?.bool;
                            setColumnMenuItems(value);
                          }}
                        />
                      }
                      label={
                        <Typography sx={[FontStyle]}>
                          {column?.label}
                        </Typography>
                      }
                    />
                  );
                })}
            </FormGroup>
          </Menu>
        </Box>
      </Box>

      {/* Add Note Dialog Box */}
      <Dragabledialog
        maxWidth="lg"
        open={openAssignLead}
        // onClose={() => setOpenNotes(false)}
      >
        <DialogTitle
          sx={[DisplayFlex, dialogFontSize]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Assign Lead
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ pt: 2 }}>
            <Customgrid>
              <Grid item>
                <Typography sx={[input_label]}>{"Lead Name"}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={[FontStyle, { padding: 1, backgroundColor: "#f9f9f9" }]}
                >
                  {openAssignLead?.LeadFirstName +
                    " " +
                    openAssignLead?.LeadLastName || " - "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Lead Source</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={[FontStyle, { padding: 1, backgroundColor: "#f9f9f9" }]}
                >
                  {openAssignLead?.LeadType || " - "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Lead Status</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={[FontStyle, { padding: 1, backgroundColor: "#f9f9f9" }]}
                >
                  {openAssignLead?.Lead_Status || " - "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Lead Location</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={[FontStyle, { padding: 1, backgroundColor: "#f9f9f9" }]}
                >
                  {openAssignLead?.LeadLocation || " - "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Date</Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={[FontStyle, { padding: 1, backgroundColor: "#f9f9f9" }]}
                >
                  {formatDate(openAssignLead?.Updated_Date) ||
                    openAssignLead?.Updated_Date ||
                    " - "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Lead Owner</Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  className="col-12 search-select3"
                  options={userNameLists}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.username) {
                      return option?.username;
                    }
                    return "";
                  }}
                  value={leanOwnerName?.username || ""}
                  onChange={(event, newValue, index) => {
                    setLeanOwnerName(newValue);
                    // setgetSalesperson_name(newValue?.username);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Lead Owner" />
                  )}
                />
              </Grid>
            </Customgrid>
          </DialogContentText>
          <DialogActions sx={[DisplayFlex, { justifyContent: "center" }]}>
            <CustomButton
              label="Save"
              Icon="saveIcon"
              onClick={() =>
                handleUpdate({
                  ...openAssignLead,
                  Lead_Owner: leanOwnerName?.username,
                })
              }
            />
            <Cancelbutton
              label="Cancel"
              onClick={() => {
                setOpenAssignLead(false);
                setLeanOwnerName({});
              }}
            />
          </DialogActions>
        </DialogContent>
      </Dragabledialog>
    </JumboContentLayoutMain>
  );
};

export default UnAsignedLeads;
