import Div from "@jumbo/shared/Div";
import Span from "@jumbo/shared/Span";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { ButtonStyle, ComponentHeading, DialogBoxSaveButton, DisplayFlex, FontStyle } from "app/pages/widgets/CRMStyles";
import { CRMDateField, CustomButton } from "app/pages/widgets/StylesComponents";


const manufacturer = [
  { month: "January", value: 1 },
  { month: "February", value: 2 },
  { month: "March", value: 3 },
  { month: "April", value: 4 },
  { month: "May", value: 5 },
  { month: "June", value: 6 },
  { month: "July", value: 7 },
  { month: "August", value: 8 },
  { month: "September", value: 9 },
  { month: "October", value: 10 },
  { month: "November", value: 11 },
  { month: "December", value: 12 },
];

const date = [
  { label: 1, yearformat: "yyyy-mm-dd" },
  { label: 2, yearformat: "mm-dd-yyyy" },
  { label: 3, yearformat: "dd-mm-yyyy" },
];

// const currencies = [
//   { label: 1, curency: "Rupees - ₹" },
//   { label: 2, curency: "Dollar - $" },
//   { label: 3, curency: "Yen – ¥" },
//   { label: 4, curency: "Euro - €" },
// ];

const currencies = [
  { label: 1, curency: "INR", format: "Rupees - ₹" },
  { label: 2, curency: "USD", format: "Dollar - $" },
  { label: 3, curency: "JPY", format: "Yen – ¥" },
  { label: 4, curency: "EUR", format: "Euro - €" },
  { label: 5, curency: "BRL", format: "Real - R$" },
];


const UpdateCompanyDetails = ({ setShowEdit, setShowUpdate, id, compId, loading, setLoading }) => {
  const token = localStorage.getItem("accesstoken");

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
      "db": localStorage.getItem("DBName")
    },
  };

  const startYear = 1900;
  const endYear = new Date().getFullYear();

  const years = [];
  for (let year = endYear; year >= startYear; year--) {
    years.push(year);
  }


  const [business, setBusiness] = useState([]);
  const [companyAdd, setCompanyAdd] = useState([]);
  const [fiscal, setFiscal] = useState([]);
  const [bankDetails, setBankDetails] = useState({});
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyMail, setCompanyMail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [currencyFormat, setCurrencyFormat] = useState("");
  const [currency, setCurrency] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [fiscalYearStart, setFiscalYearStart] = useState(null);
  const [fiscalYearEnd, setFiscalYearEnd] = useState(null);
  const [fiscalMonth, setFiscalMonth] = useState(null);
  const [checkMonth, setCheckMonth] = useState("");
  const [contactNo1, setContactNo1] = useState("");
  const [contactNo2, setContactNo2] = useState("");
  const [contactNo3, setContactNo3] = useState("");


  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);


  const [showProfile, setShowProfile] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const [showCurrencies, setShowCurrencies] = useState(false);

  const [sunCheckbox, setSunCheckbox] = useState(false);
  const [monCheckbox, setMonCheckbox] = useState(false);
  const [tueCheckbox, setTueCheckbox] = useState(false);
  const [wedCheckbox, setWedCheckbox] = useState(false);
  const [thuCheckbox, setThuCheckbox] = useState(false);
  const [friCheckbox, setFriCheckbox] = useState(false);
  const [satCheckbox, setSatCheckbox] = useState(false);

  const [base64, setbase64] = useState("");

  const ShowCompanyProfile = () => {
    setShowProfile(true);
    setShowHour(false);
    setShowYear(false);
    setShowDetails(false);
    setShowCurrencies(false);
    setShowBankDetails(false);
  };

  const ShowCompanyDetails = () => {
    setShowDetails(true);
    setShowYear(false);
    setShowHour(false);
    setShowCurrencies(false);
    setShowProfile(false);
    setShowBankDetails(false);
  };

  const ShowFiscalYear = () => {
    setShowYear(true);
    setShowHour(false);
    setShowCurrencies(false);
    setShowDetails(false);
    setShowProfile(false);
    setShowBankDetails(false);
  };

  const ShowBusinessHour = () => {
    setShowHour(true);
    setShowYear(false);
    setShowCurrencies(false);
    setShowDetails(false);
    setShowProfile(false);
    setShowBankDetails(false);
  };

  const ShowCurrencies = () => {
    setShowCurrencies(true);
    setShowHour(false);
    setShowYear(false);
    setShowDetails(false);
    setShowProfile(false);
    setShowBankDetails(false);
  };

  const ShowBankDetail = () => {
    setShowCurrencies(false);
    setShowHour(false);
    setShowYear(false);
    setShowDetails(false);
    setShowProfile(false);
    setShowBankDetails(true);
  };

  const createrId = parseInt(localStorage.getItem("UserId"));

  const getCompanyDetails = async () => {
    setLoading(true);

    await axios.get(`${BASE_URL}/useradmin/CRMorganizationCRUD/?id=${createrId}`, header).then((res) => {
      setLoading(false);

      console.log("D", res?.data);
      const listdata = res?.data;
      var Id = listdata?.Created_By;
      // if (Id === 0) {
      //   setShowEdit(true);
      //   setShowUpdate(false);
      // } else {
      //   setShowEdit(false);
      //   setShowUpdate(true);
      // }

      setBusiness(listdata?.Business_Hour);
      setSunCheckbox(listdata?.Business_Hour?.Sunday_Chekbox);
      setMonCheckbox(listdata?.Business_Hour?.Monday_Chekbox);
      setTueCheckbox(listdata?.Business_Hour?.Tuesday_Chekbox);
      setWedCheckbox(listdata?.Business_Hour?.Wednessday_Chekbox);
      setThuCheckbox(listdata?.Business_Hour?.Thursday_Chekbox);
      setFriCheckbox(listdata?.Business_Hour?.Friday_Chekbox);
      setSatCheckbox(listdata?.Business_Hour?.Saturday_Chekbox);

      setCompanyAdd(listdata?.Organization_Address);
      setFiscal(listdata?.Fiscal_Year);
      setCompanyMail(listdata?.Organization_Mail);
      setCompanyName(listdata?.Organization_Name);
      setCompanyLogo(listdata?.Organization_Logo);

      setSelectedCountry(listdata?.Organization_Address?.Building_Country);
      setSelectedState(listdata?.Organization_Address?.Building_State);
      setSelectedCity(listdata?.Organization_Address?.Building_City);

      setCurrency(listdata?.Currencies);
      setDateFormat(listdata?.DateFormat);
      setCheckMonth(listdata?.Fiscal_Year?.Calandermonth);
      setFiscalMonth(listdata?.Fiscal_Year?.Calendar_MonthName);
      setFiscalYearStart(listdata?.Fiscal_Year?.Calender_MonthFromDate);
      setFiscalYearEnd(listdata?.Fiscal_Year?.Calender_MonthTodate);

      setContactNo1(listdata?.Organization_Contact_Number?.Org_Contact_NO_1);
      setContactNo2(listdata?.Organization_Contact_Number?.Org_Contact_NO_2);
      setContactNo3(listdata?.Organization_Contact_Number?.Org_Contact_NO_3);

      setBankDetails(listdata?.Organization_BankDetails);


      fetch(`${BASE_URL}${listdata?.Organization_Logo}`)
        // fetch(`http://134.209.148.66:8000${listdata?.Organization_Logo}`)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const base64 = btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          // const encoded = base64.b64encode(byte).decode('ascii');

          setbase64(base64);
          const urlParts = listdata?.Organization_Logo.split("/");
          console.log(urlParts);
          setCompanyLogo(urlParts[urlParts?.length - 1]);
        });
    }).catch((error) => {
      console.log("ds", error);
      setLoading(false);
    });
  };

  useEffect(() => {
    getCompanyDetails();
    // Load the list of countries
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    // If the selected country changes, load the list of states for the selected country
    setStates(
      State?.getStatesOfCountry(
        countries.find((option) => option["name"] === selectedCountry)?.isoCode
      )
    );

  }, [selectedCountry]);

  useEffect(() => {
    // If the selected state changes, load the list of cities for the selected state

    setCities(
      City?.getCitiesOfState(
        states.find((option) => option["name"] === selectedState)?.countryCode,
        states.find((option) => option["name"] === selectedState)?.isoCode
      )
    );
  }, [selectedState]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBusiness((values) => ({ ...values, [name]: value }));
    setCompanyAdd((values) => ({ ...values, [name]: value }));
    setFiscal((values) => ({ ...values, [name]: value }));
    setBankDetails((values) => ({ ...values, [name]: value }));
    console.log("GGG", fiscal.Calendar_MonthName);

    if (name === "IFSC_Code") {
      fetchBankDetails(value)
    }
  };

  const handleChangeMonth = (e) => {
    var month = e.target.value;
    setCheckMonth(month);
    console.log(month);
  };

  // image upload function
  const handleChangeImage = (e) => {
    console.log("file uploaded: ", e.target.files[0]);
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = handleReaderLoaded;
      reader.readAsBinaryString(file);
      // reader.readAsArrayBuffer(file);
      setCompanyLogo(e.target.files[0].name);
    }
  };

  const handleReaderLoaded = (e) => {
    console.log("file uploaded 2: ", e);
    let binaryString = e.target.result;
    setbase64(btoa(binaryString));
  };

  // const handleClickConfirm = () => {
  //   toast.success("Currency is formatted");
  // };

  const handleClickConfirm = (currency) => {
    if (currency === "INR") {
      setCurrencyFormat("Rupees - ₹")
    } else if (currency === "USD") {
      setCurrencyFormat("Dollar - $")
    } else if (currency === "JPY") {
      setCurrencyFormat("Yen – ¥")
    } else if (currency === "EUR") {
      setCurrencyFormat("Euro - €")
    } else if (currency === "BRL") {
      setCurrencyFormat("Real - R$")
    }
    // toast.success("Currency is formatted");
  };


  const fetchBankDetails = async (ifscCode) => {
    try {
      const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
      const data = await response.json();

      if (response.ok) {
        setBankDetails({ ...bankDetails, ['IFSC_CODE']: ifscCode, ["Branch"]: data.BRANCH, ["Beneficiary_Bank_Name"]: data.BANK, ["Bank_Address"]: data.ADDRESS })
      } else {
        toast.error("Enter a valid IFSC Code");
        setBankDetails({ ...bankDetails, ['IFSC_CODE']: "", ["Branch"]: "", ["Beneficiary_Bank_Name"]: "", ["Bank_Address"]: "" })
      }
    } catch (error) {
      console.log(error)
    }
  };


  useEffect(() => {
    handleClickConfirm(currency);
  }, [currency]);


  const handleUpdateBankDetails = async () => {
    try {
      const response = await axios.put(`${BASE_URL}/useradmin/UserorganizationmasterBankDetailsCRUD/`, {
        ...bankDetails,
        Beneficiary_Account_Name: bankDetails?.Beneficiary_Account_Name,
        Beneficiary_Account_Number: bankDetails?.Beneficiary_Account_Number,
        Beneficiary_Bank_Name: bankDetails?.Beneficiary_Bank_Name,
        IFSC_CODE: bankDetails?.IFSC_CODE,
        Created_By: parseInt(localStorage.getItem("UserId")),
        Created_Date: new Date().toISOString().slice(0, 10),
        Updated_By: parseInt(localStorage.getItem("UserId")),
        Updated_Date: new Date().toISOString().slice(0, 10),
      }, header)
      return response?.data?.id
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bankId = await handleUpdateBankDetails();

    var companyPayload = {
      id: id,
      Organization_Id: compId,
      Organization_Name: companyName,
      Organization_Logo: companyLogo,
      // CompanyLogo_Name: companyLogo,
      Organization_Mail: companyMail,
      Organization_Contact_Number: {
        Org_Contact_NO_1: contactNo1,
        Org_Contact_NO_2: contactNo2,
        Org_Contact_NO_3: contactNo3,
      },
      Organization_Address: {
        id: companyAdd?.id,
        Address_Id: companyAdd?.Address_Id,
        // Company_Id: parseInt(localStorage.getItem("OrganizationId")),
        Address_Type: companyAdd?.Address_Type,
        Building_Street: companyAdd?.Building_Street,
        Building_Name: companyAdd?.Building_Street,
        Building_Area: companyAdd?.Building_Area,
        Building_Country: selectedCountry,
        Building_State: selectedState,
        Building_City: selectedCity,
        Zip_Code: companyAdd?.Zip_Code,
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      },

      // Organization_BankDetails: bankDetails,
      Organization_BankDetails: bankId,

      Fiscal_Year: {
        id: fiscal?.id,
        Calendar_Id: fiscal?.Calendar_Id,
        // Company_Id: parseInt(localStorage.getItem("OrganizationId")),
        Calendar_MonthName: fiscalMonth,
        CalendarName: "",
        Calender_QuarterName: "",
        Calender_MonthFromDate: fiscalYearStart,
        Calender_MonthTodate: fiscalYearEnd,
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
        Calandermonth: checkMonth,
      },
      Business_Hour: {
        id: business?.id,
        Businesshours_Id: business?.Businesshours_Id,
        // Company_Id: parseInt(localStorage.getItem("OrganizationId")),
        Monday_Chekbox: monCheckbox,
        Monday_Starttime: business?.Monday_Starttime,
        Monday_EndTime: business?.Monday_EndTime,
        Tuesday_Chekbox: tueCheckbox,
        Tuesday_Starttime: business?.Tuesday_Starttime,
        Tuesday_Endtime: business?.Tuesday_Endtime,
        Wednessday_Chekbox: wedCheckbox,
        Wednessday_Starttime: business?.Wednessday_Starttime,
        Wednessday_Endtime: business?.Wednessday_Endtime,
        Thursday_Chekbox: thuCheckbox,
        Thursday_Starttime: business?.Thursday_Starttime,
        Thursday_Endtime: business?.Thursday_Endtime,
        Friday_Chekbox: friCheckbox,
        Friday_Starttime: business?.Friday_Starttime,
        Friday_Endtime: business?.Friday_Endtime,
        Saturday_Chekbox: satCheckbox,
        Saturday_Starttime: business?.Saturday_Starttime,
        Saturday_Endtime: business?.Saturday_Endtime,
        Sunday_Chekbox: sunCheckbox,
        Sunday_Starttime: business?.Sunday_Starttime,
        Sunday_Endtime: business?.Sunday_Endtime,
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      },
      DateFormat: dateFormat,
      Currencies: currency,
      Created_By: parseInt(localStorage.getItem("UserId")),
      Updated_By: parseInt(localStorage.getItem("UserId")),
    };

    await axios.put(`${BASE_URL}/useradmin/CRMorganizationCRUD/?id=${createrId}`, companyPayload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "db": localStorage.getItem("DBName"),
      },
    }).then((res) => {
      console.log("res", res?.data);
      if (res?.data === "Updates Successfully") {
        toast.success("Company details updated successfully");
        ShowCompanyProfile();
      } else {
        toast.error("Enter valid company details");
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Enter valid company details");
    });
  };


  return (
    <>
      {/* Update company details code */}
      <Div>
        {/* <Typography sx={[ComponentHeading]}> */}
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          Update Your Company Details
        </Typography>
        {loading ? (
          <div class="loader1">
            <CircularProgress />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div>
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  flexWrap: "wrap",
                  mt: -3,
                  mr: 0.5,
                }}
              >
                <CustomButton
                  type="submit"
                  label="Update"
                  Icon="EditIcon"
                />
                {/* <Button type="submit" color="success" variant="contained">
                  Update
                </Button> */}
              </Div>
              <div>
                <Div
                  className="card"
                  sx={{ m: 1, ml: 0, mr: 0, p: 2, minHeight: "466px" }}
                >
                  <Div
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      fontSize: 16,
                      fontWeight: 500,
                      cursor: "pointer",
                      overflow: "auto",
                    }}
                  >
                    <Button
                      onClick={ShowCompanyProfile}
                      sx={{
                        color: showProfile === true ? "#10BBE5" : "#000000",
                      }}
                    >
                      Company Profile
                    </Button>
                    <Button
                      onClick={ShowCompanyDetails}
                      sx={{
                        color: showDetails === true ? "#10BBE5" : "#000000",
                      }}
                    >
                      Company Details
                    </Button>
                    <Button
                      onClick={ShowBankDetail}
                      sx={{
                        color: showBankDetails === true ? "#10BBE5" : "#000000",
                      }}
                    >
                      Bank Details
                    </Button>
                    <Button
                      onClick={ShowFiscalYear}
                      sx={{
                        color: showYear === true ? "#10BBE5" : "#000000",
                      }}
                    >
                      Fiscal Year
                    </Button>
                    <Button
                      onClick={ShowBusinessHour}
                      sx={[{
                        color: showHour === true ? "#10BBE5" : "#000000",
                      }]}
                    >
                      Business Hour
                    </Button>
                    <Button
                      onClick={ShowCurrencies}
                      sx={{
                        color: showCurrencies === true ? "#10BBE5" : "#000000",
                      }}
                    >
                      Currencies
                    </Button>
                  </Div>

                  {/* code for company profile */}
                  <Div>
                    <Div sx={{ display: showProfile === true ? "block" : "none", mb: 2 }}>
                      <form>
                        <Div
                          sx={{
                            mt: 4,
                            ml: { xs: 0, md: 2 },
                          }}
                        >
                          <Grid item xs={12} sm={4} lg={4} className="row">
                            <Typography
                              className="input-label col-md-3"
                              sx={{ mt: 1, textAlign: "left" }}
                            >
                              Company Name <Span className="required">*</Span>
                            </Typography>
                            <TextField
                              className="input-box2 col-md-5"
                              id="outlined-basic"
                              placeholder="Company Name"
                              variant="outlined"
                              value={companyName}
                              name="companyName"
                              onChange={(e) => setCompanyName(e.target.value)}
                              autoComplete="off"
                            />
                          </Grid>
                        </Div>

                        <Div sx={{ mt: 2, ml: { xs: 0, md: 2 } }}>
                          <Typography
                            fontWeight={600}
                            sx={[ComponentHeading, { ml: 1.5, textAlign: "left" }]}
                          >
                            Address :
                          </Typography>
                          <br />
                          <Grid container>
                            <Grid item xs={12} className="row">
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Street & Building Name{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                id="outlined-basic"
                                placeholder="Street & Building Name"
                                variant="outlined"
                                // value={companystreet}
                                value={companyAdd?.Building_Street}
                                name="Building_Street"
                                // onChange={(e) => setCompanystreet(e.target.value)}
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Area Name <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="col-md-5 input-box2"
                                id="outlined-basic"
                                placeholder="Area Name"
                                variant="outlined"
                                value={companyAdd?.Building_Area}
                                name="Building_Area"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Country <Span className="required">*</Span>
                              </Typography>
                              <FormControl className="col-md-5">
                                {/* <Select
                                  styles={{ textAlign: "left" }}
                                  // className="input-box2"
                                  placeholder="Select Country"
                                  name="selectedCountry"
                                  options={countries}
                                  Value={selectedCountry}
                                  getOptionLabel={(options) => {
                                    return options["name"];
                                  }}
                                  getOptionValue={(options) => {
                                    return options["name"];
                                  }}
                                  value={countries.find(
                                    (option) =>
                                      option["name"] === selectedCountry
                                  )}
                                  onChange={(item) => {
                                    setSelectedCountry(item);
                                    // console.log("PP", item);
                                  }}
                                /> */}

                                <Autocomplete
                                  className="search-select3"
                                  name="selectedCountry"
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (option && option?.name) {
                                      return option?.name;
                                    }
                                    return "";
                                  }}
                                  options={countries}
                                  value={selectedCountry || null}
                                  onChange={(event, newValue) => {
                                    setSelectedCountry(newValue?.name);
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  renderInput={(params) => <TextField {...params} placeholder="Select Country" variant="outlined" />}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                State <Span className="required">*</Span>
                              </Typography>
                              <FormControl className="col-md-5">
                                {/* <Select
                                  styles={{ textAlign: "left" }}
                                  className="react-select-box"
                                  placeholder="Select State"
                                  name="selectedState"
                                  options={states}
                                  getOptionLabel={(options) => {
                                    return options["name"];
                                  }}
                                  getOptionValue={(options) => {
                                    return options["name"];
                                  }}
                                  value={states.find(
                                    (option) => option["name"] === selectedState
                                  )}
                                  onChange={(item) => {
                                    setSelectedState(item);
                                    console.log("PP", item);
                                  }}
                                  Value={selectedState}
                                /> */}

                                <Autocomplete
                                  className="search-select3"
                                  name="selectedState"
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (option && option?.name) {
                                      return option?.name;
                                    }
                                    return "";
                                  }}
                                  options={states}
                                  value={selectedState || null}
                                  onChange={(event, newValue) => {
                                    setSelectedState(newValue?.name);
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option === value
                                  }
                                  renderInput={(params) => <TextField {...params} placeholder="Select State" variant="outlined" />}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                City <Span className="required">*</Span>
                              </Typography>
                              <FormControl className="col-md-5">
                                {/* <Select
                                  styles={{ textAlign: "left" }}
                                  className="react-select-box"
                                  placeholder="Select City"
                                  name="selectedCity"
                                  options={cities}
                                  getOptionLabel={(options) => {
                                    return options["name"];
                                  }}
                                  getOptionValue={(options) => {
                                    return options["name"];
                                  }}
                                  value={cities.find(
                                    (option) => option["name"] === selectedCity
                                  )}
                                  onChange={(item) => {
                                    setSelectedCity(item);
                                    console.log("PP", item);
                                  }}
                                  Value={selectedCity}
                                /> */}
                                <Autocomplete
                                  className="search-select3"
                                  name="selectedCity"
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (option && option?.name) {
                                      return option?.name;
                                    }
                                    return "";
                                  }}
                                  options={cities}
                                  value={selectedCity || null}
                                  onChange={(event, newValue) => {
                                    setSelectedCity(newValue?.name);
                                  }}
                                  isOptionEqualToValue={(option, value) => option === value}
                                  renderInput={(params) => <TextField {...params} placeholder="Select City" variant="outlined" />}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Pincode <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="col-md-5 input-box2"
                                id="outlined-basic"
                                placeholder="Pincode"
                                variant="outlined"
                                value={companyAdd?.Zip_Code}
                                name="Zip_Code"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Date Format
                                <Span className="required">*</Span>
                              </Typography>
                              <FormControl className="input-box2 col-md-5">
                                <Autocomplete
                                  className="search-select3 col-12"
                                  id="highlights-demo"
                                  name="dateFormat"
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (option && option?.yearformat) {
                                      return option?.yearformat;
                                    }
                                    return "";
                                  }}
                                  options={date}
                                  value={dateFormat}
                                  onChange={(e, item) => {
                                    setDateFormat(item?.yearformat);
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.label === value?.label &&
                                    option?.yearformat === value
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Date Format"
                                      variant="outlined"
                                      autoComplete="off"
                                    />
                                  )}
                                ></Autocomplete>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Contact Number 1{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                type="number"
                                id="outlined-basic"
                                placeholder="Contact Number 1"
                                variant="outlined"
                                value={contactNo1}
                                name="contactNo1"
                                onChange={(e) => setContactNo1(e.target.value)}
                                autoComplete="off"
                              />
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Contact Number 2{" "}
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                type="number"
                                id="outlined-basic"
                                placeholder="Contact Number 2"
                                variant="outlined"
                                value={contactNo2}
                                name="contactNo2"
                                onChange={(e) => setContactNo2(e.target.value)}
                                autoComplete="off"
                              />
                            </Grid>

                            <Grid item xs={12} className="row" sx={{ mt: 1, mb: 3 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Contact Number 3{" "}
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                type="number"
                                id="outlined-basic"
                                placeholder="Contact Number 3"
                                variant="outlined"
                                value={contactNo3}
                                name="contactNo3"
                                onChange={(e) => setContactNo3(e.target.value)}
                                autoComplete="off"
                              />
                            </Grid>
                          </Grid>
                        </Div>
                      </form>
                    </Div>

                    {/* code for company details */}
                    <Div
                      sx={{
                        display: showDetails === true ? "block" : "none",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={6} lg={6}>
                          <Div sx={{ mt: 8 }}>
                            <Div
                              sx={[DisplayFlex, {
                                justifyContent: "center",
                                height: { xs: 230, xl: 225 },
                                borderRadius: 0,
                              }]}
                            >
                              <img
                                alt="Company Logo"
                                src={`data:image/png;base64,${base64}`}
                                width={150}
                                height={150}
                                style={{ border: "1px solid #10BBE5 ", padding: 2, }}
                              />
                            </Div>

                            <Button
                              component="label"
                              onChange={handleChangeImage}
                              sx={[FontStyle, {
                                marginTop: { sm: 2, md: "5px" },
                                color: "#10BBE5",
                                textTransform: "capitalize",
                                textAlign: "center",
                                // border: "1px solid lightgray",
                                padding: "10px",
                              }]}
                            >
                              Upload Company Logo
                              <input
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                style={{
                                  backgroundColor: "transparent",
                                }}
                                name="companyLogo"
                                onChange={handleInputChange}
                              />
                            </Button>
                          </Div>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <Div sx={{ mt: 10, ml: { xs: 0, md: 10 } }}>
                            <Div
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: "#10BBE5",
                                marginBottom: "5px",
                              }}
                            >
                              <span></span>
                              {/* CRM India Private Limited */}
                              {companyName}
                            </Div>

                            <Div>
                              <TextField
                                className="col-md-8 input-box"
                                id="outlined-basic"
                                placeholder="Company Mail"
                                variant="outlined"
                                value={companyMail}
                                name="companyMail"
                                onChange={(e) => setCompanyMail(e.target.value)}
                                autoComplete="off"
                              />
                            </Div>
                          </Div>
                        </Grid>
                      </Grid>
                    </Div>

                    {/* code for Bank Details */}
                    <Div
                      sx={{
                        display: showBankDetails === true ? "block" : "none", mb: 2
                      }}
                    >
                      <form>
                        <Div sx={{ mt: 2, ml: { xs: 0, md: 2 } }}>
                          <Grid container>
                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                IFSC Code{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                id="outlined-basic"
                                placeholder="IFSC Code"
                                variant="outlined"
                                value={bankDetails?.IFSC_CODE}
                                name="IFSC_CODE"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>
                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Bank Name{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                id="outlined-basic"
                                placeholder="Bank Name"
                                variant="outlined"
                                value={bankDetails?.Beneficiary_Bank_Name}
                                name="Beneficiary_Bank_Name"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>
                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Account Name{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                id="outlined-basic"
                                placeholder="Account Name"
                                variant="outlined"
                                value={bankDetails?.Beneficiary_Account_Name}
                                name="Beneficiary_Account_Name"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>
                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Account Number{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                id="outlined-basic"
                                placeholder="Account Number"
                                variant="outlined"
                                value={bankDetails?.Beneficiary_Account_Number}
                                name="Beneficiary_Account_Number"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>
                            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                              <Typography
                                className="input-label col-md-3"
                                sx={{ mt: 1, textAlign: "left" }}
                              >
                                Address{" "}
                                <Span className="required">*</Span>
                              </Typography>
                              <TextField
                                className="input-box2 col-md-5"
                                id="outlined-basic"
                                placeholder="Address"
                                variant="outlined"
                                value={bankDetails?.Bank_Address}
                                name="Bank_Address"
                                onChange={handleInputChange}
                                autoComplete="off"
                              />
                            </Grid>
                          </Grid>
                        </Div>
                      </form>
                    </Div>

                    {/* code for fiscal year */}
                    <Div style={{ display: showYear === true ? "block" : "none", }}>
                      <Typography sx={{ pl: 1, pt: 3, fontWeight: 600, textAlign: 'left', fontSize: "14px" }}>
                        Fiscal Year
                      </Typography>
                      <Typography sx={{ pl: 1, fontWeight: 500, textAlign: 'left', fontSize: "14px" }}>
                        You can fix your company's fiscal year here
                      </Typography>
                      <Div sx={{ mt: 5 }}>
                        {/* <Grid container spacing={1} rowSpacing={1}>
                          <Grid item xs={12} sm={4} lg={3}>
                            <Typography sx={[FontStyle, { textAlign: "left" }]} className="input-label">
                              Fiscal Year Start
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={3}>
                            <FormControl className="col-12">
                              <CRMDateField
                                id="fiscalYearStart"
                                name="fiscalYearStart"
                                inputValue={fiscalYearStart}
                                handleInputChange={(e) => setFiscalYearStart(e.target.value)}
                                variant="outlined"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4} lg={3}>
                            <Typography sx={[FontStyle, { textAlign: "left" }]} className="input-label">
                              Fiscal Year End
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={3}>
                            <FormControl className="col-12">
                              <CRMDateField
                                id="fiscalYearEnd"
                                name="fiscalYearEnd"
                                inputValue={fiscalYearEnd}
                                handleInputChange={(e) => setFiscalYearEnd(e.target.value)}
                                variant="outlined"
                              />
                            </FormControl>
                          </Grid>
                        </Grid> */}

                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4} lg={3}>
                            <Typography sx={[FontStyle, { textAlign: "left" }]} className="input-label">
                              Fiscal Month
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={3}>
                            <FormControl className="col-12">
                              <Autocomplete
                                className="search-select3"
                                name="fiscalMonth"
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option && option?.month) {
                                    return option?.month;
                                  }
                                  return "";
                                }}
                                options={manufacturer}
                                value={fiscalMonth}
                                onChange={(e, newValue) => {
                                  setFiscalMonth(newValue?.month);
                                  // console.log("HHH", newValue);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option?.month === value
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Month"
                                    variant="outlined"
                                    autoComplete="off"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                          {/* <Grid item xs={12} sm={4} lg={4}></Grid> */}
                          <Grid item xs={12} sm={4} lg={3}>
                            <Typography sx={[FontStyle, { pt: 2, textAlign: "left" }]} className="input-label">
                              Display Fiscal Based On
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={4} lg={4}>
                            <Div sx={{ pt: 1 }}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="start month"
                                name="radio-buttons-group"
                              >
                                <FormControlLabel
                                  control={<Radio color="info" size="small" />}
                                  label="Start Month"
                                  name="Start Month"
                                  value="startMonth"
                                  checked={checkMonth === "startMonth"}
                                  onChange={handleChangeMonth}
                                />
                                <FormControlLabel
                                  control={<Radio color="info" size="small" />}
                                  label="End Month"
                                  value="endMonth"
                                  name="End Month"
                                  checked={checkMonth === "endMonth"}
                                  onChange={handleChangeMonth}
                                />
                              </RadioGroup>
                            </Div>
                          </Grid>
                        </Grid>
                      </Div>
                    </Div>

                    {/* code for Business hour */}
                    <Div
                      style={{
                        display: showHour === true ? "block" : "none",
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={[ComponentHeading, { pl: 1, pt: 3, fontWeight: 600, textAlign: "left" }]}
                      >
                        Business Hour
                      </Typography>
                      <Typography sx={[ComponentHeading, { pl: 1, fontWeight: 500, textAlign: "left" }]}>
                        You can fix your company's business hour here
                      </Typography>
                      <Div sx={{ mt: 3, fontWeight: 400 }}>
                        <Grid container>
                          <Grid item xs={12} sm={12} lg={1}></Grid>
                          <Grid className="row" item xs={12} sm={3} lg={3}>
                            <Typography
                              sx={[
                                FontStyle,
                                {
                                  pt: 0,
                                  pl: 10,
                                  // color: "#4ECD71",
                                  color: "#10BBE5",
                                  textAlign: "left",
                                }
                              ]}
                            >
                              Business Days
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 1, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="sunCheckbox"
                                checked={sunCheckbox}
                                onChange={(e) => {
                                  setSunCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />{" "}
                              Sunday
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 0, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="monCheckbox"
                                checked={monCheckbox}
                                onChange={(e) => {
                                  setMonCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />
                              Monday
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 0, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="tueCheckbox"
                                checked={tueCheckbox}
                                onChange={(e) => {
                                  setTueCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />{" "}
                              Tuesday
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 0, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="wedCheckbox"
                                checked={wedCheckbox}
                                onChange={(e) => {
                                  setWedCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />{" "}
                              Wednesday
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 0, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="thuCheckbox"
                                checked={thuCheckbox}
                                onChange={(e) => {
                                  setThuCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />
                              Thursday
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 0, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="friCheckbox"
                                checked={friCheckbox}
                                onChange={(e) => {
                                  setFriCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />
                              Friday
                            </Typography>
                            <Typography sx={[FontStyle, { textAlign: "left", pt: 0, pl: 9 }]}>
                              <Checkbox color="info"
                                size="small"
                                name="satCheckbox"
                                checked={satCheckbox}
                                onChange={(e) => {
                                  setSatCheckbox(e.target.checked);
                                  console.log("C", e.target.checked);
                                }}
                              />
                              Saturday
                            </Typography>
                          </Grid>

                          <Grid className="row" item xs={12} sm={3} lg={3}>
                            <Typography
                              className="col"
                              sx={[
                                FontStyle,
                                {
                                  textAlign: "left",
                                  pt: 0,
                                  pl: 10,
                                  // color: "#4ECD71",
                                  color: "#10BBE5",
                                }
                              ]}
                            >
                              Start Time
                            </Typography>
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Sunday_Starttime"
                              value={business?.Sunday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Monday_Starttime"
                              value={business?.Monday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Tuesday_Starttime"
                              value={business?.Tuesday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Wednessday_Starttime"
                              value={business?.Wednessday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Thursday_Starttime"
                              value={business?.Thursday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Friday_Starttime"
                              value={business?.Friday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Saturday_Starttime"
                              value={business?.Saturday_Starttime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </Grid>

                          <Grid className="row" item xs={12} sm={3} lg={3}>
                            <Typography
                              sx={[
                                FontStyle,
                                {
                                  textAlign: "left",
                                  pt: 0,
                                  pl: 10,
                                  // color: "#4ECD71",
                                  color: "#10BBE5",
                                }
                              ]}
                            >
                              End Time
                            </Typography>

                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Sunday_Endtime"
                              value={business?.Sunday_Endtime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Monday_EndTime"
                              value={business?.Monday_EndTime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Tuesday_Endtime"
                              value={business?.Tuesday_Endtime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Wednessday_Endtime"
                              value={business?.Wednessday_Endtime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Thursday_Endtime"
                              value={business?.Thursday_Endtime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Friday_Endtime"
                              value={business?.Friday_Endtime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                            <TextField
                              type="time"
                              className="input-box col-10"
                              sx={{ pt: 0, pl: 10 }}
                              name="Saturday_Endtime"
                              value={business?.Saturday_Endtime}
                              onChange={handleInputChange}
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item xs={12} sm={2} lg={2}></Grid>
                        </Grid>
                      </Div>
                    </Div>

                    {/* code for Currencies */}
                    <Div
                      style={{
                        display: showCurrencies === true ? "block" : "none",
                      }}
                    >
                      <Typography
                        sx={[ComponentHeading, { textAlign: "left", pl: 1, pt: 3, fontWeight: 600 }]}
                      >
                        Currencies
                      </Typography>
                      <Typography sx={[FontStyle, { textAlign: "left", pl: 1, fontWeight: 500 }]}>
                        You can fix your currencies which will be used to
                        transact all financial transactions. Once you setup you
                        can not change later
                      </Typography>
                      <Div sx={{ mt: 5, ml: 1, textAlign: "left" }}>
                        <Grid container spacing={2}>
                          {/* <Grid item xs={12} sm={1} lg={1}></Grid> */}
                          <Grid className="row" item xs={12} sm={12} lg={3}>
                            <Typography

                              className="col-12"
                              sx={[FontStyle, { textAlign: "left", pt: 0, ml: -1, color: "#10BBE5" }]}
                            >
                              Home Currencies
                            </Typography>
                            <FormControl
                              className="col-md-8 ms-1"
                              sx={{ minWidth: "195px" }}
                            >
                              <Autocomplete
                                className="search-select3"
                                name="currency"
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option && option?.curency) {
                                    return option?.curency;
                                  }
                                  return "";
                                }}
                                options={currencies}
                                value={currency}
                                onChange={(e, newValue) => {
                                  setCurrency(newValue?.curency);
                                  handleClickConfirm(newValue?.curency);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option?.curency === value
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Currency Format"
                                    autoComplete="off"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid className="row" item xs={12} sm={12} lg={3}>
                            <Typography
                              className="col-12"
                              sx={[FontStyle, { textAlign: "left", pt: 0, pl: { xs: 0, md: 6 }, color: "#10BBE5" }]}
                            >
                              Format
                            </Typography>
                            <TextField
                              className="input-box2 col-12"
                              value={currencyFormat}
                              placeholder="Currency Format"
                              autoComplete="off"
                              disabled
                              sx={{ pt: 0, pl: { xs: 0, md: 6 }, maxWidth: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3} lg={3}></Grid>
                        </Grid>
                        <Button
                          sx={[DialogBoxSaveButton, ButtonStyle, FontStyle, {
                            display: "flex",
                            justifySelf: "flex-start",
                            mt: 10,
                            ml: 0.5,
                          }]}
                          onClick={handleClickConfirm}
                        >
                          Confirm
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                </Div>
                <br />
              </div>
            </div>
          </form>
        )}
      </Div>
    </>
  );
};

export default UpdateCompanyDetails;