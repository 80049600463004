export const sidebarItems = [
  {
    key: "1",
    label: "Folders",
    children: [
      {
        key: "11",
        label: "Inbox",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-inbox"></i>,
      },
      {
        key: "12",
        label: "Junk Email",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-folder-x"></i>,
      },
      {
        key: "13",
        label: "Drafts",
        icon: (
          <i style={{ fontSize: "18px" }} className="bi bi-pencil-square"></i>
        ),
      },
      {
        key: "14",
        label: "Sent Items",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-send"></i>,
      },
      {
        key: "15",
        label: "Deleted Items",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-trash3"></i>,
      },
      {
        key: "16",
        label: "Archive",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-archive"></i>,
      },
      {
        key: "17",
        label: "Notes",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-sticky"></i>,
      },
      {
        key: "18",
        label: "Conversation History",
        icon: <i style={{ fontSize: "18px" }} className="bi bi-folder"></i>,
      },
    ],
  },
];
