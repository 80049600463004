import React, { useState, useEffect } from "react";

import {
  FormControl,
  Table,
  TableHead,
  Typography,
  Autocomplete,
  TextField,
  Pagination,
  TableBody,
  Button,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import {
  ButtonStyle,
  CRMTableHead,
  ComponentHeading,
  DialogBoxCancelButton,
  DisplayFlex,
  FontStyle,
  DialogBoxSaveButton,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { BiEdit } from "react-icons/bi";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

function LeadScoringtypes({ scrollHeight }) {
  const token = localStorage.getItem("accesstoken");
  const [datas, setDatas] = useState([
    { sl: 1, Description: "ss", MaxScore: "re" },
  ]);
  // const [edit, setEdit] = useState(null);

  // const addMoreFunction = () => {
  //   let list = [...datas];
  //   let bool = list?.every((dat) => {
  //     return Object.values(dat)?.every((val) => {
  //       console.log("boolean", val, Boolean(val));
  //       return Boolean(val);
  //     });
  //   });
  //   if (bool || datas?.length == 0) {
  //     list?.push({ sl: datas?.length + 1, Description: "", MaxScore: "" });
  //     setDatas(list);
  //     setEdit(Number(list?.length - 1));
  //     //   console.log("length", list?.length - 1);
  //   } else {
  //     toast.error("please fill the black list first");
  //   }
  // };

  // //   get data api
  // const getAllData = () => {
  //   // setDatas(res.data.results);
  // };

  // const onChangeHandler = (value, name, idx) => {
  //   let list = [...datas];
  //   list[idx][name] = value;
  //   setDatas(list);
  // };

  // Show the Table For the Lead Scoring Types

  const [demotype, setDemoType] = useState("Demographic");

  const [demo, setDemo] = useState(true);

  const [Firemographic, setFiremographic] = useState(false);

  const [Positive, setPositive] = useState(false);

  const [Negative, setNegative] = useState(false);

  const [id, setId] = useState(null);

  const [scoreval, setScoreVal] = useState(null);

  const Operatortypes = [
    { parameter: "is" },
    { parameter: "Less Than or Equal To" },
    { parameter: "Greater Than or Equal To" },
    { parameter: "Greater Than" },
    { parameter: "Less Than" },
  ];

  const ShowField = (item) => {
    console.log("item", item);
    if (item == "Demographic") {
      setDemoType(item);
      setDemo(true);
      setFiremographic(false);
      setPositive(false);
      setNegative(false);
      DemogetRecords();
    }
    if (item == "Firemographic") {
      setDemoType(item);
      setDemo(false);
      setFiremographic(true);
      setPositive(false);
      setNegative(false);
      FirmoRecords();
    }
    if (item == "Behavior-Positive") {
      setDemoType(item);
      PositiveRecords();
      setDemo(false);
      setFiremographic(false);
      setPositive(true);
      setNegative(false);
    }
    if (item == "Behavior-Negative") {
      setDemoType(item);
      NegativeRecords();
      setDemo(false);
      setFiremographic(false);
      setPositive(false);
      setNegative(true);
    }
  };

  //  for Demo Graphics

  const [catname, setCatName] = useState("");

  const [rows1, setrows1] = useState([]);

  //  Demographic Add Record

  const handleAddRow1 = () => {
    const newitem = {
      Category_Value: "",
      Score: "",
      Operator: "",
      allow: true,
    };
    setrows1([...rows1, newitem]);
  };

  // Demo Removal Record
  const handleRemoveRow1 = (item, index) => {
    handleDeleteprediction1(item);
    setrows1((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction1 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(`${BASE_URL}/useradmin/LeadScoreValuesCRUD/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Demo Create Record
  const LeadSubmitDemoGraphic = () => {
    let arr = [];
    rows1.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Category_Value === "" ||
          item.Category_Value === undefined ||
          item.Category_Value === null ||
          item.Operator === "" ||
          item.Operator === undefined ||
          item.Operator === null ||
          item.Score === "" ||
          item.Score === undefined ||
          item.Score === null
        ) {
          toast.error("Invalid Data in Lead DemoGrphics");
        } else {
          let playload = {
            Catategory_Name: catname?.Demo_Description,
            Graphics_name: demotype,
            Category_Value: item?.Category_Value,
            Operator: item?.Operator,
            Unique_Id: catname?.Unique_Id,
            Score: item?.Score,
            LeadDemoGraphic_Id: catname?.id,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };
          console.log("playload", playload);
          axios
            .post(`${BASE_URL}/useradmin/LeadScoreValuesCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Lead Score Setup Created Successfully");
                LeadScoreDemoDetails(res?.data?.LeadDemoGraphic_Id);
              }
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Demo Delete Record
  const handleAddChange1 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows1];
    updatedRows[index][name] = value;
    setrows1(updatedRows);
  };

  // handle Update on the Lead Scoring Page

  const handleupdate1 = (index, data) => {
    if (data.id !== undefined) {
      if (data.Category_Value === "" || data.Score === "") {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadScoreValues_Id: data.LeadScoreValues_Id,
          Catategory_Name: data?.Catategory_Name,
          Operator: data?.Operator,
          Graphics_name: data?.Graphics_name,
          Category_Value: data?.Category_Value,
          Score: data?.Score,
          LeadDemoGraphic_Id: data?.LeadDemoGraphic_Id,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadScoreValuesCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              DemogetRecords();
              // Generalrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };

  // Lead Score DemoDetils

  const LeadScoreDemoDetails = (ids) => {
    axios
      .get(`${BASE_URL}/useradmin/LeadScoreValuesList/?id=${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        setrows1(res?.data);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  // For Firmo Graphics Details

  const [rows2, setrows2] = useState([]);

  //  Demographic Add Record

  const handleAddRow2 = () => {
    const newitem = {
      Category_Value: "",
      Operator: "",
      Score: "",
      allow: true,
    };
    setrows2([...rows2, newitem]);
  };

  // Demo Removal Record
  const handleRemoveRow2 = (item, index) => {
    handleDeleteprediction2(item);
    setrows2((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction2 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(
          `${BASE_URL}/useradmin/LeadScoreValuesFirmoCRUD/?id=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          }
        )
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Demo Create Record
  const LeadSubmitFirmoGraphic = () => {
    let arr = [];
    rows2.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Category_Value === "" ||
          item.Category_Value === undefined ||
          item.Category_Value === null ||
          item.Operator === "" ||
          item.Operator === undefined ||
          item.Operator === null ||
          item.Score === "" ||
          item.Score === undefined ||
          item.Score === null
        ) {
          toast.error("Invalid Data in Lead DemoGrphics");
        } else {
          let playload = {
            Catategory_Name: catname?.Demo_Description,
            Graphics_name: demotype,
            Category_Value: item?.Category_Value,
            Unique_Id: catname?.Unique_Id,
            Score: item?.Score,
            Operator: item?.Operator,
            LeadFirmoGraphic_Id: catname?.id,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };
          console.log("playload", playload);
          axios
            .post(`${BASE_URL}/useradmin/LeadScoreValuesFirmoCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Lead Score Setup Created Successfully");
                LeadScoreFrimoDetails(res?.data?.LeadFirmoGraphic_Id);
              }
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Demo Delete Record
  const handleAddChange2 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows2];
    updatedRows[index][name] = value;
    setrows2(updatedRows);
  };

  // handle Update on the Lead Scoring Page

  const handleupdate2 = (index, data) => {
    if (data.id !== undefined) {
      if (data.Category_Value === "" || data.Score === "") {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadScoreFirmo_Id: data?.LeadScoreFirmo_Id,
          Catategory_Name: data?.Catategory_Name,
          Graphics_name: data?.Graphics_name,
          Category_Value: data?.Category_Value,
          Operator: data?.Operator,
          Score: data?.Score,
          LeadFirmoGraphic_Id: data?.LeadFirmoGraphic_Id,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadScoreValuesFirmoCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              // Generalrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };

  // Lead Score DemoDetils

  const LeadScoreFrimoDetails = (ids) => {
    axios
      .get(`${BASE_URL}/useradmin/LeadScoreValuesFirmoList/?id=${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        setrows2(res?.data || []);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  //  For the positive Behaviour

  const [rows3, setrows3] = useState([]);

  const handleAddRow3 = () => {
    const newitem = {
      Category_Value: "",
      Score: "",
      Operator: "",
      allow: true,
    };
    setrows3([...rows3, newitem]);
  };

  // Demo Removal Record
  const handleRemoveRow3 = (item, index) => {
    handleDeleteprediction3(item);
    setrows3((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction3 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(
          `${BASE_URL}/useradmin/LeadScoreValuespositiveCRUD/?id=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          }
        )
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Lead Score DemoDetils

  const LeadScorePositiveDetails = (ids) => {
    axios
      .get(`${BASE_URL}/useradmin/LeadScoreValuespositiveList/?id=${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        setrows3(res?.data);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  // Demo Create Record
  const LeadSubmitPositiveGraphic = () => {
    let arr = [];
    rows3.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Category_Value === "" ||
          item.Category_Value === undefined ||
          item.Category_Value === null ||
          item.Operator === "" ||
          item.Operator === undefined ||
          item.Operator === null ||
          item.Score === "" ||
          item.Score === undefined ||
          item.Score === null
        ) {
          toast.error("Invalid Data in Lead DemoGrphics");
        } else {
          let playload = {
            Catategory_Name: catname?.Demo_Description,
            Graphics_name: demotype,
            Category_Value: item?.Category_Value,
            Operator: item?.Operator,
            Unique_Id: catname?.Unique_Id,
            Score: item?.Score,
            LeadPositiveGraphic_Id: catname?.id,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };
          console.log("playload", playload);
          axios
            .post(
              `${BASE_URL}/useradmin/LeadScoreValuespositiveCRUD/`,
              playload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  db: localStorage.getItem("DBName"),
                },
              }
            )
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Lead Score Setup Created Successfully");
                LeadScorePositiveDetails(res?.data?.LeadPositiveGraphic_Id);
              }
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Demo Delete Record
  const handleAddChange3 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows3];
    updatedRows[index][name] = value;
    setrows3(updatedRows);
  };

  // handle Update on the Lead Scoring Page

  const handleupdate3 = (index, data) => {
    if (data.id !== undefined) {
      if (data.Category_Value === "" || data.Score === "") {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadScorePositive_Id: data.LeadScorePositive_Id,
          Catategory_Name: data?.Catategory_Name,
          Operator: data?.Operator,
          Graphics_name: data?.Graphics_name,
          Category_Value: data?.Category_Value,
          Score: data?.Score,
          LeadPositiveGraphic_Id: data?.LeadPositiveGraphic_Id,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadScoreValuespositiveCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              PositiveRecords();
              // Generalrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };

  //  Negative Records

  const [rows4, setrows4] = useState([]);

  const handleAddRow4 = () => {
    const newitem = {
      Category_Value: "",
      Score: "",
      Operator: "",
      allow: true,
    };
    setrows4([...rows4, newitem]);
  };

  // Demo Removal Record
  const handleRemoveRow4 = (item, index) => {
    handleDeleteprediction4(item);
    setrows4((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction4 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(
          `${BASE_URL}/useradmin/LeadScoreValuesNegativeCRUD/?id=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          }
        )
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Demo Create Record
  const LeadSubmitNegativeGraphic = () => {
    let arr = [];
    rows4.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Category_Value === "" ||
          item.Category_Value === undefined ||
          item.Category_Value === null ||
          item.Operator === "" ||
          item.Operator === undefined ||
          item.Operator === null ||
          item.Score === "" ||
          item.Score === undefined ||
          item.Score === null
        ) {
          toast.error("Invalid Data in Lead DemoGrphics");
        } else {
          let playload = {
            Catategory_Name: catname?.Demo_Description,
            Graphics_name: demotype,
            Category_Value: item?.Category_Value,
            Operator: item?.Operator,
            Unique_Id: catname?.Unique_Id,
            Score: item?.Score,
            LeadNegativeGraphic_Id: catname?.id,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };
          console.log("playload", playload);
          axios
            .post(
              `${BASE_URL}/useradmin/LeadScoreValuesNegativeCRUD/`,
              playload,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  db: localStorage.getItem("DBName"),
                },
              }
            )
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Lead Score Setup Created Successfully");
                LeadScoreNegativeDetails(res?.data?.LeadNegativeGraphic_Id);
              }
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Demo Delete Record
  const handleAddChange4 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows4];
    updatedRows[index][name] = value;
    setrows4(updatedRows);
  };

  // handle Update on the Lead Scoring Page

  const handleupdate4 = (index, data) => {
    if (data.id !== undefined) {
      if (data.Category_Value === "" || data.Score === "") {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadScoreNegative_Id: data.LeadScoreNegative_Id,
          Catategory_Name: data?.Catategory_Name,
          Operator: data?.Operator,
          Graphics_name: data?.Graphics_name,
          Category_Value: data?.Category_Value,
          Score: data?.Score,
          LeadNegativeGraphic_Id: data?.LeadNegativeGraphic_Id,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadScoreValuesNegativeCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              PositiveRecords();
              // Generalrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };

  // Lead Score DemoDetils

  const LeadScoreNegativeDetails = (ids) => {
    axios
      .get(`${BASE_URL}/useradmin/LeadScoreValuesNegativeList/?id=${ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        setrows4(res?.data);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  // Getting the Demo List

  const [demodatas, setDemoDatas] = useState("");

  const DemogetRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadDemoGraphicsList/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.Result", res?.data?.Result);
        setDemoDatas(res?.data?.Result);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  const FirmoRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadFirmoGraphicsFullList/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.Result", res?.data?.Result);
        setDemoDatas(res?.data?.Result);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  const PositiveRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadPositiveGraphicsFullList/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.Result", res?.data?.Result);

        setDemoDatas(res?.data?.Result);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  const NegativeRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadNegativeGraphicsFullList/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.Result", res?.data?.Result);
        setDemoDatas(res?.data?.Result);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  useEffect(() => {
    DemogetRecords();
  }, []);

  return (
    <div>
      <JumboContentLayoutMain>
        <Typography sx={[ComponentHeading]}> Lead Scoring Setup</Typography>
        <Div sx={[DisplayFlex, { justifyContent: "start" }]}>
          <Div sx={[DisplayFlex, { gap: "10px" }]}>
            <Typography sx={[{ fontSize: "14px" }]}> Choose a Type</Typography>
            <FormControl>
              <Autocomplete
                options={[
                  "Demographic",
                  "Firemographic",
                  "Behavior-Positive",
                  "Behavior-Negative",
                ]}
                value={demotype}
                className="search-select"
                getOptionLabel={(option) => option || ""}
                sx={{ width: 250 }}
                onChange={(e, newValue) => ShowField(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Div>
          <Div sx={[DisplayFlex, { gap: "10px", ml: 10 }]}>
            <Typography sx={[{ fontSize: "14px" }]}>
              {" "}
              Choose a {demotype} Filed
            </Typography>
            <FormControl>
              <Autocomplete
                options={demodatas || []}
                className="search-select"
                onChange={(e, newValue) => {
                  setCatName(newValue);
                  setScoreVal(newValue?.Score);
                  setId(newValue?.id);
                  if (newValue?.Graphics_Name == "Demographic") {
                    LeadScoreDemoDetails(newValue?.id);
                  }
                  if (newValue?.Graphics_Name == "Firemographic") {
                    LeadScoreFrimoDetails(newValue?.id);
                  }
                  if (newValue?.Graphics_Name == "Behavior-Positive") {
                    LeadScorePositiveDetails(newValue?.id);
                  }
                  if (newValue?.Graphics_Name == "Behavior-Negative") {
                    LeadScoreNegativeDetails(newValue?.id);
                  }
                }}
                getOptionLabel={(option) => option?.Demo_Description || ""}
                sx={{ width: 250 }}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Div>
        </Div>

        {demo && (
          <>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={scrollHeight ? scrollHeight : 400}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table stickyHeader className="table" sx={{ mt: 2 }}>
                <TableHead sx={[CRMTableHead, FontStyle]}>
                  <CRMTableRow>
                    <CRMTableCell>Code</CRMTableCell>
                    <CRMTableCell>Graphics Name</CRMTableCell>
                    <CRMTableCell>Operator</CRMTableCell>
                    <CRMTableCell>Values</CRMTableCell>
                    <CRMTableCell>Score</CRMTableCell>
                    <CRMTableCell align="center">Action</CRMTableCell>
                  </CRMTableRow>
                </TableHead>
                <TableBody>
                  {rows1.length > 0 &&
                    rows1.map((item, index) => {
                      //  console.log("value",item.Value)
                      return (
                        <CRMTableRow key={index}>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "40%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={item?.LeadScoreValues_Id}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={catname?.Demo_Description}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl
                              className=""
                              sx={{
                                padding: "0px",
                              }}
                            >
                              <Select
                                sx={{
                                  height: "33px !important",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    xl: "70px !important",
                                  },
                                  padding: "0px",
                                }}
                                className="react-select-box"
                                name="Operator"
                                placeholder="Operator"
                                value={item?.Operator}
                                onChange={(e) => {
                                  handleAddChange1(e, index);
                                }}
                              >
                                {Operatortypes &&
                                  Operatortypes.map((item, index) => (
                                    <MenuItem value={item.parameter}>
                                      {item.parameter}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Value"
                                name="Category_Value"
                                value={item?.Category_Value}
                                onChange={(e) => {
                                  handleAddChange1(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>

                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "30%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Score"
                                name="Score"
                                value={item?.Score}
                                onChange={(e) => {
                                  handleAddChange1(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell data-title="Action" align="center">
                            <Div>
                              <IconButton
                                className="edit-icon"
                                disabled={item?.allow}
                                onClick={(e) => {
                                  handleupdate1(index, item);
                                }}
                              >
                                <BiEdit />
                              </IconButton>

                              <Button
                                color="error"
                                onClick={() => {
                                  handleRemoveRow1(item, index);
                                }}
                              >
                                <MdDelete size={24} />
                              </Button>
                            </Div>
                          </CRMTableCell>
                        </CRMTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </JumboScrollbar>
            <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
              * Score shall be less than {scoreval}
            </Div>
            <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                sx={{ mt: 1, ml: 2, color: "#10BBE5" }}
                onClick={handleAddRow1}
              >
                + Add More Lines
              </Button>
            </Div>
            <Div
              sx={{
                mt: 0,
                justifyContent: "end",
                alignItems: "center",
                display: "flex",
                mr: 5,
                mb: 4,
              }}
            >
              <CustomButton
                onClick={LeadSubmitDemoGraphic}
                label="Save"
                type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton label="Cancel" />
            </Div>
          </>
        )}

        {Firemographic && (
          <>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={scrollHeight ? scrollHeight : 400}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table stickyHeader className="table" sx={{ mt: 2 }}>
                <TableHead sx={[CRMTableHead, FontStyle]}>
                  <CRMTableRow>
                    <CRMTableCell>Code</CRMTableCell>
                    <CRMTableCell>Graphics Name</CRMTableCell>
                    <CRMTableCell>Operator</CRMTableCell>
                    <CRMTableCell>Values</CRMTableCell>
                    <CRMTableCell>Score</CRMTableCell>
                    <CRMTableCell align="center">Action</CRMTableCell>
                  </CRMTableRow>
                </TableHead>
                <TableBody>
                  {rows2.length > 0 &&
                    rows2.map((item, index) => {
                      //  console.log("value",item.Value)
                      return (
                        <CRMTableRow key={index}>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "40%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={item?.LeadScoreFirmo_Id}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={catname?.Demo_Description}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl
                              className=""
                              sx={{
                                padding: "0px",
                              }}
                            >
                              <Select
                                sx={{
                                  height: "33px !important",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    xl: "200px !important",
                                  },
                                  padding: "0px",
                                }}
                                className="react-select-box"
                                name="Operator"
                                placeholder="Operator"
                                value={item?.Operator}
                                onChange={(e) => {
                                  handleAddChange2(e, index);
                                }}
                              >
                                {Operatortypes &&
                                  Operatortypes.map((item, index) => (
                                    <MenuItem value={item.parameter}>
                                      {item.parameter}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </CRMTableCell>

                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Value"
                                name="Category_Value"
                                value={item?.Category_Value}
                                onChange={(e) => {
                                  handleAddChange2(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>

                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "30%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Score"
                                name="Score"
                                value={item?.Score}
                                onChange={(e) => {
                                  handleAddChange2(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell data-title="Action" align="center">
                            <Div>
                              <IconButton
                                className="edit-icon"
                                disabled={item?.allow}
                                onClick={(e) => {
                                  handleupdate2(index, item);
                                }}
                              >
                                <BiEdit />
                              </IconButton>

                              <Button
                                color="error"
                                onClick={() => {
                                  handleRemoveRow2(item, index);
                                }}
                              >
                                <MdDelete size={24} />
                              </Button>
                            </Div>
                          </CRMTableCell>
                        </CRMTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </JumboScrollbar>
            <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
              * Score shall be less than {scoreval}
            </Div>
            <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                sx={{ mt: 1, ml: 2, color: "#10BBE5" }}
                onClick={handleAddRow2}
              >
                + Add More Lines
              </Button>
            </Div>
            <Div
              sx={{
                mt: 0,
                justifyContent: "end",
                alignItems: "center",
                display: "flex",
                mr: 5,
                mb: 4,
              }}
            >
              <CustomButton
                onClick={LeadSubmitFirmoGraphic}
                label="Save"
                type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton label="Cancel" />
            </Div>
          </>
        )}

        {Positive && (
          <>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={scrollHeight ? scrollHeight : 400}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table stickyHeader className="table" sx={{ mt: 2 }}>
                <TableHead sx={[CRMTableHead, FontStyle]}>
                  <CRMTableRow>
                    <CRMTableCell>Code</CRMTableCell>
                    <CRMTableCell>Graphics Name</CRMTableCell>
                    <CRMTableCell>Operator</CRMTableCell>
                    <CRMTableCell>Values</CRMTableCell>
                    <CRMTableCell>Score</CRMTableCell>
                    <CRMTableCell align="center">Action</CRMTableCell>
                  </CRMTableRow>
                </TableHead>
                <TableBody>
                  {rows3.length > 0 &&
                    rows3.map((item, index) => {
                      //  console.log("value",item.Value)
                      return (
                        <CRMTableRow key={index}>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "40%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={item?.LeadScorePositive_Id}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={catname?.Demo_Description}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl
                              className=""
                              sx={{
                                padding: "0px",
                              }}
                            >
                              <Select
                                sx={{
                                  height: "33px !important",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    xl: "200px !important",
                                  },
                                  padding: "0px",
                                }}
                                className="react-select-box"
                                name="Operator"
                                placeholder="Operator"
                                value={item?.Operator}
                                onChange={(e) => {
                                  handleAddChange3(e, index);
                                }}
                              >
                                {Operatortypes &&
                                  Operatortypes.map((item, index) => (
                                    <MenuItem value={item.parameter}>
                                      {item.parameter}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Value"
                                name="Category_Value"
                                value={item?.Category_Value}
                                onChange={(e) => {
                                  handleAddChange3(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "30%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Score"
                                name="Score"
                                value={item?.Score}
                                onChange={(e) => {
                                  handleAddChange3(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell data-title="Action" align="center">
                            <Div>
                              <IconButton
                                className="edit-icon"
                                disabled={item?.allow}
                                onClick={(e) => {
                                  handleupdate3(index, item);
                                }}
                              >
                                <BiEdit />
                              </IconButton>

                              <Button
                                color="error"
                                onClick={() => {
                                  handleRemoveRow3(item, index);
                                }}
                              >
                                <MdDelete size={24} />
                              </Button>
                            </Div>
                          </CRMTableCell>
                        </CRMTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </JumboScrollbar>
            <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
              * Score shall be less than {scoreval}
            </Div>
            <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                sx={{ mt: 1, ml: 2, color: "#10BBE5" }}
                onClick={handleAddRow3}
              >
                + Add More Lines
              </Button>
            </Div>
            <Div
              sx={{
                mb: 4,
                justifyContent: "end",
                alignItems: "center",
                display: "flex",
                mr: 5,
              }}
            >
              <CustomButton
                onClick={LeadSubmitPositiveGraphic}
                label="Save"
                type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton label="Cancel" />
            </Div>
          </>
        )}
        {Negative && (
          <>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={scrollHeight ? scrollHeight : 400}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table stickyHeader className="table" sx={{ mt: 2 }}>
                <TableHead sx={[CRMTableHead, FontStyle]}>
                  <CRMTableRow>
                    <CRMTableCell>Code</CRMTableCell>
                    <CRMTableCell>Graphics Name</CRMTableCell>
                    <CRMTableCell>Operator</CRMTableCell>
                    <CRMTableCell>Values</CRMTableCell>
                    <CRMTableCell>Score</CRMTableCell>
                    <CRMTableCell align="center">Action</CRMTableCell>
                  </CRMTableRow>
                </TableHead>
                <TableBody>
                  {rows4.length > 0 &&
                    rows4.map((item, index) => {
                      //  console.log("value",item.Value)
                      return (
                        <CRMTableRow key={index}>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "40%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={item?.LeadScoreNegative_Id}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Default"
                                value={catname?.Demo_Description}
                                disabled={true}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell>
                            <FormControl
                              className=""
                              sx={{
                                padding: "0px",
                              }}
                            >
                              <Select
                                sx={{
                                  height: "33px !important",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    xl: "200px !important",
                                  },
                                  padding: "0px",
                                }}
                                className="react-select-box"
                                name="Operator"
                                placeholder="Operator"
                                value={item?.Operator}
                                onChange={(e) => {
                                  handleAddChange4(e, index);
                                }}
                              >
                                {Operatortypes &&
                                  Operatortypes.map((item, index) => (
                                    <MenuItem value={item.parameter}>
                                      {item.parameter}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </CRMTableCell>

                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "100%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Value"
                                name="Category_Value"
                                value={item?.Category_Value}
                                onChange={(e) => {
                                  handleAddChange4(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>

                          <CRMTableCell>
                            <FormControl>
                              <TextField
                                sx={{ width: { xs: "100%", md: "30%" } }}
                                className="input-box"
                                variant="outlined"
                                placeholder="Score"
                                name="Score"
                                value={item?.Score}
                                onChange={(e) => {
                                  handleAddChange4(e, index);
                                }}
                              ></TextField>
                            </FormControl>
                          </CRMTableCell>
                          <CRMTableCell data-title="Action" align="center">
                            <Div>
                              <IconButton
                                className="edit-icon"
                                disabled={item?.allow}
                                onClick={(e) => {
                                  handleupdate4(index, item);
                                }}
                              >
                                <BiEdit />
                              </IconButton>

                              <Button
                                color="error"
                                onClick={() => {
                                  handleRemoveRow4(item, index);
                                }}
                              >
                                <MdDelete size={24} />
                              </Button>
                            </Div>
                          </CRMTableCell>
                        </CRMTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </JumboScrollbar>
            <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
              * Score shall be less than {scoreval}
            </Div>
            <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                sx={{ mt: 1, ml: 2, color: "#10BBE5" }}
                onClick={handleAddRow4}
              >
                + Add More Lines
              </Button>
            </Div>
            <Div
              sx={{
                mb: 4,
                justifyContent: "end",
                alignItems: "center",
                display: "flex",
                mr: 5,
              }}
            >
              <CustomButton
                onClick={LeadSubmitNegativeGraphic}
                label="Save"
                type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton label="Cancel" />
            </Div>
          </>
        )}

        {/* pagination */}
        <Div
          sx={{
            mt: 3,
            mr: 2,
          }}
        ></Div>
      </JumboContentLayoutMain>
      <ToastContainer />
    </div>
  );
}

export default LeadScoringtypes;
