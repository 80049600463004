import React from "react";
import "./Feed.css";
import CreateIcon from "@mui/icons-material/Create";
import InputOption from "./InputOption";
import ImageIcon from "@mui/icons-material/Image";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import { useState } from "react";
import PostDialog from "./PostDialog";
import axios from "axios";
import { useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import Article from "./Article";
import { useRef } from "react";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactForm from "./ContactForm";

const Feed = ({ usrInfo }) => {
  const [postDialogOpen, setPostDialogOpen] = useState(false);
  const [isArticleOpen, setIsArticleOpen] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const formRef = useRef(null);
  const token = localStorage.getItem("accessToken");

  const [userInfo, setUserInfo] = useState(usrInfo);

  useEffect(() => {
    setUserInfo(usrInfo);
  }, [usrInfo]);

  const handlePost = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);
    console.log("formProps", formProps);

    const payload = {
      UserId: localStorage.getItem("UserId"),
      sub: userInfo?.sub,
      subject: formProps?.postText,
    };

    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(`${BASE_URL}/user/LinkdinCRUD/`, payload, { headers: headers })
      .then((res) => {
        console.log("post response", res?.data?.status);
        if (res?.status == 200) {
          toast.success(res?.data?.status);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });

    formRef?.current.reset(); //for resetting the form
  };

  return (
    <div style={{ backgroundColor: "#dbdbdb" }}>
      <div className="feed">
        <div className="feed_inputContainer">
          <div className="feed_input">
            <IconButton>
              <CreateIcon />
            </IconButton>
            <form ref={formRef} onSubmit={handlePost}>
              <input type="text" name="postText" placeholder="Start a Post" />
              <Button
                type="submit"
                className="dialog-save-btn"
                variant="contained"
              >
                Post
              </Button>
            </form>
          </div>
          <div className="feed_inputOptions">
            <span
              onClick={() => {
                setPostDialogOpen(true);
              }}
            >
              <InputOption Icon={ImageIcon} title="Photo" color="#70B5F9" />
            </span>

            {/* <InputOption Icon={SubscriptionsIcon} title='Video' color='#E7A33E' /> */}
            {/* <InputOption Icon={EventNoteIcon} title='Event' color='#COCBCD' /> */}
            <span
              onClick={() => {
                setIsArticleOpen(true);
              }}
            >
              <InputOption
                Icon={CalendarViewDayIcon}
                title="Write article"
                color="#7FC15E"
              />
            </span>
            <span
              onClick={() => {
                setIsContactFormOpen(true);
              }}
            >
              <InputOption Icon={ContactMailIcon} title="Contact form" />
            </span>
          </div>
        </div>
      </div>
      {postDialogOpen && (
        <PostDialog
          postDialogOpen={postDialogOpen}
          setPostDialogOpen={setPostDialogOpen}
          userInfo={userInfo}
        />
      )}
      {isArticleOpen && (
        <Article
          isArticleOpen={isArticleOpen}
          setIsArticleOpen={setIsArticleOpen}
          userInfo={userInfo}
        />
      )}
      {isContactFormOpen && (
        <ContactForm
          isContactFormOpen={isContactFormOpen}
          setIsContactFormOpen={setIsContactFormOpen}
          usrInfo={userInfo}
        ></ContactForm>
      )}
    </div>
  );
};

export default Feed;
