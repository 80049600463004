import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import { FontStyle } from "app/pages/widgets/CRMStyles";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import CoolIcon from "@mui/icons-material/AcUnit";
import WarmIcon from "@mui/icons-material/Star";
import HotIcon from "@mui/icons-material/Highlight";

const TemperatureTable = () => {
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const currentDate = new Date();

  // Calculate the last month's date
  const lastMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1
  );
  const CurrenMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  // Get the month name of the last month
  const lastMonthName = lastMonthDate.toLocaleString("default", {
    month: "long",
  });
  const CurrentMonthName = CurrenMonthDate.toLocaleString("default", {
    month: "long",
  });

  const getTemperatureData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/LeadHomeDash/`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const { data: temperatureLists } = useQuery(
    ["temperatureLists"],
    getTemperatureData,
    {
      staleTime: Infinity,
    }
  );

  const [showData, setShowData] = useState({});

  useEffect(() => {
    if (temperatureLists) {
      setShowData(temperatureLists);
    }
  }, [temperatureLists]);

  return (
    <Box p={2}>
      {/* Temperature Legend */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Temperature Legend
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card
            variant="outlined"
            style={{
              backgroundColor: "#eef1ee",
              borderRadius: "5px",
              border: "0px solid",
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center">
                <CoolIcon style={{ color: "rgb(90 90 90)", marginRight: 8 }} />
                <Typography
                  variant="h4"
                  color={"#505050"}
                  style={{ marginTop: "4%" }}
                >
                  Cool
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            variant="outlined"
            style={{
              backgroundColor: "#eef1ee",
              borderRadius: "5px",
              border: "0px solid",
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center">
                <WarmIcon style={{ color: "rgb(90 90 90)", marginRight: 8 }} />
                <Typography
                  variant="h4"
                  color="#505050"
                  style={{ marginTop: "4%" }}
                >
                  Warm
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card
            variant="outlined"
            style={{
              backgroundColor: "#eef1ee",
              borderRadius: "5px",
              border: "0px solid",
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center">
                <HotIcon style={{ color: "rgb(90 90 90)", marginRight: 8 }} />
                <Typography
                  variant="h4"
                  color="#505050"
                  style={{ marginTop: "4%" }}
                >
                  Hot
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Temperature Data */}
      <Grid item xs={12} mt={2}>
        <Typography variant="h4" gutterBottom>
          Temperature Data
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            style={{ backgroundColor: "#f1f1f1", border: "0px solid #fff" }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "#484848", fontWeight: "700" }}
              >
                {lastMonthName}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography>Cold:</Typography>
                <Typography>{showData?.Previous_Month_Cold}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Warm:</Typography>
                <Typography>{showData?.Previous_Month_Warm}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Hot:</Typography>
                <Typography>{showData?.Previous_Month_Hot}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            variant="outlined"
            style={{ backgroundColor: "#f1f1f1", border: "0px solid #fff" }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "#484848", fontWeight: "700" }}
              >
                {CurrentMonthName}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography>Cold:</Typography>
                <Typography>{showData?.Current_Month_Cold}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Warm:</Typography>
                <Typography>{showData?.Current_Month_Warm}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Hot:</Typography>
                <Typography>{showData?.Current_Month_Hot}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            style={{ backgroundColor: "#f1f1f1", border: "0px solid #fff" }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: "#484848", fontWeight: "700" }}
              >
                Change
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography>Cold:</Typography>
                <Typography>{showData?.Change_Cold} %</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Warm:</Typography>
                <Typography>{showData?.Change_Warm} %</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Hot:</Typography>
                <Typography>{showData?.Change_Hot} %</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemperatureTable;
