import Div from "@jumbo/shared/Div";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Select,
  Stack,
  TextField,
  Typography,
  MenuItem,
  TableRow,
  TableHead,
  TableBody,
  Table,
  ButtonGroup,
  Box,
  FormControl,
  Popover,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { CloseIcon, EditIcon, MailIcon, PhoneIcon } from "app/icons/PngIcons";

import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import React, { useCallback, useEffect, useState } from "react";
import {
  MdOutlineModeEdit,
  MdOutlineSaveAs,
  MdOutlineScheduleSend,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import randomColor from "randomcolor";

import {
  AiOutlineClockCircle,
  AiOutlineEdit,
  AiOutlineMail,
} from "react-icons/ai";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import moment from "moment";
import { BASE_URL, BASE_URL1, Tenantname } from "app/services/auth-services";
import { alpha, styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Switch from "@mui/material/Switch";
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
import "./dialogCall.css";
import {
  CallEnd,
  KeyboardDoubleArrowUp,
  Mic,
  MicOff,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import {
  ButtonStyle,
  CRMTableHead,
  dialogTitle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  generateRandomColor,
  Colors,
  customHeight,
  AntTabs,
  AntTab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  input_label,
  dialogFontSize,
  input_label1,
} from "app/pages/widgets/CRMStyles";
import { IoIosArrowBack, IoMdAttach, IoMdClose } from "react-icons/io";
import { BsClockHistory } from "react-icons/bs";
import { TiPhoneOutline } from "react-icons/ti";
import DealConatctNotes from "./ContactNotes";
import DealCallHistory from "./DealCallHistory";
import DealEmailContent from "app/pages/home/email-lists/DealEmailContent";
import DealOpenActivity from "./DealOpenActivity";
import Appointment from "./Appointment";
import DealHistory from "./DealHistory";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import DealOverView from "./DealOverView";
import {
  AvatarCircle,
  Cancelbutton,
  DialogBox,
  CustomButton,
  SelectDropdown,
  errorFilter,
  Dragabledialog,
  ckEditorOnchange,
  Customgrid,
  CustomCKEditor,
} from "app/pages/widgets/StylesComponents";
import { AutoHeight } from "app/redux/auth/localStorage";
import { ProfileImageUpload } from "app/pages/contacts/extra-components/APIs";
import { GrTransaction } from "react-icons/gr";
import TransactionReport from "./TransactionReport";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ComposeEmail from "app/pages/contacts/extra-components/ComposeEmail";

const OverviewDealContact = ({ scrollHeight }) => {
  const navigate = useNavigate();

  const [ClientEngagement, setClientEngagement] = useState("");
  const [BudgetAlignment, setBudgetAlignment] = useState("");
  const [DecisionMaker, setDecisionMaker] = useState("");
  const [UniqueSelling, setUniqueSelling] = useState("");
  const [MarketCondition, setMarketCondition] = useState("");
  const [ROIValue, setROIValue] = useState("");
  const [PostSales, setPostSales] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  let ClientEngagementlist = [
    { value: "High" },
    { value: "Medium" },
    { value: "Low" },
  ];

  let BudgetAlignmentlist = [
    { value: "Well alignment" },
    { value: "Somewhat aligned" },
    { value: "Poorly aligned" },
  ];

  let DecisionMakerlist = [
    { value: "High" },
    { value: "Medium" },
    { value: "Low" },
  ];

  let UniqueSellinglist = [
    { value: "Unique" },
    { value: "Average" },
    { value: "Low" },
  ];

  let MarketConditionlist = [
    { value: "Favorable" },
    { value: "Average" },
    { value: "Not Favorable" },
  ];

  let ROIValuelist = [
    { value: "Demonstrated ROI" },
    { value: "Articulated Benefits" },
  ];

  let PostSaleslist = [
    { value: "Custom support offered" },
    { value: "Standard support offered" },
  ];

  let rows = [
    {
      Weight: "Client Engagement",
      Points: "",
      value: ClientEngagement,
      id: "",
    },
    {
      Weight: "Budget Alignment",
      Points: "",
      value: BudgetAlignment,
      id: "",
    },
    {
      Weight: "Decision Maker",
      Points: "",
      value: DecisionMaker,
      id: "",
    },
    {
      Weight: "Unique Selling Point",
      Points: "",
      value: UniqueSelling,
      id: "",
    },
    {
      Weight: "Market Condition",
      Points: "",
      value: MarketCondition,
      id: "",
    },
    {
      Weight: "ROI & Value proposition",
      Points: "",
      value: ROIValue,
      id: "",
    },
    {
      Weight: "Post Sales Support",
      Points: "",
      value: PostSales,
      id: "",
    },
  ];

  const [firsttimeid, setfirsttimeid] = useState("");
  const [initiatedid, setinitiatedid] = useState("");
  const [respondingid, setrespondingid] = useState("");
  const [understoodid, setunderstoodid] = useState("");
  const [biddersid, setbiddersid] = useState("");
  const [economyid, seteconomyid] = useState("");
  const [supportid, setsupportid] = useState("");
  const [satisfiedid, setsatisfiedid] = useState("");
  const [budgetid, setbudgetid] = useState("");
  const [addingvaluesid, setaddingvaluesid] = useState("");
  const [approvedid, setapprovedid] = useState("");
  const [decisionid, setdecisionid] = useState("");
  const [customizationid, setcustomizationid] = useState("");
  const [customisationfreeid, setcustomisationfreeid] = useState("");

  const [firsttime, setfirsttime] = useState(false);
  const [initiated, setinitiated] = useState(false);
  const [responding, setresponding] = useState(false);
  const [understood, setunderstood] = useState(false);
  const [bidders, setbidders] = useState(false);
  const [economy, seteconomy] = useState(false);
  const [support, setsupport] = useState(false);
  const [satisfied, setsatisfied] = useState(false);
  const [budget, setbudget] = useState(false);
  const [addingvalues, setaddingvalues] = useState(false);
  const [approved, setapproved] = useState(false);
  const [decision, setdecision] = useState(false);
  const [customization, setcustomization] = useState(false);
  const [customisationfree, setcustomisationfree] = useState(false);
  let rowsrating = [
    {
      Weight: "Is our company is first time to client",
      Points: firsttime == true ? "-5" : "5",
      Values: [
        { value: "Yes", point: "-5" },
        { value: "No", point: "5" },
      ],
      value: firsttime,
      id: firsttimeid,
    },
    {
      Weight: "Is this deal was initiated by client",
      Points: initiated == true ? "0" : "5",
      Values: [
        { value: true, point: "0" },
        { value: false, point: "5" },
      ],
      value: initiated,
      id: initiatedid,
    },
    {
      Weight: "Is client is responding to our proposal",
      Points: responding == true ? "-5" : "5",
      Values: [
        { value: true, point: "-5" },
        { value: false, point: "5" },
      ],
      value: responding,
      id: respondingid,
    },
    {
      Weight: "Is the client understood our product",
      Points: understood == true ? "2" : "0",
      Values: [
        { value: true, point: "2" },
        { value: false, point: "0" },
      ],
      value: understood,
      id: understoodid,
    },
    {
      Weight: "Is there any other bidders",
      Points: bidders == true ? "-5" : "5",
      Values: [
        { value: true, point: "-5" },
        { value: false, point: "5" },
      ],
      value: bidders,
      id: biddersid,
    },
    {
      Weight: "Is client looking for economy solution",
      Points: economy == true ? "2" : "0",
      Values: [
        { value: true, point: "2" },
        { value: false, point: "0" },
      ],
      value: economy,
      id: economyid,
    },
    {
      Weight: "Is client looking for support after sales",
      Points: support == true ? "2" : "0",
      Values: [
        { value: true, point: "2" },
        { value: false, point: "0" },
      ],
      value: support,
      id: supportid,
    },
    {
      Weight: "Is client satisfied our product demo",
      Points: satisfied == true ? "5" : "-5",
      Values: [
        { value: true, point: "5" },
        { value: false, point: "-5" },
      ],
      value: satisfied,
      id: satisfiedid,
    },
    {
      Weight: "Is our product price is with in the budget",
      Points: budget == true ? "5" : "-5",
      Values: [
        { value: true, point: "5" },
        { value: false, point: "-5" },
      ],
      value: budget,
      id: budgetid,
    },
    {
      Weight: "Is our product adding values to client",
      Points: addingvalues == true ? "5" : "-5",
      Values: [
        { value: true, point: "5" },
        { value: false, point: "-5" },
      ],
      value: addingvalues,
      id: addingvaluesid,
    },
    {
      Weight: "Is budget approved",
      Points: approved == true ? "5" : "-3",
      Values: [
        { value: true, point: "5" },
        { value: false, point: "-3" },
      ],
      value: approved,
      id: approvedid,
    },
    {
      Weight: "Is decision maker",
      Points: decision == true ? "10" : "-5",
      Values: [
        { value: true, point: "10" },
        { value: false, point: "-5" },
      ],
      value: decision,
      id: decisionid,
    },
    {
      Weight: "Are we ready to do the customization",
      Points: customization == true ? "5" : "-5",
      Values: [
        { value: true, point: "5" },
        { value: false, point: "-5" },
      ],
      value: customization,
      id: customizationid,
    },
    {
      Weight: "Is client looking customisation free",
      Points: customisationfree == true ? "-5" : "5",
      Values: [
        { value: true, point: "-5" },
        { value: false, point: "5" },
      ],
      value: customisationfree,
      id: customisationfreeid,
    },
  ];

  const [base64, setbase64] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [openUploadEdit, setOpenUploadEdit] = useState(false);
  const [showNotes, setShowNotes] = useState(true);
  const [showMail, setShowMail] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [showDial, setShowDial] = useState(false);
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [noteCall, setNoteCall] = useState(false);
  // const [notes, setNotes] = useState("")
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachFile, setAttachFile] = useState("");
  const [showMailDialog, setShowMailDialog] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [bannerColor, setBannerColors] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [openAddLead, setOpenAddLead] = useState(false);
  const [showOpenActivity, setShowOpenActivity] = useState(false);
  const [showClosedActivity, setShowClosedActivity] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [phonenotes, setPhoneNotes] = useState(false);
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [showCc, setShowCc] = useState(false);

  const [predictbutton, setpredictbutton] = useState(true);

  const option = [
    "Connected",
    "Customer Missed",
    "Voice mail",
    "Busy",
    "Disconnect",
    "Missed call",
    "Call waiting",
    "Confidence call",
    "Call attended",
    "Do Not Disturb Turned On",
    "Call Not Picked",
    "Called Back",
  ];

  const contaxtoptions = [
    "Solving Problem",
    "Making Decision",
    "Solution Achieved",
  ];
  const callconvoptions = [
    "Casual",
    "Tension",
    "Not Interested",
    "Friendly",
    "Confidancial",
  ];

  const BusyHroptions = ["Morning", "Evening", "After Noon"];

  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");
  const [nextdate, setNextDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [CallRecording, setCallRecording] = useState(null);
  const [MissedRang, setMissedRang] = useState(0);
  const [callcontext, setCallContext] = useState("");
  const [busy, setBusy] = useState("");
  const [callconvstatus, setCallconvstatus] = useState("");
  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const [tabValue, setTabValue] = useState(0);

  const [firstREmainderHrs, setfirstREmainderHrs] = useState("");
  const [Repeateveryhrs, setRepeateveryhrs] = useState("");
  const [firstRemainder, setfirstRemainder] = useState("");
  const [repeatEvery, setrepeatEvery] = useState("");
  const [isReminderSet, setIsReminderSet] = useState(false);
  const [show, setShow] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  // mail sending ---------------------------------------
  const [base64DataList, setBase64DataList] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [tap, setTap] = useState(0);

  const handleTap = useCallback(
    (value) => {
      setTap(value);
    },
    [tap]
  );

  const handleEditorChange = (event) => {
    const value = event.editor.getData();
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const textContent = doc.body.textContent || "";
    setNotes(textContent);
  };

  const setreminder = (event) => {
    setIsReminderSet(true);
    setShow(true);
    console.log("inside");
  };

  const handleDialogClose = (confirmed) => {
    setIsReminderSet(false);
  };

  const onChange = (evt, editor) => {
    ckEditorOnchange(evt, editor, setEditorContent);
    // ////console.log("eve", evt);
    // var newContent = evt.editor.getData();
    // ////console.log("newContent", newContent);
    // const doc = parser.parseFromString(value, "text/html");
    // const textContent = doc.body.textContent || "";
    // setEditorContent(textContent);
  };

  const handleFileInputChange = (e) => {
    const fileList = e.target.files;

    const promises = Array.from(fileList).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((base64DataArray) => {
        setBase64DataList(base64DataArray);
        const fileArray = Array.from(fileList);
        let list = [...fileNames, ...fileArray];
        setFileNames(list);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const fileRemoved = (e) => {
    let list = [...fileNames];
    list?.splice(e, 1);
    let base64Array = [...base64DataList];
    base64Array?.splice(e, 1);
    setBase64DataList(base64Array);
    // alert(e)
    setFileNames(list);
  };

  const uploadFiles = () => {
    const promises = base64DataList?.map((base64Data, index) => {
      console.log(`Uploading `, fileNames);
      const payload = {
        Organization_Id: localStorage.getItem("OrganizationId"),
        File: base64Data,
        FileName: randomColor() + fileNames[index]?.name,
        Urls: "url",
        Description: "Upload File",
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };

      return axios.post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    });

    Promise.all(promises)
      .then((responses) => {
        console.log("Files uploaded successfully:", responses);
        MailSubmit();
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  const mailBodyContent = `<div class='card'>
<div class='card-body'>
  <h4 className="card-subtitle mb-2 text-muted">Dear Sir,</h4>
  <div class='card-text'>
    <p>${message}</p>
    <p>Click the button to download the attachment </p>
    <div>
    ${base64DataList?.map((items, index) => (
      <a href={`BASE_URL/media/${fileNames[index]?.name}`}>
        <button
          type="button"
          class="btn btn-primary"
          style="background-color:#3c7c33; color: #fff; border: none; border-radius: 4px; padding: 8px 16px; cursor: pointer; text-decoration: none;"
        >
          Download Attachment
        </button>
      </a>
    ))}
    
    
    </div>
        </div>
</div>
</div>`;

  const MailSubmit = async (e) => {
    // e.preventDefault();
    var payload = {
      MailSubject: subject,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Cc: [cc],
      MailBody: mailBodyContent,
      file:
        fileNames?.length > 0
          ? fileNames?.map((fileName) => fileName?.name)
          : [],
      RecevierMailId: to,
      UserId: localStorage.getItem("UserId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Deal_Id: id,
    };
    console.log("payload", payload);
    await axios
      .post(`${BASE_URL}/user/DealMailMasterCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast.success("Mail Sent successfully", {
          position: "top-right",
          autoClose: 100,
        });
        getData();
        setShowMailDialog(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleSubmit = (e) => {
    console.log("Deal,", datas);
    e.preventDefault();
    var payload = {
      CallCenter_Id: "",
      Company_Name: datas?.Account_Id?.Organization_Name,
      Receiver_Name: datas?.Account_Name,
      Contact_Number: datas?.Account_Id?.Phone_Number,
      Selected_Date: moment().format("YYYY-MM-DD"),
      Next_SheduledTime: nextdate,
      Call_Duration: duration,
      Missed_Rank: MissedRang,
      Call_Recording: CallRecording,
      Call_Context: callcontext,
      Call_Con_Status: callconvstatus,
      Call_Busy_hour: busy,
      Activity_Type: "Call",
      Activity_relates: "Deal",
      Details: notes,
      Select_Status: status,
      Reminder_Payload: Boolean(show)
        ? {
            RemainderDueDate: firstRemainder + " " + firstREmainderHrs,
            Repeat_EveryDay: repeatEvery + " " + Repeateveryhrs,
            ReminderFor: "Reminder for Deal Calls",
            RemainderMessage: editorContent,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          }
        : null,
      Deal_Id: Number(id),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };
    console.log("Call", payload);
    axios
      .post(`${BASE_URL}/user/CallcenterActivityCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("check", res);
        toast.success("Created successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setShowDial(false);
        handleClose();

        // for Updating the Contact List

        if (datas?.No_of_Calls == null || "" || NaN) {
          var x = 1;
        } else {
          var x = parseInt(datas?.No_of_Calls) + 1;
        }
        // For Updateing the History of Changes
        let editUser = localStorage.getItem("Username");
        let histotypayload = {
          Document_Id: res.data.CallCenter_Id,
          Updated_Person: `${editUser} Created this Deal Phone History.`,
          Module_Name: "Deal Phone History",
          Deal_Id: Number(id),
          Updated_By: parseInt(localStorage.getItem("UserId")),
        };
        console.log("histotypayload", histotypayload);
        axios
          .post(`${BASE_URL}/user/DealHistorycrud/`, histotypayload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("Result", res.data);
          })
          .catch((res) => {
            console.log(res.message);
          });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const { id } = useParams();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("overview",id);

  const handleShowNotes = () => {
    setShowNotes(true);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowPhone = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(true);
  };

  const handleShowMail = () => {
    setShowNotes(false);
    setShowMail(true);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowOpenActivity = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(true);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowClosedActivity = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(true);
    setShowAppointment(false);
    setShowHistory(false);
  };

  const handleShowAppointment = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(true);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowHistory = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(true);
    setPhoneNotes(false);
  };

  // image upload function
  const handleChangeImage = (e) => {
    // console.log("file uploaded: ", e.target.files[0]);
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = handleReaderLoaded;
      setImage(reader.readAsBinaryString(file));
      setImageName(file?.lastModified + file?.name);
    }
  };

  const onsubmit = () => {
    setExpanded(false);
    setNoteCall(false);
    setNotes("");
  };

  const handleReaderLoaded = (e) => {
    // console.log("file uploaded 2: ", e);
    let binaryString = e.target.result;
    setbase64(btoa(binaryString));
  };

  // upload profile image api
  const handleSaveImage = (e) => {
    let payload = { base64, imageName, id };
    let error = errorFilter(payload);

    if (Object.keys(error).length === 0) {
      ProfileImageUpload(payload, setOpenUploadEdit, "AccountCRUD", getData);
      setbase64("");
      setOpenUploadEdit(false);
    }
  };

  // Getting the Conatct details based on the Id
  const item = "crmuseradmin1";
  const token = localStorage.getItem("accesstoken");

  const [Showcloseddeal, setShowcloseddeal] = useState(false);

  const [showCompetitor, setShowCompetitor] = useState(false);
  const [comname, setCompname] = useState("");
  const [comprice, setComprice] = useState("");
  const [cmparr, setCmparr] = useState([]);

  // const [Showcloseddeal, setShowcloseddeal] = useState(false)
  const [closeddealdata, setcloseddealdata] = useState(false);
  const [dealreason, setdealreason] = useState("");
  const [dealstage, setdealstage] = useState("");

  const [predictmodal, setpredictmodal] = useState(false);
  const [postpredict, setpostpredict] = useState(false);
  const [dealpointsdata, setdealpointsdata] = useState([]);

  const [postrating, setpostrating] = useState([]);
  const [dealratingdata, setdealratingdata] = useState([]);

  const [datas, setDatas] = useState([]);
  const [appointments, setAppointments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    let dealid = 0;
    try {
      await axios
        .get(`${BASE_URL}/user/DealCRUD/?id=${id}`, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("resasas", res?.data);
          setDatas(res?.data || []);
          setImageName(randomColor() + res?.data?.FileName || "");
          setCmparr(res?.data?.Deal_Competitor || []);
          dealid = res.data.id;
        });
    } catch (error) {
      console.error("error", error);
    }

    try {
      await axios
        .get(
          `${BASE_URL}/user/DealScoreTableAdditional/?Created_By=${localStorage.getItem(
            "UserId"
          )}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("listPoints", res.data);
          res?.data?.results.map((data, ind) => {
            if (data.Deal_Id === dealid) {
              setClientEngagement(data.Client_Engagement);
              setBudgetAlignment(data.Budget_Allientment);
              setDecisionMaker(data.Desicion_Maker);
              setUniqueSelling(data.Unique_Selling_Point);
              setMarketCondition(data.Market_Condition);
              setROIValue(data.ROI_Prediction);
              setPostSales(data.Post_Sales_Support);
              setpostpredict(true);
              setdealpointsdata(data);
              console.log("post", data);
            }
          });
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }

    try {
      await axios
        .get(
          `${BASE_URL}/user/DealRatingAdditional/?Created_By=${localStorage.getItem(
            "UserId"
          )}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("listRating", res.data);
          rowsrating.map((data, ind) => {
            res.data.map((item, index) => {
              if (dealid === item.Deal_Id) {
                if (data.Weight === item.Deal_Questions) {
                  data.id = item.id;
                  setpredictbutton(false);

                  if (
                    "Is our company is first time to client" ===
                    item.Deal_Questions
                  ) {
                    setfirsttime(item.Deal_Answer == "Yes" ? true : false);
                    setfirsttimeid(item.id);
                  }
                  if (
                    "Is this deal was initiated by client" ===
                    item.Deal_Questions
                  ) {
                    setinitiated(item.Deal_Answer === "Yes" ? true : false);
                    setinitiatedid(item.id);
                  }
                  if (
                    "Is client is responding to our proposal" ===
                    item.Deal_Questions
                  ) {
                    setresponding(item.Deal_Answer === "Yes" ? true : false);
                    setrespondingid(item.id);
                  }
                  if (
                    "Is the client understood our product" ===
                    item.Deal_Questions
                  ) {
                    setunderstood(item.Deal_Answer === "Yes" ? true : false);
                    setunderstoodid(item.id);
                  }
                  if ("Is there any other bidders" === item.Deal_Questions) {
                    setbidders(item.Deal_Answer === "Yes" ? true : false);
                    setbiddersid(item.id);
                  }
                  if (
                    "Is client looking for economy solution" ===
                    item.Deal_Questions
                  ) {
                    seteconomy(item.Deal_Answer === "Yes" ? true : false);
                    seteconomyid(item.id);
                  }
                  if (
                    "Is client looking for support after sales" ===
                    item.Deal_Questions
                  ) {
                    setsupport(item.Deal_Answer === "Yes" ? true : false);
                    setsupportid(item.id);
                  }
                  if (
                    "Is client satisfied our product demo" ===
                    item.Deal_Questions
                  ) {
                    setsatisfied(item.Deal_Answer === "Yes" ? true : false);
                    setsatisfiedid(item.id);
                  }
                  if (
                    "Is our product price is with in the budget" ===
                    item.Deal_Questions
                  ) {
                    setbudget(item.Deal_Answer === "Yes" ? true : false);
                    setbudgetid(item.id);
                  }
                  if (
                    "Is our product adding values to client" ===
                    item.Deal_Questions
                  ) {
                    setaddingvalues(item.Deal_Answer === "Yes" ? true : false);
                    setaddingvaluesid(item.id);
                  }
                  if ("Is budget approved" === item.Deal_Questions) {
                    setapproved(item.Deal_Answer === "Yes" ? true : false);
                    setapprovedid(item.id);
                  }
                  if ("Is decision maker" === item.Deal_Questions) {
                    setdecision(item.Deal_Answer === "Yes" ? true : false);
                    setdecisionid(item.id);
                  }
                  if (
                    "Are we ready to do the customization" ===
                    item.Deal_Questions
                  ) {
                    setcustomization(item.Deal_Answer === "Yes" ? true : false);
                    setcustomizationid(item.id);
                  }
                  if (
                    "Is client looking customisation free" ===
                    item.Deal_Questions
                  ) {
                    setcustomisationfree(
                      item.Deal_Answer === "Yes" ? true : false
                    );
                    setcustomisationfreeid(item.id);
                  }
                }
              }
            });
          });
          console.log("finallist", rowsrating);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const date = new Date();
  let day = date.getDate();
  // console.log("day",day);

  const getAppointments = async () => {
    try {
      await axios
        .get(
          `${BASE_URL}/user/DealMeetingMyAdditional/?Is_Deleted=False&Deal_Id=${id}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("get appointments list", res.data.results);
          setAppointments(res.data?.results);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  // Getting the Activities List

  // For Mail List & Call List

  const [maildatas, setMailDatas] = useState([]);
  const [calldatas, setCallDatas] = useState([]);

  const MailData = () => {
    axios
      .get(`${BASE_URL}/user/DealMailListAdditional/?Deal_Id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log("Mail Data check",res.data.pop());
        setMailDatas(res.data?.pop()?.MailSentDate);
      });
  };

  const CallData = () => {
    axios
      .get(`${BASE_URL}/user/HistoryDealMeetingFilter/?Deal_Id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log("for contact check")
        // console.log("Mail Data check for call",res.data.results.pop());
        setCallDatas(res.data?.results?.pop().Created_Date);
      });
  };

  useEffect(() => {
    MailData();
    CallData();
  }, [id]);

  useEffect(() => {
    getData();
    getAppointments();
    setBannerColors(generateRandomColor());
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const onClosed = () => {
    setShowDial(false);
    setNoteCall(false);
  };

  const Showcloseddeal1 = () => {
    setdealreason(datas.Lost_Reason);
    setdealstage("Deals Lost Stage");
    setcloseddealdata(datas);
    setShowcloseddeal(true);
  };
  const ShowCompetitor = () => {
    setShowCompetitor(true);
  };

  const FunctionclosedDeal = () => {
    if (dealreason === "" || dealreason === null || dealreason === undefined) {
      toast.error("Enter Valid Reason", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      const payload = {
        id: datas?.id,
        Deal_Stage: dealstage,
        Lost_Reason: dealreason,
      };
      console.log("Patch payload : ", payload);

      try {
        axios
          .patch(`${BASE_URL}/user/DealCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("update res", res.data);
            toast.success("Sucessfully Updated", {
              position: "top-right",
              autoClose: 100,
            });
            getData();
            setdealreason("");
            setShowcloseddeal(false);
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      }
    }
  };

  const FunctionCompetitorUser = () => {
    if (comname === "" || comname === null || comname === undefined) {
      toast.error("Enter Competitor Name ", {
        position: "top-right",
        autoClose: 700,
      });
    } else if (comprice === "" || comprice === null || comprice === undefined) {
      toast.error("Enter Competitor Price Name ", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      if (
        datas?.Deal_Competitor == "" ||
        datas?.Deal_Competitor == null ||
        datas?.Deal_Competitor === undefined
      ) {
        console.log("in else", datas?.Deal_Competitor);

        let payload = {
          id: datas?.id,
          Deal_Competitor: [
            {
              Competitor_Name: comname,
              Price: comprice,
            },
          ],
        };
        console.log("if payload", payload);
        axios
          .patch(`${BASE_URL}/user/DealCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("update res", res.data);
            toast.success("Sucessfully Updated", {
              position: "top-right",
              autoClose: 100,
            });
            getData();
            setShowCompetitor(false);
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } else if (
        datas?.Deal_Competitor != "" ||
        datas?.Deal_Competitor != null ||
        datas?.Deal_Competitor != undefined
      ) {
        let newarr = { Competitor_Name: comname, Price: comprice };
        console.log("newarr", newarr);
        let newArr = [...cmparr];
        newArr = [...cmparr, newarr];
        setCmparr(newArr);
        if (newArr) {
          let payload1 = {
            id: datas?.id,
            Deal_Competitor: newArr,
          };
          console.log("in else comp", payload1);

          try {
            axios
              .patch(`${BASE_URL}/user/DealCRUD/`, payload1, {
                headers: {
                  db: item,
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
              .then((res) => {
                console.log("update res", res.data);
                toast.success("Sucessfully Updated", {
                  position: "top-right",
                  autoClose: 100,
                });
                getData();
                setShowCompetitor(false);
              })
              .catch((error) => {
                if (error.response && error.response.status === 403) {
                  const errorMessage =
                    error.response.data.details ||
                    "You don't have permission to do it";
                  console.log(errorMessage);
                  toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 100,
                  });
                } else {
                  toast.error(`${error}`, {
                    position: "top-right",
                    autoClose: 100,
                  });
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  };

  const FunctiondealPoints = () => {
    console.log("Deal Points", postpredict);
    let count = 0;
    rows.map((data, ind) => {
      if (
        data.value === "" ||
        data.value === undefined ||
        data.value === null
      ) {
        count++;
      }
    });
    if (count === 0) {
      if (postpredict === false) {
        let payload = {
          Client_Engagement: ClientEngagement,
          Budget_Allientment: BudgetAlignment,
          Desicion_Maker: DecisionMaker,
          Unique_Selling_Point: UniqueSelling,
          Market_Condition: MarketCondition,
          ROI_Prediction: ROIValue,
          Post_Sales_Support: PostSales,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
          Deal_Id: datas.id,
        };
        axios
          .post(`${BASE_URL}/user/DealScoreTableCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("Result", res.data);
            toast.success("Created Successfully");
            getData();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } else if (postpredict === true) {
        let payload = {
          id: dealpointsdata.id,
          Client_Engagement: ClientEngagement,
          Budget_Allientment: BudgetAlignment,
          Desicion_Maker: DecisionMaker,
          Unique_Selling_Point: UniqueSelling,
          Market_Condition: MarketCondition,
          ROI_Prediction: ROIValue,
          Post_Sales_Support: PostSales,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
          Deal_Id: datas.id,
        };
        axios
          .put(`${BASE_URL}/user/DealScoreTableCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("Result", res.data);
            toast.success("Created Successfully");
            getData();
          })
          .catch((res) => {
            console.log(res.message);
          });
      }
    } else {
      toast.error("Enter Valid Deal Pionts", {
        position: "top-right",
        autoClose: 700,
      });
      count = 0;
    }
  };
  const FunctiondealRating = async () => {
    console.log("Deal Rating", rowsrating);
    let count = 0;
    rowsrating.map((data, ind) => {
      if (
        data.Points === "" ||
        data.Points === undefined ||
        data.Points === null
      ) {
        count++;
      }
    });
    if (count === 0) {
      await rowsrating.map((data, ind) => {
        console.log("map", data);
        if (data.id == "" || data.id == null || data.id == undefined) {
          let payload = {
            Deal_Questions: data.Weight,
            Deal_Answer: data.value === true ? "Yes" : "No",
            Deal_Probablity: data.Points,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
            Deal_Id: datas.id,
            Organization_Id: localStorage.getItem("OrganizationId"),
          };
          axios
            .post(`${BASE_URL}/user/DealRatingCRUD/`, payload, {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
            .then((res) => {
              console.log("Result", res.data);
              toast.success("Created Successfully");
            })
            .catch((error) => {
              if (error.response && error.response.status === 403) {
                const errorMessage =
                  error.response.data.details ||
                  "You don't have permission to do it";
                console.log(errorMessage);
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 100,
                });
              } else {
                toast.error(`${error}`, {
                  position: "top-right",
                  autoClose: 100,
                });
              }
            });
        } else {
          let payload = {
            id: data.id,
            Deal_Questions: data.Weight,
            Deal_Answer: data.value === true ? "Yes" : "No",
            Deal_Probablity: data.Points,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
            Deal_Id: datas.id,
            Organization_Id: localStorage.getItem("OrganizationId"),
          };
          axios
            .put(`${BASE_URL}/user/DealRatingCRUD/`, payload, {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
            .then((res) => {
              console.log("Result", res.data);
            })
            .catch((error) => {
              if (error.response && error.response.status === 403) {
                const errorMessage =
                  error.response.data.details ||
                  "You don't have permission to do it";
                console.log(errorMessage);
                toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 100,
                });
              } else {
                toast.error(`${error}`, {
                  position: "top-right",
                  autoClose: 100,
                });
              }
            });
        }
      });

      getData();
    } else {
      toast.error("Enter Valid Deal Ratings", {
        position: "top-right",
        autoClose: 700,
      });
      count = 0;
    }
  };

  const FunctionPredictPost = async () => {
    let count = 0;
    let payload = {
      Deal_Id: datas.id,
    };
    await axios
      .post(`${BASE_URL}/user/DealProbabilityCalculation/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("Result", res.data);
        count++;
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });

    await axios
      .get(`${BASE_URL}/user/DealScoring/?Deal_Id=${datas?.id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("Result", res.data);
        count++;
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
    if (count == 2) {
      getData();
      setpredictmodal(true);
    } else {
      toast.error("Enter Deal Pionts", {
        position: "top-right",
        autoClose: 700,
      });
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme?.palette?.getContrastText(theme?.palette?.primary?.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme?.palette?.getContrastText(theme?.palette?.primary?.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const [knowlaritydatas, setKnowlaritydatas] = useState([]);
  const [agentdetails, setagentdetails] = useState([]);
  const [callogsdetails, setcallogsdetails] = useState([]);

  const callLogs = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityCallLogsCRUD/?start_Date=2020-04-16&end_Date=&UserId=${localStorage.getItem(
          "UserId"
        )}&Module=Deal`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("calllogsresss", res.data);
        // setKnowlaritydatas(res?.data);
        // setcallogsdetails(res.data)
        const mynumberList = res?.data?.filter((item) =>
          item.Agent_Number.includes(userDetails?.PhoneNo)
        );

        const lastIndexData = mynumberList[0];
        console.log("lastIndexData", mynumberList, lastIndexData);

        setDuration(lastIndexData?.Call_Duration);
        setCallRecording(lastIndexData?.Call_Recording);
        setMissedRang(lastIndexData?.Call_Duration);

        if (lastIndexData?.Agent_Number?.includes("User Disconnected")) {
          setStatus("User Disconnected");
        } else if (lastIndexData?.Agent_Number?.includes("Customer Missed")) {
          setStatus("Customer Missed");
        } else {
          setStatus("Connected");
        }

        setIsLoading(false);
        setAnchorEl(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const getKnowlarityConfiguration = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res?.data", res.data);
        setKnowlaritydatas(res?.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const getagentnumber = async () => {
    await axios
      .get(`https://kpi.knowlarity.com/Basic/v1/account/agent`, {
        headers: {
          // "Accept": "application/json",
          // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
          // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

          Accept: "application/json",
          "x-api-key": knowlaritydatas?.Header_Key,
          Authorization: knowlaritydatas?.API_Key,
        },
      })
      .then((res) => {
        console.log("agentres", res);
        const agentnum = res.data?.objects?.find(
          (item) => item?.phone === userDetails?.PhoneNo
        );
        // setagentdetails(agentnum)

        onGoingCallApi(agentnum);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  useEffect(() => {
    getKnowlarityConfiguration();
  }, []);

  //  OnGoing Call API

  const onGoingCallApi = async (agentnum) => {
    console.log("agentnum", agentnum);
    let knopayload = {
      // k_number: "+918037125874",
      // agent_number: "+919344312970",
      // customer_number: "+918870791912",
      // caller_id: "+918040210001",
      // caller_name: datas?.Name,
      k_number: knowlaritydatas?.Knowlarity_Number,
      agent_number: agentnum?.country_code + agentnum?.phone,
      customer_number: "+91" + datas?.Account_Id?.Phone_Number,
      caller_id: knowlaritydatas?.Knowlarity_CallerId,
      caller_name: datas?.Account_Name,
    };
    console.log("knopayload", knopayload);
    await axios
      .post(
        `https://kpi.knowlarity.com/Basic/v1/account/call/makecall`,
        knopayload,
        {
          headers: {
            // "Accept": "application/json",
            // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
            // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

            Accept: "application/json",
            "x-api-key": knowlaritydatas?.Header_Key,
            Authorization: knowlaritydatas?.API_Key,
          },
        }
      )
      .then((res) => {
        console.log("callres", res);
        setShowDial(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        callLogs();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  //  Right time to Call

  const [rightTimeCalls, setRightTimeCalls] = useState([]);
  const [rightTimeToCallData, setRightTimeToCallData] = useState({});
  const [openShcedule, setOpenshcedule] = useState(false);

  const ContactRightTimeAdditional = async () => {
    try {
      if (Boolean(datas?.id)) {
        await axios
          .get(
            `${BASE_URL}/user/ContactRightTimeAdditional/?Contact_Id=${Number(
              datas?.id
            )}&Is_Delete=False`,
            {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            // setDatas(res?.data?.Result);
            if (res?.data?.results?.length > 0) {
              // console.log("Bearer", res?.data?.results[0]);
              // rightTimeToCall(res?.data?.Result[res?.data?.Result?.length - 1]);
              setRightTimeCalls(res?.data?.results[0]);
              setOpenshcedule(false);
            }
          });
        //
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log("rightTimeCalls", rightTimeCalls);
  // right time to call function
  const rightTimeToCallSave = async () => {
    try {
      let response;
      if (!Boolean(rightTimeCalls?.id)) {
        const payload = {
          Contact_Id: datas?.id,
          Right_Time:
            rightTimeToCallData
              ?.filter((right) => right?.checked)
              ?.map((right) => right?.time) ?? [],
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        response = await axios.post(
          `${BASE_URL}/user/ContactRightTimeCRUD/`,
          payload,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      } else if (Boolean(rightTimeCalls?.id)) {
        const payload = {
          id: rightTimeCalls?.id,
          Contact_Id: datas?.id,
          Right_Time:
            rightTimeToCallData
              ?.filter((right) => right?.checked)
              ?.map((right) => right?.time) ?? [],
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        response = await axios.put(
          `${BASE_URL}/user/ContactRightTimeCRUD/`,
          payload,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      }
      if (response) {
        ContactRightTimeAdditional();
      }
    } catch (error) {
      console.error("Error in rightTimeToCall:", error);
    }
  };
  // console.log("agentdetails", agentdetails?.phone, agentdetails?.country_code);
  // right time to call function
  const rightTimeToCall = async (rightTimeTo) => {
    try {
      const payload = {
        Customer_number: rightTimeTo?.Contact_Number,
        Call_status: rightTimeTo?.Select_Status,
        Call_duration: rightTimeTo?.Call_Duration,
        business_type: rightTimeTo?.Business_Call_Type,
      };
      const response = await axios.post(
        `${BASE_URL1}/user/RighttimetocallContacts/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response?.data?.Right_time?.length > 0) {
        // Extract the Right_time array from the response and map it to the required format
        const rightTimeData = Object.values(response?.data?.Right_time[0])
          ?.map((val) => {
            if (
              rightTimeCalls?.id &&
              rightTimeCalls?.Right_Time.includes(val)
            ) {
              return {
                id: rightTimeCalls?.id,
                time: val,
                checked: true,
              };
            } else {
              return {
                time: val,
                checked: false,
              };
            }
          })
          .filter(Boolean);
        // Update the state with the mapped data
        console.log("rightTimeData2", rightTimeData);
        setRightTimeToCallData(rightTimeData);
      }
    } catch (error) {
      console.error("Error in rightTimeToCall:", error);
    }
  };
  const rightTimeToCallLists = async () => {
    let startDate = moment().startOf("year");
    let endDate = moment().endOf("year");
    let ThisYearStart = startDate?.format("YYYY-MM-DD");
    let ThisYearEnd = endDate?.format("YYYY-MM-DD");
    if (Boolean(datas?.Mobile_Number) && Boolean(datas?.id)) {
      await axios
        .get(
          `${BASE_URL}/user/KnowlarityAgentCalllogsandActivityList/?Phone_Number=${
            datas?.Mobile_Number
          }&Contact_Id=${Number(
            datas?.id
          )}&start_date=${ThisYearStart}&end_date=${ThisYearEnd}&Module=Contact`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("NotesFilter", res?.data?.results);
          // setDatas(res?.data?.Result);
          if (res?.data?.Result?.length > 0) {
            rightTimeToCall(res?.data?.Result[res?.data?.Result?.length - 1]);
          }
        })
        .catch((error) => {
          console.log("error");
        });
      //
    }
  };
  useEffect(() => {
    ContactRightTimeAdditional();
    rightTimeToCallLists();
  }, [datas]);

  console.log("Deals Overview datas:", datas);

  return (
    <JumboContentLayoutMain>
      {/* call section */}
      <Dialog
        open={showDial && !Boolean(anchorEl)}
        // onClose={onClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            backgroundColor: "#0070B9",
            overflow: "hidden",
            // borderTopRightRadius: "12px",
            // borderRadiusTopleft: "12px",
          }}
        >
          <Stack
            sx={{
              overflow: "hidden",
              width: "350px",
              height: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                <Div
                  sx={{
                    width: "100%",
                    height: "200px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Div
                    sx={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: "green",
                      borderRadius: "50%",
                      position: "relative",
                      zIndex: "2",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        fontSize: "30px",
                        borderColor: "common.white",
                        boxShadow: "inset 0px 0px 5px white",
                        color: "#3498db",
                        fontWeight: "900",
                      }}
                    >
                      {datas?.Account_Name &&
                        datas?.Account_Name?.substring(0, 2)}
                    </Avatar>
                  </Div>
                  <Div
                    sx={{
                      width: "115px",
                      height: "115px",
                      position: "absolute",
                      backgroundColor: "#F2F2F2",
                      opacity: "25%",
                      borderRadius: "50%",
                    }}
                  ></Div>
                  <Div
                    sx={{
                      width: "130px",
                      height: "130px",
                      position: "absolute",
                      backgroundColor: "#F2F2F2",
                      opacity: "20%",
                      borderRadius: "50%",
                    }}
                  ></Div>
                  <Div
                    sx={{
                      width: "145px",
                      height: "145px",
                      position: "absolute",
                      backgroundColor: "#F2F2F2",
                      opacity: "15%",
                      borderRadius: "50%",
                    }}
                  ></Div>
                </Div>
                <Div sx={{ textAlign: "center" }}>
                  <Typography
                    id="Typography-root"
                    variant="h3"
                    mb={1}
                    sx={{
                      fontSize: "28px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                    color={"common.black"}
                  >
                    {datas?.Account_Name}
                  </Typography>
                  <Typography
                    id="Typography-root"
                    sx={{ color: "#F2F2F2", fontSize: "16px" }}
                  >
                    {datas?.Account_Id?.Phone_Number}
                  </Typography>
                </Div>

                <Div
                  sx={{
                    mt: 2,
                    gap: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Div>
                    <IconButton
                      onClick={() => setVolume(false)}
                      sx={{
                        display: volume ? "" : "none",
                        borderRadius: "50%",
                        padding: "8px",
                        color: "black",
                        backgroundColor: "white",
                        ":hover": { backgroundColor: "white", opacity: 0.8 },
                      }}
                    >
                      <VolumeUp fontSize="16px" color="black" />
                    </IconButton>
                    <IconButton
                      onClick={() => setVolume(true)}
                      sx={{
                        display: !volume ? "" : "none",
                        borderRadius: "50%",
                        padding: "8px",
                        color: "black",
                        backgroundColor: "white",
                        opacity: 0.8,
                        ":hover": { backgroundColor: "white", opacity: 0.8 },
                      }}
                    >
                      <VolumeOff fontSize="16px" color="black" />
                    </IconButton>
                  </Div>
                  <IconButton
                    sx={{
                      borderRadius: "50%",
                      padding: "8px",
                      color: "white",
                      backgroundColor: "red",
                      ":hover": {
                        backgroundColor: "white",
                        color: "red",
                      },
                    }}
                    onClick={() => setIsLoading(true)}
                  >
                    <CallEnd fontSize="13px" color="red" />
                  </IconButton>
                  <Div>
                    <IconButton
                      onClick={() => setMic(false)}
                      sx={{
                        display: mic ? "" : "none",
                        borderRadius: "50%",
                        padding: "8px",
                        color: "black",
                        backgroundColor: "white",
                        ":hover": { backgroundColor: "white", opacity: 0.9 },
                      }}
                    >
                      <MicOff fontSize="16px" color="black" />
                    </IconButton>
                    <IconButton
                      onClick={() => setMic(true)}
                      sx={{
                        display: !mic ? "" : "none",
                        borderRadius: "50%",
                        padding: "8px",
                        color: "black",
                        backgroundColor: "white",
                        opacity: 0.8,
                        ":hover": { backgroundColor: "white", opacity: 0.8 },
                      }}
                    >
                      <Mic fontSize="16px" color="black" />
                    </IconButton>
                  </Div>
                </Div>
                <Div
                  sx={{
                    textAlign: "center",
                    borderRadius: "5px",
                    marginTop: "30px",
                    fontSize: "16px",
                    backgroundColor: "white",
                    padding: "10px",
                  }}
                >
                  Connecting . . . .
                </Div>
              </>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      {/* call submit form */}
      <Dragabledialog maxWidth="lg" open={Boolean(anchorEl)}>
        <DialogTitle
          sx={[
            DisplayFlex,
            dialogFontSize,
            { justifyContent: "space-between" },
          ]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Create Call Notes
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ mt: 2 }}>
            <Div className="row">
              <Div className="col-12 col-md-6 col-lg-6">
                <Grid container>
                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography
                      className="col-md-12 col-lg-6 col-xl-5 input-label"
                      sx={[input_label]}
                    >
                      Call Status
                    </Typography>
                    <Div className="col-md-12 col-lg-6 col-xl-6">
                      <Autocomplete
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.value) {
                            return option?.value;
                          }
                          return "";
                        }}
                        options={option}
                        value={status}
                        onChange={(e, newValue) => {
                          console.log("newValue", newValue);
                          setStatus(newValue);
                        }}
                        id="outlined-basic"
                        className="search-select3 w-full"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              borderRadius: "10px",
                            }}
                            placeholder="Call Status"
                          />
                        )}
                      />
                    </Div>
                  </Grid>
                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography
                      className="col-md-12 col-lg-6 col-xl-5 input-label"
                      sx={[input_label]}
                    >
                      Call Duration
                    </Typography>
                    <Div className="col-md-12 col-lg-6 col-xl-6">
                      <TextField
                        className="input-box2 w-full"
                        onChange={(e) => {
                          setDuration(e.target.value);
                        }}
                        placeholder="Enter Call Duration"
                        type="number"
                        value={duration}
                        InputProps={{
                          endAdornment: (
                            <Typography
                              sx={[FontStyle, { textAlign: "right" }]}
                            >
                              Seconds
                            </Typography>
                          ),
                        }}
                      />
                    </Div>
                  </Grid>
                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography
                      className="col-md-12 col-lg-6 col-xl-5 input-label"
                      sx={[input_label]}
                    >
                      Call Busy Hour
                    </Typography>
                    <Div className="col-md-12 col-lg-6 col-xl-6">
                      <Autocomplete
                        className="search-select3 w-full"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.value) {
                            return option?.value;
                          }
                          return "";
                        }}
                        value={busy}
                        options={BusyHroptions}
                        onChange={(e, newValue) => {
                          console.log("newValue", newValue);
                          setBusy(newValue);
                        }}
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              borderRadius: "10px",
                            }}
                            placeholder="Call Busy Hour"
                          />
                        )}
                      />
                    </Div>
                  </Grid>
                </Grid>
              </Div>
              <Div className="col-12 col-md-6 col-lg-6">
                <Grid container>
                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography
                      className="col-md-12 col-lg-6 col-xl-5 input-label"
                      sx={[input_label]}
                    >
                      Call Coversation Status
                    </Typography>
                    <Div className="col-md-12 col-lg-6 col-xl-6">
                      <Autocomplete
                        className="search-select3 w-full"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.value) {
                            return option?.value;
                          }
                          return "";
                        }}
                        value={callconvstatus}
                        options={callconvoptions}
                        onChange={(e, newValue) => {
                          console.log("newValue", newValue);
                          setCallconvstatus(newValue);
                        }}
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              borderRadius: "10px",
                            }}
                            placeholder="Call Status"
                          />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography
                      className="col-md-12 col-lg-6 col-xl-5 input-label"
                      sx={[input_label]}
                    >
                      Call Context
                    </Typography>
                    <Div className="col-md-12 col-lg-6 col-xl-6">
                      <Autocomplete
                        className="search-select3 w-full"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.value) {
                            return option?.value;
                          }
                          return "";
                        }}
                        value={callcontext}
                        options={contaxtoptions}
                        onChange={(e, newValue) => {
                          console.log("newValue", newValue);
                          setCallContext(newValue);
                        }}
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              borderRadius: "10px",
                            }}
                            placeholder="Call Context"
                          />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography
                      className="col-md-12 col-lg-6 col-xl-5 input-label"
                      sx={[input_label]}
                    >
                      Next Scheduled Date
                    </Typography>
                    <Div className="col-md-12 col-lg-6 col-xl-6">
                      <TextField
                        type="datetime-local"
                        value={nextdate}
                        onChange={(e) => {
                          setNextDate(e.target.value);
                        }}
                        id="outlined-basic"
                        variant="outlined"
                        className="input-box2 w-full"
                      />
                      <FormGroup sx={{ display: nextdate ? "block" : "none" }}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={show} onChange={setreminder} />
                          }
                          label={
                            <Typography sx={[input_label1]}>
                              Set Reminder
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Div>
                  </Grid>
                </Grid>
              </Div>

              <Div className="col-12 col-md-12">
                <Grid container>
                  <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                    <Typography className="col-md-2.5" sx={[input_label]}>
                      Notes
                    </Typography>
                    <Div className="col-md-12">
                      <CustomCKEditor
                        initData={notes}
                        // className={`${errors["notes"] && "border-1 border-danger"}`}
                        onChange={handleEditorChange}
                      />
                    </Div>
                  </Grid>
                </Grid>
              </Div>
            </Div>
          </DialogContent>
          <DialogActions sx={[DisplayFlex, { justifyContent: "center" }]}>
            <CustomButton
              type="submit"
              label="Save"
              // onClick={handleSaveImage}
              Icon="saveIcon"
            />
          </DialogActions>

          <Dragabledialog
            open={isReminderSet}
            onClose={() => handleDialogClose(false)}
            maxWidth={"md"}
            title="Reminder"
          >
            <DialogContent>
              <Box mt={2} sx={{ height: "200px !important" }}>
                <Customgrid columnCount={4}>
                  <Grid item colSpan={4}>
                    <Typography sx={[input_label]}>
                      First Reminder Before Due
                    </Typography>
                  </Grid>

                  <Grid item sx={{ display: "flex" }} colSpan={8} gap={2}>
                    <Div>
                      <TextField
                        className="w-full input-box2"
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Time"
                        value={firstRemainder}
                        onChange={(e) => {
                          setfirstRemainder(e.target.value);
                        }}
                      />
                    </Div>
                    <Div>
                      <Autocomplete
                        className="w-full search-select3"
                        sx={{ width: "100px !important" }}
                        options={[
                          { name: "Days" },
                          { name: "Hrs" },
                          { name: "Mins" },
                        ]}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        value={firstREmainderHrs}
                        onChange={(e, newValue) => {
                          setfirstREmainderHrs(newValue?.name || "");
                          // functiondate(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select" />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item colSpan={4}>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        label={
                          <Typography sx={[input_label]}>
                            Repeat Every
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item sx={{ display: "flex" }} colSpan={8} gap={2}>
                    <Div>
                      <TextField
                        className="w-full input-box2"
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Time"
                        value={repeatEvery}
                        onChange={(e) => {
                          setrepeatEvery(e.target.value);
                        }}
                      />
                    </Div>
                    <Div>
                      <Autocomplete
                        className="w-full search-select3"
                        sx={{ width: "100px !important" }}
                        options={[{ name: "Hrs" }, { name: "Mins" }]}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        value={Repeateveryhrs}
                        onChange={(e, newValue) => {
                          setRepeateveryhrs(newValue.name);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select" />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item colSpan={4}>
                    <Typography
                      //   id="Typography-root"
                      sx={[input_label]}
                    >
                      Reminder Message
                    </Typography>
                  </Grid>

                  <Grid item colSpan={8}>
                    <Div>
                      <CustomCKEditor
                        initData={editorContent}
                        onChange={onChange}
                      />
                    </Div>
                  </Grid>
                </Customgrid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <CustomButton
                  label="Save"
                  Icon="saveIcon"
                  onClick={() => handleDialogClose(true)}
                />
                <Cancelbutton onClick={() => handleDialogClose(false)} />
              </Div>
              {/* <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button onClick={() => handleDialogClose(true)} color="primary">OK</Button> */}
            </DialogActions>
          </Dragabledialog>
        </form>
      </Dragabledialog>

      {/* mail modal */}
      <Dragabledialog open={showMailDialog} maxWidth="lg">
        {/* <DialogTitle
          id="alert-dialog-title"
          sx={[
            { mb: 0.7 },
            customHeight,
            DisplayFlex,
            {
              bgcolor: "#dcdcdc !important",
              justifyContent: "space-between",
            },
          ]}
        >
          <Typography id="Typography-root" sx={[dialogTitle]}>
            Compose Mail
          </Typography>
        </DialogTitle>

        <Div>
          <DialogContent>
            <DialogContentText>
              <Div sx={{ width: "100%" }}>
                <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                  From{" "}
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  size={"small"}
                  type={"email"}
                  value={UserDetails?.email || "No Email"}
                  className="input-box2"
                  placeholder={"From"}
                  disabled
                />
              </Div>

              <Div sx={{ width: "100%", my: 1 }}>
                <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                  To{" "}
                </Typography>
                <ButtonGroup
                  sx={{
                    borderRadius: "0px !important",
                    width: "100%",
                    position: "relative",
                  }}
                  aria-label="Basic button group"
                >
                  <TextField
                    sx={{ width: "100%" }}
                    size={"small"}
                    type={"email"}
                    className="input-box2 col-12"
                    placeholder={"To"}
                    onChange={(e) => setTo(e.target.value)}
                  />
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        position: "absolute",
                        top: "50%",
                        left: "95%",
                        transform: "translate(-50%,-20%)",
                      },
                    ]}
                  >
                    {!Boolean(showCc) && (
                      <Typography
                        id="Typography-root"
                        sx={[
                          FontStyle,
                          {
                            cursor: "pointer",
                            ":hover": { color: Colors?.Mblue },
                            alignSelf: "center",
                            height: "33px !important",
                            border: "none !important",
                          },
                        ]}
                        onClick={() => setShowCc(true)}
                      >
                        Cc
                      </Typography>
                    )}
                  </Div>
                </ButtonGroup>
              </Div>

              {Boolean(showCc) && (
                <Div sx={{ my: 1, width: "100%" }}>
                  <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                    Cc{" "}
                  </Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    size={"small"}
                    type={"email"}
                    className="input-box2"
                    placeholder={"CC"}
                    value={cc}
                    onChange={(e) => setCc(e.target.value)}
                  />
                </Div>
              )}

              <Div sx={{ my: 1, width: "100%" }}>
                <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                  Subject{" "}
                </Typography>
                <TextField
                  sx={{ width: "100%" }}
                  size={"small"}
                  className="input-box2"
                  placeholder={"Subject"}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Div>

              <Div sx={{ my: 1, width: "100%" }}>
                <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                  <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                    Content{" "}
                  </Typography>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="attachedFiles"
                    multiple
                    onChange={handleFileInputChange}
                  />
                  <label htmlFor="attachedFiles" style={{ cursor: "pointer" }}>
                    <IoMdAttach style={{ fontSize: 20 }} />
                  </label>
                </Div>

                <Div
                  sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        flexWrap: "wrap",
                        gap: "10px !important",
                        p: Boolean(fileNames?.length > 0) ? 1 : 0,
                      },
                    ]}
                  >
                    {fileNames?.map((file, index) => {
                      console.log("image", file, base64DataList[index]);
                      return (
                        <div
                          id="attachedFile"
                          key={index}
                          style={{ position: "relative" }}
                        >
                          <Div sx={{ width: 70, height: 90 }}>
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`File ${index + 1}`}
                              width={70}
                              height={70}
                            />
                            <p>
                              {file?.name?.length > 10
                                ? file?.name?.substring(0, 10) + "..."
                                : file?.name}
                            </p>
                          </Div>
                          <Div
                            id="attachedRemove"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-65%)",
                              width: 70,
                              height: 70,
                              backgroundColor: alpha("#000", 0.2),
                            }}
                          >
                            <Div
                              sx={[DisplayFlex, { justifyContent: "center" }]}
                            >
                              <Typography
                                id="Typography-root"
                                onClick={() => fileRemoved(index)}
                                sx={[
                                  {
                                    fontSize: "11px",
                                    color: "tomato !important",
                                  },
                                ]}
                              >
                                <IoMdClose
                                  style={{ fontSize: 20, color: "tomato" }}
                                />{" "}
                                Remove
                              </Typography>
                            </Div>
                          </Div>
                        </div>
                      );
                    })}
                  </Div>
                  <TextField
                    multiline
                    sx={{
                      width: "100%",
                      borderWidth: "0px !important",
                      border: "none !important",
                    }}
                    rows={5}
                    maxRows={10}
                    placeholder={"Content"}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Div>
              </Div>
            </DialogContentText>
          </DialogContent>
          <Div
            sx={[
              DisplayFlex,
              {
                justifyContent: "center",
                my: 3,
              },
            ]}
          >
            <CustomButton
              label="Send"
              type="submit"
              onClick={() => uploadFiles()}
              Icon="sendIcon"
            />
            <Cancelbutton onClick={() => setShowMail(true)} label="Cancel" />
          </Div>

        
        </Div> */}

        <ComposeEmail
          endpoint={"DealMailMasterCRUD"}
          data={datas}
          setOpenSendMail={setShowMailDialog}
          setOpen={setShowMail}
          extraPayload={{
            Deal_Id: datas?.id,
            MailId: "",
            RecevierMailId: datas?.Account_Id?.Mail,
          }}
        />
      </Dragabledialog>

      {/* Close dialog box */}
      <DialogBox
        onClickNo={() => setShowMail(false)}
        onClickYes={() => {
          setShowMail(false);
          setShowMailDialog(false);
          setFileNames([]);
        }}
        open={showMail}
      />

      {/* Closed Deal modal */}
      <Dragabledialog
        open={Showcloseddeal}
        onClose={() => setShowcloseddeal(false)}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
        title="Closed/Lost Deal"
      >
        <DialogContent>
          <DialogContentText>
            <Div sx={{ width: "100%" }}>
              <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                Deal Stage{" "}
              </Typography>
              <TextField
                className="input-box2 col-12"
                size={"small"}
                type={"email"}
                // {...register("to")}
                placeholder={"To"}
                value={dealstage}
                // onChange={(e) => setdealstage(e.target.value)}
              />
              {/* <Div style={{ color: "red" }}>{errors.to?.message}</Div> */}
            </Div>
            <Div sx={{ my: 1, width: "100%" }}>
              <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                Reason{" "}
              </Typography>
              <TextField
                multiline
                sx={{ width: "100%" }}
                rows={5}
                maxRows={10}
                placeholder={"Content"}
                // {...register("message")}
                value={dealreason}
                onChange={(e) => setdealreason(e.target.value)}
              />
              {/* <Div style={{ color: "red" }}>{errors.message?.message}</Div> */}
            </Div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Div sx={[DisplayFlex, { mt: 2 }]}>
            <CustomButton
              label="Save"
              Icon="saveIcon"
              onClick={(e) => {
                FunctionclosedDeal();
              }}
              type="submit"
            />
            <Cancelbutton
              label="Cancel"
              onClick={() => {
                setdealreason("");
                setShowcloseddeal(false);
              }}
            />
          </Div>
        </DialogActions>
      </Dragabledialog>

      {/* Competitor Price Setup */}
      <Dragabledialog
        open={showCompetitor}
        onClose={() => setShowCompetitor(false)}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
        title="Competitor Price Setup"
      >
        <DialogContent>
          <DialogContentText>
            <Div sx={{ width: "100%" }}>
              <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                Competitor Name{" "}
              </Typography>
              <TextField
                className="input-box2 col-12"
                size={"small"}
                type={"email"}
                value={comname}
                onChange={(e) => setCompname(e.target.value)}
              />
              {/* <Div style={{ color: "red" }}>{errors.to?.message}</Div> */}
            </Div>

            <Div sx={{ my: 1, width: "100%" }}>
              <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                Price{" "}
              </Typography>
              <TextField
                multiline
                sx={{ width: "100%" }}
                placeholder={"Enter the Price"}
                value={comprice}
                onChange={(e) => setComprice(e.target.value)}
              />
              {/* <Div style={{ color: "red" }}>{errors.message?.message}</Div> */}
            </Div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Div className="justify-center" sx={[DisplayFlex, { mt: 2 }]}>
            <CustomButton
              label="Save"
              Icon="saveIcon"
              onClick={(e) => {
                FunctionCompetitorUser();
              }}
              type="submit"
            />
            <Cancelbutton
              label="Cancel"
              onClick={() => {
                setCompname("");
                setComprice("");
                setShowCompetitor(false);
              }}
            />
          </Div>
        </DialogActions>
      </Dragabledialog>

      {/* Predict Deal modal */}
      <Dragabledialog
        open={predictmodal}
        onClose={() => setpredictmodal(false)}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
        title="Deal Prediction"
      >
        <DialogContent>
          <DialogContentText>
            <Div sx={{ width: "100%" }}>
              <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                Prediction Days{" "}
              </Typography>
              <TextField
                className="input-box2 col-12"
                size={"small"}
                type={"email"}
                // {...register("to")}
                placeholder={"To"}
                value={datas?.Prediction}
                // onChange={(e) => setdealstage(e.target.value)}
              />
              {/* <Div style={{ color: "red" }}>{errors.to?.message}</Div> */}
            </Div>

            <Div sx={{ my: 1, width: "100%" }}>
              <Typography id="Typography-root" mb={0.3} sx={FontStyle}>
                Recomanded Action{" "}
              </Typography>
              <TextField
                multiline
                sx={{ width: "100%" }}
                rows={5}
                maxRows={10}
                placeholder={"Content"}
                // {...register("message")}
                value={datas?.Recommaned_Action}
                // onChange={(e) => setdealreason(e.target.value)}
              />
              {/* <Div style={{ color: "red" }}>{errors.message?.message}</Div> */}
            </Div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Div sx={[DisplayFlex, { mt: 2 }]}>
            <CustomButton
              label="Save"
              Icon="saveIcon"
              onClick={(e) => {}}
              type="submit"
            />
            <Cancelbutton
              label="Cancel"
              onClick={() => {
                setdealreason("");
                setpredictmodal(false);
              }}
            />
          </Div>
        </DialogActions>
      </Dragabledialog>

      <Div
        sx={{
          display: "flex",
          //  mt:-3.5,
          //  ml:-4.5
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <Card
              sx={{
                mb: 1,
                boxShadow: "none",
                border: "1px solid #EEEEEE",
                // minHeight: "760px",
                height: "100%",
                borderRadius: "5px",
              }}
            >
              <Div
                sx={{
                  position: "relative",
                  background: `linear-gradient(0deg,  rgb(245 245 245 / 79%) 0%, #00CDAC 100%)`,
                  // color: random && random[1],
                  height: "230px",
                  width: "100%",
                  py: 2,
                  px: 2,
                }}
              >
                <Div sx={[DisplayFlex, {}]}>
                  <Div sx={{ width: "100%" }}>
                    <Div>
                      <IconButton
                        sx={{
                          cursor: "pointer",
                          transform: "translate(-20px , -10px)",
                          backgroundColor: "#f7f7f733",
                        }}
                        onClick={() => navigate(`/deals`)}
                      >
                        <IoIosArrowBack color="white" size={18} />
                      </IconButton>
                    </Div>

                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          justifyContent: "space-between !important",
                          width: "100%",
                          transform: "translateY(-17px)",
                        },
                      ]}
                    >
                      <Div>
                        <AvatarCircle
                          // title={datas?.Name}
                          image={
                            Boolean(datas?.Account_Id?.File) &&
                            BASE_URL + datas?.Account_Id?.File
                          }
                          bgColor={Colors?.Mblue}
                          size="120px"
                          fontsize="32px"
                        />
                        <Div
                          my={2}
                          sx={[
                            DisplayFlex,
                            {
                              ml: -1.5,
                              gap: "3px",
                              width: "100% !important",
                              justifyContent: "center",
                            },
                          ]}
                        >
                          <IconButton
                            onClick={() => {
                              // onGoingCallApi();
                              getagentnumber();
                            }}
                            sx={{}}
                          >
                            <PhoneIcon size={20} color="#5c5c5c" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setShowMailDialog(true);
                            }}
                            sx={{}}
                          >
                            <MailIcon size={20} color="#5c5c5c" />
                          </IconButton>
                          <IconButton
                            sx={{
                              p: 1,
                            }}
                            onClick={() => setOpenUploadEdit(true)}
                          >
                            <MdOutlineModeEdit size={20} color="#5c5c5c" />
                          </IconButton>
                          <IconButton
                            sx={{
                              p: 1,
                            }}
                            onClick={(e) => {
                              setOpenshcedule(e.currentTarget);
                              rightTimeToCallLists();
                            }}
                          >
                            <MdOutlineScheduleSend size={20} color="#5C5C5C" />
                          </IconButton>
                          <Popover
                            id={id}
                            open={Boolean(openShcedule)}
                            anchorEl={openShcedule}
                            // onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <DialogTitle
                              id="alert-dialog-title"
                              sx={[dialogTitle, DisplayFlex]}
                            >
                              Rigth Time To Call
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {rightTimeToCallData?.length > 0 &&
                                  rightTimeToCallData?.map((right, idx) => {
                                    return (
                                      <Div key={idx}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={() =>
                                                setRightTimeToCallData(
                                                  rightTimeToCallData?.map(
                                                    (item, idxx) =>
                                                      idxx === idx
                                                        ? {
                                                            ...item,
                                                            checked:
                                                              !item?.checked,
                                                          }
                                                        : item
                                                  )
                                                )
                                              }
                                              checked={right?.checked}
                                              size="small"
                                              color="info"
                                            />
                                          }
                                          label={
                                            <Typography sx={[FontStyle]}>
                                              {right?.time}
                                            </Typography>
                                          }
                                        />
                                      </Div>
                                    );
                                  })}
                              </DialogContentText>
                              <Div
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  mt: 2,
                                }}
                              >
                                <Cancelbutton
                                  onClick={() => {
                                    setOpenshcedule(false);
                                  }}
                                  label="Cancel"
                                />
                                <CustomButton
                                  label={
                                    Boolean(rightTimeCalls?.id)
                                      ? "Update"
                                      : "Save"
                                  }
                                  onClick={rightTimeToCallSave}
                                  Icon="saveIcon"
                                />
                              </Div>
                            </DialogContent>
                          </Popover>

                          <Dialog
                            open={openUploadEdit}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <Formik>
                              <Form>
                                <DialogTitle
                                  id="alert-dialog-title"
                                  sx={[dialogTitle, DisplayFlex]}
                                >
                                  Upload Picture
                                </DialogTitle>

                                <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    <Div
                                      sx={[
                                        DisplayFlex,
                                        { justifyContent: "center" },
                                      ]}
                                    >
                                      <label
                                        title="Click upload Image"
                                        for={"profile_pic"}
                                      >
                                        <Avatar
                                          alt="User"
                                          src={
                                            base64
                                              ? `data:image/png;base64,${base64}`
                                              : image
                                          }
                                          sx={{
                                            width: 100,
                                            height: 100,
                                            verticalAlign: "middle",
                                            boxShadow: (theme) =>
                                              theme.shadows[3],
                                            cursor: "pointer",
                                            ":hover": {
                                              backgroundColor: "grey",
                                            },
                                          }}
                                        />
                                      </label>
                                    </Div>

                                    <Typography
                                      id="Typography-root"
                                      sx={{
                                        fontSize: "13px",
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                        mt: 1,
                                      }}
                                    >
                                      {datas?.Name}
                                    </Typography>
                                    <input
                                      type="file"
                                      onChange={handleChangeImage}
                                      style={{ display: "none" }}
                                      id="profile_pic"
                                    />
                                  </DialogContentText>

                                  <DialogActions
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Cancelbutton
                                      onClick={() => setOpenUploadEdit(false)}
                                      label="Cancel"
                                    />
                                    <CustomButton
                                      label="Save"
                                      onClick={handleSaveImage}
                                      Icon="saveIcon"
                                    />
                                  </DialogActions>
                                </DialogContent>
                              </Form>
                            </Formik>
                          </Dialog>
                        </Div>
                      </Div>

                      <Div>
                        <Typography
                          id="Typography-root"
                          sx={{
                            fontSize: "29px",
                            color: "white !important",
                            fontWeight: 700,
                          }}
                        >
                          {datas?.Account_Name || "No Name"}
                        </Typography>

                        <Typography
                          id="Typography-root"
                          sx={[
                            {
                              fontSize: "14px",
                              color: "#5c5c5c !important",
                              my: 1,
                            },
                          ]}
                        >
                          {datas?.Account_Id?.Phone_Number || "No Mobile No"}
                        </Typography>

                        <Typography
                          id="Typography-root"
                          sx={[
                            {
                              fontSize: "14px",
                              color: "#5c5c5c !important",
                              my: 1,
                            },
                          ]}
                        >
                          {datas?.Account_Id?.Mail || "No Email"}
                        </Typography>

                        {datas?.Account_Id?.Organization_Name && (
                          <Typography
                            id="Typography-root"
                            sx={[
                              {
                                fontSize: "14px",
                                color: "#5c5c5c !important",
                                my: 1,
                              },
                            ]}
                          >
                            {datas?.Account_Id?.Organization_Name ||
                              "No Company"}
                          </Typography>
                        )}
                        <Typography
                          id="Typography-root"
                          sx={[
                            {
                              fontSize: "14px",
                              color: "#5c5c5c !important",
                              my: 1,
                            },
                          ]}
                        >
                          {datas?.Account_Id?.City}
                          {Boolean(datas?.Account_Id?.City) && ","}{" "}
                          {datas?.Account_Id?.Country || "No Address"}
                        </Typography>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>

              <CardContent sx={{ px: 2 }}>
                {/*  Deal Overview Page For the Proability  */}
                <Box sx={{ mb: 2 }}>
                  <AntTabs
                    value={tabValue}
                    onChange={(e, newValue) => {
                      setTabValue(newValue);

                      // console.log("newValue : ", newValue);
                    }}
                    aria-label="ant example"
                  >
                    <AntTab label="Statistics" />
                    <AntTab label="Deal Prediction" />
                    <AntTab label="Others Activities" />
                  </AntTabs>
                </Box>

                {tabValue === 0 && (
                  <Box>
                    <Div
                      mb={3}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        mb: 1,
                        p: 2,
                      }}
                    >
                      <Box sx={{ borderBottom: "1px solid lightgray", pb: 1 }}>
                        <Div
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                            alignItems: "center",
                          }}
                        >
                          <Div
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            <Div sx={[dialogTitle]}>
                              <AvatarCircle title={datas?.Account_Name} />
                            </Div>
                            <Div>
                              <Div sx={[FontStyle]}>{datas?.Account_Name}</Div>

                              <Div sx={[FontStyle]}>
                                {datas?.Account_Id &&
                                  datas?.Account_Id?.Organization_Name}
                              </Div>
                            </Div>
                          </Div>
                          <Div sx={[dialogTitle]}>
                            Proability : {datas?.Deal_Probablity}
                          </Div>
                        </Div>
                        <Div
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <Div sx={[FontStyle]}>Deal No : {datas?.Deal_Id}</Div>
                          <Div sx={[FontStyle]}>
                            Date : {datas?.Created_Date && datas?.Created_Date}
                          </Div>
                        </Div>
                      </Box>
                      <Div
                        sx={{
                          mt: 1,
                        }}
                      >
                        <DealOverView id={id} />
                      </Div>
                    </Div>
                  </Box>
                )}

                {tabValue === 1 && (
                  <Box sx={{ mb: 1, py: 2 }}>
                    <Div
                      sx={{
                        // display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        mb: 2,
                        // mt: -5,
                      }}
                    >
                      <Div
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          id="Typography-root"
                          sx={[
                            dialogTitle,
                            {
                              // borderBottom: "1px solid lightgray",
                              marginTop: "10px",
                              paddingLeft: "10px ",
                            },
                          ]}
                        >
                          Deal Scores
                        </Typography>
                      </Div>
                      <Grid
                        conatiner
                        spacing={2}
                        sx={{
                          display: "flex",

                          marginTop: 1,
                        }}
                      >
                        <Grid item md={5}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            Deal Prediction
                          </Typography>
                        </Grid>
                        <Grid item md={1}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            :
                          </Typography>
                        </Grid>

                        <Grid item md={6}>
                          <Div
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              id="Typography-root"
                              sx={[
                                FontStyle,
                                {
                                  marginTop: 1,
                                },
                              ]}
                            >
                              {datas.Prediction || "----"}
                            </Typography>

                            <CustomButton
                              label="Predict"
                              Icon=""
                              onClick={() => {
                                FunctionPredictPost();
                              }}
                            />
                          </Div>
                        </Grid>
                      </Grid>

                      <Grid
                        conatiner
                        spacing={2}
                        sx={{
                          display: "flex",

                          marginTop: 1,
                        }}
                      >
                        <Grid item md={5}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            Deal Stage
                          </Typography>
                        </Grid>
                        <Grid item md={1}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            :
                          </Typography>
                        </Grid>

                        <Grid item md={6}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            {datas.Deal_Stage || "----"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        conatiner
                        spacing={2}
                        sx={{
                          display: "flex",

                          marginTop: 1,
                        }}
                      >
                        <Grid item md={5}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            Deal Probability
                          </Typography>
                        </Grid>
                        <Grid item md={1}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            :
                          </Typography>
                        </Grid>

                        <Grid item md={6}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 1,
                              },
                            ]}
                          >
                            {datas.Deal_Probablity || "----"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Div sx={{ height: "10px" }}></Div>
                    </Div>

                    <Box sx={{ my: 2 }}>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Div
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              id="Typography-root"
                              sx={[
                                dialogTitle,
                                {
                                  // borderBottom: "1px solid lightgray",
                                  marginTop: "10px",
                                  paddingLeft: "10px ",
                                },
                              ]}
                            >
                              Deal Points
                            </Typography>
                            <IconButton
                              className="edit-icon"
                              sx={[{ marginRight: 1 }]}
                              onClick={(e) => {
                                FunctiondealPoints();
                              }}
                            >
                              <MdOutlineSaveAs
                                style={{ color: Colors?.Mblue }}
                              />
                            </IconButton>
                            {/* <Button
                          
                          sx={[DialogBoxSaveButton, FontStyle, ButtonStyle,{marginRight:1,marginTop:1}]}
                        >Save</Button> */}
                          </Div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Div
                            sx={{
                              // display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              mb: 2,
                              // mt: -5,
                            }}
                          >
                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Client Engagement
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={ClientEngagement}
                                  options={ClientEngagementlist || []}
                                  onChange={(e, newValue) =>
                                    setClientEngagement(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Budget Alignment
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={BudgetAlignment}
                                  options={BudgetAlignmentlist || []}
                                  onChange={(e, newValue) =>
                                    setBudgetAlignment(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Decision Maker
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={DecisionMaker}
                                  options={DecisionMakerlist || []}
                                  onChange={(e, newValue) =>
                                    setDecisionMaker(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Unique Selling Point
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={UniqueSelling}
                                  options={UniqueSellinglist || []}
                                  onChange={(e, newValue) =>
                                    setUniqueSelling(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Market Condition
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={MarketCondition}
                                  options={MarketConditionlist || []}
                                  onChange={(e, newValue) =>
                                    setMarketCondition(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  ROI & Value proposition
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={ROIValue}
                                  options={ROIValuelist || []}
                                  onChange={(e, newValue) =>
                                    setROIValue(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={5}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Post Sales Support
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>

                              <Grid item md={6}>
                                <Autocomplete
                                  disablePortal
                                  className="search-select"
                                  value={PostSales}
                                  options={PostSaleslist || []}
                                  onChange={(e, newValue) =>
                                    setPostSales(newValue?.value)
                                  }
                                  sx={[FontStyle, { minWidth: "200px" }]}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (Boolean(option) && option?.value) {
                                      return option?.value;
                                    }
                                    return "";
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={[FontStyle]}
                                      placeholder={"Choose a value..."}
                                      {...params}
                                      // label="Movie"
                                      // InputProps={{
                                      //   ...params.InputProps,
                                      //   endAdornment: clearButton == false ? null:true, // Remove the caret
                                      //   style: { border: border == false && "none" }
                                      // }}
                                      // clearButton={clearButton} // Remove the clear button
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Div sx={{ height: "10px" }}></Div>
                          </Div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>

                    <Box sx={{ my: 2 }}>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                        >
                          <Div
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              id="Typography-root"
                              sx={[
                                dialogTitle,
                                {
                                  // borderBottom: "1px solid lightgray",
                                  marginTop: "10px",
                                  paddingLeft: "10px ",
                                },
                              ]}
                            >
                              Deal Rating
                            </Typography>
                            <IconButton
                              className="edit-icon"
                              sx={[{ marginRight: 1 }]}
                              onClick={(e) => {
                                setpostrating(rowsrating);
                                FunctiondealRating();
                              }}
                            >
                              <MdOutlineSaveAs
                                style={{ color: Colors?.Mblue }}
                              />
                            </IconButton>
                            {/* <Button
                          
                          sx={[DialogBoxSaveButton, FontStyle, ButtonStyle,{marginRight:1,marginTop:1}]}
                        >Save</Button> */}
                          </Div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Div
                            sx={{
                              // display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              mb: 2,
                              // mt: -5,
                            }}
                          >
                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                                marginTop: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is our company is first time to client
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={firsttime}
                                      onChange={(e) => {
                                        setfirsttime(!firsttime);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is this deal was initiated by client
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={initiated}
                                      onChange={(e) => {
                                        setinitiated(!initiated);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is client is responding to our proposal
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={responding}
                                      onChange={(e) => {
                                        setresponding(!responding);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is the client understood our product
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={understood}
                                      onChange={(e) => {
                                        setunderstood(!understood);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is there any other bidders
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={bidders}
                                      onChange={(e) => {
                                        setbidders(!bidders);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is client looking for economy solution
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={economy}
                                      onChange={(e) => {
                                        seteconomy(!economy);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is client looking for support after sales
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={support}
                                      onChange={(e) => {
                                        setsupport(!support);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is client satisfied our product demo
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={satisfied}
                                      onChange={(e) => {
                                        setsatisfied(!satisfied);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is our product price is with in the budget
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={budget}
                                      onChange={(e) => {
                                        setbudget(!budget);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is our product adding values to client
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={addingvalues}
                                      onChange={(e) => {
                                        setaddingvalues(!addingvalues);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is budget approved
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={approved}
                                      onChange={(e) => {
                                        setapproved(!approved);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is decision maker
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={decision}
                                      onChange={(e) => {
                                        setdecision(!decision);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Are we ready to do the customization
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={customization}
                                      onChange={(e) => {
                                        setcustomization(!customization);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              conatiner
                              spacing={2}
                              sx={{
                                display: "flex",
                                paddingLeft: 1,
                                paddingRight: 1,
                              }}
                            >
                              <Grid item md={8}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  Is client looking customization free
                                </Typography>
                              </Grid>
                              <Grid item md={1}>
                                <Typography
                                  id="Typography-root"
                                  sx={[FontStyle, { marginTop: 1 }]}
                                >
                                  :
                                </Typography>
                              </Grid>
                              <Grid item md={3}>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      color="info"
                                      defaultChecked={customisationfree}
                                      onChange={(e) => {
                                        setcustomisationfree(
                                          !customisationfree
                                        );
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            </Grid>

                            <Div sx={{ height: "10px" }}></Div>
                          </Div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                )}

                {tabValue === 2 && (
                  <Box>
                    <Div
                      mb={3}
                      sx={{
                        // display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        mt: 2,
                        mb: 1,
                        // mt: -5,
                      }}
                    >
                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          id="Typography-root"
                          sx={[
                            dialogTitle,
                            {
                              paddingLeft: "10px ",
                            },
                          ]}
                        >
                          Deal Competitor
                        </Typography>

                        <CustomButton
                          label=" Mention Competitor"
                          width="170px"
                          Icon=""
                          onClick={() => {
                            ShowCompetitor();
                          }}
                        />
                      </Div>
                      <Div
                        sx={[
                          dialogTitle,
                          {
                            borderBottom: "1px solid lightgray",
                            // margin: "0px 20px",
                            marginTop: "5px",
                          },
                        ]}
                      ></Div>
                      <Grid conatiner>
                        <Table className="table" sx={{ minHeight: 100 }}>
                          <TableHead className="table-head">
                            <CRMTableRow>
                              <CRMTableCell>Competitor Name</CRMTableCell>
                              <CRMTableCell>Price</CRMTableCell>
                            </CRMTableRow>
                          </TableHead>

                          <TableBody>
                            {datas?.Deal_Competitor &&
                              datas?.Deal_Competitor?.map((data, index) => {
                                return (
                                  <>
                                    <CRMTableRow>
                                      <CRMTableCell>
                                        {data?.Competitor_Name}
                                      </CRMTableCell>
                                      <CRMTableCell>{data?.Price}</CRMTableCell>
                                    </CRMTableRow>
                                  </>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </Grid>

                      <Div sx={{ height: "10px" }}></Div>
                    </Div>

                    <Div
                      mb={3}
                      sx={{
                        // display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        mt: 2,
                        mb: 1,
                        // mt: -5,
                      }}
                    >
                      <Div
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          id="Typography-root"
                          sx={[
                            dialogTitle,
                            {
                              paddingLeft: "10px ",
                            },
                          ]}
                        >
                          Closed/Lost Deal
                        </Typography>

                        <CustomButton
                          label=" Mark as Deal lost"
                          width="150px "
                          Icon=""
                          onClick={() => {
                            Showcloseddeal1();
                          }}
                        />
                      </Div>
                      <Div
                        sx={[
                          dialogTitle,
                          {
                            borderBottom: "1px solid lightgray",
                            // margin: "0px 20px",
                            marginTop: "5px",
                          },
                        ]}
                      ></Div>
                      <Grid
                        conatiner
                        spacing={2}
                        sx={{
                          display: "flex",
                          paddingLeft: "10px ",
                          marginTop: 1,
                          minHeight: "100px",
                        }}
                      >
                        <Grid item md={3}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 0.5,
                              },
                            ]}
                          >
                            Reason
                          </Typography>
                        </Grid>
                        <Grid item md={1}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 0.5,
                              },
                            ]}
                          >
                            :
                          </Typography>
                        </Grid>

                        <Grid item md={8}>
                          <Typography
                            id="Typography-root"
                            sx={[
                              FontStyle,
                              {
                                marginTop: 0.5,
                              },
                            ]}
                          >
                            {datas?.Lost_Reason || "--------"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Div>

                    <Div
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        mt: 2,
                      }}
                    >
                      <Typography
                        id="Typography-root"
                        sx={[
                          dialogTitle,
                          {
                            borderBottom: "1px solid lightgray",
                            paddingLeft: "10px ",
                          },
                        ]}
                      >
                        Upcoming Activities
                      </Typography>
                      <JumboScrollbar
                        autoHeight={true}
                        autoHideTimeout={4000}
                        autoHeightMin={scrollHeight ? scrollHeight : 286}
                        autoHide={true}
                        hideTracksWhenNotNeeded
                        id="no-more-tables"
                      >
                        {appointments.length > 0 &&
                          appointments?.map((item, index) => {
                            const date = new Date(item?.Date)
                              .toDateString()
                              .split(" ");
                            return (
                              <Div
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  marginY: "5px",
                                }}
                              >
                                <Typography
                                  id="Typography-root"
                                  sx={{
                                    fontSize: "9px !important",
                                    backgroundColor: "#F7D4D5",
                                    color: "##A84F52",
                                    padding: "4px",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {date[2]} {date[1]} {date[3]}
                                </Typography>
                                <Typography
                                  id="Typography-root"
                                  sx={{ fontSize: "9px" }}
                                >
                                  To send the propsosal
                                </Typography>
                              </Div>
                            );
                          })}
                      </JumboScrollbar>
                    </Div>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* code for right side card */}
          <Grid item xs={12} sm={12} md={8} xl={8}>
            <Card
              sx={{
                // px: 1,
                p: { xs: 1, md: 2 },
                boxShadow: "none",
                border: "1px solid #EEEEEE",
                height: "100%",
                borderRadius: "5px",
                width: "100% !important",
                // height: "100%",
              }}
            >
              <Grid
                container
                alignItems={"center"}
                spacing={2}
                style={{
                  backgroundColor: "#dcf9f4",
                  width: "calc(100% + 35px)",
                  paddingBottom: "16px",
                }}
              >
                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(0)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 0 ? Colors?.Mblue : "#613659",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <AiOutlineEdit size={14} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      mt={1}
                      sx={[
                        FontStyle,
                        {
                          color: tap === 0 ? Colors?.Mblue : "black",
                        },
                      ]}
                      textAlign="center"
                    >
                      Notes
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(1)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 1 ? Colors?.Mblue : "#ED006D",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <TiPhoneOutline size={14} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      mt={1}
                      sx={[
                        FontStyle,
                        {
                          color: tap === 1 ? Colors?.Mblue : "black",
                        },
                      ]}
                      textAlign="center"
                    >
                      Call History
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(2)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 2 ? Colors?.Mblue : "#6600FF",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <AiOutlineMail size={14} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      mt={1}
                      sx={[
                        FontStyle,
                        {
                          color: tap === 2 ? Colors?.Mblue : "black",
                        },
                      ]}
                      textAlign="center"
                    >
                      Mail
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(3)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 3 ? Colors?.Mblue : "#E32227",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <AiOutlineClockCircle size={14} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      mt={1}
                      sx={[
                        FontStyle,
                        {
                          color: tap === 3 ? Colors?.Mblue : "black",
                        },
                      ]}
                      textAlign="center"
                    >
                      Activities
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(4)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 4 ? Colors?.Mblue : "#B7AC44",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <InsertInvitationIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      sx={[
                        FontStyle,
                        {
                          color: tap === 4 ? Colors?.Mblue : "black",
                        },
                      ]}
                      mt={1}
                      textAlign="center"
                    >
                      Appointments
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(5)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 5 ? Colors?.Mblue : "#006194",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <BsClockHistory size={14} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      mt={1}
                      sx={[
                        FontStyle,
                        {
                          color: tap === 5 ? Colors?.Mblue : "black",
                        },
                      ]}
                      textAlign="center"
                    >
                      History
                    </Typography>
                  </Div>
                </Grid>

                <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                  <Div
                    onClick={() => handleTap(6)}
                    sx={{
                      textAlign: "center",
                      cursor: "pointer",
                      py: 1.5,
                      ":hover": {
                        borderRadius: "5px",
                        backgroundColor: "#99e1d2",
                      },
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: tap === 6 ? Colors?.Mblue : "#40A578",
                        mb: 0.5,
                        color: "#ffffff",
                        ":hover": {
                          backgroundColor: alpha(Colors?.Mblue, 0.8),
                        },
                      }}
                    >
                      <GrTransaction size={14} />
                    </IconButton>
                    <Typography
                      id="Typography-root"
                      mt={1}
                      sx={[
                        FontStyle,
                        {
                          color: tap === 6 ? Colors?.Mblue : "black",
                        },
                      ]}
                      textAlign="center"
                    >
                      Transaction
                    </Typography>
                  </Div>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2, color: "#000000" }} />

              <Box>
                <Div sx={{ display: tap === 0 ? "" : "none" }}>
                  <DealConatctNotes id={id} data={datas} />
                </Div>
                <Div sx={{ display: tap === 1 ? "" : "none" }}>
                  <DealCallHistory id={id} leaddatas={datas} />
                </Div>

                <Div sx={{ display: tap === 2 ? "" : "none" }}>
                  <DealEmailContent data={datas} id={id} />
                </Div>

                <Div sx={{ display: tap === 3 ? "" : "none" }}>
                  <DealOpenActivity data={datas} id={id} />
                </Div>

                {/* <Div
                    sx={{
                      display: showClosedActivity === true ? "block" : "none",
                    }}
                  >
                    <ClosedActivity id={id} />
                  </Div> */}

                <Div
                  sx={{
                    display: tap === 4 ? "block" : "none",
                  }}
                >
                  <Appointment data={datas} id={id} />
                </Div>

                <Div sx={{ display: tap === 5 ? "block" : "none" }}>
                  <DealHistory id={id} />
                </Div>

                <Div
                  sx={{
                    display: tap === 6 ? "block" : "none",
                  }}
                >
                  <TransactionReport data={datas} id={id} />
                </Div>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Div>
    </JumboContentLayoutMain>
  );
};

export default OverviewDealContact;
