import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Autocomplete,
  FormControl,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Avatar,
  FormGroup,
  Checkbox,
  Box,
  Pagination,
  ButtonGroup,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "react-toastify/dist/ReactToastify.css";
import { MailIcon, PhoneIcon, SettingsIcon } from "app/icons/PngIcons";
import {
  ButtonStyle,
  CRMTableHead,
  Colors,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  AntTabs,
  componentTitle,
  customHeight,
  dialogTitle,
  AntTab,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import axios from "axios";
import { BASE_URL, BASE_URL1 } from "app/services/auth-services";
import CKEditor from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEdit from "@mui/icons-material/ModeEdit";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { MdClose } from "react-icons/md";
import LeadFromSocialmediaEdit from "app/pages/leads/lead generation/LeadFromSocialmediaEdit";
import { GrView } from "react-icons/gr";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";
import { AutoHeight } from "app/redux/auth/localStorage";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import randomColor from "randomcolor";
import { IoMdAttach, IoMdClose } from "react-icons/io";
import { alpha } from "@material-ui/core";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const OutlookInbox = () => {
  // Create Lead Questions
  const [id, setId] = useState(0);

  const token = localStorage.getItem("accesstoken");
  const [value, setValue] = useState(0);
  const [height, setHeight] = useState(true);
  const Swal = useSwalWrapper();
  const [to, setTo] = useState();
  const [showCc, setShowCc] = useState(false);
  const [cc, setCc] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [attachFile, setAttachFile] = useState();

  const [base64DataList, setBase64DataList] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const item = "crmuseradmin1";

  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  // Lead Generation

  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [base64, setbase64] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedFile, setSelectedFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [filePath, setFilePath] = useState("");
  const [namefile, setNameFile] = useState("");

  // For the popup
  const [save, setSave] = useState(false);

  // For Table Modifications

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "recipient", label: "From", bool: true },
    { sort: "sender", label: "To", bool: true },
    { sort: "body", label: "Subject", bool: true },
    { sort: "Content", label: "Content", bool: true },
  ]);

  // Edit And Delete Menu Functionality
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  // Handle Update Function For the Update

  const [openEditContact, setOpenEditContact] = useState(false);
  const [update, setUpdate] = useState("");

  const handleUpdate = (data) => {
    setUpdate(data);
  };

  const handleClickEditContact = (update) => {
    //  // // // console.log("In Edit",update)
    setOpenEditContact(true);
  };

  //  For Pagination Table

  const [page, setPage] = useState(1);
  const count = 10;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = 0;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [datas, setDatas] = useState([]);

  //    Get Data

  const getData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/InboxOutlookAdditional/`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("results in contacts", response.data);
      //   setDatas(response?.data || []);
      //   setDatas response?.data?.results || [];
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // Handle the error here, show a message, log it, etc.
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //    Compose Email
  const userDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [showDialog, setShowDialog] = useState(false);

  const uploadFiles = () => {
    const promises = base64DataList?.map((base64Data, index) => {
      console.log(`Uploading `, fileNames);
      const payload = {
        Organization_Id: localStorage.getItem("OrganizationId"),
        File: base64Data,
        FileName: randomColor() + fileNames[index]?.name,
        Urls: "url",
        Description: "Upload File",
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };

      axios.post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    });

    Promise.all(promises)
      .then((responses) => {
        console.log("Files uploaded successfully:", responses);
        MailSubmit();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const MailSubmit = async (e) => {
    // e.preventDefault();
    var payload = {
      MailSubject: subject,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Cc: cc,
      MailBody: mailBodyContent,
      base64DataList: base64DataList,
      file:
        fileNames?.length > 0
          ? fileNames?.map((fileName) => fileName?.name)
          : [],
      RecevierMailId: to,
      User_Id: localStorage.getItem("UserId"),
    };
    console.log("payload", payload);
    await axios
      .post(`${BASE_URL1}/user/MicorosoftOutlookMailSending/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log("res", res);
        toast.success("Mail sent Successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setOpenAddNotes(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const mailBodyContent = `<div class='card'>
  <div class='card-body'>
    <h4 className="card-subtitle mb-2 text-muted">Dear Sir,</h4>
    <div class='card-text'>
      <p>${message}</p>
    </div>
  </div>
  </div>`;

  const handleFileInputChange = (e) => {
    const fileList = e.target.files;

    const promises = Array.from(fileList).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((base64DataArray) => {
        console.log("base64DataArray", base64DataArray);
        setBase64DataList(base64DataArray);
        const fileArray = Array.from(fileList);
        let list = [...fileNames, ...fileArray];
        console.log("list", list);
        setFileNames(list);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const fileRemoved = (e) => {
    let list = [...fileNames];
    list?.splice(e, 1);
    let base64Array = [...base64DataList];
    base64Array?.splice(e, 1);
    setBase64DataList(base64Array);
    // alert(e)
    setFileNames(list);
  };

  const [authorizationCode, setAuthorizationCode] = useState(null);

  // Function to handle the redirect and extract the authorization code

  const handleRedirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    console.log("code", urlParams.get("code"));
    if (code) {
      // Set the authorization code in state
      setAuthorizationCode(code);
      getMailAccessToken(code);
    }
  };

  useEffect(() => {
    // Call handleRedirect when the component mounts or updates
    handleRedirect();
  }, []);

  const authorize = async () => {
    await axios
      .get(
        `${BASE_URL1}/user/MicorosoftOutlookAccessToken/?Created_By=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res?.result);

        if (res?.data?.result) {
          window.location.href = `${res?.data?.result}`;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getMailAccessToken = async (code) => {
    await axios
      .get(
        `${BASE_URL1}/user/MicorosoftOutlooMailAccessToken/?code=${code}&Created_By=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SendData = async () => {
    await axios
      .get(
        `${BASE_URL1}/user/MicorosoftOutlookMailData/?Created_By=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res?.data);
        setDatas(res?.data?.Result?.value || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (value === 0) {
      SendData();
    } else {
    }
  }, [value]);

  return (
    <>
      <JumboContentLayoutMain>
        <Div>
          <Typography
            id="Typography-root"
            sx={[componentTitle, { transform: "translateY(-20px)" }]}
          >
            Outlook List{" "}
          </Typography>

          <Box>
            <Div
              sx={[DisplayFlex, { flexWrap: "wrap", justifyContent: "start" }]}
            >
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab label="Sent" {...a11yProps(0)} />
                <AntTab label="Inbox" {...a11yProps(1)} />
              </AntTabs>
            </Div>
          </Box>
          <Box>
            <Div
              sx={[
                DisplayFlex,
                { flexWrap: "wrap", justifyContent: "flex-end" },
              ]}
            >
              <CustomButton
                Icon="createIcon"
                label="Token"
                onClick={() => {
                  authorize();
                }}
              />
              <CustomButton
                Icon="createIcon"
                label="Compose"
                onClick={() => {
                  setOpenAddNotes(true);
                }}
              />
            </Div>
          </Box>
        </Div>

        {/* Code for Contact List */}

        {/* Code for Contact List */}
        {value == 0 && (
          <Box sx={{ mt: 2 }}>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={AutoHeight(1.6)}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table className="table">
                <CustomTableHeader
                  headerMenuItems={columnMenuItems}
                  setHeadermenuItems={setColumnMenuItems}
                  setDatas={setDatas}
                  sortDatas={datas}
                />

                <TableBody sx={{ mt: 2 }}>
                  {datas?.length > 0 &&
                    datas
                      ?.slice(
                        page == 1 ? page * 0 : (page - 1) * 10,
                        page == 1 ? page * 0 + 10 : (page - 1) * 10 + 10
                      )
                      ?.map((data, index) => {
                        return (
                          <CRMTableRow key={index}>
                            {columnMenuItems[0]?.bool && (
                              <CRMTableCell
                                data-title="From"
                                sx={{ cursor: "pointer" }}
                              >
                                <Div
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Typography id="Typography-root">
                                    {data?.from?.emailAddress?.address}
                                  </Typography>
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[1].bool && (
                              <CRMTableCell data-title="To">
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {data?.toRecipients?.map((data1, index) => {
                                    return (
                                      <>
                                        <Div>{data1?.emailAddress?.name}</Div>
                                      </>
                                    );
                                  })}
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[2].bool && (
                              <CRMTableCell data-title="subject">
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Div>{data?.subject}</Div>
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[3].bool && (
                              <CRMTableCell data-title="body">
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <span
                                    style={{ fontSize: "12px !important" }}
                                    dangerouslySetInnerHTML={{
                                      __html: data.body?.content,
                                    }}
                                  ></span>
                                </Div>
                              </CRMTableCell>
                            )}
                          </CRMTableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </JumboScrollbar>
            <Div
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Pagination
                color="success"
                count={Math.ceil(datas?.length / 10)}
                page={page}
                onChange={(e, newValue) => handleChangePage(e, newValue)}
                size="small"
                showFirstButton
                showLastButton
              />
            </Div>
          </Box>
        )}

        {value == 1 && (
          <Box sx={{ mt: 2 }}>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={AutoHeight(1.6)}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table className="table">
                <CustomTableHeader
                  headerMenuItems={columnMenuItems}
                  setHeadermenuItems={setColumnMenuItems}
                  setDatas={setDatas}
                  sortDatas={datas}
                />

                <TableBody sx={{ mt: 2 }}>
                  {datas?.length > 0 &&
                    datas
                      ?.slice(
                        page == 1 ? page * 0 : (page - 1) * 10,
                        page == 1 ? page * 0 + 10 : (page - 1) * 10 + 10
                      )
                      ?.map((data, index) => {
                        return (
                          <CRMTableRow key={index}>
                            {columnMenuItems[0]?.bool && (
                              <CRMTableCell
                                data-title="From"
                                sx={{ cursor: "pointer" }}
                              >
                                <Div
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Typography id="Typography-root">
                                    {data?.from?.emailAddress?.address}
                                  </Typography>
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[1].bool && (
                              <CRMTableCell data-title="To">
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {data?.toRecipients?.map((data1, index) => {
                                    return (
                                      <>
                                        <Div>{data1?.emailAddress?.name}</Div>
                                      </>
                                    );
                                  })}
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[2].bool && (
                              <CRMTableCell data-title="subject">
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Div>{data?.subject}</Div>
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[3].bool && (
                              <CRMTableCell data-title="body">
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {/* <Div>{data?.body}</Div> */}
                                </Div>
                              </CRMTableCell>
                            )}
                          </CRMTableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </JumboScrollbar>
            <Div
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Pagination
                color="success"
                count={Math.ceil(datas?.length / 10)}
                page={page}
                onChange={(e, newValue) => handleChangePage(e, newValue)}
                size="small"
                showFirstButton
                showLastButton
              />
            </Div>
          </Box>
        )}

        <Dialog
          open={openAddNotes}
          // onClose={() => setShowDialog(false)}
          // PaperComponent={PaperComponent}
          // aria-labelledby="draggable-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={[
              { mb: 0.7 },
              customHeight,
              DisplayFlex,
              {
                bgcolor: "#dcdcdc !important",
                justifyContent: "space-between",
              },
            ]}
          >
            <Typography sx={[dialogTitle]}>Compose Mail</Typography>
          </DialogTitle>

          <Div>
            <DialogContent>
              <DialogContentText>
                <Div sx={{ width: "100%" }}>
                  <Typography mb={0.3} sx={FontStyle}>
                    From{" "}
                  </Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    size={"small"}
                    type={"email"}
                    value={userDetails?.email || "No Email"}
                    className="input-box"
                    // {...register("to")}
                    placeholder={"From"}
                    disabled
                  />
                </Div>

                <Div sx={{ width: "100%", my: 1 }}>
                  <Typography mb={0.3} sx={FontStyle}>
                    To{" "}
                  </Typography>
                  <ButtonGroup
                    sx={{
                      borderRadius: "0px !important",
                      width: "100%",
                      position: "relative",
                    }}
                    aria-label="Basic button group"
                  >
                    <TextField
                      sx={{ width: "100%" }}
                      size={"small"}
                      type={"email"}
                      className="input-box col-12"
                      // {...register("to")}
                      placeholder={"To"}
                      onChange={(e) => setTo(e.target.value)}
                    />
                    {/* <Div style={{ color: "red" }}>{errors.to?.message}</Div> */}
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          position: "absolute",
                          top: "50%",
                          left: "95%",
                          transform: "translate(-50%,-20%)",
                        },
                      ]}
                    >
                      {!Boolean(showCc) && (
                        <Typography
                          sx={[
                            FontStyle,
                            {
                              cursor: "pointer",
                              ":hover": { color: Colors?.Mblue },
                              alignSelf: "center",
                              height: "33px !important",
                              border: "none !important",
                            },
                          ]}
                          onClick={() => setShowCc(true)}
                        >
                          Cc
                        </Typography>
                      )}
                    </Div>
                  </ButtonGroup>
                </Div>

                {Boolean(showCc) && (
                  <Div sx={{ my: 1, width: "100%" }}>
                    <Typography mb={0.3} sx={FontStyle}>
                      Cc{" "}
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size={"small"}
                      type={"email"}
                      className="input-box"
                      // {...register("cc")}
                      placeholder={"CC"}
                      value={cc}
                      onChange={(e) => setCc(e.target.value)}
                    />
                    {/* <Div style={{ color: "red" }}>{errors.cc?.message}</Div> */}
                  </Div>
                )}

                <Div sx={{ my: 1, width: "100%" }}>
                  <Typography mb={0.3} sx={FontStyle}>
                    Subject{" "}
                  </Typography>
                  <TextField
                    sx={{ width: "100%" }}
                    size={"small"}
                    className="input-box"
                    placeholder={"Subject"}
                    // {...register("subject")}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  {/* <Div style={{ color: "red" }}>{errors.subject?.message}</Div> */}
                </Div>

                <Div sx={{ my: 1, width: "100%" }}>
                  <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                    <Typography mb={0.3} sx={FontStyle}>
                      Content{" "}
                    </Typography>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="attachedFiles"
                      multiple
                      onChange={handleFileInputChange}
                    />
                    <label
                      htmlFor="attachedFiles"
                      style={{ cursor: "pointer" }}
                    >
                      <IoMdAttach style={{ fontSize: 20 }} />
                    </label>
                  </Div>

                  {/* <Div style={{ color: "red" }}>{errors.message?.message}</Div> */}
                  <Div
                    sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
                  >
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          flexWrap: "wrap",
                          gap: "10px !important",
                          p: Boolean(fileNames?.length > 0) ? 1 : 0,
                        },
                      ]}
                    >
                      {fileNames?.map((file, index) => {
                        console.log("image", file, base64DataList[index]);
                        return (
                          <div
                            id="attachedFile"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <Div sx={{ width: 70, height: 90 }}>
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`File ${index + 1}`}
                                width={70}
                                height={70}
                              />
                              <p>
                                {file?.name?.length > 10
                                  ? file?.name?.substring(0, 10) + "..."
                                  : file?.name}
                              </p>
                            </Div>
                            <Div
                              id="attachedRemove"
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-65%)",
                                width: 70,
                                height: 70,
                                backgroundColor: alpha("#000", 0.2),
                              }}
                            >
                              <Div
                                sx={[DisplayFlex, { justifyContent: "center" }]}
                              >
                                <Typography
                                  onClick={() => fileRemoved(index)}
                                  sx={[
                                    {
                                      fontSize: "11px",
                                      color: "tomato !important",
                                    },
                                  ]}
                                >
                                  <IoMdClose
                                    style={{ fontSize: 20, color: "tomato" }}
                                  />{" "}
                                  Remove
                                </Typography>
                              </Div>
                            </Div>
                          </div>
                        );
                      })}
                    </Div>
                    <TextField
                      multiline
                      sx={{
                        width: "100%",
                        borderWidth: "0px !important",
                        border: "none !important",
                      }}
                      rows={5}
                      maxRows={10}
                      placeholder={"Content"}
                      // {...register("message")}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Div>
                </Div>
              </DialogContentText>
            </DialogContent>
            <Div
              sx={[
                DisplayFlex,
                {
                  justifyContent: "center",
                  my: 3,
                },
              ]}
            >
              <CustomButton
                type="submit"
                onClick={() => uploadFiles()}
                label="Send"
                Icon="sendIcon"
              />
              <Cancelbutton
                label="Cancel"
                onClick={() => {
                  setOpenAddNotes(false);
                  setFileNames([]);
                }}
              />
            </Div>
          </Div>
        </Dialog>
      </JumboContentLayoutMain>
    </>
  );
};

export default OutlookInbox;
