import React, { memo, useEffect, useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ChatHeader from "../components/chatHeaderAndFooter/Header";
import ChatFooter from "../components/chatHeaderAndFooter/Footer";
import useResponsive from "../hook/useResponsive";
import Div from "@jumbo/shared/Div";
import {
  AudioMsg,
  CallMsg,
  DocMsg,
  ImageMsg,
  LinkMsg,
  MediaMsg,
  OthersMsg,
  TextMsg,
  Timeline,
  VideoMsg,
} from "./Conversation";
import { useSelector } from "react-redux";
import { getMessagesError } from "./SelectChatUserSlice";
import { useStateContext } from "app/contexts/ContextProvider";
import { useChatContext } from "../context/Chatcontext";

// File format constants
export const FileFormats = [
  "js",
  "pdf",
  "doc",
  "docx",
  "pptx",
  "xlsx",
  "csv",
  "txt",
  "html",
  "xml",
  "json",
  "zip",
  "rar",
  "7z",
  "tar",
  "gz",
  "exe",
  "msi",
];
export const VideoFormats = [
  "mp4",
  "avi",
  "mkv",
  "mov",
  "wmv",
  "flv",
  "webm",
  "m4v",
  "ogg",
  "mpeg",
  "mpg",
  "3gp",
  "ts",
  "vob",
  "swf",
  "rm",
  "asf",
];
export const ImageFormats = [
  "jfif",
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "svg",
  "ico",
  "heic",
  "apng",
  "raw",
];
export const AudioFormats = ["ogg", "amr", "aac", "mp3", "wav", "flac"];

function ChatComponent() {
  const { conversation, selectUser } = useChatContext();
  const isMobile = useResponsive("between", "md", "xs", "sm");
  const messageListRef = useRef(null);

  // Error message from the Redux state (currently unused)
  const messageError = useSelector(getMessagesError);

  // Determine the message type and render the appropriate component
  const renderMessage = (msg) => {
    if (msg?.Jitci) {
      return (
        <>
          <CallMsg msg={msg} menu={false} />
          <Timeline msg={msg} />
        </>
      );
    }

    if (msg?.File) {
      const fileExtension = msg.File.split(".").pop();
      if (ImageFormats.includes(fileExtension))
        return <ImageMsg msg={msg} menu={false} />;
      if (VideoFormats.includes(fileExtension))
        return <VideoMsg msg={msg} menu={false} />;
      if (AudioFormats.includes(fileExtension))
        return <AudioMsg msg={msg} menu={false} />;
      if (FileFormats.includes(fileExtension))
        return <DocMsg msg={msg} menu={false} />;
      return <OthersMsg msg={msg} menu={false} />;
    }

    if (msg?.Meassage) {
      if (msg.Meassage.startsWith("https://www.youtube.com"))
        return <MediaMsg msg={msg} menu={false} />;
      if (msg.Meassage.startsWith("http"))
        return <LinkMsg msg={msg} menu={false} />;
      return <TextMsg msg={msg} menu={false} />;
    }

    return <OthersMsg msg={msg} menu={false} />;
  };

  // Render messages based on the conversation state
  const renderMessages = () => {
    if (conversation === "Waiting") {
      return (
        <Div className="h-full flex items-center justify-center text-gray-700">
          <img
            src={require("../../../assets/images/chat.gif")}
            width={100}
            height={100}
            alt="Loading..."
          />
        </Div>
      );
    }

    if (conversation === "Blocked") {
      return (
        <Div className="h-full flex items-center justify-center text-gray-700">
          <Typography>
            {selectUser?.username} is not available right now. Please try
            chatting again later.
          </Typography>
        </Div>
      );
    }

    if (conversation?.length > 0) {
      return conversation.map((msg, idx) => (
        <React.Fragment key={idx}>{renderMessage(msg)}</React.Fragment>
      ));
    }

    return (
      <Div className="h-full flex items-center justify-center">
        <img
          src={require("../../../assets/images/no-message.png")}
          className="w-52 h-52"
          alt="No Messages"
        />
      </Div>
    );
  };

  // Scroll to the bottom of the message list when conversation updates
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [conversation]);

  return (
    <Stack
      className="h-full"
      sx={{ border: "1px solid #f4f4f4" }}
      width={isMobile ? "100vw" : "100%"}
    >
      <ChatHeader />

      <Box
        ref={messageListRef}
        className="custom-scrollbar max-h-[620px] min-h-[600px]"
        sx={{
          position: "relative",
          flexGrow: 1,
          overflow: "auto",
          py: 2,
          display:
            conversation === "Waiting" || conversation?.length === 0
              ? "flex"
              : "block",
          justifyContent:
            conversation === "Waiting" || conversation?.length === 0
              ? "center"
              : "initial",
          alignItems:
            conversation === "Waiting" || conversation?.length === 0
              ? "center"
              : "initial",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Div sx={{ px: 1 }}>{renderMessages()}</Div>
      </Box>

      <ChatFooter />
    </Stack>
  );
}

export default memo(ChatComponent);
