import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain'
import Div from '@jumbo/shared/Div'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FacebookSignIn from './FacebookSignIn'
import FBFeed from './FBFeed'
import FacebookLogin from './FacebookLogin'
import axios from 'axios'
import { BASE_URL } from 'app/services/auth-services'
import { toast } from 'react-toastify'

const FacebookAPI = () => {
    const [isFacebookSignInOpen, setIsFacebookSignInOpen] = useState(false)
    const [FBpageAccessToken, setFBPageAccessToken] = useState('')
    const [isLoginOpen, setIsLoginOpen] = useState(false)
    const [id, setId] = useState('')
    const [userAccessToken, setUserAccessToken] = useState('')
    const [longLivedUserAccessToken, setLongLivedUserAccessToken] = useState('')
    const [clientId, setClientId] = useState('1487082945559869')
    const [clientSecret, setClientSecret] = useState('96e5bc97df46e201242ab00719b2b9a2')
    const [redirectUri, setRedirectUri] = useState('http://localhost:3000/')

    useEffect(() => {
        let token = localStorage.getItem('accesstoken')
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        axios.get(`${BASE_URL}/user/FacebookConfigurationAdditional/`, { headers: headers })
            .then((res) => {
                console.log("get Token lists", res)
            })
            .catch((error) => {
                console.error("Error", error)
            })
    }, [])

    useEffect(() => {
        let token = localStorage.getItem('accesstoken')
        const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        const params = {
            id: id
        }
        axios.get(`${BASE_URL}/user/FacebookConfigurationCRUD/`, { params: params, headers: headers })
            .then((res) => {
                console.log("get Access Token", res)
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    const errorMessage = error.response.data.details || "You don't have permission to do it";
                    console.log(errorMessage);
                    toast.error(errorMessage, {
                      position: "top-right",
                      autoClose: 100,
                    });
                  } else {
                    toast.error(`${error}`, {
                      position: "top-right",
                      autoClose: 100,
                    });
                  }
            })
    }, [id])

    const getAccessToken = async (code) => {

        console.log("code", code)
        const params = {
            "client_id": clientId,
            "redirect_uri": redirectUri,
            "client_secret": clientSecret,
            "code": code
        }
        await axios.get('https://graph.facebook.com/v18.0/oauth/access_token', { params: params })
            .then((res) => {
                console.log("res for token", res)
                if (res?.status == 200) {
                    setUserAccessToken(res?.data?.access_token)
                    // setFBPageAccessToken(res?.data?.access_token)
                }
            })
            .catch((error) => { console.error("error while getting token", error) })
    }

    useEffect(() => {
        console.log("triggered useEffect")
        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams",urlParams)
        const code = urlParams.get('code');

        console.log("code",code)
        if (code) {
            getAccessToken(code);
        }
    }, [])

    useEffect(() => {

        const params = {
            access_token: userAccessToken
        }

        axios.get('https://graph.facebook.com/me/accounts', { params: params })
            .then((res) => {
                console.log("long lived user access token", res)
                if (res?.status == 200) {
                    let data = res?.data?.data
                    data?.map((item) => {
                        setLongLivedUserAccessToken(item.access_token)
                    })
                }
            })
            .catch((error) => {
                console.error("Error", error)
            })
    }, [userAccessToken])

    useEffect(() => {

        //for storing in database
        let token = localStorage.getItem('accesstoken')
        const payload = {
            "client_id": clientId,
            "client_secret": clientSecret,
            "fb_exchange_token": longLivedUserAccessToken,
            "App_Id": clientId,
            "Secret_Id": clientSecret,
            "Created_By": localStorage.getItem("UserId"),
            "Updated_By": localStorage.getItem("UserId"),
            "UserId": localStorage.getItem("UserId")
        }
        const headers = {
            "db": localStorage.getItem('DBName'),
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        axios.post(`${BASE_URL}/user/FacebookConfigurationCRUD/`, payload, {
            headers: headers
        })
            .then((res) => {
                console.log("page access token response", res)
                if (res?.status == 201) {
                    toast.success("Logged In Successfully")
                    setFBPageAccessToken(res?.data?.Access_Token)
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    const errorMessage = error.response.data.details || "You don't have permission to do it";
                    console.log(errorMessage);
                    toast.error(errorMessage, {
                      position: "top-right",
                      autoClose: 100,
                    });
                  } else {
                    toast.error(`${error}`, {
                      position: "top-right",
                      autoClose: 100,
                    });
                  }
            })
    }, [longLivedUserAccessToken])


    const getToken = () => {
        let token = localStorage.getItem('accesstoken')
        const headers = {
            "db": localStorage.getItem('DBName'),
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        axios.get(`${BASE_URL}/user/FacebookConfigurationCRUD/`, {
            params: {
                UserId: `${localStorage.getItem("UserId")}`
            }, headers: headers
        })
            .then((res) => {
                console.log("Get token", res?.data)
                if (res.data) {
                    let accessToken = res.data.Access_Token
                    setFBPageAccessToken(accessToken)
                }
            })
            .catch((error) => {
                console.error("error", error)
            })
    }
    useEffect(() => {
        getToken()
    }, [id])


    const getIdFromTokenList = () => {
        let token = localStorage.getItem('accesstoken')
        const headers = {
            "db": localStorage.getItem('DBName'),
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        axios.get(`${BASE_URL}/user/FacebookConfigurationAdditional/`, {
            headers: headers
        })
            .then((res) => {
                console.log("Get token from list", res?.data.results)
                let tokenList = res?.data.results
                tokenList?.map((item) => {
                    setId(item?.id)
                })

            })
            .catch((error) => {
                console.error("error", error)
            })
    }

    useEffect(() => {
        getIdFromTokenList()
    }, [])



    return (
        <JumboContentLayoutMain>
            <Div sx={{ backgroundColor: 'whitesmoke', width: '100%', height: '100%' }}>
                {!FBpageAccessToken && <Div sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button variant='contained' onClick={() => { setIsLoginOpen(true) }}>Facebook Configuration</Button></Div>}
                {/* <Div sx={{ display: 'flex', justifyContent: 'flex-end' }} >  <Button onClick={() => { setIsFacebookSignInOpen(true) }} variant='contained'>Facebook Configuration</Button ></Div> */}
                {FBpageAccessToken && <Div sx={{ display: 'flex', justifyContent: 'center' }} ><FBFeed FBpageAccessToken={FBpageAccessToken} setFBPageAccessToken={setFBPageAccessToken} /></Div>}
            </Div>
            {isFacebookSignInOpen &&
                <FacebookSignIn isFacebookSignInOpen={isFacebookSignInOpen} setIsFacebookSignInOpen={setIsFacebookSignInOpen} />
            }
            {isLoginOpen && <FacebookLogin isLoginOpen={isLoginOpen} setIsLoginOpen={setIsLoginOpen} FBpageAccessToken={FBpageAccessToken} setFBPageAccessToken={setFBPageAccessToken}></FacebookLogin>}
        </JumboContentLayoutMain>
    )
}

export default FacebookAPI