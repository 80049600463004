import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import iconImage from "./../../../assets/images/map.png";
import {
  CircularProgress,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Autocomplete,
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { BASE_URL, Tenantname } from "app/services/auth-services";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  ButtonStyle,
  Colors,
  DisplayFlex,
  FontStyle,
  UploadBtn,
  avatarColors,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBar from "app/pages/widgets/SearchField";
import useStateRef from "react-usestateref";
import { useDispatch, useSelector } from "react-redux";
import { SET_GOOGLE_MAPS_API_KEY } from "app/redux/actions/locationkey";
import { getGeoLocationKey, locationKey } from "app/redux/reducers/locationApp";
import {
  AvatarCircle,
  CustomButton,
  avatarColorFun,
} from "app/pages/widgets/StylesComponents";
import { IoCallOutline } from "react-icons/io5";
import {
  CallEnd,
  KeyboardDoubleArrowUp,
  Mic,
  MicOff,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import moment from "moment";
import { customHeight } from "app/pages/widgets/CRMStyles";
import { MdLocationOn } from "react-icons/md";

const GeoLocation = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");
  const dispatch = useDispatch();
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const item = "crmuseradmin1";

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [searchedCity, setSearchedCity] = useState("");
  const [searchedDatas, setSearchedDatas] = useState([]);
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [customMarkerIcon, setCustomMarkerIcon] = useState(null);

  const defaultCenter = {
    // Chennai
    lat: 13.0827,
    lng: 80.2707,
  };

  const locationKeys = useSelector(locationKey);
  // Get The Google Map Authentication Token
  const [mapsLoaded, setMapsLoaded] = useState("");

  const [isApiKeyLoaded, setIsApiKeyLoaded] = useState(false);

  console.log("check", locationKeys);

  const [check, setCheck] = useState(false);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: locationKeys,
  });

  const [distance, setDistance] = useState(0);
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });

  //call
  const option = [
    "Connected",
    "Customer Missed",
    "Voice mail",
    "Busy",
    "Disconnect",
    "Missed call",
    "Call waiting",
    "Confidence call",
    "Call attended",
    "Do Not Disturb Turned On",
    "Call Not Picked",
    "Called Back",
  ];

  const contaxtoptions = [
    "Solving Problem",
    "Making Decision",
    "Solution Achieved",
  ];
  const callconvoptions = [
    "Casual",
    "Tension",
    "Not Interested",
    "Friendly",
    "Confidancial",
  ];

  const BusyHroptions = ["Morning", "Evening", "After Noon"];

  const [anchorEl, setAnchorEl] = useState(null);

  const [showDial, setShowDial] = useState(false);
  const [calldata, setcalldata] = useState();
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [notes, setNotes] = useState("");
  const [statusc, setStatusc] = useState("");
  const [nextdate, setNextDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [callcontext, setCallContext] = useState("");
  const [busy, setBusy] = useState("");
  const [callconvstatus, setCallconvstatus] = useState("");

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);

  useEffect(() => {
    if (isLoaded) {
      const timeoutId = setTimeout(() => {
        setCheck(true);

        setCustomMarkerIcon({
          url: iconImage,
          scaledSize: new window.google.maps.Size(40, 40),
        });
      }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)

      return () => clearTimeout(timeoutId);
    }
  }, [isLoaded]);

  const handleCitySearch = (newValue) => {
    setIsSearchClicked(true);
    axios
      .get(
        `${BASE_URL}/user/AccountLocationByValue/?City=${newValue?.Location}`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setSearchedDatas(res?.data?.Response || []);
        res.data.Response.map((item, index) => {
          setLatitude(parseFloat(item?.Latitude));
          setLongitude(parseFloat(item?.Longitude));
        });
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  console.log("res?.data.Response?.Latitude", longitude, longitude);

  const [number, setNumber] = useState(1);

  const handleMarkerClick = (data, index) => {
    console.log("data", data);
    setNumber(index);
    setSelectedMarker(data);
    setLatitude(parseFloat(data.Latitude));
    setLongitude(parseFloat(data.Longitude));
  };

  const handleInfoClose = () => {
    // setSelectedMarker(null);
  };

  const handleClearSearch = () => {
    setSearchedCity("");
    setIsSearchClicked(false);
    // setSearchedDatas([]);
    setSelectedMarker(null);
  };
  const mapStyles = {
    height: "500px",
    width: "100%",
  };

  const handleClickOverviewContact = () => {
    navigate(`/${Tenantname}/app/accounts/overview/${selectedMarker?.id}`);
  };

  const [location, setLocation] = useState([]);

  const getCustomerLocation = () => {
    axios
      .get(`${BASE_URL}/user/AccountGeoLocation/`, {
        headers: {
          db: "crmuseradmin1",
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("location", res.data);
        setLocation(res.data?.Response);
        setSearchedDatas(res.data?.allLocation);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  useEffect(() => {
    getCustomerLocation();
  }, []);

  const getRandomOffset = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const [knowlaritydatas, setKnowlaritydatas] = useState([]);
  const [agentdetails, setagentdetails] = useState([]);

  const getKnowlarityConfiguration = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res?.data", res.data);
        setKnowlaritydatas(res?.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const getagentnumber = async (data) => {
    await axios
      .get(`https://kpi.knowlarity.com/Basic/v1/account/agent`, {
        headers: {
          // "Accept": "application/json",
          // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
          // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

          Accept: "application/json",
          "x-api-key": knowlaritydatas?.Header_Key,
          Authorization: knowlaritydatas?.API_Key,
        },
      })
      .then((res) => {
        console.log("agentres", res);
        const agentnum = res.data?.objects?.find(
          (item) => item?.phone === userDetails?.PhoneNo
        );
        // setagentdetails(agentnum)

        onGoingCallApi(data, agentnum);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  useEffect(() => {
    getKnowlarityConfiguration();
  }, []);

  //  OnGoing Call API

  const onGoingCallApi = async (data, agentnum) => {
    let knopayload = {
      // k_number: "+918037125874",
      // agent_number: "+919344312970",
      // customer_number: "+918870791912",
      // caller_id: "+918040210001",
      // caller_name: datas?.Name,
      k_number: knowlaritydatas?.Knowlarity_Number,
      agent_number: agentnum?.country_code + agentnum?.phone,
      customer_number: "+91" + data?.Phone_Number,
      caller_id: knowlaritydatas?.Knowlarity_CallerId,
      caller_name: data?.Contact_Name,
    };
    console.log("knopayload", knopayload);
    await axios
      .post(
        `https://kpi.knowlarity.com/Basic/v1/account/call/makecall`,
        knopayload,
        {
          headers: {
            // "Accept": "application/json",
            // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
            // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

            Accept: "application/json",
            "x-api-key": knowlaritydatas?.Header_Key,
            Authorization: knowlaritydatas?.API_Key,
          },
        }
      )
      .then((res) => {
        console.log("callres", res);
        setShowDial(true);
        setcalldata(data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  console.log("agentdetails", agentdetails?.phone, agentdetails?.country_code);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const calllogs = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityCallLogsCRUD/?start_Date=2020-04-16&end_Date=&UserId=${localStorage.getItem(
          "UserId"
        )}&Module=Account`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("calllogsresss", res.data);
        // setKnowlaritydatas(res?.data);
        // setcallogsdetails(res.data)
        const mynumberList = res?.data?.filter((item) =>
          item.Agent_Number.includes(userDetails?.PhoneNo)
        );

        const lastIndexData = mynumberList[0];
        console.log("lastIndexData", mynumberList, lastIndexData);

        setDuration(lastIndexData?.Call_Duration);

        if (lastIndexData?.Agent_Number?.includes("User Disconnected")) {
          setStatusc("User Disconnected");
        } else if (lastIndexData?.Agent_Number?.includes("Customer Missed")) {
          setStatusc("Customer Missed");
        } else {
          setStatusc("Connected");
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    var payload = {
      Company_Name: calldata?.Organization_Name,
      Receiver_Name: calldata?.Contact_Name,
      Contact_Number: calldata?.Phone_Number,
      Selected_Date: moment().format("YYYY-MM-DD"),
      Next_SheduledTime: nextdate,
      Call_Duration: duration,
      Call_Context: callcontext,
      Call_Con_Status: callconvstatus,
      Call_Busy_hour: busy,
      Activity_Type: "Call",
      Activity_relates: "Account",
      Details: notes,
      Select_Status: statusc,
      Account_Id: Number(calldata?.id),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };

    axios
      .post(`${BASE_URL}/user/CallcenterActivityCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("check", res);
        toast.success("Created successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setShowDial(false);
        handleClose();
        // for Updating the Contact List
        if (calldata?.No_of_Calls == null || "" || NaN) {
          var x = 1;
        } else {
          var x = parseInt(calldata?.No_of_Calls) + 1;
        }
        let payload1 = {
          id: Number(calldata?.id),
          Last_Call_Date: moment().format("YYYY-MM-DD"),
          Last_Called_By: userDetails && userDetails?.username,
          No_of_Calls: x,
        };
        console.log("check", payload1);
        axios
          .patch(`${BASE_URL}/user/ContactCRUD/`, payload1, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((re) => {
            console.log("res", re);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // For Updateing the History of Changes
        let editUser = localStorage.getItem("Username");
        let histotypayload = {
          Document_Id: res?.data?.CallCenter_Id,
          Updated_Person: `${editUser} Created this Contact Phone History.`,
          Module_Name: "Contact Phone History",
          Contact_Id: calldata?.id,
          Updated_By: parseInt(localStorage.getItem("UserId")),
        };
        console.log("histotypayload", histotypayload);
        axios
          .post(`${BASE_URL}/user/Updaterecordscrud/`, histotypayload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("Result", res?.data);
          })
          .catch((res) => {
            console.log(res?.message);
          });
        // Create Task
        // CreateTask(editUser);
      })
      .catch((error) => {
        if (error?.response && error?.response?.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to calculate distance between two points using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  useEffect(() => {
    if (anchorEl) {
      const timer = setTimeout(() => {
        calllogs();
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [anchorEl]);

  useEffect(() => {
    // Calculate distance when current location or destination changes
    const distance = calculateDistance(
      currentLocation.lat,
      currentLocation.lng,
      latitude,
      longitude
    );
    setDistance(distance);
  }, [currentLocation, latitude, longitude, searchedDatas]);

  const onCurrentLocationChange = () => {
    // Get current location and update state
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  console.log("searchedDatas", searchedDatas);
  return (
    <JumboContentLayoutMain>
      <div>
        {/* call section */}
        <Dialog
          open={showDial && !Boolean(anchorEl)}
          // onClose={onClosed}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{
              backgroundColor: "#0070B9",
              overflow: "hidden",
              // borderTopRightRadius: "12px",
              // borderRadiusTopleft: "12px",
            }}
          >
            {/* <IconButton
              onClick={() => {
                return setShowDial(false), setNoteCall(false);
              }}
              sx={{
                right: "10px",
                top: "8px",
                position: "absolute",
                opacity: "87%",
              }}
            >
              <CloseIcon size={24} color="#FFFFFF" />
            </IconButton> */}
            <Stack sx={{ overflow: "hidden", width: "350px", height: "400px" }}>
              <Div
                sx={{
                  width: "100%",
                  height: "200px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Div
                  sx={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "green",
                    borderRadius: "50%",
                    position: "relative",
                    zIndex: "2",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white",
                      fontSize: "30px",
                      borderColor: "common.white",
                      boxShadow: "inset 0px 0px 5px white",
                      color: "#3498DB",
                      fontWeight: "900",
                    }}
                  >
                    {calldata?.Contact_Name &&
                      calldata?.Contact_Name[0]?.toUpperCase()}
                    {calldata?.Contact_Name &&
                      calldata?.Contact_Name[1]?.toUpperCase()}
                  </Avatar>
                </Div>
                <Div
                  sx={{
                    width: "115px",
                    height: "115px",
                    position: "absolute",
                    backgroundColor: "#F2F2F2",
                    opacity: "25%",
                    borderRadius: "50%",
                  }}
                ></Div>
                <Div
                  sx={{
                    width: "130px",
                    height: "130px",
                    position: "absolute",
                    backgroundColor: "#F2F2F2",
                    opacity: "20%",
                    borderRadius: "50%",
                  }}
                ></Div>
                <Div
                  sx={{
                    width: "145px",
                    height: "145px",
                    position: "absolute",
                    backgroundColor: "#F2F2F2",
                    opacity: "15%",
                    borderRadius: "50%",
                  }}
                ></Div>
              </Div>
              <Div sx={{ textAlign: "center" }}>
                <Typography
                  id="Typography-root"
                  variant="h3"
                  mb={1}
                  sx={{
                    fontSize: "28px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                  color={"common.black"}
                >
                  {calldata?.Contact_Name}
                </Typography>
                <Typography
                  id="Typography-root"
                  sx={{ color: "#F2F2F2", fontSize: "16px" }}
                >
                  {calldata?.Phone_Number}
                </Typography>
              </Div>
              <Div
                sx={{
                  mt: 2,
                  gap: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Div>
                  <IconButton
                    onClick={() => setVolume(false)}
                    sx={{
                      display: volume ? "" : "none",
                      borderRadius: "50%",
                      padding: "8px",
                      color: "black",
                      backgroundColor: "white",
                      ":hover": { backgroundColor: "white", opacity: 0.8 },
                    }}
                  >
                    <VolumeUp fontSize="16px" color="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => setVolume(true)}
                    sx={{
                      display: !volume ? "" : "none",
                      borderRadius: "50%",
                      padding: "8px",
                      color: "black",
                      backgroundColor: "white",
                      opacity: 0.8,
                      ":hover": { backgroundColor: "white", opacity: 0.8 },
                    }}
                  >
                    <VolumeOff fontSize="16px" color="black" />
                  </IconButton>
                </Div>
                <IconButton
                  sx={{
                    borderRadius: "50%",
                    padding: "8px",
                    color: "white",
                    backgroundColor: "red",
                    ":hover": {
                      backgroundColor: "white",
                      color: "red",
                    },
                  }}
                  onClick={handleClick}
                >
                  <CallEnd fontSize="13px" color="red" />
                </IconButton>
                <Div>
                  <IconButton
                    onClick={() => setMic(false)}
                    sx={{
                      display: mic ? "" : "none",
                      borderRadius: "50%",
                      padding: "8px",
                      color: "black",
                      backgroundColor: "white",
                      ":hover": { backgroundColor: "white", opacity: 0.9 },
                    }}
                  >
                    <MicOff fontSize="16px" color="black" />
                  </IconButton>
                  <IconButton
                    onClick={() => setMic(true)}
                    sx={{
                      display: !mic ? "" : "none",
                      borderRadius: "50%",
                      padding: "8px",
                      color: "black",
                      backgroundColor: "white",
                      opacity: 0.8,
                      ":hover": { backgroundColor: "white", opacity: 0.8 },
                    }}
                  >
                    <Mic fontSize="16px" color="black" />
                  </IconButton>
                </Div>
              </Div>
              <Div
                sx={{
                  textAlign: "center",
                  borderRadius: "5px",
                  marginTop: "30px",
                  fontSize: "16px",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                Connecting . . . .
              </Div>
            </Stack>
          </DialogContent>
        </Dialog>

        <Dialog
          open={Boolean(anchorEl)}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="xs"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={[
              { mb: 0.7 },
              dialogTitle,
              customHeight,
              DisplayFlex,
              {
                bgcolor: "#DCDCDC !important",
                justifyContent: "space-between",
              },
            ]}
          >
            Create Call Notes
          </DialogTitle>
          <form onSubmit={handleSubmit1}>
            <DialogContent>
              <Div sx={{ marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Call Status
                    </Typography>
                    <Autocomplete
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      options={option}
                      value={statusc}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setStatusc(newValue);
                      }}
                      id="outlined-basic"
                      className="search-select col-12"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Call Status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Call context
                    </Typography>
                    <Autocomplete
                      className="search-select"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      value={callcontext}
                      options={contaxtoptions}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setCallContext(newValue);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Call Context"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Call Busy Hour
                    </Typography>
                    <Autocomplete
                      className="search-select"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      value={busy}
                      options={BusyHroptions}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setBusy(newValue);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Call Busy Hour"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Call Coversation Status
                    </Typography>
                    <Autocomplete
                      className="search-select"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      value={callconvstatus}
                      options={callconvoptions}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setCallconvstatus(newValue);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Call Status"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Call Duration
                    </Typography>
                    <TextField
                      className="input-box col-12"
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                      placeholder="Enter Call Duration"
                      type="number"
                      value={duration}
                      InputProps={{
                        endAdornment: (
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { textAlign: "right" }]}
                          >
                            Seconds
                          </Typography>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Next Scheduled Date
                    </Typography>
                    <TextField
                      type="datetime-local"
                      value={nextdate}
                      onChange={(e) => {
                        setNextDate(e.target.value);
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      className="input-box col-12"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Notes
                    </Typography>
                    <TextField
                      className={`col-12 multiline-box`}
                      multiline
                      rows={4}
                      value={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                      id="outlined-basic"
                      placeholder="Enter your notes"
                      variant="outlined"
                      sx={{ fontSize: "12px" }}
                    />
                  </Grid>
                </Grid>
              </Div>
            </DialogContent>
            <DialogActions sx={[DisplayFlex, { justifyContent: "center" }]}>
              <CustomButton
                type="submit"
                label="Save"
                // onClick={handleSaveImage}
                Icon="saveIcon"
              />
            </DialogActions>
          </form>
        </Dialog>

        <Typography id="Typography-root" variant="h1">
          Customers Locate
        </Typography>
        {/* <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xl={4} xs={12}>
            <Autocomplete
              options={location || []}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.Location) {
                  return option?.Location;
                }
                return "";
              }}
              onChange={(e, newValue) => {
                handleCitySearch(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Choose City" />
              )}
              className="search-select"
            />
          </Grid>
          <Grid item xl={4}>           
            <CustomButton label="Clear" onClick={handleClearSearch} Icon="" />
          </Grid>
        </Grid> */}

        {check && isLoaded && isSearchClicked && searchedDatas.length > 0 && (
          <GoogleMap
            onLoad={onCurrentLocationChange}
            mapContainerStyle={{ height: "600px", width: "100%" }}
            zoom={1}
            center={defaultCenter}
          >
            {searchedDatas?.map((data, index) => {
              let image =
                BASE_URL +
                  // data?.File?.split("https://backend.crmfarm.app")[1] ||
                  data?.File ?? "";
              console.log("img", image);

              let avatar =
                index >= 10 ? avatarColors[index % 10] : avatarColors[index];
              return (
                <Marker
                  key={index}
                  zoom={1}
                  position={{
                    lat:
                      parseFloat(data.Latitude) +
                      getRandomOffset(-0.0001, 0.0001),
                    lng:
                      parseFloat(data.Longitude) +
                      getRandomOffset(-0.0001, 0.0001),
                  }}
                  title={data?.Contact_Name}
                  icon={customMarkerIcon}
                  onClick={() => handleMarkerClick(data, index)}
                >
                  {number == index && (
                    <InfoWindow
                      position={{ lat: latitude, lng: longitude }}
                      onCloseClick={handleInfoClose}
                      zoom={1}
                    >
                      <Div>
                        <Div sx={[DisplayFlex, { justifyContent: "center" }]}>
                          <AvatarCircle
                            size="60px"
                            fontSize="30px"
                            onClick={() => {
                              handleClickOverviewContact(data);
                            }}
                            // title={data?.Contact_Name}
                            title={!Boolean(data?.File) && data?.Contact_Name}
                            image={Boolean(data?.File) ? image : false}
                            bgColor={avatarColorFun(index)}
                          />
                        </Div>
                        <Typography
                          id="Typography-root"
                          sx={{
                            fontSize: "13px",
                            verticalAlign: "middle",
                            textAlign: "center",
                            mt: 1,
                          }}
                        >
                          {data?.Contact_Name}
                        </Typography>

                        <Div sx={[DisplayFlex]}>
                          <IoCallOutline
                            onClick={() => {
                              getagentnumber(data);
                            }}
                            size={16}
                            color={Colors?.Mblue}
                          />

                          <Typography
                            id="Typography-root"
                            sx={{
                              fontSize: "13px",
                              verticalAlign: "middle",
                              textAlign: "center",
                              mt: 1,
                            }}
                          >
                            {data?.Phone_Number}
                          </Typography>
                        </Div>

                        <Div sx={[DisplayFlex]}>
                          <MdLocationOn
                            // onClick={() => getagentnumber(data)}
                            size={16}
                            color={Colors?.Mblue}
                          />

                          <Typography
                            id="Typography-root"
                            sx={{
                              fontSize: "13px",
                              verticalAlign: "middle",
                              textAlign: "center",
                              mt: 1,
                            }}
                          >
                            {`${data?.City}(${distance.toFixed(2)} km away)`}
                          </Typography>
                        </Div>
                      </Div>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
          </GoogleMap>
        )}

        {check && isLoaded && !isSearchClicked && (
          <Div>
            <GoogleMap
              onLoad={onCurrentLocationChange}
              mapContainerStyle={{ height: "600px", width: "100%" }}
              zoom={1}
              center={defaultCenter}
            >
              {searchedDatas?.map((data, index) => {
                let image =
                  BASE_URL +
                    // data?.File?.split("https://backend.crmfarm.app")[1] ||
                    data?.File ?? "";
                console.log("img", image);
                let avatar =
                  index >= 10 ? avatarColors[index % 10] : avatarColors[index];
                return (
                  <Marker
                    key={index}
                    zoom={1}
                    position={{
                      lat:
                        parseFloat(data.Latitude) +
                        getRandomOffset(-0.0001, 0.0001),
                      lng:
                        parseFloat(data.Longitude) +
                        getRandomOffset(-0.0001, 0.0001),
                    }}
                    title={data?.Contact_Name}
                    icon={customMarkerIcon}
                    onClick={() => handleMarkerClick(data, index)}
                  >
                    {number == index && (
                      <InfoWindow
                        position={{ lat: latitude, lng: longitude }}
                        onCloseClick={handleInfoClose}
                        zoom={1}
                      >
                        <Div>
                          <Div sx={[DisplayFlex, { justifyContent: "center" }]}>
                            {/* <Avatar
                              onClick={() => {
                                handleClickOverviewContact(data);
                              }}
                              sx={{
                                width: 50,
                                height: 50,
                                verticalAlign: "middle",
                                boxShadow: (theme) => theme.shadows[3],
                                cursor: "pointer",
                                ":hover": {
                                  backgroundColor: "grey",
                                },
                              }}
                            >
                              {data.Contact_Name && data.Contact_Name !== ""
                                ? data?.Contact_Name?.substring(
                                  0,
                                  2
                                ).toUpperCase()
                                : ""}
                            </Avatar> */}
                            <AvatarCircle
                              onClick={() => {
                                handleClickOverviewContact(data);
                              }}
                              size="60px"
                              fontSize="30px"
                              // title={data?.Contact_Name}
                              title={!Boolean(data?.File) && data?.Contact_Name}
                              image={Boolean(data?.File) ? image : false}
                              bgColor={avatarColorFun(index)}
                            />
                          </Div>
                          <Typography
                            id="Typography-root"
                            sx={{
                              fontSize: "13px",
                              verticalAlign: "middle",
                              textAlign: "center",
                              mt: 1,
                            }}
                          >
                            {data?.Contact_Name}
                          </Typography>
                          <Div sx={[DisplayFlex]}>
                            <IoCallOutline
                              onClick={() => getagentnumber(data)}
                              size={16}
                              color={Colors?.Mblue}
                            />

                            <Typography
                              id="Typography-root"
                              sx={{
                                fontSize: "13px",
                                verticalAlign: "middle",
                                textAlign: "center",
                                mt: 1,
                              }}
                            >
                              {data?.Phone_Number}
                            </Typography>
                          </Div>
                          <Div sx={[DisplayFlex]}>
                            <MdLocationOn
                              // onClick={() => getagentnumber(data)}
                              size={16}
                              color={Colors?.Mblue}
                            />

                            <Typography
                              id="Typography-root"
                              sx={{
                                fontSize: "13px",
                                verticalAlign: "middle",
                                textAlign: "center",
                                mt: 1,
                              }}
                            >
                              {`${data?.City}(${distance.toFixed(2)} km away)`}
                            </Typography>
                          </Div>
                        </Div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })}
            </GoogleMap>
          </Div>
        )}
      </div>
      <Div></Div>
    </JumboContentLayoutMain>
  );
};

export default GeoLocation;
