import {
  Button,
  Avatar,
  IconButton,
  Card,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Menu,
  MenuItem,
  Pagination,
  CircularProgress,
  useMediaQuery,
  ListItemText,
  ListItemIcon,
  List,
  Popover,
  Grid,
  Box,
  Autocomplete,
  TextField,
  ListItemButton,
} from "@mui/material";
import { CloseIcon, MailIcon, PhoneIcon } from "app/icons/PngIcons";
import axios from "axios";
import React, { useState, useEffect, memo } from "react";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModeEdit } from "@mui/icons-material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { BsArrowDownUp } from "react-icons/bs";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadFromSocialmediaEdit from "./LeadFromSocialmediaEdit";
import {
  ButtonStyle,
  CRMTableHead,
  componentTitle,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  generateRandomColor,
  UploadBtn,
  avatarColors,
  Colors,
  deletedWarnigMsg,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import {
  AvatarCircle,
  DialogBox,
  CustomButton,
  DeletedIcon,
  EditIcon,
  CustomIcons,
  formatDate,
  avatarColorFun,
  Cancelbutton,
  SelectDropdown,
} from "app/pages/widgets/StylesComponents";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { AutoHeight, UserId } from "app/redux/auth/localStorage";
import { useQuery, useQueryClient } from "react-query";
import OverviewPage from "app/pages/components/OverviewPage";
import { TiEdit } from "react-icons/ti";
import { FaGear } from "react-icons/fa6";
import { BiFilterAlt } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { City, Country, State } from "country-state-city";

function createData(
  accName,
  EnqDate,
  LeadName,
  Email,
  Phoneno,
  Location,
  Status
) {
  return { accName, EnqDate, LeadName, Email, Phoneno, Location, Status };
}

const LeadFromSocialmedia = ({ scrollHeight }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [openEditContact, setOpenEditContact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showContact, setShowContact] = useState(true);
  const [showHeading, setShowHeadding] = useState(true);
  const [showQualifyNow, setShowQualifyNow] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };
  const handleClickEditContact = () => {
    setOpenEditContact(true);
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddContact = () => {
    setOpenAddContact(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const navigate = useNavigate();

  // For Settings Icon Function
  const [datas, setDatas] = useState([]);
  const [contactname, setContactName] = useState(true);
  const [phone, setPhone] = useState(true);
  const [email, setEmail] = useState(true);
  const [country, setCountry] = useState(true);
  const [city, setCity] = useState(true);
  const [companyname, setCompanyName] = useState(true);
  const [industrytype, setIndustryType] = useState(false);
  const [state, setState] = useState(false);
  const [status, setStatus] = useState(false);
  const [id, setId] = useState(false);
  const [owner, setOwner] = useState(true);

  const [openAnchor, setOpenAnchor] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [update, setUpdate] = useState(0);
  const [overview, setOverview] = useState(false);

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "CompanyName", label: "Company Name", bool: false },
    { sort: "PhoneNo", label: "Phone", bool: true },
    { sort: "EmailId", label: "Email", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "Lead_Owner", label: "Lead Owner", bool: true },
    { sort: "LeadIndustry", label: "Industry Type", bool: true },
    { sort: "City", label: "City", bool: true },
    { sort: "State", label: "State", bool: true },
    { sort: "Country", label: "Country", bool: true },
  ]);

  const [showDelete, setShowDelete] = useState(false);

  const matches = useMediaQuery("(min-width:759px)");

  // For Asecnding Order

  const [showsort, setShowsort] = useState(true);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas].sort((a, b) =>
      value === "A-Z"
        ? a?.LeadFirstName?.localeCompare(b?.LeadFirstName)
        : b?.LeadFirstName?.localeCompare(a?.LeadFirstName)
    );
    setDatas(sortedData);
  };

  // For filter Functionality
  const [contry, setContry] = useState("");
  const [cty, setCty] = useState("");
  const [indus, setIndus] = useState("");
  const [fromdate, setFromdate] = useState(new Date().toISOString());
  const [todate, setToDate] = useState(new Date().toISOString());
  const [statefil, setStatefil] = useState("");

  const filterSubmit = async (e) => {
    console.log("fromdate", todate, fromdate);
    e.preventDefault();
    try {
      axios
        .get(
          `${BASE_URL}/user/leadFilter/?Industry_Type=${indus}&Country=${contry}&State=${statefil}&City=${cty}&fromdate=${fromdate}&todate=${todate}&Created_By=${localStorage.getItem(
            "UserId"
          )}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          // setDatas(res.data || []);
          setShowFilter(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const RefreshData = () => {
    setContry("");
    setCty("");
    setIndus("");
    setFromdate("");
    setToDate("");
    setStatefil("");
    getFacebookListsRefetch(page);
  };

  // Generating Color

  //  For Pagination Table
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = datas?.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSocialListsRefetch(newPage);
  };

  // Get Contact List

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const getData = async (pageNum) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserLeadfromSocialMediaAdditional/?Created_By=${UserId}&page=${pageNum}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const data = response.data.results || [];
      const count = response.data?.count || 0;

      return { data, count };
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };

  const LinkedinData = async () => {
    try {
      let payload = {
        Organization_Id: localStorage.getItem("OrganizationId"),
        UserId: localStorage.getItem("UserId"),
      };
      const response = await axios.post(
        `${BASE_URL}/user/Fetch_and_storeLinkdin_data/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
      throw error; // Rethrow the error to propagate it further
    }
  };

  const FaceBookData = async () => {
    try {
      let payload1 = {
        Organization_Id: localStorage.getItem("OrganizationId"),
        UserId: localStorage.getItem("UserId"),
      };

      const response = await axios.post(
        `${BASE_URL}/user/Fetch_and_storeFacebook_data/`,
        payload1,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
      throw error; // Rethrow the error to propagate it further
    }
  };

  //  Handle Update For Update Data

  const handleUpdate = (data) => {
    setUpdate(data);
  };

  // For Delete Functionality

  const [number, setNumber] = useState(1);

  const Deletedata = (data) => {
    axios
      .delete(`${BASE_URL}/user/UserLeadfromSocialMediaCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        getSocialListsRefetch(page);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  //  For Edit Functionality
  const [openAddLead, setOpenAddLead] = useState(false);
  // Web Update
  const [webupdate, setWebUpdate] = useState({});

  const handleWebUpdate = (data) => {
    console.log("data", data);
    setWebUpdate(data);
  };

  // Lead Assignment
  const handelAssign = () => {
    setIsLoading(true);
    axios
      .get(
        `${BASE_URL}/user/LeadSocialMediaAssignmentForm/?Created_By=${UserId}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success(`${res.data}`, {
          position: "top-right",
          autoClose: 100,
        });
        getSocialListsRefetch(page);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // get Lists All Data
  const {
    data: getSocialLists,
    isLoading: getSocialListsLoading,
    isError: getSocialListsError,
    refetch: getSocialListsRefetch,
  } = useQuery(["getSocialLists"], () => getData(page), {
    staleTime: Infinity,
  });

  const {
    data: getLinkdinLists,
    isLoading: getLinkdinListsLoading,
    isError: getLinkdinListsError,
    refetch: getLinkdinListsRefetch,
  } = useQuery(["getLinkdinLists"], LinkedinData, { staleTime: Infinity });
  const {
    data: getFacebookLists,
    isLoading: getFacebookListsLoading,
    isError: getFacebookListsError,
    refetch: getFacebookListsRefetch,
  } = useQuery(["getFacebookLists"], FaceBookData, { staleTime: Infinity });

  useEffect(() => {
    if (getSocialLists) {
      setDatas(getSocialLists?.data);
      setCount(getSocialLists?.count);
    }
  }, [getSocialLists]);

  useEffect(() => {
    if (getFacebookLists) {
      getSocialListsRefetch(page);
    }
  }, [getFacebookLists]);

  useEffect(() => {
    if (getLinkdinLists) {
      getSocialListsRefetch(page);
    }
  }, [getLinkdinLists]);

  return (
    <>
      <Div sx={{ display: showContact === true ? "display" : "none" }}>
        <Box>
          <Box className="mt-3">
            <Grid container spacing={2}>
              <Grid
                item
                xs={10.5}
                md={6}
                display={"flex"}
                alignItems={"center"}
              >
                <IconButton
                  sx={{ color: Colors?.Mblue }}
                  onClick={(e) => setShowFilter(e.currentTarget)}
                >
                  <BiFilterAlt size={22} />
                </IconButton>
                <Typography
                  sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                >
                  ( {count} ) Social Media
                </Typography>
              </Grid>
              {!matches && (
                <Grid item xs={1} md={1}>
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              )}

              {/* Filter popover  */}

              <Popover
                open={Boolean(showFilter)}
                anchorEl={showFilter}
                onClose={() => setShowFilter(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
              >
                <Box>
                  <Div
                    sx={[
                      DisplayFlex,
                      CRMTableHead,
                      dialogTitle,
                      { px: 2, justifyContent: "space-between" },
                    ]}
                  >
                    <Typography id="Typography-root" sx={[dialogTitle]}>
                      Filters
                    </Typography>
                    <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                      <GrPowerReset size={16} />
                    </IconButton>
                  </Div>

                  <form style={{ padding: "1rem" }} onSubmit={filterSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Country
                        </Typography>
                        <Autocomplete
                          className="col-12 search-select3"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={Country.getAllCountries() || []}
                          value={contry?.name}
                          onChange={(e, newValue) => {
                            setContry(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          State
                        </Typography>
                        <Autocomplete
                          className=" col-12 search-select3"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          value={statefil?.name}
                          options={
                            State.getStatesOfCountry(contry?.isoCode) || []
                          }
                          onChange={(e, newValue) => {
                            setStatefil(newValue);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value || value === ""
                          }
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="State" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          City
                        </Typography>
                        <Autocomplete
                          className="col-12 search-select3"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={
                            City.getCitiesOfState(
                              statefil?.countryCode,
                              statefil?.isoCode
                            ) || []
                          }
                          value={cty?.name}
                          onChange={(e, newValue) => {
                            setCty(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="City" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Industry Type
                        </Typography>
                        <TextField
                          type="text"
                          className="col-12 input-box2"
                          autoComplete="off"
                          placeholder="Industry Type"
                          value={indus}
                          onChange={(e) => {
                            setIndus(e.target.value);
                          }}
                          sx={{ mt: -0.5 }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          From Date
                        </Typography>
                        <TextField
                          type="date"
                          className="col-12 input-box2"
                          value={fromdate}
                          onChange={(e) => {
                            setFromdate(e.target.value);
                          }}
                          id="createdDate"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          To Date
                        </Typography>
                        <TextField
                          value={todate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          type="date"
                          className="col-12 input-box2"
                          id="lastDate"
                        />
                      </Grid>
                    </Grid>

                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        gap: "10px",
                      }}
                    >
                      {/* <Button
                    type="submit"
                    sx={[DialogBoxSaveButton, ButtonStyle, FontStyle]}
                  >
                    Apply
                  </Button> */}
                      <CustomButton
                        type="submit"
                        label="Apply"
                        Icon="filterIcon"
                      />
                      <Cancelbutton onClick={() => setShowFilter(null)} />
                    </Div>
                  </form>
                </Box>
              </Popover>

              {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                  <Typography
                    sx={[
                      componentTitle,
                      { color: Colors?.gray, fontWeight: 700 },
                    ]}
                  ></Typography>
                  <Div className="flex items-center justify-center gap-2">
                    <SelectDropdown
                      className="search-select3 "
                      defaultValue={"A-Z"}
                      onChange={sortData}
                      options={["A-Z", "Z-A"]}
                      width="70px"
                      clearButton={false}
                    />
                    {matches && (
                      <IconButton
                        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                      >
                        <FaGear style={{ fontSize: 18 }} />
                      </IconButton>
                    )}
                  </Div>

                  <CustomButton
                    onClick={() => handelAssign()}
                    label="Assign Lead Owner"
                    width="160px"
                    Icon=""
                    endIcon={true}
                  />
                </Div>
              </Grid>
            </Grid>
          </Box>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={AutoHeight(1.6)}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <List sx={{ mt: 0 }}>
              {datas?.length > 0 &&
                datas?.map((data, idx) => {
                  // if (idx < 2) {

                  let image =
                    (BASE_URL +
                      data?.File?.split("https://backend.crmfarm.app")[1] ||
                      data?.File) ??
                    "";

                  return (
                    <Div
                      // onMouseOver={() => setFadeEdit(idx)}
                      // onMouseLeave={() => setFadeEdit(null)}
                      className=""
                      key={idx}
                      sx={{
                        minHeight: "124px",
                        p: { xs: 0.5, sm: 2 },
                        px: { xs: 0.5, md: 7 },
                        my: 2,
                        bgcolor: "white",
                        borderRadius: { xs: "5px", md: "10px" },
                        ":hover": {
                          boxsShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
                        },
                        cursor: "default",
                      }}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        spacing={2}
                        sx={{ pr: "5%" }}
                      >
                        <Grid item xs={12} md={1.5}>
                          <div
                            style={{ marginTop: "6px" }}
                            onClick={() => {
                              let obj = {};
                              Object.entries(data)?.map(([key, value]) => {
                                columnMenuItems?.filter((item) => {
                                  if (item.sort === key) {
                                    if (
                                      item.sort === "Created_Date" ||
                                      item.sort === "Updated_Date"
                                    ) {
                                      obj[key] = new Date(data[key])
                                        ?.toISOString()
                                        .split("T")[0];
                                      return;
                                    }
                                    obj[key] = data[key];
                                  }
                                });
                              });
                              setOverview(obj);
                            }}
                          >
                            <AvatarCircle
                              size="90px"
                              fontSize="30px"
                              bgColor={avatarColorFun(idx)}
                              title={
                                !Boolean(data?.File) && data?.LeadFirstName
                              }
                              image={Boolean(data?.File) ? image : false}
                            />
                          </div>
                        </Grid>

                        <Grid container spacing={1} item xs={12} md={10.5}>
                          <Grid item xs={12}>
                            <Box
                              sx={[
                                DisplayFlex,
                                {
                                  justifyContent: "space-between",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Typography
                                sx={[
                                  {
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    color: `${Colors?.Mblue}`,
                                  },
                                ]}
                              >
                                {data?.LeadFirstName +
                                  " " +
                                  data?.LeadLastName ?? "No Name"}
                              </Typography>
                              <Div sx={[DisplayFlex]}>
                                <CustomButton
                                  onClick={(e) => {
                                    setOpenAnchor(e.target);
                                    setUpdate(data);
                                  }}
                                  sx={{ borderRadius: "25px" }}
                                  label="Action"
                                  bgColor={Colors?.Mblue}
                                  Icon="downArrowIcon"
                                  endIcon={true}
                                />
                              </Div>
                            </Box>
                          </Grid>
                          {columnMenuItems?.map((Menu, subIdx) => {
                            return (
                              <>
                                {Menu?.bool && (
                                  <Grid key={subIdx} item xs={12} md={4}>
                                    <Typography
                                      sx={[
                                        { fontSize: "14px", color: "#828282" },
                                      ]}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 700,
                                          color: "#5e5e5e",
                                        }}
                                      >
                                        {Menu?.label} :
                                      </span>{" "}
                                      {Menu?.sort === "Created_Date" ||
                                      Menu?.sort === "Updated_Date"
                                        ? formatDate(data[Menu?.sort])
                                        : data[Menu?.sort] ?? "-"}
                                    </Typography>
                                  </Grid>
                                )}
                              </>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Div>
                  );
                  // }
                })}
            </List>
          </JumboScrollbar>

          {/* gear options */}
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            sx={{
              mt: 5,
              maxHeight: "300px",
              scrollBehavior: "smooth",
            }}
          >
            <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
              Choose Fields
            </Typography>
            <FormGroup sx={{ p: 2 }}>
              {columnMenuItems?.length > 0 &&
                columnMenuItems?.map((column, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="info"
                          size="small"
                          checked={column?.bool}
                          onChange={(e) => {
                            let value = [...columnMenuItems];
                            value[index].bool = !column?.bool;
                            setColumnMenuItems(value);
                          }}
                        />
                      }
                      label={
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {column?.label}
                        </Typography>
                      }
                    />
                  );
                })}
            </FormGroup>
          </Menu>

          {/* openAnchor Action popover */}
          <Popover
            id={Boolean(openAnchor) ? "simple-popover" : undefined}
            open={Boolean(openAnchor)}
            anchorEl={openAnchor}
            onClose={() => {
              setOpenAnchor(null);
              // setIsLoading(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List sx={{ p: 1 }}>
              <ListItemButton
                onClick={() => {
                  setOpenAddLead(true);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: Colors?.Mblue,
                    minWidth: "25px !important",
                  }}
                >
                  <TiEdit
                    style={{
                      fontSize: 18,
                      fontWeight: 900,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={[FontStyle]}>Edit Social Media</Typography>
                  }
                />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  setNumber(update?.id);
                  setShowDelete(true);
                }}
                sx={{ borderRadius: "5px" }}
              >
                <ListItemIcon
                  sx={{ minWidth: "25px !important", color: "tomato" }}
                >
                  {CustomIcons?.deleteIcon}
                </ListItemIcon>
                <ListItemText
                  primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                />
              </ListItemButton>
            </List>
          </Popover>
        </Box>
        <Div
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Pagination
            color="success"
            count={Math.ceil(count / 10)}
            page={page}
            onChange={(e, newValue) => handleChangePage(e, newValue)}
            size="small"
            showFirstButton
            showLastButton
          />
        </Div>
      </Div>
      <DialogBox
        open={showDelete}
        Content={deletedWarnigMsg}
        onClickNo={() => setShowDelete(false)}
        onClickYes={() => {
          setShowDelete(false);
          Deletedata();
        }}
      />

      {/* overview */}
      <OverviewPage
        label={"Social Media"}
        datas={overview}
        open={Boolean(overview)}
        setOpen={setOverview}
        onClose={() => setOverview(false)}
      />

      <LeadFromSocialmediaEdit
        setOpenAddLead={setOpenAddLead}
        openAddLead={openAddLead}
        webupdate={update}
        getSocialListsRefetch={getSocialListsRefetch}
        anchorElAction={anchorElAction}
        setAnchorElAction={setAnchorElAction}
      />
    </>
  );
};

export default memo(LeadFromSocialmedia);
