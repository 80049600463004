import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiConfig } from "app/services/config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FontStyle } from "app/pages/widgets/CRMStyles";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";
import { BASE_URL } from "app/services/auth-services";

// const manufacturer = [
//   { format: "dd/mm/yyyy" },
//   { format: "dd-mm-yyyy" },
//   { format: "mm/dd/yyyy" },
//   { format: "mm-dd-yyyy" },
//   { format: "yyyy/mm/dd" },
//   { format: "yyyy-mm-dd" },
// ];


const manufacturer = [
  { label: 1, format: "yyyy-mm-dd" },
  { label: 2, format: "mm-dd-yyyy" },
  { label: 3, format: "dd-mm-yyyy" },
];





const SystemPreference = () => {
  const [date, setdate] = useState("");
  const [pass, setpass] = useState("");
  const [id, setid] = useState("");
  const [newuser, setnewuser] = useState(true);
  const [errors, setErrors] = useState({});


  const handleSubmit = (e) => {
    e.preventDefault();

    if (id === "" || id === undefined && id === null) {
      console.log("post")
      if (date === "" || date === undefined || date === null || pass === "" || pass === undefined || pass === null) {
        toast.error("Enter Valid data");
      } else {
        let playload = {
          DateFormat: date,
          Password: `${pass} days`,
          Created_By: parseInt(localStorage.getItem("UserId")),
          Updated_By: parseInt(localStorage.getItem("UserId"))
        }
        axios.post(`${BASE_URL}/useradmin/SystemPreferenceCRUD/`, playload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Db": localStorage.getItem("DBName")
          },
        })
          .then((response) => {
            console.log(response.data);
            toast.success("Created Successfully.")
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
          });
      }
    } else {
      if (date === "" || date === undefined || date === null || pass === "" || pass === undefined || pass === null) {
        toast.error("Enter Valid data");
      } else {
        let playload = {
          id: id,
          DateFormat: date,
          Password: `${pass} days`,
          Created_By: parseInt(localStorage.getItem("UserId")),
          Updated_By: parseInt(localStorage.getItem("UserId"))
        }
        console.log("playload", playload)
        axios.put(`${BASE_URL}/useradmin/SystemPreferenceCRUD/`, playload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Db": localStorage.getItem("DBName")
          },
        }).then((response) => {
          console.log(response.data)
          if (response?.data?.id === id) {
            toast.success("Updated Successfully");
          }
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
        });
      }

    }
  }


  const SystemPreferancerecords = async () => {
    if (id != null || id != undefined) {
      await axios.get(`${BASE_URL}/useradmin/SystemPreferenceCRUD/?id=3`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Db": localStorage.getItem("DBName")
        },
      }).then((response) => {
        console.log("response.data", response)
        if (response?.data?.id) {
          setdate(response?.data?.DateFormat)
          setpass(response?.data?.Password?.split(" ")[0])
          setid(response?.data?.id)
          setnewuser(false)
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
      });
    }
  }

  useEffect(() => {
    SystemPreferancerecords()
  }, [id]);



  return (
    <>
      <JumboContentLayoutMain>
        <Typography sx={[{ fontSize: "20px", fontWeight: 600 }]}>
          Setting
        </Typography>
        {/* <Typography sx={[{ fontSize: "18px", fontWeight: 600 }]}>
          Preferences
        </Typography> */}
        <Typography sx={[{ fontSize: "18px", fontWeight: 600 }]}>
          System Preferences
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ minHeight: "50vh" }}>
            <Grid container xs={10} spacing={1} rowSpacing={1} sx={{ mt: 2 }}>
              <Grid item xs={12} md={3} lg={3}>
                <Typography sx={[FontStyle]}>Date Format <span className="required"> * </span></Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  className="search-select3"
                  name="date"
                  options={manufacturer}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.format) {
                      return option?.format;
                    }
                    return "";
                  }}
                  value={date}
                  onChange={(e, newValue) => {
                    setdate(newValue?.format)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='date'
                      placeholder="Select Date Format"
                    />
                  )}
                />
                <Div style={{ color: "red" }}>{errors.date?.message}</Div>
              </Grid>
            </Grid>

            <Grid container xs={10} spacing={1} rowSpacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12} md={3} lg={3}>
                <Typography sx={[FontStyle]}>Password Expiry Days <span className="required"> * </span></Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  className="col-12 input-box2"
                  value={pass}
                  id="outlined-basic"
                  placeholder="Password expiry days"
                  variant="outlined"
                  name="pass"
                  onChange={(e) => { setpass(e.target.value) }}
                />
                <Div style={{ color: "red" }}>{errors.pass?.message}</Div>
              </Grid>
            </Grid>
          </Box>

          <Div sx={{ mt: 5 }}>
            <Div>
              {/* <Button type="submit" sx={[DialogBoxSaveButton, FontStyle, ButtonStyle, { mr: 1 }]} >
                Save
              </Button>
              <Button
                sx={[DialogBoxCancelButton, FontStyle, ButtonStyle]}
              >
                Cancel
              </Button> */}

              <CustomButton
                // onClick={RedirectToCreateRoles}
                label={newuser ? "Save" : "Update"}
                type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton
                label="Cancel"
              // onClick={() => {
              //   setShowCreateRoles(true);
              //   setShowConfigurePermission(false);
              // }}
              />

            </Div>
          </Div>
        </form>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>
  );
};

export default SystemPreference;
