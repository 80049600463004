import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
// import DoubleBarChart from './deals-right-side-card-list/DoubleBarChart';
import "./dashboard.css";
import * as React from "react";
import { PieChart, Pie, Cell, Legend } from "recharts";
import { useEffect, useState } from "react";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { indexof } from "stylis";
import {
  avatarColors,
  cardHeight,
  componentTitle,
  dialogTitle,
  DisplayFlex,
  FontStyle,
} from "../widgets/CRMStyles";
import Div from "@jumbo/shared/Div";
import DealPieChart from "./dashboardCharts/DealPieChart";
import PyramidDeals from "./dashboardCharts/PyramidDeals";
import DealBarChart from "./dashboardCharts/DealBarChart";

import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { GiDiscussion } from "react-icons/gi";
import { FcCallTransfer } from "react-icons/fc";
import { IoIosMailOpen } from "react-icons/io";
import { MdCallEnd } from "react-icons/md";
import Counter from "../widgets/StylesComponents";
import { useQuery, useQueryClient } from "react-query";
import '../leads/Lead.css';

const DashboardDeals = () => {
  const [pipeLineData, setPipeLineData] = useState([]);
  const [activities, setActivies] = useState([]);
  const [lastYearDealCount, setLastYearDealCount] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [datas, setDatas] = useState([]);

  const colors = [
    "#AA5024",
    "#D9672E",
    "#EF7133",
    "#FC8B53",
    "#FC854B",
    "#FF9F71",
  ];
  const dealsCounts = {
    yearToDate: 130,
    monthToDate: 10,
    sales: 2,
    openDeals: 45,
    closedDeals: 20,
  };
  const data = [
    { name: "Category 1", value: 300 },
    { name: "Category 2", value: 500 },
    // ... add more data entries
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"]; // Define your colors

  const size = {
    width: 400,
    height: 200,
  };

  // token and db name
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const requestOption = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  // my activities get data
  const myActivities = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/Dealdashboard/`,
        {},
        requestOption
      );
      if (Object.keys(response.data).length > 0) {
        console.log("Activies results : ", response.data);
        // setActivies(response.data);
        return response?.data;
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Last Year Deal Count get data
  const dealCount = async () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);

    // Set the start date to one year ago
    startDate.setFullYear(startDate.getFullYear() - 1);

    // Set the end date to today
    endDate.setHours(23, 59, 59, 999);

    const Start = startDate.toISOString().split("T")[0];
    const end = endDate.toISOString().split("T")[0];
    console.log("start :", Start, "  end : ", end);

    const requestOption = {
      headers: {
        db: item,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    let payLoad = { Created_Date: [Start, end] };

    try {
      const res = await axios.put(
        `${BASE_URL}/user/Dealdashboard/`,
        payLoad,
        requestOption
      );
      if (Object.keys(res.data).length > 0) {
        console.log("Activies results : ", res?.data);

        return [
          { name: "Year To Month", val: res.data?.Deal_Count_For_Year },
          { name: "Month To Date", val: res.data?.Deal_Count_For_Year },
          { name: "Sales", val: res.data?.Sales_Count },
          { name: "Open Deals", val: res.data?.Open_Deals },
          { name: "Closed Deals", val: res.data?.Closed_Deals },
        ];
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  // Line chart get data
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const lineChart = async () => {
    const currentDate = new Date();
    const CurrentDateRanges = [];
    const PreviousDateRanges = [];

    // Helper function to format month-year
    const formatMonthYear = (date) => {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
      return `${month}-${year}`;
    };

    // Current date range
    for (let i = 0; i < 12; i++) {
      const start = new Date(currentDate);
      const end = new Date(currentDate);

      start.setMonth(start.getMonth() - i);
      start.setDate(1);
      end.setMonth(end.getMonth() - i + 1);
      end.setDate(0);

      const startDate = start.toISOString().split("T")[0];
      const endDate = end.toISOString().split("T")[0];

      console.log("month date wise", startDate, endDate);

      const requestOption = {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.post(
          `${BASE_URL}/user/Dealdashboard1/`,
          { Created_Date: [startDate, endDate] },
          requestOption
        );

        if (Object.keys(res?.data).length > 0) {
          console.log(
            "linechart results ",
            startDate,
            "-",
            endDate,
            " : ",
            res?.data?.Monthwise_Deal_Count
          );

          CurrentDateRanges.push({
            date: new Date(startDate),
            value: res?.data?.Monthwise_Deal_Count,
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    // Previous date range
    for (let i = 0; i < 12; i++) {
      const start = new Date(currentDate);
      const end = new Date(currentDate);

      start.setMonth(start.getMonth() - (12 + i));
      start.setDate(1);
      end.setMonth(end.getMonth() - (11 + i));
      end.setDate(0);

      const startDate = start.toISOString().split("T")[0];
      const endDate = end.toISOString().split("T")[0];

      console.log("before 1 Year date wise", startDate, endDate);

      const requestOption = {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const res = await axios.post(
          `${BASE_URL}/user/Dealdashboard1/`,
          { Created_Date: [startDate, endDate] },
          requestOption
        );

        if (Object.keys(res?.data).length > 0) {
          console.log(
            "1 year before linechart results ",
            startDate,
            "-",
            endDate,
            " : ",
            res?.data?.Monthwise_Deal_Count
          );

          PreviousDateRanges.push({
            date: new Date(startDate),
            value: res?.data?.Monthwise_Deal_Count,
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    console.log(
      "line chart exact output : ",
      PreviousDateRanges,
      CurrentDateRanges
    );

    let dateValues = [];
    for (let i = 0; i < CurrentDateRanges.length; i++) {
      const currentFormattedDate = formatMonthYear(CurrentDateRanges[i]?.date);
      const previousFormattedDate = formatMonthYear(PreviousDateRanges[i]?.date);

      dateValues.unshift({
        name: currentFormattedDate,
        current: CurrentDateRanges[i]?.value,
        previous: PreviousDateRanges[i]?.value,
      });
    }

    console.log("dateValues", dateValues);
    // setLineChartData(dateValues);
    return dateValues;
  };


  const {
    data: getMyActivityLists,
    isLoading: getMyActivityListsLoading,
    isError: getMyActivityListsError,
    refetch: getMyActivityListsRefetch,
  } = useQuery(["getMyActivityLists"], myActivities, { staleTime: Infinity });

  const {
    data: getLineChartsLists,
    isLoading: getLineChartsListsLoading,
    isError: getLineChartsListsError,
    refetch: getLineChartsListsRefetch,
  } = useQuery(["getLineChartsLists"], lineChart, { staleTime: Infinity });

  const {
    data: getDealCountsLists,
    isLoading: getDealCountsListsLoading,
    isError: getDealCountsListsError,
    refetch: getDealCountsListsRefetch,
  } = useQuery(["getDealCountsLists"], dealCount, { staleTime: Infinity });

  useEffect(() => {
    if (getMyActivityLists) {
      setActivies([
        {
          label: "Total Mail Sent",
          value: getMyActivityLists?.Mail_Master_Count,
        },
        { label: "Calls Made", value: getMyActivityLists?.Call_Made_Count },
        {
          label: "Client Meetings",
          value: getMyActivityLists?.Client_Meeting_Count,
        },
      ]);
    }
  }, [getMyActivityLists]);

  useEffect(() => {
    if (getLineChartsLists) {
      setLineChartData(getLineChartsLists);
    }
  }, [getLineChartsLists]);

  useEffect(() => {
    if (getDealCountsLists) {
      setLastYearDealCount(getDealCountsLists);
    }
  }, [getDealCountsLists]);

  // console.log("pipeLineData : ", pipeLineData)
  console.log("activities : ", getMyActivityLists);
  console.log("lastYearDealCount : ", getDealCountsLists);
  console.log("line chart : ", getLineChartsLists);

  return (
    <>
      <JumboContentLayoutMain>
        <Typography sx={[componentTitle, { mb: 2 }]}>
          Deals Dashboard{" "}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Div
              sx={{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', p: 1, borderRadius: "10px" }}
            >
              <Div sx={{ py: 1 }}>
                <DealPieChart resource={lastYearDealCount} />
              </Div>
            </Div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Div 
              sx={{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', p: 1, borderRadius: "10px" }}
            >
              <Typography sx={[dialogTitle]}>Deals Pipeline</Typography>
              <Div sx={[DisplayFlex, { py: 1, justifyContent: "center" }]}>
                <PyramidDeals datas={datas} />
              </Div>
            </Div>
          </Grid>

          <Grid item xs={8} sm={8} md={6} lg={8}>
            <Div
              sx={{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', p: 1, borderRadius: "10px" }}
            >
              <Typography sx={[dialogTitle]}>Monthly Reports</Typography>
              <Div sx={{ py: 1 }}>
                <DealBarChart lineChartData={lineChartData} />
              </Div>
            </Div>
          </Grid>

          <Grid item xs={4} sm={12} md={6} lg={4}>
         <Div  sx={{ boxShadow:'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px', p: 1, borderRadius: "10px" }}>
         <div class="row mt-3">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12">
              <div class="card custom-card bg-primary-transparent border-0 shadow-none">
                <div class="card-body p-4">
                  <div class="d-flex align-items-center gap-3 flex-wrap">
                    <div class="main-card-icon primary">
                      <div
                        class="avatar avatar-lg bg-primary-transparent border border-primary border-opacity-10">
                        <div class="avatar avatar-sm svg-white">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div> <span class="d-block ,mb-1">TOTAL MAIL SENT</span>
                      <h5 class="fw-medium d-block mb-0">15<span
                        class="text-success fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                          class="ri-arrow-up-s-line align-middle mx-1"></i></span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12">
              <div class="card custom-card bg-secondary-transparent border-0 shadow-none">
                <div class="card-body p-4">
                  <div class="d-flex align-items-center gap-3 flex-wrap">
                    <div class="main-card-icon secondary">
                      <div
                        class="avatar avatar-lg bg-secondary-transparent border border-secondary border-opacity-10">
                        <div class="avatar avatar-sm svg-white">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-receipt-cutoff" viewBox="0 0 16 16">
                            <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5M11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                            <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div> <span class="d-block ,mb-1">CALLS MADE</span>
                      <h5 class="fw-medium d-block mb-0">2<span
                        class="text-danger fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                          class="ri-arrow-down-s-line align-middle mx-1"></i></span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12">
              <div class="card custom-card bg-success-transparent border-0 shadow-none">
                <div class="card-body p-4">
                  <div class="d-flex align-items-center gap-3 flex-wrap">
                    <div class="main-card-icon success">
                      <div
                        class="avatar avatar-lg bg-success-transparent border border-success border-opacity-10">
                        <div class="avatar avatar-sm svg-white">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-hand-index-thumb-fill" viewBox="0 0 16 16">
                            <path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716q.113.137.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div> <span class="d-block ,mb-1">CLIENT MEETINGS</span>
                      <h5 class="fw-medium d-block mb-0">5<span
                        class="text-success fw-semibold fs-12 ms-2 d-inline-block">Nos<i
                          class="ri-arrow-up-s-line align-middle mx-1"></i></span></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </Div>
          </Grid>
        </Grid>
      </JumboContentLayoutMain>
    </>
  );
};

export default DashboardDeals;
