import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Select,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import Setting from "../Setting";
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { TableBody } from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { apiConfig } from "app/services/config";
import {
  ButtonStyle,
  CRMTableHead,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

function DealPredictsetup() {
  const [datas, setdatas] = useState([]);
  const [rows, setrows] = useState([]);

  const createrId = localStorage.getItem("UserId");

  useEffect(() => {
    DealPredictionsetuprecords();
  }, []);

  const DealPredictionsetuprecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/UserAdminDealPredictionAdditional/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("prediction", res.data.results);
        setdatas(res.data.results);
        if (res.data.results.length !== 0) {
          let arr = res.data.results;
          let valueArr = [];

          arr.map((item, index) => {
            const newitem = {
              id: item.id,
              fromscore: item.ScoreRange[0],
              toscore: item.ScoreRange[1],
              prediction: item.prediction,
              allow: false,
            };
            valueArr.push(newitem);
          });
          console.log(valueArr);
          setrows(valueArr);

          // setrows({...rows,fromscore:res.data.ScoreRange[0]})
        } else {
          const newitem = {
            allow: true,
            fromscore: "",
            toscore: "",
            prediction: "",
          };
          setrows([...rows, newitem]);
        }
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  const handleAddRow = () => {
    const newitem = {
      fromscore: "",
      toscore: "",
      prediction: "",
      allow: true,
    };
    setrows([...rows, newitem]);
  };

  const handleRemoveRow = (index, data) => {
    handleDeleteprediction(data);
    setrows((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction = (data) => {
    console.log(data.id);
    if (data.id !== undefined) {
      axios
        .delete(
          `${BASE_URL}/useradmin/UserAdminDealPredictionCRUD/?id=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          }
        )
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            DealPredictionsetuprecords();
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  const handleAddChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setrows(updatedRows);
  };

  const handleSaveprediction = (e) => {
    e.preventDefault();
    let postarr = [];
    let putarr = [];
    rows.map((item, index) => {
      if (item.id === "" || item.id === null || item.id === undefined) {
        postarr.push(item);
      } else {
        putarr.push(item);
      }
    });
    console.log("postarr", postarr);
    console.log("putarr", putarr);
    postarr.map((item, ind) => {
      if (
        item.fromscore === "" ||
        item.fromscore === null ||
        item.fromscore === undefined ||
        item.fromscore === " " ||
        item.prediction === "" ||
        item.prediction === null ||
        item.prediction === undefined ||
        item.prediction === " " ||
        item.toscore === "" ||
        item.toscore === null ||
        item.toscore === undefined ||
        item.toscore === " "
      ) {
        toast.error("Enter Valid Details");
      } else {
        let playload = {
          ScoreRange: [item.fromscore, item.toscore],
          prediction: item.prediction,
          Recommended_Action: "High",
          Created_By: createrId,
          Updated_By: createrId,
        };
        console.log("playload", playload);
        axios
          .post(
            BASE_URL + "/useradmin/UserAdminDealPredictionCRUD/",
            playload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                Db: localStorage.getItem("DBName"),
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.id !== undefined) {
              toast.success(" Created Successfully");
              DealPredictionsetuprecords();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    });

    putarr.map((item, index) => {
      if (
        item.id === "" ||
        item.id === null ||
        item.id === undefined ||
        item.fromscore === "" ||
        item.fromscore === null ||
        item.fromscore === undefined ||
        item.fromscore === " " ||
        item.prediction === "" ||
        item.prediction === null ||
        item.prediction === undefined ||
        item.prediction === " " ||
        item.toscore === "" ||
        item.toscore === null ||
        item.toscore === undefined ||
        item.toscore === " "
      ) {
        toast.error("Enter Valid Details");
      } else {
        let playload = {
          id: item.id,
          ScoreRange: [item.fromscore, item.toscore],
          prediction: item.prediction,
          Recommended_Action: "High",
          Created_By: createrId,
          Updated_By: createrId,
        };
        console.log("playload1", playload);
        axios
          .put(
            `${BASE_URL}/useradmin/UserAdminDealPredictionCRUD/?id=${item.id}`,
            playload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            }
          )
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === item.id) {
              // toast.success("Updated Successfully");
              // Predictionrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    });
  };

  return (
    <>
      <JumboContentLayoutMain>
        <Setting />
        <Typography sx={[ComponentHeading]}>Deal Predict Setup</Typography>
        <form>
          <div>
            <Div
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: -3,
                mr: 0.5,
                height: "40px",
              }}
            ></Div>
            <div>
              <Div sx={{ m: 1, ml: 0, mr: 0, p: 2, minHeight: "366px" }}>
                <Div sx={{ display: "block" }}>
                  <form>
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMin={"55vh"}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                      id="no-more-tables"
                    >
                      <TableContainer>
                        <Table stickyHeader className="table" sx={{ mt: 2 }}>
                          <TableHead sx={[CRMTableHead, FontStyle]}>
                            <CRMTableRow>
                              <CRMTableCell>Score From</CRMTableCell>
                              <CRMTableCell>Score To</CRMTableCell>
                              <CRMTableCell>Predict Days</CRMTableCell>
                              <CRMTableCell>Action</CRMTableCell>
                            </CRMTableRow>
                          </TableHead>
                          <TableBody>
                            {rows.length > 0 &&
                              rows.map((data, index) => {
                                {
                                  /* console.log("data2 : ",data) */
                                }
                                return (
                                  <CRMTableRow key={index} hover>
                                    <CRMTableCell data-title="Score From">
                                      <TextField
                                        sx={{
                                          height: "35px",
                                          width: "220px",
                                          ml: 2,
                                          mt: 1,
                                        }}
                                        className="col-md-5 input-box"
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        name="fromscore"
                                        value={data.fromscore}
                                        onChange={(e) => {
                                          handleAddChange(index, e);
                                        }}
                                      />
                                    </CRMTableCell>

                                    <CRMTableCell data-title="Score To">
                                      <TextField
                                        sx={{
                                          height: "35px",
                                          width: "220px",
                                          mt: 1,
                                        }}
                                        className="col-md-5 input-box"
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        name="toscore"
                                        value={data.toscore}
                                        onChange={(e) => {
                                          handleAddChange(index, e);
                                        }}
                                      />
                                    </CRMTableCell>

                                    <CRMTableCell data-title="Predict Days">
                                      <TextField
                                        sx={{
                                          height: "35px",
                                          width: "140px",
                                          mt: 1,
                                        }}
                                        className="col-md-5 input-box"
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        name="prediction"
                                        value={data.prediction}
                                        onChange={(e) => {
                                          handleAddChange(index, e);
                                        }}
                                      />
                                    </CRMTableCell>

                                    <CRMTableCell data-title="Action">
                                      <Div>
                                        <Button
                                          color="error"
                                          onClick={(e) =>
                                            handleRemoveRow(index, data)
                                          }
                                        >
                                          <MdDelete size={24} />
                                        </Button>
                                      </Div>
                                    </CRMTableCell>
                                  </CRMTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </JumboScrollbar>
                    <Div sx={[DisplayFlex, { justifyContent: "flex-start" }]}>
                      <Button
                        sx={{ mt: 1, color: "#10BBE5" }}
                        onClick={handleAddRow}
                      >
                        + Add More Lines
                      </Button>
                    </Div>

                    <Div
                      sx={{
                        mt: 0,
                        justifyContent: "end",
                        alignItems: "center",
                        display: "flex",
                        mr: 5,
                        mb: 5,
                      }}
                    >
                      <CustomButton
                        onClick={handleSaveprediction}
                        label="Save"
                        type="submit"
                        Icon="saveIcon"
                        sx={{ mr: 1 }}
                      />
                      <Cancelbutton label="Cancel" />
                    </Div>
                  </form>
                </Div>
              </Div>
            </div>
          </div>
        </form>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>
  );
}

export default DealPredictsetup;
