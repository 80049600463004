import {
  Autocomplete,
  IconButton,
  List,
  Grid,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Popover,
  ListItemButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  DialogContent,
  FormControl,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import {
  deletedWarnigMsg,
  dialogFontSize,
  input_label,
} from "app/pages/widgets/CRMStyles";
import React, { useCallback, useEffect } from "react";
import * as yup from "yup";
import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiFilterAlt } from "react-icons/bi";
import { BASE_URL, Tenantname } from "app/services/auth-services";
import {
  CRMTableHead,
  Colors,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  componentTitle,
} from "app/pages/widgets/CRMStyles";
import { GrPowerReset } from "react-icons/gr";
import {
  AvatarCircle,
  Cancelbutton,
  DialogBox,
  CustomButton,
  Div,
  CustomIcons,
  SelectDropdown,
  avatarColorFun,
  Dragabledialog,
  Customgrid,
  formatDate,
  fetchTransactionSeries,
  CRMDateField,
  CRMCustomScrollTable,
  CRMCustomScrollCard,
} from "app/pages/widgets/StylesComponents";
import { useQuery, useQueryClient } from "react-query";
import { TiEdit } from "react-icons/ti";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { FaGear } from "react-icons/fa6";
import { RiMailSendLine } from "react-icons/ri";
import { MdOutlineNoteAdd } from "react-icons/md";
import { TbActivity } from "react-icons/tb";
import TodayIcon from "@mui/icons-material/Today";
import AppointmentModel from "app/pages/contacts/extra-components/AppointmentModel";
import ComposeEmail from "app/pages/contacts/extra-components/ComposeEmail";
import ActivityModel from "app/pages/contacts/extra-components/ActivityModel";
import NotesModel from "app/pages/contacts/extra-components/NotesModel";
import { OrganizationId, UserId } from "app/redux/auth/localStorage";
import SearchBar from "app/pages/widgets/SearchField";

const AccountSales = () => {
  const [openNotes, setOpenNotes] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [openAnchor, setOpenAnchor] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [fadeEdit, setFadeEdit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const [showAccounts, setShowAccounts] = useState("accounts");
  const [seriesValue, setSeriesValue] = useState("");
  const [Account_Id, setAccount_Id] = useState("");

  const pageRef = useRef(page);

  //create
  const [openAccounts, setOpenAccounts] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [accId, setaccId] = useState("");
  const [name1, setName1] = useState("");
  const [accountOwner1, setAccountOwner1] = useState("");
  const [landLine1, setLandLine1] = useState("");
  const [fax, setFax] = useState("");
  const [turnOver, setTurnOver] = useState("");
  const [employees, setEmployees] = useState("");
  const [code1, setCode1] = useState("");
  const [organisationId, setOrganisationId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [mail1, setMail1] = useState("");
  const [orgname1, setOrgname1] = useState("");
  const [reasonChurn, setreasonChurn] = useState("");
  const [selectedCountry1, setSelectedCountry1] = useState("");
  const [selectedState1, setSelectedState1] = useState("");
  const [industry1, setIndustry1] = useState("");
  const [mobilenumber1, setMobilenumber1] = useState("");
  const [selectedCity1, setSelectedCity1] = useState("");
  const [pincode1, setPincode1] = useState("");
  const [areaName1, setAreaName1] = useState("");
  const [streetName1, setStreetName1] = useState("");
  const [doorNo1, setDoorNo1] = useState("");
  const [PaymentTerms, setPaymentTerms] = useState("");
  const [industries, setIndustries] = useState([]);

  const [datas, setDatas] = useState([]);
  const [isSortName, setIsSortName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const loadingRef = useRef(Loading);
  const scrollRef = useRef(null);

  const [save, setSave] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [openeditAccount, setopeneditAccount] = useState(null);

  const [DeleteDatas, setDeleteDatas] = useState([]);
  const [listStatus, setListStatus] = useState(false);
  const [countryLists, setCountryLists] = useState([]);
  const [stateLists, setStateLists] = useState([]);
  const [cityLists, setCityLists] = useState([]);
  const [countryId, setCountryId] = useState("");

  const [showDial, setShowDial] = useState(false);
  const [calldata, setcalldata] = useState();
  const [statusc, setStatusc] = useState("");
  const [duration, setDuration] = useState(0);
  const [busy, setBusy] = useState("");

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Organization_Name", label: "Company Name", bool: true },
    { sort: "Account_Id", label: "Account Id", bool: true },
    { sort: "Phone_Number", label: "Mobile Number", bool: true },
    { sort: "Created_Date", label: "Date", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "Mail", label: "E-mail", bool: true },
    { sort: "Turn_Over", label: "Turn Over", bool: true },
    { sort: "Industry", label: "Industry Type", bool: false },
    { sort: "Account_Owner", label: "Account Owner", bool: false },
    { sort: "City", label: "City", bool: false },
    { sort: "State", label: "State", bool: false },
    { sort: "Country", label: "Country", bool: true },
  ]);

  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  //create
  const [code, setCode] = useState("");
  const [orgname, setOrgname] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const totalPages = Math?.ceil(count / 10);

  const [errors, setErrors] = useState({});
  console.log("Response checking $$$", datas);

  const CreatevalidateForm = () => {
    let err = {};

    if (Boolean(seriesValue !== "Automatic") && !Boolean(Account_Id)) {
      err.Account_Id = "Account Id is required";
    } else if (
      Boolean(seriesValue !== "Automatic") &&
      !Boolean(Account_Id?.length == 11)
    ) {
      err.Account_Id = "Account Id must be 11 digits.";
    }
    if (!Boolean(name1)) {
      err.Name = "Name is required";
    }
    if (!Boolean(code)) {
      err.countrycode = "Country Code is required";
    }
    if (!Boolean(mobilenumber1)) {
      err.mobileno = "Mobile No is required";
    }
    if (!Boolean(mail1)) {
      err.email = "Mail Id is required";
    }
    if (!Boolean(leadId)) {
      err.lead = "Lead is required";
    }
    if (!Boolean(orgname1)) {
      err.organization = "Organization is required";
    }
    if (!Boolean(industry1)) {
      err.industry = "Industry is required";
    }
    if (!Boolean(employees)) {
      err.employee = "Employers is required";
    }
    if (!Boolean(organisationId)) {
      err.gst = "Gst is required";
    }
    if (!Boolean(selectedCountry1)) {
      err.country = "Country is required";
    }
    if (!Boolean(selectedState1)) {
      err.state = "State is required";
    }
    if (!Boolean(selectedCity1)) {
      err.city = "City is required";
    }
    if (!Boolean(pincode1)) {
      err.pincode = "PinCode is required";
    }
    if (!Boolean(areaName1)) {
      err.areaname = "Area Name is required";
    }
    if (!Boolean(streetName1)) {
      err.streetname = "Street Name is required";
    }
    if (!Boolean(doorNo1)) {
      err.doorno = "Door No is required";
    }
    if (!Boolean(PaymentTerms)) {
      err.payment = "Payment Terms is required";
    }

    return err;
  };
  const editvalidateForm = () => {
    let err = {};
    if (!Boolean(name1)) {
      err.Name = "Name is required";
    }
    if (!Boolean(code1)) {
      err.countrycode = "Country Code is required";
    }
    if (!Boolean(mobilenumber1)) {
      err.mobileno = "Mobile No is required";
    }
    if (!Boolean(mail1)) {
      err.email = "Mail Id is required";
    }
    if (!Boolean(leadId)) {
      err.lead = "Lead is required";
    }
    if (!Boolean(orgname1)) {
      err.organization = "Organization is required";
    }
    if (!Boolean(industry1)) {
      err.industry = "Industry is required";
    }
    if (!Boolean(employees)) {
      err.employee = "Employers is required";
    }
    if (!Boolean(organisationId)) {
      err.gst = "Gst is required";
    }
    if (!Boolean(selectedCountry1)) {
      err.country = "Country is required";
    }
    if (!Boolean(selectedState1)) {
      err.state = "State is required";
    }
    if (!Boolean(selectedCity1)) {
      err.city = "City is required";
    }
    if (!Boolean(pincode1)) {
      err.pincode = "PinCode is required";
    }
    if (!Boolean(areaName1)) {
      err.areaname = "Area Name is required";
    }
    if (!Boolean(streetName1)) {
      err.streetname = "Street Name is required";
    }
    if (!Boolean(doorNo1)) {
      err.doorno = "Door No is required";
    }
    if (!Boolean(PaymentTerms)) {
      err.payment = "Payment Terms is required";
    }

    return err;
  };

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas]?.sort((a, b) =>
      value === "A-Z"
        ? a?.Contact_Name?.localeCompare(b?.Contact_Name)
        : b?.Contact_Name?.localeCompare(a?.Contact_Name)
    );
    setDatas(sortedData);
    setIsSortName(value);
  };

  const handleClickOverviewNotification = (data) => {
    navigate(`/app/accounts/overview/${data?.id}`);
    setShowAccounts(false);
  };

  const handleClickAction = (event, data) => {
    console.log("data123", data);
    setDeleteDatas(data);
    setaccId(data?.id);
    setAccountId(data?.Account_Id);
    setName1(data?.Contact_Name);
    setAccountOwner1(data?.Account_Owner);
    setLandLine1(data?.Land_Line);
    setCode1(data?.Country_Code);
    setreasonChurn(data?.ReasonForChurn);
    setMobilenumber1(data?.Phone_Number);
    setFax(data?.Fax);
    setTurnOver(data?.Turn_Over);
    setMail1(data?.Mail);
    setPaymentTerms(data?.Payment_Terms);
    setLeadId({ leadId: data?.Lead_Id, leadName: data?.Lead_Name });
    setOrgname1(data?.Website);
    setIndustry1(data?.Industry);
    setEmployees(data?.Employees);
    setOrganisationId(data?.Organization_Id);
    setSelectedCountry1(data?.Country);
    setSelectedState1(data?.State);
    setSelectedCity1(data?.City);
    setPincode1(data?.Address.Pincode);
    setDoorNo1(data?.Address.BuildingDoorNo);
    setStreetName1(data?.Address.StreetName);
    setAreaName1(data?.Address.AreaName);
    setAnchorElAction(event.currentTarget);

    setUpdate(data);
    setShowAccounts("edit");
  };

  const ClearData = () => {
    setName1("");
    setopeneditAccount("");
    setLandLine1("");
    setCode("");
    setFax("");
    setMail1("");
    setLeadId("");
    setOrgname1("");
    setIndustry1("");
    setEmployees("");
    setOrganisationId("");
    setSelectedCountry1("");
    setSelectedState1("");
    setSelectedCity1("");
    setPincode1("");
    setDoorNo1("");
    setStreetName1("");
    setAreaName1("");
    setPaymentTerms("");
  };

  // get products
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const dialogFilter = (event) => {
    setShowFilter(event.currentTarget);
  };

  // filter functionality
  const [indus, setIndus] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setToDate] = useState("");
  const [OrganizationName, setOrganizationName] = useState("");

  const RefreshData = () => {
    setOrganizationName("");
    setFromdate("");
    setToDate("");
    getAccountlist(page);
    setShowFilter(false);
  };

  const CreatAccount = async () => {
    let err = CreatevalidateForm();
    setErrors(CreatevalidateForm());

    if (Object.keys(err).length === 0) {
      let payload = {
        Account_Id: seriesValue === "Manual" && Account_Id,
        Contact_Name: name1,
        Account_Owner: localStorage.getItem("Username"),
        Phone_Number: mobilenumber1,
        Land_Line: landLine1,
        Mail: mail1,
        Fax: fax,
        Lead_Name: leadId.LeadFirstName,
        ReasonForChurn: reasonChurn || "",
        GST_No: organisationId,
        Payment_Terms: PaymentTerms,
        Organization_Name: orgname1,
        Decision_Maker: false,
        Turn_Over: turnOver,
        Employees: employees,
        Industry: industry1,
        Website: orgname1,
        Country: selectedCountry1?.name,
        Country_Code: code,
        State: selectedState1,
        City: selectedCity1,
        Location: selectedCity1,
        Address: {
          Pincode: pincode1,
          AreaName: areaName1,
          StreetName: streetName1,
          BuildingDoorNo: doorNo1,
        },
        LeadCheck: 0,
        Active_Flag: false,
        Status_Flag: "false",
        Is_Deleted: false,
        Created_Date: "2023-09-03",
        Updated_Date: "2023-09-03",
        Organization_Id: OrganizationId,
        Lead_Id: leadId?.id,
        Created_By: UserId,
        Updated_By: UserId,
      };
      console.log("payloadonsubmit", payload);
      await axios
        .post(`${BASE_URL}/user/AccountCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          setShowAccounts("accounts");
          ClearData();
          toast.success("Created successfully");
          setErrors({});
          setPage(1);
          getAccountlist(1);
          setOpenAccounts(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data?.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  const EditAccount = async () => {
    let err = editvalidateForm();
    setErrors(editvalidateForm());

    if (Object.keys(err).length === 0) {
      let payload = {
        id: accId,
        Account_Id: accountId,
        Contact_Name: name1,
        Account_Owner: accountOwner1,
        Phone_Number: mobilenumber1,
        Land_Line: landLine1,
        Mail: mail1,
        ReasonForChurn: reasonChurn || "",
        Fax: fax,
        Lead_Name: leadId?.leadName,
        GST_No: organisationId,
        Payment_Terms: PaymentTerms,
        Organization_Name: orgname1,
        Decision_Maker: false,
        Turn_Over: turnOver,
        Employees: employees,
        Industry: industry1,
        Website: orgname1,
        Country: selectedCountry1?.name,
        Country_Code: code1,
        State: selectedState1,
        City: selectedCity1,
        Location: selectedCity1,
        Address: {
          Pincode: pincode1,
          AreaName: areaName1,
          StreetName: streetName1,
          BuildingDoorNo: doorNo1,
        },
        LeadCheck: 0,
        Active_Flag: false,
        Status_Flag: "false",
        Is_Deleted: false,
        Lead_Id: leadId.leadId,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
      console.log("payloadonsubmit", payload);
      await axios
        .put(`${BASE_URL}/user/AccountCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          ClearData();
          toast.success("Edited successfully");
          setErrors({});
          setPage(1);
          getAccountlist(1);
          setOpenAccounts(false);
          setShowAccounts("accounts");
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data?.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  const deleteItem = async () => {
    try {
      await axios
        .delete(`${BASE_URL}/user/AccountCRUD/?id=${update?.id}`, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          toast.success("Deleted Sucessfully", {
            position: "top-right",
            autoClose: 100,
          });
          setDeleteDatas([]);
          setListStatus(!listStatus);
          setPage(1);
          getAccountlist(1);
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data?.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  const getCountryLists = async () => {
    await axios
      .get(`https://countriesnow.space/api/v0.1/countries`, {
        headers: {
          //   Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res?.data?.data);
        setCountryLists(res?.data?.data);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  const getStateLists = async () => {
    await axios
      .post(
        `https://countriesnow.space/api/v0.1/countries/states`,
        { country: selectedCountry },
        {
          headers: {
            //   Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res?.data?.data);
        var state = res?.data?.data;
        setStateLists(state?.states);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  const getCityLists = async () => {
    var payload = {
      country: selectedCountry,
      state: selectedState,
    };

    await axios
      .post(
        `https://countriesnow.space/api/v0.1/countries/state/cities`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res?.data);
        var city = res?.data?.data;
        var citynames = city?.map((cityname) => cityname);
        setCityLists(citynames);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  React.useEffect(() => {
    getCountryLists();
  }, [countryId]);

  React.useEffect(() => {
    getStateLists();
  }, [selectedCountry]);

  React.useEffect(() => {
    getCityLists();
  }, [selectedCountry, selectedState]);

  const getleadlist = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserQualifiedListAdditional/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      console.log("Qualifiedlist", response.data);
      return response.data;
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const getAccountlist = async (pageNum = 1) => {
    console.log("getAccountlist", pageNum);

    // if (count !== 0 && !(Math.ceil(count / 10) >= pageNum)) {
    //   return console.log("Out of Page");
    // }

    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/user/AccountAdditional/?Is_Deleted=False&page=${pageNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      console.log("AccountAdditional", response?.data);

      const newData = response?.data?.results || [];

      if (pageNum == 1) {
        setDatas(newData);
      } else {
        setDatas((prevDatas) => {
          const combinedData = [...prevDatas, ...newData];
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.Account_Id))
          ).map((id) => combinedData.find((item) => item.Account_Id === id));
          return uniqueData;
        });
      }

      setCount(response?.data?.count);
    } catch (error) {
      console.log(error.response.data);
    } finally {
      setLoading(false);
    }
  };
  const getMyAccountlist = async (pageNum = 1) => {
    console.log("getMyAccountlist", pageNum);

    // if (count !== 0 && !(Math.ceil(count / 10) >= pageNum)) {
    //   return console.log("Out of Page");
    // }

    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/user/AccountMyAdditional/?Created_By=${UserId}&page=${pageNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      console.log("AccountAdditional", response?.data);

      const newData = response?.data?.results || [];

      if (pageNum == 1) {
        setDatas(newData);
      } else {
        setDatas((prevDatas) => {
          const combinedData = [...prevDatas, ...newData];
          const uniqueData = combinedData?.filter((item, index, self) => {
            console.log(
              "selffffffff",
              self,
              index,
              self?.findIndex((data) => data?.id == item?.id)
            );

            return index == self?.findIndex((data) => data?.id == item?.id);
          });
          return uniqueData;
        });
      }

      setCount(response?.data?.count);
    } catch (error) {
      console.log(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const filterSubmit = async (e) => {
    e.preventDefault();

    try {
      let payload = {
        Organization_Name: OrganizationName?.trim(),
        Industry: indus?.trim(),
        Created_Date: [fromdate, todate],
      };
      const response = await axios.post(
        `${BASE_URL}/user/AccountFilter/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setDatas(response?.data);
      setCount(response?.data?.length);
      setShowFilter(false);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // get industry
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      response.data?.find((op) => {
        if (op?.Lookupname === "Industry Type") {
          setIndustries(op?.Values?.map((v) => v?.value) || []);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const [Qualifiedlead, setQualifiedlead] = useState([]);
  const [AccountLists, setAccountLists] = useState([]);

  const {
    data: QualifiedleadLists,
    isLoading: QualifiedleadListsLoading,
    isError: QualifiedleadListsError,
  } = useQuery("QualifiedleadLists", getleadlist, { staleTime: Infinity });

  const [knowlaritydatas, setKnowlaritydatas] = useState([]);

  // Search Functionality

  const SearchData = (e) => {
    Boolean(e.target.value !== "")
      ? handleSearch(e.target.value)
      : tabValue == 0
      ? getAccountlist(page)
      : getMyAccountlist(page);
  };

  const handleSearch = async (search) => {
    await axios
      .get(`${BASE_URL}/user/AccountSearch/?search=${search}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setDatas(res.data || []);
        setCount(res?.data?.length || 0);
      });
  };

  const getKnowlarityConfiguration = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res?.data", res.data);
        setKnowlaritydatas(res?.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  //  OnGoing Call API
  const onGoingCallApi = async (data, agentnum) => {
    let knopayload = {
      // k_number: "+918037125874",
      // agent_number: "+919344312970",
      // customer_number: "+918870791912",
      // caller_id: "+918040210001",
      // caller_name: datas?.Name,
      k_number: knowlaritydatas?.Knowlarity_Number,
      agent_number: agentnum?.country_code + agentnum?.phone,
      customer_number: "+" + data?.MobileCountry_Id + data?.Mobile_Number,
      caller_id: knowlaritydatas?.Knowlarity_CallerId,
      caller_name: data?.Name,
    };
    console.log("knopayload", knopayload);
    await axios
      .post(
        `https://kpi.knowlarity.com/Basic/v1/account/call/makecall`,
        knopayload,
        {
          headers: {
            // "Accept": "application/json",
            // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
            // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

            Accept: "application/json",
            "x-api-key": knowlaritydatas?.Header_Key,
            Authorization: knowlaritydatas?.API_Key,
          },
        }
      )
      .then((res) => {
        console.log("callres", res);
        setShowDial(true);
        setcalldata(data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const calllogs = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityCallLogsCRUD/?start_Date=2020-04-16&end_Date=&UserId=${localStorage.getItem(
          "UserId"
        )}&Module=Account`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("calllogsresss", res.data);
        const mynumberList = res?.data?.filter((item) =>
          item.Agent_Number.includes(userDetails?.PhoneNo)
        );

        const lastIndexData = mynumberList[0];
        console.log("lastIndexData", mynumberList, lastIndexData);

        setDuration(lastIndexData?.Call_Duration);

        if (lastIndexData?.Agent_Number?.includes("User Disconnected")) {
          setStatusc("User Disconnected");
        } else if (lastIndexData?.Agent_Number?.includes("Customer Missed")) {
          setStatusc("Customer Missed");
        } else {
          setStatusc("Connected");
        }
        setLoading(false);
        setAnchorEl(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const handleScroll = useCallback(async () => {
    console.log("scrollRef.current", scrollRef.current);
    const scrollTop =
      scrollRef.current.scrollTop || document.documentElement.scrollTop;
    const divHeight = scrollRef.current.clientHeight;
    const scrollHeight = scrollRef.current.scrollHeight;

    if (
      scrollTop > prevScrollTop &&
      scrollTop + divHeight >= scrollHeight - 5 &&
      !loadingRef.current &&
      pageRef.current < totalPages &&
      count > 0
    ) {
      setLoading(true);
      const nextPage = pageRef.current + 1;
      pageRef.current = nextPage; // Update the ref directly

      try {
        let response;
        if (OrganizationName || indus) {
          let payload = {
            Organization_Name: OrganizationName?.trim(),
            Industry: indus?.trim(),
            Created_Date: [fromdate, todate],
          };
          response = await axios.post(
            `${BASE_URL}/user/AccountFilter/`,
            payload,
            {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
        }

        const results = response?.data?.results || response?.results || [];
        const newCount = response?.data?.count || response?.count || count;

        setDatas((prevDatas) => {
          const newData = results || [];
          const combinedData = [...prevDatas, ...newData];
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.Account_Id))
          ).map((id) => combinedData.find((item) => item.Account_Id === id));
          return uniqueData;
        });
        setCount(newCount);
        setPage(nextPage);
      } catch (error) {
        console.error("Error during pagination:", error);
      } finally {
        setLoading(false);
      }
    }

    setPrevScrollTop(scrollTop);
  }, [prevScrollTop, OrganizationName, indus]);

  useEffect(() => {
    const divElement = scrollRef.current;
    divElement.addEventListener("scroll", handleScroll);

    return () => {
      divElement.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    loadingRef.current = Loading;
  }, [Loading]);

  useEffect(() => {
    if (Loading) {
      const timer = setTimeout(() => {
        calllogs();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [Loading]);

  useEffect(() => {
    getKnowlarityConfiguration();
  }, []);

  useEffect(() => {
    if (tabValue == 0) {
      getAccountlist(page);
    } else {
      getMyAccountlist(page);
    }
  }, [page, tabValue]);

  useEffect(() => {
    if (QualifiedleadLists) {
      setQualifiedlead(QualifiedleadLists);
    }
  }, [QualifiedleadLists]);

  console.log("AccountLists", AccountLists);

  useEffect(() => {
    getlookupData();
  }, []);

  useEffect(async () => {
    const series = await fetchTransactionSeries("Account");
    console.log("Account series", series);
    setSeriesValue(series);
  }, []);

  console.log("pagessss", page, datas);

  return (
    <>
      <JumboContentLayoutMain ref={scrollRef}>
        <ToastContainer />
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: showAccounts === "accounts" ? "" : "none",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Box className="flex items-center justify-between flex-wrap">
                <Typography
                  sx={[
                    componentTitle,
                    { color: Colors?.gray, fontWeight: 700 },
                  ]}
                >
                  Account List
                </Typography>

                <Div className="flex items-center justify-between ">
                  <SelectDropdown
                    className="search-select-border col-12"
                    defaultValue={"All Accounts"}
                    onChange={(e, value) => {
                      setPage(1);
                      setTabValue(value === "All Accounts" ? 0 : 1);
                      console.log("value", value);
                    }}
                    options={["All Accounts", "My Accounts"]}
                  />

                  <SelectDropdown
                    className="search-select-border"
                    defaultValue={"A-Z"}
                    onChange={sortData}
                    options={["A-Z", "Z-A"]}
                  />
                  {matches && (
                    <IconButton
                      onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    >
                      <FaGear style={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                </Div>
              </Box>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={10.5}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <IconButton
                    sx={{ color: Colors?.Mblue }}
                    onClick={dialogFilter}
                  >
                    <BiFilterAlt size={22} />
                  </IconButton>
                  <Typography
                    sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                  >
                    ( {count} ) Accounts
                  </Typography>
                </Grid>
                {!matches && (
                  <Grid item xs={1} md={1}>
                    <IconButton
                      onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    >
                      <FaGear style={{ fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                )}

                {/* Filter popover  */}

                <Popover
                  open={Boolean(showFilter)}
                  anchorEl={showFilter}
                  onClose={() => setShowFilter(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
                >
                  <Box>
                    <Div
                      sx={[
                        DisplayFlex,
                        CRMTableHead,
                        dialogTitle,
                        { px: 2, justifyContent: "space-between" },
                      ]}
                    >
                      <Typography sx={[dialogTitle]}>Filters</Typography>
                      <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                        <GrPowerReset size={16} />
                      </IconButton>
                    </Div>

                    <form style={{ padding: "1rem" }} onSubmit={filterSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Organization Name
                          </Typography>
                          <TextField
                            type="text"
                            className="col-12 input-box2"
                            autoComplete="off"
                            placeholder="Organization Name"
                            value={OrganizationName}
                            onChange={(e) => {
                              setOrganizationName(e.target.value);
                            }}
                            sx={{ mt: -0.5 }}
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Industry Type
                          </Typography>
                          <TextField
                            type="text"
                            className="col-12 input-box2"
                            autoComplete="off"
                            placeholder="Industry Type"
                            value={indus}
                            onChange={(e) => {
                              setIndus(e.target.value);
                            }}
                            sx={{ mt: -0.5 }}
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            From Date
                          </Typography>
                          <TextField
                            id="todate"
                            name="todate"
                            value={fromdate}
                            onChange={(e) => setFromdate(e.target.value)}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            To Date
                          </Typography>
                          <TextField
                            id="todate"
                            name="todate"
                            value={todate}
                            disabled={!Boolean(fromdate)}
                            inputProps={{
                              min: new Date(fromdate),
                            }}
                            onChange={(e) => setToDate(e.target.value)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          gap: "10px",
                        }}
                      >
                        {/* <Button
                  type="submit"
                  sx={[DialogBoxSaveButton, ButtonStyle, FontStyle]}
                >
                  Apply
                </Button> */}
                        <Cancelbutton onClick={() => setShowFilter(null)} />
                        <CustomButton
                          type="submit"
                          label="Apply"
                          Icon="filterIcon"
                        />
                      </Div>
                    </form>
                  </Box>
                </Popover>

                <Grid item xs={12} md={6}>
                  <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                    <SearchBar
                      SearchData={(e) => SearchData(e)}
                      width={"250px !important"}
                      color={"black"}
                      className=""
                    />
                    <CustomButton
                      onClick={() => setShowAccounts("create")}
                      label="Create"
                      Icon="createIcon"
                      endIcon={true}
                    />
                  </Div>
                </Grid>
              </Grid>
            </Box>
            <Div id="list">
              <CRMCustomScrollTable datas={datas} scrollRef={scrollRef}>
                {Loading && (
                  <div class="loader1" id="loader" style={{ zIndex: 9999 }}>
                    <CircularProgress />
                  </div>
                )}
                {datas?.length > 0 &&
                  datas?.map((data, idx) => {
                    let image =
                      (BASE_URL +
                        data?.File?.split("https://backend.crmfarm.app")[1] ||
                        data?.File) ??
                      "";
                    return (
                      <Div
                        onMouseOver={() => setFadeEdit(idx)}
                        onMouseLeave={() => setFadeEdit(null)}
                        className=""
                        key={idx}
                        sx={{
                          minHeight: "124px",
                          p: { xs: 0.5, sm: 2 },
                          px: { xs: 0.5, md: 7 },
                          my: 2,
                          bgcolor: "white",
                          borderRadius: { xs: "5px", md: "10px" },
                          ":hover": {
                            boxsShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
                          },
                          cursor: "default",
                        }}
                      >
                        <Grid
                          container
                          alignItems={"center"}
                          spacing={2}
                          sx={{ pr: "5%" }}
                        >
                          <Grid item xs={12} md={1.5}>
                            <div
                              style={{ marginTop: "6px" }}
                              onClick={() =>
                                handleClickOverviewNotification(data)
                              }
                            >
                              <AvatarCircle
                                size="90px"
                                fontSize="30px"
                                bgColor={avatarColorFun(idx)}
                                title={
                                  !Boolean(data?.File) && data?.Contact_Name
                                }
                                image={Boolean(data?.File) ? image : false}
                              />
                            </div>
                          </Grid>

                          <Grid container spacing={1} item xs={12} md={10.5}>
                            <Grid item xs={12}>
                              <Box
                                sx={[
                                  DisplayFlex,
                                  {
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "18px",
                                      fontWeight: 700,
                                      color: `${Colors?.Mblue}`,
                                    },
                                  ]}
                                >
                                  {data?.Contact_Name}
                                </Typography>
                                <Div sx={[DisplayFlex]}>
                                  <Div
                                    className={` ${
                                      idx === fadeEdit ? "fade-in-right" : ""
                                    } `}
                                    sx={{
                                      display: {
                                        xs: "none !important",
                                        sm:
                                          fadeEdit === idx
                                            ? "flex !important"
                                            : "none !important",
                                      },
                                    }}
                                  >
                                    <Div
                                      sx={[
                                        FontStyle,
                                        DisplayFlex,
                                        {
                                          cursor: "pointer",
                                          transform: "translateX(-50px)",
                                          color: Colors?.Mblue,
                                        },
                                      ]}
                                      onClick={(event) => {
                                        handleClickAction(event, data);
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: "25px !important",
                                          color: Colors?.Mblue,
                                        }}
                                      >
                                        <TiEdit
                                          style={{
                                            fontSize: 18,
                                            fontWeight: 900,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            sx={[
                                              FontStyle,
                                              { color: Colors?.Mblue },
                                            ]}
                                          >
                                            Edit Account
                                          </Typography>
                                        }
                                      />
                                    </Div>
                                  </Div>
                                  <CustomButton
                                    onClick={(e) => {
                                      setOpenAnchor(e.target);
                                      setUpdate(data);
                                    }}
                                    sx={{ borderRadius: "25px" }}
                                    label="Action"
                                    bgColor={Colors?.Mblue}
                                    Icon="downArrowIcon"
                                    endIcon={true}
                                  />
                                </Div>
                              </Box>
                            </Grid>
                            {columnMenuItems?.map(
                              (Menu, subIdx) =>
                                Menu?.bool && (
                                  <Grid key={subIdx} item xs={12} md={4}>
                                    <Typography
                                      sx={[
                                        { fontSize: "14px", color: "#828282" },
                                      ]}
                                    >
                                      <span
                                        style={{
                                          fontWeight: 700,
                                          color: "#5e5e5e",
                                        }}
                                      >
                                        {Menu?.label} :
                                      </span>{" "}
                                      {Menu?.sort === "Turn_Over"
                                        ? data[Menu?.sort]?.toLocaleString() +
                                            ".00" ?? 0.0
                                        : Menu?.label === "Date" ||
                                          Menu?.sort === "Created_Date" ||
                                          Menu?.sort === "Updated_Date"
                                        ? formatDate(data[Menu?.sort])
                                        : data[Menu?.sort] || "-"}
                                    </Typography>
                                  </Grid>
                                )
                            )}
                          </Grid>
                        </Grid>
                      </Div>
                    );
                  })}
              </CRMCustomScrollTable>
            </Div>
            {/* gear options */}
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
              sx={{
                mt: 5,
                maxHeight: "300px",
                scrollBehavior: "smooth",
              }}
            >
              <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
                Choose Fields
              </Typography>
              <FormGroup sx={{ p: 2 }}>
                {columnMenuItems?.length > 0 &&
                  columnMenuItems?.map((column, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            color="info"
                            size="small"
                            checked={column?.bool}
                            onChange={(e) => {
                              let value = [...columnMenuItems];
                              value[index].bool = !column?.bool;
                              setColumnMenuItems(value);
                            }}
                          />
                        }
                        label={
                          <Typography sx={[FontStyle]}>
                            {column?.label}
                          </Typography>
                        }
                      />
                    );
                  })}
              </FormGroup>
            </Menu>

            {/* openAnchor Action popover */}
            <Popover
              id={Boolean(openAnchor) ? "simple-popover" : undefined}
              open={Boolean(openAnchor)}
              anchorEl={openAnchor}
              onClose={() => {
                setOpenAnchor(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List sx={{ p: 1 }}>
                <ListItemButton
                  sx={[
                    FontStyle,
                    {
                      transform: {
                        xs: "translateX(0px)",
                        sm: "translateX(-50px)",
                      },
                      color: Colors?.Mblue,
                      display: {
                        sm: "none !important",
                        xs: "flex !important",
                      },
                    },
                  ]}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px !important",
                      color: Colors?.Mblue,
                    }}
                  >
                    <TiEdit
                      style={{ fontSize: { xs: 18, md: 25 }, fontWeight: 900 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Edit Contact</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenNotes(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <MdOutlineNoteAdd style={{ fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Notes</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenSendMail(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <RiMailSendLine style={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Send Mail</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenActivity(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TbActivity style={{ fontSize: 18 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Activity</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenAppointment(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TodayIcon sx={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Appointment</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setShowDelete(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "25px !important", color: "tomato" }}
                  >
                    {CustomIcons?.deleteIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                  />
                </ListItemButton>
              </List>
            </Popover>
          </Box>
        </Box>

        {/* Create Accounts */}
        {Boolean(showAccounts === "create") && (
          <Box>
            <Typography sx={[dialogFontSize, { mb: 3, mt: -2 }]}>
              Create Account
            </Typography>

            <Box
              sx={{
                bgcolor: "white",
                padding: { xs: 1, md: 4 },
                borderRadius: "5px",
                border: "1px solid lightgray",
              }}
            >
              <DialogContent sx={{ mt: 1 }}>
                <Customgrid container columnCount={4}>
                  <Grid item>
                    <Typography sx={[input_label]}> Account Id</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      value={Account_Id}
                      onChange={(e) => {
                        setAccount_Id(e.target.value);
                      }}
                      name="Account Id"
                      id="outlined-basic"
                      placeholder="# Account Id"
                      variant="outlined"
                      disabled={seriesValue == "Automatic" ? true : ""}
                    />

                    {errors?.Account_Id && (
                      <span style={{ color: "red" }}>{errors?.Account_Id}</span>
                    )}
                  </Grid>

                  <Grid item>
                    <Typography sx={[input_label]}>
                      Name <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      onChange={(e) => {
                        setName1(e.target.value);
                        if (Boolean(e.target.value)) {
                          const updatedPerson = { ...errors };
                          delete updatedPerson["Name"];
                          setErrors(updatedPerson);
                        }
                      }}
                      name="name"
                      id="outlined-basic"
                      placeholder="Enter your name"
                      variant="outlined"
                    />
                    {errors?.Name && (
                      <span style={{ color: "red" }}>{errors?.Name}</span>
                    )}
                  </Grid>

                  {/* Username */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Username <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      value={localStorage.getItem("Username")}
                      onChange={(e) => {
                        setAccountOwner1(localStorage.getItem("Username"));
                      }}
                      id="outlined-basic"
                      placeholder="Enter Account Owner"
                      variant="outlined"
                    />
                  </Grid>

                  {/* Landline and Country Code */}
                  <Grid item>
                    <Typography sx={[input_label]}>Landline</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      onChange={(e) => {
                        setLandLine1(e.target.value);
                        if (Boolean(e.target.value)) {
                          const updatedPerson = { ...errors };
                          delete updatedPerson["landline"];
                          setErrors(updatedPerson);
                        }
                      }}
                      name="landline"
                      id="outlined-basic"
                      placeholder="Enter Landline Number"
                      variant="outlined"
                    />
                    {errors?.landline && (
                      <span style={{ color: "red" }}>{errors?.landline}</span>
                    )}
                  </Grid>

                  {/* Mobile num */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Mobile Number <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={[DisplayFlex]}>
                      <TextField
                        name="code"
                        className={`col-2 input-box2 ${
                          errors.code ? "is-invalid" : ""
                        }`}
                        sx={{ width: "100px" }}
                        value={code}
                        placeholder="code"
                        autoFocus={code === ""}
                        disabled
                      />

                      <TextField
                        name="mobilenumber"
                        sx={{ width: "100%" }}
                        className="input-box2 "
                        value={mobilenumber1}
                        onChange={(e) => {
                          if (isNaN(e.target.value))
                            return toast.warn(
                              "Mobile number should only contain digits."
                            );
                          setMobilenumber1(e.target.value || 0);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["mobileno"];
                            setErrors(updatedPerson);
                          }
                        }}
                        id="outlined-basic"
                        placeholder="Enter Mobile Number"
                        variant="outlined"
                      />
                    </Div>
                    <Div style={{ color: "red" }}>
                      {errors?.countrycode && (
                        <span style={{ color: "red" }}>
                          {errors?.countrycode}
                        </span>
                      )}
                      {errors?.mobileno && (
                        <span style={{ color: "red" }}>{errors?.mobileno}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* fax and turnover */}
                  <Grid item>
                    <Typography sx={[input_label]}>Fax</Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        name="fax"
                        className="col-12  input-box2"
                        id="outlined-basic"
                        onChange={(e) => {
                          setFax(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["Fax"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Fax Number"
                        variant="outlined"
                      />
                      {errors?.Fax && (
                        <span style={{ color: "red" }}>{errors?.Fax}</span>
                      )}
                    </Div>
                  </Grid>

                  <Grid item>
                    <Typography sx={[input_label]}>Turn Over</Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2"
                        id="outlined-basic"
                        onChange={(e) => {
                          setTurnOver(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["turnover"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter your Turnover"
                        variant="outlined"
                      />
                      {errors?.turnover && (
                        <span style={{ color: "red" }}>{errors?.turnover}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* mailid  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      E-mail Id <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        name="mail"
                        className="col-12  input-box2"
                        onChange={(e) => {
                          setMail1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["email"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Mail Id"
                        variant="outlined"
                      />
                      {errors?.email && (
                        <span style={{ color: "red" }}>{errors?.email}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* lead */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Lead <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          name="industry"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.LeadFirstName) {
                              return option?.LeadFirstName;
                            }
                            return "";
                          }}
                          getOptionValue={(options) => {
                            return options["LeadFirstName"];
                          }}
                          options={Qualifiedlead}
                          onChange={(e, newValue) => {
                            console.log("setLeadId", newValue);
                            setLeadId(newValue);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["lead"];
                              setErrors(updatedPerson);
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Select Lead" />
                          )}
                        />
                        {errors?.lead && (
                          <span style={{ color: "red" }}>{errors?.lead}</span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* Reason for Churn */}
                  <Grid item>
                    <Typography sx={[input_label]}>Reason for Churn</Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          name="Reasonforchurn"
                          options={[
                            { value: "dissatisfaction with product" },
                            { value: "dissatisfaction with service" },
                            { value: "pricing concerns" },
                            { value: "poor customerservice" },
                          ]}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.value) {
                              return option?.value;
                            }
                            return "";
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.value === value || value === ""
                          }
                          value={reasonChurn}
                          onChange={(item, newValue) => {
                            setreasonChurn(newValue?.value);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["reasonChurn"];
                              setErrors(updatedPerson);
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Reason for churn"
                            />
                          )}
                        />
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* organisation  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Organization <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12  input-box2 "
                        id="outlined-basic"
                        onChange={(e) => {
                          setOrgname1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["organization"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Organisation Name"
                        variant="outlined"
                        autoFocus={orgname === ""}
                      />
                      {errors?.organization && (
                        <span style={{ color: "red" }}>
                          {errors?.organization}
                        </span>
                      )}
                    </Div>
                  </Grid>

                  {/* industry  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Industry <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          sx={{ width: "100%" }}
                          autocomplete="off"
                          options={industries}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option) {
                              return option;
                            }
                          }}
                          value={industry1}
                          onChange={(e, newValue) => {
                            console.log("industrynewValue", newValue);
                            setIndustry1(newValue);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["industry"];
                              setErrors(updatedPerson);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Industry"
                            />
                          )}
                        />
                        {errors?.industry && (
                          <span style={{ color: "red" }}>
                            {errors?.industry}
                          </span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* employers  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Employers <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        onChange={(e) => {
                          setEmployees(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["employee"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Number of Employers"
                        variant="outlined"
                      />
                      {errors?.employee && (
                        <span style={{ color: "red" }}>{errors?.employee}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Gst  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      GST No <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        onChange={(e) => {
                          setOrganisationId(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["gst"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter GST Number"
                        variant="outlined"
                      />
                      {errors?.gst && (
                        <span style={{ color: "red" }}>{errors?.gst}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Payment terms  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Payment Terms <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        value={PaymentTerms}
                        onChange={(e) => {
                          setPaymentTerms(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["payment"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Payment Terms"
                        variant="outlined"
                      />
                      {errors?.payment && (
                        <span style={{ color: "red" }}>{errors?.payment}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Country  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Country <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12 ">
                        <Autocomplete
                          className="search-select3 col-12"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={Country.getAllCountries() || []}
                          value={selectedCountry1}
                          onChange={(e, newValue) => {
                            console.log("codeeeeee", newValue);
                            setSelectedCountry(newValue);
                            setSelectedCountry1(newValue);
                            if (Boolean(newValue)) {
                              setCode(newValue?.phonecode);
                              const updatedPerson = { ...errors };
                              delete updatedPerson["country"];
                              delete updatedPerson["countrycode"];
                              setErrors(updatedPerson);
                            } else {
                              setCode("");
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />

                        {errors?.country && (
                          <span style={{ color: "red" }}>
                            {errors?.country}
                          </span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* state  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      State <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          value={selectedState1}
                          options={
                            State.getStatesOfCountry(
                              selectedCountry?.isoCode
                            ) || []
                          }
                          onChange={(e, newValue) => {
                            console.log("statenewValue", newValue);
                            setSelectedState(newValue);
                            setSelectedState1(newValue?.name);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["state"];
                              setErrors(updatedPerson);
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value || value === ""
                          }
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="State" />
                          )}
                          disabled={!Boolean(selectedCountry1)}
                        />
                        {errors?.state && (
                          <span style={{ color: "red" }}>{errors?.state}</span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* City  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      City <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={
                            City.getCitiesOfState(
                              selectedState?.countryCode,
                              selectedState?.isoCode
                            ) || []
                          }
                          value={selectedCity1}
                          onChange={(e, newValue) => {
                            setSelectedCity(newValue);
                            setSelectedCity1(newValue?.name);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["city"];
                              setErrors(updatedPerson);
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="City" />
                          )}
                          disabled={
                            !Boolean(selectedCountry1) ||
                            !Boolean(selectedState1)
                          }
                        />
                        {errors?.city && (
                          <span style={{ color: "red" }}>{errors?.city}</span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* pincode and door No  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Pin Code <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        onChange={(e) => {
                          setPincode1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["pincode"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Pincode"
                        variant="outlined"
                        autoFocus={pincode1 === ""}
                      />
                      {errors?.pincode && (
                        <span style={{ color: "red" }}>{errors?.pincode}</span>
                      )}
                    </Div>
                  </Grid>

                  <Grid item>
                    <Typography sx={[input_label]}>
                      Door No <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2"
                        id="outlined-basic"
                        onChange={(e) => {
                          setDoorNo1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["doorno"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Door Number  "
                        variant="outlined"
                        autoFocus={doorNo1 === ""}
                      />
                      {errors?.doorno && (
                        <span style={{ color: "red" }}>{errors?.doorno}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Street Name  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Sreet Name <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        value={streetName1}
                        onChange={(e) => {
                          setStreetName1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["streetname"];
                            setErrors(updatedPerson);
                          }
                        }}
                        id="outlined-basic"
                        placeholder="Enter your Street Name"
                        variant="outlined"
                      />
                      {errors?.streetname && (
                        <span style={{ color: "red" }}>
                          {errors?.streetname}
                        </span>
                      )}
                    </Div>
                  </Grid>

                  {/* Area Name  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Area Name <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12  input-box2 "
                        value={areaName1}
                        name="areaName1"
                        onChange={(e) => {
                          setAreaName1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["areaname"];
                            setErrors(updatedPerson);
                          }
                        }}
                        id="outlined-basic"
                        placeholder="Enter your Area Name"
                        variant="outlined"
                      />
                      {errors?.areaname && (
                        <span style={{ color: "red" }}>{errors?.areaname}</span>
                      )}
                    </Div>
                  </Grid>
                </Customgrid>

                <Grid container align="center" mt={2} spacing={2} pb={2}>
                  <Grid item align="right" xs={6}>
                    <CustomButton
                      label="Save"
                      Icon="saveIcon"
                      onClick={CreatAccount}
                    />
                  </Grid>
                  <Grid item align="left" xs={6}>
                    <Cancelbutton onClick={() => setSave("create")} />
                  </Grid>
                </Grid>
              </DialogContent>
            </Box>
          </Box>
        )}

        {/* Edit Accounts */}
        {Boolean(showAccounts === "edit") && (
          <Box>
            <Typography sx={[dialogFontSize, { mb: 3, mt: -2 }]}>
              Edit Account
            </Typography>

            <Box
              sx={{
                bgcolor: "white",
                padding: { xs: 1, md: 4 },
                borderRadius: "5px",
                border: "1px solid lightgray",
              }}
            >
              <DialogContent sx={{ mt: 1 }}>
                <Customgrid container columnCount={4}>
                  {/*  Account Id */}
                  <Grid item>
                    <Typography sx={[input_label]}> Account Id</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      value={accountId}
                      name="Account Id"
                      id="outlined-basic"
                      placeholder="# Account Id"
                      variant="outlined"
                      disabled
                    />
                  </Grid>

                  <Grid item>
                    <Typography sx={[input_label]}>
                      Name <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      onChange={(e) => {
                        setName1(e.target.value);
                        if (Boolean(e.target.value)) {
                          const updatedPerson = { ...errors };
                          delete updatedPerson["Name"];
                          setErrors(updatedPerson);
                        }
                      }}
                      value={name1}
                      name="name"
                      id="outlined-basic"
                      placeholder="Enter your name"
                      variant="outlined"
                    />

                    {errors?.Name && (
                      <span style={{ color: "red" }}>{errors?.Name}</span>
                    )}
                  </Grid>

                  {/* Username */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Username <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      value={accountOwner1}
                      onChange={(e) => {
                        setAccountOwner1(e.target.value);
                      }}
                      id="outlined-basic"
                      placeholder="Enter Account Owner"
                      variant="outlined"
                    />
                  </Grid>

                  {/* Landline and Country Code */}
                  <Grid item>
                    <Typography sx={[input_label]}>Landline</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="col-12 input-box2"
                      value={landLine1}
                      onChange={(e) => {
                        setLandLine1(e.target.value);
                        if (Boolean(e.target.value)) {
                          const updatedPerson = { ...errors };
                          delete updatedPerson["landline"];
                          setErrors(updatedPerson);
                        }
                      }}
                      name="landline"
                      id="outlined-basic"
                      placeholder="Enter Landline Number"
                      variant="outlined"
                    />
                    {errors?.landline && (
                      <span style={{ color: "red" }}>{errors?.landline}</span>
                    )}
                  </Grid>

                  {/* Mobile num */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Mobile Number <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={[DisplayFlex]}>
                      <TextField
                        name="code"
                        className="input-box2 col-12"
                        sx={{ width: "100px" }}
                        value={code1}
                        placeholder="code"
                        autoFocus={code1 === ""}
                        disabled
                      />

                      <TextField
                        name="mobilenumber"
                        className="input-box2"
                        style={{ width: "100%" }}
                        value={mobilenumber1}
                        onChange={(e) => {
                          if (isNaN(e.target.value))
                            return toast.warn(
                              "Mobile number should only contain digits."
                            );
                          setMobilenumber1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["mobileno"];
                            setErrors(updatedPerson);
                          }
                        }}
                        id="outlined-basic"
                        placeholder="Enter Mobile Number"
                        variant="outlined"
                      />
                    </Div>
                    <Div style={{ color: "red" }}>
                      {errors?.countrycode && (
                        <span style={{ color: "red" }}>
                          {errors?.countrycode}
                        </span>
                      )}
                      {errors?.mobileno && (
                        <span style={{ color: "red" }}>{errors?.mobileno}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* fax and turnover */}
                  <Grid item>
                    <Typography sx={[input_label]}>Fax</Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        name="fax"
                        className="col-12  input-box2"
                        id="outlined-basic"
                        value={fax}
                        onChange={(e) => {
                          setFax(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["Fax"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Fax Number"
                        variant="outlined"
                      />
                      {errors?.Fax && (
                        <span style={{ color: "red" }}>{errors?.Fax}</span>
                      )}
                    </Div>
                  </Grid>

                  <Grid item>
                    <Typography sx={[input_label]}>Turn Over</Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2"
                        id="outlined-basic"
                        value={turnOver}
                        onChange={(e) => {
                          setTurnOver(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["turnover"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter your Turnover"
                        variant="outlined"
                      />
                      {errors?.turnover && (
                        <span style={{ color: "red" }}>{errors?.turnover}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* mailid  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      E-mail Id <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        name="mail"
                        className="col-12  input-box2"
                        value={mail1}
                        onChange={(e) => {
                          setMail1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["email"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Mail Id"
                        variant="outlined"
                      />
                      {errors?.email && (
                        <span style={{ color: "red" }}>{errors?.email}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* lead */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Lead <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <Autocomplete
                        className="search-select3 col-12"
                        name="industry"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.LeadFirstName) {
                            return option?.LeadFirstName;
                          }
                          return "";
                        }}
                        getOptionValue={(options) => {
                          return options["LeadFirstName"];
                        }}
                        options={Qualifiedlead}
                        value={leadId?.leadName}
                        onChange={(item, newValue) => {
                          setLeadId({
                            leadId: newValue?.id,
                            leadName: newValue?.LeadFirstName,
                          });
                          if (Boolean(newValue)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["lead"];
                            setErrors(updatedPerson);
                          }
                        }}
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Lead" />
                        )}
                      />
                      {errors?.lead && (
                        <span style={{ color: "red" }}>{errors?.lead}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Reason for Churn */}
                  <Grid item>
                    <Typography sx={[input_label]}>Reason for Churn</Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          name="Reasonforchurn"
                          options={[
                            { value: "dissatisfaction with product" },
                            { value: "dissatisfaction with service" },
                            { value: "pricing concerns" },
                            { value: "poor customerservice" },
                          ]}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.value) {
                              return option?.value;
                            }
                            return "";
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.value === value || value === ""
                          }
                          value={reasonChurn}
                          onChange={(item, newValue) => {
                            setreasonChurn(newValue?.value);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["reasonChurn"];
                              setErrors(updatedPerson);
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Reason for churn"
                            />
                          )}
                        />
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* organisation  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Organization <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12  input-box2 "
                        id="outlined-basic"
                        value={orgname1}
                        onChange={(e) => {
                          setOrgname1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["organization"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Organisation Name"
                        variant="outlined"
                        autoFocus={orgname === ""}
                      />
                      {errors?.organization && (
                        <span style={{ color: "red" }}>
                          {errors?.organization}
                        </span>
                      )}
                    </Div>
                  </Grid>

                  {/* industry  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Industry <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          // {...register('bookingType',)}
                          className="search-select3 col-12"
                          sx={{ width: "100%" }}
                          autocomplete="off"
                          options={industries}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option) {
                              return option;
                            }
                          }}
                          value={industry1}
                          onChange={(item, newValue) => {
                            console.log("industrynewValue", newValue);
                            setIndustry1(newValue);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["industry"];
                              setErrors(updatedPerson);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Industry"
                            />
                          )}
                        />

                        {errors?.industry && (
                          <span style={{ color: "red" }}>
                            {errors?.industry}
                          </span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* employers  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Employers <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        value={employees}
                        onChange={(e) => {
                          setEmployees(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["employee"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Number of Employers"
                        variant="outlined"
                      />
                      {errors?.employee && (
                        <span style={{ color: "red" }}>{errors?.employee}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Gst  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      GST No <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        value={organisationId}
                        onChange={(e) => {
                          setOrganisationId(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["gst"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter GST Number"
                        variant="outlined"
                      />
                      {errors?.gst && (
                        <span style={{ color: "red" }}>{errors?.gst}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Payment terms  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Payment Terms <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        value={PaymentTerms}
                        onChange={(e) => {
                          setPaymentTerms(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["payment"];
                            setErrors(updatedPerson);
                          }
                        }}
                        placeholder="Enter Payment Terms"
                        variant="outlined"
                      />
                      {errors?.payment && (
                        <span style={{ color: "red" }}>{errors?.payment}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Country  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Country <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12 ">
                        <Autocomplete
                          className="search-select3 col-12"
                          name="selectedCountry"
                          // className="react-select-box"
                          // getOptionLabel={(options) => {
                          //   return options["name"];
                          // }}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          getOptionValue={(options) => {
                            return options["name"];
                          }}
                          options={Country.getAllCountries()}
                          value={selectedCountry1}
                          onChange={(e, newValue) => {
                            setSelectedCountry1(newValue);
                            if (Boolean(newValue)) {
                              setCode1(newValue?.phonecode);
                              const updatedPerson = { ...errors };
                              delete updatedPerson["country"];
                              delete updatedPerson["countrycode"];
                              setErrors(updatedPerson);
                            } else {
                              setCode1("");
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />
                        {errors?.country && (
                          <span style={{ color: "red" }}>
                            {errors?.country}
                          </span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* state  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      State <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          name="selectedState"
                          options={State.getStatesOfCountry(
                            selectedCountry1?.isoCode
                          )}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          getOptionValue={(options) => {
                            return options["name"];
                          }}
                          value={selectedState1}
                          onChange={(e, newValue) => {
                            console.log("statenewValue", newValue);
                            setSelectedState(newValue);
                            setSelectedState1(newValue?.name);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["state"];
                              setErrors(updatedPerson);
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="state" />
                          )}
                          disabled={!Boolean(selectedCountry1)}
                        />
                        {errors?.state && (
                          <span style={{ color: "red" }}>{errors?.state}</span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* City  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      City <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <FormControl className="col-12">
                        <Autocomplete
                          className="search-select3 col-12"
                          name="selectedCity"
                          options={City?.getCitiesOfState(
                            selectedCountry1?.isoCode,
                            selectedState1?.isoCode
                          )}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          getOptionValue={(options) => {
                            return options["name"];
                          }}
                          value={selectedCity1}
                          onChange={(e, newValue) => {
                            setSelectedCity(newValue);
                            setSelectedCity1(newValue?.name);
                            if (Boolean(newValue)) {
                              const updatedPerson = { ...errors };
                              delete updatedPerson["city"];
                              setErrors(updatedPerson);
                            }
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="City" />
                          )}
                          disabled={
                            !Boolean(selectedCountry1) ||
                            !Boolean(selectedState1)
                          }
                        />
                        {errors?.city && (
                          <span style={{ color: "red" }}>{errors?.city}</span>
                        )}
                      </FormControl>
                    </Div>
                  </Grid>

                  {/* pincode and door No  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Pin Code <span className="required">*</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2 "
                        id="outlined-basic"
                        onChange={(e) => {
                          setPincode1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["pincode"];
                            setErrors(updatedPerson);
                          }
                        }}
                        value={pincode1}
                        placeholder="Enter Pincode"
                        variant="outlined"
                        autoFocus={pincode1 === ""}
                      />
                      {errors?.pincode && (
                        <span style={{ color: "red" }}>{errors?.pincode}</span>
                      )}
                    </Div>
                  </Grid>

                  <Grid item>
                    <Typography sx={[input_label]}>
                      Door No <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12 input-box2"
                        id="outlined-basic"
                        onChange={(e) => {
                          setDoorNo1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["doorno"];
                            setErrors(updatedPerson);
                          }
                        }}
                        value={doorNo1}
                        placeholder="Enter Door Number  "
                        variant="outlined"
                        autoFocus={doorNo1 === ""}
                      />
                      {errors?.doorno && (
                        <span style={{ color: "red" }}>{errors?.doorno}</span>
                      )}
                    </Div>
                  </Grid>

                  {/* Street Name  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Sreet Name <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12  input-box2 "
                        onChange={(e) => {
                          setStreetName1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["streetname"];
                            setErrors(updatedPerson);
                          }
                        }}
                        value={streetName1}
                        id="outlined-basic"
                        placeholder="Enter your Street Name"
                        variant="outlined"
                      />
                      {errors?.streetname && (
                        <span style={{ color: "red" }}>
                          {errors?.streetname}
                        </span>
                      )}
                    </Div>
                  </Grid>

                  {/* Area Name  */}
                  <Grid item>
                    <Typography sx={[input_label]}>
                      Area Name <span className="required">*</span>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Div sx={{ width: "100%" }}>
                      <TextField
                        className="col-12  input-box2 "
                        value={areaName1}
                        name="areaName1"
                        onChange={(e) => {
                          setAreaName1(e.target.value);
                          if (Boolean(e.target.value)) {
                            const updatedPerson = { ...errors };
                            delete updatedPerson["areaname"];
                            setErrors(updatedPerson);
                          }
                        }}
                        id="outlined-basic"
                        placeholder="Enter your Area Name"
                        variant="outlined"
                      />
                      {errors?.areaname && (
                        <span style={{ color: "red" }}>{errors?.areaname}</span>
                      )}
                    </Div>
                  </Grid>
                </Customgrid>

                <Grid container align="center" mt={4} spacing={2} pb={2}>
                  <Grid item align="right" xs={6}>
                    <CustomButton
                      label="Update"
                      Icon="saveIcon"
                      onClick={EditAccount}
                    />
                  </Grid>
                  <Grid item align="left" xs={6}>
                    <Cancelbutton onClick={() => setSave("edit")} />
                  </Grid>
                </Grid>
              </DialogContent>
            </Box>
          </Box>
        )}

        {/* Add Note Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openNotes}
          // onClose={() => setOpenNotes(false)}
        >
          <NotesModel
            name={{ label: "Account Name", value: update?.Contact_Name }}
            endpoint={"AccountNotesCRUD"}
            historyEndpoint={{
              point: "AccountHistorycrud",
              payload: { Account_Id: update?.id },
            }}
            extraPayload={{ Account_Id: update?.id }}
            data={update}
            setOpenNotes={setOpenNotes}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Activities Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openActivity}
          // onClose={() => setOpenActivity(false)}
        >
          <ActivityModel
            name={{ label: "Account Name", value: update?.Contact_Name }}
            endpoint={"AccountActivitiesCRUD"}
            historyEndpoint={{
              point: "AccountHistorycrud",
              payload: { Account_Id: update?.id },
            }}
            extraPayload={{ Account_Id: update?.id }}
            data={update}
            setOpenActivity={setOpenActivity}
            setOpen={setSave}
            getData={getAccountlist}
          />
        </Dragabledialog>

        {/* Add Appointment Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openAppointment}
          // onClose={() => setOpenAppointment(false)}
        >
          <AppointmentModel
            name={{ label: "Account Name", value: update?.Contact_Name }}
            historyEndpoint={{
              point: "AccountHistorycrud",
              payload: { Account_Id: update?.id },
            }}
            extraPayload={{ Account_Id: update?.id }}
            endpoint={"AccountMeetingCRUD"}
            data={update}
            setOpenAppointment={setOpenAppointment}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Send Mail Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openSendMail}
          // onClose={() => setOpenSendMail(false)}
        >
          <ComposeEmail
            extraPayload={{ Account_Id: update?.id }}
            endpoint={"AccountMailMasterCRUD"}
            data={update}
            setOpenSendMail={setOpenSendMail}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* delete popup */}

        <DialogBox
          open={showDelete}
          Content={deletedWarnigMsg}
          onClickNo={() => setShowDelete(false)}
          onClickYes={() => {
            // setSave(false);
            deleteItem();
            setShowDelete(false);
            // reset();
            setOpenAnchor(null);
          }}
        />

        {/* Close dialog box */}
        <DialogBox
          open={save}
          onClickNo={() => setSave(false)}
          onClickYes={() => {
            switch (save) {
              case "note":
                setOpenNotes(false);
                break;
              case "activity":
                setOpenActivity(false);
                break;
              case "appointment":
                setOpenAppointment(false);
                break;
              case "email":
                setOpenSendMail(false);
                break;
              case "accounts":
                setShowAccounts("accounts");
                break;
              case "create":
                setShowAccounts("accounts");
                setErrors({});
                break;
              case "edit":
                setShowAccounts("accounts");
                setErrors({});
                break;
            }
            setSave(false);
          }}
        />
      </JumboContentLayoutMain>
    </>
  );
};

export default AccountSales;
