import React, { createContext, useContext, useEffect, useState } from "react";
import { chatUsersList } from "../api/chatAPI";

// Create the Chat context
const ChatContext = createContext();

// Export the custom hook to use the context
export const useChatContext = () => {
  const context = useContext(ChatContext);

  if (!context) {
    console.warn("useChatContext must be used within a ChatProvider");
  }
  return context;
};

export const ChatProvider = ({ children }) => {
  const [usersList, setUsersList] = useState([]);
  const [selectUser, setSelectUser] = useState({});
  const [conversation, setConversations] = useState([]);
  const UserId = localStorage.getItem("UserId");

  const fetchUsers = async () => {
    try {
      const users = await chatUsersList(UserId);
      // console.log(" fetching chat users list : ", users);
      setUsersList(users);
    } catch (error) {
      console.error("Error fetching chat users list:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <ChatContext.Provider
      value={{
        usersList,
        setUsersList,
        fetchUsers,
        selectUser,
        setSelectUser,
        conversation,
        setConversations,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
