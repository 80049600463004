// addons state
const addonsFilterState = {
  Name: "",
  NoofUsers: "",
  Price: "",
  Fromdate: "",
  Todate: "",
};

// storage state
const storageFilterState = {
  Name: "",
  StorageSize: "",
  Price: "",
  Fromdate: "",
  Todate: "",
};

// plan state
const planFilterState = {
  Name: "",
  Country: "",
  Code: "",
  Fromdate: "",
  Todate: "",
};

// subscription state
const subscriptionFilterState = {
  Name: "",
  Customer_Name: "",
  Plan_Name: "",
  Fromdate: "",
  Todate: "",
};

// invoice state
const invoiceFilterState = {
  InvoiceAmount: "",
  TaxAmount: "",
  NetAmount: "",
  Fromdate: "",
  Todate: "",
};

// payment state
const paymentFilterState = {
  Customer_Name: "",
  PaymentMode: "",
  PaidAmount: "",
  Fromdate: "",
  Todate: "",
};

// instance request state
const RequestFilterState = {
  Name: "",
  Customer_Name: "",
  InstanceName: "",
  Fromdate: "",
  Todate: "",
};

// License request state
const licenseFilterState = {
  CustomerName: "",
  Planname: "",
  Fromdate: "",
  Todate: "",
};

// addon subscription request state
const AddonsubscriptionFilterState = {
  planname: "",
  Subscriptiondate: "",
  Fromdate: "",
  Todate: "",
};

// addon subscription request state
const aditionaStorageFilterState = {
  Domainname: "",
  PlanName: "",
  Fromdate: "",
  Todate: "",
};

// addon subscription request state
const renewalFilterState = {
  CustomerName: "",
  RenewalAmount: "",
  Fromdate: "",
  Todate: "",
};

// cancel Plan request state
const cancelPlanFilterState = {
  PlanName: "",
  CustomerName: "",
  DomainName: "",
  Fromdate: "",
  Todate: "",
};

// instant Creation state
const InstantCreationFilterState = {
  Domain_Name: "",
  Customer_Name: "",
  Database_Name: "",
  Fromdate: "",
  Todate: "",
};

// Define the reducer function
const reducerFilter = (state, action) => {
  if (action?.type) {
    return { ...state, [action.type]: action.payload };
  } else {
    return action;
  }
};

export {
  reducerFilter,
  addonsFilterState,
  storageFilterState,
  planFilterState,
  subscriptionFilterState,
  invoiceFilterState,
  paymentFilterState,
  RequestFilterState,
  licenseFilterState,
  AddonsubscriptionFilterState,
  aditionaStorageFilterState,
  renewalFilterState,
  cancelPlanFilterState,
  InstantCreationFilterState,
};
