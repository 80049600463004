import React, { useCallback, useEffect, useMemo, useState } from "react";
import useResponsive from "../hook/useResponsive";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Search, SearchIconWrapper, StyledInputBase } from "../widgets/Search";
import { IoIosSearch } from "react-icons/io";
import { PiCheck } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import ChatElement from "../components/ChatElement";
import { ToastContainer, toast } from "react-toastify";
import {
  ButtonStyle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";

import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useSelector } from "react-redux";
import Div from "@jumbo/shared/Div";
import { useStateContext } from "app/contexts/ContextProvider";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";
import { BASE_URL, socket } from "app/services/auth-services";
import axios from "axios";
import { useChatContext } from "../context/Chatcontext";

const CreateGroups = ({ open, handleClose }) => {
  const { usersList, setUsersList } = useChatContext();
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [groupName, setGroupName] = useState("New Group");
  const [selectPeople, setSelectPeople] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");

  const handleSelectPerson = useCallback(
    (select) => {
      const isDuplicate = selectPeople?.some(
        (group) => group?.id === select?.id
      );
      if (select !== "") {
        if (isDuplicate) {
          setSelectPeople(
            selectPeople?.filter((group) => group?.id !== select?.id)
          );
        } else {
          setSelectPeople([...selectPeople, select]);
        }
      }
    },
    [selectPeople]
  );

  const handleSearch = async (e) => {
    const search = e.target.value?.trim()?.toLowerCase();
    if (Boolean(search)) {
      const filteredUsers = usersList.filter(
        (item) =>
          item?.username?.toLowerCase()?.includes(search) ||
          item?.Group_Name?.toLowerCase()?.includes(search)
      );
      // console.log("filteredUsers", filteredUsers);
      setFilteredUsers(filteredUsers);
    } else {
      setFilteredUsers(usersList);
    }
  };

  useEffect(() => {
    if (usersList) setFilteredUsers(usersList);
  }, [usersList]);

  let users;
  if (usersList?.length === 0) {
    users = <Typography>No usersList</Typography>;
  } else if (users?.length > 0) {
    users = usersList?.map((user) => {
      let check = selectPeople?.some((che) => che?.id === user?.id);
      if (!user?.Group_Name) {
        return (
          <Div sx={{ my: 1 }}>
            <ChatElement
              user={user}
              check={check}
              createGroup={"create group"}
              handleSelectPerson={handleSelectPerson}
            />
          </Div>
        );
      }
    });
  }

  const token = localStorage.getItem("accesstoken");
  const UserId = localStorage.getItem("UserId");
  const Organization_Id = localStorage.getItem("OrganizationId");
  const item = "crmuseradmin1";

  const handleSubmit = async () => {
    const payload = {
      Organization_Id: Organization_Id,
      Group_Name: groupName,
      Assign_User_Name: selectPeople.map((che) => che.username),
      User_Photo: "",
      File_Name: "",
      Description: "no description",
      Assign_User: selectPeople.map((che) => che.id),
      Created_By: UserId,
      Created_Date: new Date().toISOString(),
      Updated_By: UserId,
      Updated_Date: new Date().toISOString(),
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/user/UserChatGroupCRUD/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response) {
        socket.emit("ChatUserGroupList", {
          db: BASE_URL,
          id: UserId,
        });

        socket.on("ChatUserGroupList", (data) => {
          console.log("ChatUserGroupList all list: ", data);
          setUsersList(data);
        });

        handleClose();
      }
    } catch (error) {
      console.error("Error creating group:", error);
      toast.error("Failed to create group.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: "300px",
          height: "100%",
          width: "100%",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background,

          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        {/* {!isDesktop && (
          // Bottom Nav
          <Button>button</Button>
        )} */}

        <Stack
          px={2}
          pb={2}
          spacing={2}
          sx={{ maxHeight: "95vh", minHeight: "300px" }}
        >
          <Stack
            pt={3}
            alignItems={"center"}
            justifyContent="space-between"
            direction="row"
          >
            <Typography sx={{ fontSize: "16px" }}>{groupName}</Typography>

            <Stack direction={"row"} alignItems="center" spacing={1}>
              <IconButton
                disabled={groupName === ""}
                onClick={() => {
                  if (selectPeople?.length > 0) {
                    setIsOpenDialog(true);
                  } else {
                    toast.error(
                      "At least one person is picked; we can form a group now",
                      {
                        position: "top-right",
                        autoClose: 2000,
                      }
                    );
                  }
                  // handleSelectPerson();
                }}
                sx={{ width: "max-content" }}
              >
                <PiCheck
                  color={groupName === "" ? "gray" : "green"}
                  size={20}
                />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleClose();
                }}
                sx={{ width: "max-content" }}
              >
                <IoCloseOutline size={21} color="tomato" />
              </IconButton>
            </Stack>
          </Stack>
          <Stack sx={{ width: "100%" }} className="w-full">
            <Search sx={{ height: "35px" }} className="w-full">
              <SearchIconWrapper>
                {/* <MagnifyingGlass color="#709CE6" /> */}
                <IoIosSearch color="#709CE6" size={18} />
              </SearchIconWrapper>
              <StyledInputBase
                className="w-full"
                sx={[FontStyle, { ml: 4, mt: 1, color: "gray" }]}
                onChange={handleSearch}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Stack>

          {selectPeople?.length > 0 && (
            // <div style={{ height: "100%", width: isDesktop ? 300 : "100vw" }}>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={"60px"}
              autoHide={true}
              hideTracksWhenNotNeeded
            >
              <Div
                sx={[
                  DisplayFlex,
                  {
                    paddingY: 1,
                    mr: 2,
                    gap: 1,
                  },
                ]}
              >
                {/* selected List */}
                {selectPeople?.map((items, idx) => {
                  return (
                    <Stack key={idx} sx={{ mx: 0.5 }}>
                      <Badge
                        sx={{
                          "& .MuiBadge-badge": {
                            transform: "translate(20px)",
                            padding: 0,
                            bottom: -2,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleSelectPerson(items)}
                        badgeContent={
                          <IoCloseOutline
                            color="white"
                            style={{
                              backgroundColor: "tomato",
                              borderRadius: "50%",
                            }}
                          />
                        }
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Div sx={[DisplayFlex, { justifyContent: "center" }]}>
                          <Avatar
                            sx={{
                              fontSize: "10px",
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                            }}
                          >
                            {items?.username?.substring(0, 2)?.toUpperCase()}
                          </Avatar>
                        </Div>
                      </Badge>
                      <Typography sx={{ fontSize: "8px", textAlign: "center" }}>
                        {items?.username?.slice(0, 6)}
                        {items?.username?.length > 7 && "..."}
                      </Typography>
                    </Stack>
                  );
                })}
              </Div>
            </JumboScrollbar>
            // </div>
          )}

          <Typography sx={[FontStyle, { color: "#676667" }]}>
            All Peoples
          </Typography>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={"58vh"}
            autoHide={true}
            hideTracksWhenNotNeeded
          >
            {
              (users = filteredUsers?.map((user) => {
                let check = selectPeople?.some((che) => che?.id === user?.id);
                if (!user?.Group_Name) {
                  return (
                    <Div sx={{ my: 1 }}>
                      <ChatElement
                        user={user}
                        check={check}
                        createGroup={"create group"}
                        handleSelectPerson={handleSelectPerson}
                      />
                    </Div>
                  );
                }
              }))
            }
          </JumboScrollbar>
        </Stack>
      </Box>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpenDialog}
        TransitionComponent={"left"}
        keepMounted
        onClose={() => setIsOpenDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ p: 4 }}
      >
        <DialogTitle sx={[DisplayFlex, dialogTitle]}>
          Create New Group
        </DialogTitle>

        <DialogContent sx={{ mt: 1 }}>
          <div>
            <Typography sx={[FontStyle]}>Group Name</Typography>
            <FormControl sx={{ width: "100%", height: "40px" }}>
              <OutlinedInput
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Please enter group name"
                fullWidth
                sx={{ height: "40px", color: "gray", fontSize: "14px" }}
              />
            </FormControl>
          </div>

          <div style={{ marginTop: 10 }}>
            <Typography sx={[FontStyle]}>Participants</Typography>
            <div>
              {/* <Stack spacing={1} sx={{ maxHeight: "300px", overflow: "auto" }}> */}
              {/* Chat List */}
              <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMax={"300px"}
                autoHide={true}
                hideTracksWhenNotNeeded
              >
                {selectPeople?.map((use) => (
                  <ChatElement
                    createGroup="create group"
                    user={use}
                    person={"person"}
                    key={use?.id}
                  />
                ))}
              </JumboScrollbar>
              {/* </Stack> */}
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ my: 1 }}>
          <CustomButton label="Create" onClick={handleSubmit} />

          <Cancelbutton label="Cancel" onClick={() => setIsOpenDialog(false)} />
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </>
  );
};

export default CreateGroups;
