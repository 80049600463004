import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { authUser } from "./fake-db";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  IconButton,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import axios from "axios";
import { BASE_URL, Tenantname } from "app/services/auth-services";
import MailConfiguration from "./MailConfiguration";
import { CiCalendar, CiMail } from "react-icons/ci";
import CalendarConfiguration from "./CalendarConfiguration";
import GMailConfiguration from "./GmailConfiguration";
import MapConfiguration from "./MapConfiguration";
import LinkedinConfiguration from "./LinkedinConfiguration";
import FacebookConfiguration from "./FacebookConfiguration";
import InstagramConfiguration from "./InstagramConfiguration";

import KnowlarityConfiguration from "./KnowlarityConfiguration";
import { BsChatText } from "react-icons/bs";
import { IoVideocamOutline } from "react-icons/io5";
import CloseIcon from "@mui/icons-material/Close";
import OutlookConfiguration from "./OutlookConfigurations";
import {
  getGeoLocationKey,
  GmailSecertKey,
} from "app/redux/reducers/locationApp";

// import { useJumboLayoutSidebar } from "@jumbo/hooks";
// import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const AuthUserDropdown = () => {
  // const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const set = localStorage.getItem("Username");
  const token = localStorage.getItem("accesstoken");

  // const {setAuthToken} = useJumboAuth();
  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const [frommail, setFromMail] = useState(
    `${userDetails && userDetails?.email}`
  );

  // update login status
  const updateLoginStatus = async () => {
    try {
      const token = localStorage.getItem("accesstoken");
      const username = localStorage.getItem("Username");
      await axios.patch(
        `${BASE_URL}/useradmin/UserCRUD/`,
        {
          User_Login_Status: false,
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: "crmuseradmin1",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const updatePassWordStatus = () => {
    if (
      userDetails?.Password_Status === false &&
      userDetails?.Navigate_Status === false
    ) {
      axios
        .patch(
          `${BASE_URL}/useradmin/UserCRUD/`,
          {
            id: localStorage.getItem("UserId"),
            username: userDetails && userDetails?.username,
            Password_Status: true,
          },
          {
            headers: {
              db: "crm",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("get appointments list", res.data);
          window.location.href = `https://admin.crmfarm.in/InstanceEditList/?id=${Tenantname}`;
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      navigate(`/`);
      localStorage.removeItem("selectedItems");
      localStorage.removeItem("selectedItems1");
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("UserId");
      localStorage.removeItem("OrganizationId");
      localStorage.removeItem("UserType");
    }
  };

  //   no action auto logout
  const inactivityTimeout = 12000000; // 5 minutes in milliseconds
  let logoutTimer;

  const resetTimeout = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(onLogout, inactivityTimeout);
  };

  useEffect(() => {
    resetTimeout();

    const events = ["mousemove", "keydown"];

    const handleUserActivity = () => {
      resetTimeout();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });

    return () => {
      clearTimeout(logoutTimer);
      events.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, [navigate]);

  const onLogout = () => {
    // setAuthToken(null);
    updateLoginStatus();
    updatePassWordStatus();
  };

  // Calendar Mail Configuration and Mail Configuration

  const [showconfig, setShowConfig] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);

  const [chatopen, setchatopen] = useState(false);
  const [save, setSave] = useState(false);

  const [cal, setCal] = useState(false);

  useEffect(() => {
    getGeoLocationKey();
    GmailSecertKey();
  }, []);

  return (
    <Div>
      <ThemeProvider theme={theme}>
        <JumboDdPopover
          triggerButton={
            <Div sx={{ display: "flex" }}>
              <Avatar
                sizes={"small"}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#dff3dc",
                  fontSize: "16px",
                  color: "#0c5c00",
                }}
              >
                {set && set[0].toUpperCase()}
                {set && set[1].toUpperCase()}
              </Avatar>
              {/* <Typography variant="h3"  fontWeight={700}
                    sx={{pl:1.8,pt:1.3}}>
                      {set&&set}
                      </Typography> */}
            </Div>
          }
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              p: (theme) => theme.spacing(2.5),
            }}
          >
            <Avatar sx={{ width: 60, height: 60, mb: 2 }}>
              {set && set[0].toUpperCase()}
              {set && set[1].toUpperCase()}
            </Avatar>
            <Typography variant={"h5"}>{set}</Typography>
            <Typography variant={"body1"} color="text.secondary">
              {frommail}
            </Typography>
          </Div>
          <Divider />
          <nav>
            <List disablePadding sx={{ pb: 1 }}>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <PersonOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
              </ListItemButton>

              {localStorage.getItem("UserType") !== "User" && (
                <ListItemButton
                  onClick={() => {
                    setchatopen(true);
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <BsChatText />
                  </ListItemIcon>
                  <ListItemText
                    onClick={() => navigate(`/ChatandSupport`)}
                    primary="Chat & Support"
                    sx={{ my: 0 }}
                  />
                </ListItemButton>
              )}
              <ListItemButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiMail />
                </ListItemIcon>
                <ListItemText primary="Mail Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen1(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText primary="Calendar Configuration" sx={{ my: 0 }} />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  setOpen2(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText primary="Gmail Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen8(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText primary="Outlook Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen3(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText primary="Map Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen4(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText primary="Linkedin Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen5(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText primary="Facebook Configuration" sx={{ my: 0 }} />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen6(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText
                  primary="Instagram Configuration"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setOpen7(true);
                }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <CiCalendar />
                </ListItemIcon>
                <ListItemText
                  primary="Knowlarity Configuration"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <RepeatOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  onClick={() => navigate("/samples/content-layout")}
                  primary="Switch User"
                  sx={{ my: 0 }}
                />
              </ListItemButton>
              <ListItemButton onClick={onLogout}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ my: 0 }} />
              </ListItemButton>
            </List>
          </nav>
        </JumboDdPopover>
      </ThemeProvider>

      <Div>
        <MailConfiguration open={open} setOpen={setOpen} />
      </Div>
      <Div>
        <CalendarConfiguration open1={open1} setOpen1={setOpen1} />
      </Div>
      <Div>
        <GMailConfiguration open2={open2} setOpen2={setOpen2} />
      </Div>
      <Div>
        <MapConfiguration open3={open3} setOpen3={setOpen3} />
      </Div>
      <Div>
        <LinkedinConfiguration open4={open4} setOpen4={setOpen4} />
      </Div>
      <Div>
        <FacebookConfiguration open5={open5} setOpen5={setOpen5} />
      </Div>
      <Div>
        <InstagramConfiguration open6={open6} setOpen6={setOpen6} />
      </Div>
      <Div>
        <KnowlarityConfiguration open7={open7} setOpen7={setOpen7} />
      </Div>
      <Div>
        <OutlookConfiguration open8={open8} setOpen8={setOpen8} />
      </Div>
    </Div>
  );
};

export default AuthUserDropdown;
