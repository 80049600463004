import {
  ComponentHeading,
  dialogFontSize,
  dialogTitle,
  input_label,
} from "../widgets/CRMStyles";
import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  FormControl,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "react-toastify/dist/ReactToastify.css";
import { MailIcon, PhoneIcon, SettingsIcon } from "app/icons/PngIcons";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import CKEditor from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  Cancelbutton,
  CustomButton,
  CustomCKEditor,
  Customgrid,
  DialogBox,
  Dragabledialog,
  errorFilter,
} from "../widgets/StylesComponents";

const answerss = [
  {
    Answer: "",
    Points: "",
    Updated_By: localStorage.getItem("UserId"),
    Created_By: localStorage.getItem("UserId"),
  },
  {
    Answer: "",
    Points: "",
    Updated_By: localStorage.getItem("UserId"),
    Created_By: localStorage.getItem("UserId"),
  },
  {
    Answer: "",
    Points: "",
    Updated_By: localStorage.getItem("UserId"),
    Created_By: localStorage.getItem("UserId"),
  },
  {
    Answer: "",
    Points: "",
    Updated_By: localStorage.getItem("UserId"),
    Created_By: localStorage.getItem("UserId"),
  },
];

const LeadQuestionEdit = ({
  openEditContact,
  setOpenEditContact,
  update,
  getData,
  setDatas,
  refetch,
}) => {
  // console.log("update", update);

  const token = localStorage.getItem("accesstoken");

  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const item = "crmuseradmin1";

  const [editorContent, setEditorContent] = useState(update?.Question);

  const onChange = (content) => {
    setEditorContent(content);
  };

  // Lead Generation

  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Fields for Answer

  const [name, setName] = useState(
    update?.Answer_Id?.length > 0 ? update?.Answer_Id : answerss
  );

  // Get Lead Industry

  const [leadindustry, setLeadindustry] = useState("");

  const [indus, setIndus] = useState(update?.Industry_Type);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setEditorContent(update?.Question);
    setIndus(update?.Industry_Type);

    if (update?.Answer_Id?.length > 0) {
      setName(update?.Answer_Id);
    } else {
      setName(answerss);
    }
  }, [update]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Uncomment the console.log line if you want to log the response data
      console.log(
        "checklookupdatas",
        response.data.find((op) => op?.Lookupname === "Industry Type")
      );
      setLeadindustry(
        response?.data?.find((op) => op?.Lookupname === "Industry Type") || []
      );
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed s(e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    getlookupData();
  }, []);

  // For Showing the Pop up
  const [save, setSave] = useState(false);

  useEffect(() => {
    setEditorContent(update?.Question);
    setIndus(
      update?.Industry_Type
        ? { value: update?.Industry_Type, id: update?.Industry_Id }
        : ""
    );
    if (update?.Answer_Id?.length > 0) {
      setName(update?.Answer_Id);
    } else {
      setName(answerss);
    }
  }, [openEditContact, update]);

  // For hnadle Submit Functionality

  const handleSubmit = async (e) => {
    console.log("indus.value", indus);

    e.preventDefault();

    let error = errorFilter({ indus: indus, editorContent });

    let filter = name?.filter((o, i) => {
      if (!Boolean(o?.Answer) || !Boolean(o?.Points)) {
        if (!error.Answer) {
          error.Answer = [];
        }
        error.Answer.push(i);
      }
    });

    let bool = name?.every((o) => Boolean(o?.Answer) && o?.Points);

    console.log("indus.value", error, bool, filter);
    setErrors(error);
    // console.log("payload", payload);
    if (Object.keys(error).length > 0 || !bool) {
      toast.error("Please Check the all the fields are Filled", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      let payload = {
        id: update.id,
        LeadQuestion_Id: update.LeadQuestion_Id,
        Question_Tittle: update.Question_Tittle,
        Industry_Type: indus?.value,
        Question: editorContent,
        Industry_Id: indus?.id,
        Answer_Id: name,
        Created_Date: update.Created_Date,
        Updated_Date: moment().format("YYYY-MM-DD"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };

      await axios
        .put(`${BASE_URL}/user/LeadQualifyQuestionsCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("check", res);
          toast.success("Updated  successfully", {
            position: "top-right",
            autoClose: 100,
          });
          setOpenEditContact(false);
          setDatas([]);
          refetch(1);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  return (
    <>
      <Dragabledialog
        maxWidth="lg"
        open={openEditContact}
        // onClose={() => setOpenNotes(false)}
      >
        <DialogTitle
          sx={[DisplayFlex, dialogFontSize]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Edit Lead Questions
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <form onSubmit={handleSubmit}>
            <Customgrid>
              <Grid item>
                <Typography sx={[input_label]}>Lead Question Id</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                      MinWidth: "325px",
                      width: "100%",
                    }}
                    className={`col-12 input-box2`}
                    id="outlined-basic"
                    name="LeadQuestionId"
                    value={update?.LeadQuestion_Id}
                    placeholder="# Lead Question Id"
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Industry Type</Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.value) {
                      return option?.value;
                    }
                    return "";
                  }}
                  value={indus?.value}
                  className="search-select3 col-12"
                  options={(leadindustry && leadindustry?.Values) || []}
                  onChange={(e, newValue) => {
                    console.log("newValue", newValue);
                    setIndus(newValue);
                  }}
                  id="outlined-basic"
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Industry Type" />
                  )}
                />
                {errors?.indus && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Industry Type {errors.indus}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography sx={[input_label]}>Enter the Question</Typography>
              </Grid>
              <Grid item>
                <CustomCKEditor onChange={onChange} initData={editorContent} />
                {errors?.editorContent && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Question {errors.editorContent}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography sx={[input_label]}>Answer & Points</Typography>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {name?.map((data, index) => {
                    return (
                      <Grid container spacing={2}>
                        <Grid item sx={{ my: 0.5 }} xs={12} md={10.5}>
                          <Div
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              paddingTop: 1,
                              gap: "10px !important",
                              width: "100%",
                            }}
                          >
                            <FormControlLabel
                              value={index}
                              control={<Radio color="info" size="small" />}
                            />
                            <Div sx={{ width: "100%" }}>
                              <TextField
                                className=" w-full input-box2"
                                id="outlined-basic"
                                placeholder="Enter the answer"
                                variant="outlined"
                                value={name[index]?.Answer}
                                sx={{ width: "100%" }}
                                onChange={(e) => {
                                  let ch = [...name];
                                  ch[index].Answer = e.target.value;
                                  setName(ch);
                                }}
                                disabled={selectedValue != index}
                              />
                              {errors?.Answer?.some((o) => o === index) && (
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "12px",
                                      color: "red !important",
                                    },
                                  ]}
                                >
                                  Answer & Points is required
                                </Typography>
                              )}
                            </Div>
                          </Div>
                        </Grid>
                        <Grid item sx={{ py: 0.5 }} xs={12} md={1.5}>
                          <Div sx={{ paddingTop: 1.5 }}>
                            <TextField
                              className=" input-box2"
                              id="outlined-basic"
                              variant="outlined"
                              sx={{
                                width: "100%",
                              }}
                              value={name[index]?.Points}
                              onChange={(e) => {
                                let ch1 = [...name];
                                ch1[index].Points = e.target.value;
                                setName(ch1);
                              }}
                              disabled={selectedValue != index}
                            />
                          </Div>
                        </Grid>
                      </Grid>
                    );
                  })}
                </RadioGroup>
              </Grid>
            </Customgrid>

            <Div sx={[DisplayFlex, { my: 2, justifyContent: "center" }]}>
              <CustomButton label="Update" Icon="saveIcon" type="submit" />
              <Cancelbutton
                label="Cancel"
                onClick={() => {
                  setSave(true);
                }}
              />
            </Div>
          </form>
        </DialogContent>
      </Dragabledialog>

      {/* close dialog box */}
      <DialogBox
        open={save}
        onClickNo={() => setSave(false)}
        onClickYes={() => {
          setSave(false);
          setOpenEditContact(false);
        }}
      />
    </>
  );
};

export default LeadQuestionEdit;
