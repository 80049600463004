import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Select,
} from "@mui/material";
import Setting from "../Setting";
import React, { useEffect, useState } from "react";
import { TableBody } from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { apiConfig } from "app/services/config";
import { CRMTableHead, ComponentHeading } from "app/pages/widgets/CRMStyles";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

const Deal_Weight = [
  { Weight: "Client Engagement" },
  { Weight: "Budget Alignment" },
  { Weight: "Decision maker Involvement" },
  { Weight: "Unique Selling Point" },
  { Weight: "Market Condition" },
  { Weight: "ROI & Value proposition" },
  { Weight: "Post Sales Support" },
];

function DealFactorsetup() {
  const [datas, setdatas] = useState([]);
  const [rows, setrows] = useState([]);
  const [rows1, setrows1] = useState([
    {
      Weight: "Client Engagement",
      Points: "",
      Values: [{ value: "High" }, { value: "Medium" }, { value: "Low" }],
      value: "High",
      id: "",
    },
    {
      Weight: "Client Engagement",
      Points: "",
      Values: [{ value: "High" }, { value: "Medium" }, { value: "Low" }],
      value: "Medium",
      id: "",
    },
    {
      Weight: "Client Engagement",
      Points: "",
      Values: [{ value: "High" }, { value: "Medium" }, { value: "Low" }],
      value: "Low",
      id: "",
    },
    {
      Weight: "Budget Alignment",
      Points: "",
      Values: [
        { value: "Well alignment" },
        { value: "Somewhat aligned" },
        { value: "Poorly aligned" },
      ],
      value: "Well alignment",
      id: "",
    },
    {
      Weight: "Budget Alignment",
      Points: "",
      Values: [
        { value: "Well alignment" },
        { value: "Somewhat aligned" },
        { value: "Poorly aligned" },
      ],
      value: "Somewhat aligned",
      id: "",
    },
    {
      Weight: "Budget Alignment",
      Points: "",
      Values: [
        { value: "Well alignment" },
        { value: "Somewhat aligned" },
        { value: "Poorly aligned" },
      ],
      value: "Poorly aligned",
      id: "",
    },
    {
      Weight: "Decision Maker",
      Points: "",
      Values: [{ value: "High" }, { value: "Medium" }, { value: "Low" }],
      value: "High",
      id: "",
    },
    {
      Weight: "Decision Maker",
      Points: "",
      Values: [{ value: "High" }, { value: "Medium" }, { value: "Low" }],
      value: "Medium",
      id: "",
    },
    {
      Weight: "Decision Maker",
      Points: "",
      Values: [{ value: "High" }, { value: "Medium" }, { value: "Low" }],
      value: "Low",
      id: "",
    },
    {
      Weight: "Unique Selling Point",
      Points: "",
      Values: [{ value: "Unique" }, { value: "Average" }, { value: "Low" }],
      value: "Unique",
      id: "",
    },
    {
      Weight: "Unique Selling Point",
      Points: "",
      Values: [{ value: "Unique" }, { value: "Average" }, { value: "Low" }],
      value: "Average",
      id: "",
    },
    {
      Weight: "Unique Selling Point",
      Points: "",
      Values: [{ value: "Unique" }, { value: "Average" }, { value: "Low" }],
      value: "Low",
      id: "",
    },
    {
      Weight: "Market Condition",
      Points: "",
      Values: [
        { value: "Favorable" },
        { value: "Average" },
        { value: "Not Favorable" },
      ],
      value: "Favorable",
      id: "",
    },
    {
      Weight: "Market Condition",
      Points: "",
      Values: [
        { value: "Favorable" },
        { value: "Average" },
        { value: "Not Favorable" },
      ],
      value: "Average",
      id: "",
    },
    {
      Weight: "Market Condition",
      Points: "",
      Values: [
        { value: "Favorable" },
        { value: "Average" },
        { value: "Not Favorable" },
      ],
      value: "Not Favorable",
      id: "",
    },
    {
      Weight: "ROI & Value proposition",
      Points: "",
      Values: [
        { value: "Demonstrated ROI" },
        { value: "Articulated Benefits" },
      ],
      value: "Demonstrated ROI",
      id: "",
    },
    {
      Weight: "ROI & Value proposition",
      Points: "",
      Values: [
        { value: "Demonstrated ROI" },
        { value: "Articulated Benefits" },
      ],
      value: "Articulated Benefits",
      id: "",
    },
    {
      Weight: "Post Sales Support",
      Points: "",
      Values: [
        { value: "Custom support offered" },
        { value: "Standard support offered" },
      ],
      value: "Custom support offered",
      id: "",
    },
    {
      Weight: "Post Sales Support",
      Points: "",
      Values: [
        { value: "Custom support offered" },
        { value: "Standard support offered" },
      ],
      value: "Standard support offered",
      id: "",
    },
  ]);

  const createrId = localStorage.getItem("UserId");

  const handleAddChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setrows(updatedRows);
  };

  useEffect(() => {
    DealFactorysetuprecords();
  }, []);

  const DealFactorysetuprecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/UserAdminDealFactorSetupAdditional/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("Factory setup", res.data.results);
        setdatas(res.data.results);
        let response = res.data.results;
        response.map((item, index) => {
          rows1.map((ele, ind) => {
            if (item.Weight === ele.Weight && item.values === ele.value) {
              ele.Points = item.Points;
              ele.id = item.id;
            }
          });
        });

        console.log("rows", rows1);
        setrows(rows1);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  const handleSaveFactorset = () => {
    let count = 0;
    console.log("rows", rows);
    rows.map((item, index) => {
      if (
        item.Points >= 1 ||
        item.Points === null ||
        item.Points === undefined
      ) {
        count++;
      }
    });
    if (count !== 0) {
      // console.log("total",total)
      toast.error("Enter Valid Points");
    } else {
      let postarr = [];
      let putarr = [];
      rows.map((item, index) => {
        if (item.id === "" || item.id === null || item.id === undefined) {
          postarr.push(item);
        } else {
          putarr.push(item);
        }
      });
      console.log("postarr", postarr);
      console.log("putarr", putarr);
      postarr.map((item, ind) => {
        if (
          item.Points !== "" &&
          item.Points !== null &&
          item.Points !== undefined &&
          item.Points <= 1
        ) {
          let playload = {
            Weight: item.Weight,
            Points: item.Points,
            values: item.value,
            Deal_Weight: 2,
            Created_By: createrId,
            Updated_By: createrId,
          };
          console.log("playload", playload);

          axios
            .post(
              BASE_URL + "/useradmin/UserAdminDealFactorSetupCRUD/",
              playload,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accesstoken"
                  )}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Db: localStorage.getItem("DBName"),
                },
              }
            )
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        }
      });

      // putarr.map((item,index)=>{
      //   if(item.Points !== "" || item.Points !== null || item.Points !== undefined){
      //     let playload={
      //       "id": item.id,
      //       "Weight": item.Weight,
      //       "Points": item.Points,
      //       "values": "",
      //       "Created_By": 1,
      //       "Updated_By": 1
      //     }
      //     axios.put(`${BASE_URL}/useradmin/UserAdminDealWeightSetupCRUD/?id=${item.id}`,playload, {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
      //         "Content-Type": "application/json",
      //         Accept: "application/json",
      //         "db":localStorage.getItem("DBName")
      //       },
      //      })
      //     .then((res) => {
      //       console.log("put", res.data);
      //       // if(res.data.id === data.id){
      //       //   toast.success("Updated Successfully");
      //       //   // Predictionrecords()
      //       // }else{
      //       //   toast.error("Something went wrong");
      //       // }
      //       // setdatas(res.data.results)

      //      })
      //      .catch((error) => {
      //       console.log("ds", error);
      //       });
      //     }

      // })
    }
  };

  return (
    <>
      <JumboContentLayoutMain>
        <Setting />
        <Typography sx={[ComponentHeading]}>Deal Factor Setup</Typography>
        <form>
          <div>
            <Div
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: -3,
                mr: 0.5,
                height: "40px",
              }}
            ></Div>
            <div>
              <Div sx={{ m: 1, ml: 0, mr: 0, p: 2, minHeight: "366px" }}>
                <Div sx={{ display: "block" }}>
                  <form>
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMin={"55vh"}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                      id="no-more-tables"
                    >
                      <TableContainer>
                        <Table stickyHeader className="table" sx={{ mt: 2 }}>
                          <TableHead sx={[CRMTableHead]}>
                            <TableRow>
                              <TableCell>Deal Weight</TableCell>
                              <TableCell>Factors</TableCell>
                              <TableCell>Points</TableCell>
                              {/* <TableCell>
                              Action
                              </TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows?.map((item, index) => {
                              {
                                /* console.log("data2 : ",data) */
                              }
                              return (
                                <TableRow hover>
                                  <TableCell data-title="Deal Weight">
                                    <TextField
                                      sx={{
                                        height: "35px",
                                        width: "240px",
                                        ml: 2,
                                      }}
                                      className="col-md-5 input-box"
                                      id="outlined-basic"
                                      variant="outlined"
                                      autoComplete="off"
                                      name="score"
                                      value={item.Weight}
                                      disabled={true}
                                    />
                                  </TableCell>

                                  <TableCell data-title="Factors">
                                    <FormControl className="col-sm-12 ">
                                      <Select
                                        value={item.value}
                                        sx={{ height: "35px", width: "240px" }}
                                        className="react-select-box"
                                        name="Parameter"
                                        // onChange={(e) => handlesalespersonchange1(e, index)}
                                      >
                                        {item?.Values &&
                                          item?.Values?.map((item, index) => (
                                            <MenuItem value={item?.value}>
                                              {item?.value}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>

                                  <TableCell data-title="Points">
                                    <TextField
                                      sx={{ height: "35px", width: "140px" }}
                                      className="col-md-5 input-box"
                                      id="outlined-basic"
                                      variant="outlined"
                                      autoComplete="off"
                                      name="Points"
                                      value={item.Points}
                                      onChange={(e) => {
                                        handleAddChange(index, e);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </JumboScrollbar>
                    <Div>
                      <Div sx={{ mt: 2, ml: 4, color: "#F22323" }}>
                        * It shall be less than 1
                      </Div>
                    </Div>

                    <Div
                      sx={{
                        mt: 1,
                        justifyContent: "end",
                        alignItems: "center",
                        display: "flex",
                        mr: 5,
                      }}
                    >
                      {/* <Button sx={[DialogBoxSaveButton, ButtonStyle, FontStyle, { mr: 1 }]} onClick={handleSaveFactorset}>
                        Save
                      </Button>
                      <Button sx={[DialogBoxCancelButton, ButtonStyle, FontStyle]} >
                        Cancel
                      </Button> */}

                      <CustomButton
                        onClick={handleSaveFactorset}
                        label="Save"
                        Icon="saveIcon"
                        sx={{ mr: 1 }}
                      />
                      <Cancelbutton
                        label="Cancel"
                        // onClick={handleClickBack}
                      />
                    </Div>
                  </form>
                </Div>
              </Div>
            </div>
          </div>
        </form>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>
  );
}

export default DealFactorsetup;
