import Div from '@jumbo/shared/Div'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, TextField } from '@mui/material'
import InputTextFields from 'app/pages/components/mui/TextFields/InputTextFields'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const FBPostDialog = ({ isPostDialogOpen, setPostDialogOpen, pageAccessToken }) => {

    const [open, setOpen] = useState(isPostDialogOpen)
    const [postMessage, setPostMessage] = useState('')
    const [link, setLink] = useState('')
    const handleClose = () => {
        setPostDialogOpen(false)
    }
    const [token, setToken] = useState(pageAccessToken)
    useEffect(() => {
        setToken(pageAccessToken)
    }, [pageAccessToken])


    const handlePost = async () => {

        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

        const isUrlValid = urlPattern.test(link);
        console.log("isUrlValid", isUrlValid)

        if (isUrlValid) {
            // let token = "EAADn3m1nbtgBO64mLM3rZBc8DSxHNbZCFWVvC2WZA7FjZAQCzsrNb4cgLRa829jykUZAjqwCCsEY7cidVfZA03fR8MOTbtqFHIIXnbv9HhAMhCpzorNitI3GL66bqzxB6BZCyydg65xUubZCz6ZCsEZAntGpNC0fZBXfSawEUK8hbPTUEZAZAZCYdLp7ILyvAvo1C9Ur91HSXa0zcZD"
            const Headers = {
                Authorization: `Bearer ${pageAccessToken}`,
                "Content-Type": "application/json"
            }
            const payload = {
                "message": postMessage,
                "link": link,
                "published": "true"
            }
            await axios.post('https://graph.facebook.com/v18.0/251682828032268/feed', payload, {
                headers: Headers
            }).then((res) => {
                console.log("fb post response", res)
                if (res?.status == 200) {
                    toast.success("Posted Successfully")
                    handleClose()
                }
            }).catch((error) => {
                console.error("FB Post error", error)
            })
        }
        else {
            toast.error("Enter valid url")
        }


    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "500px", minHeight: '300px' } }}
        >
            <DialogTitle className='dialog-head' >Create Post</DialogTitle>
            <DialogContent sx={{ marginTop: '40px' }}>
                <Div>
                    {/* <input style={{minWidth:'100%',minHeight:'5vh'}}></input> */}
                    <TextField multiline rows={3} value={postMessage} placeholder={`What's on your mind?`} onChange={(e) => { setPostMessage(e.target.value) }} sx={{ minWidth: '100%' }} />
                    <TextField sx={{ marginTop: '10px', minWidth: '100%' }} placeholder='link' value={link} onChange={(e) => { setLink(e.target.value) }}></TextField>
                </Div>
            </DialogContent >
            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button className='dialog-close-btn' onClick={handleClose}>Close</Button>
                <Button className='dialog-save-btn' onClick={handlePost} >Post</Button>
            </DialogActions>
        </Dialog >
    )
}

export default FBPostDialog