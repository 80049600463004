import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Grid,
  Table,
  TableBody,
  Typography,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import { AutoHeight } from "app/redux/auth/localStorage";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Colors, ComponentHeading, DisplayFlex, FontStyle } from "app/pages/widgets/CRMStyles";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import CreateEngagement from "./CreateEngagement";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { CiMail } from "react-icons/ci";
import { CommonPagination, CustomButton, DeletedIcon, DialogBox, EditIcon, NoRecordsPage } from "app/pages/widgets/StylesComponents";
import { CRMTableCell, CRMTableRow } from "../widgets/CRMTable";
import SearchBar from "../widgets/SearchField";

const CustomerEngagementList = ({ scrollHeight }) => {
  const [value, setValue] = useState("My Task");
  const [createEngagement, setcreateEngagement] = useState(false);
  const [listEngagement, setListEngagement] = useState(true);
  const [sendAddRequest, setSendAddRequest] = useState(false);
  const [sendReduceRequest, setSendReduceRequest] = useState(false);
  const [datas, setDatas] = useState([]);
  const [transfer, setTransfer] = useState([]);
  const [Modaldata, setModaldata] = useState({});
  const [tabValue, setTabValue] = useState(0);


  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Poster_ID", label: "Poster ID", bool: true },
    { sort: "Customer_ID", label: "Customer ID", bool: false },
    { sort: "Email_ID", label: "Email ID", bool: true },
    { sort: "FB_Like", label: "FB Like", bool: true },
    { sort: "FB_Comment", label: "FB Comment", bool: true },
    { sort: "FB_Shared", label: "FB Shared", bool: true },
    { sort: "LinkedIn_Like", label: "LinkedIn Like", bool: true },
    { sort: "LinkedIn_Comment", label: "LinkedIn Comment", bool: false },
    { sort: "LinkedIn_Shared", label: "LinkedIn Shared", bool: true },
    { sort: "Insta_Like", label: "Insta Like", bool: true },
    { sort: "Insta_Comment", label: "Insta Comment", bool: false },
    { sort: "Insta_Shared", label: "Insta Shared", bool: false },
  ]);
  const matches = useMediaQuery("(min-width:600px)");
  const token = localStorage.getItem("accesstoken");



  const [save, setSave] = useState("");

  // For the Pagination
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    try {
      const response = await axios.get(`${BASE_URL}/user/CustomerEngagementslist/?page=${newPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setDatas(response?.data?.results);
      setCount(response?.data?.count);
      // setOpenDelete(false);
    } catch (error) {
      console.error("Error fetching licence list:", error);
      toast.error("Something went wrong");
    }
  };

  const [showsort, setShowsort] = useState(true);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  //code for table sorting
  const sortData = (key, direction) => {
    const sortedData = [...datas];

    sortedData.sort((a, b) => {
      const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setDatas(sortedData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
    setShowsort(false);
  };

  const EditList = (data) => {
    console.log("EditList")
    setcreateEngagement('Edit');
    setListEngagement(false);
    console.log("setTransfer:", data)
    setTransfer(data);
  }

  const DeleteList = async () => {
    try {
      await axios.delete(`${BASE_URL}/user/CustomerEngagementsCRUD/?id=${save?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      EngagementList();
      setSave(false);
      toast.success("Deleted successfully", {
        position: "top-right",
        autoClose: 100,
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");

    }
  };


  const ShowCreateLicenceForm = () => {
    // navigate("/Licence/create-Licence");
    setcreateEngagement('Create');
    setListEngagement(false);
    setTransfer([]);
  };


  const EngagementList = async () => {
    try {
      console.log("Function EngagementList");
      const response = await axios.get(`${BASE_URL}/user/CustomerEngagementslist/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setDatas(response?.data?.results);
      setCount(response?.data?.count);
      // setOpenDelete(false);
    } catch (error) {
      console.error("Error fetching licence list:", error);
      toast.error("Something went wrong");
    }
  };

  const myEngagementList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/CustomerEngagementslist/}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setDatas(response?.data?.results);
      setCount(response?.data?.count);
      // setOpenDelete(false);
    } catch (error) {
      console.error("Error fetching additional list:", error);
      toast.error("Something went wrong");
    }
  };

  //------------------------------- List API-------------------------------------// 
  useEffect(() => {
    EngagementList();
  }, []);



  //-------------------------------End of My Task and All Task event--------------------------------
  const handleSendRequest = (data) => {
    setModaldata(data.data);
    console.log("raw data:", data);
    console.log("data", Modaldata);
    console.log("data.data?.status:", data?.data?.status)

    const action = data?.data?.Addorcancel;

    if (action === "Reduce") {
      setSendReduceRequest(true);
    } else if (action === "Add") {
      setSendAddRequest(true);
    }
  };


  const handleClose = () => {
    setSendAddRequest(false);
    setSendReduceRequest(false);
  };

  //------------------------------------Edit Button Functionality----------------------------------#

  //-----------Searching Functionality--Start----------------------//
  const handleSearchChange = (event) => {
    console.log("handleSearchange:", event.target.value)
    var SearchData = event.target.value;
    SearchData !== "" ? handleSearch(SearchData) : EngagementList();
  };


  const handleSearch = async (SearchData) => {
    try {
      const response = await axios.get(`${BASE_URL}/user/CustomerEngagementssearch/?search=${SearchData}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log(response.data);
      setDatas(response.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };


  return (
    <>
      <JumboContentLayoutMain>
        <Typography sx={[ComponentHeading]}>
          Customer Engagement
        </Typography>
        <Div sx={{ display: listEngagement === true ? "block" : "none" }}>
          <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
            List View
          </Typography>
          {/* -----------------------Search Operation---------------------------------- */}
          <Grid container sx={{ my: 1 }}>
            <Grid
              item
              className="filter"
              xs={12}
              md={7.5}
              sm={12}
              xl={7.5}
              sx={{ display: "flex", flexDirection: "row" }}
            >

            </Grid>
            <Grid item xs={12} md={3} sm={12} xl={3}>
              <SearchBar
                width={"300px !important"}
                border={"1px solid lightgray"}
                SearchData={(e) => handleSearchChange(e)}
              // SearchData={(e)=>setSearchValue(e.target.value)}
              />
            </Grid>

            <Grid item sx={[DisplayFlex, { justifyContent: { xs: "left", md: "right" } }]} xs={12} md={1.5} sm={12} xl={1.5}>
              <CustomButton
                onClick={ShowCreateLicenceForm}
                label="Create"
                Icon="createIcon"
              // endIcon={true}
              />
            </Grid>
          </Grid>

          {/* code for Licence list table */}
          <React.Fragment>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={AutoHeight(1.75)}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              {datas?.length > 0 ? (
                <Table stickyHeader>
                  <CustomTableHeader
                    headerMenuItems={columnMenuItems}
                    setHeadermenuItems={setColumnMenuItems}
                    setDatas={setDatas}
                    sortDatas={datas}
                  />

                  <TableBody>
                    {datas?.length > 0 &&
                      datas?.map((data, index) => {
                        return (
                          <CRMTableRow>
                            {columnMenuItems[0]?.bool && (
                              <CRMTableCell
                                data-title="Poster_ID"
                                sx={{ cursor: "pointer" }}
                              >
                                <Div
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Typography sx={[FontStyle,]}>{data?.Poster_ID}</Typography>

                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[1].bool && (
                              <CRMTableCell data-title="Customer_ID">
                                {data?.Customer_ID?.Organization_Name}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[2].bool && (
                              <CRMTableCell
                                data-title="Email"
                              >
                                <Div
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Div sx={[DisplayFlex]}>
                                    <Typography id='Typography-root'
                                      sx={{
                                        display: {
                                          xs: "none !important",
                                          md: "block !important",
                                          lg: "block !important",
                                        },
                                      }}
                                    >
                                      <CiMail size={16} color={Colors?.Mblue} />
                                    </Typography>
                                    {data?.Email_ID}
                                  </Div>
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[3].bool && (
                              <CRMTableCell
                                data-title="FB Like"
                              >
                                <Div sx={[DisplayFlex]}>
                                  {data?.FB_Like ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                                </Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[4].bool && (
                              <CRMTableCell data-title="FB_Comment">
                                <Div>{data?.FB_Comment ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}</Div>
                              </CRMTableCell>
                            )}
                            {columnMenuItems[5].bool && (
                              <CRMTableCell data-title="FB_Shared">
                                {data?.FB_Shared ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[6].bool && (
                              <CRMTableCell data-title="LinkedIn_Like">
                                {data?.LinkedIn_Like ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[7].bool && (
                              <CRMTableCell data-title="LinkedIn_Comment">
                                {data?.LinkedIn_Comment ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[8].bool && (
                              <CRMTableCell data-title="LinkedIn_Shared">
                                {data?.LinkedIn_Shared ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[9].bool && (
                              <CRMTableCell data-title="Insta_Like">
                                {data?.Insta_Like ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[10].bool && (
                              <CRMTableCell data-title="Insta_Comment">
                                {data?.Insta_Comment ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}
                            {columnMenuItems[11].bool && (
                              <CRMTableCell data-title="Insta_Shared">
                                {data?.Insta_Shared ? (<Checkbox checked disabled />) : (<Checkbox disabled />)}
                              </CRMTableCell>
                            )}


                            <CRMTableCell
                              align="center"
                              data-title="Action"
                              sx={[
                                DisplayFlex,
                                {
                                  cursor: "pointer",
                                  // mb: 1,
                                  justifyContent: matches ? "center" : "left",
                                },
                              ]}
                            >
                              <>
                                <EditIcon
                                  onClick={(e) => {
                                    EditList(data);
                                  }}
                                // color={Array.isArray(userRolePermissions) && userRolePermissions.includes(898) ? "#7ac6a4" : "gray"}
                                // disabled={Array.isArray(userRolePermissions) && userRolePermissions.includes(898) ? false : true}
                                />

                                <DeletedIcon
                                  onClick={() => {
                                    setSave(data);
                                  }}
                                // color={Array.isArray(userRolePermissions) && userRolePermissions.includes(899) ? "#f07167" : "gray"}
                                // disabled={Array.isArray(userRolePermissions) && userRolePermissions.includes(899) ? false : true}
                                />
                              </>
                            </CRMTableCell>
                          </CRMTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              ) : (
                <NoRecordsPage title={tabValue == 0 ? "All Contacts" : tabValue == 1 && "My Contacts"} />
              )}
            </JumboScrollbar>

            {/* Pagination code */}
            <CommonPagination
              totalPages={Math?.ceil(count / 10)}
              page={page}
              onChange={handleChangePage}
            />

          </React.Fragment>
        </Div>


        {/* delete a customer engagement */}
        <DialogBox
          Content={"Are you sure you want to delete this customer engagement?"}
          open={Boolean(save)}
          onClickNo={() => setSave(false)}
          onClickYes={DeleteList}
        />

        {/* boolean Function for switching the page between list and create */}
        <Div sx={{ display: Boolean(createEngagement) ? "block" : "none" }}>
          <CreateEngagement
            setcreateEngagement={setcreateEngagement}
            createEngagement={createEngagement}
            setListEngagement={setListEngagement}
            EngagementList={EngagementList}
            prop={transfer}
          />
        </Div>
      </JumboContentLayoutMain>
    </>
  );
};

export default CustomerEngagementList;
