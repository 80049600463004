import Div from "@jumbo/shared/Div";
import {
    Autocomplete,
    FormControl,
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import React, { memo, useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import { Cancelbutton, CustomButton } from "../widgets/StylesComponents";


const CreateEngagement = ({ createEngagement, setcreateEngagement, setListEngagement, prop, EngagementList }) => {
    const token = localStorage.getItem("accesstoken");
    const [CustomerData, setCustomerData] = useState({})
    const [PosterData, setPosterData] = useState({})
    const [checkboxes, setCheckboxes] = useState({
        FB_Like: false,
        FB_Comment: false,
        FB_Shared: false,
        LinkedIn_Like: false,
        LinkedIn_Comment: false,
        LinkedIn_Shared: false,
        Insta_Like: false,
        Insta_Comment: false,
        Insta_Shared: false
        // Add more checkboxes here if needed
    });

    const [formData, setFormData] = useState({
        Poster_id: '',
        Poster_ID: '',
        Customer_ID: '',
        Customer_Name: "",
        Email_ID: ''
    })

    const handleCheckboxChange = (event) => {
        const { name } = event.target;
        const currentValue = checkboxes[name]; // Get the current value of the checkbox

        setCheckboxes({
            ...checkboxes,
            [name]: !currentValue, // Toggle the current value
        });
    };

    const handleFieldChange = (fieldName) => (event) => {
        setFormData({
            ...formData,
            [fieldName]: event.target.value
        });
    };

    const ClearFields = () => {
        setFormData({
            ...formData,
            Poster_ID: '',
            Customer_ID: '',
            Customer_Name: '',
            Email_ID: ''
        });

        setCheckboxes({
            ...checkboxes,
            FB_Like: false,
            FB_Comment: false,
            FB_Shared: false,
            LinkedIn_Like: false,
            LinkedIn_Comment: false,
            LinkedIn_Shared: false,
            Insta_Like: false,
            Insta_Comment: false,
            Insta_Shared: false
        })

    }

    const handleSubmit = async () => {
        if (prop != undefined && prop.length != 0) {
            try {
                const payload = {
                    "id": prop?.id,
                    "Poster_ID": formData?.Poster_ID,
                    "SocialMedia_Id": formData?.Poster_id,
                    "Customer_ID": formData?.Customer_ID,
                    "Email_ID": formData?.Email_ID,
                    "FB_Like": checkboxes?.FB_Like,
                    "FB_Comment": checkboxes?.FB_Comment,
                    "FB_Shared": checkboxes?.FB_Shared,
                    "LinkedIn_Like": checkboxes?.LinkedIn_Like,
                    "LinkedIn_Comment": checkboxes?.LinkedIn_Comment,
                    "LinkedIn_Shared": checkboxes?.LinkedIn_Shared,
                    "Insta_Like": checkboxes?.Insta_Like,
                    "Insta_Comment": checkboxes?.Insta_Comment,
                    "Insta_Shared": checkboxes?.Insta_Shared,
                    "Created_By": localStorage.getItem("UserId"),
                    "Updated_By": localStorage.getItem("UserId")
                }
                console.log("payload:", payload)
                const response = await axios.put(`${BASE_URL}/user/CustomerEngagementsCRUD/`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                });
                console.log("handle put response:", response);
                setListEngagement(true);
                setcreateEngagement(false);
                EngagementList();
                // ClearFields();
                toast.success("Updated successfully", {
                    position: "top-right",
                    autoClose: 100,
                });
            } catch (error) {
                console.error("Error:", error);
                toast.error("Something went wrong");
            }
        } else {
            try {
                const payload = {
                    "Poster_ID": formData?.Poster_ID,
                    "SocialMedia_Id": formData?.Poster_id,
                    "Customer_ID": formData?.Customer_ID,
                    "Email_ID": formData?.Email_ID,
                    "FB_Like": checkboxes?.FB_Like,
                    "FB_Comment": checkboxes?.FB_Comment,
                    "FB_Shared": checkboxes?.FB_Shared,
                    "LinkedIn_Like": checkboxes?.LinkedIn_Like,
                    "LinkedIn_Comment": checkboxes?.LinkedIn_Comment,
                    "LinkedIn_Shared": checkboxes?.LinkedIn_Shared,
                    "Insta_Like": checkboxes?.Insta_Like,
                    "Insta_Comment": checkboxes?.Insta_Comment,
                    "Insta_Shared": checkboxes?.Insta_Shared,
                    "Created_By": localStorage.getItem("UserId"),
                    "Updated_By": localStorage.getItem("UserId")
                }
                console.log("payload:", payload)
                const response = await axios.post(`${BASE_URL}/user/CustomerEngagementsCRUD/`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                });
                console.log("handle submit response:", response);
                setListEngagement(true);
                setcreateEngagement(false);
                EngagementList();
                ClearFields();
                toast.success("Created successfully", {
                    position: "top-right",
                    autoClose: 100,
                });
            } catch (error) {
                console.error("Error:", error);
                toast.error("Something went wrong");
            }
        }

    };

    const handleClickBack = () => {
        setListEngagement(true);
        setcreateEngagement(false);
        ClearFields();
    }

    const GetCustomerData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/user/AccountFullList/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            console.log("GetCustomerData response:", response?.data)
            setCustomerData(response?.data);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Something went wrong");
        }

    };

    const GetPosterData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/user/SocialMediaPromotionallList/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            console.log("GetPosterData response:", response?.data)
            setPosterData(response?.data);
        } catch (error) {
            console.error("Error:", error);
            toast.error("Something went wrong");
        }

    };

    useEffect(() => {
        GetCustomerData();
        GetPosterData();
    }, []);

    useEffect(() => {
        if (prop != undefined && prop.length != 0) {
            console.log("edit prop:", prop)

            setFormData({
                ...formData,
                Poster_ID: prop?.Poster_ID,
                Customer_ID: prop?.Customer_ID?.id,
                Customer_Name: prop?.Customer_ID?.Organization_Name,
                Email_ID: prop?.Email_ID,
                Poster_id: prop?.SocialMedia_Id?.id
            });

            setCheckboxes({
                ...checkboxes,
                FB_Like: prop?.FB_Like,
                FB_Comment: prop?.FB_Comment,
                FB_Shared: prop?.FB_Shared,
                LinkedIn_Like: prop?.LinkedIn_Like,
                LinkedIn_Comment: prop?.LinkedIn_Comment,
                LinkedIn_Shared: prop?.LinkedIn_Shared,
                Insta_Like: prop?.Insta_Like,
                Insta_Comment: prop?.Insta_Comment,
                Insta_Shared: prop?.Insta_Shared
            })
        }
    }, [prop]);

    return (
        <>
            <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                {createEngagement} Customer Engagement
            </Typography>

            <Div sx={{ mt: 1, minHeight: "450px" }}>
                <Grid container>
                    <Grid item className="row" xs={12} sx={{ mt: 2 }}>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Typography className="col-12 input-label">
                                Poster ID<span className="required">*</span>
                            </Typography>

                            <FormControl className="col-12" sx={{ minWidth: "240px" }}>
                                {/* <TextField
                                    className="col-12 input-box"
                                    placeholder="Poster ID"
                                    value={formData?.Poster_ID}
                                    onChange={handleFieldChange('Poster_ID')}
                                    disabled={false}
                                ></TextField> */}
                                <Autocomplete
                                    className="search-select3"
                                    options={PosterData}
                                    getOptionLabel={(option) => {
                                        if (typeof option === "string") {
                                            return option;
                                        }
                                        if (option && option?.Promo_Id) {
                                            return option?.Promo_Id;
                                        }
                                        return "";
                                    }}
                                    value={formData?.Poster_ID || ""}
                                    // onChange={handleFieldChange('Customer_ID')}
                                    onChange={(e, newVal) => setFormData(formData => ({
                                        ...formData,
                                        Poster_ID: newVal?.Promo_Id,
                                        Poster_id: newVal?.id
                                    }))}
                                    id="outlined-basic"
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Choose Poster ID" />
                                    )}
                                />
                            </FormControl>
                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Typography className="col-12 input-label">
                                Customer ID<span className="required">*</span>
                            </Typography>
                            <FormControl className="col-12" sx={{ minWidth: "240px" }}>
                                <Autocomplete
                                    className="search-select3"
                                    options={CustomerData}
                                    getOptionLabel={(option) => {
                                        if (typeof option === "string") {
                                            return option;
                                        }
                                        if (option && option?.Organization_Name) {
                                            return option?.Organization_Name;
                                        }
                                        return "";
                                    }}
                                    value={formData?.Customer_Name || ""}
                                    onChange={(e, newVal) => setFormData(formData => ({
                                        ...formData,
                                        Customer_Name: newVal?.Organization_Name,
                                        Customer_ID: newVal?.id,
                                        Email_ID: newVal?.Mail
                                    }))}
                                    id="outlined-basic"
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="Choose  Customer Name" />
                                    )}
                                />
                            </FormControl>
                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Typography className="col-12 input-label">
                                Email ID<span className="required">*</span>
                            </Typography>
                            <FormControl className="col-12" sx={{ minWidth: "240px" }}>
                                <TextField
                                    className="col-12 input-box2"
                                    placeholder="Email_ID"
                                    value={formData?.Email_ID}
                                    onChange={handleFieldChange('Email_ID')}
                                    disabled={true}
                                ></TextField>
                            </FormControl>
                        </Div>
                    </Grid>


                    <Grid item className="row" xs={12} sx={{ mt: 2 }}>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    FB Like<span className="required">*</span>
                                </Typography>


                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    sx={{ color: "#cdcdcd" }}
                                    name='FB_Like'
                                    checked={checkboxes?.FB_Like}

                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", color: "#cdcdcd" }]}>


                                </FormControlLabel>
                            </Div>
                        </Div>

                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    FB Comment<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    name='FB_Comment'
                                    checked={checkboxes?.FB_Comment}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}
                                >

                                </FormControlLabel>

                            </Div>

                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    FB Shared<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    name='FB_Shared'
                                    checked={checkboxes?.FB_Shared}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                    </Grid>
                    <Grid item className="row" xs={12} sx={{ mt: 2 }}>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    LinkedIn Like<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    // className="col-6 input-box"
                                    name="LinkedIn_Like"
                                    checked={checkboxes?.LinkedIn_Like}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    LinkedIn Comment<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    name='LinkedIn_Comment'
                                    checked={checkboxes?.LinkedIn_Comment}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    LinkedIn Shared<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={
                                    <Checkbox
                                        name='LinkedIn_Shared'
                                        checked={checkboxes?.LinkedIn_Shared}
                                        onChange={handleCheckboxChange}
                                        disabled={false}
                                    />
                                } sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                    </Grid>

                    <Grid item className="row" xs={12} sx={{ mt: 2 }}>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    Insta Like<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    name='Insta_Like'
                                    checked={checkboxes?.Insta_Like}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    Insta Comment<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    name="Insta_Comment"
                                    checked={checkboxes?.Insta_Comment}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                        <Div className="col-sm-12 col-lg-4" sx={{ ml: -1 }}>
                            <Div className="row">
                                <Typography className="col-6 input-label">
                                    Insta Shared<span className="required">*</span>
                                </Typography>
                                <FormControlLabel className="col-6 input-label" control={<Checkbox
                                    name='Insta_Shared'
                                    checked={checkboxes?.Insta_Shared}
                                    onChange={handleCheckboxChange}
                                    disabled={false}
                                />} sx={[{ justifyContent: "center", alignItems: "center", }]}>

                                </FormControlLabel>
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>

            <Div className="flex items-center justify-start mt-3">
                <CustomButton
                    label={createEngagement === "create" ? "Save" : "Update"}
                    onClick={handleSubmit}
                    Icon="saveIcon"
                    sx={{ mr: 2 }}
                />
                <Cancelbutton onClick={handleClickBack} />
            </Div>
        </>
    );
};
export default memo(CreateEngagement);
