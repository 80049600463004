import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Pagination,
  Popover,
  Table,
  TableBody,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import "./email.css";
import Div from "@jumbo/shared/Div/Div";
import CreateEmailCampaign from "./CreateEmailCampaign";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { useEffect } from "react";
import EditEmailCampaign from "./EditEmailCampaign";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateDraftCampaign from "./CreateDraftCampaign";
import {
  componentTitle,
  DisplayFlex,
  FontStyle,
  StyledTabs,
  StyledTab,
  deletedWarnigMsg,
  Colors,
  dialogTitle,
  CRMTableHead,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { CustomWidthTooltip } from "app/pages/widgets/ToolTip";
import {
  DialogBox,
  CustomButton,
  DeletedIcon,
  EditIcon,
  AvatarCircle,
  avatarColorFun,
  CustomIcons,
  SelectDropdown,
  NoRecordsPage,
  Cancelbutton,
  CRMDateField,
} from "app/pages/widgets/StylesComponents";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { AutoHeight } from "app/redux/auth/localStorage";
import { useQuery, useQueryClient } from "react-query";
import { FaGear } from "react-icons/fa6";
import { BiFilterAlt } from "react-icons/bi";
import { TiEdit } from "react-icons/ti";
import { set } from "lodash";
import { GrPowerReset } from "react-icons/gr";
import { CiMail } from "react-icons/ci";

const EmailMainPage = ({ scrollHeight }) => {
  const [openCreateSent, setopenCreateSent] = useState(false);
  const [openEditEmailCampaign, setOpenEditEmailCampaign] = useState(false);
  const [openEditDraftCampaign, setOpenEditDraftCampaign] = useState(false);
  const [deleteSave, setDeleteSave] = useState(false);
  const [deleteRow, setdeleteRow] = useState(false);

  const [openAnchor, setOpenAnchor] = useState(null);
  const [isLoader, setIsLoading] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // For getting the Send mail Items
  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Campaign_Id", label: "Campaign Id", bool: true, width: "150px" },
    {
      sort: "Campaign_Name",
      label: "Campaign Name",
      bool: true,
      width: "150px",
    },
    { sort: "Recipients", label: "Recipients", bool: true },
    {
      sort: "NoofTimesMailOpened",
      label: "Mail Opened",
      bool: true,
      width: "100px",
    },
    {
      sort: "Archive_Flag",
      label: "Status",
      bool: true,
      width: "100px",
    },
    {
      sort: "NoofTimesMailClicked",
      label: "Mail Clicked",
      bool: true,
      width: "100px",
    },
  ]);
  const [columnMenuItems2, setColumnMenuItems2] = useState([
    { sort: "DraftCampaign_Id", label: "Draft Id", bool: true, width: "150px" },
    {
      sort: "Campaign_Name",
      label: "Campaign Name",
      bool: true,
      width: "150px",
    },
    { sort: "Subject_Line", label: "Subject", bool: true },
  ]);
  const [columnMenuItems3, setColumnMenuItems3] = useState([
    { sort: "Campaign_Id", label: "Campaign Id", bool: true },
    {
      sort: "Campaign_Name",
      label: "Campaign Name",
      bool: true,
    },
    { sort: "Schedule_Date", label: "Recipients", bool: true },
    {
      sort: "NoofTimesMailOpened",
      label: "Mail Opened",
      bool: true,
    },
    {
      sort: "NoofTimesMailClicked",
      label: "Mail Clicked",
      bool: true,
    },
  ]);
  const matches = useMediaQuery("(min-width:759px)");

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const [datas, setDatas] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [archive, setArchive] = useState([]);
  const [draftdata, setDraftdata] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [fadeEdit, setFadeEdit] = useState(false);

  //  For Pagination Table
  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);
  const [sentCount, setSentCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);
  const [ScheduleCount, setScheduleCount] = useState(0);

  const [CampaignName, setCampaignName] = useState("");
  const [FromName, setFromName] = useState("");

  const [showFilter, setShowFilter] = useState(false);
  const [filter, setfilter] = useState(false);
  const [loading, setloading] = useState(false);

  const [fromdate, setFromdate] = useState(new Date().toISOString());
  const [todate, setToDate] = useState(new Date().toISOString());
  const hasMounted = useRef(false);

  const getData = async () => {
    if (loading) return;

    setloading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserCampaignAdditional/?Is_Deleted=False&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setDatas((prevDatas) => {
        const newDatas = response?.data?.results || [];
        const updatedDatas = new Map();

        [...prevDatas, ...newDatas].forEach((item) => {
          updatedDatas.set(item.Campaign_Id, item);
        });

        return Array.from(updatedDatas.values());
      });

      setSentCount(response?.data?.count);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setloading(false);
    }
  };

  const scheduledData = async (pageNo) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserCampaignScheduledate/?Is_Deleted=False&page=${pageNo}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // setScheduleCount(response.data.length || 0);
      // setScheduled(response.data || []);
      return {
        datas: response?.data?.results || [],
        count: response?.data?.count || 0,
      };
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error here, show a message, log it, etc.
    }
  };

  const ArchiveData = async (pageNo) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserCampaignArchive/?Archive_Flag=True&page=${pageNo}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // Assuming res.data?.results is the correct property containing your data
      // console.log("response.data?.results", response.data?.results || []);
      setArchive(response.data?.results || []);
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error here, show a message, log it, etc.
    }
  };

  const filterSubmit = async (e) => {
    console.log("fromdate", todate, fromdate);

    if (sentCount != 0 && !Boolean(Math.ceil(sentCount / 10) >= page))
      return console.log("Out of Page");

    var payload = {
      Campaign_Name: CampaignName,
      From_Name: FromName,
      page: page,
      Created_Date: [fromdate, todate],
    };

    setloading(true);
    try {
      axios
        .post(`${BASE_URL}/user/UserCampaignfilter/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          if (res?.data?.results) {
            setfilter(true);

            setDatas((prevDatas) => {
              return page === 1
                ? res.data.results
                : Array.from(new Set([...prevDatas, ...res?.data?.results]));
            });

            setSentCount(res?.data?.count);
          }
          setShowFilter(false);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };
  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas].sort((a, b) =>
      value === "A-Z"
        ? a?.Name?.localeCompare(b?.Name)
        : b?.Name?.localeCompare(a?.Name)
    );
    setDatas(sortedData);
    // setIsSortName(value);
  };

  const DraftData = async (pageNo) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserDraftCampaignAdditional/?page=${pageNo}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // setDraftdata(response.data?.results || []);
      // setDraftCount(response.data?.count || 0);
      return {
        datas: response?.data?.results || [],
        count: response?.data?.count || 0,
      };
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error here, show a message, log it, etc.
    }
  };

  // For update Functionality
  const [update, setUpdate] = useState("");
  const [updateData, setUpdateData] = useState("");
  const [number, setNumber] = useState(1);
  const [number1, setNumber1] = useState(1);

  // For delete Functionality
  const Deletedata = (data) => {
    axios
      .delete(`${BASE_URL}/user/UserCampaignCRUD/?id=${update?.id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  // // // console.log(res.data);
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        // getEmailSentListsRefetch(page);
        handleCloseAction();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const Deletedata1 = (data) => {
    axios
      .delete(`${BASE_URL}/user/UserDraftCampaignCRUD/?id=${update?.id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  // // // console.log(res.data);
        toast.success("Draft Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        getEmailDraftListsRefetch(page);
        handleCloseAction();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };
  // For Getting the Sheduled Mail Items

  // const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalRecords = datas.length;

  // For Menu Items
  const [anchorElAction, setAnchorElAction] = useState(null);

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };
  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePageDraft = async (event, newPage) => {
    setPage2(newPage);
    await getEmailDraftListsRefetch(newPage);
  };
  const handleChangePageSent = async (event, newPage) => {
    setPage(newPage);
    // await getEmailSentListsRefetch(newPage);
  };

  const handleChangePageSchedule = async (event, newPage) => {
    setPage1(newPage);
    await getEmailScheduledListsRefetch(newPage);
  };

  const handleChange = (data) => {
    setOpenEditEmailCampaign(true);
    setTabValue(null);
    setUpdate(data);
  };

  const RefreshData = () => {
    setfilter(false);
    setloading(false);
    setDatas([]);
    setFromName("");
    setCampaignName("");
    setFromdate("");
    setToDate("");
    setShowFilter(false);
    if (tabValue === 0 && !filter) {
      getData();
    }
  };

  const getEmails = (text) => {
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const emails = text.match(emailPattern) || [];
    return emails;
  };

  const renderEmails = (emails) => {
    if (emails.length <= 1) {
      return emails.join(", ");
    }
    return `${emails.slice(0, 1).join(", ")} and ${emails.length - 1}+ more`;
  };

  // const {
  //   data: getEmailSentLists,
  //   isLoading: getEmailSentListsLoading,
  //   isError: getEmailSentListsError,
  //   refetch: getEmailSentListsRefetch,
  // } = useQuery(["getEmailSentLists", page, tabValue], () => getData(page), {
  //   staleTime: Infinity,
  // });

  const {
    data: getEmailScheduledLists,
    isLoading: getEmailScheduledListsLoading,
    isError: getEmailScheduledListsError,
    refetch: getEmailScheduledListsRefetch,
  } = useQuery(
    ["getEmailScheduledLists", page1, tabValue],
    () => scheduledData(page1),
    { staleTime: Infinity }
  );

  const {
    data: getEmailDraftLists,
    isLoading: getEmailDraftListsLoading,
    isError: getEmailDraftListsError,
    refetch: getEmailDraftListsRefetch,
  } = useQuery(
    ["getEmailDraftLists", page2, tabValue],
    () => DraftData(page2),
    { staleTime: Infinity }
  );

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100 &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // useEffect(() => {
  //   if (getEmailSentLists) {
  //     setDatas(getEmailSentLists?.datas || []);
  //     setSentCount(getEmailSentLists?.count || 0);
  //   }
  // }, [getEmailSentLists]);

  useEffect(() => {
    if (getEmailScheduledLists) {
      setScheduled(getEmailScheduledLists?.datas || []);
      setScheduleCount(getEmailScheduledLists?.count || 0);
    }
  }, [getEmailScheduledLists]);
  useEffect(() => {
    setPage(1);
    setDatas([]);
  }, [tabValue]);

  useEffect(() => {
    if (getEmailDraftLists) {
      setDraftdata(getEmailDraftLists?.datas || []);
      setDraftCount(getEmailDraftLists?.count || 0);
    }
  }, [getEmailDraftLists]);

  // Update data on initial mount and when tabValue, page, or filter changes
  useEffect(() => {
    const fetchData = async () => {
      if (tabValue === 0) {
        await getData();
      } else if (tabValue === 1) {
        const response = await DraftData(page1);
        setDraftdata(response.datas);
        setDraftCount(response.count);
      } else if (tabValue === 2) {
        const response = await scheduledData(page2);
        setScheduled(response.datas);
        setScheduleCount(response.count);
      }
    };
    fetchData();
  }, [tabValue, page, filter]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      getData();
    }
  }, []);

  console.log("page", page);

  return (
    <>
      <JumboContentLayoutMain>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Box className="flex items-center justify-between flex-wrap">
              <Typography
                sx={[componentTitle, { color: Colors?.gray, fontWeight: 700 }]}
              >
                Email Campaign
              </Typography>

              <Div className="flex items-center justify-between gap-2">
                <SelectDropdown
                  className="search-select3"
                  defaultValue={"Sent"}
                  onChange={(e, value) => {
                    const tabValues = {
                      Sent: 0,
                      Drafts: 1,
                      Scheduled: 2,
                    };
                    setTabValue(tabValues[value]);
                    console.log("value", value);
                  }}
                  options={["Sent", "Drafts", "Scheduled"]}
                />

                <SelectDropdown
                  className="search-select3"
                  defaultValue={"A-Z"}
                  onChange={sortData}
                  options={["A-Z", "Z-A"]}
                  width="70px"
                  clearButton={false}
                />
                {matches && (
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Div>
            </Box>
          </Box>

          {Boolean(tabValue == 0) && (
            <>
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={10.5}
                    md={6}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <IconButton
                      sx={{ color: Colors?.Mblue }}
                      onClick={(e) => {
                        setShowFilter(e.currentTarget);
                        setPage(1);
                      }}
                    >
                      <BiFilterAlt size={22} />
                    </IconButton>
                    <Typography
                      sx={[
                        dialogTitle,
                        { color: Colors?.gray, fontWeight: 400 },
                      ]}
                    >
                      ( {sentCount} ) Sent
                    </Typography>
                  </Grid>
                  {!matches && (
                    <Grid item xs={1} md={1}>
                      <IconButton
                        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                      >
                        <FaGear style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  )}

                  {/* Filter popover  */}

                  {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}
                  <Grid item xs={12} md={6}>
                    <Div
                      className="gap-3 flex-wrap"
                      sx={[DisplayFlex, { justifyContent: "right" }]}
                    >
                      <CustomButton
                        onClick={() => {
                          setTabValue(null);
                          setopenCreateSent(true);
                        }}
                        type="submit"
                        label="Create"
                        Icon="createIcon"
                        endIcon={true}
                      />
                    </Div>
                  </Grid>
                </Grid>
              </Box>

              <List sx={{ mt: 0 }}>
                {datas?.length > 0 ? (
                  datas?.map((data, idx) => {
                    // if (idx < 2) {

                    let image =
                      (BASE_URL +
                        data?.File?.split("https://backend.crmfarm.app")[1] ||
                        data?.File) ??
                      "";

                    return (
                      <Div
                        onMouseOver={() => setFadeEdit(idx)}
                        onMouseLeave={() => setFadeEdit(null)}
                        className=""
                        key={idx}
                        sx={{
                          minHeight: "124px",

                          p: { xs: 0.5, sm: 2 },
                          px: { xs: 0.5, md: 7 },
                          my: 2,

                          bgcolor: "white",
                          borderRadius: { xs: "5px", md: "10px" },
                          ":hover": {
                            backgroundColor: Colors?.lightgray,
                          },
                          cursor: "default",
                        }}
                      >
                        <Grid
                          container
                          alignItems={"center"}
                          spacing={2}
                          sx={{ pr: "5%" }}
                        >
                          <Grid item xs={12} md={1.5}>
                            <div
                              style={{ marginTop: "6px" }}
                              // onClick={() => handleClickOverviewContact(data)}
                            >
                              <AvatarCircle
                                size="90px"
                                fontSize="30px"
                                bgColor={avatarColorFun(idx)}
                                title={
                                  !Boolean(data?.File) && data?.Campaign_Name
                                }
                                image={Boolean(data?.File) ? image : false}
                              />
                            </div>
                          </Grid>
                          <Grid container spacing={1} item xs={12} md={10.5}>
                            <Grid item xs={12}>
                              <Box
                                sx={[
                                  DisplayFlex,
                                  {
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "18px",
                                      fontWeight: 700,
                                      color: `${Colors?.Mblue}`,
                                    },
                                  ]}
                                >
                                  {data?.Campaign_Name}
                                </Typography>
                                <Div sx={[DisplayFlex]}>
                                  <CustomButton
                                    onClick={(e) => {
                                      setOpenAnchor(e.target);
                                      setUpdate(data);
                                    }}
                                    sx={{ borderRadius: "25px" }}
                                    label="Action"
                                    bgColor={Colors?.Mblue}
                                    Icon="downArrowIcon"
                                    endIcon={true}
                                  />
                                </Div>
                              </Box>
                            </Grid>
                            {columnMenuItems?.map((Menu, subIdx) => {
                              return (
                                <>
                                  {Menu?.bool && (
                                    <Grid key={subIdx} item xs={12} md={4}>
                                      <Typography
                                        sx={[
                                          DisplayFlex,
                                          {
                                            fontSize: "14px",
                                            color: "#828282",
                                          },
                                        ]}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 700,
                                            color: "#5e5e5e",
                                          }}
                                        >
                                          {Menu?.label} :
                                        </span>{" "}
                                        {Menu?.sort === "Created_Date" ||
                                        Menu?.sort === "Updated_Date" ? (
                                          data[Menu?.sort]
                                        ) : Menu?.sort === "Recipients" ? (
                                          <CustomWidthTooltip
                                            title={(() => {
                                              const emails = getEmails(
                                                data[Menu?.sort]
                                              );
                                              return emails.length > 0 ? (
                                                <div>
                                                  {emails.map((item, ind) => (
                                                    <Typography
                                                      sx={[
                                                        {
                                                          fontSize: "14px",
                                                          color: "green",
                                                        },
                                                      ]}
                                                      key={ind}
                                                    >
                                                      {ind + 1}. {item}
                                                    </Typography>
                                                  ))}
                                                </div>
                                              ) : (
                                                "No emails found"
                                              );
                                            })()}
                                          >
                                            <span>
                                              {renderEmails(
                                                getEmails(data[Menu?.sort])
                                              ) || "-"}
                                            </span>
                                          </CustomWidthTooltip>
                                        ) : Menu?.sort === "Archive_Flag" ? (
                                          data[Menu?.sort] ? (
                                            <span
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* <CiMail

                                                onClick={() => {
                                                  handleChange(data);
                                                }}
                                                size={16}
                                                color={Colors?.Mblue}
                                              /> */}
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                Mail Sent
                                              </span>
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <CiMail
                                                onClick={() => {
                                                  handleChange(data);
                                                }}
                                                size={16}
                                                color={Colors?.Mblue}
                                              />
                                              <span>Pending</span>
                                            </span>
                                          )
                                        ) : (
                                          data[Menu?.sort] || "-"
                                        )}
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Div>
                    );
                    // }
                  })
                ) : (
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        p: 0.3,
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <NoRecordsPage title="Sent Mails" />
                  </Div>
                )}
              </List>
              {/* gear options */}
              <Menu
                id="menu-appbar"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
                sx={{
                  mt: 5,
                  maxHeight: "300px",
                  scrollBehavior: "smooth",
                }}
              >
                <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
                  Choose Fields
                </Typography>
                <FormGroup sx={{ p: 2 }}>
                  {columnMenuItems?.length > 0 &&
                    columnMenuItems?.map((column, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="info"
                              size="small"
                              checked={column?.bool}
                              onChange={(e) => {
                                let value = [...columnMenuItems];
                                value[index].bool = !column?.bool;
                                setColumnMenuItems(value);
                              }}
                            />
                          }
                          label={
                            <Typography id="Typography-root" sx={[FontStyle]}>
                              {column?.label}
                            </Typography>
                          }
                        />
                      );
                    })}
                </FormGroup>
              </Menu>

              {/* openAnchor Action popover */}
              <Popover
                id={Boolean(openAnchor) ? "simple-popover" : undefined}
                open={Boolean(openAnchor)}
                anchorEl={openAnchor}
                onClose={() => {
                  setOpenAnchor(null);
                  setIsLoading(false);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List sx={{ p: 1 }}>
                  <ListItemButton
                    sx={[
                      FontStyle,
                      {
                        transform: {
                          xs: "translateX(0px)",
                          sm: "translateX(-50px)",
                        },
                        color: Colors?.Mblue,
                        display: {
                          sm: "none !important",
                          xs: "flex !important",
                        },
                      },
                    ]}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "25px !important",
                        color: Colors?.Mblue,
                      }}
                    >
                      <TiEdit
                        style={{
                          fontSize: { xs: 18, md: 25 },
                          fontWeight: 900,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={[FontStyle]}>Edit Drafts</Typography>
                      }
                    />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      // setNumber(data?.id);
                      setdeleteRow(true);
                    }}
                    sx={{ borderRadius: "5px" }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: "25px !important", color: "tomato" }}
                    >
                      {CustomIcons?.deleteIcon}
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                    />
                  </ListItemButton>
                </List>

                <DialogBox
                  open={deleteRow}
                  Content={deletedWarnigMsg}
                  onClickNo={() => setdeleteRow(false)}
                  onClickYes={() => {
                    setdeleteRow(false);
                    Deletedata();
                  }}
                />
              </Popover>

              {/* Filter popover  */}

              <Popover
                open={Boolean(showFilter)}
                anchorEl={showFilter}
                onClose={() => setShowFilter(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
              >
                <Box>
                  <Div
                    sx={[
                      DisplayFlex,
                      CRMTableHead,
                      dialogTitle,
                      { px: 2, justifyContent: "space-between" },
                    ]}
                  >
                    <Typography id="Typography-root" sx={[dialogTitle]}>
                      Filters
                    </Typography>
                    <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                      <GrPowerReset size={16} />
                    </IconButton>
                  </Div>

                  <Div style={{ padding: "1rem" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Campaign Name
                        </Typography>
                        <TextField
                          type="text"
                          className="col-12 input-box2"
                          autoComplete="off"
                          placeholder="Campaign Name"
                          value={CampaignName}
                          onChange={(e) => {
                            setCampaignName(e.target.value);
                          }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          From Name
                        </Typography>
                        <TextField
                          type="text"
                          className="col-12 input-box2"
                          variant="outlined"
                          autoComplete="off"
                          placeholder="Enter From Name"
                          value={FromName}
                          onChange={(e) => {
                            setFromName(e.target.value);
                          }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          From Date
                        </Typography>
                        <CRMDateField
                          type="date"
                          className="col-12 input-box2"
                          value={fromdate}
                          onChange={(e) => {
                            setFromdate(e.target.value);
                          }}
                          id="createdDate"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          To Date
                        </Typography>
                        <CRMDateField
                          value={todate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          type="date"
                          className="col-12 input-box2"
                          id="lastDate"
                        />
                      </Grid>
                    </Grid>

                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        gap: "10px",
                      }}
                    >
                      {/* <Button
                    type="submit"
                    sx={[DialogBoxSaveButton, ButtonStyle, FontStyle]}
                  >
                    Apply
                  </Button> */}
                      <CustomButton
                        onClick={filterSubmit}
                        type="submit"
                        label="Apply"
                        Icon="filterIcon"
                      />
                      <Cancelbutton onClick={() => setShowFilter(null)} />
                    </Div>
                  </Div>
                </Box>
              </Popover>
            </>
          )}

          {Boolean(tabValue == 1) && (
            <>
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={10.5}
                    md={6}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <IconButton
                      sx={{ color: Colors?.Mblue }}
                      onClick={(e) => {
                        setShowFilter(e.currentTarget);
                        setPage(1);
                      }}
                    >
                      {/* <BiFilterAlt size={22} /> */}
                    </IconButton>
                    <Typography
                      sx={[
                        dialogTitle,
                        { color: Colors?.gray, fontWeight: 400 },
                      ]}
                    >
                      ( {draftCount || 0} ) Drafts
                    </Typography>
                  </Grid>
                  {!matches && (
                    <Grid item xs={1} md={1}>
                      <IconButton
                        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                      >
                        <FaGear style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  )}

                  {/* Filter popover  */}

                  {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}
                </Grid>
              </Box>

              <List sx={{ mt: 0 }}>
                {draftdata?.length > 0 ? (
                  draftdata?.map((data, idx) => {
                    // if (idx < 2) {

                    let image =
                      (BASE_URL +
                        data?.File?.split("https://backend.crmfarm.app")[1] ||
                        data?.File) ??
                      "";

                    return (
                      <Div
                        onMouseOver={() => setFadeEdit(idx)}
                        onMouseLeave={() => setFadeEdit(null)}
                        className=""
                        key={idx}
                        sx={{
                          minHeight: "124px",

                          p: { xs: 0.5, sm: 2 },
                          px: { xs: 0.5, md: 7 },
                          my: 2,

                          bgcolor: "white",
                          borderRadius: { xs: "5px", md: "10px" },
                          ":hover": {
                            backgroundColor: Colors?.lightgray,
                          },
                          cursor: "default",
                        }}
                      >
                        <Grid
                          container
                          alignItems={"center"}
                          spacing={2}
                          sx={{ pr: "5%" }}
                        >
                          <Grid item xs={12} md={1.5}>
                            <div
                              style={{ marginTop: "6px" }}
                              // onClick={() => handleClickOverviewContact(data)}
                            >
                              <AvatarCircle
                                size="90px"
                                fontSize="30px"
                                bgColor={avatarColorFun(idx)}
                                title={
                                  !Boolean(data?.File) && data?.Campaign_Name
                                }
                                image={Boolean(data?.File) ? image : false}
                              />
                            </div>
                          </Grid>

                          <Grid container spacing={1} item xs={12} md={10.5}>
                            <Grid item xs={12}>
                              <Box
                                sx={[
                                  DisplayFlex,
                                  {
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "18px",
                                      fontWeight: 700,
                                      color: `${Colors?.Mblue}`,
                                    },
                                  ]}
                                >
                                  {data?.Campaign_Name}
                                </Typography>
                                <Div sx={[DisplayFlex]}>
                                  <Div
                                    className={` ${
                                      idx === fadeEdit ? "fade-in-right" : ""
                                    } `}
                                    sx={{
                                      display: {
                                        xs: "none !important",
                                        sm:
                                          fadeEdit === idx
                                            ? "flex !important"
                                            : "none !important",
                                      },
                                    }}
                                  >
                                    <Div
                                      sx={[
                                        FontStyle,
                                        DisplayFlex,
                                        {
                                          cursor: "pointer",
                                          transform: "translateX(-50px)",
                                          color: Colors?.Mblue,
                                        },
                                      ]}
                                      onClick={() => {
                                        setOpenEditDraftCampaign(true);
                                        setUpdateData(data);
                                        setTabValue(null);
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: "25px !important",
                                          color: Colors?.Mblue,
                                        }}
                                      >
                                        <TiEdit
                                          style={{
                                            fontSize: 18,
                                            fontWeight: 900,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            sx={[
                                              FontStyle,
                                              { color: Colors?.Mblue },
                                            ]}
                                          >
                                            Edit Draft
                                          </Typography>
                                        }
                                      />
                                    </Div>
                                  </Div>
                                  <CustomButton
                                    onClick={(e) => {
                                      setOpenAnchor(e.target);
                                      setUpdate(data);
                                    }}
                                    sx={{ borderRadius: "25px" }}
                                    label="Action"
                                    bgColor={Colors?.Mblue}
                                    Icon="downArrowIcon"
                                    endIcon={true}
                                  />
                                </Div>
                              </Box>
                            </Grid>
                            {columnMenuItems2?.map((Menu, subIdx) => {
                              return (
                                <>
                                  {Menu?.bool && (
                                    <Grid key={subIdx} item xs={12} md={4}>
                                      <Typography
                                        sx={[
                                          {
                                            fontSize: "14px",
                                            color: "#828282",
                                          },
                                        ]}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 700,
                                            color: "#5e5e5e",
                                          }}
                                        >
                                          {Menu?.label} :
                                        </span>{" "}
                                        {Menu?.sort === "Created_Date" ||
                                        Menu?.sort === "Updated_Date"
                                          ? data[Menu?.sort]
                                          : data[Menu?.sort] || "-"}
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Div>
                    );
                    // }
                  })
                ) : (
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        p: 0.3,
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <NoRecordsPage title="Drafts" />
                  </Div>
                )}
              </List>
              {/* gear options */}
              <Menu
                id="menu-appbar"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
                sx={{
                  mt: 5,
                  maxHeight: "300px",
                  scrollBehavior: "smooth",
                }}
              >
                <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
                  Choose Fields
                </Typography>
                <FormGroup sx={{ p: 2 }}>
                  {columnMenuItems?.length > 0 &&
                    columnMenuItems?.map((column, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="info"
                              size="small"
                              checked={column?.bool}
                              onChange={(e) => {
                                let value = [...columnMenuItems];
                                value[index].bool = !column?.bool;
                                setColumnMenuItems(value);
                              }}
                            />
                          }
                          label={
                            <Typography id="Typography-root" sx={[FontStyle]}>
                              {column?.label}
                            </Typography>
                          }
                        />
                      );
                    })}
                </FormGroup>
              </Menu>

              {/* openAnchor Action popover */}
              <Popover
                id={Boolean(openAnchor) ? "simple-popover" : undefined}
                open={Boolean(openAnchor)}
                anchorEl={openAnchor}
                onClose={() => {
                  setOpenAnchor(null);
                  setIsLoading(false);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List sx={{ p: 1 }}>
                  <ListItemButton
                    sx={[
                      FontStyle,
                      {
                        transform: {
                          xs: "translateX(0px)",
                          sm: "translateX(-50px)",
                        },
                        color: Colors?.Mblue,
                        display: {
                          sm: "none !important",
                          xs: "flex !important",
                        },
                      },
                    ]}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "25px !important",
                        color: Colors?.Mblue,
                      }}
                    >
                      <TiEdit
                        style={{
                          fontSize: { xs: 18, md: 25 },
                          fontWeight: 900,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={[FontStyle]}>Edit Drafts</Typography>
                      }
                    />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      // setNumber(data?.id);
                      setdeleteRow(true);
                    }}
                    sx={{ borderRadius: "5px" }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: "25px !important", color: "tomato" }}
                    >
                      {CustomIcons?.deleteIcon}
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                    />
                  </ListItemButton>
                </List>
              </Popover>

              <DialogBox
                open={deleteRow}
                Content={deletedWarnigMsg}
                onClickNo={() => setdeleteRow(false)}
                onClickYes={() => {
                  setdeleteRow(false);
                  Deletedata1();
                }}
              />
            </>
          )}
          {Boolean(tabValue == 2) && (
            <>
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={10.5}
                    md={6}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <IconButton
                      sx={{ color: Colors?.Mblue }}
                      onClick={(e) => {
                        setShowFilter(e.currentTarget);
                        setPage(1);
                      }}
                    >
                      {/* <BiFilterAlt size={22} /> */}
                    </IconButton>
                    <Typography
                      sx={[
                        dialogTitle,
                        { color: Colors?.gray, fontWeight: 400 },
                      ]}
                    >
                      ( {ScheduleCount || 0} ) Scheduled
                    </Typography>
                  </Grid>
                  {!matches && (
                    <Grid item xs={1} md={1}>
                      <IconButton
                        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                      >
                        <FaGear style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  )}

                  {/* Filter popover  */}

                  {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}
                </Grid>
              </Box>

              <List sx={{ mt: 0 }}>
                {scheduled?.length > 0 ? (
                  scheduled?.map((data, idx) => {
                    // if (idx < 2) {

                    let image =
                      (BASE_URL +
                        data?.File?.split("https://backend.crmfarm.app")[1] ||
                        data?.File) ??
                      "";

                    return (
                      <Div
                        onMouseOver={() => setFadeEdit(idx)}
                        onMouseLeave={() => setFadeEdit(null)}
                        className=""
                        key={idx}
                        sx={{
                          minHeight: "124px",

                          p: { xs: 0.5, sm: 2 },
                          px: { xs: 0.5, md: 7 },
                          my: 2,

                          bgcolor: "white",
                          borderRadius: { xs: "5px", md: "10px" },
                          ":hover": {
                            backgroundColor: Colors?.lightgray,
                          },
                          cursor: "default",
                        }}
                      >
                        <Grid
                          container
                          alignItems={"center"}
                          spacing={2}
                          sx={{ pr: "5%" }}
                        >
                          <Grid item xs={12} md={1.5}>
                            <div
                              style={{ marginTop: "6px" }}
                              // onClick={() => handleClickOverviewContact(data)}
                            >
                              <AvatarCircle
                                size="90px"
                                fontSize="30px"
                                bgColor={avatarColorFun(idx)}
                                title={
                                  !Boolean(data?.File) && data?.Campaign_Name
                                }
                                image={Boolean(data?.File) ? image : false}
                              />
                            </div>
                          </Grid>

                          <Grid container spacing={1} item xs={12} md={10.5}>
                            <Grid item xs={12}>
                              <Box
                                sx={[
                                  DisplayFlex,
                                  {
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                  },
                                ]}
                              >
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "18px",
                                      fontWeight: 700,
                                      color: `${Colors?.Mblue}`,
                                    },
                                  ]}
                                >
                                  {data?.Campaign_Name}
                                </Typography>
                                {/* <Div sx={[DisplayFlex]}>
                                  <Div
                                    className={` ${idx === fadeEdit ? "fade-in-right" : ""
                                      } `}
                                    sx={{
                                      display: {
                                        xs: "none !important",
                                        sm:
                                          fadeEdit === idx
                                            ? "flex !important"
                                            : "none !important",
                                      },
                                    }}
                                  >
                                    <Div
                                      sx={[
                                        FontStyle,
                                        DisplayFlex,
                                        {
                                          cursor: "pointer",
                                          transform: "translateX(-50px)",
                                          color: Colors?.Mblue,
                                        },
                                      ]}
                                      onClick={() => {
                                        // setUpdate(data);
                                        // setShowAllContacts("edit");
                                      }}
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: "25px !important",
                                          color: Colors?.Mblue,
                                        }}
                                      >
                                        <TiEdit
                                          style={{ fontSize: 18, fontWeight: 900 }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <Typography
                                            sx={[
                                              FontStyle,
                                              { color: Colors?.Mblue },
                                            ]}
                                          >
                                            Edit Draft
                                          </Typography>
                                        }
                                      />
                                    </Div>
                                  </Div>
                                  <CustomButton
                                    onClick={(e) => {
                                      setOpenAnchor(e.target);
                                      // setUpdate(data);
                                    }}
                                    sx={{ borderRadius: "25px" }}
                                    label="Action"
                                    bgColor={Colors?.Mblue}
                                    Icon="downArrowIcon"
                                    endIcon={true}
                                  />
                                </Div> */}
                              </Box>
                            </Grid>
                            {columnMenuItems3?.map((Menu, subIdx) => {
                              return (
                                <>
                                  {Menu?.bool && (
                                    <Grid key={subIdx} item xs={12} md={4}>
                                      <Typography
                                        sx={[
                                          {
                                            fontSize: "14px",
                                            color: "#828282",
                                          },
                                        ]}
                                      >
                                        <span
                                          style={{
                                            fontWeight: 700,
                                            color: "#5e5e5e",
                                          }}
                                        >
                                          {Menu?.label} :
                                        </span>{" "}
                                        {Menu?.sort === "Created_Date" ||
                                        Menu?.sort === "Updated_Date"
                                          ? data[Menu?.sort]
                                          : data[Menu?.sort] || "-"}
                                      </Typography>
                                    </Grid>
                                  )}
                                </>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Div>
                    );
                    // }
                  })
                ) : (
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        p: 0.3,
                        justifyContent: "center",
                      },
                    ]}
                  >
                    <NoRecordsPage title="Scheduled Mails" />
                  </Div>
                )}
              </List>
              {/* gear options */}
              <Menu
                id="menu-appbar"
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
                sx={{
                  mt: 5,
                  maxHeight: "300px",
                  scrollBehavior: "smooth",
                }}
              >
                <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
                  Choose Fields
                </Typography>
                <FormGroup sx={{ p: 2 }}>
                  {columnMenuItems?.length > 0 &&
                    columnMenuItems?.map((column, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              color="info"
                              size="small"
                              checked={column?.bool}
                              onChange={(e) => {
                                let value = [...columnMenuItems];
                                value[index].bool = !column?.bool;
                                setColumnMenuItems(value);
                              }}
                            />
                          }
                          label={
                            <Typography id="Typography-root" sx={[FontStyle]}>
                              {column?.label}
                            </Typography>
                          }
                        />
                      );
                    })}
                </FormGroup>
              </Menu>

              {/* openAnchor Action popover */}
              <Popover
                id={Boolean(openAnchor) ? "simple-popover" : undefined}
                open={Boolean(openAnchor)}
                anchorEl={openAnchor}
                onClose={() => {
                  setOpenAnchor(null);
                  setIsLoading(false);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List sx={{ p: 1 }}>
                  <ListItemButton
                    sx={[
                      FontStyle,
                      {
                        transform: {
                          xs: "translateX(0px)",
                          sm: "translateX(-50px)",
                        },
                        color: Colors?.Mblue,
                        display: {
                          sm: "none !important",
                          xs: "flex !important",
                        },
                      },
                    ]}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "25px !important",
                        color: Colors?.Mblue,
                      }}
                    >
                      <TiEdit
                        style={{
                          fontSize: { xs: 18, md: 25 },
                          fontWeight: 900,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography sx={[FontStyle]}>Edit Drafts</Typography>
                      }
                    />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      // setNumber(data?.id);
                      setdeleteRow(true);
                    }}
                    sx={{ borderRadius: "5px" }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: "25px !important", color: "tomato" }}
                    >
                      {CustomIcons?.deleteIcon}
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                    />
                  </ListItemButton>
                </List>
              </Popover>
            </>
          )}

          {openCreateSent && (
            <CreateEmailCampaign
              // getEmailSentListsRefetch={getEmailSentListsRefetch}
              DraftData={getEmailDraftListsRefetch}
              setOpenCreateEmailCampaign={setopenCreateSent}
              openCreateEmailCampaign={openCreateSent}
              setTabValue={setTabValue}
            />
          )}

          {openEditEmailCampaign && (
            <EditEmailCampaign
              // getMainData={getEmailSentListsRefetch}
              update={update}
              // DraftData={getEmailDraftListsRefetch}
              setOpenEditEmailCampaign={setOpenEditEmailCampaign}
              openEditEmailCampaign={openEditEmailCampaign}
              setTabValue={setTabValue}
            />
          )}

          {openEditDraftCampaign && (
            <>
              <CreateDraftCampaign
                // getMainData={getEmailSentListsRefetch}
                update={updateData}
                setOpenEditDraftCampaign={setOpenEditDraftCampaign}
                openEditDraftCampaign={openEditDraftCampaign}
                DraftData={getEmailDraftListsRefetch}
                setTabValue={setTabValue}
              />
            </>
          )}
        </Box>

        {/* delete dialog box */}
        <DialogBox
          onClickNo={() => {
            setDeleteSave(false);
            setAnchorElAction(false);
          }}
          onClickYes={() => {
            setDeleteSave(false);
            if (tabValue == 0) {
              Deletedata(updateData);
            } else if (tabValue == 1) {
              Deletedata1(updateData);
            } else if (tabValue == 2) {
              // Deletedata1(updateData)
            } else if (tabValue == 3) {
              // Deletedata1(updateData)
            }
          }}
          open={deleteSave}
        />
      </JumboContentLayoutMain>
      {/* <>
        <ToastContainer />
      </> */}
    </>
  );
};

export default EmailMainPage;
