import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain'
import { Typography, Grid, TextField, Autocomplete, Box } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { ButtonStyle, ComponentHeading, DisplayFlex, FontStyle } from '../widgets/CRMStyles';
import Div from '@jumbo/shared/Div';
import axios from 'axios';
import { BASE_URL } from 'app/services/auth-services';
import { AutoHeight } from 'app/redux/auth/localStorage';
import moment from "moment";
import { useQuery } from "react-query";
import Chart from 'react-apexcharts';

const barDatas = [
  {
    label: 'Door to Door',
    value: 12,
    color: '#8884d8'
  },
  {
    label: 'Refferal',
    value: 7,
    color: '#82ca9d'
  },
  {
    label: 'Our website',
    value: 7,
    color: '#ffc658'
  },
  {
    label: 'Newspaper ad',
    value: 7,
    color: '#f1685e'
  },
  {
    label: 'Word of mouth',
    value: 6,
    color: '#ffafcc'
  },
  {
    label: 'No source set',
    value: 5,
    color: '#fb8500'
  },
];

function LeadSources() {
  const [alignment, setAlignment] = useState('deal');

  let startDate = moment().startOf('week');
  let endDate = moment().endOf('week');

  let ThisWeekStart = startDate?.format('YYYY-MM-DD');
  let ThisWeekEnd = endDate?.format('YYYY-MM-DD');

  const [datas, setDatas] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('This Year');
  const [selectedDate, setSelectedDate] = useState(ThisWeekStart);
  const [customDate, setCustomDate] = useState(false);
  const [currentDate, setCurrentDate] = useState(ThisWeekEnd);
  const [getSalesperson_name, setgetSalesperson_name] = useState("all")
  const [userNameLists, setUserNameLists] = useState([]);
  const [salesperson_id, setSalesperson_id] = useState(0);

  const token = localStorage.getItem("accesstoken")
  const item = "crmuseradmin1";
  const header = {
    headers: {
      "db": item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }

  const periodOptions = [
    'Previous Week',
    'Previous Month',
    'Previous Quarter',
    'Previous Year',
    'This Week',
    'This Month',
    'This Quarter',
    'This Year',
    'Last One Year',
    'Last Six Month',
    'Last Three Month',
    'Custom Date',
  ];

  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let TodayDate = `${year}-${month < 10 ? "0" + month : month}-${day}`;
  let piechartTitle = `Sales by Customer between ${selectedDate} & ${currentDate}`

  const handlePeriodChange = (e, newValue) => {
    console.log("handlePeriodChange newValue:", newValue);
    setSelectedPeriod(newValue);
    setSelectedDate(null);

    let date;

    let startDate; // for previous
    let endDate;

    switch (newValue) {
      case 'This Week':
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
        let ThisWeekStart = startDate?.format('YYYY-MM-DD');
        let ThisWeekEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(ThisWeekStart);
        setCurrentDate(ThisWeekEnd);
        setCustomDate(false);
        return;

      case 'This Month':
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        let ThisMonthStart = startDate?.format('YYYY-MM-DD');
        let ThisMonthEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(ThisMonthStart);
        setCurrentDate(ThisMonthEnd);
        setCustomDate(false);
        return;

      case 'This Quarter':
        startDate = moment().startOf('quarter');
        endDate = moment().endOf('quarter');
        let ThisQuarterStart = startDate?.format('YYYY-MM-DD');
        let ThisQuarterEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(ThisQuarterStart);
        setCurrentDate(ThisQuarterEnd);
        setCustomDate(false);
        return;

      case 'This Year':
        startDate = moment().startOf('year');
        endDate = moment().endOf('year');
        let ThisYearStart = startDate?.format('YYYY-MM-DD');
        let ThisYearEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(ThisYearStart);
        setCurrentDate(ThisYearEnd);
        setCustomDate(false);
        return;

      case 'Previous Week':
        startDate = moment().subtract(1, 'week').startOf('week');
        endDate = moment().subtract(1, 'week').endOf('week');
        let PrevWeekStart = startDate?.format('YYYY-MM-DD');
        let PrevWeekEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(PrevWeekStart);
        setCurrentDate(PrevWeekEnd);
        setCustomDate(false);
        return;

      case 'Previous Month':
        startDate = moment().subtract(1, 'month').startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');
        let PrevMonthStart = startDate?.format('YYYY-MM-DD');
        let PrevMonthEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(PrevMonthStart);
        setCurrentDate(PrevMonthEnd);
        setCustomDate(false);
        return;

      case 'Previous Quarter':
        startDate = moment().subtract(1, 'quarter').startOf('quarter');
        endDate = moment().subtract(1, 'quarter').endOf('quarter');
        let PrevQuarterStart = startDate?.format('YYYY-MM-DD');
        let PrevQuarterEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(PrevQuarterStart);
        setCurrentDate(PrevQuarterEnd);
        setCustomDate(false);
        return;

      case 'Previous Year':
        startDate = moment().subtract(1, 'year').startOf('year');
        endDate = moment().subtract(1, 'year').endOf('year');
        let MomentDateStart = startDate?.format('YYYY-MM-DD');
        let MomentDateEnd = endDate?.format('YYYY-MM-DD');
        setSelectedDate(MomentDateStart);
        setCurrentDate(MomentDateEnd);
        setCustomDate(false);
        return;

      case 'Last One Year':
        date = moment().subtract(1, 'year');
        setCustomDate(false);
        break;

      case 'Last Six Month':
        date = moment().subtract(6, 'months');
        setCustomDate(false);
        break;

      case 'Last Three Month':
        date = moment().subtract(3, 'months');
        setCustomDate(false);
        break;

      case 'Custom Date':
        setCustomDate(true);
        break;
      default:
        date = null;
    }
    let MomentDate = date?.format('YYYY-MM-DD');
    setSelectedDate(MomentDate);
    setCurrentDate(TodayDate)
  };

  const getLeadSource = async () => {
    try {
      if (currentDate !== "" && currentDate !== undefined && currentDate !== null) {
        let payload = {
          Is_Deleted: false,
          fromdate: selectedDate,
          todate: currentDate,
          userid: salesperson_id === 0 ? 'all' : salesperson_id
        }
        const response = await axios.post(`${BASE_URL}/user/AnalysticLeadSource/`, payload, header)

        console.log("response:", response);

        if (response.data?.lead_sources_within_period?.length > 0) {
          const list = response.data?.lead_sources_within_period?.map((res, idx) => ({
            leadSegment: res?.source,
            totalLeads: res?.total,
            color: getRandomColor(),
          }));
          console.log("lists", list);
          setDatas(list || []);
        } else {
          setDatas([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/useradmin/UsersList/`, header);
      console.log("Users List", response.data);
      return response?.data
    } catch (error) {
      console.log("Error in getting user list", error);
    }
  };

  const { data: getTerUserLists, isLoading: getTerUserListsLoading, isError: getTerUserListsError, refetch: getTerUserListsRefetch } = useQuery(
    ['getTerUserLists'],
    fetchUserData,
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (getTerUserLists) {
      let listdata = [{ 'username': 'all', 'id': 0 }]
      getTerUserLists?.map((opt) => {
        listdata.push({
          id: opt?.id,
          username: opt?.username
        })
      })
      setUserNameLists(listdata);
    }

  }, [getTerUserLists]);

  useEffect(() => {
    const fetchData = async () => {
      await getLeadSource();
    };
    fetchData();
  }, [selectedDate, currentDate, userNameLists, salesperson_id]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Prepare data for ApexCharts
  const chartOptions = {
    chart: {
      type: 'pie'
    },
    labels: datas.map(d => d.leadSegment),
    colors: datas.map(d => d.color),
    legend: {
      position: 'bottom'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const chartSeries = datas.map(d => d.totalLeads);

  return (
    <JumboContentLayoutMain>
      <Div sx={[{ p: { xs: 0, md: 2 }, px: { xs: 0, md: 4 }, }]}>
        <Typography sx={[ComponentHeading, { mb: 2 }]}>Lead Source</Typography>

        <Grid container columnSpacing={2} item xs={12} md={12} lg={6}>

          <Grid item className="col-12 col-md-6">
            <Typography className="col-12 input-label">
              Choose a Period<span className="required">*</span>
            </Typography>

            <Autocomplete
              className="col-12 search-select"
              value={selectedPeriod}
              options={periodOptions}
              onChange={(e, newValue) => handlePeriodChange(e, newValue)}
              renderInput={(params) => <TextField {...params} placeholder="Choose a Period" />}
            />
          </Grid>

          <Grid item className="col-6 col-md-3" style={{ display: customDate ? "block" : "none" }}>
            <Typography className="col-6 input-label">
              From<span className="required">*</span>
            </Typography>
            <TextField
              type={"date"}
              className="col-12 search-select"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </Grid>

          <Grid item className="col-6 col-md-3" style={{ display: customDate ? "block" : "none" }}>
            <Typography className="col-6 input-label">
              To
            </Typography>
            <TextField
              type={"date"}
              className="col-12 search-select"
              value={currentDate}
              onChange={(e) => setCurrentDate(e.target.value)}
            />
          </Grid>

          <Grid item className="col-12 col-md-6">
            <Typography className="col-12 input-label">
              Choose Rep<span className="required">*</span>
            </Typography>
            <Autocomplete
              className="col-12 search-select"
              options={userNameLists}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.username) {
                  return option?.username;
                }
                return "";
              }}
              value={getSalesperson_name}
              onChange={(e, newValue) => {
                setgetSalesperson_name(newValue?.username);
                setSalesperson_id(newValue?.id);
              }}
              renderInput={(params) => <TextField {...params} placeholder="Choose Rep" />}
            />
          </Grid>
        </Grid>
        <br />
        <Box sx={{ p: 3 }}>
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="pie"
            height={AutoHeight()}
          />
        </Box>
      </Div>
    </JumboContentLayoutMain>
  )
}

export default LeadSources
