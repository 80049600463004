// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  width: 100%;
  display: block;

  .nav-items {
    padding: 1rem 2rem;
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    margin-top: -11px;
  }

  .nav-items h3,
  .nav-items h4 {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .top-bar {
    padding: 1rem 2rem;
    background-color: rgba(249, 249, 249, 0.96);
  }

  .top-bar-1 {
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .top-bar-2 {
    background-color: white;
    padding: 3px;
    border-radius: 8px;
  }

  .MuiTab-root {
    min-width: 0px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/OutlookAPI/Components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;;EAEd;IACE,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,uBAAuB;IACvB,iBAAiB;EACnB;;EAEA;;IAEE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA;IACE,kBAAkB;IAClB,2CAA2C;EAC7C;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".navbar {\n  width: 100%;\n  display: block;\n\n  .nav-items {\n    padding: 1rem 2rem;\n    grid-column: 1 / -1;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    background: transparent;\n    margin-top: -11px;\n  }\n\n  .nav-items h3,\n  .nav-items h4 {\n    display: flex;\n    align-items: center;\n    gap: 7px;\n  }\n\n  .top-bar {\n    padding: 1rem 2rem;\n    background-color: rgba(249, 249, 249, 0.96);\n  }\n\n  .top-bar-1 {\n    height: 60px;\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n  }\n\n  .top-bar-2 {\n    background-color: white;\n    padding: 3px;\n    border-radius: 8px;\n  }\n\n  .MuiTab-root {\n    min-width: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
