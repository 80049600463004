import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonStyle,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  input_label,
} from "../../widgets/CRMStyles";
// import {
//   ButtonStyle,
//   ComponentHeading,
//   DialogBoxCancelButton,
//   DialogBoxSaveButton,
//   DisplayFlex,
//   FontStyle,
// } from "../widgets/CRMStyles";
import { toast } from "react-toastify";
import { BASE_URL } from "app/services/auth-services";
import {
  axiosInstance,
  Cancelbutton,
  CustomButton,
} from "app/pages/widgets/StylesComponents";
import { handleError } from "app/pages/components/ErrorHandler/handleError";

const CreateInstance = ({
  setCreateInstance,
  setListInstance,
  scrollHeight,
  createInstance,
}) => {
  const navigate = useNavigate();

  console.log("CreateInstance", createInstance);
  const [step, setStep] = useState(1);

  const [migrateBtn, setmigrateBtn] = useState(false);
  const [visitBtn, setvisitBtn] = useState(false);

  const [FinishDialog, setFinishDialog] = useState(false);

  const [checkBox, setCheckBox] = useState({
    Cond_1: false,
    Cond_2: false,
    loginCheck: false,
    finalstep: false,
    // subDominCreated: false,
  });

  const [Tenant_URL, setTenant_URL] = useState("");

  const [fieldData, setFieldData] = useState({
    instanceReqNo: createInstance?.RequestId,
    customerName: createInstance?.CustomerName,
    subDomainName: createInstance?.InstanceName,
    customer_id: createInstance?.Subscription_Id?.Customer_Id,
    Subscription_Id: createInstance?.Subscription_Id?.id,
  });
  useEffect(() => {
    setFieldData({
      instanceReqNo: createInstance?.RequestId,
      customerName: createInstance?.CustomerName,
      subDomainName: createInstance?.InstanceName,
      customer_id: createInstance?.Subscription_Id?.Customer_Id,
      Subscription_Id: createInstance?.Subscription_Id?.id,
    });
  }, [createInstance]);

  const [Customer_Details, setCustomer_Details] = useState({
    Company_Name: "",
    Company_Email: "",
  });

  const [error, setErrors] = useState({});

  const handleClickBack = () => {
    setCreateInstance(false);
    setListInstance(true);
    setErrors({});
  };

  const GettingTenant_Email_ID = async () => {
    console.log("GettingTenant_Email_ID..");
    console.log("${Number(fieldData.customer_id)}", fieldData?.customer_id);
    console.log("createInstance:", createInstance);

    try {
      await axios
        .get(
          `${BASE_URL}/user/AccountCRUD/?id=${Number(
            createInstance?.Subscription_Id?.Customer_Id
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            toast.success(res.data);
            // setFieldData({
            //   ...fieldData,
            //   instanceReqNo: createInstance?.RequestId,
            //   customerName: createInstance?.CustomerName,
            //   customer_id: createInstance?.Subscription_Id?.Customer_Id,
            //   Subscription_Id: createInstance?.SubscriptionId,
            // });
            setCustomer_Details({
              ...Customer_Details,
              Company_Name: res?.data?.Organization_Name,
              Company_Email: res?.data?.Mail,
            });
            console.log("res.data", res.data);
            console.log("Got Customer Details");
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  // Create database instance
  const createDataBaseFunc = async () => {
    // console.log("createDataBaseFunc",createDataBaseFunc)
    console.log(
      "localStorage?.getItem(UserId)",
      localStorage?.getItem("UserId")
    );
    const keys = Object.keys(localStorage);
    console.log(
      "localStorage?.getItem(UserId)",
      localStorage?.getItem("UserId")
    );

    // Iterate over each key and log its corresponding value
    keys.forEach((key) => {
      const value = localStorage.getItem(key);
      console.log(`Key: ${key}, Value: ${value}`);
    });

    let payload = {
      Instance_Request_No: fieldData?.instanceReqNo,
      Customer_Name: fieldData?.customerName,
      Customer_Id: Number(fieldData?.customer_id),
      Domain_Name: fieldData?.subDomainName,
      Subscription_Id: fieldData?.Subscription_Id,
      Database_Name: fieldData?.subDomainName,
      Customer_Email: Customer_Details?.Company_Email,
      Created_By: Number(localStorage?.getItem("UserId")),
      Updated_By: Number(localStorage?.getItem("UserId")),
    };
    console.log("payload", payload);

    const handledata = await handleError(
      axiosInstance.post(`/superadmin/InstanceCreationtCRUD/`, payload)
    );
    if (handledata) {
      toast.success("Created Successfully");
      localStorage.setItem("InstanceId", handledata?.id);
      setStep(step + 1);
    }
  };

  const VisitTenant_URL = () => {
    // window.open(Tenant_URL, '_blank');
    console.log("print", Tenant_URL);
    window.location.href = Tenant_URL;
    // window.location.href = "https://crmfarm.app/foyer/";
    setCheckBox({ ...checkBox, loginCheck: !checkBox?.loginCheck });
    setvisitBtn(true);
  };

  const Migratedatafun = async () => {
    // MyURL:'https://64.227.149.3:8000/superadmin/subscription/'
    console.log("Migratedatafun");
    //Getting data form super Admin database for subscription model
    try {
      await axios
        .get(
          `${BASE_URL}/superadmin/subscription/?id=${createInstance?.Subscription_Id?.id}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then(async (sub_res) => {
          console.log("Migratedatafun in subscription", sub_res);
          const Tenant_URL_BE = `https://backend.crmfarm.app/${fieldData?.subDomainName}`;
          // const Tenant_URL_BE = `https://127.0.0.1:8000/${fieldData?.subDomainName}`;
          setTenant_URL(`https://${fieldData?.subDomainName}.crmfarm.app/`);
          // setTenant_URL(`https://127.0.0.1:8000/${fieldData?.subDomainName}`);
          //calling plan data migration function --order-1=>SRN
          Migratedatafun_Plan(
            Tenant_URL_BE,
            sub_res?.data?.Plan_Id?.id,
            sub_res
          );
          //calling Subscription data migration function--order-2=>SRN
        });
    } catch (error) {
      console.error(error);
    }
  };

  const Migratedatafun_Plan = async (Tenant_URL_BE, id, sub_res) => {
    console.log("Migratedatafun_Plan");
    //Getting data form super Admin database for Plan model ${ids?.Plan_ID}
    try {
      await axios
        .get(`${BASE_URL}/superadmin/plan/?id=${id}`, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then(async (Plan_res) => {
          console.log("Migratedatafun in plan", Plan_res);
          if (Plan_res?.data) {
            toast.success(Plan_res?.data);
            console.log("Plan_res?.data:", Plan_res?.data);

            let plan_payload = {
              ID: Plan_res?.data?.id,
              Plan_Id: Plan_res?.data?.Plan_Id,
              Plan_Name: Plan_res?.data?.Plan_Name,
              Country: Plan_res?.data?.Country,
              UnitPrice: Plan_res?.data?.UnitPrice,
              Trialperiod: Plan_res?.data?.Trialperiod,
              Currency_Type: Plan_res?.data?.Currency_Type,
              No_Of_Users: Plan_res?.data?.No_Of_Users,
              AllocatedStorage: Plan_res?.data?.AllocatedStorage,
              PlanDetails: Plan_res?.data?.PlanDetails,
              Organization_Id: Plan_res?.data?.Organization_Id?.id,
              Noofcontacts: Plan_res?.data?.Noofcontacts,
              Noofmonths: Plan_res?.data?.Noofmonths,
              Plantype: Plan_res?.data?.Plantype,
              Setupfees: Plan_res?.data?.Setupfees,
              Addstorage: Plan_res?.data?.Addstorage,
              AllocatedAddons: Plan_res?.data?.AllocatedAddons,
            };

            console.log("plan_payload", plan_payload);
            console.log(
              " Plan_res?.data?.AllocatedAddons",
              Plan_res?.data?.AllocatedAddons
            );

            try {
              await axios
                .post(`${Tenant_URL_BE}/useradmin/plan/`, plan_payload, {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                })
                .then((res) => {
                  console.log("Migratedatafun in Plan", res);
                  if (res.data) {
                    toast.success(res.data);
                    // Migratedatafun_Addon(Tenant_URL_BE,ADDON)
                    Migratedatafun_Subscription(sub_res);
                    console.log("res.data", res.data);
                    console.log("success");
                  }
                });
            } catch (error) {
              console.error(error);
            }
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const Migratedatafun_Subscription = async (sub_res) => {
    const Tenant_URL_BE = `https://backend.crmfarm.app/${fieldData?.subDomainName}`;
    // const Tenant_URL_BE = `https://127.0.0.1:8000/${fieldData?.subDomainName}`;

    let subscription_payload = {
      ID: sub_res?.data?.id,
      Subscription_Id: sub_res?.data?.Subscription_Id,
      SubscriptionDate: sub_res?.data?.SubscriptionDate,
      No_Of_Users: sub_res?.data?.No_Of_Users,
      Storage_Size: sub_res?.data?.Storage_Size,
      UnitPrice: sub_res?.data?.UnitPrice,
      SubscriptionPeriod: sub_res?.data?.SubscriptionPeriod,
      TaxAmount: sub_res?.data?.TaxAmount,
      Transaction_Type: "online",
      TaxPercentage: sub_res?.data?.TaxPercentage,
      SuscriptionAmount: sub_res?.data?.SuscriptionAmount,
      AddtionalUsers: sub_res?.data?.AddtionalUsers,
      CancelledUsers: sub_res?.data?.CancelledUsers,
      AdditinalAddOns: sub_res?.data?.AdditinalAddOns,
      AdditinalStorage: sub_res?.data?.AdditinalStorage,
      InvoicegenFlag: sub_res?.data?.InvoicegenFlag,
      SubInvoicePaidFlag: sub_res?.data?.SubInvoicePaidFlag,
      Organization_Id: sub_res?.data?.Organization_Id?.id,
      Customer_Id: sub_res?.data?.Customer_Id?.id,
      Customer_Name: sub_res?.data?.Customer_Id?.Organization_Name,
      Plan_Id: sub_res?.data.Plan_Id?.id,
    };

    console.log("subscription_payload", subscription_payload);

    try {
      await axios
        .post(
          `${Tenant_URL_BE}/useradmin/subscription/`,
          subscription_payload,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Migratedatafun in subscription", res);
          if (res.data) {
            toast.success(res.data);
            console.log("res.data", res.data);
            console.log("Subscription success");
            setCheckBox({ ...checkBox, finalstep: !checkBox?.finalstep });
            setmigrateBtn(true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const SendingMailToTenant = () => {
    console.log("Mail Sent to Tenant");
    console.log(
      "Customer_Details.Company_Email",
      Customer_Details.Company_Email
    );
    let payload = {
      MailSubject: "Welcome to CRMFarm Family",
      Organization_Id: localStorage.getItem("OrganizationId"),
      MailBody: `<!DOCTYPE html><html><head><title>Hi</title></head><body><h1 style='color: green'>Welcome to CRMFarm Family</h1><img width='1' height='1' src= '{{url}}'><b>CRMFarm is now accessible for you via the URL below.</b><p>${Tenant_URL}</p><br><a href='${Tenant_URL}'></a><br>User Name: admin<br>Password: Admin@123<br>Note: Please change your password immediately for security reasons. <b></body></html>`,
      RecevierMailId: `${Customer_Details.Company_Email}`,
      UserId: localStorage.getItem("UserId"),
    };
    axios
      .post(`${BASE_URL}/superadmin/InstanceMailSent/`, payload, {
        // axios.post(`http://127.0.0.1:8000/superadmin/InstanceMailSent/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("email sending resspp", res);
        //updating Instance Request status
        try {
          payload = {
            RequestId: fieldData?.instanceReqNo,
            InstanceCreatedFlag: true,
          };
          axios
            .patch(`${BASE_URL}/superadmin/InstanceRequestlist/`, payload, {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
            .then((res) => {
              console.log("Instance Creation Updated..!");
            });
        } catch (error) {
          console.error(error);
        }
        setFinishDialog(true);
        setCreateInstance(false);
        setListInstance(true);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const nextButtonFunc = () => {
    let errors = {};
    console.log("inside function");

    switch (step) {
      case 1:
        {
          Object.keys(fieldData)?.map((field) => {
            if (!Boolean(fieldData[field])) {
              errors[field] = field;
              console.log("errors", errors);
            }
          });
          if (Object.keys(errors)?.length == 0) {
            createDataBaseFunc();
            // setStep(step + 1);
          }
        }
        break;
      case 2:
        {
          if (Boolean(checkBox?.Cond_1) && Boolean(checkBox?.Cond_2)) {
            setStep(step + 1);
          }
        }
        break;
      case 3:
        {
          if (Boolean(checkBox?.finalstep)) {
            //   setStep(step + 1);
            console.log("finalstep");
            SendingMailToTenant();
          }
        }
        break;
      default:
        toast.error("please give a value");
    }

    setErrors(errors);
  };

  // useEffect(()=>{
  //   setFieldData({...fieldData,instanceReqNo:createInstance?.RequestId,customerName:createInstance?.Company_Name, customer_id:createInstance?.Customer_id})
  // },[createInstance])

  useEffect(() => {
    GettingTenant_Email_ID();
  }, [createInstance]);

  return (
    <>
      <Typography sx={[ComponentHeading, { fontWeight: 600 }]}>
        Create Instance
      </Typography>
      {step > 0 && (
        <Div
          sx={{
            border: "1px solid lightgrey",
            p: 2,
            mt: 2,
            borderRadius: "5px",
          }}
        >
          <Grid container spacing={2}>
            <Grid container alignItems={"center"} item xs={12} md={6}>
              <Grid item xs={12} md={4} mt={1.5}>
                <Typography
                  className="input-label"
                  sx={{ color: step != 1 && "gray !important" }}
                >
                  Instance Request No <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  disabled={true}
                  // {step != 1}
                  value={createInstance?.RequestId}
                  onChange={(e) =>
                    setFieldData({
                      ...fieldData,
                      instanceReqNo: e?.target?.value,
                    })
                  }
                  className="input-box2"
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="Instance Request No"
                  variant="outlined"
                />
                {Boolean(error?.instanceReqNo) && (
                  <Typography color={"#FF0000"} sx={{ fontSize: "10px" }}>
                    <span className="required">*</span> Please enter a instance
                    request no
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems={"center"} item xs={12} md={6}>
              <Grid item xs={12} md={4} mt={1.5}>
                <Typography
                  className="input-label"
                  sx={{ color: step != 1 && "gray !important" }}
                >
                  Customer Name <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  disabled={true}
                  className="input-box2"
                  value={createInstance?.CustomerName}
                  onChange={(e) =>
                    setFieldData({
                      ...fieldData,
                      customerName: e?.target?.value,
                    })
                  }
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="Customer Name"
                  variant="outlined"
                />
                {Boolean(error?.customerName) && (
                  <Typography color={"#FF0000"} sx={{ fontSize: "10px" }}>
                    <span className="required">*</span> Please enter a customer
                    name
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* <Grid xs={12} mt={1.5}>
              <Div>
                <FormControlLabel
                  sx={{ ml: 1 }}
                  label={
                    <Typography
                      className="input-label"
                      sx={{ color: step != 1 && "gray !important" }}
                    >
                      Sub Domain creation created
                    </Typography>
                  }
                  control={
                    <Checkbox
                      color="info"
                      disabled={step != 1}
                      checked={Boolean(checkBox?.subDominCreated)}
                      onChange={() =>
                        setCheckBox({
                          ...checkBox,
                          subDominCreated: !checkBox?.subDominCreated,
                        })
                      }
                    />
                  }
                />
              </Div>
            </Grid> */}

            <Grid container alignItems={"center"} item xs={12} md={6}>
              <Grid item xs={12} md={4} mt={1.5}>
                <Typography
                  className="input-label"
                  sx={{ color: step != 1 && "gray !important" }}
                >
                  Sub Domain Name <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  disabled={step != 1}
                  value={fieldData?.subDomainName}
                  onChange={(e) =>
                    setFieldData({
                      ...fieldData,
                      subDomainName: e?.target?.value,
                    })
                  }
                  className="input-box2"
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="Sub Domain Name"
                  variant="outlined"
                />
                {Boolean(error?.subDomainName) && (
                  <Typography color={"#FF0000"} sx={{ fontSize: "10px" }}>
                    <span className="required">*</span> Please enter a sub
                    domain name
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid container alignItems={"center"} item xs={12} md={6}>
              <Grid item xs={12} md={4} mt={1.5}>
                <Typography
                  className="input-label"
                  sx={{ color: step != 1 && "gray !important" }}
                >
                  Database Name <span className="required">*</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField
                  disabled={step != 1}
                  value={fieldData?.subDomainName}
                  className="input-box2"
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  placeholder="Database Name"
                  variant="outlined"
                />
                {Boolean(error?.subDomainName) && (
                  <Typography color={"#FF0000"} sx={{ fontSize: "10px" }}>
                    <span className="required">*</span> Please enter a domain
                    name
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Div>
      )}

      {step > 1 && (
        <Div
          sx={{
            border: "1px solid lightgrey",
            p: 2,
            mt: 2,
            borderRadius: "5px",
          }}
        >
          <Div>
            <FormControlLabel
              disabled={step != 2}
              label={
                <Typography
                  className="input-label"
                  sx={{ color: step != 2 && "gray !important" }}
                >
                  All tables copied from the master database
                </Typography>
              }
              control={
                <Checkbox
                  color="info"
                  checked={Boolean(checkBox?.Cond_1)}
                  onChange={() =>
                    setCheckBox({ ...checkBox, Cond_1: !checkBox?.Cond_1 })
                  }
                />
              }
            />
          </Div>

          <Div>
            <FormControlLabel
              disabled={step != 2}
              label={
                <Typography
                  className="input-label"
                  sx={{ color: step != 2 && "gray !important" }}
                >
                  Establish Tenant Credentials in the Tenant Database (
                  <span style={{ color: "#00b4d8" }}>
                    {fieldData?.subDomainName || "No Database Name"}
                  </span>
                  )
                </Typography>
              }
              control={
                <Checkbox
                  color="info"
                  checked={Boolean(checkBox?.Cond_2)}
                  onChange={() =>
                    setCheckBox({ ...checkBox, Cond_2: !checkBox?.Cond_2 })
                  }
                />
              }
            />
          </Div>
        </Div>
      )}

      {step > 2 && (
        <Div
          sx={{
            border: "1px solid lightgrey",
            p: 2,
            mt: 2,
            borderRadius: "5px",
          }}
        >
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              onClick={() => {
                Boolean(checkBox.finalstep)
                  ? toast.success("migrate successfully", {
                      position: "top-right",
                    })
                  : toast.error("please click migrate button");
              }}
              disabled={step != 3}
              label={
                <Typography
                  className="input-label"
                  sx={{ color: step != 3 && "gray !important" }}
                >
                  Transfer Subscription information in Tenant Database
                </Typography>
              }
              control={
                <Checkbox
                  color="info"
                  checked={Boolean(checkBox?.finalstep)}

                  // onChange={() =>
                  //   setCheckBox({
                  //     ...checkBox,
                  //     finalstep: !checkBox?.finalstep,
                  //   })
                  // }
                />
              }
            />

            <CustomButton
              label="Migrate"
              Icon=""
              onClick={Migratedatafun}
              disabled={migrateBtn}
            />
          </Div>

          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              disabled={Boolean(checkBox?.finalstep)}
              label={
                <Typography
                  className="input-label"
                  sx={{ color: step != 2 && "gray !important" }}
                >
                  Login Check
                </Typography>
              }
              control={
                <Checkbox
                  color="info"
                  checked={Boolean(checkBox?.loginCheck)}
                  onChange={() =>
                    setCheckBox({
                      ...checkBox,
                      loginCheck: !checkBox?.loginCheck,
                    })
                  }
                />
              }
            />

            <CustomButton
              label="Visit"
              Icon=""
              onClick={VisitTenant_URL}
              disabled={visitBtn}
            />
          </Div>
        </Div>
      )}

      <Div sx={[DisplayFlex, { mt: 2 }]}>
        <Div>
          <Cancelbutton label="Cancel" onClick={handleClickBack} />
        </Div>
        <Div sx={{ ml: 1 }}>
          <CustomButton
            width={"130px"}
            label={step == 3 ? "Send Mail" : "Next Step"}
            Icon={step == 3 ? "sendIcon" : "nextIcon"}
            onClick={nextButtonFunc}
          />
        </Div>
      </Div>
      <Dialog
        open={FinishDialog}
        onClose={() => setFinishDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        xs={12}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              color: "black",
              lineHeight: 2,
            }}
          >
            URL(`{Tenant_URL}`) has been handed over to the customer's email
            address.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            label="Ok"
            Icon=""
            onClick={() => {
              setFinishDialog(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateInstance;
