import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { BASE_URL } from 'app/services/auth-services';
import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

const Article = ({ isArticleOpen, setIsArticleOpen, userInfo }) => {
    const [content, setContent] = useState('');
    const [open, setOpen] = useState(isArticleOpen)
    const [title, setTitle] = useState('')
    const [userInformation, setUserInformation] = useState(userInfo)
    const [url, setUrl] = useState('https://forms.gle/SxWF7P4RZzMKhuN68')
    const [description, setDescription] = useState('')

    useEffect(() => {
        setUserInformation(userInfo)
    }, [userInfo])

    const token = "AQXtbs_2sVs-pQ_odyuSTJbW47m_cScVRGz8OOhA6QaFW6AgUIJlu2qLIpAWNLvZxguwVhv-AvH4a7SlCV05Nf0E2tv1SM26Xv0Bc3wYYCToVE8YMu2PnAoFU88C7Wis3O1usbvcELNUaOPucx68xVtnVfOsXiI9X9bSd1rXmWEkx8rkPvmrPTsOc4zcU5H2o7MRxP_B2POdbnb5oXqyATSV3BwmQnqhI6GV0R7BC_dY3FjkPiqBmsvOtTGbSqkWjnjAa0HTOxn_4-jzSNDFV3v_3NkpT0mg_iKaNUmozLVTaaryRVoLAtqigjGSJhE4VilJofak8FjnQRGqFd8B46vHg-hGYg"


    const handleClose = () => {
        setIsArticleOpen(false)
    }
    const handlePost = async () => {
        let token = localStorage.getItem("accesstoken")
        const payload = {
            "sub": userInformation?.sub,
            "UserId": localStorage.getItem("UserId"),
            "ShareContent": content,
            "description": description,
            "url": url,
            "title": title,

        }
        const headers = {
            "db": localStorage.getItem("DBName"),
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        await axios.put(`${BASE_URL}/user/LinkdinCRUD/`, payload, { headers: headers })
            .then((res) => {
                console.log("Article res", res)
                if (res?.status == 200) {
                    toast.success(res?.data?.status)
                    handleClose()
                }
            })
            .then((error) => {
                console.error("Article post error", error)
            }).catch((error)=>{
                if (error.response && error.response.status === 403) {
                    const errorMessage = error.response.data.details || "You don't have permission to do it";
                    console.log(errorMessage);
                    toast.error(errorMessage, {
                      position: "top-right",
                      autoClose: 100,
                    });
                  } else {
                    toast.error(`${error}`, {
                      position: "top-right",
                      autoClose: 100,
                    });
                  }

            })
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "800px", minHeight: '500px' } }}
        >
            <DialogTitle>Article Post</DialogTitle>
            <DialogContent sx={{ marginTop: '10px' }}>
                {/* <ReactQuill theme="snow" value={content} onChange={setContent} /> */}
                <TextField
                    style={{ width: '100%', height: '100%', marginTop: '10px', marginBottom: '10px' }}
                    InputProps={{ style: { fontSize: '16px' } }}
                    placeholder="Title"
                    value={title}
                    onChange={(e) => { setTitle(e.target.value) }}

                />
                {/* <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                    style={{ height: '100px', marginBottom: '50px' }}
                /> */}

                <TextField
                    style={{ width: '100%', height: '100%', marginTop: '10px' }}
                    InputProps={{ style: { fontSize: '16px' } }}
                    placeholder="Content"
                    value={content}
                    multiline
                    rows={3}
                    onChange={(e) => { setContent(e.target.value) }}

                />
                <TextField
                    style={{ width: '100%', height: '100%', marginTop: '10px' }}
                    InputProps={{ style: { fontSize: '16px' } }}
                    placeholder="Url"
                    value={url}
                    onChange={(e) => { setUrl(e.target.value) }}

                />
                <TextField
                    style={{ width: '100%', height: '100%', marginTop: '10px' }}
                    InputProps={{ style: { fontSize: '16px' } }}
                    placeholder="description"
                    value={description}
                    onChange={(e) => { setDescription(e.target.value) }}

                />
            </DialogContent>
            <DialogActions>
                <Button className='dialog-cancel-btn' onClick={handleClose}>Close</Button>
                <Button className='dialog-save-btn' onClick={handlePost} >Post</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Article
