import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { FontStyle, componentTitle } from "app/pages/widgets/CRMStyles";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import { toLocaleCurrency } from "app/pages/widgets/StylesComponents";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { AutoHeight } from "app/redux/auth/localStorage";
import VSkeleton from "app/pages/components/skeleton/Skeleton";
import { useLocation } from "react-router-dom";
import SalesTargetViewList from "./SalesDatas";
import {
  useGetSalesPersonTarget,
  useSalesInvoiceAllAdditional,
  useTerritoryList,
  useTotalSalesTarget,
} from "../TerritoryAPI";
export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const TerritoryView = () => {
  const location = useLocation();
  const arraysEqual = (arr1, arr2) =>
    JSON.stringify(arr1) === JSON.stringify(arr2);

  const { salesInvoiceAllList } = useSalesInvoiceAllAdditional();
  const { territoryList, isLoading } = useTerritoryList();
  const { SalesTargetList } = useTotalSalesTarget();
  const { salesPersonTarget } = useGetSalesPersonTarget();

  const columnItems = [
    { label: "Territory Name", bool: true, width: "200px" },
    { label: "", bool: true, width: "50px" },
    { label: "Jan", bool: true },
    { label: "Feb", bool: true },
    { label: "Mar", bool: true },
    { label: "Apr", bool: true },
    { label: "May", bool: true },
    { label: "Jun", bool: true },
    { label: "Jul", bool: true },
    { label: "Aug", bool: true },
    { label: "Sep", bool: true },
    { label: "Oct", bool: true },
    { label: "Nov", bool: true },
    { label: "Dec", bool: true },
  ];

  const [openRows, setOpenRows] = useState({});
  const [open, setOpen] = useState("list");
  const [datas, setDatas] = useState([]);

  const toggleRow = (id) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id],
    }));
  };

  const renderRow = (data, level = 0) => {
    const isOpen = openRows[data?.TerritoryName];

    return (
      <React.Fragment key={data?.id}>
        <CRMTableRow>
          <CRMTableCell rowSpan={2}>
            <Typography
              onClick={() =>
                data?.children?.length > 0 && toggleRow(data?.TerritoryName)
              }
              sx={[
                FontStyle,
                {
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: level * 2,
                  cursor: "pointer",
                },
              ]}
            >
              <IconButton
                disabled={data?.children?.length === 0}
                sx={{
                  padding: "0px !important",
                  visibility: data?.children?.length > 0 ? "visible" : "hidden",
                }}
                size="large"
              >
                {isOpen ? <RiArrowDropDownLine /> : <RiArrowDropRightLine />}
              </IconButton>

              {data?.TerritoryName || data?.salesperson}
            </Typography>
          </CRMTableCell>
          <CRMTableCell>
            <Typography sx={[FontStyle, { color: "red" }]}>T</Typography>
          </CRMTableCell>
          {columnItems.slice(2).map((col, index) => (
            <CRMTableCell key={index} align="right">
              <Typography
                sx={{
                  fontSize: "13px !important",
                  color: "#3F404F !important",
                }}
              >
                {toLocaleCurrency(data?.Target?.[col.label] || 0)}
              </Typography>
            </CRMTableCell>
          ))}
        </CRMTableRow>
        <CRMTableRow>
          <CRMTableCell>
            <Typography sx={[FontStyle, { color: "green" }]}>A</Typography>
          </CRMTableCell>

          {columnItems.slice(2).map((col, index) => (
            <CRMTableCell key={index} align="right">
              <Typography
                sx={{
                  fontSize: "13px !important",
                  color: "#3F404F !important",
                }}
              >
                {toLocaleCurrency(data?.Acheivement?.[col.label] || 0)}
              </Typography>
            </CRMTableCell>
          ))}
        </CRMTableRow>
        {isOpen &&
          data?.children?.map((child, i) => (
            <React.Fragment key={child.id}>
              <TableRow
                sx={{
                  "&:nth-of-type(even)": {
                    backgroundColor: "Transparent",
                  },
                  // hide last border
                  "td, th": {
                    border: "0px solid !important",
                    height: "35px !important",
                    padding: "0px !important",
                    paddingX: "10px !important",
                  },
                }}
              >
                <CRMTableCell rowSpan={3} sx={{ paddingLeft: level * 2 }}>
                  <Typography sx={{ paddingLeft: level * 2 }}>
                    {child?.salesperson}
                  </Typography>
                </CRMTableCell>
                <CRMTableCell>
                  <Typography sx={[FontStyle, { color: "red" }]}>T</Typography>
                </CRMTableCell>
                {columnItems.slice(2).map((col, index) => (
                  <CRMTableCell key={index} align="right">
                    <Typography
                      sx={{
                        fontSize: "13px !important",
                        color: "#3F404F !important",
                      }}
                    >
                      {toLocaleCurrency(child?.Target?.[col.label] || 0)}
                    </Typography>
                  </CRMTableCell>
                ))}
              </TableRow>
              <TableRow
                sx={{
                  // "&:nth-of-type(odd)": {
                  //   backgroundColor: "#F3F3F3",
                  // },
                  // hide last border
                  "td, th": {
                    border: "0px solid !important",
                    height: "35px !important",
                    padding: "0px !important",
                    paddingX: "10px !important",
                  },
                }}
              >
                <CRMTableCell>
                  <Typography sx={[FontStyle, { color: "green" }]}>
                    A
                  </Typography>
                </CRMTableCell>
                {columnItems.slice(2).map((col, index) => (
                  <CRMTableCell key={index} align="right">
                    <Typography
                      sx={{
                        fontSize: "13px !important",
                        color: "#3F404F !important",
                      }}
                    >
                      {toLocaleCurrency(child?.Acheivement?.[col.label] || 0)}
                    </Typography>
                  </CRMTableCell>
                ))}
              </TableRow>
              <TableRow
                sx={{
                  // "&:nth-of-type(odd)": {
                  //   backgroundColor: "#F3F3F3",
                  // },
                  // hide last border
                  "td, th": {
                    border: "0px solid !important",
                    height: "35px !important",
                    padding: "0px !important",
                    paddingX: "10px !important",
                  },
                }}
              >
                <CRMTableCell>
                  <Typography sx={[FontStyle, { color: "orange" }]}>
                    V
                  </Typography>
                </CRMTableCell>
                {columnItems.slice(2).map((col, index) => (
                  <CRMTableCell key={index} align="right">
                    <Typography
                      sx={{
                        fontSize: "13px !important",
                        color: "#3F404F !important",
                      }}
                    >
                      {toLocaleCurrency(
                        child?.Acheivement?.[col.label] -
                          child?.Target?.[col.label]
                      )}{" "}
                      (
                      {(child?.Acheivement?.[col.label] /
                        child?.Target?.[col.label]) *
                        100 || 0}
                      %)
                    </Typography>
                  </CRMTableCell>
                ))}
              </TableRow>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  };

  const territoryTree = (territoryData) => {
    const territoryMap = new Map();
    territoryData?.data?.forEach((territory) => {
      territory.children = [];
      territory.Acheivement = {};
      let person = arraysEqual(
        territory?.AssignSalesPersons,
        territory?.User_Id
      );
      if (!person) {
        territory?.AssignSalesPersons?.map((item, ind) => {
          let don = {
            id: item,
            salesperson: territory?.Assign_Users[ind],
            Target: {},
            Acheivement: {},
            Variance: {},
          };
          Months?.map((month, i) => {
            don.Target[month] = 0;
            don.Acheivement[month] = 0;
            don.Variance[month] = 0;
          });
          territory?.children.push(don);
        });
      }
      Months?.map((month, i) => {
        territory.Acheivement[month] = 0;
      });

      territoryMap.set(territory.id, territory);
    });

    // Assign targets to children (salespeople) from salesPersonTarget
    salesPersonTarget?.forEach((SalesPerson) => {
      const parentTerritory = territoryMap.get(SalesPerson?.Territery_Id);

      console.log("parentTerritory", parentTerritory);
      if (parentTerritory) {
        parentTerritory.children = SalesPerson?.Target;
        parentTerritory?.children?.map((person) => {
          let target = salesInvoiceAllList?.find(
            (invoice) => invoice?.SalesUser_Id === person?.id
          );
          if (target) {
            let mont = Months[new Date(target?.Created_Date)?.getMonth()];
            console.log(
              "target---------------------------------------------------",
              mont,
              target
            );
            if (!person.Acheivement) {
              person.Acheivement = {};
              Months.map((mo) => {
                parentTerritory.Acheivement[mo] = 0;
                person.Acheivement[mo] = 0;
              });
            }
            if (mont) {
              person.Acheivement[mont] =
                person.Acheivement[mont] + Number(target?.Net_Amount || 0);
              parentTerritory.Acheivement[mont] += person.Acheivement[mont];
            }
          }
        });
      }
    });

    // Assign targets to territories from SalesTargetList
    SalesTargetList?.forEach((SalesTarget) => {
      const targetFind = territoryMap.get(SalesTarget?.Territery_Id);
      if (targetFind) {
        console.log("targetFind : ", targetFind);
        targetFind.Target = { ...SalesTarget.Target };
      }
    });

    console.log("salesInvoiceAllList : ", salesInvoiceAllList);
    console.log("territoryData : ", territoryData);
    console.log("SalesTargetList : ", SalesTargetList);
    console.log("salesPersonTarget : ", salesPersonTarget);

    return territoryList?.data;
  };

  // territoryTree(territoryList?.data);

  useEffect(() => {
    setDatas(territoryTree(territoryList) || []);
  }, [territoryList]);

  return (
    <JumboContentLayoutMain>
      <Box sx={{ display: open === "list" ? "" : "none" }}>
        <Box className="flex items-center gap-3 my-2">
          <Typography sx={[componentTitle, { mb: 2 }]}>
            {location?.pathname === "/reports/SalesAchievements"
              ? "Sales Achievements"
              : "Territory Overview"}
          </Typography>
        </Box>

        <Box sx={{ display: !Boolean(isLoading) ? "block" : "none" }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={AutoHeight(1.6)}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader>
              <CustomTableHeader
                headerMenuItems={columnItems}
                showGear={false}
              />
              <TableBody>
                {Boolean(datas?.length > 0) &&
                  datas?.map((element) => renderRow(element))}
              </TableBody>
            </Table>
          </JumboScrollbar>
        </Box>

        <Box sx={{ display: Boolean(isLoading) ? "block" : "none" }}>
          <VSkeleton type="table" />
        </Box>
      </Box>
    </JumboContentLayoutMain>
  );
};

export default TerritoryView;
