import React, { useEffect, useState } from "react";
import { TaskCUD, useTaskAllList, useTaskMyList } from "../task-api/taskAPI";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  Typography,
  Grid,
  TextField,
  Box,
  Popover,
  Tooltip,
} from "@mui/material";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { Skeleton } from "antd";
import {
  Cancelbutton,
  CommonPagination,
  CRMCustomLoader,
  CRMDateField,
  CustomButton,
  DateFormatter,
  DeletedIcon,
  DialogBox,
  Dragabledialog,
  EditIcon,
} from "app/pages/widgets/StylesComponents";
import Div from '@jumbo/shared/Div';
import {
  Colors,
  componentTitle,
  CRMTableHead,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  StyledTab,
  StyledTabs,
} from "app/pages/widgets/CRMStyles";
import { BiFilterAlt } from "react-icons/bi";
import TaskCU from "./TaskCU";
import { toast } from "react-toastify";
import { QueryClient } from "react-query";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import SearchBar from "app/pages/widgets/SearchField";
import { useSelector } from "react-redux";
import { selectUserRolePermissions } from "app/redux/actions/fetchUserRolePermissions";
import { GrPowerReset } from "react-icons/gr";


const TaskManagementList = () => {
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const userId = parseInt(localStorage.getItem("UserId"));
  const queryClient = new QueryClient();

  const { userRolePermissions, formPermissions, modulePermissions } = useSelector(selectUserRolePermissions);


  const [openModal, setOpenModal] = useState(false);
  const [save, setSave] = useState("");


  const [columnItems, setColumnItems] = useState([
    { sort: "TaskName", label: "Task Name", bool: true, width: "200px" },
    { sort: "Start_Date", label: "Start Date", bool: true },
    { sort: "Due_Date", label: "Due Date", bool: true },
    { sort: "first_name", label: "Owner", bool: false },
    { sort: "project_name", label: "Project Name", bool: true },
    { sort: "Priority", label: "Priority", bool: true },
    { sort: "Completion", label: "Completion", bool: true },
    { sort: "Assigned_By", label: "Assigned By", bool: true },
    { sort: "Task_Desc", label: "Task Description", bool: false },
  ]);

  const [listShow, setlistShow] = useState(0);
  const [datas, setDatas] = useState([]);
  const [currentTaskRequest, setCurrentTaskRequest] = useState({});
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [status, setStatus] = useState(false);

  // For filter Functionality
  const [showFilter, setShowFilter] = useState(false);
  const [meg, setMeg] = useState("");
  const [module, setModule] = useState("");
  const [fromdate, setFromdate] = useState(new Date().toISOString());
  const [todate, setToDate] = useState(new Date().toISOString());


  // search state
  const [searchData, setSearchData] = useState("");


  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    try {
      let url = '';
      let config = {};

      if (listShow === 0) {
        if (searchData) {
          url = `${BASE_URL}/user/Newtaskmanagmentsearch/?search=${searchData}&page=${newPage}`;
          config = {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            }
          };
        } else if (meg || module) {
          url = `${BASE_URL}/user/Newtaskmanagmentfilter/`;
          config = {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            data: {
              "TaskName": module,
              "Completion": "",
              "project_name": meg,
              "Start_Date": [fromdate, todate],
              "page": newPage,
            }
          };
          const res = await axios.post(url, config.data, config);
          setDatas(res?.data?.results || []);
          setCount(res?.data?.count || 0);
          setShowFilter(false);
          return; // Return early to avoid unnecessary requests
        } else {
          url = `${BASE_URL}/user/NewTaskManagemntAdditional/?Is_Deleted=False&page=${newPage}`;
          config = {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            }
          };
        }
      } else if (listShow === 1) {
        url = `${BASE_URL}/user/NewTaskManagemntMyAdditional/?Created_By=${userId}&page=${newPage}`;
        config = {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        };
      }

      if (url) {
        const response = await axios.get(url, config);
        const list = response?.data;
        if (listShow === 0) {
          setDatas(list?.results || []);
          setCount(list?.count || 0);
          setCount1(list?.count || 0);
        } else if (listShow === 1) {
          setDatas(list?.results || []);
          setCount2(list?.count || 0);
        } else {
          setDatas(list?.results || []);
          setCount(list?.count || 0);
        }
      }
    } catch (error) {
      console.error("Error fetching Task Management data:", error);
    }
  };



  // filter function
  const filterSubmit = async (e) => {
    e.preventDefault();
    setStatus(!status);
    setPage(1);
    setlistShow(0);

    await axios.post(`${BASE_URL}/user/Newtaskmanagmentfilter/`, {
      "TaskName": module,
      "Completion": "",
      "project_name": meg,
      "Start_Date": [fromdate, todate],
      "page": page,
    },
      {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).then((res) => {
      const list = res?.data;
      setDatas(list?.results || []);
      setCount(list?.count || 0);
      setCount1(list?.count || 0);
      // setCount2(list?.count || 0);
      setShowFilter(false);
    }).catch((error) => {
      console.log("error", error);
      setShowFilter(false);
    });
  };

  const RefreshData = () => {
    setMeg("");
    setModule("");
    setFromdate("");
    setToDate("");

    setStatus(!status);
    refetchTaskAllData(page);
  };

  // Search Functionality
  const SearchData = (e) => {
    let data = e.target.value;
    console.log("searchdata", data)
    setSearchData(data);
    data !== "" ? handleSearch(data) : refetchTaskAllData(page);
  };

  const handleSearch = async (search) => {
    setStatus(!status);
    setPage(1);
    setlistShow(0);

    if (Boolean(search)) {
      await axios.get(`${BASE_URL}/user/Newtaskmanagmentsearch/?search=${search}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        const list = res?.data
        setDatas(list?.results);
        setCount(list?.count || 0);
        setCount1(list?.count || 0);
        // setCount2(list?.count || 0);
      }).catch((error) => { });
    } else {
      refetchTaskAllData(page)
    }
  };


  const { taskAllData, isLoadingTaskAllData, refetchTaskAllData } = useTaskAllList();
  const { taskMyData, isLoadingTaskMyData, refetchTaskMyData } = useTaskMyList();


  useEffect(() => {
    if (Boolean(listShow === 0) && !searchData) {
      setDatas(taskAllData?.results);
      setCount(taskAllData?.count || 0);
      setCount1(taskAllData?.count || 0);
      setCount2(taskMyData?.count || 0);
    } else if (Boolean(listShow === 1) && !searchData) {
      setDatas(taskMyData?.results);
      setCount(taskMyData?.count || 0);
      setCount1(taskAllData?.count || 0);
      setCount2(taskMyData?.count || 0);
    }
  }, [taskMyData, taskAllData, listShow, searchData, status]);


  console.log("datas", datas)


  return (
    <>
      {/* Create modal */}
      <Dragabledialog open={openModal} maxWidth="lg">
        <TaskCU
          open={openModal}
          type={Object?.keys(openModal)?.length > 0 ? "Edit" : "Create"}
          setOpen={setOpenModal}
          currentTaskRequest={currentTaskRequest}
        />
      </Dragabledialog>

      <JumboContentLayoutMain>
        <Typography sx={[componentTitle]} className="mb-2">
          Task Management Lists
        </Typography>

        <div className="mb-2 flex items-center gap-2 justify-between">
          <div className="flex gap-2 items-center">
            <IconButton
              sx={{ color: Colors?.Mblue }}
              onClick={(e) => setShowFilter(e.currentTarget)}
            // onClick={dialogFilter}
            >
              <BiFilterAlt size={22} />
            </IconButton>

            {/* Filter popover  */}
            <Popover
              open={Boolean(showFilter)}
              anchorEl={showFilter}
              onClose={() => setShowFilter(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
            >
              <Box>
                <Div
                  sx={[
                    DisplayFlex,
                    CRMTableHead,
                    dialogTitle,
                    { px: 2, justifyContent: "space-between" },
                  ]}
                >
                  <Typography id="Typography-root" sx={[dialogTitle]}>
                    Filters
                  </Typography>
                  <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                    <GrPowerReset size={16} />
                  </IconButton>
                </Div>

                <form style={{ padding: "1rem" }} onSubmit={filterSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        id="Typography-root"
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        Task Name
                      </Typography>
                      <TextField
                        type="text"
                        className="col-12 input-box2"
                        autoComplete="off"
                        placeholder="Task Name"
                        value={module}
                        onChange={(e) => {
                          setModule(e.target.value);
                        }}
                        sx={{ mt: -0.5 }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        id="Typography-root"
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        Project Name
                      </Typography>
                      <TextField
                        type="text"
                        className="col-12 input-box2"
                        autoComplete="off"
                        placeholder="Project Name"
                        value={meg}
                        onChange={(e) => {
                          setMeg(e.target.value);
                        }}
                        sx={{ mt: -0.5 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        id="Typography-root"
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        From Date
                      </Typography>
                      <CRMDateField
                        id="fromdate"
                        name="fromdate"
                        value={fromdate}
                        onChange={(e) => setFromdate(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        id="Typography-root"
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        To Date
                      </Typography>
                      <CRMDateField
                        id="todate"
                        name="todate"
                        value={todate}
                        onChange={(e) => setToDate(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Div
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      gap: "10px",
                    }}
                  >
                    <CustomButton
                      type="submit"
                      label="Apply"
                      Icon="filterIcon"
                    />
                    <Cancelbutton onClick={() => setShowFilter(null)} />
                  </Div>
                </form>
              </Box>
            </Popover>

            <StyledTabs
              value={listShow}
              onChange={(e, newValue) => {
                if (!Boolean(searchData)) {
                  setlistShow(newValue);
                }
              }}
              aria-label="ant example"
            >
              <StyledTab label={`All Tasks ( ${count1 || 0} )`} />
              <StyledTab label={`My Task ( ${count2 || 0} )`} />
            </StyledTabs>

            {/* <Segmented
              onChange={(newValue) => setlistShow(newValue)}
              options={[
                {
                  value: 0,
                  icon: <BarsOutlined />,
                  label: `All List ( ${taskAllData?.count || 0} )`,
                },
                {
                  value: 1,
                  icon: <BarsOutlined />,
                  label: `My List ( ${taskMyData?.count || 0} )`,
                },
              ]}
            /> */}
          </div>

          <div className="flex gap-1 items-center">
            <SearchBar
              width={"280px !important"}
              border={"1px solid lightgray"}
              SearchData={(e) => SearchData(e)}
            // SearchData={(e)=>setSearchValue(e.target.value)}
            />

            {Array.isArray(userRolePermissions) && userRolePermissions.includes(897) && (
              <CustomButton
                onClick={() => setOpenModal(true)}
                label="Create"
                Icon="createIcon"
              // endIcon={true}
              />
            )}
          </div>

        </div>

        {(
          Boolean(listShow === 0) ? isLoadingTaskAllData : isLoadingTaskMyData
        ) ? (
          Array.from({ length: 3 }).map((_, i) => (
            <CRMCustomLoader />
          ))
        ) : (
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeOut={4000}
            autoHeightMin={420}
            id="no-more-tables"
          >
            <Table stickyHeader>
              <CustomTableHeader
                headerMenuItems={columnItems}
                setHeadermenuItems={setColumnItems}
                sortDatas={datas}
                setDatas={setDatas}
              />
              <TableBody>
                {datas?.length > 0 ? datas
                  // ?.slice(
                  //   page == 1 ? page * 0 : (page - 1) * count,
                  //   page == 1 ? page * 0 + count : (page - 1) * count + count
                  // )
                  ?.map((data, index) => {
                    return (
                      <CRMTableRow
                        key={index}
                      >
                        {columnItems[0].bool &&
                          <CRMTableCell>
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: 600 }}
                            >
                              {data?.TaskName || "Task Management"}
                            </Typography>
                            <Typography sx={[FontStyle]}>
                              <Tooltip title={data?.Task_Desc}>
                                {data?.Task_Desc?.length > 15
                                  ? data?.Task_Desc?.substring(
                                    0, 15
                                  ) + "..."
                                  : data?.Task_Desc}
                              </Tooltip>
                            </Typography>
                            <Typography></Typography>
                          </CRMTableCell>
                        }
                        {columnItems[1].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              <DateFormatter date={data?.Start_Date} />
                              {/* {data?.Start_Date?.split("T")[0] || "-"} */}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[2].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              <DateFormatter date={data?.Due_Date} />
                              {/* {data?.Due_Date?.split("T")[0] || " - "} */}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[3].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              {data?.Created_By?.first_name || " - "}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[4].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              {data?.Project?.project_name || " - "}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[5].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              {data?.Priority || " - "}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[6].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              {data?.Completion || " - "}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[7].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              {data?.Assigned_By?.first_name || " - "}
                            </Typography>
                          </CRMTableCell>
                        }
                        {columnItems[8].bool &&
                          <CRMTableCell>
                            <Typography sx={[FontStyle]}>
                              <Tooltip title={data?.Task_Desc}>
                                {data?.Task_Desc?.length > 15
                                  ? data?.Task_Desc?.substring(
                                    0, 15
                                  ) + "..."
                                  : data?.Task_Desc}
                              </Tooltip>
                            </Typography>
                          </CRMTableCell>
                        }
                        <CRMTableCell
                          align="center"
                          data-title="Action"
                          sx={[
                            // DisplayFlex,
                            {
                              display: "flex",
                              cursor: "pointer",
                              // mb: 1,
                              justifyContent: { xs: "left", md: "center" },
                            },
                          ]}
                        >
                          {data?.Created_By?.id === userId ? (
                            <>
                              <EditIcon
                                onClick={(e) => {
                                  setCurrentTaskRequest(data);
                                  setOpenModal(data);
                                }}
                                color={Array.isArray(userRolePermissions) && userRolePermissions.includes(898) ? "#7ac6a4" : "gray"}
                                disabled={Array.isArray(userRolePermissions) && userRolePermissions.includes(898) ? false : true}
                              />

                              <DeletedIcon
                                onClick={() => {
                                  setSave(data);
                                }}
                                color={Array.isArray(userRolePermissions) && userRolePermissions.includes(899) ? "#f07167" : "gray"}
                                disabled={Array.isArray(userRolePermissions) && userRolePermissions.includes(899) ? false : true}
                              />
                            </>
                          ) : (
                            <Typography sx={{ display: "flex", alignItems: "center" }}>
                              -
                            </Typography>
                          )}
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  }
                  ) : (
                  <TableRow align="center">
                    <CRMTableCell colSpan={columnItems?.length + 1}>No Records</CRMTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </JumboScrollbar>
        )}


        {/* pagination */}
        <CommonPagination
          totalPages={Math?.ceil(count / 10)}
          page={page}
          onChange={handleChangePage}
        />

      </JumboContentLayoutMain >


      {/* delete a task */}
      < DialogBox
        Content={"Are you sure you want to delete this task?"}
        open={Boolean(save)}
        onClickNo={() => setSave(false)}
        onClickYes={async () => {
          let res = await TaskCUD("delete", save?.id);
          if (res?.error) {
            queryClient.invalidateQueries("refetchTaskAllList");
            toast.success(`${res?.error} Deleted Unsuccessfully`);
          } else {
            toast.success("Deleted successfully");
            refetchTaskAllData();
            refetchTaskMyData();
            setSave(false);
          }
        }}
      />
    </>
  );
};

export default TaskManagementList;
