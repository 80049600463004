// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  overflow-y: scroll;
}

.email-body__header {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-body__header h2 {
  padding-bottom: 5px;
}

.email-body__icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-body__icons span {
  background-color: #4caf50; /* Replace with your secondary green color */
  border-radius: 50%;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-body__icons--icon {
  color: #388e3c; /* Replace with your primary green color */
  font-size: 1rem;
}

.email-body__icons--icon:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/OutlookAPI/Components/EmailBody/EmailBody.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,yBAAyB,EAAE,4CAA4C;EACvE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc,EAAE,0CAA0C;EAC1D,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".email-body {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 2rem;\n  overflow-y: scroll;\n}\n\n.email-body__header {\n  padding: 1rem 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.email-body__header h2 {\n  padding-bottom: 5px;\n}\n\n.email-body__icons {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.email-body__icons span {\n  background-color: #4caf50; /* Replace with your secondary green color */\n  border-radius: 50%;\n  padding: 0.6rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.email-body__icons--icon {\n  color: #388e3c; /* Replace with your primary green color */\n  font-size: 1rem;\n}\n\n.email-body__icons--icon:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
