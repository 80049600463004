import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";

// Create an axios instance with default headers
const axiosInstance = axios.create({
  headers: {
    db: localStorage.getItem("DBName"),
    Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// < --------------------------------------------------  get my organization --------------------------------------------------
const getOrganisation = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/useradmin/CRMorganizationCRUD/?id=${localStorage.getItem(
        "OrganizationId"
      )}`
    );
    // console.log("getOrganisation", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching get Organisation:", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < --------------------------------------------  get Territory Management List ----------------------------------------------

const getTerritoryManagementList = async () => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/user/TerritoryManagementFullList/`);
    // console.log("Territory Manage List response", response.data.results);
    return response.data;
  } catch (error) {
    console.error("Error fetching territory management list:", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < -------------------------------------------- get all users lists from admin ------------------------------------------------
const getUsersList = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/useradmin/UsersList/`
    );
    // console.log("Users List", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getting user list", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < -------------------------------------------- get sales Target ------------------------------------------------
const getSalesTarget = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/user/SalesTargetAdditional/`
    );
    // console.log("SalesTargetAdditional List", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getting Sales Target Additional", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < -------------------------------------------- post Sales Target ------------------------------------------------
const postSalesTarget = async (type, payload) => {
  try {
    const response = await axiosInstance?.[type](
      `${BASE_URL}/user/SalesTargetCRUD/`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error in post Sales Target CRUD", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < -------------------------------------------- get sales person Target ------------------------------------------------
const getSalesPersonTarget = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/user/SalesPersonTargetAdditional/`
    );
    // console.log("SalesPersonTargetAdditional List", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getting Sales Person Target Additional", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < ------------------------------------------- post Sales Person Targett -----------------------------------------------
const postSalesPersonTarget = async (type, payload) => {
  try {
    const response = await axiosInstance?.[type](
      `${BASE_URL}/user/SalesPersonTargetCRUD/`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error in post Sales Target CRUD", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < -------------------------------------------- get sales person Target ------------------------------------------------
const getSalesInvoiceAllAdditional = async () => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/user/SalesInvoiceAllAdditional/?Is_Deleted=False`
    );
    // console.log("SalesPersonTargetAdditional List", response.data);
    return response.data.results;
  } catch (error) {
    console.error("Error in getting Sales Person Target Additional", error);
    throw new Error(error.response?.data?.message || "Error fetching data");
  }
};

// < --------------------------------------------------  below use query --------------------------------------------------
const useSalesInvoiceAllAdditional = () => {
  const {
    data: salesInvoiceAllList,
    isLoading: isLoadingSalesInvoiceAllList,
    isError: isErrorSalesInvoiceAllList,
    refetch: refetchSalesInvoiceAllList,
  } = useQuery("refetchSalesInvoiceAllList", getSalesInvoiceAllAdditional, {
    staleTime: Infinity,
  });

  return {
    salesInvoiceAllList,
    isLoadingSalesInvoiceAllList,
    isErrorSalesInvoiceAllList,
    refetchSalesInvoiceAllList,
  };
};

const useTerritoryList = () => {
  const {
    data: organisation,
    isLoading: isOrganisationLoading,
    isError: isOrganisationError,
    refetch: refetchOrganisation,
  } = useQuery("getOrganisation", getOrganisation, { staleTime: Infinity });

  const {
    data: territoryList,
    isLoading: isTerritoryListLoading,
    isError: isTerritoryListError,
    refetch: refetchTerritoryList,
  } = useQuery("getTerritoryLists", getTerritoryManagementList, {
    staleTime: Infinity,
  });

  const isLoading = isTerritoryListLoading || isOrganisationLoading;
  const isError = isTerritoryListError || isOrganisationError;

  const refetch = () => {
    refetchTerritoryList();
    refetchOrganisation();
  };

  return {
    territoryList: {
      TerritoryName: organisation?.Organization_Name || "Empty Name",
      data: territoryList,
    },
    isLoading,
    isError,
    refetch,
  };
};

const useUserList = () => {
  const {
    data: userList,
    isLoading: isUserListLoading,
    isError: isUserListError,
    refetch: refetchUserList,
  } = useQuery("allUsersList", getUsersList, {
    staleTime: Infinity,
  });

  return {
    userList,
    isUserListLoading,
    isUserListError,
    refetchUserList,
  };
};

const useGetSalesPersonTarget = () => {
  const {
    data: salesPersonTarget,
    isLoading: isSalesPersonTargetLoading,
    isError: isSalesPersonTargetError,
    refetch: refetchSalesPersonTarget,
  } = useQuery("updateSalesPersonTarget", getSalesPersonTarget, {
    staleTime: Infinity,
  });

  return {
    salesPersonTarget,
    isSalesPersonTargetLoading,
    isSalesPersonTargetError,
    refetchSalesPersonTarget,
  };
};

const useTotalSalesTarget = () => {
  const {
    data: SalesTargetList,
    isLoading: isSalesTargetListLoading,
    isError: isSalesTargetListError,
    refetch: refetchSalesTargetList,
  } = useQuery("updateSalesTargetList", getSalesTarget, {
    staleTime: Infinity,
  });

  return {
    SalesTargetList,
    isSalesTargetListLoading,
    isSalesTargetListError,
    refetchSalesTargetList,
  };
};

export {
  useTerritoryList,
  useUserList,
  useTotalSalesTarget,
  useGetSalesPersonTarget,
  getOrganisation,
  postSalesTarget,
  postSalesPersonTarget,
  useSalesInvoiceAllAdditional,
};
