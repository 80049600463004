import { memo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Empty } from "antd";

// ----------------------------------------------------------------------

function NoChat({ ...other }) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box {...other}>
      <img
        src={require("../../../../assets/images/chat-and-chatting.jpg")}
        className="w-52 h-52"
      />
      {/* <Empty /> */}
    </Box>
  );
}

export default memo(NoChat);
