import Div from '@jumbo/shared/Div'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';

const FacebookSignIn = ({ isFacebookSignInOpen, setIsFacebookSignInOpen }) => {
    const [open, setOpen] = useState(isFacebookSignInOpen)
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [pageId, setPageId] = useState('')
    const handleClose = () => {
        setIsFacebookSignInOpen(false)
    }

    const getAppAccessToken = () => {
        const params = {
            "client_id": clientId,
            "client_secret": clientSecret,
            "grant_type": "client_credentials"
        }
        
        axios.post('https://graph.facebook.com/oauth/access_token', { params: params })
            .then((res) => console.log("app token response", res))
            .then((error) => {
                console.error("Error in app token response", error)
            })
    }
    const handleSignIn = () => {
        getAppAccessToken()
        setIsFacebookSignInOpen(false)
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "500px", minHeight: '300px' } }}
        >
            <DialogTitle className='dialog-head' >Facebook Configuration</DialogTitle>
            <DialogContent sx={{ marginTop: '10px' }}>
                <Div>
                    <Div className='row mt-3 '>
                        <Div className='col-4'>
                            <Typography
                                mb={0.3}
                                sx={{ fontWeight: 600, fontSize: "14px", }}
                            >
                                App Id
                            </Typography>
                        </Div>
                        <Div className="col-6">
                            <TextField value={clientId} type='text' onChange={(e) => { setClientId(e.target.value) }}></TextField>
                        </Div>
                    </Div>
                    <Div className='row mt-3'>
                        <Div className='col-4'>
                            <Typography
                                mb={0.3}
                                sx={{ fontWeight: 600, fontSize: "14px", }}

                            >
                                Client Secret
                            </Typography>
                        </Div>
                        <Div className="col-6">
                            <TextField
                                value={clientSecret}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => { setClientSecret(e.target.value) }}
                                InputProps={{
                                    endAdornment: <InputAdornment>
                                        <IconButton onClick={() => { setShowPassword(!showPassword) }}>{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>
                                    </InputAdornment>
                                }}
                            >
                            </TextField>
                        </Div>
                    </Div>
                    <Div className='row mt-3'>
                        <Div className='col-4'>
                            <Typography
                                mb={0.3}
                                sx={{ fontWeight: 600, fontSize: "14px", }}

                            >
                                Enter Page Id
                            </Typography>
                        </Div>
                        <Div className="col-6">
                            <TextField
                                value={pageId}
                                type='text'
                                onChange={(e) => { setPageId(e.target.value) }}
                            >
                            </TextField>
                        </Div>
                    </Div>
                </Div>
            </DialogContent >
            <DialogActions>
                <Button className='dialog-cancel-btn' onClick={handleClose}>Cancel</Button>
                <Button className='dialog-save-btn' onClick={handleSignIn}>Save</Button>
            </DialogActions>
        </Dialog >
    )
}

export default FacebookSignIn