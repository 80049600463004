import React from "react";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "react-query";
import Fab from "@mui/material/Fab";
import useStateRef from "react-usestateref";
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from "@mui/icons-material/Forum";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { MdCheck, MdClose } from "react-icons/md";
import crmlogo from "../../assets/images/crmlogo.png";

const LeadQuestionsPage = () => {
  const navigate = useNavigate();
  let queryClient = useQueryClient();

  const urlParams = new URLSearchParams(window.location.search);
  const Id = urlParams.get("id");
  const Industry = urlParams.get("industry_type");

  const Lead_Type = urlParams.get("Lead_Type");
  const Lead_Id = urlParams.get("LeadId");
  const Organization_Id = urlParams.get("Organization_Id");
  const UserId = urlParams.get("UserId");

  const [open, setOpen] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  console.log("check", urlParams.get("Lead_Type"), urlParams.get("LeadId"));
  const item = "crmuseradmin1";
  const [datas, setDatas] = useState([]);
  const [ans, setAns] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [answerid, setAnswerId] = useState([]);

  const [question, setQuestion] = useState([]);
  const [selectedValues, setSelectedValues] = useState(
    Array(datas.length).fill(undefined)
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Remove the HTML Tags
  const removeHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const QuestionMailSend = async (idlist) => {
    await axios
      .post(
        `${BASE_URL}/user/LeadsQuestionSubmitMailSend/`,
        {
          User_Id: UserId,
          LeadType: Lead_Type,
          Contactid: Lead_Id,
        },
        {
          headers: {
            db: item,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res Check", res?.data);
        handleSubmit(idlist);
      })
      .catch((err) => {
        // console.log("err", err);
        handleSubmit(idlist);
      });
  };

  // Select Data Function

  const handleSubmit = async (idlist) => {
    console.log("question", idlist);
    var payload = {
      Answer: idlist,
      Lead_Id: Lead_Id,
      Lead_Type: Lead_Type,
      Organization_Id: Organization_Id,
      UserId: UserId,
    };
    await axios
      .post(`${BASE_URL}/user/LeadQualifyAnswerQaulification/`, payload, {
        headers: {
          db: "crmuseradmin1",
        },
      })
      .then(async (res) => {
        console.log("Response", res.data);
        await queryClient.invalidateQueries("getQualifiedDataLists");
        QuestionMailSend();
        handleClose();

        // toast.success(`${res.data}`, {
        //     position: "top-right",
        //     autoClose: 500,
        // });
      })
      .catch((error) => {
        handleClose();
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .finally(() => {
        setSubmitted(true);
        setOpen(false);
        handleClose();
      });
  };

  const handleCheck = (data1, index) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = data1.id;
    console.log("newSelectedValues", newSelectedValues);
    setSelectedValues(newSelectedValues);
  };

  // Submission Form
  const handleReset = () => {
    setSubmitted(false);
    navigate("/");
  };

  useEffect(() => {
    UnqualifiedLead();
  }, [Id, Industry]);

  //  Chat Boat Design and FUnctionality

  const defaulttext = [
    { type: "bot", content: " Hi Foyer Customer, Let's chat" },
  ];
  const [chatBot, setChatBot] = useState(true);
  const [closeChat, setCloseChat] = useState(false);
  const [text, setText] = useState("");
  const [details, setDetails] = useState({
    Name: "",
    emailid: "",
    phoneNo: "",
  });
  const [sender, setSender] = useState(false);
  const [step, setStep] = useState(0);
  const [prestep, setprestep] = useState(1);
  const [messages, setMessages, message] = useStateRef(defaulttext);
  const [typing, settyping] = useState(false);
  const [validtion, setValidtion] = useState(true);
  const chatBottomRef = useRef(null);
  const [showButtons, setShowButtons] = useState(true); // State to control button visibility
  const [response, setResponse] = useState(null); // State to track user response
  const [selectedAnswerID, setSelectedAnswerID, selectedAnswerIDC] =
    useStateRef(null);
  const [givenQuestionID, setGivenQuestionID] = useState(null);

  // const BASE_URL = `https://127.0.0.1:8000/demo`;

  const header = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  // const urlParams = new URLSearchParams(window.location.search);
  // const Id = urlParams.get("id");
  // const Industry = urlParams.get("industry_type");
  // const Lead_Type = urlParams.get("Lead_Type");
  // const Lead_Id = urlParams.get("LeadId");
  // const Organization_Id = urlParams.get("Organization_Id");
  // const UserId = urlParams.get("UserId");

  // const Id = urlParams.get("id");
  // const Industry = "Automation";
  // const Lead_Type = "web";
  // const Lead_Id = 1;
  // const Organization_Id = 1;
  // const UserId = 1;

  //  New Changes

  const UnqualifiedLead = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/LeadQualifyQuestionsIndustryType/?Created_By=${Id}&Industry_Type=${Industry}`,
        {
          headers: {},
        }
      );
      console.log("UnqualifiedLead response", response?.data?.Answer_Id);
      setDatas(response?.data || []);
      setAns(response?.data?.Answer_Id || []);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const Lead_Chatbot = async (idx) => {
    settyping(true);
    try {
      // const payload = {
      //   Industry_Type: Industry,
      //   Lead_ID: Lead_Id,
      //   Lead_Type: Lead_Type,
      //   Answer_id: selectedAnswerIDC.current,
      //   Question_id: givenQuestionID,
      // };
      // const response = await axios.post(
      //   `${BASE_URL}/user/LeadsQuestionChatBot/`,
      //   payload,
      //   header
      // );
      // console.log("response in Lead_Chatbot:", response);

      settyping(false);

      setText(".");
      setText("");

      console.log("dfdsfsdfsd : ", idx);

      const { Question, Answer_Id, id } = datas[idx];

      setGivenQuestionID(id);
      console.log("question:", Question);
      console.log("answers:", Answer_Id);
      console.log("id:", givenQuestionID);

      let list4 = [...message?.current];
      console.log("Lead_Chatbot list2:", list4, message?.current);
      list4 = list4.map((item) => {
        // Destructure the object to exclude the 'options' key
        const { options, ...rest } = item;
        return rest;
      });

      list4.push({
        type: "bot",
        content: Question,
        options: Answer_Id,
      });
      setMessages(list4);
      console.log("list2 in Al bot:", list4);
      setText(".");
      setText("");
      console.log("message:", message);
    } catch (error) {
      console.error("AI_Chatbot error:", error);
    }
  };

  // Function to handle button clicks
  const handleButtonClick = (answer) => {
    if (answer === "Yes") {
      setResponse(answer);
      setShowButtons(false);
      const list2 = [...messages];
      console.log("list2:", list2);
      console.log("User clicked:", answer);
      list2.push(
        { type: "user", content: "Yes" },
        { type: "bot", content: "Thanks, Lets we start" }
      );
      setMessages(list2);
      console.log("list2:", list2);
      Lead_Chatbot(0); // Trigger your functionality here
    } else if (answer === "No") {
      setResponse(answer);
      setShowButtons(false); // Hide buttons after clicking
      // Add any additional logic for Yes/No responses here
      const list2 = [...messages];
      list2.push(
        { type: "user", content: "No" },
        { type: "bot", content: "We schedule it later" },
        { type: "bot", content: "Thank you" }
      );
      setMessages(list2);
      console.log("User clicked:", answer);
    }
  };

  const handleClose = () => {
    setChatBot(!chatBot);
    setCloseChat(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of the Enter key
      if (validtion) {
        Lead_Chatbot(); // Trigger your functionality here
      } else {
        Lead_Chatbot();
      }
    }
  };

  const handleCheckboxChange = (e, option, con) => {
    let list2 = [...message?.current];
    console.log("option in handleCheckboxChange:", option);
    const selectedAnswer = option.id;
    setSelectedAnswerID(selectedAnswer);
    let idlist = [...answerid, selectedAnswer];

    setAnswerId((prev) => [...prev, selectedAnswer]);
    console.log("setting selectedAnswer:", answerid, selectedAnswer);

    list2 = list2.map((item) => {
      // Destructure the object to exclude the 'options' key
      const { options, ...rest } = item;
      return rest;
    });

    // Update messages with user's selected answer
    list2.push({ type: "user", content: option?.Answer });
    list2.push({
      type: "bot",
      content:
        datas[datas?.length - 1]?.Question == con
          ? "Thank you for your submission! A sales person will contact you soon for further details."
          : "Thank you for your response!",
    });
    if (Boolean(datas[datas?.length - 1]?.Question == con)) {
      QuestionMailSend(idlist);
    }
    setMessages(list2);

    let find = datas?.findIndex((itm) => itm?.Question == con);
    Lead_Chatbot(find + 1);

    // Hide checkboxes after selection
    setShowButtons(false);

    // Trigger further processing if needed
    console.log("User selected answer:", selectedAnswer);
    // Process the selected answer as required, e.g., send to API
  };

  useEffect(() => {
    if (!showButtons) {
      chatBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, showButtons]); // Assuming messages is your state containing chat messages

  return (
    <>
      {/* <section className="h-full w-full bg-[#F5F7FA]"> */}
      <Div
        className="flex items-center gap-2 p-4 w-[100%] bg-[#F5F7FA]"
        sx={{ minHeight: "100vh" }}
      >
        <Div className="flex gap-5">
          <img
            src={require("../../assets/images/chat.jpg")}
            alt="image"
            style={{
              height: "36%",
              width: "45%", // ponkumar changes
            }}
          />
          <Div className="flex w-full h-full">
            {!closeChat && (
              <Div
                sx={{
                  // bgcolor: "background.paper",
                  // width: 500,
                  position: "relative",
                  borderRadius: "50%",
                  // right: 20,
                  // bottom: 30
                  // minHeight: 200,
                }}
              >
                {chatBot ? (
                  <Fab
                    onClick={() => {
                      setChatBot(!chatBot);
                      setMessages(defaulttext);
                      setText("");
                      setDetails({ Name: "", emailid: "", phoneNo: "" });
                      setStep(0);
                    }}
                    color="#002e5d"
                    aria-label="add"
                    sx={{
                      width: "50px !important",
                      height: "50px !important",
                      borderRadius: "50% !important",
                      background:
                        "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)", // ponkumar changes
                      color: "white",
                      // position: 'fixed !important',
                      // bottom: '20px !important',
                      // right: '20px !important',
                      "&:hover": {
                        background: "white", // ponkumar changes
                        color: "black",
                      },
                    }}
                  >
                    <CloseIcon />
                  </Fab>
                ) : (
                  <Fab
                    onClick={() => {
                      setChatBot(!chatBot);
                      setShowButtons(true);
                    }}
                    color="#002e5d"
                    aria-label="add"
                    sx={{
                      width: "50px !important",
                      height: "50px !important",
                      borderRadius: "50% !important",
                      background:
                        "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)", // ponkumar changes
                      color: "white",
                      // position: 'fixed !important',
                      // bottom: '20px !important',
                      // right: '20px !important',
                      "&:hover": {
                        background: "white", // ponkumar changes
                        color: "black",
                      },
                    }}
                  >
                    <ForumIcon />
                  </Fab>
                )}
              </Div>
            )}
            {chatBot ? (
              <Div
                sx={{
                  bgcolor: "background.paper",
                  // width: 650,
                  position: "absolute",
                  right: 40, // ponkumar changes
                  borderRadius: "10px",
                  width: "42%",
                  // height: "89vh",      // ponkumar changes
                  // overflow: "scroll",       // ponkumar changes
                }}
              >
                <Div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 10px",
                    // backgroundColor: "#002e5d",
                    background:
                      "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)", // ponkumar changes
                    borderBottom: "0.1px solid #ccc",
                    justifyContent: "space-between",
                    mb: 1,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <Typography
                    style={{ fontSize: 16, color: "#fefae0", letterSpacing: 1 }}
                  >
                    Luna
                  </Typography>
                  {/* <IconButton
                  style={{ padding: "5px", color: "#fefae0" }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton> */}
                </Div>
                <JumboScrollbar
                  autoHeight={true}
                  // autoHeightMin={580}
                  autoHeightMin={"84vh"}
                  autoHideTimeOut={4000}
                >
                  <Div
                    sx={{
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      padding: "5px", // ponkumar changes
                      mb: "5px", // ponkumar changes
                      height: "84vh", // ponkumar changes
                      // backgroundImage: `url(${crmlogo})`,
                      backgroundSize: "50%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      // opacity: 0.3,
                      zIndex: 0,
                    }}
                  >
                    {console.log("message inside Return:", message)}
                    {console.log(
                      "message?.current inside Return:",
                      message?.current
                    )}

                    {message?.current?.map((con, idx) => {
                      return (
                        <div
                          style={{
                            marginTop: "20px",
                            padding: "10px",
                          }}
                        >
                          <div
                            key={idx}
                            style={{
                              display: "flex",
                              justifyContent:
                                con?.type === "user" ? "right" : "left",
                            }}
                          >
                            <Typography
                              // bgcolor={con?.type == "user" ? "#164c7b" : "#f4f7f9"}     // ponkumar changes
                              p={1}
                              borderRadius={3}
                              color={
                                con?.type === "user" ? "white" : "text.primary"
                              }
                              gutterBottom
                              align={con?.type === "user" ? "right" : "left"}
                              sx={{
                                fontSize: 14,
                                display: "inline-flex",
                                // justifySelf: "left",
                                justifySelf: "center", // ponkumar changes
                                minWidth: "80px",
                                background:
                                  con?.type == "user"
                                    ? "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)"
                                    : "#f4f7f9", // ponkumar changes
                              }}
                            >
                              {con?.content}
                            </Typography>
                          </div>
                          {/* {con?.options && (
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      backgroundColor:
                                        con?.type == "user"
                                          ? "#164c7b"
                                          : "#f4f7f9",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    {con.options?.map((option, index) => (
                                      <Typography
                                        p={1}
                                        sx={{
                                          fontSize: 14,
                                          display: "block",
                                          justifySelf: "left",
                                        }}
                                        color={
                                          con?.type === "user"
                                            ? "white"
                                            : "text.primary"
                                        }
                                        gutterBottom
                                        align={
                                          con?.type === "user"
                                            ? "right"
                                            : "left"
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          id={`answer-${option.id}`}
                                          name="answer"
                                          value={option.id}
                                          onChange={() =>
                                            handleCheckboxChange(option)
                                          }
                                        />
                                        <label htmlFor={`answer-${option.id}`}>
                                          {option.answer}
                                        </label>
                                      </Typography>
                                    ))}
                                  </div>
                                )} */}

                          <Div
                            style={{
                              fontSize: "5px",
                              // backgroundColor:
                              //   con?.type === "user"
                              //     ? "#164c7b"
                              //     : "#f4f7f9",
                              borderRadius: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              width: "100%",
                              p: 1,
                            }}
                          >
                            {con?.options &&
                              con.options?.map((option, index) => {
                                return (
                                  <Div
                                    sx={{
                                      display: "flex",
                                      p: 1,
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <Typography
                                      color={
                                        con?.type === "user"
                                          ? "white"
                                          : "text.primary"
                                      }
                                      gutterBottom
                                      align={
                                        con?.type === "user" ? "right" : "left"
                                      }
                                      onClick={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          option,
                                          con?.content
                                        )
                                      }
                                      sx={{
                                        fontSize: 14,
                                        p: 1,
                                        cursor: "pointer",
                                        // boxShadow: "1px 1px 3px 0px #2189d9 !important",
                                        borderRadius: "5px",
                                        border: "0.5px solid #2189d9",
                                        color: "#2189d9",
                                        "&:hover": {
                                          // color: "blue",
                                          // bgcolor: "#002e5d",
                                          color: "#2189d9",
                                          bgcolor: "white",
                                        },
                                      }}
                                    >
                                      {option?.Answer}
                                    </Typography>
                                  </Div>
                                );
                              })}
                          </Div>
                        </div>
                      );
                    })}

                    {/* Conditionally render the typing message */}
                    {/* {typing && (
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <Typography
                        bgcolor="#f4f7f9"
                        p={1}
                        borderRadius={3}
                        sx={{
                          fontSize: 14,
                          display: "inline-flex",
                          justifySelf: "left",
                        }}
                        color="text"
                        gutterBottom
                        align="left"
                      >
                        Typing...
                      </Typography>
                    </div>
                    )} */}

                    {/* Render Yes/No buttons */}
                    {showButtons && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleButtonClick("Yes")}
                          startIcon={<MdCheck />}
                          sx={{ marginRight: "10px", width: "60px" }}
                        >
                          Yes
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleButtonClick("No")}
                          startIcon={<MdClose />}
                          sx={{ width: "60px" }}
                        >
                          No
                        </Button>
                      </div>
                    )}

                    {/* Ref to scroll to bottom */}
                    <div ref={chatBottomRef} />
                  </Div>
                </JumboScrollbar>
              </Div>
            ) : (
              <Div
                sx={{
                  // bgcolor: "background.paper",
                  // width: 650,
                  background:
                    "linear-gradient(90deg, hsla(225, 78%, 59%, 1) 0%, hsla(197, 85%, 51%, 1) 100%)", // ponkumar changes
                  width: "42%",
                  height: "90vh", // ponkumar changes
                  position: "absolute",
                  right: 40, // ponkumar changes
                  borderRadius: "5px",
                  display: closeChat ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    letterSpacing: 1,
                    color: "white",
                    width: "70%",
                    textWrap: "wrap",
                    textAlign: "center",
                  }}
                >
                  Thank you for your submission! <br />A sales person will
                  contact you soon for further details.
                </Typography>
              </Div>
            )}
          </Div>
        </Div>

        <Div
          sx={{ position: "fixed", top: "40%", left: "44.5%", opacity: 0.3 }}
        >
          <img src={require("../../assets/images/crmlogo.png")} alt="logo" />
        </Div>
        {/* <Div sx={{ top: "50%", left: "50%", opacity: 0.5 }}>
          <img
            src={require("../../assets/images/crmlogo.png")}
            alt="logo"
          />
        </Div> */}
      </Div>
      {/* </section> */}
    </>
  );
};

export default LeadQuestionsPage;
