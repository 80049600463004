import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import ReactApexChart from "react-apexcharts";
import { BASE_URL } from "app/services/auth-services";

const LeadSourceWise = () => {
  const [leadDatas, setLeadDatas] = useState([]);

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const requestOption = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const fetchLeadData = async () => {
    try {
      const sources = [
        "LeadbysourceEnquiry",
        "LeadbysourceCampaign",
        "LeadbysourceSocial",
        "LeadbysourceWeb",
        "LeadbysourceContact",
      ];
      let data = [];

      for (let source of sources) {
        const response = await axios.get(
          `${BASE_URL}/user/${source}/`,
          requestOption
        );
        if (Object.keys(response.data).length > 0) {
          data.push({
            name: source
              .split("Leadbysource")[1]
              .replace("Enquiry", "Enquiry")
              .replace(/([A-Z])/g, " ₹1")
              .trim(),
            value: Number(response.data[Object.keys(response.data)[0]]),
          });
        }
      }

      return data;
    } catch (error) {
      console.error(error.message);
      return [];
    }
  };

  const {
    data: leadSourceWiseLists,
    isLoading: leadSourceWiseListsLoading,
    isError: leadSourceWiseListsError,
  } = useQuery("leadSourceWiseLists", fetchLeadData, { staleTime: Infinity });

  useEffect(() => {
    if (leadSourceWiseLists) {
      setLeadDatas(leadSourceWiseLists);
    }
  }, [leadSourceWiseLists]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: leadDatas.map((d) => d.name),
    },
    yaxis: {
      min: 0,
      max: Math.max(...leadDatas.map((d) => d.value)) + 20, // Ensures the maximum is a bit higher than the highest value
      tickAmount: 5, // Number of ticks or intervals
      labels: {
        formatter: function (value) {
          return value.toString();
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#66ff66"], // Light green color at the end of the gradient
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  const series = [
    {
      name: "Lead Sources",
      data: leadDatas?.map((d) => d.value),
    },
  ];

  return (
    <div>
      {leadSourceWiseListsLoading && <p>Loading...</p>}
      {leadSourceWiseListsError && <p>Error loading data.</p>}
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default LeadSourceWise;
