// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email-list {
  background-color: #f5f5f5;
  max-height: 67vh;
  overflow-y: auto;
  width: 500px;
  transition: all 0.3s ease;
}

.emails-container__left {
  display: flex;
}

.emails-container__results {
  overflow-y: auto;
  height: 67vh;
}

.emails-container__email-body {
  width: 100%;
  overflow-y: auto;
  height: 67vh;
}

.emails-container__empty-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emails-container__empty-body h4 {
  font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/OutlookAPI/containers/EmailsContainer/EmailsContainer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".email-list {\n  background-color: #f5f5f5;\n  max-height: 67vh;\n  overflow-y: auto;\n  width: 500px;\n  transition: all 0.3s ease;\n}\n\n.emails-container__left {\n  display: flex;\n}\n\n.emails-container__results {\n  overflow-y: auto;\n  height: 67vh;\n}\n\n.emails-container__email-body {\n  width: 100%;\n  overflow-y: auto;\n  height: 67vh;\n}\n\n.emails-container__empty-body {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.emails-container__empty-body h4 {\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
