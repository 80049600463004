/* eslint-disable array-callback-return */
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
    Avatar,
    Table,
    TableBody,
    TableContainer,
    TextField,
    Typography,
} from "@mui/material";
import {
    AvatarFont,
    Colors,
    ComponentHeading,
    DisplayFlex,
    FontStyle,
    TableHeaderType,
    avatarLightColors,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { LoadingFullPageSkel } from "app/pages/widgets/StylesComponents";
import {
    loadingfullpage,
    setLoadingFullPageFunc,
} from "app/redux/auth/Loadingskeleton";
import { AutoHeight } from "app/redux/auth/localStorage";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function SalesPipelineReport() {
    // const loading = useSelector(loadingfullpage);
    const dispatch = useDispatch();

    const [columnMenuItems, setColumnMenuItems] = useState([
        { sort: "Account_Name", label: "Deal Name", bool: true },
        { sort: "", label: "Stage", bool: true },
        { sort: "Contact_Person", label: "Probability", bool: true },
        { sort: "Amount", label: "Amount", bool: true },
        { sort: "Deal_Note", label: "Close Date", bool: false },
        { sort: "Deal_Stage", label: "Sales Rep Assigned", bool: true },
        { sort: "ActualDealAmount", label: "Lead Source", bool: true },
        { sort: "Customer Name", label: "Customer Name", bool: true },
        { sort: "Contact  Name", label: "Contact Name", bool: false },
        { sort: "Last Contacted", label: "Last Contacted", bool: false },
        { sort: "Reasons for Lost Opportunities", label: "Days in Stage", bool: false },
        { sort: "Days in Stage", label: "Days until Close", bool: false },
        { sort: "Retention Rate", label: "Next Steps", bool: false },

    ]);

    const [datas, setDatas] = useState([]);
    const [selecteddate, setSelectedDate] = useState({ from: "", to: "" });

    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const getData = async () => {
        try {
            await axios
                .get(`${BASE_URL}/user/DealAdditional/?Is_Deleted=False`, {
                    headers: {
                        db: item,
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    console.log("pipeline Report : ", res.data);
                    getSalesUsers(res.data?.results);
                    dispatch(setLoadingFullPageFunc("success"));
                });
        } catch (error) {
            console.error("error", error);
        }
    };

    const getSalesUsers = async (DealDatas) => {
        try {
            await axios
                .get(
                    `${BASE_URL}/user/SalesUsersList/?Is_Deleted=False&id=${localStorage.getItem(
                        "UserId"
                    )}`,
                    {
                        headers: {
                            db: item,
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        },
                    }
                )
                .then((res) => {
                    //    console.log("pipeline Report : ",res.data);
                    let list = [];
                    // res.data?.results?.map((result) => {
                    DealDatas?.map((result2) => {
                        if (
                            result2?.Created_Date?.split("T")[0] >= selecteddate?.from &&
                            result2?.Created_Date?.split("T")[0] <= selecteddate?.to
                        ) {
                            // if (result2?.id == result?.Lead_Id?.id) {
                            list.push(result2);
                            // }
                        } else if (
                            !Boolean(selecteddate?.from) ||
                            !Boolean(selecteddate?.to)
                        ) {
                            // if (result2?.id == result?.Lead_Id?.id) {
                            list.push(result2);
                            // }
                        }
                    });
                    // });
                    setDatas(list || []);
                });
        } catch (error) {
            console.error("error", error);
        }
    };

    useEffect(() => {
        // dispatch(setLoadingFullPageFunc("loading"));
        getData();
    }, []);

    useEffect(() => {
        if (Boolean(selecteddate?.from) && Boolean(selecteddate?.to)) {
            // dispatch(setLoadingFullPageFunc("loading"));
            getData();
        }
    }, [selecteddate]);

    return (
        <JumboContentLayoutMain>
            {/* {loading === "loading" && <LoadingFullPageSkel />} */}

            <Div >
                <Div
                    sx={[
                        DisplayFlex,
                        { justifyContent: "space-between", flexWrap: "wrap" },
                    ]}
                >
                    <Typography sx={[ComponentHeading, {}]}>
                        Sales Pipeline Report
                    </Typography>

                    <Div sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap" }]}>
                        <Div>
                            <Typography mb={0.3} sx={[FontStyle]}>
                                From
                            </Typography>
                            <TextField
                                type="date"
                                sx={[FontStyle]}
                                className={`input-box`}
                                onChange={(e) => {
                                    setSelectedDate({ ...selecteddate, from: e.target.value });
                                }}
                                value={selecteddate?.from}
                                id="outlined-basic"
                                variant="outlined"
                            />
                        </Div>

                        <Div>
                            <Typography mb={0.3} sx={[FontStyle]}>
                                To
                            </Typography>
                            <TextField
                                type="date"
                                sx={[FontStyle]}
                                className={`input-box`}
                                onChange={(e) => {
                                    setSelectedDate({ ...selecteddate, to: e.target.value });
                                }}
                                inputProps={{
                                    min:
                                        selecteddate?.from ||
                                        new Date()?.toISOString()?.split("T")[0],
                                }}
                                value={selecteddate?.to}
                                id="outlined-basic"
                                variant="outlined"
                            />
                        </Div>
                    </Div>
                </Div>

                <div style={{ margin: "1rem 0rem" }}>
                    <TableContainer>
                        
                            <Table stickyHeader>
                                <CustomTableHeader
                                    headerMenuItems={columnMenuItems}
                                    setHeadermenuItems={setColumnMenuItems}
                                    setDatas={setDatas}
                                    sortDatas={datas}
                                    transformText={TableHeaderType}
                                />

                                <TableBody>
                                    {datas.length > 0 ? (
                                        datas?.map((data, index) => {
                                            let stage = data?.Deal_Stage?.split(" ")[1];
                                            let color =
                                                stage == "Qualifying"
                                                    ? {
                                                        color: Colors?.green,
                                                        bgcolor: Colors?.lightgreen,
                                                    }
                                                    : stage == "Enquiry"
                                                        ? {
                                                            color: Colors?.indigo,
                                                            bgcolor: Colors?.lightindigo,
                                                        }
                                                        : stage == "Lost"
                                                            ? { color: Colors?.red, bgcolor: Colors?.lightred }
                                                            : stage == "Demo"
                                                                ? {
                                                                    color: Colors?.blue,
                                                                    bgcolor: Colors?.lightBlue,
                                                                }
                                                                : stage == "Proposal"
                                                                    ? {
                                                                        color: Colors?.orange,
                                                                        bgcolor: Colors?.lightorange,
                                                                    }
                                                                    : {
                                                                        color: Colors?.purple,
                                                                        bgcolor: Colors?.lightpurple,
                                                                    };
                                            let avatar =
                                                index > 10
                                                    ? avatarLightColors[index % 10]
                                                    : avatarLightColors[index];
                                            return (
                                                <CRMTableRow>
                                                    {columnMenuItems[0]?.bool && (
                                                        <CRMTableCell
                                                            data-title="Prospect"
                                                            sx={{ color: Colors?.blue }}
                                                        >
                                                            <Div
                                                                sx={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    gap: "5px",
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={[
                                                                        AvatarFont,
                                                                        {
                                                                            bgcolor: avatar,
                                                                            width: "22px",
                                                                            height: "22px",
                                                                            display: {
                                                                                xs: "none !important",
                                                                                md: "flex !important",
                                                                                lg: "flex !important",
                                                                            },
                                                                            color: "black",
                                                                        },
                                                                    ]}
                                                                >
                                                                    {data?.Account_Name.substring(
                                                                        0,
                                                                        2
                                                                    )?.toUpperCase()}
                                                                    {/* {data?.Name[1]?.toUpperCase()} */}
                                                                </Avatar>
                                                                <Typography
                                                                    color="#0040ff !important"
                                                                    sx={[
                                                                        FontStyle,
                                                                        { color: `${Colors?.blue} !important` },
                                                                    ]}
                                                                >
                                                                    {data?.Account_Name}

                                                                    {/* <span style={{ color: "#475259" }}>Tech Wire</span> */}
                                                                </Typography>
                                                            </Div>
                                                        </CRMTableCell>
                                                    )}
                                                    {columnMenuItems[1]?.bool && (
                                                        <CRMTableCell data-title="Opportunity">
                                                            {/* {data?.Created_Date.split("T")[0]} */}
                                                        </CRMTableCell>
                                                    )}
                                                    {columnMenuItems[2]?.bool && (
                                                        <CRMTableCell data-title="Contact Person">
                                                            {data?.Contact_Person}
                                                        </CRMTableCell>
                                                    )}
                                                    {columnMenuItems[3]?.bool && (
                                                        <CRMTableCell
                                                            data-title="Deal Value"
                                                            sx={{ color: Colors?.green }}
                                                        >
                                                            {data?.Amount?.toLocaleString() || 0}.00
                                                        </CRMTableCell>
                                                    )}
                                                    {columnMenuItems[4]?.bool && (
                                                        <CRMTableCell data-title="Status">
                                                            <Typography
                                                                sx={[
                                                                    FontStyle,
                                                                    {
                                                                        backgroundColor: `${color?.bgcolor} !important`,
                                                                        color: color?.color,
                                                                        borderRadius: "15px",
                                                                        py: 0.4,
                                                                        px: 0.8,
                                                                        display: "inline-flex",
                                                                    },
                                                                ]}
                                                            >
                                                                {data?.Deal_Stage?.split("Deals")[1]}
                                                            </Typography>
                                                        </CRMTableCell>
                                                    )}
                                                    {columnMenuItems[5]?.bool && (
                                                        <CRMTableCell
                                                            data-title="Actual Earnings"
                                                            sx={{ color: Colors?.blue }}
                                                        >
                                                            {data?.ActualDealAmount?.toLocaleString() || 0}.00
                                                        </CRMTableCell>
                                                    )}
                                                    {columnMenuItems[6]?.bool && (
                                                        <CRMTableCell data-title="Comments">
                                                            {data?.Deal_Note}
                                                        </CRMTableCell>
                                                    )}
                                                </CRMTableRow>
                                            );
                                        })
                                    ) : (
                                        <CRMTableRow>
                                            <CRMTableCell
                                                align="center"
                                                sx={{ color: Colors?.blue }}
                                                colSpan={8}
                                            >
                                                No Records
                                            </CRMTableCell>
                                        </CRMTableRow>
                                    )}
                                </TableBody>
                            </Table>
                       
                    </TableContainer>
                </div>
            </Div>
        </JumboContentLayoutMain>
    );
}

export default SalesPipelineReport;
