import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { PiCaretDoubleLeftLight, PiCaretDoubleRight } from "react-icons/pi";
import { MdGroupAdd, MdMoreVert } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import CreateGroups from "../components/CreateGroups";
import ChatElement from "../components/ChatElement";
import NoChat from "../components/chatHeaderAndFooter/NoChat";
import { useStateContext } from "app/contexts/ContextProvider";
import { BASE_URL, socket } from "app/services/auth-services";
import { ProfileImageUpload } from "app/pages/contacts/extra-components/APIs";
import useResponsive from "../hook/useResponsive";
import { Search, SearchIconWrapper, StyledInputBase } from "../widgets/Search";
import { errorFilter, formatDate } from "app/pages/widgets/StylesComponents";
import Div from "@jumbo/shared/Div";
import { Spin } from "antd";
import { useChatContext } from "../context/Chatcontext";

// Redux selectors/actions and other imports...

function ChatLeftSide() {
  const { users, setUsers } = useStateContext();

  const { usersList, setUsersList, fetchUsers } = useChatContext();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const [open, setOpen] = useState(!isDesktop);
  const [openProfile, setOpenProfile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [base64, setBase64] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("UserDetails"));

  useEffect(() => {
    if (usersList) setFilteredUsers(usersList);
  }, [usersList]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value?.trim()?.toLowerCase();
    if (searchTerm) {
      const filtered = usersList?.filter(
        (item) =>
          item?.username?.toLowerCase()?.includes(searchTerm) ||
          item?.Group_Name?.toLowerCase()?.includes(searchTerm)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(usersList);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const base = btoa(event.target.result);
      const imageName = `${file.lastModified}${file.name}`;
      const payload = {
        base64: base,
        imageName,
        id: localStorage.getItem("UserId"),
        username: localStorage.getItem("Username"),
      };

      const errors = errorFilter(payload);
      if (!Object.keys(errors).length) {
        setBase64(base);
        ProfileImageUpload(
          payload,
          setOpenProfile,
          "UserCRUD",
          refreshUserData,
          "useradmin"
        );
      } else {
        console.error("Error in payload:", errors);
      }
    };
    reader.readAsBinaryString(file);
  };

  const refreshUserData = async (res) => {
    fetchUsers();
  };

  const profile = () => (
    <Box p={2}>
      <Stack alignItems="center" mt={6}>
        <label htmlFor="profile_pics" title="Click to upload Image">
          <Avatar
            className="border-2 border-cyan-400"
            alt={currentUser?.username?.[0]}
            src={BASE_URL + currentUser?.User_Photo}
            sx={{
              width: 100,
              height: 100,
              cursor: "pointer",
              boxShadow: theme.shadows[3],
              "&:hover": { backgroundColor: "grey" },
            }}
          />
        </label>
        <input
          className="w-full"
          type="file"
          id="profile_pics"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
        <Typography className="mt-1 font-bold" sx={{ fontSize: 16 }}>
          {currentUser?.username || currentUser?.Group_Name}
        </Typography>
      </Stack>
      <Stack mt={2} spacing={1}>
        {currentUser?.email && (
          <UserInfo label="E-mail" value={currentUser.email} />
        )}
        {currentUser?.PhoneNo && (
          <UserInfo label="Phone Number" value={currentUser.PhoneNo} />
        )}
        <UserInfo
          label="Designation"
          value={currentUser?.Designation || "No Designation"}
        />
        <UserInfo
          label="Created Date"
          value={formatDate(currentUser?.date_joined) || "-"}
        />
      </Stack>
    </Box>
  );

  const UserInfo = ({ label, value }) => (
    <Div className="bg-[#f9f9f9] p-1 my-1">
      <Typography sx={{ fontSize: 14 }}>{label}</Typography>
      <Typography className="mt-1" sx={{ fontSize: 14 }}>
        {value}
      </Typography>
    </Div>
  );

  return (
    <>
      {!openDialog ? (
        <>
          {!isDesktop && !open && (
            <IconButton sx={{ zIndex: 222 }} onClick={() => setOpen(!open)}>
              <PiCaretDoubleRight />
            </IconButton>
          )}
          <Box
            sx={{
              position: !isDesktop ? "absolute" : "relative",
              display: open || (!isDesktop && "none"),
              zIndex: 0,
              minHeight: 300,
              height: "100%",
              width: "100%",
              backgroundColor: "#f9f9f9",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
            }}
          >
            {!isDesktop && open && (
              <IconButton sx={{ zIndex: 222 }} onClick={() => setOpen(!open)}>
                <PiCaretDoubleLeftLight />
              </IconButton>
            )}
            <Stack px={2} spacing={2}>
              <Stack
                pt={isDesktop && 3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ fontSize: 16 }}>Chats</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <IconButton
                    onClick={() => setOpenDialog(true)}
                    sx={{ width: "max-content" }}
                  >
                    <MdGroupAdd size={20} />
                  </IconButton>
                  <IconButton
                    onClick={(e) => setOpenProfile(e.currentTarget)}
                    sx={{ width: "max-content" }}
                  >
                    <MdMoreVert size={20} />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack sx={{ width: "100%" }}>
                <Search sx={{ height: 35, width: "100%" }}>
                  <SearchIconWrapper>
                    <IoIosSearch color="#709CE6" size={18} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    onChange={handleSearch}
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    sx={{ ml: 4, mt: 1, color: "gray", width: "100%" }}
                  />
                </Search>
              </Stack>
              <Typography sx={{ color: "#676667" }}>All Chats</Typography>
              <JumboScrollbar
                autoHeight
                autoHideTimeout={4000}
                autoHeightMin={600}
                autoHide
                hideTracksWhenNotNeeded
              >
                {filteredUsers?.length > 0 ? (
                  filteredUsers.map((user, index) => (
                    <Div key={index} sx={{ my: 1 }}>
                      <ChatElement user={user} />
                    </Div>
                  ))
                ) : (
                  <Div
                    sx={{ display: "flex", justifyContent: "center", my: 1 }}
                  >
                    <Spin tip="Loading" />
                  </Div>
                )}
              </JumboScrollbar>
            </Stack>
          </Box>
        </>
      ) : (
        <CreateGroups
          open={openDialog}
          users={filteredUsers}
          handleClose={() => setOpenDialog(false)}
        />
      )}

      <Popover
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        onClose={() => {
          setOpenProfile(null);
          setBase64("");
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ borderRadius: "5px !important" }}
      >
        <Box className="flex w-[400px] h-[450px]">{profile()}</Box>
      </Popover>
    </>
  );
}

export default memo(ChatLeftSide);
