import React, { useState } from "react";
import { Menu } from "antd";
import "./sidebarmenu.css";

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const SidebarMenu = ({ data }) => {
  const levelKeys = getLevelKeys(data);
  const [stateOpenKeys, setStateOpenKeys] = useState(["1", "11"]);
  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={["11"]}
      openKeys={stateOpenKeys}
      onOpenChange={onOpenChange}
      // style={{
      //   width: 256,
      // }}
    >
      {data?.map((item) => (
        <Menu.SubMenu key={item?.key} title={item?.label}>
          {item?.children.map((child) => (
            <Menu.Item key={child.key} icon={child?.icon}>
              {child?.label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ))}
    </Menu>
  );
};
export default SidebarMenu;
