import React, { useEffect, useState } from 'react'

const EmailDetail = ({ data, headersArray }) => {
    const [headers, setHeaders] = useState([headersArray])


    return (
        <div className='ms-5'>
            {console.log("data", data)}
            {console.log("headerSArrayinEMailDetails", headers)}
            <p style={{marginBottom:0}}>{headersArray.map((obj) => { return obj.name === "From" ? obj.value : '' })}</p>
            <p>to me</p>
            <div>
                <span dangerouslySetInnerHTML={{ __html: data }}></span>
            </div>
        </div>
    )
}

export default EmailDetail