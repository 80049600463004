import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  Grid,
  ListItem,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  Card,
  Box,
  InputLabel,
  Stack,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  CloseIcon,
  MailIcon,
  PhoneIcon,
  SettingsIcon,
} from "app/icons/PngIcons";
import React, { useEffect, useContext, useCallback, memo } from "react";
import Select from "react-select";
import * as yup from "yup";
import { HiOutlineSearch } from "react-icons/hi";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState, useRef } from "react";
import { BsArrowDownUp } from "react-icons/bs";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEdit from "@mui/icons-material/ModeEdit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BASE_URL } from "app/services/auth-services";
import { BiLocationPlus, BiWebcam } from "react-icons/bi";
import { FaIndustry } from "react-icons/fa";
import {
  AiOutlineArrowLeft,
  AiOutlinePhone,
  AiTwotoneMail,
} from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiOrganizationChart } from "react-icons/ri";
import { ImLocation2 } from "react-icons/im";
import { MdAccountCircle, MdDescription } from "react-icons/md";
import { Country, State, City } from "country-state-city";
import colors from "Colors";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
} from "../widgets/CRMStyles";
import {
  Cancelbutton,
  DialogBox,
  CustomButton,
} from "../widgets/StylesComponents";
import { useQuery, useQueryClient } from "react-query";

const LeadFromWebEdit = ({
  scrollHeight,
  openAddLead,
  setOpenAddLead,
  webupdate,
  getLeadListsRefetch,
  setAnchorElAction,
  anchorElAction,
}) => {
  console.log("webupdate", webupdate);
  //  Initilization For Country,state,city

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [save, setSave] = useState(false);

  const [fname, setFname] = useState(webupdate.LeadFirstName);
  const [lname, setLname] = useState(webupdate.LeadLastName);
  const [cname, setCname] = useState(webupdate.CompanyName);
  const [phone, setPhone] = useState(webupdate.PhoneNo);
  const [url, setUrl] = useState(webupdate.WebsiteURL);
  const [leadtype, setLeadType] = useState(webupdate.LeadType);
  const [selectedCountry1, setSelectedCountry1] = useState(webupdate.Country);
  const [selectedState1, setSelectedState1] = useState(webupdate.State);
  const [selectedCity1, setSelectedCity1] = useState(webupdate.City);
  const [areaname, setAreaName] = useState(webupdate.AreaName);
  const [strname, setStrname] = useState(webupdate.StreetName);
  const [email, setEmail] = useState(webupdate.EmailId);
  const [BuildingNo, setBuildingNo] = useState(webupdate.BuildingDoorNo);
  const [des, setDes] = useState(webupdate.LeadDescription);
  const [turnover, setTurnOver] = useState(webupdate.LeadCompanyTurnOver);
  const [noofemployee, setNoofEmployee] = useState(webupdate.LeadNoOfEmployees);
  const [location, setLocation] = useState(webupdate.LeadLocation);
  const [leadsource1, setLeadscource1] = useState(webupdate.LeadSource);
  const [jobtitle1, setJobtitle1] = useState(webupdate.Job_Title);
  const [leadindustry1, setLeadindustry1] = useState(webupdate.Lead_Industry);
  const [leadstatus1, setLeadStatus1] = useState(webupdate.Lead_Status);

  useEffect(() => {
    setFname(webupdate.LeadFirstName);
    setLname(webupdate.LeadLastName);
    setCname(webupdate.CompanyName);
    setPhone(webupdate.PhoneNo);
    setUrl(webupdate.WebsiteURL);
    setLeadType(webupdate.LeadType);
    setSelectedCountry1(webupdate.Country);
    setSelectedState1(webupdate.State);
    setSelectedCity1(webupdate.City);
    setAreaName(webupdate.AreaName);
    setStrname(webupdate.StreetName);
    setEmail(webupdate.EmailId);
    setBuildingNo(webupdate.BuildingDoorNo);
    setDes(webupdate.LeadDescription);
    setTurnOver(webupdate.LeadCompanyTurnOver);
    setNoofEmployee(webupdate.LeadNoOfEmployees);
    setLocation(webupdate.LeadLocation);
    setJobtitle1(webupdate.Job_Title);
    setLeadscource1(webupdate.LeadSource);
    setLeadindustry1(webupdate.Lead_Industry);
    setLeadStatus1(webupdate.Lead_Status);
  }, [webupdate]);

  // For Validation Purpose
  const validationSchema = yup.object().shape({
    fname: yup.string().required("Lead First Name is required"),
    lname: yup.string().required("Lead Last Name is required"),
    cname: yup.string().required("Company Name is Required"),
    phone: yup.string().required("Mobile Number is required"),
    // url: yup.string().required("Website  is required"),
    selectedCity1: yup.string().required("City is required"),
    selectedCountry1: yup.string().required("Country is Required"),
    selectedState1: yup.string().required("State is Required"),
    areaname: yup.string().required("Area Name is required"),
    strname: yup.string().required("Street Name is required"),
    email: yup.string().required("Email is required"),
    BuildingNo: yup.string().required("Buildingt Name is required"),
    // des: yup.string().required("Description is required"),
    // location: yup.string().required("Location is required"),
    jobtitle1: yup.string().required("Job Tittle is Required"),
    leadsource1: yup.string().required("Lead Source is Required"),
    leadindustry1: yup.string().required("Lead Industry is Required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const onsubmit = () => {
    var updatepayload = {
      id: webupdate.id,
      LeadId: webupdate.LeadId,
      Salutation: webupdate.Salutation,
      LeadFirstName: fname,
      LeadLastName: lname,
      CompanyName: cname,
      PhoneNo: phone,
      EmailId: email,
      WebsiteURL: url,
      BuildingDoorNo: BuildingNo,
      StreetName: strname,
      AreaName: areaname,
      City: selectedCity1,
      State: selectedState1,
      Country: selectedCountry1,
      LeadSource: leadsource1,
      LeadIndustry: leadindustry1,
      Job_Title: jobtitle1,
      Lead_Status: leadstatus1,
      LeadLocation: webupdate.LeadLocation,
      LeadCompanyTurnOver: turnover,
      LeadNoOfEmployees: noofemployee,
      Decissionmaker: webupdate.Decissionmaker,
      LeadDescription: webupdate.LeadDescription,
      LeadType: webupdate.LeadType,
      LeadMLQStatus: webupdate.LeadMLQStatus,
      LeadSLQStatus: webupdate.LeadSLQStatus,
      LeadQualifyStatus: webupdate.LeadQualifyStatus,
      LeadMLQuestions: webupdate.LeadMLQuestions,
      LeadSLQuestions: webupdate.LeadSLQuestions,
      Is_blocked: webupdate.Is_blocked,
      Created_Date: webupdate.Created_Date,
      Updated_Date: webupdate.Updated_Date,
      Organization_Id: webupdate.Organization_Id,
      Created_By: webupdate.Created_By,
      Updated_By: webupdate.Updated_By,
    };

    console.log("payload", updatepayload);

    axios
      .put(`${BASE_URL}/user/LeadCRUD/`, updatepayload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("res.data", res.data);
        setOpenAddLead(false);
        getLeadListsRefetch();
        toast.success("Updated successfully", {
          position: "top-right",
          autoClose: 100,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // Geeting the Job Tittle From UserAdmin Table

  const [jobtitle, setJobtitle] = useState([]);
  const [leadstatus, setLeadStatus] = useState([]);
  const [leadindustry, setLeadindustry] = useState([]);
  const [leadsource, setLeadscource] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  const HandleCancel = () => {
    setSave(false);
    setOpenAddLead(false);
    reset();
    setFname("");
    setLname("");
    setCname("");
    setPhone("");
    setUrl("");
    setLeadType("");
    setSelectedCountry1("");
    setSelectedState1("");
    setSelectedCity1("");
    setAreaName("");
    setStrname("");
    setEmail("");
    setBuildingNo("");
    setDes("");
    setTurnOver("");
    setNoofEmployee("");
    setLocation("");
    setJobtitle1("");
    setLeadscource1("");
    setLeadindustry1("");
    setLeadStatus1("");
    setAnchorElAction(null);
  };

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["list"], getlookupData, { staleTime: Infinity });

  useEffect(() => {
    if (getUserLoopUp) {
      setLeadindustry(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type")
      );
      setLeadStatus(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Status")
      );
      setJobtitle(getUserLoopUp?.find((op) => op?.Lookupname === "Job Title"));
      setLeadscource(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Source")
      );
    }
  }, [getUserLoopUp]);

  return (
    <>
      <Dialog
        open={openAddLead}
        // onClose={() => setOpenAddLead(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={[
            CRMTableHead,
            DisplayFlex,
            { bgcolor: "#dcdcdc !important", justifyContent: "space-between" },
          ]}
        >
          <Typography sx={[FontStyle]}>Edit Lead</Typography>
          <IconButton
            onClick={() => setSave(true)}
            sx={{ float: "right", mr: -2.5 }}
          >
            <CloseIcon size={24} color="white" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onsubmit)}>
          <DialogContent>
            <Stack
              m={2}
              alignContent={"start"}
              justifyContent={"start"}
              direction={{ md: "row", xs: "column" }}
              spacing={6}
            >
              <Stack direction={"column"} spacing={2}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Lead First Name
                  </Typography>
                  <TextField
                    className={`col-md-12 input-box2 mt-1 ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    {...register("fname")}
                    placeholder="Lead First Name"
                    variant="outlined"
                    value={fname}
                    onChange={(e) => {
                      setFname(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.fname?.message}</Div>
                </Stack>
                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Company Name
                    </Typography>
                    <TextField
                      className={`col-md-12 input-box2 mt-1 ${
                        errors.cname ? "is-invalid" : ""
                      }`}
                      id="outlined-basic"
                      placeholder="Company Name"
                      value={cname}
                      onChange={(e) => {
                        setCname(e.target.value);
                      }}
                      variant="outlined"
                      {...register("cname")}
                      InputProps={{
                        sx: {
                          borderRadius: "5px",
                          width: "300px !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>{errors.cname?.message}</Div>
                </Stack>
                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Lead Industry
                    </Typography>
                    <Autocomplete
                      className="search-select3"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value || value === ""
                      }
                      value={leadindustry1}
                      options={leadindustry?.Values}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setLeadindustry1(newValue?.value);
                        setValue("leadindustry1", newValue?.value);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Lead Industry"
                        />
                      )}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>
                    {errors.leadindustry1?.message}
                  </Div>
                </Stack>

                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Lead Status
                    </Typography>
                    <Autocomplete
                      className="search-select3"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value || value === ""
                      }
                      value={leadstatus1}
                      options={leadstatus?.Values}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setLeadStatus1(newValue?.value);
                        setValue("leadstatus1", newValue?.value);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Lead Status"
                        />
                      )}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>
                    {errors.jobtitle1?.message}
                  </Div>
                </Stack>

                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Email Id
                    </Typography>
                    <TextField
                      {...register("email")}
                      className={`col-md-12 input-box2 mt-1${
                        errors.email ? "is-invalid" : ""
                      }`}
                      id="outlined-basic"
                      placeholder="Email Id"
                      variant="outlined"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "5px",
                          width: "300px !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>{errors.email?.message}</Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Building Door No
                  </Typography>
                  <TextField
                    {...register("BuildingNo")}
                    className={`col-md-12 input-box2 mt-1 ${
                      errors.BuildingNo ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Building DoorNo"
                    variant="outlined"
                    value={BuildingNo}
                    onChange={(e) => {
                      setBuildingNo(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.BuildingNo?.message}
                  </Div>
                </Stack>

                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Area Name
                    </Typography>
                    <TextField
                      {...register("areaname")}
                      className={`col-md-12 input-box2 mt-1 ${
                        errors.BuildingNo ? "is-invalid" : ""
                      }`}
                      id="outlined-basic"
                      placeholder="Area Name"
                      variant="outlined"
                      value={areaname}
                      onChange={(e) => {
                        setAreaName(e.target.value);
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "5px",
                          width: "300px !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>{errors.areaname?.message}</Div>
                </Stack>

                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    State
                  </Typography>
                  <Autocomplete
                    className="search-select3"
                    {...register("selectedState1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    value={selectedState1}
                    options={State.getStatesOfCountry(selectedCountry?.isoCode)}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setSelectedState(newValue);
                      setValue("selectedState1", newValue?.name);
                      setSelectedState1(newValue?.name);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "5px",
                          width: "300px !important",
                        }}
                        placeholder="State"
                      />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.selectedState1?.message}
                  </Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Lead Company Turn Over
                  </Typography>
                  <TextField
                    {...register("turnover")}
                    className={`col-md-12 input-box2 mt-1${
                      errors.turnover ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead CompanyTurn Over"
                    variant="outlined"
                    value={turnover}
                    onChange={(e) => {
                      setTurnOver(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.turnover?.message}</Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Lead Description
                  </Typography>
                  <TextField
                    {...register("des")}
                    className={`col-md-12 input-box2 mt-1${
                      errors.des ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead Description"
                    variant="outlined"
                    value={des}
                    onChange={(e) => {
                      setDes(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.des?.message}</Div>
                </Stack>
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Lead Last Name
                    </Typography>
                    <TextField
                      {...register("lname")}
                      className={`col-md-12 input-box2 mt-1${
                        errors.lname ? "is-invalid" : ""
                      }`}
                      id="outlined-basic"
                      placeholder="Lead Last Name"
                      variant="outlined"
                      value={lname}
                      onChange={(e) => {
                        setLname(e.target.value);
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "5px",
                          width: "300px !important",
                        },
                      }}
                    />
                    <Div style={{ color: "red" }}>{errors.lname?.message}</Div>
                  </FormControl>
                </Stack>

                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Job Tittle
                    </Typography>
                    <Autocomplete
                      className="search-select3"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value || value === ""
                      }
                      value={jobtitle1}
                      options={jobtitle?.Values}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setJobtitle1(newValue?.value);
                        setValue("jobtitle1", newValue?.value);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Job Tittle"
                        />
                      )}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>
                    {errors.jobtitle1?.message}
                  </Div>
                </Stack>
                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Lead Source
                    </Typography>
                    <Autocomplete
                      className="search-select3"
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.value) {
                          return option?.value;
                        }
                        return "";
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value || value === ""
                      }
                      value={leadsource1}
                      options={leadsource?.Values}
                      onChange={(e, newValue) => {
                        console.log("newValue", newValue);
                        setLeadscource1(newValue?.value);
                        setValue("leadsource1", newValue?.value);
                      }}
                      id="outlined-basic"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            borderRadius: "10px",
                          }}
                          placeholder="Lead Source"
                        />
                      )}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>
                    {errors.leadsource1?.message}
                  </Div>
                </Stack>

                <Stack>
                  <FormControl>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "black",
                        mb: 0.2,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      {...register("phone")}
                      className={`col-md-12 input-box2 mt-1${
                        errors.phone ? "is-invalid" : ""
                      }`}
                      id="outlined-basic"
                      placeholder="Phone No"
                      variant="outlined"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "5px",
                          width: "300px !important",
                        },
                      }}
                    />
                  </FormControl>
                  <Div style={{ color: "red" }}>{errors.phone?.message}</Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Website URL
                  </Typography>
                  <TextField
                    {...register("url")}
                    className={`col-md-12 input-box2 mt-1${
                      errors.url ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Website URL"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                    variant="outlined"
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.url?.message}</Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Street Name
                  </Typography>
                  <TextField
                    {...register("strname")}
                    className={`col-md-12 input-box2 mt-1${
                      errors.strname ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Street Name"
                    variant="outlined"
                    value={strname}
                    onChange={(e) => {
                      setStrname(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.strname?.message}</Div>
                </Stack>

                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Country
                  </Typography>
                  <Autocomplete
                    className="search-select3"
                    {...register("selectedCountry1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    options={Country.getAllCountries()}
                    value={selectedCountry1}
                    onChange={(e, newValue) => {
                      setValue("selectedCountry1", newValue?.name);
                      console.log("newValue", newValue);
                      setSelectedCountry(newValue);
                      setSelectedCountry1(newValue?.name);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Country" />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.selectedCountry1?.message}
                  </Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    City
                  </Typography>
                  <Autocomplete
                    className="search-select3"
                    {...register("selectedCity1")}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    options={City.getCitiesOfState(
                      selectedState?.countryCode,
                      selectedState?.isoCode
                    )}
                    value={selectedCity1}
                    onChange={(e, newValue) => {
                      setValue("selectedCity1", newValue?.name);
                      console.log("newValue", newValue);
                      setSelectedCity(newValue);
                      setSelectedCity1(newValue?.name);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="City" />
                    )}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.selectedCity1?.message}
                  </Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Lead No Of Employees
                  </Typography>
                  <TextField
                    {...register("noofemployee")}
                    className={`col-md-12 input-box2 mt-1${
                      errors.noofemployee ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead No Of Employees"
                    variant="outlined"
                    value={noofemployee}
                    onChange={(e) => {
                      setNoofEmployee(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>
                    {errors.noofemployee?.message}
                  </Div>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "black",
                      mb: 0.2,
                    }}
                  >
                    Lead Location
                  </Typography>
                  <TextField
                    {...register("location")}
                    className={`col-md-12 input-box2 mt-1${
                      errors.location ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Lead Location"
                    variant="outlined"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "5px",
                        width: "300px !important",
                      },
                    }}
                  />
                  <Div style={{ color: "red" }}>{errors.location?.message}</Div>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignContent={"center"}
              justifyContent={"center"}
              spacing={2}
              sx={{ borderTop: "1px solid lightgray", pt: 2 }}
            >
              <CustomButton label="Update" type="submit" Icon="saveIcon" />
              <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
            </Stack>
          </DialogContent>

          {/* close dialog box */}
          <DialogBox
            open={save}
            onClickNo={() => setSave(false)}
            onClickYes={() => HandleCancel()}
          />
        </form>
      </Dialog>
    </>
  );
};

export default memo(LeadFromWebEdit);
