import React, { useEffect, useState } from "react";
import Board, {
  moveCard,
  moveColumn,
  removeCard,
  addCard,
} from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import { RxCross2 } from "react-icons/rx";
import { IoMdAdd } from "react-icons/io";
import Modal from "react-modal";
import "./carousel.css";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { QueryClient, useQuery } from "react-query";
import {
  AvatarCircle,
  Div,
  toLocaleCurrency,
} from "../widgets/StylesComponents";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import {
  CRMTableHead,
  Colors,
  DisplayFlex,
  FontStyle,
  dialogFontSize,
  dialogTitle,
} from "../widgets/CRMStyles";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import SearchBar from "../widgets/SearchField";

// Sample data for the Kanban board
const initialBoard = {
  columns: [
    { id: 1, title: "Enquiry", amount: 0, cards: [] },
    { id: 2, title: "Proposal", amount: 0, cards: [] },
    { id: 3, title: "Negotiation", amount: 0, cards: [] },
    { id: 4, title: "Qualifying", amount: 0, cards: [] },
    { id: 5, title: "Demo", amount: 0, cards: [] },
    { id: 6, title: "Lost", amount: 0, cards: [] },
  ],
};

const PipeLineDeals = () => {
  const [board, setBoard] = useState(initialBoard);
  const [modalOpened, setModalOpened] = useState(false);
  const [update, setUpdate] = useState(null);

  const [anchorElAction, setAnchorElAction] = React.useState(null);

  const queryClient = new QueryClient();

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };
  const handleClickAction = (event, data) => {
    setAnchorElAction(event.currentTarget);
    setUpdate(data);
  };

  const handleColumnMove = (_card, source, destination) => {
    const updatedBoard = moveColumn(board, source, destination);
    setBoard(updatedBoard);
    console.log("Card moved : ", updatedBoard);
  };

  const handleCardMove = (_card, source, destination) => {
    const updatedBoard = moveCard(board, source, destination);
    setBoard(updatedBoard);
    updateCard({ _card, source, destination });
    console.log("Card moved2 : ", _card, source, destination);
  };

  const getColumn = (card) => {
    const column = board.columns.filter((column) =>
      column.cards.includes(card)
    );
    return column[0];
  };

  const getGradient = (card) => {
    // const column = getColumn(card);
    // const title = column?.title;
    return {
      background: "white",
      boxShadow: "1px 1px 8px gray",
    };
  };

  // const handleCardAdd = (title, description) => {
  //   const card = {
  //     id: new Date().getTime(),
  //     title,
  //     description,
  //   };
  //   const updatedBoard = addCard(board, currentColumn, card);
  //   setBoard(updatedBoard);
  //   setModalOpened(false);
  // };

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const requestOption = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  // Enquiry Stage
  const enquiryFun = async () => {
    try {
      // Deal Enquiry
      const dealEnquiry = "Deals Enquiry Stage";
      const resEnquiry = await axios.get(
        `${BASE_URL}/user/DealPipeLineFilter/?Deal_Stage=${dealEnquiry}`,
        requestOption
      );
      let dataStage = [...board?.columns];
      dataStage[0].amount = 0;

      if (resEnquiry?.data?.length > 0) {
        dataStage[0].cards = resEnquiry?.data;
        resEnquiry?.data?.forEach((child) => {
          dataStage[0].amount += parseFloat(child?.Amount);
        });
      }

      return dataStage;
    } catch (error) {
      console.error(error);
      // setDragVisible(false);
    }
  };
  // Proposal Stage
  const proposalFun = async () => {
    try {
      // Deal Enquiry
      const dealStage = "Deals Proposal Stage";
      const res = await axios.get(
        `${BASE_URL}/user/DealPipeLineFilter/?Deal_Stage=${dealStage}`,
        requestOption
      );
      let dataStage = [...board?.columns];
      dataStage[1].amount = 0;

      if (res?.data?.length > 0) {
        dataStage[1].cards = res?.data;
        res?.data?.forEach((child) => {
          dataStage[1].amount += parseFloat(child?.Amount);
        });
      }

      return dataStage;
    } catch (error) {
      console.error(error);
      // setDragVisible(false);
    }
  };
  // negotiation Stage
  const negotiationFun = async () => {
    try {
      // Deal Enquiry
      const dealStage = "Deals Negotiation Stage";
      const res = await axios.get(
        `${BASE_URL}/user/DealPipeLineFilter/?Deal_Stage=${dealStage}`,
        requestOption
      );
      let dataStage = [...board?.columns];
      dataStage[2].amount = 0;

      if (res?.data?.length > 0) {
        dataStage[2].cards = res?.data;
        res?.data?.forEach((child) => {
          dataStage[2].amount += parseFloat(child?.Amount);
        });
      }

      return dataStage;
    } catch (error) {
      console.error(error);
      // setDragVisible(false);
    }
  };
  // qualifying Stage
  const qualifyingFun = async () => {
    try {
      // Deal Enquiry
      const dealStage = "Deals Qualifying Stage";
      const res = await axios.get(
        `${BASE_URL}/user/DealPipeLineFilter/?Deal_Stage=${dealStage}`,
        requestOption
      );
      let dataStage = [...board?.columns];
      dataStage[3].amount = 0;

      if (res?.data?.length > 0) {
        dataStage[3].cards = res?.data;
        res?.data?.forEach((child) => {
          dataStage[3].amount += parseFloat(child?.Amount);
        });
      }

      return dataStage;
    } catch (error) {
      console.error(error);
      // setDragVisible(false);
    }
  };
  // demo Stage
  const demoFun = async () => {
    try {
      // Deal Enquiry
      const dealStage = "Deals Demo Stage";
      const res = await axios.get(
        `${BASE_URL}/user/DealPipeLineFilter/?Deal_Stage=${dealStage}`,
        requestOption
      );
      let dataStage = [...board?.columns];
      dataStage[4].amount = 0;

      if (res?.data?.length > 0) {
        dataStage[4].cards = res?.data;
        res?.data?.forEach((child) => {
          dataStage[4].amount += parseFloat(child?.Amount);
        });
      }

      return dataStage;
    } catch (error) {
      console.error(error);
      // setDragVisible(false);
    }
  };
  // lost Stage
  const lostFun = async () => {
    try {
      // Deal Enquiry
      const dealStage = "Deals Lost Stage";
      const res = await axios.get(
        `${BASE_URL}/user/DealPipeLineFilter/?Deal_Stage=${dealStage}`,
        requestOption
      );
      let dataStage = [...board?.columns];

      dataStage[5].amount = 0;
      if (res?.data?.length > 0) {
        dataStage[5].cards = res?.data;
        res?.data?.forEach((child) => {
          dataStage[5].amount += parseFloat(child?.Amount);
        });
      }

      return dataStage;
    } catch (error) {
      console.error(error);
      // setDragVisible(false);
    }
  };

  const {
    data: enquiryDatas,
    isLoading: enquiryDatasIsLoading,
    isError: enquiryDatasIsError,
    refetch: enquiryDatasRefetch,
  } = useQuery(["getEnquiryDatas"], enquiryFun, { staleTime: Infinity });

  const {
    data: proposalDatas,
    isLoading: proposalDatasIsLoading,
    isError: proposalDatasIsError,
    refetch: proposalDatasRefetch,
  } = useQuery(["getproposalDatas"], proposalFun, { staleTime: Infinity });

  const {
    data: negotiationDatas,
    isLoading: negotiationDatasIsLoading,
    isError: negotiationDatasIsError,
    refetch: negotiationDatasRefetch,
  } = useQuery(["getnegotiationDatas"], negotiationFun, {
    staleTime: Infinity,
  });

  const {
    data: qualifyingDatas,
    isLoading: qualifyingDatasIsLoading,
    isError: qualifyingDatasIsError,
    refetch: qualifyingDatasRefetch,
  } = useQuery(["getqualifyingDatas"], qualifyingFun, { staleTime: Infinity });

  const {
    data: demoDatas,
    isLoading: demoDatasIsLoading,
    isError: demoDatasIsError,
    refetch: demoDatasRefetch,
  } = useQuery(["getdemoDatas"], demoFun, { staleTime: Infinity });

  const {
    data: lostDatas,
    isLoading: lostDatasIsLoading,
    isError: lostDatasIsError,
    refetch: lostDatasRefetch,
  } = useQuery(["getlostDatas"], lostFun, { staleTime: Infinity });

  useEffect(() => {
    if (enquiryDatas) {
      setBoard({ columns: enquiryDatas || [] });
      console.log("Board1 : ", enquiryDatas);
    }
  }, [enquiryDatas]);

  useEffect(() => {
    if (proposalDatas) {
      setBoard({ columns: proposalDatas || [] });
      console.log("Board2 : ", proposalDatas);
    }
  }, [proposalDatas]);

  useEffect(() => {
    if (negotiationDatas) {
      setBoard({ columns: negotiationDatas || [] });
      console.log("Board3 : ", negotiationDatas);
    }
  }, [negotiationDatas]);

  useEffect(() => {
    if (qualifyingDatas) {
      setBoard({ columns: qualifyingDatas || [] });
      console.log("Board4 : ", qualifyingDatas);
    }
  }, [qualifyingDatas]);

  useEffect(() => {
    if (demoDatas) {
      setBoard({ columns: demoDatas || [] });
      console.log("Board5 : ", demoDatas);
    }
  }, [demoDatas]);

  useEffect(() => {
    if (lostDatas) {
      setBoard({ columns: lostDatas || [] });
      console.log("Board6 : ", lostDatas);
    }
  }, [lostDatas]);

  const [filteredItems, setFilteredItems] = useState(null);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();

    // Deep clone the initialBoard columns structure
    const clonedColumns = JSON.parse(JSON.stringify(initialBoard?.columns));

    // Filter cards based on Account_Name
    const filteredData = board?.columns?.flatMap((column) =>
      column?.cards.filter((card) =>
        card?.Account_Name?.toLowerCase()?.includes(value)
      )
    );

    // Clear cards in cloned columns before adding filtered ones
    clonedColumns?.forEach((column) => {
      column.cards = [];
      column.amount = 0; // Reset amount to 0
    });

    // Update cloned columns based on filtered cards
    filteredData?.forEach((filteredCard) => {
      clonedColumns?.forEach((column) => {
        if (column?.title === filteredCard?.Deal_Stage?.split(" ")[1]) {
          column.cards.push(filteredCard);
          column.amount += filteredCard?.Amount;
        }
      });
    });

    // Update state with filtered columns
    if (Boolean(value)) {
      setFilteredItems({
        columns: clonedColumns?.filter((column) => column?.cards?.length > 0),
      });
    } else {
      setFilteredItems(null);
    }
  };

  // Update cards

  const updateCard = async (update) => {
    let indTo = update?.destination?.toColumnId;
    let indFr = update?.source?.fromColumnId;
    let con = board?.columns[indTo - 1]?.title;

    const updateDetails = {
      id: update?._card?.id,
      Deal_Stage: `Deals ${con} Stage`,
      Updated_Date: new Date().toISOString().split("T")[0],
    };

    console.log("updateDetails : ", update, updateDetails, con, indFr, indTo);
    if (Boolean(con)) {
      try {
        const response = await axios.patch(
          `${BASE_URL}/user/DealCRUD/`,
          updateDetails,
          requestOption
        );

        if (response) {
          enquiryDatasRefetch();
          proposalDatasRefetch();
          negotiationDatasRefetch();
          qualifyingDatasRefetch();
          demoDatasRefetch();
          lostDatasRefetch();

          await queryClient.invalidateQueries("DealsLists");
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response?.data?.details ||
            "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      }
    } else {
      toast.error("Invalid deal stage", {
        position: "top-right",
        autoClose: 100,
      });
    }
  };

  const renderCard = (props) => {
    let image = BASE_URL + props?.Account_Id?.File;

    console.log("image : ", props?.Amount, image);

    return (
      <div className="kanban-card w-60 " style={getGradient(props)}>
        <div className="w-full flex items-center justify-between">
          <Typography
            sx={[FontStyle]}
            className="text-gray-700 dark:text-gray-700"
          >
            {props?.Account_Name}
          </Typography>
          <AvatarCircle
            textColor="black"
            size="30px"
            fontSize="13px"
            bgColor="#f2f2f2"
            title={props?.Account_Name}
            image={Boolean(props?.Account_Id?.File) ? image : false}
          />
        </div>
        <Div className="flex items-center justify-between">
          <Typography
            sx={[FontStyle]}
            className="text-gray-700 dark:text-gray-700"
          >
            Deal Amount
          </Typography>

          <IconButton
            className="bg-gray-200"
            sx={{ cursor: "pointer" }}
            onClick={(e) => handleClickAction(e, props)}
          >
            {/* <InfoOutlinedIcon /> */}
            <InfoIcon className="text-cyan-600" />
          </IconButton>
        </Div>

        <Typography
          className="text-center text-white"
          sx={{ fontSize: "14px", fontWeight: "bold" }}
        >
          {toLocaleCurrency(props?.Amount || 0)}
        </Typography>
      </div>
    );
  };

  return (
    <JumboContentLayoutMain>
      <Div>
        <SearchBar
          color="gray"
          border={"1px solid lightgray"}
          width={{ xs: "100%", md: "250px !important" }}
          SearchData={handleSearch}
        />
      </Div>

      <div className="h-[700px] overflow-y-auto">
        <Board
          allowAddColumn
          allowRenameColumn
          allowRemoveCard
          onCardDragEnd={handleCardMove}
          onColumnDragEnd={handleColumnMove}
          renderCard={renderCard}
          renderColumnHeader={(props) => (
            <div className="column-header w-60  flex items-center justify-between">
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600 }}
                className="text-gray-800"
              >
                {props?.title}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 600 }}
                className="text-white"
              >
                {toLocaleCurrency(props?.amount || 0)}
              </Typography>
            </div>
          )}
          key={(props) => props?.id}
        >
          {Boolean(filteredItems) ? filteredItems : board}
        </Board>
      </div>

      {/* Overview  */}
      <Popover
        open={Boolean(anchorElAction)}
        anchorEl={anchorElAction}
        onClose={handleCloseAction}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: "250px" }}>
          <Div
            sx={[
              DisplayFlex,
              CRMTableHead,
              dialogTitle,
              { px: 2, py: 1, justifyContent: "space-between" },
            ]}
          >
            Overview
          </Div>
        </Box>
        <Box sx={{ p: 2 }}>
          <Div sx={[DisplayFlex, { mb: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Deal Name : </Div>
            <Div sx={[FontStyle, { color: Colors?.Mblue }]}>
              {update?.Deal_Name}
            </Div>
          </Div>
          <Div sx={[DisplayFlex, { mb: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Customer Name : </Div>
            <Div sx={[FontStyle, { color: Colors?.Mblue }]}>
              {update?.Account_Name}
            </Div>
          </Div>
          {/* <Div sx={[DisplayFlex, { my: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Deal Type : </Div>
            <Div sx={[FontStyle]}>{update?.Deal_Type}</Div>
          </Div> */}
          <Div sx={[DisplayFlex, { my: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Deal Probablity : </Div>
            <Div sx={[FontStyle, { color: Colors?.Mblue }]}>
              {update?.Deal_Probablity}%
            </Div>
          </Div>

          <Div sx={[DisplayFlex, { my: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Deal Prediction : </Div>
            <Div sx={[FontStyle, { color: Colors?.Mblue }]}>
              {" "}
              {update?.Prediction || 0} days
            </Div>
          </Div>

          <Div sx={[DisplayFlex, { my: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Deal Amount : </Div>
            <Div sx={[FontStyle, { color: Colors?.Mblue }]}>
              {toLocaleCurrency(update?.Amount || 0)}
            </Div>
          </Div>

          <Div sx={[DisplayFlex, { my: 1, justifyContent: "space-between" }]}>
            <Div sx={[FontStyle]}>Duration : </Div>
            <Div sx={[FontStyle, { color: Colors?.Mblue }]}>
              {update?.Updated_Date}
            </Div>
          </Div>
        </Box>
      </Popover>
    </JumboContentLayoutMain>
  );
};

export default PipeLineDeals;
