import React, { useEffect } from "react";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TextField,
  Typography,
  Pagination,
  Autocomplete,
  Checkbox,
  Box,
  Popover,
  useMediaQuery,
  List,
  Menu,
  FormGroup,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useState } from "react";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";

import { BiFilterAlt } from "react-icons/bi";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  CRMTableHead,
  Colors,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  avatarColors,
  componentTitle,
  input_label,
  dialogFontSize,
} from "../widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "../widgets/CRMTable";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import {
  AvatarCircle,
  Cancelbutton,
  CustomButton,
  Customgrid,
  DialogBox,
  Dragabledialog,
  LoadingFullPageSkel,
  NoRecordsPage,
  SelectDropdown,
  avatarColorFun,
  formatDate,
} from "../widgets/StylesComponents";
import CustomTableHeader from "../widgets/CustomTableHeader";
import { AutoHeight } from "app/redux/auth/localStorage";
import { GrPowerReset } from "react-icons/gr";
import { useQuery, useQueryClient } from "react-query";
import { FaGear } from "react-icons/fa6";
import "./Lead.css";

const UnqualifiedLeads = () => {
  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const matches = useMediaQuery("(min-width:759px)");

  let queryClient = useQueryClient();

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "CompanyName", label: "Company Name", bool: true },
    { sort: "LeadId", label: "Lead Id", bool: true },
    { sort: "EmailId", label: "Email Id", bool: true },
    { sort: "PhoneNo", label: "Phone No", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "LeadType", label: "Lead Source", bool: true },
    { sort: "Lead_Status", label: "Lead Status", bool: true },
    { sort: "LeadIndustry", label: "Industry Type", bool: false },
    { sort: "City", label: "City", bool: false },
    { sort: "State", label: "State", bool: false },
    { sort: "Country", label: "Country", bool: true },
  ]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [mailOverview, setMailOverview] = useState(null);
  const [mailOverviewCacel, setMailOverviewcancel] = useState(null);

  const [page, setPage] = useState(1);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contacts, setContacts] = useState([]); // initial state with one empty input
  const [age, setAge] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //  For Filter
  const [showFilter, setShowFilter] = useState(false);

  // Refresh Data
  const [datas, setDatas] = useState([]);

  // For Asecnding Order
  const [showsort, setShowsort] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  //code for table sorting
  // const sortData = (key, direction) => {
  //   const sortedData = [...datas];

  //   sortedData.sort((a, b) => {
  //     const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
  //     const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

  //     if (valueA < valueB) {
  //       return direction === "ascending" ? -1 : 1;
  //     }
  //     if (valueA > valueB) {
  //       return direction === "ascending" ? 1 : -1;
  //     }
  //     return 0;
  //   });

  //   setDatas(sortedData);
  // };

  // const handleSort = (key) => {
  //   let direction = "ascending";
  //   if (sortConfig.key === key && sortConfig.direction === "ascending") {
  //     direction = "descending";
  //   }
  //   setSortConfig({ key, direction });
  //   sortData(key, direction);
  //   setShowsort(false);
  // };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  //  Show Purpose
  const [notificationActive, setNotificationActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");
  const item = "crmuseradmin1";
  const [notificationMessage, setNotificationMessage] = useState(null); // State to hold the notification message
  const [number, setNumber] = useState(null);
  const [leadindustry, setLeadindustry] = useState([]);

  // const getData = async () => {
  //   try {
  //     const res1 = await axios.get(
  //       `${BASE_URL}/user/InboxEmailStoring/?UserId=${localStorage.getItem("UserId")}&MailId=ravimech476@gmail.com`,
  //       {
  //         headers: {
  //           db: item,
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       }
  //     );

  //     const res2 = await axios.get(`${BASE_URL}/user/InboxEmailCRUD/`, {
  //       headers: {
  //         db: item,
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     });

  //     setDatas1(res2.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (isLoggedIn === "true") {
  //     getData();

  //     const interval = setInterval(() => {
  //       getData();
  //     }, 5000);

  //     return () => clearInterval(interval);
  //   }
  // }, [isLoggedIn]);

  // useEffect(() => {
  //   if (isLoggedIn === "true") {
  //     const currentEmailCount = datas1.length;
  //     if (currentEmailCount > prevEmailCountRef.current) {
  //       const newlyAddedData = datas1.slice(prevEmailCountRef.current);
  //       showNotification(newlyAddedData);
  //     }
  //     prevEmailCountRef.current = currentEmailCount;
  //   }
  // }, [datas1, isLoggedIn]);

  // const closeNotification = () => {
  //   setNotificationActive(false);
  //   setNewEmailData(null);
  // };

  // Get Uqualified Lead List

  const UnqualifiedLead = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/CombinedLeadSearch/?Created_By=${userId}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log("UnqualifiedLead response", response?.data);
      // setDatas(response?.data);
      setDatas(response?.data);
      console.log("getUnqualifiedLead", response?.data);
      setAllselected(false);
      setTabValue("");
      setSelecteduser([]);
      // return response?.data;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // For Delete Functionality
  const Deletedata = () => {
    axios
      .delete(`${BASE_URL}/user/UserLeadfromcontactsCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
        console.log("error", error);
      });
  };

  // Geting the Job Tittle From UserAdmin Table
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  const [tabValue, setTabValue] = useState("");

  const handleChange = async (e, newValue) => {
    try {
      if (!Boolean(newValue)) {
        UnqualifiedLead();
        // UnqualifiedLead();
        // queryClient.refetchQueries("getUnqualifiedLeadLists");
        setTabValue("");
        setAllselected(false);
      } else {
        const response = await axios.get(
          `${BASE_URL}/user/CombinedLeadFilterSearch/?Created_By=${userId}&Industry_Type=${newValue?.value}`,
          {
            headers: {
              db: "crmuseradmin",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setDatas(response?.data);
        setTabValue(newValue);
        setAllselected(false);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  // For Checking the Checkbox Value
  const [selecteduser, setSelecteduser] = useState([]);
  const [allselected, setAllselected] = useState(false);

  const selectedData = (data) => {
    let updatedata = [...selecteduser];

    let bool = selecteduser?.findIndex(
      (user) => user.id == data.id && user.LeadType == data.LeadType
    );
    if (bool == -1) {
      updatedata.push(data);
    } else {
      updatedata.splice(bool, 1);
    }
    setSelecteduser(updatedata);

    const allSelect = datas?.every((user) =>
      updatedata?.some((use) => user?.id === use?.id)
    );

    setAllselected(allSelect);

    console.log("updatedata", allSelect, updatedata, data);
  };

  const selectAll = () => {
    if (!allselected) {
      setSelecteduser([...datas]);
    } else {
      setSelecteduser([]);
    }

    setAllselected(!allselected);
  };

  const handleSendQuestions = () => {
    console.log("sendQuestions", selecteduser);
  };

  // Send Mail Functionality
  const sendQuestions = async () => {
    let mailcontent = `
    <body style="font-family: Arial, sans-serif; line-height: 1.6; background-color: #f4f4f4; margin: 0; padding: 20px;">
  <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 20px; border-radius: 8px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
    <p style="color: #555555; font-size: 16px;">Dear Sir/Madam,</p>
    
    <p style="color: #555555; font-size: 16px;">I hope this message finds you well.</p>
    
    <p style="color: #555555; font-size: 16px;">I’m excited to introduce you to <strong>CRMfarm</strong>, a powerful CRM solution designed to streamline your sales process and drive results. Our platform offers features like lead qualification, lead prediction, auto responses, deal probability, and much more, all integrated seamlessly with third-party tools.</p>
    
    <p style="color: #555555; font-size: 16px;">To learn more about how CRMfarm can benefit your business, please click the link below:</p>
    
    <p style="color: #2980b9; font-size: 16px;"><a href="{{url}}" style="color: #2980b9; text-decoration: none;">{{url}}</a></p>
    
    <p style="color: #555555; font-size: 16px;">If you have any questions or would like to schedule a demo, feel free to reply to this email.</p>
    
    <p style="color: #555555; font-size: 16px;">Looking forward to your response!</p>
    
    <p style="color: #555555; font-size: 16px;">Best regards,</p>
    <p style="color: #555555; font-size: 16px;"><strong>${
      userDetails && userDetails?.username
    }, </strong><br>${userDetails && userDetails?.Designation}
    [
  </div>
</body>
    `;

    // var mailContent = `<div style="width: 100%; font-family: Arial, sans-serif;">
    // <h4 className="mb-2 text-muted">Lead Qualify Questions</h4>
    // <br/>
    // <p>
    //   Click here <a href="{{url}}">Click Here</a>.
    // </p>
    // <br/>
    // <p>Best Regards,</p>
    // <p>${userDetails && userDetails?.username},</p>
    // <p>${userDetails && userDetails?.Designation},</p>
    // </div>`;

    var payload = {
      user: selecteduser,
      mailhtml: mailcontent,
      UserId: localStorage.getItem("UserId"),
    };
    await axios
      .post(`${BASE_URL}/user/SendLeadQualifyQuestions/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log("res", res);
        toast.success("Mail Send Successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setMailOverview(false);
        UnqualifiedLead();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // For Filter Functionality
  const [contry, setContry] = useState("");
  const [cty, setCty] = useState("");
  const [indus, setIndus] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setToDate] = useState("");
  const [statefil, setStatefil] = useState("");

  // const filterSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/user/UserQualifiedLeadsFilter/?Industry_Type=${indus}&Country=${contry}&State=${statefil}&City=${cty}&fromdate=${fromdate}&todate=${todate}`,
  //       {
  //         headers: {
  //           db: item,
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       }
  //     );

  //     // Check if the response status is OK (200)
  //     setDatas(response.data);
  //     setShowFilter(false);
  //   } catch (error) {
  //     // Handle any network or other errors that may occur during the request
  //     console.error("An error occurred:", error);
  //   }
  // };

  const RefreshData = () => {
    setContry("");
    setCty("");
    setIndus("");
    setFromdate("");
    setToDate("");
    setStatefil("");
    UnqualifiedLead();
    setShowFilter(false);
  };

  const dialogFilter = (event) => {
    setShowFilter(event.currentTarget);
  };

  const closeFilter = () => {
    setShowFilter(null);
  };

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  // const {
  //   data: getUnqualifiedLead,
  //   isLoading: getUnqualifiedLeadLoading,
  //   isError: getUnqualifiedLeadError,
  //   refetch: getUnqualifiedLeadRefetch,
  // } = useQuery(["getUnqualifiedLeadLists"], UnqualifiedLead, {
  //   staleTime: Infinity,
  // });

  useEffect(() => {
    if (getUserLoopUp) {
      setLeadindustry(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type") || []
      );
    }
  }, [getUserLoopUp]);

  // useEffect(() => {
  //   if (getUnqualifiedLead !== undefined && getUnqualifiedLead !== null) {
  //     setDatas(getUnqualifiedLead);
  //     console.log("getUnqualifiedLead",getUnqualifiedLead)
  //     setAllselected(false);
  //     setTabValue("");
  //     setSelecteduser([]);
  //   }
  // }, [getUnqualifiedLead]);
  useEffect(() => {
    UnqualifiedLead();
  }, []);

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas]?.sort((a, b) =>
      value === "A-Z"
        ? a?.LeadFirstName?.localeCompare(b?.LeadFirstName)
        : b?.LeadFirstName?.localeCompare(a?.LeadFirstName)
    );
    setDatas(sortedData);
    console.log("sortedData", value, "A-Z", sortedData);
    // setIsSortName(value);
  };

  return (
    <JumboContentLayoutMain>
      <Dragabledialog maxWidth="lg" open={Boolean(mailOverview)}>
        <DialogTitle
          sx={[DisplayFlex, dialogFontSize]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Mail Preview
        </DialogTitle>

        <DialogContent sx={{ mt: 2 }}>
          <Customgrid>
            <Grid item>
              <Typography sx={[input_label]}>From</Typography>
            </Grid>
            <Grid item>
              <Div sx={[input_label, { p: 1, bgcolor: "#f9f9f9" }]}>
                {userDetails?.email || " - "}
              </Div>
            </Grid>
            <Grid item>
              <Typography sx={[input_label]}>To</Typography>
            </Grid>
            <Grid item>
              <Div
                sx={{
                  p: 1,
                  bgcolor: "#f9f9f9",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  flexWrap: "wrap",
                }}
              >
                {selecteduser?.map((item, idx) => {
                  return (
                    <Typography sx={[input_label]}>{item?.EmailId}</Typography>
                  );
                })}
              </Div>
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Subject</Typography>
            </Grid>
            <Grid item>
              <Typography sx={[input_label, { p: 1, bgcolor: "#f9f9f9" }]}>
                Lead Qualify Questions
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Content</Typography>
            </Grid>
            <Grid item>
              <Div
                sx={[input_label, { p: 1, bgcolor: "#F9F9F9" }]}
                className="min-h-60"
              >
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    lineHeight: "1.6",
                    backgroundColor: "#f4f4f4",
                    margin: "0",
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "600px",
                      margin: "0 auto",
                      backgroundColor: "#ffffff",
                      padding: "20px",
                      borderRadius: "8px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      Dear Sir/Madam,
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      I hope this message finds you well.
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      I’m excited to introduce you to <strong>CRMfarm</strong>,
                      a powerful CRM solution designed to streamline your sales
                      process and drive results. Our platform offers features
                      like lead qualification, lead prediction, auto responses,
                      deal probability, and much more, all integrated seamlessly
                      with third-party tools.
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      To learn more about how CRMfarm can benefit your business,
                      please click the link below:
                    </p>

                    <p style={{ color: "#2980b9", fontSize: "16px" }}>
                      <a
                        href={""}
                        style={{ color: "#2980b9", textDecoration: "none" }}
                      >
                        Click here
                      </a>
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      If you have any questions or would like to schedule a
                      demo, feel free to reply to this email.
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      Looking forward to your response!
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      Best regards,
                    </p>

                    <p style={{ color: "#555555", fontSize: "16px" }}>
                      <strong>{userDetails?.Username}</strong>,<br />
                      {userDetails?.Designation}
                    </p>
                  </div>
                </div>
              </Div>
            </Grid>
          </Customgrid>

          <Div
            sx={[
              DisplayFlex,
              { justifyContent: "center", gap: "10px !important", mt: 2 },
            ]}
          >
            <CustomButton
              Icon="sendIcon"
              onClick={() => sendQuestions()}
              width="130px"
              type="submit"
              label="Send Mail"
              disabled={selecteduser?.length === 0}
            />

            <Cancelbutton
              label="Cancel"
              onClick={() => {
                setMailOverviewcancel(true);
              }}
            />
          </Div>
        </DialogContent>
      </Dragabledialog>

      <DialogBox
        open={mailOverviewCacel}
        onClickNo={() => setMailOverviewcancel(false)}
        onClickYes={() => {
          setMailOverview(false);
          setMailOverviewcancel(false);
        }}
      />

      <ToastContainer />
      <Box sx={{ p: 2 }}>
        <Box sx={{}}>
          <Box sx={{ mb: 2 }} id="heads">
            <Box className="flex items-center justify-between flex-wrap">
              <Typography
                sx={[componentTitle, { color: Colors?.gray, fontWeight: 700 }]}
              >
                Unqualified Lead List
              </Typography>

              <Div className="flex items-center justify-between gap-2">
                <SelectDropdown
                  className="search-select3"
                  defaultValue={"A-Z"}
                  onChange={sortData}
                  options={["A-Z", "Z-A"]}
                  width="70px"
                  clearButton={false}
                />
                {matches && (
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Div>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={5} md={9} display={"flex"} alignItems={"center"}>
                {/* <IconButton
                sx={{ color: Colors?.Mblue }}
                onClick={dialogFilter}
              >
                <BiFilterAlt size={22} />
              </IconButton> */}
                <Typography
                  sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                >
                  ( {datas?.length} ) Unqualified Leads
                </Typography>
              </Grid>
              <Grid xs={2} md={1}>
                {" "}
                <Div>
                  <Typography sx={[input_label]}>Industry Type</Typography>
                </Div>
              </Grid>
              <Grid xs={3} md={2}>
                <Div>
                  <Autocomplete
                    className="search-select3 col-12 "
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    options={leadindustry?.Values || []}
                    onChange={(e, newValue) => {
                      handleChange(e, newValue);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "10px",
                        }}
                        placeholder="Industry Type"
                      />
                    )}
                  />
                </Div>
              </Grid>

              {!matches && (
                <Grid item xs={1}>
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              )}

              {/* Filter popover  */}

              {/* <Popover
              open={Boolean(showFilter)}
              anchorEl={showFilter}
              onClose={() => setShowFilter(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
            >
              <Box>
                <Div
                  sx={[
                    DisplayFlex,
                    CRMTableHead,
                    dialogTitle,
                    { px: 2, justifyContent: "space-between" },
                  ]}
                >
                  <Typography sx={[dialogTitle]}>Filters</Typography>
                  <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                    <GrPowerReset size={16} />
                  </IconButton>
                </Div>

                <form style={{ padding: "1rem" }} onSubmit={filterSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        Country
                      </Typography>
                      <Autocomplete
                        className="col-12 search-select33"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        options={Country.getAllCountries() || []}
                        value={contry?.name}
                        onChange={(e, newValue) => {
                          setContry(newValue);
                        }}
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Country" />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        State
                      </Typography>
                      <Autocomplete
                        className=" col-12 search-select33"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        value={statefil?.name}
                        options={
                          State.getStatesOfCountry(contry?.isoCode) || []
                        }
                        onChange={(e, newValue) => {
                          setStatefil(newValue);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.name === value || value === ""
                        }
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="State" />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        City
                      </Typography>
                      <Autocomplete
                        className="col-12 search-select33"
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        options={
                          City.getCitiesOfState(
                            statefil?.countryCode,
                            statefil?.isoCode
                          ) || []
                        }
                        value={cty?.name}
                        onChange={(e, newValue) => {
                          setCty(newValue);
                        }}
                        id="outlined-basic"
                        renderInput={(params) => (
                          <TextField {...params} placeholder="City" />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        Industry Type
                      </Typography>
                      <TextField
                        type="text"
                        className="col-12 input-box2"
                        autoComplete="off"
                        placeholder="Industry Type"
                        value={indus}
                        onChange={(e) => {
                          setIndus(e.target.value);
                        }}
                        sx={{ mt: -0.5 }}
                      ></TextField>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        From Date
                      </Typography>
                      <TextField
                        type="date"
                        className="col-12 input-box2"
                        value={fromdate}
                        onChange={(e) => {
                          setFromdate(e.target.value);
                        }}
                        id="createdDate"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Typography
                        sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                      >
                        To Date
                      </Typography>
                      <TextField
                        value={todate}
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                        type="date"
                        className="col-12 input-box2"
                        id="lastDate"
                      />
                    </Grid>
                  </Grid>

                  <Div
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      gap: "10px",
                    }}
                  >
                  
                    <Cancelbutton onClick={() => setShowFilter(null)} />
                    <CustomButton
                      type="submit"
                      label="Apply"
                      Icon="filterIcon"
                    />
                  </Div>
                </form>
              </Box>
            </Popover> */}

              {/* <Grid item xs={12} md={4}>
            <Div sx={{ width: { md: window.innerWidth / 3 } }}>
              <SearchBar
                SearchData={SearchData}
                width={"100%"}
                color={"black"}
              />
            </Div>
          </Grid> */}
              {/* <Grid item xs={12} md={6}>
              <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                <CustomButton
                  onClick={() => setShowAccounts("create")}
                  label="Create"
                  Icon="createIcon"
                  endIcon={true}
                />
              </Div>
            </Grid> */}
            </Grid>
          </Box>
          {Boolean(tabValue) && (
            <Div
              sx={[
                DisplayFlex,
                { mx: 1.5, mt: 3, justifyContent: "space-between" },
              ]}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allselected}
                    onChange={() => {
                      // selectedData(data);
                      selectAll();
                    }}
                    sx={{ p: 0, mr: 1 }}
                    color="info"
                    size="medium"
                  />
                }
                label={
                  <Typography sx={[FontStyle]}>
                    Select All ( {selecteduser?.length || 0} )
                  </Typography>
                }
              />

              <Div
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Div>
                  <CustomButton
                    label="Send Mail"
                    width="130px"
                    onClick={() => {
                      setMailOverview(true);
                    }}
                    Icon="sendIcon"
                    disabled={selecteduser?.length === 0}
                  />
                </Div>
                <Div>
                  <Cancelbutton
                    onClick={() => {
                      setTabValue("");
                      setSelecteduser([]);
                      setAllselected(false);
                    }}
                    label="Cancel"
                  />
                </Div>
              </Div>
            </Div>
          )}
          <List sx={{ mt: 0 }}>
            {datas?.length > 0 &&
              datas?.map((data, idx) => {
                // if (idx < 2) {

                let image =
                  (BASE_URL +
                    data?.File?.split("https://backend.crmfarm.app")[1] ||
                    data?.File) ??
                  "";

                return (
                  <Div
                    // onMouseOver={() => setFadeEdit(idx)}
                    // onMouseLeave={() => setFadeEdit(null)}
                    className=""
                    key={idx}
                    sx={{
                      minHeight: "124px",
                      p: { xs: 0.5, sm: 2 },
                      px: { xs: 0.5, md: 7 },
                      my: 2,
                      bgcolor: "white",
                      borderRadius: { xs: "5px", md: "10px" },
                      ":hover": {
                        boxsShadow:
                          "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
                      },
                      cursor: "default",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        position: "absolute",
                        top: 0,
                        transform: "translateX(10px)",
                      }}
                      control={
                        <>
                          {Boolean(tabValue) && (
                            <Checkbox
                              checked={selecteduser?.some(
                                (user) =>
                                  user?.id == data?.id &&
                                  user?.LeadType == data?.LeadType
                              )}
                              onChange={() => {
                                selectedData(data);
                              }}
                              sx={{ p: 0 }}
                              color="info"
                              size="medium"
                            />
                          )}
                        </>
                      }
                    />

                    <Grid
                      container
                      alignItems={"center"}
                      spacing={2}
                      sx={{ pr: "5%" }}
                    >
                      <Grid item xs={12} md={1.5}>
                        <div
                          style={{ marginTop: "6px" }}
                          // onClick={() => handleClickOverviewNotification(data)}
                        >
                          <AvatarCircle
                            size="90px"
                            fontSize="30px"
                            bgColor={avatarColorFun(idx)}
                            title={!Boolean(data?.File) && data?.LeadFirstName}
                            image={Boolean(data?.File) ? image : false}
                          />
                        </div>
                      </Grid>

                      <Grid container spacing={1} item xs={12} md={10.5}>
                        <Grid item xs={12}>
                          <Box
                            sx={[
                              DisplayFlex,
                              {
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                position: "relative",
                              },
                            ]}
                          >
                            <Typography
                              sx={[
                                {
                                  fontSize: "18px",
                                  fontWeight: 700,
                                  color: `${Colors?.Mblue}`,
                                },
                              ]}
                            >
                              {data?.LeadFirstName + " " + data?.LeadLastName ??
                                ""}
                            </Typography>
                            <Div sx={[DisplayFlex]}>
                              {/* <Div
                              className={` ${
                                idx == fadeEdit ? "fade-in-right" : ""
                              } `}
                              sx={{
                                display: {
                                  xs: "none !important",
                                  sm:
                                    fadeEdit === idx
                                      ? "flex !important"
                                      : "none !important",
                                },
                                position: { md: "absolute" },
                                right: { md: "-10px" },
                                top: { md: "0px" },
                              }}
                            >
                              <Div
                                sx={[
                                  FontStyle,
                                  DisplayFlex,
                                  {
                                    cursor: "pointer",
                                    transform: "translateX(-50px)",
                                    color: Colors?.Mblue,
                                  },
                                ]}
                                onClick={() => {
                                  // handleClickAction(data);
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    minWidth: "25px !important",
                                    color: Colors?.Mblue,
                                  }}
                                >
                                  <TiEdit
                                    style={{
                                      fontSize: 18,
                                      fontWeight: 900,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={[
                                        FontStyle,
                                        { color: Colors?.Mblue },
                                      ]}
                                    >
                                      Assign lead
                                    </Typography>
                                  }
                                />
                              </Div>
                            </Div> */}
                              {/* <CustomButton
                                onClick={(e) => {
                                  setOpenAssignLead(data);
                                }}
                                sx={{ borderRadius: "25px" }}
                                label="Assign Lead"
                                bgColor={Colors?.Mblue}
                                Icon=""
                              /> */}
                            </Div>
                          </Box>
                        </Grid>
                        {columnMenuItems?.map(
                          (Menu, subIdx) =>
                            Menu?.bool && (
                              <Grid
                                key={subIdx}
                                item
                                xs={12}
                                md={4}
                                sx={[DisplayFlex]}
                              >
                                <Typography
                                  sx={[{ fontSize: "14px", color: "#828282" }]}
                                >
                                  <span
                                    style={{
                                      fontWeight: 700,
                                      color: "#5e5e5e",
                                    }}
                                  >
                                    {Menu?.label} :
                                  </span>{" "}
                                </Typography>
                                <Typography
                                  sx={[
                                    {
                                      fontSize: "14px",
                                      color:
                                        Menu?.sort === "Owner"
                                          ? Colors?.red
                                          : "#5e5e5e",
                                    },
                                  ]}
                                >
                                  {Menu?.sort === "Created_Date" ||
                                  Menu?.sort === "Updated_Date"
                                    ? formatDate(data[Menu?.sort])
                                    : Menu?.sort === "LeadId"
                                    ? data?.LeadId ||
                                      data?.ContleadId ||
                                      data?.EnqleadId ||
                                      data?.SocialMedialeadId
                                    : data[Menu?.sort] || "Not Assigned"}
                                </Typography>
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Grid>
                  </Div>
                );
                // }
              })}
          </List>
          {/* gear options */}
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            sx={{
              mt: 5,
              maxHeight: "300px",
              scrollBehavior: "smooth",
            }}
          >
            <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
              Choose Fields
            </Typography>
            <FormGroup sx={{ p: 2 }}>
              {columnMenuItems?.length > 0 &&
                columnMenuItems?.map((column, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="info"
                          size="small"
                          checked={column?.bool}
                          onChange={(e) => {
                            let value = [...columnMenuItems];
                            value[index].bool = !column?.bool;
                            setColumnMenuItems(value);
                          }}
                        />
                      }
                      label={
                        <Typography sx={[FontStyle]}>
                          {column?.label}
                        </Typography>
                      }
                    />
                  );
                })}
            </FormGroup>
          </Menu>
        </Box>
      </Box>
    </JumboContentLayoutMain>
  );
};

export default UnqualifiedLeads;
