import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  DisplayFlex,
  FontStyle,
  dialogTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import {
  Cancelbutton,
  CustomButton,
  Customgrid,
  DialogBox,
  Dragabledialog,
  InvalidateQueryUpdates,
  errorFilter,
  fetchTransactionSeries,
} from "app/pages/widgets/StylesComponents";
import React, { useEffect, useState } from "react";
import { useTerritoryList, useUserList } from "../TerritoryAPI";
import { City, Country, State } from "country-state-city";
import { toast } from "react-toastify";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { useQueryClient } from "react-query";

const CreateAndEdit = ({ open, setOpen, update }) => {
  const queryClient = useQueryClient();

  const token = localStorage.getItem("accesstoken");

  const [save, setSave] = useState(false);
  const { userList } = useUserList();

  const [manager, setManager] = useState("No Name");
  const [userNames, setUserNames] = useState([]);
  const [territoryType, setTerritoryType] = useState("");
  const [territoryName, setTerritoryName] = useState("");
  const [category, setCategory] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [seriesValue, setSeriesValue] = useState("");
  const [territoryId, setTerritoryId] = useState("");
  const [territoryDetails, setTerritoryDetails] = useState({
    parentId: "",
    parentName: "",
  });

  const createSubmit = async (data) => {
    const payload = {
      Territory_Id: seriesValue === "Manual" && territoryId,
      TerritoryName: territoryName,
      Manager_Name: manager?.username,
      TerritoryManager: manager?.id,
      TerritoryType: territoryType,
      ParentTerritory: Number(territoryDetails?.parentId) || null,
      ParentTerritory_Name: territoryDetails?.parentName || null,
      AssignSalesPersons: userNames?.map((item) => item?.id),
      Territery_Categeroy: category || "",
      Assign_Users: userNames?.map((item) => item?.username),
      AssignAreas: category === "Country" ? selectedCountry?.name : category === "State" ? selectedState?.name : selectedCity?.name,
      AssignAreas: category === "Country" ? selectedCountry?.name : category === "State" ? selectedState?.name : selectedCity?.name,
      Country: selectedCountry?.name || "",
      State: selectedState?.name || "",
      City: selectedCity?.name || "",
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("OrganizationId"),
      Updated_By: localStorage.getItem("OrganizationId"),
    };
    console.log("Create payload", payload);

    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/user/TerritoryManagementCRUD/`, payload, {
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Create Territory Form", response);
      queryClient.invalidateQueries("getTerritoryLists");
      toast.success("Territory created successfully");
      setOpen(null);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 1000,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const editSubmit = async (data) => {
    const payload = {
      id: update?.id,
      Territory_Id: update?.Territory_Id,
      TerritoryName: territoryName,
      Manager_Name: manager?.username,
      TerritoryManager: manager?.id,
      TerritoryType: territoryType,
      Territery_Categeroy: category || "",
      ParentTerritory: Number(update?.ParentTerritory) || null,
      ParentTerritory_Name: update?.ParentTerritory_Name || null,
      AssignSalesPersons: userNames?.map((item) => item?.id),
      Assign_Users: userNames?.map((item) => item?.username),
      AssignAreas: category === "Country" ? selectedCountry?.name : category === "State" ? selectedState?.name : selectedCity?.name,
      Country: selectedCountry?.name || "",
      State: selectedState?.name || "",
      City: selectedCity?.name || "",
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("OrganizationId"),
      Updated_By: localStorage.getItem("OrganizationId"),
    };
    console.log("Edit payload", payload);

    try {
      setLoading(true);
      const response = await axios.put(`${BASE_URL}/user/TerritoryManagementCRUD/`, payload, {
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Update Territory Form", response);
      queryClient.invalidateQueries("getTerritoryLists");
      toast.success("Territory updated successfully");
      setOpen(null);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage = error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 1000,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (type) => {
    if (type === "post") {
      createSubmit();
    } else if (type === "put") {
      editSubmit();
    }
  };

  useEffect(() => {
    if (update) {
      if (open === "Create" && Boolean(update?.TerritoryName)) {
        setTerritoryType("Child");
        setTerritoryDetails({ ...territoryDetails, parentId: update?.id, parentName: update?.TerritoryName })
      } else if (update?.organisation) {
        setTerritoryType("Parent");
      } else if (open === "Edit") {
        const userNames = update?.Assign_Users?.map((item, i) => ({
          username: item,
          id: update?.AssignSalesPersons[i],
        })) || [];

        setCategory(update?.Territery_Categeroy || "");
        setTerritoryName(update?.TerritoryName || "");
        setTerritoryId(update?.Territory_Id || "");
        setManager({
          username: update?.Manager_Name || "",
          id: update?.TerritoryManager || null,
        });
        setTerritoryType(update?.TerritoryType || "");
        setUserNames(userNames);
        setSelectedCountry(update?.Countr);
        setSelectedState(update?.State);
        setSelectedCity(update?.City);
        console.log("User names: ", userNames);
      }
    }
  }, [update, open]);

  useEffect(() => {
    const seriesFun = async () => {
      if (open != "Create") return;
      const series = await fetchTransactionSeries("Territory_Management");
      console.log("Territory_Management series", series);
      setSeriesValue(series);
    };

    seriesFun();
  }, [open]);

  console.log("updatesssss", open, update);

  return (
    <>
      <Dragabledialog
        maxWidth="md"
        open={open}
      >
        <DialogTitle
          sx={[DisplayFlex, dialogTitle, { cursor: "move" }]}
          id="draggable-dialog-title"
        >
          {open} Territory
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Typography sx={[input_label]}>
                Territory Id
                {!Boolean(open == "Edit") && (<span className="required"> {seriesValue == "Automatic" ? "" : "*"} </span>)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                sx={{
                  "& fieldset": {
                    borderRadius: "5px",
                  },
                  width: "100%",
                }}
                value={territoryId}
                onChange={(e) => setTerritoryId(e.target.value)}
                className="input-box2 w-full"
                id="outlined-basic"
                placeholder="# Territory Id"
                variant="outlined"
                disabled={
                  seriesValue == "Automatic"
                    ? true
                    : "" || Boolean(open == "Edit")
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={[input_label]}>Territory Name</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                sx={{
                  "& fieldset": {
                    borderRadius: "5px",
                  },
                  width: "100%",
                }}
                defaultValue={territoryName}
                value={territoryName}
                onChange={(e) => setTerritoryName(e.target.value)}
                className="input-box2 w-full"
                id="outlined-basic"
                placeholder="Territory Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={[input_label]}>Territory Manager</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <FormControl className="w-full">
                <Autocomplete
                  className="search-select3  w-full"
                  options={userList || []}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.username) {
                      return option?.username;
                    }
                    return "";
                  }}
                  value={manager?.username}
                  onChange={(e, newValue) => {
                    setManager(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={[FontStyle, { width: "100%" }]}
                      {...params}
                      placeholder="Territory Manager"
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={[input_label]}>Add Users</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              {" "}
              <FormControl className="w-full">
                <Autocomplete
                  multiple
                  className="search-select"
                  autoComplete="off"
                  options={userList || []}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.username) {
                      return option?.username;
                    }
                    return "";
                  }}
                  value={userNames}
                  onChange={(e, newValue) => {
                    setUserNames(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={[FontStyle, { width: "100%" }]}
                      {...params}
                      placeholder="select Users"
                    />
                  )}
                  sx={{ height: "auto !important" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={[input_label]}>Territory Type</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              {" "}
              <TextField
                sx={{
                  "& fieldset": {
                    borderRadius: "5px",
                  },
                  width: "100%",
                }}
                value={territoryType}
                className="input-box2 w-full"
                id="outlined-basic"
                placeholder="Territory Type"
                variant="outlined"
              />
            </Grid>
            {Boolean(update?.TerritoryName) && (
              <Grid item xs={12} md={3}>
                <Typography sx={[input_label]}>Parent Territory</Typography>
              </Grid>
            )}
            {Boolean(update?.TerritoryName) && (
              <Grid item xs={12} md={7}>
                <FormControl className="w-full">
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                      width: "100%",
                    }}
                    defaultValue={update?.TerritoryName}
                    value={update?.TerritoryName}
                    className="input-box2 w-full"
                    id="outlined-basic"
                    placeholder="Territory Parent Name"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} md={3}>
              <Typography sx={[input_label]}>Territory Categeroy</Typography>
            </Grid>

            <Grid item xs={12} md={7}>
              {" "}
              <FormControl className="w-full">
                <Autocomplete
                  className="search-select3 w-full"
                  autoComplete="off"
                  options={["Country", "State", "City"]}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option) {
                      return option;
                    }
                    return "";
                  }}
                  value={category}
                  onChange={(e, newValue) => {
                    setCategory(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={[FontStyle, { width: "100%" }]}
                      {...params}
                      placeholder="Territory Categeroy"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            {(category === "Country" || category === "State" || category === "City") && (
              <>
                <Grid item xs={12} md={3}>
                  <Typography sx={[input_label]}>
                    Country
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    className="search-select3 w-full"
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    options={Country.getAllCountries() || []}
                    value={selectedCountry?.name}
                    onChange={(e, newValue) => {
                      setSelectedCountry(newValue);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Country" />
                    )}
                  />
                </Grid>
              </>
            )}

            {(category == "City" || category == "State") && (
              <>
                <Grid item xs={12} md={3}>
                  <Typography sx={[input_label]}>
                    State
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    className="search-select3 w-full"
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    value={selectedState?.name}
                    options={
                      State.getStatesOfCountry(selectedCountry?.isoCode) || []
                    }
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setSelectedState(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value || value === ""
                    }
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="State" />
                    )}
                  />
                </Grid>
              </>
            )}

            {category == "City" && (
              <>
                <Grid item xs={12} md={3}>
                  <Typography sx={[input_label]}>
                    City
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    className="col-12 search-select3"
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.name) {
                        return option?.name;
                      }
                      return "";
                    }}
                    options={
                      City.getCitiesOfState(
                        selectedState?.countryCode,
                        selectedState?.isoCode
                      ) || []
                    }
                    value={selectedCity?.name}
                    onChange={(e, newValue) => {
                      setSelectedCity(newValue);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="City" />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <DialogActions
            sx={[DisplayFlex, { justifyContent: "center !important" }]}
          >
            <CustomButton
              label={
                loading ? "Wait..." : open === "Create" ? "Save" : "Update"
              }
              Icon={loading ? "" : "saveIcon"}
              disabled={loading}
              onClick={() => {
                !loading && open === "Create"
                  ? onSubmit("post")
                  : onSubmit("put");
              }}
              type="submit"
            />
            <Cancelbutton
              onClick={() => {
                setSave(true);
              }}
            />
          </DialogActions>
        </DialogContent>
      </Dragabledialog>
      <DialogBox
        open={save}
        onClickNo={() => {
          setSave(false);
        }}
        onClickYes={() => {
          setSave(false);
          setOpen(false);
        }}
      />
    </>
  );
};

export default CreateAndEdit;
