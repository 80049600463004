// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emails-wrapper {
  border: 1px solid #ccc; /* Replace with \$border-color */
  border-right: 4px solid #388e3c; /* Replace with \$primary-green */
  padding: 1rem;
  background-color: #e8f5e9; /* Replace with \$secondary-green */
}

.email-card {
  cursor: pointer;
  background-color: white;
  border-bottom: 1px solid lightgray;
  padding: 15px;
}
.email-card:hover {
  background-color: lightgray;
}

.emailcard-username,
.emailcard-subject {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #242424 !important;
}

.emailcard-date {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #242424 !important;
}

.emailcard-body {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #646464 !important;
}

.emails-wrapper:hover {
  cursor: pointer;
}

.emails-wrapper__date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.read {
  background-color: white;
  border-right: none;
}

.read-text {
  font-weight: 300; /* Replace with your typography rule if needed */
  padding: 0.6rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/OutlookAPI/Components/EmailsList/EmailsList.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,+BAA+B;EACvD,+BAA+B,EAAE,gCAAgC;EACjE,aAAa;EACb,yBAAyB,EAAE,kCAAkC;AAC/D;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,kCAAkC;EAClC,aAAa;AACf;AACA;EACE,2BAA2B;AAC7B;;AAEA;;EAEE,0BAA0B;EAC1B,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB,EAAE,gDAAgD;EAClE,iBAAiB;AACnB","sourcesContent":[".emails-wrapper {\n  border: 1px solid #ccc; /* Replace with $border-color */\n  border-right: 4px solid #388e3c; /* Replace with $primary-green */\n  padding: 1rem;\n  background-color: #e8f5e9; /* Replace with $secondary-green */\n}\n\n.email-card {\n  cursor: pointer;\n  background-color: white;\n  border-bottom: 1px solid lightgray;\n  padding: 15px;\n}\n.email-card:hover {\n  background-color: lightgray;\n}\n\n.emailcard-username,\n.emailcard-subject {\n  font-size: 14px !important;\n  font-weight: 600 !important;\n  color: #242424 !important;\n}\n\n.emailcard-date {\n  font-size: 12px !important;\n  font-weight: 600 !important;\n  color: #242424 !important;\n}\n\n.emailcard-body {\n  font-size: 14px !important;\n  font-weight: 400 !important;\n  color: #646464 !important;\n}\n\n.emails-wrapper:hover {\n  cursor: pointer;\n}\n\n.emails-wrapper__date {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.read {\n  background-color: white;\n  border-right: none;\n}\n\n.read-text {\n  font-weight: 300; /* Replace with your typography rule if needed */\n  padding: 0.6rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
