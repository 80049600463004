import React, { memo } from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Divider,
  Grid,
  FormControl,
  Box,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { HiOutlineSearch } from "react-icons/hi";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEdit from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import randomColor from "randomcolor";
import { BASE_URL } from "app/services/auth-services";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  TabContext,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  ButtonStyle,
  CRMTableHead,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  avatarColors,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import { IoCalendarClear } from "react-icons/io5";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import AccountNotesEdit from "./AccountNotesEdit";
import {
  AvatarCircle,
  Cancelbutton,
  CustomButton,
  DialogBox,
  Dragabledialog,
  htmlToText,
  SelectDropdown,
} from "app/pages/widgets/StylesComponents";
import NotesModel from "app/pages/contacts/extra-components/NotesModel";

const AccountNotes = ({ id, data }) => {
  const [height, setHeight] = useState(true);
  const [openEditNotes, setOpenEditNotes] = useState(false);
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [datas, setDatas] = useState([]);
  const [update, setUpdate] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [save, setSave] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const [expandedItems, setExpandedItems] = useState(
    new Array(datas.length).fill(true)
  );
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };
  const handleExpandToggle = (index) => {
    const updatedExpandedItems = [...expandedItems];
    updatedExpandedItems[index] = !updatedExpandedItems[index];
    setExpandedItems(updatedExpandedItems);
  };

  //  For Contact Notes Creation
  const [Notes, setNotes] = useState("");
  const [number, setNumber] = useState(1);

  const token = localStorage.getItem("accesstoken");
  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const item = "crmuseradmin1";

  // For Choose Period

  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [customDate, setCustomDate] = useState(false);
  const [currentDate, setCurrentDate] = useState("");

  const periodOptions = [
    "Previous Week",
    "Previous Month",
    "Previous Quarter",
    "Previous Year",
    "This Week",
    "This Month",
    "This Quarter",
    "This Year",
    "Last One Year",
    "Last Six Month",
    "Last Three Month",
    "Custom Date",
  ];

  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let TodayDate = `${year}-${month < 10 ? "0" + month : month}-${day}`;

  const handlePeriodChange = (event, newValue) => {
    setSelectedPeriod(newValue);
    setSelectedDate(null);
    setfromDate("");
    settoDate("");

    let date;

    let startDate; // for previous
    let endDate;

    switch (newValue) {
      case "This Week":
        startDate = moment().startOf("week");
        endDate = moment().endOf("week");

        let ThisWeekStart = startDate?.format("YYYY-MM-DD");
        let ThisWeekEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(ThisWeekStart);
        setCurrentDate(ThisWeekEnd);

        setCustomDate(false);

        return;

      case "This Month":
        startDate = moment().startOf("month");
        endDate = moment().endOf("month");

        let ThisMonthStart = startDate?.format("YYYY-MM-DD");
        let ThisMonthEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(ThisMonthStart);
        setCurrentDate(ThisMonthEnd);

        setCustomDate(false);

        return;

      case "This Quarter":
        startDate = moment().startOf("quarter");
        endDate = moment().endOf("quarter");

        let ThisQuarterStart = startDate?.format("YYYY-MM-DD");
        let ThisQuarterEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(ThisQuarterStart);
        setCurrentDate(ThisQuarterEnd);

        setCustomDate(false);

        return;

      case "This Year":
        startDate = moment().startOf("year");
        endDate = moment().endOf("year");

        let ThisYearStart = startDate?.format("YYYY-MM-DD");
        let ThisYearEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(ThisYearStart);
        setCurrentDate(ThisYearEnd);

        setCustomDate(false);

        return;

      case "Previous Week":
        startDate = moment().subtract(1, "week").startOf("week");
        endDate = moment().subtract(1, "week").endOf("week");

        let PrevWeekStart = startDate?.format("YYYY-MM-DD");
        let PrevWeekEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(PrevWeekStart);
        setCurrentDate(PrevWeekEnd);

        setCustomDate(false);

        return;

      case "Previous Month":
        startDate = moment().subtract(1, "month").startOf("month");
        endDate = moment().subtract(1, "month").endOf("month");

        let PrevMonthStart = startDate?.format("YYYY-MM-DD");
        let PrevMonthEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(PrevMonthStart);
        setCurrentDate(PrevMonthEnd);

        setCustomDate(false);

        return;

      case "Previous Quarter":
        startDate = moment().subtract(1, "quarter").startOf("quarter");
        endDate = moment().subtract(1, "quarter").endOf("quarter");

        let PrevQuarterStart = startDate?.format("YYYY-MM-DD");
        let PrevQuarterEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(PrevQuarterStart);
        setCurrentDate(PrevQuarterEnd);

        setCustomDate(false);

        return;

      case "Previous Year":
        startDate = moment().subtract(1, "year").startOf("year");
        endDate = moment().subtract(1, "year").endOf("year");

        let MomentDateStart = startDate?.format("YYYY-MM-DD");
        let MomentDateEnd = endDate?.format("YYYY-MM-DD");

        setSelectedDate(MomentDateStart);
        setCurrentDate(MomentDateEnd);

        setCustomDate(false);

        return;

      case "Last One Year":
        date = moment().subtract(1, "year");
        setCustomDate(false);

        break;

      case "Last Six Month":
        date = moment().subtract(6, "months");
        setCustomDate(false);

        break;

      case "Last Three Month":
        date = moment().subtract(3, "months");
        setCustomDate(false);

        break;

      case "Custom Date":
        setCustomDate(true);
        break;
      default:
        date = null;
    }
    let MomentDate = date?.format("YYYY-MM-DD");
    setSelectedDate(MomentDate);
    setCurrentDate(TodayDate);
  };

  const NotesFilter = async () => {
    if (
      selectedDate !== "" &&
      selectedDate !== undefined &&
      currentDate !== ""
    ) {
      console.log("selectedDate", selectedDate, currentDate);
      await axios
        .get(
          `${BASE_URL}/user/ContactNotesFilter/?fromdate=${selectedDate}&todate=${currentDate}&Contact_Id=${Number(
            id
          )}`,
          header
        )
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((error) => {
          console.log("error", error);
        });

      //
    }
    if (fromDate !== "" && toDate !== "") {
      await axios
        .get(
          `${BASE_URL}/user/ContactNotesFilter/?fromdate=${fromDate}&todate=${toDate}&Contact_Id=${Number(
            id
          )}`,
          header
        )
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const onsubmit = () => {
    console.log("in submit", Notes);
    var payload = {
      Account_Id: Number(id),
      Notes: Notes,
      Active_Flag: false,
      Is_Deleted: false,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    // console.log("payload",payload)

    axios
      .post(`${BASE_URL}/user/AccountNotesCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast.success("Created successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setNotes("");
        getData();
        setOpenAddNotes(false);
        reset();
        console.log("CreatedNotes", res.data);
        let editUser = localStorage.getItem("Username");
        let histotypayload = {
          Document_Id: res.data.AccountNotes_Id,
          Updated_Person: `${editUser} Created this Account Notes.`,
          Module_Name: "Account Notes",
          Account_Id: Number(id),
          Updated_By: parseInt(localStorage.getItem("UserId")),
        };
        console.log("histotypayload", histotypayload);
        axios
          .post(`${BASE_URL}/user/AccountHistorycrud/`, histotypayload, header)
          .then((res) => {
            console.log("Result", res.data);
          })
          .catch((res) => {
            console.log(res.message);
          });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const validationSchema = yup.object({
    Notes: yup.string().required("Notes is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Get the Contact List Notes

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(
        `${BASE_URL}/user/AccountNotesAdditional/?Is_Deleted=False&Account_Id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDatas(res.data.results || []);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  // Search Functionality
  const SearchData = (e) => {
    console.log(e.target.value);
    e.target.value !== "" ? handleSearch(e.target.value) : getData();
  };
  const handleSearch = (search) => {
    axios
      .get(
        `${BASE_URL}/user/AccountNotesSearch/?search=${search}&Account_Id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDatas(res.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  // Delete Function for Name

  const Deletedata = (data) => {
    console.log("Delete", data);

    axios
      .delete(`${BASE_URL}/user/AccountNotesCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        getData();
        setOpenAddNotes(false);
        reset();
        // let editUser = localStorage.getItem("Username");
        // let histotypayload = {
        //   Document_Id: data?.AccountNotes_Id,
        //   Updated_Person: `${editUser} Deleted this Account Notes.`,
        //   Module_Name: "Account Notes",
        //   Account_Id: Number(id),
        //   Updated_By: parseInt(localStorage.getItem("UserId")),
        // }
        // console.log("histotypayload", histotypayload)
        // axios
        //   .post(
        //     `${BASE_URL}/user/AccountHistorycrud/`, histotypayload
        //     ,
        //    { headers: {
        //       "db": "crmuseradmin1",
        //       Authorization: `Bearer ${token}`,
        //       "Content-Type": "application/json",
        //       Accept: "application/json",
        //     },}
        //   )
        //   .then((res) => {
        //     console.log("Result", res.data);
        //   })
        //   .catch((res) => {
        //     console.log(res.message);
        //   });
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // For update
  const handleUpdate = (data) => {
    setUpdate(data);
    console.log("transfer", data);
  };

  return (
    <>
      <>
        {/* Create Accounts */}

        {/* Create Notes */}
        <Dragabledialog
          maxWidth="lg"
          open={openAddNotes}
          // onClose={() => setOpenNotes(false)}
        >
          {/* {alert("create")} */}
          <NotesModel
            data={data}
            endpoint={"AccountNotesCRUD"}
            historyEndpoint={{
              point: "AccountHistorycrud",
              payload: { Account_Id: data?.id },
            }}
            extraPayload={{ Account_Id: data?.id }}
            name={{
              label: "Account Name",
              labelSeries: "Deal Name",
              value: data?.Contact_Name,
            }}
            setOpenNotes={setOpenAddNotes}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Edit Notes */}
        <Dragabledialog
          maxWidth="lg"
          open={openEditNotes}
          // onClose={() => setOpenNotes(false)}
        >
          <NotesModel
            label="Edit"
            endpoint={"AccountNotesCRUD"}
            historyEndpoint={{
              point: "AccountHistorycrud",
              payload: { Account_Id: update?.id },
            }}
            extraPayload={{
              AccountNotes_Id: update?.AccountNotes_Id,
              Account_Id: update?.id,
            }}
            data={{ ...update, Name: update?.Contact_Name }}
            name={{
              label: "Account Name",
              labelSeries: "Deal Name",
              value: data?.Contact_Name,
            }}
            setOpenNotes={setOpenEditNotes}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* close dialog box */}
        <DialogBox
          onClickNo={() => {
            setSave(false);
          }}
          onClickYes={() => {
            setSave(false);
            setOpenAddNotes(false);
            setOpenEditNotes(false);
            // handleReset();
          }}
          open={save}
        />

        <Div>
          <Typography
            id="Typography-root"
            sx={[
              dialogTitle,
              {
                backgroundColor: "transparent !important",
                display: { xs: "none", md: "block" },
              },
            ]}
          >
            Notes
          </Typography>
          <Div
            sx={[
              DisplayFlex,
              {
                mt: 2,
                flexWrap: "wrap",
                gap: "0px !important",
                justifyContent: "space-between",
                alignItems: "center",
              },
            ]}
          >
            <Div sx={[DisplayFlex, { flexWrap: "wrap" }]}>
              <FormControl
                sx={{
                  width: "250px",
                }}
              >
                <SelectDropdown
                  value={selectedPeriod}
                  options={periodOptions || []}
                  onChange={handlePeriodChange}
                  placeholder="Choose a Period"
                />
              </FormControl>
            </Div>

            <Div sx={[DisplayFlex, { flexWrap: "wrap" }]}>
              <SearchBar
                width={"200px !important"}
                border={"1px solid lightgray"}
                SearchData={SearchData}
              />
              <CustomButton
                Icon="createIcon"
                label="Create"
                onClick={() => setOpenAddNotes(true)}
              />
            </Div>
          </Div>

          {Boolean(customDate) && (
            <Box sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap", mt: 2 }]}>
              <Div sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap" }]}>
                <FormControl>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    From
                  </Typography>
                  <TextField
                    sx={[FontStyle]}
                    type={"date"}
                    className="input-box"
                    value={fromDate}
                    onChange={(e) => setfromDate(e.target.value)}
                  />
                </FormControl>
              </Div>
              <Div sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap" }]}>
                <FormControl>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    To
                  </Typography>
                  <TextField
                    sx={[FontStyle]}
                    type={"date"}
                    className="input-box"
                    value={toDate}
                    onChange={(e) => settoDate(e.target.value)}
                  />
                </FormControl>
              </Div>
            </Box>
          )}
        </Div>

        <JumboScrollbar
          autoHeight={true}
          autoHeightMax={window.innerHeight}
          autoHideTimeOut={4000}
        >
          <Timeline sx={{ mt: 2, p: (theme) => theme.spacing(0) }}>
            {datas?.length > 0 &&
              datas?.map((data, index) => {
                let avatar =
                  index >= 10 ? avatarColors[index % 10] : avatarColors[index];

                return (
                  <TimelineItem
                    key={index}
                    sx={{
                      p: 0,
                      "&::before": {
                        display: "none",
                        // mt: 1,
                      },
                    }}
                  >
                    <TimelineSeparator>
                      <AvatarCircle
                        fullTitle={true}
                        size="32px"
                        fontSize="10px"
                        bgColor={avatar}
                        title={moment(data.received_date).format("MMM D")}
                      />

                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent
                      sx={{
                        p: (theme) => theme.spacing(0.5, 0),
                        ml: 2.5,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor:
                            height === index && data?.Notes?.length > 90
                              ? "#EAFCEF "
                              : "#fbfcff",
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: "3px",
                          padding: "3px 5px !important",
                        }}
                      >
                        <JumboScrollbar
                          autoHeight={true}
                          autoHeightMax={"200px"}
                          autoHideTimeOut={4000}
                        >
                          <Card
                            sx={{
                              backgroundColor:
                                height === index && data?.Notes?.length > 90
                                  ? "#EAFCEF "
                                  : "#fbfcff",
                              height: {
                                xs:
                                  height === index && data?.Notes?.length > 30
                                    ? "fit-content"
                                    : "27px",
                                md:
                                  height === index && data?.Notes?.length > 90
                                    ? "fit-content"
                                    : "27px",
                              },
                            }}
                          >
                            <Typography
                              id="Typography-root"
                              sx={[FontStyle, { lineHeight: "20px", mt: 0.5 }]}
                            >
                              {htmlToText(data?.Notes)}
                            </Typography>
                          </Card>
                        </JumboScrollbar>

                        <Div
                          sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            width: "50px",
                            mt: "0px !important",
                          }}
                        >
                          <Div sx={[DisplayFlex, { gap: "0px !important" }]}>
                            <IconButton
                              onClick={() => {
                                index !== height
                                  ? setHeight(index)
                                  : setHeight(null);
                              }}
                              sx={{ p: 0 }}
                            >
                              {height !== index && data?.Notes?.length > 90 ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropUpIcon />
                              )}
                            </IconButton>
                            <IconButton
                              onClick={(event) => {
                                setNumber(data.id);
                                handleUpdate(data);
                                handleClickAction(event);
                              }}
                              sx={{ p: 0 }}
                            >
                              <MoreVertIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          </Div>
                        </Div>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorElAction}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(anchorElAction)}
                          onClose={() => setAnchorElAction(false)}
                          sx={{
                            mt: 3,
                            mr: 5,
                            maxHeight: "300px",
                            width: "300px",
                          }}
                        >
                          <MenuItem
                            sx={[FontStyle]}
                            onClick={() => {
                              setOpenEditNotes(true);
                              setAnchorElAction(false);
                            }}
                          >
                            <ModeEdit
                              sx={{ fontSize: 16, color: "gray", mr: 0.5 }}
                            />
                            Edit
                          </MenuItem>
                          <MenuItem
                            sx={[FontStyle]}
                            onClick={() => {
                              setDeleteItem(data);
                              setAnchorElAction(false);
                            }}
                          >
                            <DeleteIcon
                              sx={{ fontSize: 16, color: "gray", mr: 0.5 }}
                            />
                            Delete
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
        </JumboScrollbar>

        {/* delete items */}
        <DialogBox
          onClickNo={() => {
            setDeleteItem(false);
          }}
          onClickYes={() => {
            setSave(false);
            Deletedata(update);
            reset();
            setDeleteItem(false);
          }}
          open={deleteItem}
        />
      </>
    </>
  );
};

export default memo(AccountNotes);
