import { Avatar, Box, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import StackedBarChart from "./leads-right-side-card/StackedBarChart";
import DealsCard from "./leads-right-side-card/DealsCard";
import TemperatureTable from "./leads-right-side-card/TemperatureTable";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { useEffect, useContext, useCallback, useState } from "react";
import moment, { weekdays } from "moment";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import {
  avatarColors,
  cardHeight,
  componentTitle,
  dialogTitle,
  DisplayFlex,
  FontStyle,
} from "../widgets/CRMStyles";
import Div from "@jumbo/shared/Div";
// import LeadStageMovement from "./dashboardCharts/LeadStageMovement";
// import LeadSourceWise from "./dashboardCharts/LeadSourceWise";
// import LeadSourcePie from "./dashboardCharts/LeadSourcePie";

import { IoIosMailOpen } from "react-icons/io";
import { MdCallEnd, MdDoNotTouch, MdOutlineScoreboard } from "react-icons/md";
import { GiDiscussion } from "react-icons/gi";
// import LeadBySource from "./dashboardCharts/LeadBySource";
import { useQuery, useQueryClient } from "react-query";
import Counter, { NoRecordsPage } from "../widgets/StylesComponents";
import { PiRadioactiveFill } from "react-icons/pi";
import { AutoHeight } from "app/redux/auth/localStorage";
import LeadStageMovement from "./dashboardCharts/LeadStageMovement";
import LeadSourceWise from "./dashboardCharts/LeadSourceWise";

const LeadsDashBoard = ({ scrollHeight }) => {
  //  Get the Active Leads
  const [datas, setDatas] = useState({});

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const [mailcount, setMailCount] = useState(0);
  const [callcount, setCallCount] = useState(0);
  const [officemeeting, setOfficemeeting] = useState(0);
  const [upcoming, setUpcoming] = useState([]);
  // Month Range Deal Amount And Deal Stage
  const [dealamount, setDealAmount] = useState(0);
  const [monthDatas, setMonthDatas] = useState([]);
  const [dealcount, setDealcount] = useState(0);
  const [salesamount, setSalesAmount] = useState(0);
  const [leadcount, setLeadCount] = useState(0);

  const UpcomingActivities = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/LeadDashboradActivities/`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("LeadDashboradActivities :", response?.data);
      return response.data; // Return response data instead of setting it in state
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };

  const DealAmount = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/LeadDashboard1/`,
        {},
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Handle the successful response here
      setDealAmount(response?.data);
      let data = [...monthDatas];
      data.push({
        name: "Deal Amount",
        value: response?.data?.Deal_Amount_for_Month,
      });
      DealCount(data);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred:", error);
    }
  };

  const DealCount = async (items) => {
    try {
      const response = await axios.get(`${BASE_URL}/user/LeadDashboard1/`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // Handle the successful response here
      setDealcount(response?.data);
      let data = [...items];
      data.push({
        name: "No. of Deals",
        value: response?.data?.Total_Deal_Count,
      });
      DealSalesAmount(data);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred:", error);
    }
  };

  const DealSalesAmount = async (items) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/user/LeadDashboard1/`,
        {},
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Handle the successful response here
      console.log("data", response?.data);
      setSalesAmount(response?.data);
      let data = [...items];
      data.push({
        name: "Sales Amount",
        value: response?.data?.Sales_Amount_for_Month,
      });
      setMonthDatas(data);
      console.log("deal amount", data);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred:", error);
    }
  };

  // For Leads To User Sales
  const LeadsCount = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/user/LeadDashboard1/`,
        {},
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Handle the successful response here
      console.log("data", response?.data);
      // setLeadCount(response?.data);
      return response?.data;
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred:", error);
    }
  };

  // useEffect(() => {
  //   DealAmount();
  //   DealCount();
  //   DealSalesAmount();
  // }, [])

  const {
    data: upcomingActivitiesLists,
    isLoading: upcomingActivitiesListsLoading,
    isError: upcomingActivitiesListsError,
    refetch: upcomingActivitiesListsRefetch,
  } = useQuery(["upcomingActivitiesLists"], UpcomingActivities, {
    staleTime: Infinity,
  });

  const {
    data: leadsCountLists,
    isLoading: leadsCountListsLoading,
    isError: leadsCountListsError,
    refetch: leadsCountListsRefetch,
  } = useQuery(["leadsCountLists"], LeadsCount, { staleTime: Infinity });

  useEffect(() => {
    if (upcomingActivitiesLists) {
      setUpcoming(upcomingActivitiesLists || []);
    }
  }, [upcomingActivitiesLists]);

  useEffect(() => {
    if (leadsCountLists) {
      setLeadCount(leadsCountLists || []);
    }
  }, [leadsCountLists]);

  return (
    <JumboContentLayoutMain>
      <Typography sx={[componentTitle]}>Lead Dashboard </Typography>

      <Box>
        <Div sx={{ my: 1.5 }}>
          <LeadStageMovement />
        </Div>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} mt={2}>
          <Div
            sx={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              borderRadius: "10px",
              p: 2,
            }}
          >
            <Typography sx={[dialogTitle]}>
              Lead Source Wise (Current Year)
            </Typography>
            <Div sx={{ my: 1 }}>
              <LeadSourceWise />
            </Div>
          </Div>

          <Div
            sx={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
              borderRadius: "10px",
              p: 2,
              mt: 2,
            }}
          >
            <Typography sx={[dialogTitle]}>Month</Typography>
            <Div sx={{ my: 1 }}>
              <TemperatureTable />
            </Div>
          </Div>
        </Grid>
        {/* 
          <Grid item xs={12} sm={12} md={7}>
            <Card
              sx={{ border: "1px solid lightgray", borderRadius: "3px", p: 1 }}
            >
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                Lead By Source (Current Year)
              </Typography>
              <LeadBySource />
            </Card>
          </Grid> */}

        <Grid item xs={12} sm={12} md={4} mt={2}>
          <Div
            sx={{
              p: 2,
              borderRadius: "12px",
              height: "100%",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", // Adding subtle shadow for depth
              backgroundColor: "#ffffff", // Clean white background
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: "#333333", // Darker color for better readability
              }}
            >
              Upcoming Meetings
            </Typography>
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={AutoHeight(2)}
              autoHide={true}
              hideTracksWhenNotNeeded
            >
              {upcoming?.length > 0 ? (
                upcoming?.map((data, idx) => (
                  <Div
                    sx={[DisplayFlex, { p: 1, alignItems: "center" }]}
                    key={idx}
                  >
                    <Avatar
                      alt="date"
                      sx={{
                        width: 30,
                        height: 30,
                        textAlign: "center",
                        fontSize: "12px",
                        p: 0.5,
                        bgcolor: "#4caf50", // Green color for better contrast
                        border: "2px solid #ffffff",
                        mr: 1,
                      }}
                    >
                      {moment(data?.Date).format("MMM D")}
                    </Avatar>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#555555", // Slightly lighter text color
                      }}
                    >
                      {data?.Location}
                    </Typography>
                  </Div>
                ))
              ) : (
                <Div
                  sx={[
                    DisplayFlex,
                    {
                      p: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    },
                  ]}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#999999",
                      textAlign: "center",
                    }}
                  >
                    No upcoming meetings scheduled.
                  </Typography>
                </Div>
              )}
            </JumboScrollbar>
          </Div>
        </Grid>

        <Grid item xs={12} sm={12} md={7}></Grid>
      </Grid>
    </JumboContentLayoutMain>
  );
};

export default LeadsDashBoard;
