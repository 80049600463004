// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputOption {
    display: flex;
    align-items: center;
    margin-top: 15px;
    color: gray;
    padding: 10px;
    cursor: pointer;
}

.inputOption:hover {
    background-color: whitesmoke;
    border-radius: 10px;

}

.inputOption>h4 {
    margin-left: 5px;
    font-size: 15px;
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/InstagramAPI/inputoption.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,mBAAmB;;AAEvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".inputOption {\n    display: flex;\n    align-items: center;\n    margin-top: 15px;\n    color: gray;\n    padding: 10px;\n    cursor: pointer;\n}\n\n.inputOption:hover {\n    background-color: whitesmoke;\n    border-radius: 10px;\n\n}\n\n.inputOption>h4 {\n    margin-left: 5px;\n    font-size: 15px;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
