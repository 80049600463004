import {
  Checkbox,
  Table,
  TableBody,
  TableHead,
  Typography,
  Pagination,
  Avatar,
  FormControlLabel,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Colors, avatarColors, generateRandomColor } from "app/pages/widgets/CRMStyles";
import Div from "@jumbo/shared/Div/Div";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";

import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  CloseIcon,
  MailIcon,
  PhoneIcon,
  SettingsIcon,
} from "app/icons/PngIcons";
import "react-toastify/dist/ReactToastify.css";
import {
  CRMTableHead,
  componentTitle,
  DisplayFlex,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { AvatarCircle } from "app/pages/widgets/StylesComponents";
import { AutoHeight } from "app/redux/auth/localStorage";

const CampaignContactList = ({
  scrollHeight,
  transfer,
  selectedEmails,
  setSelectedEmails,
}) => {
  // Getting the Contact List

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const [datas, setDatas] = useState([]);

  //  For Pagination Table
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [colors, setColors] = useState([]);
  const [allSelectedMails, setAllSelect] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = datas.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateData = async (getId) => {
    let datass = [];

    try {
      await transfer?.map(async (select) => {
        const response1 = await axios.get(
          `${BASE_URL}/user/UserContactListCRUD/?id=${select}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        if (response1?.data) {
          let allSelected = selectedEmails?.every(
            (sele) => sele?.checked === true
          );
          setAllSelect(allSelected);
          response1.data?.results[0].Contact?.map((item) => {
            datass.push(item);
          });
          setDatas(datass);
          // console.log("All selected", getId, datass);
        }
        console.log("All selected", datass);
      });

      // setDatas(datass);
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  // console.log("transfer", transfer[0]);

  // all selected
  const allSelectFunc = () => {
    let select = [...selectedEmails];
    select?.map((sel, index) => (select[index].checked = !allSelectedMails));
    setSelectedEmails(select);
    setAllSelect(!allSelectedMails);
  };

  // single selected
  const selectFunc = (item) => {
    let select = [...selectedEmails];
    let index = select?.findIndex((select) => select?.email === item?.Email_Id);
    if (index !== -1) {
      select[index].checked = !select[index]?.checked;
    } else {
      select.push({ email: item?.Email_Id, checked: true });
    }

    let allSelected = select?.every((select) => select?.checked === true);
    console.log("checking checked2  ", selectedEmails, item);

    if (allSelected) {
      setSelectedEmails(select);
      setAllSelect(true);
    } else {
      setAllSelect(false);
      setSelectedEmails(select);
    }
  };

  useEffect(() => {
    let colors = [];
    selectedEmails?.map((element) => {
      colors.push(generateRandomColor());
    });
    setColors(colors);
  }, []);

  useEffect(() => {
    updateData(transfer);
  }, [transfer]);

  return (
    <>
      <Div>
        <JumboScrollbar
          autoHeight={true}
          autoHideTimeout={4000}
          autoHeightMin={350}
          autoHide={true}
          hideTracksWhenNotNeeded
          id="no-more-tables"
        >
          <Table className="table" sx={{ mt: 1.5 }}>
            <TableHead sx={[CRMTableHead, FontStyle]}>
              <CRMTableRow>
                <CRMTableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="info"
                        checked={allSelectedMails}
                        onChange={allSelectFunc}
                      />
                    }
                    label={<Typography sx={[FontStyle,{color:`${Colors?.Mblue} !important`,}]}>Contact Name</Typography>}
                  />
                </CRMTableCell>

                <CRMTableCell ><Typography sx={[FontStyle,{color:`${Colors?.Mblue} !important`,}]}>Email</Typography></CRMTableCell>

                {/* <CRMTableCell><IconButton sx={[FontStyle, { padding: 0.5 }]}><SlRefresh size={16} color="red" /></IconButton></CRMTableCell> */}
              </CRMTableRow>
            </TableHead>
            <TableBody className="table-body" sx={{ mt: 2 }}>
              {datas
                ?.slice(
                  page == 1 ? page * 0 : (page - 1) * count,
                  page == 1 ? page * 0 + count : (page - 1) * count + count
                )
                ?.map((data, index) => {
                  let checked = selectedEmails?.find(
                    (emails) => emails?.email == data?.Email_Id
                  );
                  // console.log("checked", data);
                  let avatar =
                              index >= 10
                                ? avatarColors[index % 10]
                                : avatarColors[index];

                  return (
                    <>
                      <CRMTableRow>
                        <CRMTableCell
                          sx={{
                            transform: { xs: "translateX(0%) !important" },
                          }}
                          data-title="Name"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="info"
                                checked={checked?.checked}
                                onChange={() => {
                                  selectFunc(data);
                                }}
                              />
                            }
                            label={
                                <AvatarCircle bgColor={avatar} title={data?.Name} label={data?.Name}  />
                            
                            }
                          />
                        </CRMTableCell>

                        <CRMTableCell
                          sx={{
                            transform: { xs: "translateX(0%) !important" },
                          }}
                          data-title="Email"
                        >
                          <Div
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <MailIcon size={12} color={Colors?.Mblue} />
                            <Div>{data?.Email_Id}</Div>
                          </Div>
                        </CRMTableCell>
                      </CRMTableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </JumboScrollbar>
        {/* code for pagination */}

        <Div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Pagination
            color="success"
            count={Math?.ceil(datas?.length / count)}
            page={page}
            onChange={(e, newValue) => handleChangePage(e, newValue)}
            size="small"
            showFirstButton
            showLastButton
          />
        </Div>
      </Div>
    </>
  );
};

export default CampaignContactList;
