import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect, memo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "app/services/auth-services";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  dialogFontSize,
  dialogTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import {
  Cancelbutton,
  DialogBox,
  CustomButton,
  Customgrid,
} from "app/pages/widgets/StylesComponents";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

const contacts = [
  {
    industry_type: "Product Bsaed",
  },
  {
    industry_type: "Service Bsaed",
  },
];

const ContactEdit = ({
  openEditContact,
  setOpenEditContact,
  update,
  setSave,
  getMyData, getData, tabValue, setPage, setDatas
}) => {
  // Edit Functionality

  const queryClient = useQueryClient();

  console.log("updateedit", update);

  const [name1, setName1] = useState(update.Name);
  const [code1, setCode1] = useState(update.MobileCountry_Id);
  const [mail1, setMail1] = useState(update.Email_Id);
  const [orgname1, setOrgname1] = useState(update.Company_Name);
  const [selectedCountry1, setSelectedCountry1] = useState(update.Country);
  const [selectedState1, setSelectedState1] = useState(update.State);
  const [industry1, setIndustry1] = useState(update.Industry_Type);
  const [mobilenumber1, setMobilenumber1] = useState(update.Mobile_Number);
  const [selectedCity1, setSelectedCity1] = useState(update.City);

  const [userNameLists, setUserNameLists] = useState([]);

  const [addField, setAddField] = useState({
    id: "",
    dealId: "",
    dealName: "",
    dealType: "",
    accId: "",
    accName: "",
    contactName: "",
    leadName: "",
    leadSource: "",
    dealAmount: "",
    turnAround: "",
    dealStage: "",
    dealOwner: "",
    dealProbability: "",
    dealNote: "",
    leadId: "",
  });
  const [addField1, setAddField1] = useState({
    id: "",
    dealId: "",
    dealName: "",
    dealType: "",
    accId: "",
    accName: "",
    contactName: "",
    leadName: "",
    leadSource: "",
    dealAmount: "",
    turnAround: "",
    dealStage: "",
    dealOwner: "",
    dealProbability: "",
    dealNote: "",
    leadId: "",
  });

  const [leads, setLeads] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [lookupDatas, setLookupDatas] = useState([]);
  const [contactPersons, setContactPersons] = useState([]);

  const [errors, setErrors] = useState({});

  console.log("updatedatas ", update);

  const [number, setNumber] = useState(1);
  const [check, setCheck] = useState(true);
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(true);
  const [check3, setCheck3] = useState(true);
  const [check4, setCheck4] = useState(true);

  const validationSchema1 = yup.object({
    name1: yup.string().required("Name is required"),
    mail1: yup.string().required("Mail is required"),
    mobilenumber1: yup.string().required("Mobile Number is Required"),
    orgname1: yup.string().required("Organization Name is required"),
    // selectedCountry1: yup.string().required("Country is required"),
    // selectedState1: yup.string().required("State is required"),
    // industry1: yup.string().required("Industry is required"),
  });

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const {
    reset: reset1,
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(validationSchema1),
  });

  const headers = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/useradmin/UsersList/`, {
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Users List", response.data);
      // setUserNameLists(response.data);
      return response?.data;
    } catch (error) {
      console.log("Error in getting user list", error);
    }
  };

  // get contact persons
  const getContactPersons = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/ContactAdditional/?Is_Deleted=False`,
        headers
      );
      console.log("get Contact Persons List : ", res.data?.results);
      if (res.data?.results) {
        return res.data?.results;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // get lookup datas
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  // get leads
  const getLeads = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/UserQualifiedLeadsAdditional/?Is_Deleted=False`,
        headers
      );
      console.log("get Leads List : ", res.data?.results);
      if (res.data.results) {
        // setLeads(res.data?.results);
        return res?.data?.results;
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  get accouts list
  const getAccounts = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/AccountAdditional/?Is_Deleted=False`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("get Accounts List : ", res.data?.results);
      if (res.data.results) {
        // setAccounts(res.data?.results);
        return res?.data?.results;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onsubmit = async () => {
    let errors = {};
    Object.keys(addField)?.forEach((field) => {
      if (addField[field] === "") {
        errors[field] = `* Please enter correct `;
      }
    });
    //  console.log("errors Fie",errors,addField)

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      let payload = {
        id: addField.id,
        Deal_Id: addField.dealId,
        Deal_Name: addField?.dealName,
        Deal_Owner: addField?.dealOwner,
        Deal_Type: addField?.dealType,
        Account_Id: addField?.accId,
        Account_Name: addField.accName,
        Lead_Id: addField?.leadId,
        Lead_Name: addField?.leadName,
        // "Lead_Source": addField?.leadSource,
        Contact_Person: addField?.contactName,
        Amount: parseInt(addField?.dealAmount),
        MinimumTurnOver: parseInt(addField?.turnAround),
        Deal_Stage: addField?.dealStage,
        Deal_Probablity: addField?.dealProbability,
        Deal_Note: addField?.dealNote,
        Active_Flag: false,
        Is_Deleted: false,
        // Created_Date: new Date().toISOString(),
        Updated_Date: new Date().toJSON().slice(0, 10),
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
      // console.log("updatepayload", payload);
      try {
        await axios
          .put(`${BASE_URL}/user/DealCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log(res.data);
            ;
            queryClient.invalidateQueries("getMyActivityLists");
            queryClient.invalidateQueries("getLineChartsLists");
            queryClient.invalidateQueries("getDealCountsLists");
            queryClient.invalidateQueries("getPyramidLists");
            // pipeline
            queryClient.invalidateQueries("pipeLineChartLists");
            toast.success("Updated successfully", {
              position: "top-right",
              autoClose: 100,
            });
            setErrors({});
            setOpenEditContact(false);
            setCheck(true);
            setCheck1(true);
            setCheck2(true);
            setCheck3(true);
            setCheck4(true);
            setDatas([])
            setPage(1); // Reset the page to 1
            // Depending on the tab value, call the appropriate data fetching function
            if (tabValue === 0) {
              getData(1);
            } else {
              getMyData(1);
            }

            // For history Saving

            // Edited Fields History

            let EditedArr = [];

            if (addField1.dealName !== addField.dealName) {
              EditedArr.push("Deal Name");
            }
            if (addField1.dealType !== addField.dealType) {
              EditedArr.push("Deal Type");
            }
            if (addField1.dealOwner !== addField.dealOwner) {
              EditedArr.push("Deal Owner");
            }
            if (addField1.accName !== addField.accName) {
              EditedArr.push("Account Name");
            }
            if (addField1.contactName !== addField.contactName) {
              EditedArr.push("contact Name");
            }
            if (addField1.leadName !== addField.leadName) {
              EditedArr.push("lead Name");
            }
            if (addField1.leadSource !== addField.leadSource) {
              EditedArr.push("lead Source");
            }
            if (addField1.dealAmount !== addField.dealAmount) {
              EditedArr.push("Deal Amount");
            }
            if (addField1.turnAround !== addField.turnAround) {
              EditedArr.push("Turn Around");
            }
            if (addField1.dealStage !== addField.dealStage) {
              EditedArr.push("Deal Stage");
            }
            if (addField1.dealProbability !== addField.dealProbability) {
              EditedArr.push("Deal Probability");
            }
            if (addField1.dealNote !== addField.dealNote) {
              EditedArr.push("Deal Notes");
            }

            // For Updateing the History of Changes
            let editUser = localStorage.getItem("Username");
            if (EditedArr.length > 0) {
              let histotypayload = {
                Document_Id: addField.dealId,
                Updated_Person: `${editUser} edited this Deal.`,
                Module_Name: "Deal",
                Updated_Field: EditedArr.join(","),
                Updated_Time: moment().format("YYYY-MM-DD HH:mm:ss.SSSSSSZ"),
                Deal_Id: addField.id,
                Updated_By: parseInt(localStorage.getItem("UserId")),
              };

              console.log("histotypayload", histotypayload);
              axios
                .post(`${BASE_URL}/user/DealHistorycrud/`, histotypayload, {
                  headers: {
                    db: item,
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                })
                .then((res) => {
                  console.log("Result", res.data);
                })
                .catch((res) => {
                  console.log(res.message);
                });
            }
          })

          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        console.error("error", error);
      }
    }
  };

  //For Child Dailog
  useEffect(() => {
    // setTimeout(() => {
    setName1(update.Name);
    setCode1(update.MobileCountry_Id);
    setMail1(update.Email_Id);
    setOrgname1(update.Company_Name);
    setSelectedCountry1(update.Country);
    setSelectedState1(update.State);
    setIndustry1(update.Industry_Type);
    setMobilenumber1(update.Mobile_Number);
    setSelectedCity1(update.City);
    // }, 2000);
  }, [update]);

  useEffect(() => {
    console.log("update", update);
    setAddField({
      id: update?.id,
      dealId: update?.Deal_Id,
      dealName: update?.Deal_Name,
      dealType: update?.Deal_Type,
      accName: update?.Account_Name,
      accId: update?.Account_Id?.id,
      contactName: update?.Contact_Person,
      leadId: update?.Lead_Id?.id,
      leadName: update?.Lead_Name,
      leadSource: update?.Lead_Id?.LeadSource,
      dealAmount: update?.Amount,
      turnAround: update?.MinimumTurnOver,
      dealStage: update?.Deal_Stage,
      dealOwner: update?.Deal_Owner,
      dealProbability: update?.Deal_Probablity,
      dealNote: update?.Deal_Note,
    });
    setAddField1({
      id: update?.id,
      dealId: update?.Deal_Id,
      dealName: update?.Deal_Name,
      dealType: update?.Deal_Type,
      accName: update?.Account_Name,
      contactName: update?.Contact_Person,
      lead: update?.Lead_Id,
      leadSource: update?.Lead_Id?.LeadSource,
      dealAmount: update?.Amount,
      turnAround: update?.MinimumTurnOver,
      dealStage: update?.Deal_Stage,
      dealOwner: update?.Deal_Owner,
      dealProbability: update?.Deal_Probablity,
      dealNote: update?.Deal_Note,
    });
  }, [update]);

  const {
    data: getAccountsLists,
    isLoading: getAccountsListsLoading,
    isError: getAccountsListsError,
    refetch: getAccountsListsRefetch,
  } = useQuery(["getAccountsLists"], getAccounts, { staleTime: Infinity });

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  const {
    data: getLeadsLists,
    isLoading: getLeadsListsLoading,
    isError: getLeadsListsError,
    refetch: getLeadsListsRefetch,
  } = useQuery(["getQualifiedDataLists"], getLeads, { staleTime: Infinity });

  const {
    data: getContactPersonsLists,
    isLoading: getContactPersonsListsLoading,
    isError: getContactPersonsListsError,
    refetch: getContactPersonsListsRefetch,
  } = useQuery(["getContactPersonsLists"], getContactPersons, {
    staleTime: Infinity,
  });

  const {
    data: getTerUserLists,
    isLoading: getTerUserListsLoading,
    isError: getTerUserListsError,
    refetch: getTerUserListsRefetch,
  } = useQuery(
    ["getTerUserLists"],
    fetchUserData, // Assuming 1 as the initial page number
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (getAccountsLists) {
      setAccounts(getAccountsLists);
    }
  }, [getAccountsLists]);

  useEffect(() => {
    if (getUserLoopUp) {
      let dealType = getUserLoopUp?.find(
        (item) => item?.Lookupname === "Deal Type"
      );
      console.log("getLookupDatas ", getUserLoopUp);
      setLookupDatas(dealType?.Values);
    }
  }, [getUserLoopUp]);

  useEffect(() => {
    if (getLeadsLists) {
      setLeads(getLeadsLists);
    }
  }, [getLeadsLists]);

  useEffect(() => {
    if (getContactPersonsLists) {
      setContactPersons(getContactPersonsLists);
    }
  }, [getContactPersonsLists]);

  useEffect(() => {
    if (getTerUserLists) {
      setUserNameLists(getTerUserLists);
    }
  }, [getTerUserLists]);

  console.log("addField", addField);

  return (
    <>
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        sx={[dialogFontSize, DisplayFlex]}
      >
        Edit Deal
      </DialogTitle>

      <DialogContent sx={{ mt: 1 }}>
        <Customgrid columnCount={4}>
          <Grid item>
            <Typography sx={[input_label]}> Deal Id </Typography>
          </Grid>
          <Grid item>
            <TextField
              className={`col-12 input-box2`}
              id="outlined-basic"
              value={addField?.dealId}
              // onChange={(e) => setAddField({ ...addField, dealName: e.target.value })}
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
              disabled
            />
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}> Deal Name </Typography>
          </Grid>
          <Grid item>
            <TextField
              className={`col-12 input-box2`}
              id="outlined-basic"
              placeholder="Enter Deal Name"
              value={addField.dealName}
              onChange={(e) =>
                setAddField({ ...addField, dealName: e.target.value })
              }
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
            />
            {errors?.dealName && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealName} deal name{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Customer Name </Typography>
          </Grid>

          <Grid item>
            <FormControl className="col-12">
              <Autocomplete
                className="search-select3 col-12"
                // {...register("selectedCountry1")}
                options={accounts}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.Contact_Name) {
                    return option?.Contact_Name;
                  }
                  return "";
                }}
                value={addField?.accName}
                onChange={(e, newValue) => {
                  setAddField({
                    ...addField,
                    accId: newValue?.id,
                    accName: newValue?.Contact_Name,
                  });
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Customer Name" />
                )}
              />
            </FormControl>
            {errors?.accName && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.accName} account name
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography sx={[input_label]}> Lead Name </Typography>
          </Grid>
          <Grid item>
            <FormControl className="col-12">
              <Autocomplete
                className="search-select3 col-12"
                // {...register("selectedCountry1")}
                options={leads}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.LeadFirstName) {
                    return option?.LeadFirstName;
                  }
                  return "";
                }}
                value={addField?.leadName}
                onChange={(e, newValue) => {
                  setAddField({
                    ...addField,
                    leadSource: newValue?.LeadSource,
                    leadId: newValue?.id,
                    leadName: newValue?.LeadFirstName,
                  });
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Lead Name" />
                )}
              />
            </FormControl>
            {errors?.lead && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.lead} deal name{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Lead Source</Typography>
          </Grid>
          <Grid item>
            <FormControl className="col-12">
              <TextField
                className={`col-12 input-box2`}
                id="outlined-basic"
                placeholder="Lead Source"
                value={addField.leadSource}
                // onChange={(e) => setAddField({ ...addField, l: e.target.value })}
                InputProps={{
                  style: {
                    borderRadius: "3px",
                  },
                }}
              />
            </FormControl>
            {errors?.leadSource && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.leadSource} lead source{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Deal Amount</Typography>
          </Grid>
          <Grid item>
            <TextField
              type="number"
              className={`col-12 input-box2`}
              id="outlined-basic"
              placeholder="Deal Amount"
              value={addField.dealAmount}
              onChange={(e) =>
                setAddField({ ...addField, dealAmount: e.target.value })
              }
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
            />
            {errors?.dealAmount && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealAmount} deal amount{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Deal Stage</Typography>
          </Grid>
          <Grid item>
            <FormControl className="col-12">
              <Autocomplete
                className="search-select3 col-12"
                // {...register("selectedCountry1")}
                options={[
                  { stage: "Deals Enquiry Stage" },
                  { stage: "Deals Qualifying Stage" },
                  { stage: "Deals Proposal Stage" },
                  { stage: "Deals Demo Stage" },
                  { stage: "Deals Negotiation Stage" },
                  { stage: "Deals Lost Stage" },
                ]}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.stage) {
                    return option?.stage;
                  }
                  return "";
                }}
                value={addField?.dealStage}
                onChange={(e, newValue) => {
                  setAddField({ ...addField, dealStage: newValue?.stage });
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Deal Stage" />
                )}
              />
            </FormControl>
            {errors?.dealStage && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealStage} deal stage{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Deal Owner</Typography>
          </Grid>
          <Grid item>
            <FormControl className="col-12">
              <Typography sx={[FontStyle]}></Typography>
              <Autocomplete
                className="search-select3 col-12"
                // {...register("selectedCountry1")}
                options={userNameLists}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.username) {
                    return option?.username;
                  }
                  return "";
                }}
                value={addField?.dealOwner}
                onChange={(e, newValue) => {
                  setAddField({
                    ...addField,
                    dealOwner: newValue?.username,
                  });
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Deal Stage" />
                )}
              />
            </FormControl>
            {errors?.dealStage && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealStage} deal stage{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Deal Type</Typography>
          </Grid>
          <Grid item>
            <FormControl className="col-12">
              <Autocomplete
                className="search-select3 col-12"
                // {...register("selectedCountry1")}
                options={lookupDatas}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                value={addField?.dealType}
                onChange={(e, newValue) => {
                  setAddField({ ...addField, dealType: newValue?.value });
                }}
                sx={[FontStyle]}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Deal Type"
                    sx={[FontStyle]}
                  />
                )}
              />
            </FormControl>

            {errors?.dealType && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealType} deal type{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Contact Person</Typography>
          </Grid>
          <Grid item>
            <TextField
              className={`col-12 input-box2`}
              id="outlined-basic"
              placeholder="Contact Name"
              value={addField.contactName}
              onChange={(e) =>
                setAddField({ ...addField, contactName: e.target.value })
              }
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
            />

            {errors?.contactName && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.contactName} Contact Name{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Minimum turn around</Typography>
          </Grid>
          <Grid item>
            <TextField
              type="number"
              className={`col-12 input-box2`}
              id="outlined-basic"
              placeholder="Minimum turn around"
              value={addField.turnAround}
              onChange={(e) =>
                setAddField({ ...addField, turnAround: e.target.value })
              }
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
            />
            {errors?.turnAround && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.turnAround} turn around{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Deal Probability %</Typography>
          </Grid>
          <Grid item>
            <TextField
              type="number"
              className={`col-12 input-box2`}
              id="outlined-basic"
              placeholder="Deal Probability %"
              value={addField.dealProbability}
              onChange={(e) =>
                setAddField({
                  ...addField,
                  dealProbability: e.target.value,
                })
              }
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
            />
            {errors?.dealProbability && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealProbability} deal probability{" "}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Deal Note</Typography>
          </Grid>
          <Grid item>
            <TextField
              className={`col-12 input-box2`}
              id="outlined-basic"
              placeholder="Deal Note"
              value={addField.dealNote}
              onChange={(e) =>
                setAddField({ ...addField, dealNote: e.target.value })
              }
              InputProps={{
                style: {
                  borderRadius: "3px",
                },
              }}
            />
            {errors?.dealNote && (
              <Typography sx={[FontStyle, { color: "red !important" }]}>
                {errors?.dealNote} deal note{" "}
              </Typography>
            )}
          </Grid>
        </Customgrid>

        <Grid container align="center" mt={2} spacing={2} pb={2}>
          <Grid item align="right" xs={6}>
            <CustomButton label="Update" Icon="saveIcon" onClick={onsubmit} />
          </Grid>
          <Grid item align="left" xs={6}>
            <Cancelbutton onClick={() => setSave("edit")} />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default memo(ContactEdit);
