// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-h4y409-MuiList-root {
    height: 65vh;
    min-height: 400px;
    overflow-y: scroll;
}

.css-19idom {
    background-color: #52453317 !important;
    padding: 0px 30px 0px 30px !important;
    border-radius: 10px !important;
    margin-top: 2%;
}

.css-1q24vpl:hover {
    border-left: 0px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.css-1d7dg2m:hover {
    border-left: 0px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/NotificationMenu/Notify.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,qCAAqC;IACrC,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,gEAAgE;AACpE;;AAEA;IACI,2BAA2B;IAC3B,gEAAgE;AACpE","sourcesContent":[".css-h4y409-MuiList-root {\n    height: 65vh;\n    min-height: 400px;\n    overflow-y: scroll;\n}\n\n.css-19idom {\n    background-color: #52453317 !important;\n    padding: 0px 30px 0px 30px !important;\n    border-radius: 10px !important;\n    margin-top: 2%;\n}\n\n.css-1q24vpl:hover {\n    border-left: 0px !important;\n    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;\n}\n\n.css-1d7dg2m:hover {\n    border-left: 0px !important;\n    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
