import React, { useState, useEffect } from "react";

import {
  FormControl,
  Table,
  TableHead,
  Typography,
  Autocomplete,
  TextField,
  Pagination,
  TableBody,
  Button,
  IconButton,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import {
  ButtonStyle,
  CRMTableHead,
  ComponentHeading,
  DialogBoxCancelButton,
  DisplayFlex,
  FontStyle,
  DialogBoxSaveButton,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { BiEdit } from "react-icons/bi";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

function LeadScore({ scrollHeight }) {
  const token = localStorage.getItem("accesstoken");
  const [datas, setDatas] = useState([
    { sl: 1, Description: "ss", MaxScore: "re" },
  ]);
  // const [edit, setEdit] = useState(null);

  // const addMoreFunction = () => {
  //   let list = [...datas];
  //   let bool = list?.every((dat) => {
  //     return Object.values(dat)?.every((val) => {
  //       console.log("boolean", val, Boolean(val));
  //       return Boolean(val);
  //     });
  //   });
  //   if (bool || datas?.length == 0) {
  //     list?.push({ sl: datas?.length + 1, Description: "", MaxScore: "" });
  //     setDatas(list);
  //     setEdit(Number(list?.length - 1));
  //     //   console.log("length", list?.length - 1);
  //   } else {
  //     toast.error("please fill the black list first");
  //   }
  // };

  // //   get data api
  // const getAllData = () => {
  //   // setDatas(res.data.results);
  // };

  // const onChangeHandler = (value, name, idx) => {
  //   let list = [...datas];
  //   list[idx][name] = value;
  //   setDatas(list);
  // };

  // Show the Table For the Lead Scoring Types

  const [demotype, setDemoType] = useState("Demographic");

  const [demo, setDemo] = useState(true);

  const [Firemographic, setFiremographic] = useState(false);

  const [Positive, setPositive] = useState(false);

  const [Negative, setNegative] = useState(false);

  const ShowField = (item) => {
    console.log("item", item);
    if (item == "Demographic") {
      setDemoType(item);
      setDemo(true);
      setFiremographic(false);
      setPositive(false);
      setNegative(false);
    }
    if (item == "Firemographic") {
      setDemoType(item);
      setDemo(false);
      setFiremographic(true);
      setPositive(false);
      setNegative(false);
    }
    if (item == "Behavior-Positive") {
      setDemoType(item);
      setDemo(false);
      setFiremographic(false);
      setPositive(true);
      setNegative(false);
    }
    if (item == "Behavior-Negative") {
      setDemoType(item);
      setDemo(false);
      setFiremographic(false);
      setPositive(false);
      setNegative(true);
    }
  };







  //  for Demo Graphics

  const [rows1, setrows1] = useState([]);

  //  Demographic Add Record

  const handleAddRow1 = () => {
    const newitem = {
      Graphics_Name: "Demographic",
      Demo_Description: "",
      MaxScore: "",
      allow: true,
    };
    setrows1([...rows1, newitem]);
  };

  // Demo Removal Record
  const handleRemoveRow1 = (item, index) => {
    handleDeleteprediction1(item);
    setrows1((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction1 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(`${BASE_URL}/useradmin/LeadDemoGraphicsCRUD/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Demo Create Record
  const LeadSubmitDemoGraphic = () => {
    let arr = [];
    rows1.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (

          item.Demo_Description === "" ||
          item.Demo_Description === undefined ||
          item.Demo_Description === null ||
          item.MaxScore === "" ||
          item.MaxScore === undefined ||
          item.MaxScore === null
        ) {
          toast.error("Invalid Data in Lead DemoGrphics");
        } else {
          // setParameterCheck(item.get("parameter"))
          let playload = {
            Graphics_Name: item?.Graphics_Name,
            Demo_Description: item?.Demo_Description,
            MaxScore: item?.MaxScore,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };

          axios
            .post(`${BASE_URL}/useradmin/LeadDemoGraphicsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("LeadDemo Created Successfully");
                DemogetRecords();
                // Generalrecords()
              }
              // setdatas(res.data.results)
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Demo Delete Record
  const handleAddChange1 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows1];
    updatedRows[index][name] = value;
    setrows1(updatedRows);
  };

  // Get List

  const DemogetRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadDemoGraphicsAdditional/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.data?.results", res?.data?.results);
        if (res?.data?.results.length > 0) {
          setrows1(res?.data?.results);
        } else {
          const newitem = {
            Graphics_Name: "Demographic",
            Demo_Description: "",
            MaxScore: "",
            allow: true,
          };
          setrows1([newitem]);
        }
      });
  };

  // handle Update on the Lead Scoring Page

  const handleupdate1 = (index, data) => {
    if (data.id !== undefined) {
      if (
        data.Demo_Description === "" ||
        data.MaxScore === ""
      ) {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadDemo_Id: data.LeadDemo_Id,
          Graphics_Name: data?.Graphics_Name,
          Demo_Description: data?.Demo_Description,
          MaxScore: data?.MaxScore,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadDemoGraphicsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              DemogetRecords();
              // Generalrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };


  //  Lead Firmo Graphics 

  const [rows2, setrows2] = useState([]);

  //  Firmo Add Record

  const handleAddRow2 = () => {
    const newitem = {
      Graphics_Name: "Firemographic",
      Demo_Description: "",
      MaxScore: "",
      allow: true,
    };
    setrows2([...rows2, newitem]);
  };

  // Firmo Removal Record
  const handleRemoveRow2 = (item, index) => {
    handleDeleteprediction2(item);
    setrows2((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction2 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(`${BASE_URL}/useradmin/LeadFirmoGraphicsCRUD/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Firmo Create Record
  const LeadSubmitFirmoGraphic = () => {
    let arr = [];
    rows2.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Demo_Description === "" ||
          item.Demo_Description === undefined ||
          item.Demo_Description === null ||
          item.MaxScore === "" ||
          item.MaxScore === undefined ||
          item.MaxScore === null
        ) {
          toast.error("Invalid Data in Lead Firmographics");
        } else {
          // setParameterCheck(item.get("parameter"))
          let playload = {
            Graphics_Name: item?.Graphics_Name,
            Demo_Description: item?.Demo_Description,
            MaxScore: item?.MaxScore,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };

          axios
            .post(`${BASE_URL}/useradmin/LeadFirmoGraphicsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Lead Firmographics Created Successfully");
                FirmogetRecords()
                // Generalrecords()
              }
              // setdatas(res.data.results)
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Firmo Change
  const handleAddChange2 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows2];
    updatedRows[index][name] = value;
    setrows2(updatedRows);
  };

  // Get List

  const FirmogetRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadFirmoGraphicsAdditional/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.data?.results", res?.data?.results);
        if (res?.data?.results.length > 0) {
          setrows2(res?.data?.results);
        } else {
          const newitem = {
            Graphics_Name: "Firemographic",
            Demo_Description: "",
            MaxScore: "",
            allow: true,
          };
          setrows2([newitem]);
        }
      });
  };

  // handle Update on the Lead Scoring Page

  const handleupdate2 = (index, data) => {
    if (data.id !== undefined) {
      if (
        data.Demo_Description === "" ||
        data.MaxScore === ""
      ) {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadFirmo_Id: data.LeadFirmo_Id,
          Graphics_Name: data?.Graphics_Name,
          Demo_Description: data?.Demo_Description,
          MaxScore: data?.MaxScore,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadFirmoGraphicsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              FirmogetRecords();
              // Generalrecords()
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };


  //  For the positive Behaviour

  //  Lead positive Graphics 

  const [rows3, setrows3] = useState([]);

  //  positive Add Record

  const handleAddRow3 = () => {
    const newitem = {
      Graphics_Name: "Behavior-Positive",
      Demo_Description: "",
      MaxScore: "",
      allow: true,
    };
    setrows3([...rows3, newitem]);
  };

  // positive Removal Record
  const handleRemoveRow3 = (item, index) => {
    handleDeleteprediction3(item);
    setrows3((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction3 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(`${BASE_URL}/useradmin/LeadPositiveGraphicsCRUD/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // positive Create Record

  const LeadSubmitPositiveGraphic = () => {
    let arr = [];
    rows3.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Demo_Description === "" ||
          item.Demo_Description === undefined ||
          item.Demo_Description === null ||
          item.MaxScore === "" ||
          item.MaxScore === undefined ||
          item.MaxScore === null
        ) {
          toast.error("Invalid Data in Behaviors - Positive");
        } else {
          // setParameterCheck(item.get("parameter"))
          let playload = {
            Graphics_Name: item?.Graphics_Name,
            Demo_Description: item?.Demo_Description,
            MaxScore: item?.MaxScore,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };

          axios
            .post(`${BASE_URL}/useradmin/LeadPositiveGraphicsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Created Successfully");
                FirmogetRecords()
                PositvegetRecords();
              }
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Firmo Change
  const handleAddChange3 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows3];
    updatedRows[index][name] = value;
    setrows3(updatedRows);
  };

  // Get List

  const PositvegetRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadPositiveGraphicsAdditional/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.data?.results", res?.data?.results);
        if (res?.data?.results.length > 0) {
          setrows3(res?.data?.results);
        } else {
          const newitem = {
            Graphics_Name: "Behavior-Positive",
            Demo_Description: "",
            MaxScore: "",
            allow: true,
          };
          setrows3([newitem]);
        }
      });
  };

  // handle Update on the Lead Scoring Page

  const handleupdate3 = (index, data) => {
    if (data.id !== undefined) {
      if (
        data.Demo_Description === "" ||
        data.MaxScore === ""
      ) {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          LeadPositive_Id: data?.LeadPositive_Id,
          Graphics_Name: data?.Graphics_Name,
          Demo_Description: data?.Demo_Description,
          MaxScore: data?.MaxScore,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadPositiveGraphicsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              PositvegetRecords();
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };


  // Negative Behaviour  Records 

  //  Lead Negative Graphics 

  const [rows4, setrows4] = useState([]);

  //  Negative Add Record

  const handleAddRow4 = () => {
    const newitem = {
      Graphics_Name: "Behavior-Negative",
      Demo_Description: "",
      MaxScore: "",
      allow: true,
    };
    setrows4([...rows4, newitem]);
  };

  // Negative Removal Record
  const handleRemoveRow4 = (item, index) => {
    handleDeleteprediction4(item);
    setrows4((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handleDeleteprediction4 = (data) => {
    console.log("Delete");
    if (data.id !== undefined) {
      axios
        .delete(`${BASE_URL}/useradmin/LeadNegativeGraphicsCRUD/?id=${data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Generalrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  // Negative Create Record

  const LeadSubmitNegativeGraphic = () => {

    let arr = [];
    rows4.map((item1, index1) => {
      if (item1.id == undefined) {
        arr.push(item1);
      }
    });
    if (arr.length !== 0) {
      arr.map((item, index) => {
        if (
          item.Demo_Description === "" ||
          item.Demo_Description === undefined ||
          item.Demo_Description === null ||
          item.MaxScore === "" ||
          item.MaxScore === undefined ||
          item.MaxScore === null
        ) {
          toast.error("Invalid Data in Behaviors - Negative");
        } else {
          // setParameterCheck(item.get("parameter"))
          let playload = {
            Graphics_Name: item?.Graphics_Name,
            Demo_Description: item?.Demo_Description,
            MaxScore: item?.MaxScore,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };

          axios
            .post(`${BASE_URL}/useradmin/LeadNegativeGraphicsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                db: localStorage.getItem("DBName"),
              },
            })
            .then((res) => {
              console.log("post", res.data);
              if (res.data.id !== undefined) {
                toast.success("Created Successfully");
                NegativegetRecords()
              }
            })
            .catch((error) => {
              console.log("ds", error);
            });
        }
      });
    }
  };

  // Firmo Change

  const handleAddChange4 = (event, index) => {
    const { name, value } = event.target;
    const updatedRows = [...rows4];
    updatedRows[index][name] = value;
    setrows4(updatedRows);
  };

  // Get List

  const NegativegetRecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/LeadNegativeGraphicsAdditional/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("res?.data?.results", res?.data?.results);
        if (res?.data?.results.length > 0) {
          setrows4(res?.data?.results);
        } else {
          const newitem = {
            Graphics_Name: "Behavior-Negative",
            Demo_Description: "",
            MaxScore: "",
            allow: true,
          };
          setrows4([newitem]);
        }
      });
  };

  // handle Update on the Lead Scoring Page

  const handleupdate4 = (index, data) => {
    if (data.id !== undefined) {
      if (
        data.Demo_Description === "" ||
        data.MaxScore === ""
      ) {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: data?.id,
          Graphics_Name: data?.Graphics_Name,
          LeadNegative_Id: data?.LeadNegative_Id,
          Demo_Description: data?.Demo_Description,
          MaxScore: data?.MaxScore,
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        axios
          .put(`${BASE_URL}/useradmin/LeadNegativeGraphicsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === data.id) {
              toast.success("Updated Successfully");
              NegativegetRecords();
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
    }
  };

  useEffect(() => {
    DemogetRecords();
    FirmogetRecords();
    PositvegetRecords();
    NegativegetRecords();
  }, []);

  return (
    <div>
      <JumboContentLayoutMain>
        <Typography sx={[ComponentHeading]}> Lead Scoring Setup</Typography>
        <Div sx={[DisplayFlex, { justifyContent: "center" }]}>
          <Div sx={[DisplayFlex, { gap: "10px" }]}>
            <Typography sx={[{ fontSize: "14px" }]}> Choose a Type</Typography>
            <FormControl>
              <Autocomplete
                options={[
                  "Demographic",
                  "Firemographic",
                  "Behavior-Positive",
                  "Behavior-Negative",
                ]}
                value={demotype}
                className="search-select"
                getOptionLabel={(option) => option || ""}
                sx={{ width: 300 }}
                onChange={(e, newValue) => ShowField(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Div>

        </Div>
        <Div sx={{ display: demo == true ? "block" : "none" }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={scrollHeight ? scrollHeight : 400}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader className="table" sx={{ mt: 2 }}>
              <TableHead sx={[CRMTableHead, FontStyle]}>
                <CRMTableRow>
                  <CRMTableCell>Sl.No</CRMTableCell>
                  <CRMTableCell>Description</CRMTableCell>
                  <CRMTableCell>Max Score </CRMTableCell>
                  <CRMTableCell align="center">Action</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                {rows1.length > 0 &&
                  rows1.map((item, index) => {
                    //  console.log("value",item.Value)
                    return (
                      <CRMTableRow key={index}>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "40%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="Default Id"
                              disabled={true}
                              // type="number"
                              // onChange={(e) => {
                              //   handleAddChange1(e, index);
                              // }}
                              value={item?.LeadDemo_Id}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "100%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="Description"
                              name="Demo_Description"
                              onChange={(e) => {
                                handleAddChange1(e, index);
                              }}
                              value={item?.Demo_Description}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "50%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="MaxScore"
                              name="MaxScore"
                              value={item?.MaxScore}
                              onChange={(e) => {
                                handleAddChange1(e, index);
                              }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell data-title="Action" align="center">
                          <Div>
                            <IconButton
                              className="edit-icon"
                              disabled={item?.allow}
                              onClick={(e) => {
                                handleupdate1(index, item);
                              }}
                            >
                              <BiEdit />
                            </IconButton>

                            <Button
                              color="error"
                              onClick={() => {
                                handleRemoveRow1(item, index);
                              }}
                            >
                              <MdDelete size={24} />
                            </Button>
                          </Div>
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar>
          <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
            * Score shall be less than 100
          </Div>
          <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              sx={{ mt: 1, ml: 2, mb: 0, color: "#10BBE5" }}
              onClick={handleAddRow1}
            >
              + Add More Lines
            </Button>
          </Div>
          <Div
            sx={{
              mt: 0,
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              mr: 5,
              mb: 4
            }}
          >
            <CustomButton
              onClick={LeadSubmitDemoGraphic}
              label="Save"
              type="submit"
              Icon="saveIcon"
              sx={{ mr: 1 }}
            />
            <Cancelbutton
              label="Cancel"
            />

          </Div>

        </Div>
        <Div sx={{ display: Firemographic == true ? "block" : "none" }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={scrollHeight ? scrollHeight : 400}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader className="table" sx={{ mt: 2 }}>
              <TableHead sx={[CRMTableHead, FontStyle]}>
                <CRMTableRow>
                  <CRMTableCell>Sl.No</CRMTableCell>
                  <CRMTableCell>Description</CRMTableCell>
                  <CRMTableCell>Max Score </CRMTableCell>
                  <CRMTableCell align="center">Action</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                {rows2.length > 0 &&
                  rows2.map((item, index) => {
                    //  console.log("value",item.Value)
                    return (
                      <CRMTableRow key={index}>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "40%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="Default"
                              value={item?.LeadFirmo_Id}
                            // onChange={(e) => {
                            //   handleAddChange2(e, index);
                            // }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "100%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="Description"
                              name="Demo_Description"
                              onChange={(e) => {
                                handleAddChange2(e, index);

                              }}
                              value={item?.Demo_Description}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "50%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="MaxScore"
                              name="MaxScore"
                              value={item?.MaxScore}
                              onChange={(e) => {
                                handleAddChange2(e, index);

                              }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell data-title="Action" align="center">
                          <Div>
                            <IconButton
                              className="edit-icon"
                              disabled={item?.allow}
                              onClick={(e) => { handleupdate2(index, item) }}
                            >
                              <BiEdit />
                            </IconButton>

                            <Button
                              color="error"
                              onClick={() => { handleRemoveRow2(item, index) }}
                            >
                              <MdDelete size={24} />
                            </Button>
                          </Div>
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar>
          <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
            * Score shall be less than 100
          </Div>
          <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              sx={{ mt: 1, ml: 2, mb: 0, color: "#10BBE5" }}
              onClick={handleAddRow2}
            >
              + Add More Lines
            </Button>
          </Div>
          <Div
            sx={{
              mt: 0,
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              mr: 5,
              mb: 4
            }}
          >
            <CustomButton
              onClick={LeadSubmitFirmoGraphic}
              label="Save"
              type="submit"
              Icon="saveIcon"
              sx={{ mr: 1 }}
            />
            <Cancelbutton
              label="Cancel"
            />

          </Div>

        </Div>
        <Div sx={{ display: Positive == true ? "block" : "none" }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={scrollHeight ? scrollHeight : 400}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader className="table" sx={{ mt: 2 }}>
              <TableHead sx={[CRMTableHead, FontStyle]}>
                <CRMTableRow>
                  <CRMTableCell>Sl.No</CRMTableCell>
                  <CRMTableCell>Description</CRMTableCell>
                  <CRMTableCell>Max Score </CRMTableCell>
                  <CRMTableCell align="center">Action</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                {rows3.length > 0 &&
                  rows3.map((item, index) => {
                    //  console.log("value",item.Value)
                    return (
                      <CRMTableRow key={index}>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "40%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="default"
                              disabled={true}
                              value={item?.LeadPositive_Id}
                            // onChange={(e) => {
                            //   handleAddChange3(e, index);
                            // }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "100%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="Description"
                              name="Demo_Description"
                              onChange={(e) => {
                                handleAddChange3(e, index);
                              }}
                              value={item?.Demo_Description}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "50%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="MaxScore"
                              name="MaxScore"
                              value={item?.MaxScore}
                              onChange={(e) => {
                                handleAddChange3(e, index);
                              }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell data-title="Action" align="center">
                          <Div>
                            <IconButton
                              className="edit-icon"
                              disabled={item?.allow}
                              onClick={(e) => { handleupdate3(index, item) }}
                            >
                              <BiEdit />
                            </IconButton>

                            <Button
                              color="error"
                              onClick={() => { handleRemoveRow3(item, index) }}
                            >
                              <MdDelete size={24} />
                            </Button>
                          </Div>
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar>
          <Div sx={{ mt: 1, ml: 4, color: "#F22323" }}>
            * Score shall be less than 100
          </Div>
          <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              sx={{ mt: 1, ml: 2, mb: 0, color: "#10BBE5" }}
              onClick={handleAddRow3}
            >
              + Add More Lines
            </Button>
          </Div>
          <Div
            sx={{
              mt: 0,
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              mr: 5,
              mb: 4
            }}
          >
            <CustomButton
              onClick={LeadSubmitPositiveGraphic}
              label="Save"
              type="submit"
              Icon="saveIcon"
              sx={{ mr: 1 }}
            />
            <Cancelbutton
              label="Cancel"
            />

          </Div>

        </Div>
        <Div sx={{ display: Negative == true ? "block" : "none" }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={scrollHeight ? scrollHeight : 400}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader className="table" sx={{ mt: 2 }}>
              <TableHead sx={[CRMTableHead, FontStyle]}>
                <CRMTableRow>
                  <CRMTableCell>Sl.No</CRMTableCell>
                  <CRMTableCell>Description</CRMTableCell>
                  <CRMTableCell>Max Score </CRMTableCell>
                  <CRMTableCell align="center">Action</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                {rows4.length > 0 &&
                  rows4.map((item, index) => {
                    //  console.log("value",item.Value)
                    return (
                      <CRMTableRow key={index}>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "40%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="SI  No"
                              disabled={true}
                              value={item?.LeadNegative_Id}
                            // onChange={(e) => {
                            //   handleAddChange4(e, index);
                            // }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "100%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="Description"
                              name="Demo_Description"
                              value={item?.Demo_Description}
                              onChange={(e) => {
                                handleAddChange4(e, index);
                              }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell>
                          <FormControl>
                            <TextField
                              sx={{ width: { xs: "100%", md: "50%" } }}
                              className="input-box"
                              variant="outlined"
                              placeholder="MaxScore"
                              name="MaxScore"
                              value={item?.MaxScore}
                              onChange={(e) => {
                                handleAddChange4(e, index);
                              }}
                            ></TextField>
                          </FormControl>
                        </CRMTableCell>
                        <CRMTableCell data-title="Action" align="center">
                          <Div>
                            <IconButton
                              className="edit-icon"
                              disabled={item?.allow}
                              onClick={(e) => {
                                handleupdate4(index, item);
                              }}
                            >
                              <BiEdit />
                            </IconButton>

                            <Button
                              color="error"
                              onClick={() => {
                                handleRemoveRow4(item, index);
                              }}
                            >
                              <MdDelete size={24} />
                            </Button>
                          </Div>
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar>
          <Div sx={{ mt: 1, ml: 4, mb: 0, color: "#F22323" }}>
            * Score shall be less than 100
          </Div>
          <Div sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              sx={{ mt: 1, ml: 2, color: "#10BBE5" }}
              onClick={handleAddRow4}
            >
              + Add More Lines
            </Button>
          </Div>
          <Div
            sx={{
              mt: 0,
              justifyContent: "end",
              alignItems: "center",
              display: "flex",
              mr: 5,
              mb: 4
            }}
          >
            <CustomButton
              onClick={LeadSubmitNegativeGraphic}
              label="Save"
              type="submit"
              Icon="saveIcon"
              sx={{ mr: 1 }}
            />
            <Cancelbutton
              label="Cancel"
            />

          </Div>

        </Div>
        <Div sx={{ mt: 1, ml: 2, }}>

        </Div>

        {/* pagination */}

      </JumboContentLayoutMain>
      <ToastContainer />
    </div>
  );
}

export default LeadScore;