import Div from "@jumbo/shared/Div";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import {
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
  FormControl,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Menu,
  ButtonGroup,
  Popover,
  Box,
} from "@mui/material";
import {
  CloseIcon,
  EditIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
  SettingsIcon,
} from "app/icons/PngIcons";

import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { getAssetPath } from "app/utils/appHelpers";
import { ASSET_AVATARS, ASSET_IMAGES } from "app/utils/constants/paths";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Appointment from "./Appointment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import randomColor from "randomcolor";
import {
  AiOutlineClockCircle,
  AiOutlineEdit,
  AiOutlineMail,
} from "react-icons/ai";
import { TbSquareLetterI } from "react-icons/tb";

import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import moment from "moment";
import { BASE_URL, BASE_URL1, Tenantname } from "app/services/auth-services";
import DealEmailContent from "app/pages/home/email-lists/DealEmailContent";
import DealConatctNotes from "./ContactNotes";
import { alpha, styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
import "./dialogCall.css";
import {
  CallEnd,
  ExpandLess,
  ExpandMore,
  KeyboardDoubleArrowUp,
  Mic,
  MicOff,
  ModeEdit,
  Speaker,
  SpeakerGroupSharp,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LeadMailContent from "app/pages/home/email-lists/LeadMailContent";
import { IoIosArrowBack, IoMdAttach, IoMdClose } from "react-icons/io";
import {
  ButtonStyle,
  CRMTableHead,
  dialogTitle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  generateRandomColor,
  Colors,
  customHeight,
  deletedWarnigMsg,
  dialogFontSize,
  input_label,
  input_label1,
} from "app/pages/widgets/CRMStyles";
import { BsClockHistory } from "react-icons/bs";
import { TiPhoneOutline } from "react-icons/ti";
import DealCallHistory from "./DealCallHistory";
import DealHistory from "./DealHistory";
import LeadOpenActivity from "./LeadOpenActivity";
import DeleteIcon from "@mui/icons-material/Delete";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import EditDemo from "./EditDemo";
import ColumnBarChart from "app/pages/analytics/Bars/ColumnBarChart";
import ArrowIndicator from "./ArrowIndicator";
import GaugeChart from "react-gauge-chart";
import {
  AvatarCircle,
  Cancelbutton,
  DialogBox,
  CustomButton,
  errorFilter,
  Dragabledialog,
  Customgrid,
  ckEditorOnchange,
  CustomCKEditor,
  fetchTransactionSeries,
} from "app/pages/widgets/StylesComponents";
import { MdOutlineModeEdit, MdOutlineScheduleSend } from "react-icons/md";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { ProfileImageUpload } from "app/pages/contacts/extra-components/APIs";
import { GrTransaction } from "react-icons/gr";
import TransactionReport from "./TransactionReport";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ComposeEmail from "app/pages/contacts/extra-components/ComposeEmail";

function customizeText(arg) {
  // console.log('arg',arg);
  return `${arg.valueText}%`;
}

const OverviewDealContact = ({ scrollHeight }) => {
  const navigate = useNavigate();

  const [base64, setbase64] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [openUploadEdit, setOpenUploadEdit] = useState(false);
  const [showNotes, setShowNotes] = useState(true);
  const [showMail, setShowMail] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [phonenotes, setPhoneNotes] = useState(false);
  const [showOpenActivity, setShowOpenActivity] = useState(false);
  const [showClosedActivity, setShowClosedActivity] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const [tap, setTap] = useState(0);

  const [showDial, setShowDial] = useState(false);
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [noteCall, setNoteCall] = useState(false);

  const [showCc, setShowCc] = useState(false);
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachFile, setAttachFile] = useState("");
  const [showMailDialog, setShowMailDialog] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [bannerColor, setBannerColors] = useState("");
  const [showDelete, setShowDelete] = useState(null);
  const [errors, setErrors] = useState({});
  const [seriesValue, setSeriesValue] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const { id } = useParams();

  const handleTap = useCallback(
    (value) => {
      setTap(value);
    },
    [tap]
  );

  console.log("overview", id);

  const handleShowNotes = () => {
    setShowNotes(true);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowMail = () => {
    setShowNotes(false);
    setShowMail(true);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };
  const handleShowPhone = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(true);
  };

  const handleShowOpenActivity = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(true);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowClosedActivity = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(true);
    setShowAppointment(false);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowAppointment = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(true);
    setShowHistory(false);
    setPhoneNotes(false);
  };

  const handleShowHistory = () => {
    setShowNotes(false);
    setShowMail(false);
    setShowOpenActivity(false);
    setShowClosedActivity(false);
    setShowAppointment(false);
    setShowHistory(true);
    setPhoneNotes(false);
  };

  // image upload function
  const handleChangeImage = (e) => {
    // console.log("file uploaded: ", e.target.files[0]);
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = handleReaderLoaded;
      setImage(reader.readAsBinaryString(file));
      setImageName(randomColor() + file?.name);
    }
  };
  const handleReaderLoaded = (e) => {
    // console.log("file uploaded 2: ", e);
    let binaryString = e.target.result;
    setbase64(btoa(binaryString));
  };

  // upload profile image api
  const handleSaveImage = (e) => {
    let payload = { base64, imageName, id };
    let error = errorFilter(payload);

    if (Object.keys(error).length === 0) {
      ProfileImageUpload(
        payload,
        setOpenUploadEdit,
        "UserQualifiedLeadsCRUD",
        getData
      );
      setbase64("");
      setOpenUploadEdit(false);
    }
  };

  const onsubmit = () => {
    setExpanded(false);
    setNoteCall(false);
    setNotes("");
  };

  // Getting the Conatct details based on the Id
  const item = "crmuseradmin1";
  const token = localStorage.getItem("accesstoken");

  const [datas, setDatas] = useState([]);
  const [appointments, setAppointments] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserQualifiedLeadsCRUD/?id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Data fetched successfully:", response.data);
      setDatas(response.data);
      setImageName(randomColor() + response?.data?.FileName || "");
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // Handle the error here, e.g., show an error message to the user
    }
  };
  const FunctionLeadPredict = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/LeadPredictionScore/?id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Data fetched successfully:", response.data);
      getData();
      setPrediction(false);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // Handle the error here, e.g., show an error message to the user
    }
  };

  const date = new Date();
  let day = date.getDate();
  // console.log("day",day);

  const getAppointments = () => {
    axios
      .get(
        `${BASE_URL}/user/LeadUpcomingActivities/?Is_Deleted=False&Lead_Id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("get appointments list", res.data);
        setAppointments(res.data || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // mail sending ---------------------------------------
  const [base64DataList, setBase64DataList] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleFileInputChange = (e) => {
    const fileList = e.target.files;

    const promises = Array.from(fileList).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((base64DataArray) => {
        setBase64DataList(base64DataArray);
        const fileArray = Array.from(fileList);
        let list = [...fileNames, ...fileArray];
        setFileNames(list);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const fileRemoved = (e) => {
    let list = [...fileNames];
    list?.splice(e, 1);
    let base64Array = [...base64DataList];
    base64Array?.splice(e, 1);
    setBase64DataList(base64Array);
    // alert(e)
    setFileNames(list);
  };

  const uploadFiles = () => {
    const promises = base64DataList?.map((base64Data, index) => {
      console.log(`Uploading `, fileNames);
      const payload = {
        Organization_Id: localStorage.getItem("OrganizationId"),
        File: base64Data,
        FileName: randomColor() + fileNames[index]?.name,
        Urls: "url",
        Description: "Upload File",
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };

      return axios.post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    });

    Promise.all(promises)
      .then((responses) => {
        console.log("Files uploaded successfully:", responses);
        MailSubmit();
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  const mailBodyContent = `<div class='card'>
  <div class='card-body'>
    <h4 className="card-subtitle mb-2 text-muted">Dear Sir,</h4>
    <div class='card-text'>
      <p>${message}</p>
      <p>Click the button to download the attachment </p>
      <div>
      ${base64DataList?.map((items, index) => (
        <a href={`BASE_URL/media/${fileNames[index]?.name}`}>
          <button
            type="button"
            class="btn btn-primary"
            style="background-color:#3c7c33; color: #fff; border: none; border-radius: 4px; padding: 8px 16px; cursor: pointer; text-decoration: none;"
          >
            Download Attachment
          </button>
        </a>
      ))}
      
      
      </div>
          </div>
  </div>
  </div>`;

  const MailSubmit = async (e) => {
    // e.preventDefault();
    var payload = {
      MailSubject: subject,
      Organization_Id: localStorage.getItem("OrganizationId"),
      MailBody: mailBodyContent,
      file:
        fileNames?.length > 0
          ? fileNames?.map((fileName) => fileName?.name)
          : [],
      Cc: [cc],
      RecevierMailId: to,
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Lead_Id: id,
      UserId: localStorage.getItem("UserId"),
    };
    console.log("payload", payload);
    await axios
      .post(`${BASE_URL}/user/UserMailMasterUpdateCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log("res", res);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // For Mail List & Call List

  const [maildatas, setMailDatas] = useState([]);
  const [calldatas, setCallDatas] = useState([]);
  const [lastvisiteddate, setlastvisiteddate] = useState([]);

  const MailData = () => {
    axios
      .get(`${BASE_URL}/user/LeadMailListAdditional/?Lead_Id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log("Mail Data check",res.data.pop());
        setMailDatas(res?.data?.pop()?.MailSentDate);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const CallData = () => {
    axios
      .get(`${BASE_URL}/user/UserLeadCallCRUD/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log("for contact check")
        // console.log("Mail Data check for call",res.data.results.pop());
        setCallDatas(res?.data?.results?.pop()?.Created_Date);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const lastvisitedDate = () => {
    axios
      .get(
        `${BASE_URL}/user/UserLeadMeetingMyAdditional/?Is_Deleted=False&Lead_Id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // setDatas(res.data.results || []);
        setlastvisiteddate(res?.data?.results?.pop()?.Created_Date);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  useEffect(() => {
    MailData();
    CallData();
    lastvisitedDate();
  }, [id]);

  useEffect(() => {
    getData();
    getAppointments();
    setBannerColors(generateRandomColor());
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const onClosed = () => {
    setShowDial(false);
    setNoteCall(false);
  };

  // Lead Prediction popup

  const [leadprediction, setPrediction] = useState(false);

  // Call Status Submission functionality
  const option = [
    "Connected",
    "Customer Missed",
    "Voice mail",
    "Busy",
    "Disconnect",
    "Missed call",
    "Call waiting",
    "Confidence call",
    "Call attended",
    "Do Not Disturb Turned On",
    "Call Not Picked",
    "Called Back",
  ];

  const contaxtoptions = [
    "Solving Problem",
    "Making Decision",
    "Solution Achieved",
  ];
  const callconvoptions = [
    "Casual",
    "Tension",
    "Not Interested",
    "Friendly",
    "Confidancial",
  ];

  const BusyHroptions = ["Morning", "Evening", "After Noon"];

  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");
  const [nextdate, setNextDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [CallRecording, setCallRecording] = useState(null);
  const [MissedRang, setMissedRang] = useState(0);
  const [callcontext, setCallContext] = useState("");
  const [busy, setBusy] = useState("");
  const [callconvstatus, setCallconvstatus] = useState("");

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);

  const handleEditorChange = (event) => {
    const value = event.editor.getData();
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const textContent = doc.body.textContent || "";
    setNotes(textContent);
  };

  //  Call Center Activity
  const [firstREmainderHrs, setfirstREmainderHrs] = useState("");
  const [Repeateveryhrs, setRepeateveryhrs] = useState("");
  const [firstRemainder, setfirstRemainder] = useState("");
  const [repeatEvery, setrepeatEvery] = useState("");
  const [isReminderSet, setIsReminderSet] = useState(false);
  const [show, setShow] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  const handleDialogClose = (confirmed) => {
    setIsReminderSet(false);
  };

  const onChange = (evt, editor) => {
    ckEditorOnchange(evt, editor, setEditorContent);
    // console.log("eve", evt);
    // var newContent = evt.editor.getData();
    // console.log("newContent", newContent);
    // const doc = parser.parseFromString(value, "text/html");
    // const textContent = doc.body.textContent || "";
    // setEditorContent(textContent);
  };

  const setreminder = (event) => {
    setIsReminderSet(true);
    setShow(true);
    console.log("inside");
  };

  const handleReset = () => {
    setfirstREmainderHrs("");
    setRepeateveryhrs("");
    setfirstRemainder("");
    setrepeatEvery("");
    setEditorContent("");
    setNotes("");
    setStatus("");
    setNextDate("");
    setDuration("");
    setCallRecording("");
    setCallContext("");
    setBusy("");
    setCallconvstatus("");
  };

  const handleSubmit = (e) => {
    console.log("Deal,", datas);
    e.preventDefault();
    var payload = {
      CallCenter_Id: "",
      Company_Name: datas?.CompanyName,
      Receiver_Name: datas?.LeadFirstName,
      Contact_Number: datas?.PhoneNo,
      Selected_Date: moment().format("YYYY-MM-DD"),
      Next_SheduledTime: nextdate,
      Call_Duration: duration,
      Missed_Rank: MissedRang,
      Call_Recording: CallRecording,
      Call_Context: callcontext,
      Call_Con_Status: callconvstatus,
      Call_Busy_hour: busy,
      Activity_Type: "Call",
      Activity_relates: "Lead",
      Details: notes,
      Select_Status: status,
      Reminder_Payload: Boolean(show)
        ? {
            RemainderDueDate: firstRemainder + " " + firstREmainderHrs,
            Repeat_EveryDay: repeatEvery + " " + Repeateveryhrs,
            ReminderFor: "Reminder for Deal Call",
            RemainderMessage: editorContent,
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          }
        : null,
      Lead_Id: Number(id),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };
    console.log("Call", payload);
    axios
      .post(`${BASE_URL}/user/CallcenterActivityCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("check", res);
        toast.success("Created successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setShowDial(false);
        handleClose();
        // for Updating the Contact List

        if (datas?.No_of_Calls == null || "" || NaN) {
          var x = 1;
        } else {
          var x = parseInt(datas?.No_of_Calls) + 1;
        }

        // For Updateing the History of Changes
        let editUser = localStorage.getItem("Username");
        let histotypayload = {
          Document_Id: res.data.CallCenter_Id,
          Updated_Person: `${editUser} Created this Qualified Lead Phone History.`,
          Module_Name: "Qualified Lead Phone History",
          Lead_Id: Number(id),
          Updated_By: parseInt(localStorage.getItem("UserId")),
        };
        console.log("histotypayload", histotypayload);
        axios
          .post(
            `${BASE_URL}/user/UserQualifiedLeadsHistorycrud/`,
            histotypayload,
            {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            console.log("Result", res.data);
          })
          .catch((res) => {
            console.log(res.message);
          });

        // Create Task
        // CreateTask(editUser)
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  //FunctionPredictPost

  const FunctionPredictPost = async () => {
    axios
      .get(
        `${BASE_URL}/user/LeadPredictionScore/?id=${
          datas?.id
        }&Organization_Id=${localStorage.getItem("Organization_Id")}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res.data);
        getData();
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  //Lead  Demo CRUD Operation Table
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [showdemo, setShowDemo] = useState(false);
  const [closeDemo, setCloseDemo] = useState(false);
  const [closeDelete, setCloseDelete] = useState(false);
  const [closePredict, setClosePredict] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickEdit = () => {
    //  // // // console.log("In Edit",update)
    setOpenEdit(true);
  };
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const [demofields, setDemoFields] = useState({
    topic: "",
    demoduration: "",
    whatdemo: "",
    whichdemo: "",
    demoperson: "",
    whomtodemo: "",
    avrgRes: "",
    feedbackstatus: { label: "" },
    ResponseStatus: { label: "" },
    feedback: "",
    response: "",
    date: "",
  });

  const ShowDemoPopup = () => {
    setShowDemo(true);
  };

  // Demo List API
  const [demodatas, setDemoDatas] = useState([]);

  const [page, setPage] = useState(1);

  const Reset = () => {
    setDemoFields({
      ...demofields,
      topic: "",
      demoduration: "",
      whatdemo: "",
      whichdemo: "",
      demoperson: "",
      whomtodemo: "",
      avrgRes: "",
      feedbackstatus: false,
      ResponseStatus: false,
      feedback: "",
      response: "",
      date: "",
    });
    setErrors({});
  };

  const FeedBackStatus = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const getDataList = (page) => {
    axios
      .get(
        `${BASE_URL}/user/LeadDemoAdditional/?Created_By=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("results in contacts", res.data.results);
        setDemoDatas(res.data.results || []);
        // setCount(res.data?.count || 0)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // For Create Operation

  const FunctionLeadSubmit = () => {
    let error = errorFilter({
      ...demofields,
      ResponseStatus: demofields?.ResponseStatus?.label,
      feedbackstatus: demofields?.feedbackstatus.label,
    });

    if (Boolean(seriesValue !== "Automatic") && !Boolean(demofields?.DemoId)) {
      error.DemoId = "Demo Id is required";
    } else if (
      Boolean(seriesValue !== "Automatic") &&
      !Boolean(demofields?.DemoId?.length == 11)
    ) {
      error.DemoId = "Demo Id must be 11 digits.";
    }

    if (Object.keys(error).length > 0) {
      toast.error("Please Check the all the fields are Filled", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      const payload = {
        LeadDemo_Id: seriesValue === "Manual" && demofields?.DemoId,
        Demo_topic: demofields?.topic,
        Date: demofields?.date,
        Demo_Duration: demofields?.demoduration,
        What_Demo: demofields?.whatdemo,
        Which_demo: demofields?.whichdemo,
        Who_demo: demofields?.demoperson,
        Whom_to_demo: demofields?.whomtodemo,
        Avg_responsetime: demofields?.avrgRes,
        Feedback_Status: demofields?.feedbackstatus?.value,
        Response_Status: demofields?.ResponseStatus?.value,
        Feedback: demofields?.feedback,
        Response: demofields?.response,
        Lead_Id: Number(id),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };
      console.log("Patch payload : ", payload);

      try {
        axios
          .post(`${BASE_URL}/user/LeadDemoCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("update res", res.data);
            toast.success("Created successfully", {
              position: "top-right",
              autoClose: 100,
            });
            getDataList();
            setShowDemo(false);
            Reset();
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      }
    }

    setErrors(error);
  };

  useEffect(() => {
    getDataList();
  }, []);

  // For Table Column

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Demo_topic", label: "Demo topic", bool: true },
    { sort: "Demo_Duration", label: "Demo Duration", bool: true },
    { sort: "Date", label: "Date", bool: false },
    { sort: "What_Demo", label: "What Demo", bool: true },
    { sort: "Which_demo", label: "Which demo", bool: false },
    { sort: "Who_demo", label: "Who demo", bool: false },
    { sort: "Whom_to_demo", label: "Whom to demo", bool: false },
    { sort: "Feedback", label: "Feedback", bool: false },
    { sort: "Response", label: "Response", bool: false },
  ]);

  // Update
  const [number, setNumber] = useState(1);

  const [update, setUpdate] = useState({});

  const handleUpdate = (data) => {
    // console.log("modifiedContent",modifiedContent);
    setUpdate(data);
  };

  // Delete Data

  const Deletedata = async () => {
    //  // // // console.log("data",data);
    //  // // // console.log("number",number)
    await axios
      .delete(`${BASE_URL}/user/LeadDemoCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  // // // console.log(res.data);
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        getDataList(page);
        handleCloseAction();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // Lead Overview Page

  const [leadoverview, setLeadOverview] = useState(false);

  // For Bar Chart Vakues
  const [dataSource2, setDataSource2] = useState([]);

  //  Get Demo Details
  const [detailsGraphics, setDetailsGraphics] = useState([]);

  const getDetailsGraphics = () => {
    axios
      .get(`${BASE_URL}/user/LeadDemoGrphicsDetails/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setDetailsGraphics(res?.data?.Demo_Details || []);
        // setCount(res.data?.count || 0)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //  Get Firmo Details
  const [detailsfirmoGraphics, setDetailsFrimoGraphics] = useState([]);

  const getDetailsFirmoGraphics = () => {
    axios
      .get(`${BASE_URL}/user/LeadFirmoGrphicsDetails/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setDetailsFrimoGraphics(res?.data?.Firmo_Details || []);
        // setCount(res.data?.count || 0)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //  Get Positive Details
  const [detailspositiveGraphics, setDetailsPositiveGraphics] = useState([]);

  const getDetailsPositiveGraphics = () => {
    axios
      .get(`${BASE_URL}/user/LeadPositiveGrphicsDetails/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setDetailsPositiveGraphics(res?.data?.Positive_Details || []);
        // setCount(res.data?.count || 0)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //  Get Negative Details
  const [detailsnegativeGraphics, setDetailsNegativeGraphics] = useState([]);

  const getDetailsNegativeGraphics = () => {
    axios
      .get(`${BASE_URL}/user/LeadNegativeGrphicsDetails/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setDetailsNegativeGraphics(res?.data?.Negative_Details || []);

        // setCount(res.data?.count || 0)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // For getting the overview page Content

  const LeadScoringGraph = () => {
    axios
      .get(`${BASE_URL}/user/LeadScoringGraph/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setDataSource2(res?.data?.Graph || []);
        // setCount(res.data?.count || 0)
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // For getting the LeadScoring

  const UpdateLeadScoring = () => {
    axios
      .get(
        `${BASE_URL}/user/UpdatedLeadScoring/?id=${id}&UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        getData();
        getDetailsGraphics();
        getDetailsFirmoGraphics();
        LeadScoringGraph();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [knowlaritydatas, setKnowlaritydatas] = useState([]);
  const [agentdetails, setagentdetails] = useState([]);
  const [callogsdetails, setcallogsdetails] = useState([]);

  const calllogs = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityCallLogsCRUD/?start_Date=2020-04-16&end_Date=&UserId=${localStorage.getItem(
          "UserId"
        )}&Module=Lead`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("calllogsresss", res.data);
        // setKnowlaritydatas(res?.data);
        // setcallogsdetails(res.data)
        const mynumberList = res?.data?.filter((item) =>
          item.Agent_Number.includes(userDetails?.PhoneNo)
        );

        const lastIndexData = mynumberList[0];
        console.log("lastIndexData", mynumberList, lastIndexData);

        setDuration(lastIndexData?.Call_Duration);
        setCallRecording(lastIndexData?.Call_Recording);
        setMissedRang(lastIndexData?.Call_Duration);

        if (lastIndexData?.Agent_Number?.includes("User Disconnected")) {
          setStatus("User Disconnected");
        } else if (lastIndexData?.Agent_Number?.includes("Customer Missed")) {
          setStatus("Customer Missed");
        } else {
          setStatus("Connected");
        }

        setIsLoading(false);
        setAnchorEl(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const getKnowlarityConfiguration = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res?.data", res.data);
        setKnowlaritydatas(res?.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const getagentnumber = async () => {
    await axios
      .get(`https://kpi.knowlarity.com/Basic/v1/account/agent`, {
        headers: {
          // "Accept": "application/json",
          // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
          // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

          Accept: "application/json",
          "x-api-key": knowlaritydatas?.Header_Key,
          Authorization: knowlaritydatas?.API_Key,
        },
      })
      .then((res) => {
        console.log("agentres", res);
        const agentnum = res.data?.objects?.find(
          (item) => item?.phone === userDetails?.PhoneNo
        );
        // setagentdetails(agentnum)

        onGoingCallApi(agentnum);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  //  OnGoing Call API

  const onGoingCallApi = async (agentnum) => {
    console.log("agentnum", agentnum);
    let knopayload = {
      // k_number: "+918037125874",
      // agent_number: "+919344312970",
      // customer_number: "+918870791912",
      // caller_id: "+918040210001",
      // caller_name: datas?.Name,
      k_number: knowlaritydatas?.Knowlarity_Number,
      agent_number: agentnum?.country_code + agentnum?.phone,
      customer_number: "+91" + datas?.PhoneNo,
      caller_id: knowlaritydatas?.Knowlarity_CallerId,
      caller_name: datas?.Name,
    };
    console.log("knopayload", knopayload);
    await axios
      .post(
        `https://kpi.knowlarity.com/Basic/v1/account/call/makecall`,
        knopayload,
        {
          headers: {
            // "Accept": "application/json",
            // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
            // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

            Accept: "application/json",
            "x-api-key": knowlaritydatas?.Header_Key,
            Authorization: knowlaritydatas?.API_Key,
          },
        }
      )
      .then((res) => {
        console.log("callres", res);
        setShowDial(true);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.message);
      });
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        calllogs();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  useEffect(() => {
    getKnowlarityConfiguration();
  }, []);

  useEffect(() => {
    getDetailsGraphics();
    getDetailsFirmoGraphics();
    getDetailsPositiveGraphics();
    getDetailsNegativeGraphics();
    LeadScoringGraph();
  }, []);

  //  Right time to Call

  const [rightTimeCalls, setRightTimeCalls] = useState([]);
  const [rightTimeToCallData, setRightTimeToCallData] = useState({});
  const [openShcedule, setOpenshcedule] = useState(false);

  const ContactRightTimeAdditional = async () => {
    try {
      if (Boolean(datas?.id)) {
        await axios
          .get(
            `${BASE_URL}/user/ContactRightTimeAdditional/?Lead_Id=${Number(
              datas?.id
            )}&Is_Delete=False`,
            {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            // setDatas(res?.data?.Result);
            if (res?.data?.results?.length > 0) {
              // console.log("Bearer", res?.data?.results[0]);
              // rightTimeToCall(res?.data?.Result[res?.data?.Result?.length - 1]);
              setRightTimeCalls(res?.data?.results[0]);
              setOpenshcedule(false);
            }
          });
        //
      }
    } catch (error) {
      console.error(error);
    }
  };
  console.log("rightTimeCalls", rightTimeCalls);
  // right time to call function
  const rightTimeToCallSave = async () => {
    try {
      let response;
      if (!Boolean(rightTimeCalls?.id)) {
        const payload = {
          Lead_Id: datas?.id,
          Right_Time:
            rightTimeToCallData
              ?.filter((right) => right?.checked)
              ?.map((right) => right?.time) ?? [],
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        response = await axios.post(
          `${BASE_URL}/user/ContactRightTimeCRUD/`,
          payload,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      } else if (Boolean(rightTimeCalls?.id)) {
        const payload = {
          id: rightTimeCalls?.id,
          Lead_Id: datas?.id,
          Right_Time:
            rightTimeToCallData
              ?.filter((right) => right?.checked)
              ?.map((right) => right?.time) ?? [],
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
        response = await axios.put(
          `${BASE_URL}/user/ContactRightTimeCRUD/`,
          payload,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
      }
      if (response) {
        ContactRightTimeAdditional();
      }
    } catch (error) {
      console.error("Error in rightTimeToCall:", error);
    }
  };
  // console.log("agentdetails", agentdetails?.phone, agentdetails?.country_code);
  // right time to call function
  const rightTimeToCall = async (rightTimeTo) => {
    try {
      const payload = {
        Customer_number: rightTimeTo?.PhoneNo,
        Call_status: rightTimeTo?.Select_Status,
        Call_duration: rightTimeTo?.Call_Duration,
        business_type: rightTimeTo?.Business_Call_Type,
      };
      const response = await axios.post(
        `${BASE_URL1}/user/RighttimetocallContacts/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response?.data?.Right_time?.length > 0) {
        // Extract the Right_time array from the response and map it to the required format
        const rightTimeData = Object.values(response?.data?.Right_time[0])
          ?.map((val) => {
            if (
              rightTimeCalls?.id &&
              rightTimeCalls?.Right_Time.includes(val)
            ) {
              return {
                id: rightTimeCalls?.id,
                time: val,
                checked: true,
              };
            } else {
              return {
                time: val,
                checked: false,
              };
            }
          })
          .filter(Boolean);
        // Update the state with the mapped data
        console.log("rightTimeData2", rightTimeData);
        setRightTimeToCallData(rightTimeData);
      }
    } catch (error) {
      console.error("Error in rightTimeToCall:", error);
    }
  };
  const rightTimeToCallLists = async () => {
    let startDate = moment().startOf("year");
    let endDate = moment().endOf("year");
    let ThisYearStart = startDate?.format("YYYY-MM-DD");
    let ThisYearEnd = endDate?.format("YYYY-MM-DD");
    if (Boolean(datas?.Mobile_Number) && Boolean(datas?.id)) {
      await axios
        .get(
          `${BASE_URL}/user/KnowlarityAgentCalllogsandActivityList/?Phone_Number=${
            datas?.Mobile_Number
          }&Contact_Id=${Number(
            datas?.id
          )}&start_date=${ThisYearStart}&end_date=${ThisYearEnd}&Module=Contact`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("NotesFilter", res?.data?.results);
          // setDatas(res?.data?.Result);
          if (res?.data?.Result?.length > 0) {
            rightTimeToCall(res?.data?.Result[res?.data?.Result?.length - 1]);
          }
        })
        .catch((error) => {
          console.log("error");
        });
      //
    }
  };

  useEffect(() => {
    ContactRightTimeAdditional();
    rightTimeToCallLists();
  }, [datas]);

  useEffect(async () => {
    const series = await fetchTransactionSeries("Lead_Demo");
    console.log("Lead_Demo series", series);
    setSeriesValue(series);
  }, []);

  return (
    <>
      <JumboContentLayoutMain>
        {/* call section */}
        <Dialog
          open={showDial && !Boolean(anchorEl)}
          // onClose={onClosed}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{
              backgroundColor: "#0070B9",
              overflow: "hidden",
              // borderTopRightRadius: "12px",
              // borderRadiusTopleft: "12px",
            }}
          >
            {/* <IconButton
              onClick={() => {
                return setShowDial(false), setNoteCall(false);
              }}
              sx={{
                right: "10px",
                top: "8px",
                position: "absolute",
                opacity: "87%",
              }}
            >
              <CloseIcon size={24} color="#FFFFFF" />
            </IconButton> */}
            <Stack
              sx={{
                overflow: "hidden",
                width: "350px",
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <div class="spinner-border text-success" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <Div
                    sx={{
                      width: "100%",
                      height: "200px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Div
                      sx={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "green",
                        borderRadius: "50%",
                        position: "relative",
                        zIndex: "2",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          fontSize: "30px",
                          borderColor: "common.white",
                          boxShadow: "inset 0px 0px 5px white",
                          color: "#3498db",
                          fontWeight: "900",
                        }}
                      >
                        {datas?.Name && datas?.Name[0]?.toUpperCase()}
                        {datas?.Name && datas?.Name[1]?.toUpperCase()}
                      </Avatar>
                    </Div>
                    <Div
                      sx={{
                        width: "115px",
                        height: "115px",
                        position: "absolute",
                        backgroundColor: "#F2F2F2",
                        opacity: "25%",
                        borderRadius: "50%",
                      }}
                    ></Div>
                    <Div
                      sx={{
                        width: "130px",
                        height: "130px",
                        position: "absolute",
                        backgroundColor: "#F2F2F2",
                        opacity: "20%",
                        borderRadius: "50%",
                      }}
                    ></Div>
                    <Div
                      sx={{
                        width: "145px",
                        height: "145px",
                        position: "absolute",
                        backgroundColor: "#F2F2F2",
                        opacity: "15%",
                        borderRadius: "50%",
                      }}
                    ></Div>
                  </Div>
                  <Div sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h3"
                      mb={1}
                      sx={{
                        fontSize: "28px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                      color={"common.black"}
                    >
                      {datas?.Name}
                    </Typography>
                    <Typography sx={{ color: "#F2F2F2", fontSize: "16px" }}>
                      {datas?.Mobile_Number}
                    </Typography>
                  </Div>

                  <Div
                    sx={{
                      mt: 2,
                      gap: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Div>
                      <IconButton
                        onClick={() => setVolume(false)}
                        sx={{
                          display: volume ? "" : "none",
                          borderRadius: "50%",
                          padding: "8px",
                          color: "black",
                          backgroundColor: "white",
                          ":hover": { backgroundColor: "white", opacity: 0.8 },
                        }}
                      >
                        <VolumeUp fontSize="16px" color="black" />
                      </IconButton>
                      <IconButton
                        onClick={() => setVolume(true)}
                        sx={{
                          display: !volume ? "" : "none",
                          borderRadius: "50%",
                          padding: "8px",
                          color: "black",
                          backgroundColor: "white",
                          opacity: 0.8,
                          ":hover": { backgroundColor: "white", opacity: 0.8 },
                        }}
                      >
                        <VolumeOff fontSize="16px" color="black" />
                      </IconButton>
                    </Div>
                    <IconButton
                      sx={{
                        borderRadius: "50%",
                        padding: "8px",
                        color: "white",
                        backgroundColor: "red",
                        ":hover": {
                          backgroundColor: "white",
                          color: "red",
                        },
                      }}
                      onClick={() => setIsLoading(true)}
                    >
                      <CallEnd fontSize="13px" color="red" />
                    </IconButton>
                    <Div>
                      <IconButton
                        onClick={() => setMic(false)}
                        sx={{
                          display: mic ? "" : "none",
                          borderRadius: "50%",
                          padding: "8px",
                          color: "black",
                          backgroundColor: "white",
                          ":hover": { backgroundColor: "white", opacity: 0.9 },
                        }}
                      >
                        <MicOff fontSize="16px" color="black" />
                      </IconButton>
                      <IconButton
                        onClick={() => setMic(true)}
                        sx={{
                          display: !mic ? "" : "none",
                          borderRadius: "50%",
                          padding: "8px",
                          color: "black",
                          backgroundColor: "white",
                          opacity: 0.8,
                          ":hover": { backgroundColor: "white", opacity: 0.8 },
                        }}
                      >
                        <Mic fontSize="16px" color="black" />
                      </IconButton>
                    </Div>
                  </Div>
                  <Div
                    sx={{
                      textAlign: "center",
                      borderRadius: "5px",
                      marginTop: "30px",
                      fontSize: "16px",
                      backgroundColor: "white",
                      padding: "10px",
                    }}
                  >
                    Connecting . . . .
                  </Div>
                </>
              )}
            </Stack>
          </DialogContent>
        </Dialog>

        {/* call submit form */}
        <Dragabledialog maxWidth="lg" open={Boolean(anchorEl)}>
          <DialogTitle
            sx={[
              DisplayFlex,
              dialogFontSize,
              { justifyContent: "space-between" },
            ]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Create Call Notes
          </DialogTitle>

          <form onSubmit={handleSubmit}>
            <DialogContent sx={{ mt: 2 }}>
              <Div className="row">
                <Div className="col-12 col-md-6 col-lg-6">
                  <Grid container>
                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography
                        className="col-md-12 col-lg-6 col-xl-5 input-label"
                        sx={[input_label]}
                      >
                        Call Status
                      </Typography>
                      <Div className="col-md-12 col-lg-6 col-xl-6">
                        <Autocomplete
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.value) {
                              return option?.value;
                            }
                            return "";
                          }}
                          options={option}
                          value={status}
                          onChange={(e, newValue) => {
                            console.log("newValue", newValue);
                            setStatus(newValue);
                          }}
                          id="outlined-basic"
                          className="search-select3 w-full"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: "10px",
                              }}
                              placeholder="Call Status"
                            />
                          )}
                        />
                      </Div>
                    </Grid>
                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography
                        className="col-md-12 col-lg-6 col-xl-5 input-label"
                        sx={[input_label]}
                      >
                        Call Duration
                      </Typography>
                      <Div className="col-md-12 col-lg-6 col-xl-6">
                        <TextField
                          className="input-box2 w-full"
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                          placeholder="Enter Call Duration"
                          type="number"
                          value={duration}
                          InputProps={{
                            endAdornment: (
                              <Typography
                                sx={[FontStyle, { textAlign: "right" }]}
                              >
                                Seconds
                              </Typography>
                            ),
                          }}
                        />
                      </Div>
                    </Grid>
                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography
                        className="col-md-12 col-lg-6 col-xl-5 input-label"
                        sx={[input_label]}
                      >
                        Call Busy Hour
                      </Typography>
                      <Div className="col-md-12 col-lg-6 col-xl-6">
                        <Autocomplete
                          className="search-select3 w-full"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.value) {
                              return option?.value;
                            }
                            return "";
                          }}
                          value={busy}
                          options={BusyHroptions}
                          onChange={(e, newValue) => {
                            console.log("newValue", newValue);
                            setBusy(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: "10px",
                              }}
                              placeholder="Call Busy Hour"
                            />
                          )}
                        />
                      </Div>
                    </Grid>
                  </Grid>
                </Div>
                <Div className="col-12 col-md-6 col-lg-6">
                  <Grid container>
                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography
                        className="col-md-12 col-lg-6 col-xl-5 input-label"
                        sx={[input_label]}
                      >
                        Call Coversation Status
                      </Typography>
                      <Div className="col-md-12 col-lg-6 col-xl-6">
                        <Autocomplete
                          className="search-select3 w-full"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.value) {
                              return option?.value;
                            }
                            return "";
                          }}
                          value={callconvstatus}
                          options={callconvoptions}
                          onChange={(e, newValue) => {
                            console.log("newValue", newValue);
                            setCallconvstatus(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: "10px",
                              }}
                              placeholder="Call Status"
                            />
                          )}
                        />
                      </Div>
                    </Grid>

                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography
                        className="col-md-12 col-lg-6 col-xl-5 input-label"
                        sx={[input_label]}
                      >
                        Call Context
                      </Typography>
                      <Div className="col-md-12 col-lg-6 col-xl-6">
                        <Autocomplete
                          className="search-select3 w-full"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.value) {
                              return option?.value;
                            }
                            return "";
                          }}
                          value={callcontext}
                          options={contaxtoptions}
                          onChange={(e, newValue) => {
                            console.log("newValue", newValue);
                            setCallContext(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: "10px",
                              }}
                              placeholder="Call Context"
                            />
                          )}
                        />
                      </Div>
                    </Grid>

                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography
                        className="col-md-12 col-lg-6 col-xl-5 input-label"
                        sx={[input_label]}
                      >
                        Next Scheduled Date
                      </Typography>
                      <Div className="col-md-12 col-lg-6 col-xl-6">
                        <TextField
                          type="datetime-local"
                          value={nextdate}
                          onChange={(e) => {
                            setNextDate(e.target.value);
                          }}
                          id="outlined-basic"
                          variant="outlined"
                          className="input-box2 w-full"
                        />
                        <FormGroup
                          sx={{ display: nextdate ? "block" : "none" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox checked={show} onChange={setreminder} />
                            }
                            label={
                              <Typography sx={[input_label1]}>
                                Set Reminder
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </Div>
                    </Grid>
                  </Grid>
                </Div>

                <Div className="col-12 col-md-12">
                  <Grid container>
                    <Grid item xs={12} className="row" sx={{ mt: 1 }}>
                      <Typography className="col-md-2.5" sx={[input_label]}>
                        Notes
                      </Typography>
                      <Div className="col-md-12">
                        <CKEditor
                          initData={notes}
                          // className={`${errors["notes"] && "border-1 border-danger"}`}
                          onChange={handleEditorChange}
                        />
                      </Div>
                    </Grid>
                  </Grid>
                </Div>
              </Div>
            </DialogContent>
            <DialogActions sx={[DisplayFlex, { justifyContent: "center" }]}>
              <CustomButton
                type="submit"
                label="Save"
                // onClick={handleSaveImage}
                Icon="saveIcon"
              />
            </DialogActions>
            <Dragabledialog
              open={isReminderSet}
              onClose={() => handleDialogClose(false)}
              maxWidth={"md"}
            >
              <DialogTitle
                sx={[DisplayFlex, dialogTitle, { cursor: "move" }]}
                id="draggable-dialog-title"
              >
                Reminder
              </DialogTitle>
              <DialogContent>
                <Box mt={2} sx={{ height: "200px !important" }}>
                  <Customgrid columnCount={4}>
                    <Grid item colSpan={4}>
                      <Typography sx={[input_label]}>
                        First Reminder Before Due
                      </Typography>
                    </Grid>

                    <Grid item sx={{ display: "flex" }} colSpan={8} gap={2}>
                      <Div>
                        <TextField
                          className="w-full input-box2"
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Enter Time"
                          value={firstRemainder}
                          onChange={(e) => {
                            setfirstRemainder(e.target.value);
                          }}
                        />
                      </Div>
                      <Div>
                        <Autocomplete
                          className="w-full search-select3"
                          sx={{ width: "100px !important" }}
                          options={[
                            { name: "Days" },
                            { name: "Hrs" },
                            { name: "Mins" },
                          ]}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          value={firstREmainderHrs}
                          onChange={(e, newValue) => {
                            setfirstREmainderHrs(newValue?.name || "");
                            // functiondate(newValue)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Select" />
                          )}
                        />
                      </Div>
                    </Grid>

                    <Grid item colSpan={4}>
                      <FormGroup
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label={
                            <Typography sx={[input_label]}>
                              Repeat Every
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item sx={{ display: "flex" }} colSpan={8} gap={2}>
                      <Div>
                        <TextField
                          className="w-full input-box2"
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Enter Time"
                          value={repeatEvery}
                          onChange={(e) => {
                            setrepeatEvery(e.target.value);
                          }}
                        />
                      </Div>
                      <Div>
                        <Autocomplete
                          className="w-full search-select3"
                          sx={{ width: "100px !important" }}
                          options={[{ name: "Hrs" }, { name: "Mins" }]}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          value={Repeateveryhrs}
                          onChange={(e, newValue) => {
                            setRepeateveryhrs(newValue.name);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Select" />
                          )}
                        />
                      </Div>
                    </Grid>

                    <Grid item colSpan={4}>
                      <Typography
                        //   id="Typography-root"
                        sx={[input_label]}
                      >
                        Reminder Message
                      </Typography>
                    </Grid>

                    <Grid item colSpan={8}>
                      <Div>
                        <CustomCKEditor
                          initData={editorContent}
                          onChange={onChange}
                        />
                      </Div>
                    </Grid>
                  </Customgrid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <CustomButton
                    label="Save"
                    Icon="saveIcon"
                    onClick={() => handleDialogClose(true)}
                  />
                  <Cancelbutton onClick={() => handleDialogClose(false)} />
                </Div>
                {/* <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button onClick={() => handleDialogClose(true)} color="primary">OK</Button> */}
              </DialogActions>
            </Dragabledialog>
          </form>
        </Dragabledialog>

        {/* mail modal */}
        <Dragabledialog open={showMailDialog} maxWidth="lg">
          <ComposeEmail
            endpoint={"UserMailMasterUpdateCRUD"}
            data={datas}
            setOpenSendMail={setShowMailDialog}
            setOpen={setShowMail}
            extraPayload={{ Lead_Id: datas?.id }}
          />
          {/* <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Compose Email
          </DialogTitle> */}

          {/* <DialogContent>
            <DialogContentText sx={{ pt: 2 }}>
              <Customgrid>
                <Grid item>
                  <Typography sx={[input_label]}>From</Typography>
                </Grid>

                <Grid item>
                  <Typography sx={[FontStyle]}>
                    {JSON.parse(localStorage.getItem("UserDetails"))?.email}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={[input_label]}>To</Typography>
                </Grid>
              </Customgrid>

              <Div>
                <Typography sx={FontStyle}>From </Typography>
                <TextField
                  type={"email"}
                  value={UserDetails?.email || "No Email"}
                  className="input-box"
                  // {...register("to")}
                  placeholder={"From"}
                  disabled
                />
              </Div>

              <Div sx={{ width: "100%", my: 1 }}>
                <Typography sx={FontStyle}>To </Typography>
                <ButtonGroup
                  sx={{
                    borderRadius: "0px !important",
                    width: "100%",
                    position: "relative",
                  }}
                  aria-label="Basic button group"
                >
                  <TextField
                    type={"email"}
                    className="input-box col-12"
                    
                    placeholder={"To"}
                    onChange={(e) => setTo(e.target.value)}
                  />
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        position: "absolute",
                        top: "50%",
                        left: "95%",
                        transform: "translate(-50%,-20%)",
                      },
                    ]}
                  >
                    {!Boolean(showCc) && (
                      <Typography
                        sx={[
                          FontStyle,
                          {
                            cursor: "pointer",
                            ":hover": { color: Colors?.Mblue },
                            alignSelf: "center",
                            height: "33px !important",
                            border: "none !important",
                          },
                        ]}
                        onClick={() => setShowCc(true)}
                      >
                        Cc
                      </Typography>
                    )}
                  </Div>
                </ButtonGroup>
              </Div>

              {Boolean(showCc) && (
                <Div sx={{ my: 1, width: "100%" }}>
                  <Typography sx={FontStyle}>Cc </Typography>
                  <TextField
                    type={"email"}
                    className="input-box"
                    placeholder={"CC"}
                    value={cc}
                    onChange={(e) => setCc(e.target.value)}
                  />
                </Div>
              )}

              <Div sx={{ my: 1, width: "100%" }}>
                <Typography sx={FontStyle}>Subject </Typography>
                <TextField
                  className="input-box"
                  placeholder={"Subject"}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Div>

              <Div sx={{ my: 1, width: "100%" }}>
                <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                  <Typography sx={FontStyle}>Content </Typography>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="attachedFiles"
                    multiple
                    onChange={handleFileInputChange}
                  />
                  <label
                    htmlFor="attachedFiles"
                    style={{ cursor: "pointer" }}
                  >
                    <IoMdAttach style={{ fontSize: 20 }} />
                  </label>
                </Div>

                <Div
                  sx={{ border: "1px solid lightgray", borderRadius: "5px" }}
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        flexWrap: "wrap",
                        gap: "10px !important",
                        p: Boolean(fileNames?.length > 0) ? 1 : 0,
                      },
                    ]}
                  >
                    {fileNames?.map((file, index) => {
                      console.log("image", file, base64DataList[index]);
                      return (
                        <div
                          id="attachedFile"
                          key={index}
                          style={{ position: "relative" }}
                        >
                          <Div sx={{ width: 70, height: 90 }}>
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`File ${index + 1}`}
                              width={70}
                              height={70}
                            />
                            <p>
                              {file?.name?.length > 10
                                ? file?.name?.substring(0, 10) + "..."
                                : file?.name}
                            </p>
                          </Div>
                          <Div
                            id="attachedRemove"
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-65%)",
                              width: 70,
                              height: 70,
                              backgroundColor: alpha("#000", 0.2),
                            }}
                          >
                            <Div
                              sx={[DisplayFlex, { justifyContent: "center" }]}
                            >
                              <Typography
                                onClick={() => fileRemoved(index)}
                                sx={[
                                  {
                                    fontSize: "11px",
                                    color: "tomato !important",
                                  },
                                ]}
                              >
                                <IoMdClose
                                  style={{ fontSize: 20, color: "tomato" }}
                                />
                                Remove
                              </Typography>
                            </Div>
                          </Div>
                        </div>
                      );
                    })}
                  </Div>
                  <TextField
                    multiline
                    sx={{
                      width: "100%",
                      borderWidth: "0px !important",
                      border: "none !important",
                    }}
                    rows={5}
                    maxRows={10}
                    placeholder={"Content"}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Div>
              </Div>
            </DialogContentText>
          </DialogContent>
          <Div
            sx={[
              DisplayFlex,
              {
                justifyContent: "center",
                my: 3,
              },
            ]}
          >
            <CustomButton
              label="Send"
              type="submit"
              onClick={() => uploadFiles()}
              Icon="sendIcon"
            />
            <Cancelbutton onClick={() => setShowMail(true)} label="Cancel" />
          </Div> */}

          {/* Close dialog box */}
          <DialogBox
            onClickNo={() => setShowMail(false)}
            onClickYes={() => {
              setShowMail(false);
              setShowMailDialog(false);
              setFileNames([]);
            }}
            open={showMail}
          />
        </Dragabledialog>

        <Div
          sx={{
            display: "flex",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} xl={4}>
              <Card
                sx={{
                  mb: 2,
                  boxShadow: "none",
                  border: "1px solid #EEEEEE",
                  // minHeight: "760px",
                  height: "100%",
                  borderRadiusTopleft: "5px",
                  borderRadiusTopRight: "5px",
                }}
              >
                <Div
                  sx={{
                    position: "relative",
                    background: `linear-gradient(0deg,  rgb(245 245 245 / 79%) 0%, #00CDAC 100%)`,
                    // color: random && random[1],
                    height: "230px",
                    width: "100%",
                    py: 2,
                    px: 2,
                  }}
                >
                  <Div sx={[DisplayFlex, {}]}>
                    <Div>
                      <Div>
                        <IconButton
                          sx={{
                            cursor: "pointer",
                            transform: "translate(-20px , -10px)",
                            backgroundColor: "#f7f7f733",
                          }}
                          onClick={() => navigate(`/leads/qualifiedLeads`)}
                        >
                          <IoIosArrowBack color="white" size={18} />
                        </IconButton>
                      </Div>

                      <Div
                        sx={[
                          DisplayFlex,
                          {
                            justifyContent: "space-between !important",
                            width: "100%",
                            transform: "translateY(-17px)",
                          },
                        ]}
                      >
                        <Div>
                          <AvatarCircle
                            // title={datas?.Name}
                            image={
                              Boolean(datas?.File) && BASE_URL + datas?.File
                            }
                            bgColor={Colors?.Mblue}
                            size="120px"
                            fontsize="32px"
                          />
                          <Div
                            my={2}
                            sx={[
                              DisplayFlex,
                              {
                                ml: -1.5,
                                gap: "3px",
                                width: "100% !important",
                                justifyContent: "center",
                              },
                            ]}
                          >
                            <IconButton
                              onClick={() => {
                                // onGoingCallApi();
                                getagentnumber();
                              }}
                              sx={{}}
                            >
                              <PhoneIcon size={20} color="#5c5c5c" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setShowMailDialog(true);
                              }}
                              sx={{}}
                            >
                              <MailIcon size={20} color="#5c5c5c" />
                            </IconButton>
                            <IconButton
                              sx={{
                                p: 1,
                              }}
                              onClick={() => setOpenUploadEdit(true)}
                            >
                              <MdOutlineModeEdit size={20} color="#5c5c5c" />
                            </IconButton>
                            <IconButton
                              sx={{
                                p: 1,
                              }}
                              onClick={(e) => {
                                setOpenshcedule(e.currentTarget);
                                rightTimeToCallLists();
                              }}
                            >
                              <MdOutlineScheduleSend
                                size={20}
                                color="#5C5C5C"
                              />
                            </IconButton>
                            <Popover
                              id={id}
                              open={Boolean(openShcedule)}
                              anchorEl={openShcedule}
                              // onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <DialogTitle
                                id="alert-dialog-title"
                                sx={[dialogTitle, DisplayFlex]}
                              >
                                Rigth Time To Call
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  {rightTimeToCallData?.length > 0 &&
                                    rightTimeToCallData?.map((right, idx) => {
                                      return (
                                        <Div key={idx}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                onChange={() =>
                                                  setRightTimeToCallData(
                                                    rightTimeToCallData?.map(
                                                      (item, idxx) =>
                                                        idxx === idx
                                                          ? {
                                                              ...item,
                                                              checked:
                                                                !item?.checked,
                                                            }
                                                          : item
                                                    )
                                                  )
                                                }
                                                checked={right?.checked}
                                                size="small"
                                                color="info"
                                              />
                                            }
                                            label={
                                              <Typography sx={[FontStyle]}>
                                                {right?.time}
                                              </Typography>
                                            }
                                          />
                                        </Div>
                                      );
                                    })}
                                </DialogContentText>
                                <Div
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                    mt: 2,
                                  }}
                                >
                                  <Cancelbutton
                                    onClick={() => {
                                      setOpenshcedule(false);
                                    }}
                                    label="Cancel"
                                  />
                                  <CustomButton
                                    label={
                                      Boolean(rightTimeCalls?.id)
                                        ? "Update"
                                        : "Save"
                                    }
                                    onClick={rightTimeToCallSave}
                                    Icon="saveIcon"
                                  />
                                </Div>
                              </DialogContent>
                            </Popover>

                            <Dialog
                              open={openUploadEdit}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <Formik>
                                <Form>
                                  <DialogTitle
                                    id="alert-dialog-title"
                                    sx={[dialogTitle, DisplayFlex]}
                                  >
                                    Upload Picture
                                  </DialogTitle>

                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      <Div
                                        sx={[
                                          DisplayFlex,
                                          { justifyContent: "center" },
                                        ]}
                                      >
                                        <label
                                          title="Click upload Image"
                                          for={"profile_pic"}
                                        >
                                          <Avatar
                                            alt="User"
                                            src={
                                              base64
                                                ? `data:image/png;base64,${base64}`
                                                : image
                                            }
                                            sx={{
                                              width: 100,
                                              height: 100,
                                              verticalAlign: "middle",
                                              boxShadow: (theme) =>
                                                theme.shadows[3],
                                              cursor: "pointer",
                                              ":hover": {
                                                backgroundColor: "grey",
                                              },
                                            }}
                                          />
                                        </label>
                                      </Div>

                                      <Typography
                                        sx={{
                                          fontSize: "13px",
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                          mt: 1,
                                        }}
                                      >
                                        {datas?.Name}
                                      </Typography>
                                      <input
                                        type="file"
                                        onChange={handleChangeImage}
                                        style={{ display: "none" }}
                                        id="profile_pic"
                                      />
                                    </DialogContentText>

                                    <DialogActions
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Cancelbutton
                                        onClick={() => setOpenUploadEdit(false)}
                                        label="Cancel"
                                      />
                                      <CustomButton
                                        label="Save"
                                        onClick={handleSaveImage}
                                        Icon="saveIcon"
                                      />
                                    </DialogActions>
                                  </DialogContent>
                                </Form>
                              </Formik>
                            </Dialog>
                          </Div>
                        </Div>

                        <Div>
                          <Typography
                            sx={{
                              fontSize: "29px",
                              color: "white !important",
                              fontWeight: 700,
                            }}
                          >
                            {datas?.LeadFirstName +
                              " " +
                              (datas?.LeadLastName ?? "") ?? "No Name"}
                          </Typography>

                          <Typography
                            sx={[
                              {
                                fontSize: "14px",
                                color: "#5c5c5c !important",
                                my: 1,
                              },
                            ]}
                          >
                            {datas?.PhoneNo || "No Mobile No"}
                          </Typography>

                          <Typography
                            sx={[
                              {
                                fontSize: "14px",
                                color: "#5c5c5c !important",
                                my: 1,
                              },
                            ]}
                          >
                            {datas?.EmailId || "No Email"}
                          </Typography>

                          {datas?.CompanyName && (
                            <Typography
                              sx={[
                                {
                                  fontSize: "14px",
                                  color: "#5c5c5c !important",
                                  my: 1,
                                },
                              ]}
                            >
                              {datas?.CompanyName || "No Company"}
                            </Typography>
                          )}
                          <Typography
                            sx={[
                              {
                                fontSize: "14px",
                                color: "#5c5c5c !important",
                                my: 1,
                              },
                            ]}
                          >
                            {datas?.City}
                            {Boolean(datas?.City) && ","}
                            {datas?.Country || "No Address"}
                          </Typography>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>
                <CardContent
                  sx={{ p: 2, backgroundColor: "rgb(245 245 245 / 79%)" }}
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        justifyContent: "space-between",
                        mb: 2,
                        padding: "10px",
                        backgroundColor: "#ffff",
                      },
                    ]}
                  >
                    <Typography sx={[dialogTitle]}>
                      Lead Scoring Overview
                    </Typography>
                    <CustomButton
                      Icon=""
                      label="Insights"
                      onClick={() => {
                        setLeadOverview(true);
                      }}
                    />
                  </Div>

                  <Div
                    sx={[
                      {
                        my: 2,
                        padding: "10px",
                        backgroundColor: "#ffff",
                      },
                    ]}
                  >
                    <Div
                      sx={[DisplayFlex, { justifyContent: "space-between" }]}
                    >
                      <Typography sx={[dialogTitle]}>
                        Lead Information
                      </Typography>
                      <CustomButton
                        Icon=""
                        label="Predict"
                        onClick={() => {
                          setPrediction(true);
                          FunctionPredictPost();
                        }}
                      />
                    </Div>
                    <Div
                      sx={{
                        padding: "10px 0px",
                      }}
                    >
                      <Div
                        sx={[
                          DisplayFlex,
                          { justifyContent: "space-between", py: 1 },
                        ]}
                      >
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          Lead Stage
                        </Typography>
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          {datas && datas?.Lead_Stage}
                        </Typography>
                      </Div>

                      <Div
                        sx={[
                          DisplayFlex,
                          { justifyContent: "space-between", py: 1 },
                        ]}
                      >
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          Lead Score
                        </Typography>
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          {datas && datas?.LeadScore}
                        </Typography>
                      </Div>

                      <Div
                        sx={[
                          DisplayFlex,
                          { justifyContent: "space-between", py: 1 },
                        ]}
                      >
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          Lead Status
                        </Typography>
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          {datas && datas?.Lead_Status}
                        </Typography>
                      </Div>

                      {/* <Div sx={[DisplayFlex, { justifyContent: "space-between", mb: 0.5 }]}>
                        <Typography sx={[FontStyle, { color: "#7D7D7D !important" }]} >
                          Last Prediction
                        </Typography>
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >

                          {datas && datas?.prediction}

                        </Typography>
                      </Div> */}
                    </Div>
                  </Div>

                  <Div
                    sx={[
                      {
                        my: 2,
                        padding: "10px",
                        backgroundColor: "#ffff",
                      },
                    ]}
                  >
                    <Typography
                      sx={[
                        dialogTitle,
                        {
                          padding: "10px 0px",
                        },
                      ]}
                    >
                      Insights
                    </Typography>

                    <List disablePadding>
                      <Div
                        sx={{
                          py: 1,
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {/* <ListItemIcon> */}
                        <MailIcon size={14} color="#C9C9C9" />
                        {/* </ListItemIcon> */}
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          Last contacted through mail on{" "}
                          {maildatas && moment(maildatas).format("YYYY-MM-DD")}
                        </Typography>
                      </Div>
                      <Div
                        sx={{
                          py: 1,
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {/* <Typography> */}
                        <PhoneIcon size={14} color="#C9C9C9" />
                        {/* </Typography> */}
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          Last contacted through phone on {calldatas}
                        </Typography>
                      </Div>
                      <Div
                        sx={{
                          py: 1,
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {/* <ListItemIcon> */}
                        <LocationIcon size={14} color="#C9C9C9" />
                        {/* </ListItemIcon> */}
                        <Typography
                          sx={[FontStyle, { color: "#7D7D7D !important" }]}
                        >
                          Last visited on {lastvisiteddate}
                        </Typography>
                      </Div>
                    </List>
                  </Div>

                  <Div
                    sx={[
                      {
                        my: 2,
                        padding: "10px",
                        backgroundColor: "#ffff",
                      },
                    ]}
                  >
                    <Div
                      sx={[
                        DisplayFlex,
                        { justifyContent: "space-between", mb: 1 },
                      ]}
                    >
                      <Typography sx={[dialogTitle]}>Lead Demo</Typography>
                      <CustomButton
                        Icon=""
                        label="Create"
                        onClick={() => {
                          setShowDemo(true);
                        }}
                      />
                    </Div>
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMax={350}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                      id="no-more-tables"
                    >
                      <Table className="table">
                        <CustomTableHeader
                          headerMenuItems={columnMenuItems}
                          setHeadermenuItems={setColumnMenuItems}
                          setDatas={setDemoDatas}
                          sortDatas={demodatas}
                        />
                        <TableBody>
                          {demodatas?.map((data, index) => {
                            return (
                              <CRMTableRow>
                                {columnMenuItems[0]?.bool && (
                                  <CRMTableCell>
                                    {data?.Demo_topic}
                                  </CRMTableCell>
                                )}
                                {columnMenuItems[1]?.bool && (
                                  <CRMTableCell>
                                    {data?.Demo_Duration || 0} Minutes
                                  </CRMTableCell>
                                )}
                                {columnMenuItems[2]?.bool && (
                                  <CRMTableCell>{data?.Date}</CRMTableCell>
                                )}
                                {columnMenuItems[3]?.bool && (
                                  <CRMTableCell>{data?.What_Demo}</CRMTableCell>
                                )}
                                {columnMenuItems[4]?.bool && (
                                  <CRMTableCell>
                                    {data?.Which_demo}
                                  </CRMTableCell>
                                )}
                                {columnMenuItems[5]?.bool && (
                                  <CRMTableCell>{data?.Who_demo}</CRMTableCell>
                                )}
                                {columnMenuItems[6]?.bool && (
                                  <CRMTableCell>
                                    {data?.Whom_to_demo}
                                  </CRMTableCell>
                                )}
                                {columnMenuItems[7]?.bool && (
                                  <CRMTableCell>{data?.Feedback}</CRMTableCell>
                                )}
                                {columnMenuItems[8]?.bool && (
                                  <CRMTableCell>{data?.Response}</CRMTableCell>
                                )}
                                <CRMTableCell
                                  data-title="Action"
                                  sx={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                >
                                  <IconButton
                                    sx={{ padding: "0px !important" }}
                                    onClick={(event) => {
                                      handleClickAction(event);
                                      handleUpdate(data);
                                      setNumber(data.id);
                                    }}
                                  >
                                    <MoreVertIcon
                                      sx={{
                                        padding: "0px !important",
                                        fontSize: 18,
                                      }}
                                    />
                                  </IconButton>
                                </CRMTableCell>
                                <Menu
                                  id="menu-appbar"
                                  anchorEl={anchorElAction}
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  keepMounted
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  open={Boolean(anchorElAction)}
                                  onClose={handleCloseAction}
                                  sx={{
                                    mt: 7,
                                    mr: 5,
                                    maxHeight: "300px",
                                    width: "300px",
                                    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleClickEdit();
                                    }}
                                    sx={[FontStyle]}
                                  >
                                    <ModeEdit sx={{ fontSize: 16, mr: 1 }} />
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    sx={[FontStyle]}
                                    onClick={() => {
                                      setCloseDelete(true);
                                    }}
                                  >
                                    <DeleteIcon sx={{ fontSize: 16, mr: 1 }} />
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </CRMTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </JumboScrollbar>
                  </Div>
                </CardContent>
              </Card>
            </Grid>

            {/* code for right side card */}

            <Grid item xs={12} sm={12} md={8} xl={8}>
              <Card
                sx={{
                  // px: 1,
                  p: { xs: 1, md: 2 },
                  boxShadow: "none",
                  border: "1px solid #EEEEEE",
                  height: "100%",
                  borderRadius: "5px",
                  width: "100% !important",
                  // height: "100%",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  spacing={2}
                  style={{
                    backgroundColor: "#dcf9f4",
                    width: "calc(100% + 35px)",
                    paddingBottom: "16px",
                  }}
                >
                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(0)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 0 ? Colors?.Mblue : "#613659",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <AiOutlineEdit size={14} />
                      </IconButton>
                      <Typography
                        mt={1}
                        sx={[
                          FontStyle,
                          {
                            color: tap === 0 ? Colors?.Mblue : "black",
                          },
                        ]}
                        textAlign="center"
                      >
                        Notes
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(1)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 1 ? Colors?.Mblue : "#ED006D",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <TiPhoneOutline size={14} />
                      </IconButton>
                      <Typography
                        mt={1}
                        sx={[
                          FontStyle,
                          {
                            color: tap === 1 ? Colors?.Mblue : "black",
                          },
                        ]}
                        textAlign="center"
                      >
                        Call History
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(2)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 2 ? Colors?.Mblue : "#6600FF",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <AiOutlineMail size={14} />
                      </IconButton>
                      <Typography
                        mt={1}
                        sx={[
                          FontStyle,
                          {
                            color: tap === 2 ? Colors?.Mblue : "black",
                          },
                        ]}
                        textAlign="center"
                      >
                        Mail
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(3)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 3 ? Colors?.Mblue : "#E32227",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <AiOutlineClockCircle size={14} />
                      </IconButton>
                      <Typography
                        mt={1}
                        sx={[
                          FontStyle,
                          {
                            color: tap === 3 ? Colors?.Mblue : "black",
                          },
                        ]}
                        textAlign="center"
                      >
                        Activities
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(4)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 4 ? Colors?.Mblue : "#B7AC44",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <InsertInvitationIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                      <Typography
                        sx={[
                          FontStyle,
                          {
                            color: tap === 4 ? Colors?.Mblue : "black",
                          },
                        ]}
                        mt={1}
                        textAlign="center"
                      >
                        Appointments
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(5)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 5 ? Colors?.Mblue : "#006194",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <BsClockHistory size={14} />
                      </IconButton>
                      <Typography
                        mt={1}
                        sx={[
                          FontStyle,
                          {
                            color: tap === 5 ? Colors?.Mblue : "black",
                          },
                        ]}
                        textAlign="center"
                      >
                        History
                      </Typography>
                    </Div>
                  </Grid>

                  <Grid item xs={4} md={3} lg={1.7} xl={1.7}>
                    <Div
                      onClick={() => handleTap(6)}
                      sx={{
                        textAlign: "center",
                        cursor: "pointer",
                        py: 1.5,
                        ":hover": {
                          borderRadius: "5px",
                          backgroundColor: "#99e1d2",
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor:
                            tap === 6 ? Colors?.Mblue : "#40A578",
                          mb: 0.5,
                          color: "#ffffff",
                          ":hover": {
                            backgroundColor: alpha(Colors?.Mblue, 0.8),
                          },
                        }}
                      >
                        <GrTransaction size={14} />
                      </IconButton>
                      <Typography
                        mt={1}
                        sx={[
                          FontStyle,
                          {
                            color: tap === 6 ? Colors?.Mblue : "black",
                          },
                        ]}
                        textAlign="center"
                      >
                        Transaction
                      </Typography>
                    </Div>
                  </Grid>
                </Grid>
                <Divider sx={{ mb: 2, color: "#000000" }} />

                <Box>
                  <Div sx={{ display: tap === 0 ? "" : "none" }}>
                    <DealConatctNotes id={id} data={datas} />
                  </Div>
                  <Div sx={{ display: tap === 1 ? "" : "none" }}>
                    <DealCallHistory id={id} leaddatas={datas} />
                  </Div>

                  <Div sx={{ display: tap === 2 ? "" : "none" }}>
                    <LeadMailContent data={datas} id={id} />
                  </Div>

                  <Div sx={{ display: tap === 3 ? "" : "none" }}>
                    <LeadOpenActivity data={datas} id={id} />
                  </Div>

                  {/* <Div
                    sx={{
                      display: showClosedActivity === true ? "block" : "none",
                    }}
                  >
                    <ClosedActivity id={id} />
                  </Div> */}

                  <Div
                    sx={{
                      display: tap === 4 ? "block" : "none",
                    }}
                  >
                    <Appointment data={datas} id={id} />
                  </Div>

                  <Div sx={{ display: tap === 5 ? "block" : "none" }}>
                    <DealHistory id={id} />
                  </Div>

                  <Div
                    sx={{
                      display: tap === 6 ? "block" : "none",
                    }}
                  >
                    <TransactionReport data={datas} id={id} />
                  </Div>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Div>

        {/* Create Demo Popup */}
        <Dragabledialog
          maxWidth="lg"
          open={showdemo}
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Create Lead Demo
          </DialogTitle>

          <DialogContent
            sx={{
              mt: 2,
            }}
          >
            <Customgrid columnCount={4}>
              <Grid item>
                <Typography sx={[input_label]}>
                  Demo Id
                  <span className="required">
                    {seriesValue == "Automatic" ? "" : "*"}
                  </span>
                </Typography>
              </Grid>

              <Grid item>
                <TextField
                  className="input-box2 col-12"
                  value={demofields?.DemoId}
                  onChange={(e) => {
                    setDemoFields({ ...demofields, DemoId: e.target.value });
                  }}
                  placeholder={"# Demo Id"}
                  disabled={seriesValue == "Automatic" ? true : ""}
                />
                {errors?.DemoId && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    {errors.DemoId}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Demo Topic <span className="required">*</span>{" "}
                </Typography>
              </Grid>

              <Grid item>
                <TextField
                  value={demofields?.topic}
                  placeholder={"Topic"}
                  className="input-box2 col-12"
                  onChange={(e) => {
                    setDemoFields({ ...demofields, topic: e.target.value });
                  }}
                />
                {errors?.topic && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Demo Topic {errors.topic}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  What Demo <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder={"What Demo"}
                  className="input-box2 col-12"
                  value={demofields?.whatdemo}
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      whatdemo: e.target.value,
                    });
                  }}
                />
                {errors?.whatdemo && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    What Demo {errors.whatdemo}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Which Demo <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder={"Which Demo"}
                  className="input-box2 col-12"
                  value={demofields?.whichdemo}
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      whichdemo: e.target.value,
                    });
                  }}
                />
                {errors?.whichdemo && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Which Demo {errors.whichdemo}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Demo Person <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder={"Demo Person"}
                  value={demofields?.demoperson}
                  className="input-box2 col-12"
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      demoperson: e.target.value,
                    });
                  }}
                />
                {errors?.demoperson && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Demo Person {errors.demoperson}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Whom to demo <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder={"Whom to demo"}
                  value={demofields?.whomtodemo}
                  className="input-box2 col-12"
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      whomtodemo: e.target.value,
                    });
                  }}
                />
                {errors?.whomtodemo && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Whom to demo {errors.whomtodemo}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Demo Duration <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder={"Demo Duration"}
                  value={demofields?.demoduration}
                  className="input-box2 col-12"
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      demoduration: e.target.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          paddingLeft: "100px !important",
                          transform: "translateY(5px)",
                        }}
                      >
                        Minutes
                      </Typography>
                    ),
                  }}
                />
                {errors?.demoduration && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Demo Duration {errors.demoduration}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Average Response Time <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  // type="time"
                  placeholder={"Average Response Time"}
                  value={demofields?.avrgRes}
                  className="input-box2 col-12"
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      avrgRes: e.target.value,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          paddingLeft: "100px !important",
                          transform: "translateY(5px)",
                        }}
                      >
                        Days
                      </Typography>
                    ),
                  }}
                />
                {errors?.avrgRes && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Average Response Time {errors.avrgRes}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography sx={[input_label]}>
                  Feed Back Status <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  // {...register('bookingType',)}
                  className="search-select3 col-12"
                  name="Feed-Back-Status"
                  options={FeedBackStatus}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.label) {
                      return option?.label;
                    } else {
                      return "";
                    }
                  }}
                  value={demofields?.feedbackstatus.label}
                  defaultValue={demofields?.feedbackstatus.label}
                  onChange={(e, newValue) => {
                    setDemoFields({
                      ...demofields,
                      feedbackstatus: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} sx={{}} />}
                />
                {errors?.feedbackstatus && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Feed Back Status {errors.feedbackstatus}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Response Status <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  className="search-select3 col-12"
                  name="response-status"
                  autocomplete="off"
                  options={FeedBackStatus}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.label) {
                      return option?.label;
                    } else {
                      return "";
                    }
                  }}
                  value={demofields?.ResponseStatus?.label}
                  onChange={(e, newValue) => {
                    setDemoFields({
                      ...demofields,
                      ResponseStatus: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} sx={{}} />}
                />
                {errors?.ResponseStatus && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Response Status {errors.ResponseStatus}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Feed Back <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  placeholder={"Feed back"}
                  value={demofields?.feedback}
                  className="input-box2 col-12"
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      feedback: e.target.value,
                    });
                  }}
                />
                {errors?.feedback && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Feed Back {errors.feedback}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography sx={[input_label]}>
                  Response <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  className="input-box2 col-12"
                  placeholder={"Response "}
                  value={demofields?.response}
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      response: e.target.value,
                    });
                  }}
                />
                {errors?.response && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Response {errors.response}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>
                  Date <span className="required">*</span>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="date"
                  placeholder={"Whom to demo"}
                  className="input-box2 col-12"
                  value={demofields?.date}
                  onChange={(e) => {
                    setDemoFields({
                      ...demofields,
                      date: e.target.value,
                    });
                  }}
                />
                {errors?.date && (
                  <Typography
                    sx={[{ fontSize: "12px", color: "red !important" }]}
                  >
                    Date {errors.date}
                  </Typography>
                )}
              </Grid>
            </Customgrid>

            <Div sx={[DisplayFlex, { my: 2, justifyContent: "center" }]}>
              <CustomButton
                Icon="saveIcon"
                onClick={() => FunctionLeadSubmit()}
                type="submit"
                label="Save"
              />
              <Cancelbutton
                label="Cancel"
                onClick={() => {
                  if (
                    Object.values(demofields)?.some((value) =>
                      typeof value === "string"
                        ? Boolean(value?.trim())
                        : Boolean(value?.label?.trim())
                    )
                  ) {
                    setCloseDemo(true);
                  } else {
                    setShowDemo(false);
                  }
                }}
              />
            </Div>
          </DialogContent>
        </Dragabledialog>

        {/* close Demo */}
        <DialogBox
          open={closeDemo}
          onClickNo={() => setCloseDemo(false)}
          onClickYes={() => {
            setCloseDemo(false);
            setShowDemo(false);
          }}
        />

        {/* Lead Prediction */}
        <Dragabledialog
          maxWidth="md"
          open={leadprediction}
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Lead Prediction
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography sx={[input_label1]}>Prediction Days</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={[input_label1]}>:</Typography>
                </Grid>

                <Grid item xs={7}>
                  <Div
                    className="input-box-2"
                    sx={[FontStyle, { bgcolor: "#f8f8f8" }]}
                  >
                    {datas?.prediction}
                  </Div>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={[input_label1]}>
                    Recommended Action
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography sx={[input_label1]}>:</Typography>
                </Grid>

                <Grid item xs={7}>
                  <Div
                    className="input-box-2"
                    sx={[FontStyle, { bgcolor: "#f8f8f8" }]}
                  >
                    {datas?.Recommaned_Action}
                  </Div>
                </Grid>
              </Grid>
            </DialogContentText>
            <Div
              sx={[
                DisplayFlex,
                {
                  my: 2,
                  mt: 3,
                  gap: "10px !important",
                  justifyContent: "center",
                },
              ]}
            >
              <CustomButton
                Icon="saveIcon"
                onClick={() => FunctionLeadPredict()}
                type="submit"
                label="Save"
              />
              <Cancelbutton
                label="Cancel"
                onClick={() => {
                  setClosePredict(true);
                }}
              />
            </Div>
          </DialogContent>
        </Dragabledialog>

        {/* close predict */}
        <DialogBox
          open={closePredict}
          onClickNo={() => setClosePredict(false)}
          onClickYes={() => {
            setClosePredict(false);
            setPrediction(false);
          }}
        />

        {/*  for the Lead Scoring  */}

        {/* Lead Prediction */}

        <Dragabledialog
          maxWidth="lg"
          open={leadoverview}
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Lead Scoring Overview
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Div sx={{ my: 1 }}>
                  <Typography sx={[dialogTitle]}>Demo Graphic</Typography>
                  <JumboScrollbar
                    autoHeight={true}
                    autoHideTimeout={4000}
                    autoHeightMax={150}
                    autoHeightMin={50}
                    autoHide={true}
                    hideTracksWhenNotNeeded
                    id="no-more-tables"
                  >
                    <Table stickyHeader>
                      <TableHead sx={[CRMTableHead, FontStyle]}>
                        <CRMTableRow>
                          <CRMTableCell width={60}>SI.No</CRMTableCell>
                          <CRMTableCell colSpan={2}>Graphics Name</CRMTableCell>
                          <CRMTableCell>Values</CRMTableCell>
                          <CRMTableCell>Score</CRMTableCell>
                        </CRMTableRow>
                      </TableHead>
                      <TableBody>
                        {detailsGraphics?.map((data, index) => {
                          return (
                            <CRMTableRow key={index}>
                              <CRMTableCell>{index + 1}</CRMTableCell>
                              <CRMTableCell colSpan={2}>
                                {data?.Catategory_Name}
                              </CRMTableCell>
                              <CRMTableCell>
                                {data?.Category_Value == "True"
                                  ? "yes"
                                  : data?.Category_Value}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Score} % </CRMTableCell>
                            </CRMTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </JumboScrollbar>
                </Div>

                <Div sx={{ my: 1 }}>
                  <Typography sx={[dialogTitle]}>Firmo Graphic</Typography>
                  <JumboScrollbar
                    autoHeight={true}
                    autoHideTimeout={4000}
                    autoHeightMax={150}
                    autoHeightMin={50}
                    autoHide={true}
                    hideTracksWhenNotNeeded
                    id="no-more-tables"
                  >
                    <Table stickyHeader>
                      <TableHead sx={[CRMTableHead, FontStyle]}>
                        <CRMTableRow>
                          <CRMTableCell width={60}>SI.No</CRMTableCell>
                          <CRMTableCell>Graphics Name</CRMTableCell>
                          <CRMTableCell>Operator</CRMTableCell>
                          <CRMTableCell>Values</CRMTableCell>
                          <CRMTableCell>Score</CRMTableCell>
                        </CRMTableRow>
                      </TableHead>
                      <TableBody>
                        {detailsfirmoGraphics?.map((data, index) => {
                          return (
                            <CRMTableRow>
                              <CRMTableCell>{index + 1}</CRMTableCell>
                              <CRMTableCell>
                                {data?.Catategory_Name}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Operator}</CRMTableCell>
                              <CRMTableCell>
                                {data?.Category_Value == "True"
                                  ? "yes"
                                  : data?.Category_Value}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Score} % </CRMTableCell>
                            </CRMTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </JumboScrollbar>
                </Div>

                <Div sx={{ my: 1 }}>
                  <Typography sx={[dialogTitle]}>Behavior-Positive</Typography>
                  <JumboScrollbar
                    autoHeight={true}
                    autoHideTimeout={4000}
                    autoHeightMax={150}
                    autoHeightMin={50}
                    autoHide={true}
                    hideTracksWhenNotNeeded
                    id="no-more-tables"
                  >
                    <Table stickyHeader>
                      <TableHead sx={[CRMTableHead, FontStyle]}>
                        <CRMTableRow>
                          <CRMTableCell width={60}>SI.No</CRMTableCell>
                          <CRMTableCell>Graphics Name</CRMTableCell>
                          <CRMTableCell>Operator</CRMTableCell>
                          <CRMTableCell>Values</CRMTableCell>
                          <CRMTableCell>Score</CRMTableCell>
                        </CRMTableRow>
                      </TableHead>
                      <TableBody>
                        {detailspositiveGraphics?.map((data, index) => {
                          return (
                            <CRMTableRow>
                              <CRMTableCell>{index + 1}</CRMTableCell>
                              <CRMTableCell>
                                {data?.Catategory_Name}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Operator}</CRMTableCell>
                              <CRMTableCell>
                                {data?.Category_Value == "True"
                                  ? "yes"
                                  : data?.Category_Value}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Score} % </CRMTableCell>
                            </CRMTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </JumboScrollbar>
                </Div>

                <Div sx={{ my: 1 }}>
                  <Typography sx={[dialogTitle]}>Behavior-Negative</Typography>
                  <JumboScrollbar
                    autoHeight={true}
                    autoHideTimeout={4000}
                    autoHeightMax={150}
                    autoHeightMin={50}
                    autoHide={true}
                    hideTracksWhenNotNeeded
                    id="no-more-tables"
                  >
                    <Table stickyHeader>
                      <TableHead sx={[CRMTableHead, FontStyle]}>
                        <CRMTableRow>
                          <CRMTableCell width={60}>SI.No</CRMTableCell>
                          <CRMTableCell>Graphics Name</CRMTableCell>
                          <CRMTableCell>Operator</CRMTableCell>
                          <CRMTableCell>Values</CRMTableCell>
                          <CRMTableCell>Score</CRMTableCell>
                        </CRMTableRow>
                      </TableHead>
                      <TableBody>
                        {detailsnegativeGraphics?.map((data, index) => {
                          return (
                            <CRMTableRow>
                              <CRMTableCell>{index + 1}</CRMTableCell>
                              <CRMTableCell>
                                {data?.Catategory_Name}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Operator}</CRMTableCell>
                              <CRMTableCell>
                                {data?.Category_Value == "True"
                                  ? "yes"
                                  : data?.Category_Value}
                              </CRMTableCell>
                              <CRMTableCell>{data?.Score} % </CRMTableCell>
                            </CRMTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </JumboScrollbar>
                </Div>
              </Grid>

              <Grid item xs={12} md={12} sx={{ my: 1 }}>
                <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                  <Div>
                    <Typography sx={[dialogTitle]}>
                      Lead Scoring Activity
                    </Typography>
                  </Div>
                  <Div sx={{ my: 1 }}>
                    <CustomButton
                      label="Calculate Score"
                      onClick={UpdateLeadScoring}
                      Icon=""
                      width="130px"
                    />
                  </Div>
                </Div>
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    gap: "10px",
                  }}
                >
                  <Div>
                    <GaugeChart
                      id="gauge-chart1"
                      percent={datas?.LeadScore / 100}
                      textColor="black"
                    />
                  </Div>
                  <Div
                    sx={{
                      my: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={[
                        FontStyle,
                        { px: 2, py: 1, border: "1px solid lightgray" },
                      ]}
                    >
                      {datas?.LeadScore || 0}
                    </Typography>
                    <ArrowIndicator leadGrowth={datas?.LeadGrowth} />
                  </Div>
                </Div>
                <Div>
                  <Typography
                    sx={[
                      {
                        my: 1,
                        fontWeight: 600,
                        textAlign: "start",
                        fontSize: "13px",
                      },
                    ]}
                  >
                    Lead Scoring Weekly Performance
                  </Typography>
                  <Div sx={{ padding: 2, pt: 5 }}>
                    <ColumnBarChart
                      // height={(height * 3) / 2}
                      height={300}
                      labelShow={true}
                      color={["#4cc9f0"]}
                      barData={dataSource2}
                      barType="line"
                      endOnTick={false}
                    />
                  </Div>
                </Div>
              </Grid>
            </Grid>
            <Div sx={[DisplayFlex, { justifyContent: "center", my: 2 }]}>
              <Cancelbutton
                label="Cancel"
                onClick={() => {
                  setLeadOverview(false);
                }}
              />
            </Div>
          </DialogContent>
        </Dragabledialog>

        <Dragabledialog
          maxWidth="lg"
          open={openEdit}
          // onClose={() => setOpenAppointment(false)}
        >
          <EditDemo
            update={update}
            setOpenEdit={setOpenEdit}
            getDataList={getDataList}
            anchorElAction={anchorElAction}
            setAnchorElAction={setAnchorElAction}
            id={id}
          />
        </Dragabledialog>
      </JumboContentLayoutMain>

      {/*  demo delete popup */}
      <DialogBox
        open={closeDelete}
        Content={deletedWarnigMsg}
        onClickNo={() => setCloseDelete(false)}
        onClickYes={() => {
          setCloseDelete(false);
          Deletedata();
        }}
      />
    </>
  );
};

export default OverviewDealContact;
