import Div from "@jumbo/shared/Div";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";

const LinkedInSignIn = ({
  isOpenSignIn,
  setIsOpenSignIn,
  config,
  getIdFromConfiguredList,
}) => {
  const [open, setOpen] = useState(isOpenSignIn);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [showToken, setShowToken] = useState(false);
  const [userConfiguration, setUserConfiguration] = useState(config);
  const handleClose = () => {
    setIsOpenSignIn(false);
  };

  useEffect(() => {
    setUserConfiguration(config);
    setAccessToken(config?.Access_Token);
    setClientId(config?.Client_Id);
    setClientSecret(config?.Secret_Id);
  }, [config]);

  const handleSignIn = async () => {
    const token = localStorage.getItem("accesstoken");
    const payload = {
      Client_Id: clientId,
      Secret_Id: clientSecret,
      Access_Token: accessToken,
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      UserId: localStorage.getItem("UserId"),
    };
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .post(`${BASE_URL}/user/LinkeidnConfigurationCRUD/`, payload, {
        headers: headers,
      })
      .then((res) => {
        console.log("LinkedIn Sign in", res);
        if (res?.status === 200) {
          toast.success(res?.data);
          getIdFromConfiguredList();
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
    handleClose();
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("accesstoken");
    const payload = {
      Client_Id: clientId,
      Secret_Id: clientSecret,
      Access_Token: accessToken,
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      id: userConfiguration?.id,
      UserId: localStorage.getItem("UserId"),
    };
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .put(`${BASE_URL}/user/LinkeidnConfigurationCRUD/`, payload, {
        params: {
          id: userConfiguration?.id,
        },
        headers: headers,
      })
      .then((res) => {
        console.log("LinkedIn Sign in update", res);
        if (res?.status === 200) {
          toast.success(res?.data);
          getIdFromConfiguredList();
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data.details || "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
      });
  };

  // const handleDelete = () => {
  //     const token = localStorage.getItem("accesstoken")
  //     axios.delete(`${BASE_URL}/user/LinkeidnConfigurationCRUD/`, {
  //         params: {
  //             id: userConfiguration?.id
  //         }, headers: {
  //             "db": localStorage.getItem("DBName"),
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //         }
  //     }).then((res) => {
  //         console.log("delete configuration", res)
  //         if (res?.status === 200) {
  //             toast.success(res.data)
  //             getIdFromConfiguredList()
  //             handleClose()
  //         }
  //     })
  //         .catch((error) => {
  //             console.error("delete configuration error", error)
  //         })
  // }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { minWidth: "500px", minHeight: "300px" } }}
    >
      <DialogTitle className="dialog-head">Configuration</DialogTitle>
      <DialogContent sx={{ marginTop: "10px" }}>
        {console.log("UserCOnfiguration", userConfiguration)}
        {console.log("clientId", clientId)}
        {console.log("token", accessToken)}
        {console.log("clientSecret", clientSecret)}
        <Div>
          <Div className="row mt-3 ">
            <Div className="col-4">
              <Typography mb={0.3} sx={{ fontWeight: 600, fontSize: "14px" }}>
                Client Id
              </Typography>
            </Div>
            <Div className="col-6">
              <TextField
                value={clientId}
                type="text"
                onChange={(e) => {
                  setClientId(e.target.value);
                }}
              ></TextField>
            </Div>
          </Div>
          <Div className="row mt-3">
            <Div className="col-4">
              <Typography mb={0.3} sx={{ fontWeight: 600, fontSize: "14px" }}>
                Client Secret
              </Typography>
            </Div>
            <Div className="col-6">
              <TextField
                value={clientSecret}
                type={showPassword ? "text" : "password"}
                onChange={(e) => {
                  setClientSecret(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Div>
          </Div>
          <Div className="row mt-3">
            <Div className="col-4">
              <Typography mb={0.3} sx={{ fontWeight: 600, fontSize: "14px" }}>
                Token
              </Typography>
            </Div>
            <Div className="col-6">
              <TextField
                value={accessToken}
                type={showToken ? "text" : "password"}
                onChange={(e) => {
                  setAccessToken(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => {
                          setShowToken(!showToken);
                        }}
                      >
                        {showToken ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Div>
          </Div>
        </Div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button className="dialog-close-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Div sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* {userConfiguration && <Button className='dialog-save-btn' onClick={handleDelete}>Delete</Button>} */}
          <Button
            sx={{ marginLeft: "10px" }}
            className="dialog-save-btn"
            onClick={userConfiguration ? handleUpdate : handleSignIn}
          >
            {userConfiguration ? "update" : "Save"}
          </Button>
        </Div>
      </DialogActions>
    </Dialog>
  );
};

export default LinkedInSignIn;
