import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain'
import React from 'react'
import Feed from './Feed';
import Div from '@jumbo/shared/Div';
import LinkedInSignIn from './LinkedInSignIn';
import { useState } from 'react';
import { Button } from '@mui/material';
import { BASE_URL } from 'app/services/auth-services';
import axios from 'axios';
import { useEffect } from 'react';
import { UserId } from 'app/redux/auth/localStorage';

const LinkedInAPI = () => {

    const [userInfo, setUserInfo] = useState('')
    const [id, setId] = useState(`${localStorage.getItem("UserId")}`)
    const [userConfiguration, setUserConfiguration] = useState('')

    const getUserConfiguration = () => {
        let tok = localStorage.getItem("accesstoken")
        axios.get(`${BASE_URL}/user/LinkeidnConfigurationCRUD/`, {
            params: {
                UserId: id
            },
            headers: {
                "db": localStorage.getItem("DBName"),
                Authorization: `Bearer ${tok}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            }
        }).then((res) => {
            console.log("getUserCong response", res)
            setUserConfiguration(res?.data)
        })
            .catch((error) => {
                console.error("get user conf error", error)
            })
    }

    useEffect(() => {
        getUserConfiguration()
    }, [id])



    const getIdFromConfiguredList = () => {
        let tok = localStorage.getItem("accesstoken")
        const headers = {
            "db": localStorage.getItem("DBName"),
            Authorization: `Bearer ${tok}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        }
        axios.get(`${BASE_URL}/user/LinkeidnConfigurationAdditional/`, { headers })
            .then((res) => {
                console.log("get ConfiguredList response ", res?.data?.results)
                getUserConfiguration()
                if (res?.data?.results.length > 0) {
                    let results = res.data.results
                    results.map((item) => {
                        // setId(item?.id)
                    })
                }
                else {
                    setUserConfiguration("")
                }
            })
            .catch((error) => {
                console.error("Configured List response error", error)
            })
    }

    useEffect(() => {
        getIdFromConfiguredList()
    }, [])

    const getUserInfo = async () => {
        let token = localStorage.getItem("accesstoken")
        await axios.get(`${BASE_URL}/user/LinkdinCRUD/`, {
            params: {
                UserId: localStorage.getItem("UserId")
            },
            headers: {
                "db": localStorage.getItem("DBName"),
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            }
        })
            .then((res) => {
                console.log("UserInfo", res?.data)
                setUserInfo(res?.data)
            })
            .catch((error) => {
                console.error("user Info error", error)
            })
    }

    useEffect(() => {
        getUserInfo()
    }, [])

    const [isOpenSignIn, setIsOpenSignIn] = useState(false)

    const [token, setToken] = useState('')
    return (
        <JumboContentLayoutMain>
            {console.log("token", token)}
            {console.log("user configuration", userConfiguration)}
            {console.log("iddd", id)}
            <Div sx={{ backgroundColor: 'whitesmoke', width: '100%', height: '100%',paddingTop:"50px" }}>
                {/* {
                    token ? <Feed /> : <Div sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button onClick={() => { setIsOpenSignIn(true) }} variant='contained'>LinkedIn Configuration</Button></Div>
                } */}
                <Div sx={{ display: 'flex', justifyContent: 'center' }}><Feed usrInfo={userInfo} /></Div>
            </Div>
            {isOpenSignIn ? <LinkedInSignIn isOpenSignIn={isOpenSignIn} setIsOpenSignIn={setIsOpenSignIn} config={userConfiguration} getIdFromConfiguredList={getIdFromConfiguredList} /> : ''}
        </JumboContentLayoutMain>
    )
}

export default LinkedInAPI