import { useContext, useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { data } from "../data/data";

export const EmailsContext = createContext();

export const EmailsProvider = ({ children }) => {
  const [emails, setEmails] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [trash, setTrash] = useState([]);
  const [sidebarview, setSidebarView] = useState(true);

  useEffect(() => {
    setEmails(data);
  }, []);

  return (
    <EmailsContext.Provider
      value={{
        emails,
        setEmails,
        emailData,
        setEmailData,
        trash,
        setTrash,
        sidebarview,
        setSidebarView,
      }}
    >
      {children}
    </EmailsContext.Provider>
  );
};

export const useEmailContext = () => useContext(EmailsContext);
