import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import {
  leadGenerationList,
  unqualifiedLeads,
  unqualifiedLeadsEnquiry,
} from "../../mock/leadgeneration";
import JumboScrollbar from "@jumbo/components/JumboScrollbar/JumboScrollbar";
import JumboTableHead from "@jumbo/components/JumboTableHead/JumboTableHead";
import { filter } from "lodash";
import { CloseIcon } from "app/icons/PngIcons";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import JumboIconify from "@jumbo/components/JumboIconify/index";
import WifiCalling3RoundedIcon from "@mui/icons-material/WifiCalling3Rounded";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import Contact from "./lead generation/Contact";
import LeadList from "./LeadList";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadFromEnquiry from "./lead generation/LeadFromEnquiry";
import LeadFromSocialmedia from "./lead generation/LeadFromSocialmedia";
import LeadFromCampaign from "./lead generation/LeadFromCampaign";
import colors from "Colors";
import { TabContext, TabPanel } from "@mui/lab";
import {
  AntTab,
  AntTabs,
  FontStyle,
  componentTitle,
} from "../widgets/CRMStyles";
import Div from "@jumbo/shared/Div";
import "./Lead.css";

const TABLE_HEAD = [
  { id: "listId", label: "List Id", alignRight: false },
  { id: "leadListName", label: "Lead List Name", alignRight: false },
  { id: "nos", label: "Nos", alignRight: false },
  { id: "Geography", label: "Geography", alignRight: false },
  { id: "Industry", label: "Industry", alignRight: false },
];

const LeadList_TABLE_HEAD = [
  { id: "leadId", label: "Lead Id", alignRight: false },
  { id: "enqdate", label: "Enq. Date", alignRight: false },
  { id: "leadName", label: "Lead Name", alignRight: false },
  { id: "Email", label: "Email", alignRight: false },
  { id: "number", label: "Phone Number", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "Setting", label: <SettingsOutlinedIcon />, alignRight: false },
];

const LeadListEnquiry_TABLE_HEAD = [
  { id: "leadId", label: "Lead Id", alignRight: false },
  { id: "enqdate", label: "Enq. Date", alignRight: false },
  { id: "leadName", label: "Lead Name", alignRight: false },
  { id: "Email", label: "Email", alignRight: false },
  { id: "number", label: "Phone Number", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_contacts) =>
        _contacts.leadListName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const LeadGeneration = () => {
  const [activeButton, setActiveButton] = useState("web");
  const [open, setOpen] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState("");
  const [openCreateList, setOpenCreateList] = useState(false);
  const [leadlistName, setLeadListName] = useState("");
  const [openLeadListName, setOpenLeadListName] = useState(false);
  const [qualifiedquestionanswer, setQualifiedQuestionAnswer] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedValue, setSelectedValue] = useState("Yes");

  const [tabValue, setTabValue] = useState(0);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - leadGenerationList.length)
      : 0;

  const filteredContacts = applySortFilter(
    leadGenerationList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredContacts.length && !!filterName;

  const filteredLeadListName = applySortFilter(
    unqualifiedLeads,
    getComparator(order, orderBy),
    filterName
  );

  const filteredEnquiryLeadListName = applySortFilter(
    unqualifiedLeadsEnquiry,
    getComparator(order, orderBy),
    filterName
  );
  const handleLeadListClick = (value) => {
    setLeadListName(value);
    setOpenLeadListName(true);
  };

  const handleLeadListClickClose = () => {
    setLeadListName("");
    setOpenLeadListName(false);
  };
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <JumboContentLayoutMain>
      <Typography sx={[componentTitle]} id="heads">
        Lead Generation
      </Typography>

      <Box>
        {/* table tab */}
        <AntTabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
            console.log("newValue : ", newValue);
          }}
          aria-label="ant example"
        >
          {/* <AntTab label="Web" /> */}
          <AntTab label="Enquiry" />
          <AntTab label="Social Media" />
          <AntTab label="Contacts" />
          <AntTab label="Campaign" />
        </AntTabs>
      </Box>

      <Box>
        {/* Web tab */}
        {/* {tabValue === 0 && (
          <Div sx={{ padding: "0px !important" }}>
            <LeadList />
          </Div>
        )} */}

        {/* Enquiry tab */}
        {tabValue === 0 && (
          <Div sx={{ padding: "0px !important" }}>
            <LeadFromEnquiry />
          </Div>
        )}

        {/* Social Media tab */}
        {tabValue === 1 && (
          <Div sx={{ padding: "0px !important" }}>
            <LeadFromSocialmedia />
          </Div>
        )}

        {/* Contacts tab */}
        {tabValue === 2 && (
          <Div sx={{ padding: "0px !important" }}>
            <Contact />
          </Div>
        )}
        {/* Lead Fron Campaign tab */}
        {tabValue === 3 && (
          <Div sx={{ padding: "0px !important" }}>
            <LeadFromCampaign />
          </Div>
        )}
      </Box>

      {/* {activeButton === "enquiry" && (
        <>
          <LeadFromEnquiry />
        </>
      )}

      {activeButton === "socialMedia" && (
        <>
          <LeadFromSocialmedia />
        </>
      )}

      {activeButton === "contacts" && (
        <>
          <Contact />
        </>
      )}

      {activeButton === "leadFromCampaign" && <LeadFromCampaign />} */}

      {qualifiedquestionanswer && (
        <>
          <Stack direction={"column"}>
            <Typography variant="h3" sx={{ fontWeight: "900" }}>
              Qualify question & Answers
            </Typography>
            <Grid container mt={2}>
              <Grid
                item
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Is the prospect in the right industry to benefit from your
                  product and service
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Is territory is right to benefit from your product and service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Whether there is a need for your product or service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Is the prospect in the right industry to benefit from your
                  product and service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Is territory is right to benefit from your product and service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Whether there is a need for your product or service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Is the prospect in the right industry to benefit from your
                  product and service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Is territory is right to benefit from your product and service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  border: "1px solid",
                  textAlign: "center",
                  borderRadius: "20px",
                }}
              >
                <Typography m={1}>
                  Whether there is a need for your product or service
                </Typography>
              </Grid>
              <Grid
                item
                mt={1}
                md={6}
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="top"
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Stack direction={"row-reverse"} mr={{ md: 25 }} mt={2} spacing={2}>
              <Button
                variant="contained"
                onClick={() => setQualifiedQuestionAnswer(false)}
                sx={{
                  borderRadius: "10px !important",
                  backgroundColor: "#ddd",
                  color: "#000000",
                  "&:hover": {
                    fontWeight: "bold",
                    backgroundColor: "#ddd",
                    color: "#000000",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px !important",
                  backgroundColor: colors?.TableHeader,
                  "&:hover": {
                    fontWeight: "bold",
                    backgroundColor: colors?.TableHeader,
                  },
                }}
              >
                Qualify
              </Button>
            </Stack>
          </Stack>
        </>
      )}
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <JumboIconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: "error.main" }}>
          <JumboIconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        <MenuItem>Chat</MenuItem>
      </Popover>
    </JumboContentLayoutMain>
  );
};

export default LeadGeneration;
