import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Select,
} from "@mui/material";
import Setting from "../Setting";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { TableBody } from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { apiConfig } from "app/services/config";
import { CRMTableHead, ComponentHeading } from "app/pages/widgets/CRMStyles";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

const ForecastType = [{ Forecast_Type: "Open" }, { Forecast_Type: "Closed" }];

function DealProbability() {
  const [datas, setdatas] = useState([]);
  const [rows, setrows] = useState([]);

  const createrId = localStorage.getItem("UserId");

  const handleAddRow = () => {
    const newitem = {
      value: "",
      Probability: "",
      Forecast_Type: "",
      allow: true,
    };
    setrows([...rows, newitem]);
  };

  const handleAddChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setrows(updatedRows);
  };

  const handleRemoveRow = (index, data) => {
    handleDeleteProbability(data);
    setrows((oldValues) => {
      return oldValues.filter((_, i) => i !== index);
    });
  };

  const handlesalespersonchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setrows(list);
  };

  const DealProbabilityrecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/UserAdminDealProbabilityAdditional/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("probability", res.data.results);
        if (res.data.results.length !== 0) {
          let arr = res.data.results;
          let valueArr = [];

          arr.map((item, index) => {
            const newitem = {
              id: item.id,
              value: item.Stage_Name,
              Probability: item.Probability,
              Forecast_Type: item.Forecast_Type,
              allow: false,
            };
            valueArr.push(newitem);
          });
          console.log("map", valueArr);
          setrows(...rows, valueArr);

          // setrows({...rows,fromscore:res.data.ScoreRange[0]})
        } else {
          const newitem = {
            allow: true,
            value: "",
            Probability: "",
            Forecast_Type: "",
          };
          setrows([...rows, newitem]);
        }
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  const Lookuprecords = async () => {
    // axios.get(apiConfig.URL + "useradmin/Userlookuplist/", {
    await axios
      .get(`${BASE_URL}/useradmin/Userlookuplist/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((response) => {
        console.log(response.data);
        let arr = response.data;
        let list = [];
        arr.map((item, index) => {
          if (item.Lookupname === "Deal Probability") {
            list.push(item);
          }
        });
        setdatas(list[0].Values);
        console.log("Deal", list[0].Values);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const handlesavedeal = () => {
    console.log("Row", rows);
    let arr = [];
    let putarr = [];
    rows?.map((item1, index1) => {
      if (item1.id === undefined || item1.id === "" || item1.id === null) {
        arr.push(item1);
      } else {
        putarr.push(item1);
      }
    });
    console.log("post", arr);
    console.log("put", putarr);
    arr.map((item, ind) => {
      if (
        item.value === "" ||
        item.value === undefined ||
        item.value === null ||
        item.Probability === "" ||
        item.Probability === undefined ||
        item.Probability === null ||
        item.Probability > 100 ||
        item.Forecast_Type === "" ||
        item.Forecast_Type === undefined ||
        item.Forecast_Type === null
      ) {
        toast.error("Invalid Data");
      } else {
        let playload = {
          Stage_Name: item.value,
          Probability: item.Probability,
          Forecast_Type: item.Forecast_Type,
          Created_By: createrId,
          Updated_By: createrId,
        };
        axios
          .post(
            BASE_URL + "/useradmin/UserAdminDealProbabilityCRUD/",
            playload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                Db: localStorage.getItem("DBName"),
              },
            }
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.id !== undefined) {
              toast.success(" Created Successfully");
              // Predictionrecords()
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    });

    putarr.map((item, ind) => {
      if (
        item.value === "" ||
        item.value === undefined ||
        item.value === null ||
        item.Probability === "" ||
        item.Probability === undefined ||
        item.Probability === null ||
        item.Probability > 100 ||
        item.Forecast_Type === "" ||
        item.Forecast_Type === undefined ||
        item.Forecast_Type === null ||
        item.id === "" ||
        item.id === undefined ||
        item.id === null
      ) {
        toast.error("Invalid Data");
      } else {
        let playload = {
          id: item.id,
          Stage_Name: item.value,
          Probability: item.Probability,
          Forecast_Type: item.Forecast_Type,
          Created_By: createrId,
          Updated_By: createrId,
        };
        axios
          .put(
            `${BASE_URL}/useradmin/UserAdminDealProbabilityCRUD/?id=${item.id}`,
            playload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                Db: localStorage.getItem("DBName"),
              },
            }
          )
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    });
  };

  const handleDeleteProbability = (data) => {
    console.log(data.id);
    if (data.id !== undefined) {
      axios
        .delete(
          `${BASE_URL}/useradmin/UserAdminDealProbabilityCRUD/?id=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              db: localStorage.getItem("DBName"),
            },
          }
        )
        .then((res) => {
          console.log("post", res.data);
          if (res.data === "Deleted Successfully") {
            toast.success(res.data);
            // Predictionrecords()
          } else {
            toast.error("Something went wrong");
          }
          // setdatas(res.data.results)
        })
        .catch((error) => {
          console.log("ds", error);
        });
    }
  };

  useEffect(() => {
    Lookuprecords();
    DealProbabilityrecords();
  }, []);

  return (
    <>
      <JumboContentLayoutMain>
        <Setting />
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          Deal Probability
        </Typography>
        <Div
          sx={{
            display: "flex",
            justifyContent: "end",
            mt: -3,
            mr: 0.5,
            height: "40px",
          }}
        ></Div>
        <Div sx={{ m: 1, ml: 0, mr: 0, p: 2, minHeight: "366px" }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={"45vh"}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader className="">
              <TableHead sx={[CRMTableHead]}>
                <TableRow>
                  <TableCell>Stage Name</TableCell>
                  <TableCell>Probability %</TableCell>
                  <TableCell>Forecast Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length > 0 &&
                  rows?.map((item, index) => {
                    // console.log("rows",datas)
                    return (
                      <TableRow key={index} hover>
                        <TableCell data-title="Stage Name">
                          <FormControl className="col-sm-12 ">
                            <Select
                              value={item.value}
                              sx={{ height: "35px", width: "190px" }}
                              className="react-select-box"
                              name="value"
                              onChange={(e) =>
                                handlesalespersonchange(e, index)
                              }
                            >
                              {datas?.length > 0 &&
                                datas?.map((ele, index) => (
                                  <MenuItem value={ele?.value}>
                                    {ele?.value}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell data-title="Probability %">
                          <TextField
                            sx={{ height: "35px", width: "190px" }}
                            className="col-md-5 input-box2"
                            id="outlined-basic"
                            variant="outlined"
                            autoComplete="off"
                            name="Probability"
                            value={item.Probability}
                            onChange={(e) => {
                              handleAddChange(index, e);
                            }}
                            placeholder="Probability %"
                            // disabled={data.allow}
                          />
                        </TableCell>

                        <TableCell data-title="Forecast Type">
                          <FormControl className="col-sm-12 ">
                            <Select
                              name="Forecast_Type"
                              sx={{ height: "35px", width: "190px" }}
                              value={item.Forecast_Type}
                              className="col-md-12 input-select"
                              onChange={(e) =>
                                handlesalespersonchange(e, index)
                              }
                            >
                              {ForecastType &&
                                ForecastType.map((item, index) => {
                                  return (
                                    <MenuItem
                                      key={`item-${item.id}-${index}`}
                                      value={item.Forecast_Type}
                                    >
                                      {item.Forecast_Type}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell data-title="Action">
                          <Div>
                            <Button
                              color="error"
                              onClick={(e) => handleRemoveRow(index, item)}
                            >
                              <MdDelete size={24} />
                            </Button>
                          </Div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar>
          <Div sx={{ mt: 2, display: "flex", justifyContent: "flex-start" }}>
            <Button sx={{ color: "#287EFF" }} onClick={handleAddRow}>
              + Add More Lines
            </Button>
          </Div>

          <Div
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <CustomButton
              onClick={handlesavedeal}
              label="Save"
              Icon="saveIcon"
              sx={{ mr: 1 }}
            />
            <Cancelbutton
              label="Cancel"
              // onClick={handleClickBack}
            />
          </Div>
        </Div>
      </JumboContentLayoutMain>
    </>
  );
}

export default DealProbability;
