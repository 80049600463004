import React, { useContext } from "react";
import { EmailsContext, useEmailContext } from "../../Context/EmailsContext";
import "./Sidebar.css";

import { sidebarItems } from "./sidebardata";
import SidebarMenu from "../Utils/Sidebarmenu/SidebarMenu";

const Sidebar = () => {
  const { sidebarview } = useEmailContext();

  return (
    <div
      className={`sidebar `}
      style={{
        transform: sidebarview ? "translateX(0%)" : "translateX(-100%)",
        opacity: sidebarview ? 1 : 0,
      }}
    >
      <SidebarMenu data={sidebarItems} />
    </div>
  );
};

export default Sidebar;
