import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Select,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import Setting from "../Setting";
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { TableBody } from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import { apiConfig } from "app/services/config";
import {
  ButtonStyle,
  CRMTableHead,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

const ClientEngagement = [{ Value: "Yes" }, { Value: "No" }];

function DealWeightsetup() {
  const [rows, setrows] = useState([]);
  const [rows1, setrows1] = useState([
    {
      Weight: "Client Engagement",
      Points: "",
      id: "",
    },
    {
      Weight: "Budget Alignment",
      Points: "",
      id: "",
    },
    {
      Weight: "Decision Maker",
      Points: "",
      id: "",
    },
    {
      Weight: "Unique Selling Point",
      Points: "",
      id: "",
    },
    {
      Weight: "Market Condition",
      Points: "",
      id: "",
    },
    {
      Weight: "ROI & Value proposition",
      Points: "",
      id: "",
    },
    {
      Weight: "Post Sales Support",
      Points: "",
      id: "",
    },
  ]);
  const createrId = localStorage.getItem("UserId");

  const [datas, setdatas] = useState([]);

  const handleAddChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setrows(updatedRows);
  };

  useEffect(() => {
    DealWeightsetuprecords();
  }, []);

  const DealWeightsetuprecords = () => {
    axios
      .get(`${BASE_URL}/useradmin/UserAdminDealWeightSetupAdditional/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      })
      .then((res) => {
        console.log("probability", res.data.results);
        setdatas(res.data.results);
        let response = res.data.results;
        response.map((item, index) => {
          rows1.map((ele, ind) => {
            if (item.Weight === ele.Weight) {
              ele.Points = item.Points;
              ele.id = item.id;
            }
          });
        });

        console.log("rows", rows1);
        setrows(rows1);
      })
      .catch((error) => {
        console.log("ds", error);
      });
  };

  const handlesaveweight = (e) => {
    e.preventDefault();
    let count = 0;
    var total = 0;
    console.log("rows", rows);
    rows.map((item, index) => {
      if (
        item.Points === null ||
        item.Points === undefined ||
        item.Points === ""
      ) {
        count++;
      } else {
        if (item.Points !== "") {
          total = total + parseFloat(item.Points);
        }
      }
    });
    if (count !== 0) {
      // console.log("total",total)
      toast.error("Enter Valid Points");
    } else {
      console.log("total", total);
      if (total < 1) {
        toast.success("sucess");
        let postarr = [];
        let putarr = [];
        rows.map((item, index) => {
          if (item.id === "" || item.id === null || item.id === undefined) {
            postarr.push(item);
          } else {
            putarr.push(item);
          }
        });
        console.log("postarr", postarr);
        console.log("putarr", putarr);
        postarr.map((item, ind) => {
          if (
            item.Points !== "" &&
            item.Points !== null &&
            item.Points !== undefined
          ) {
            let playload = {
              Weight: item.Weight,
              Points: item.Points,
              values: "",
              Created_By: createrId,
              Updated_By: createrId,
            };
            axios
              .post(
                BASE_URL + "/useradmin/UserAdminDealWeightSetupCRUD/",
                playload,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "accesstoken"
                    )}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Db: localStorage.getItem("DBName"),
                  },
                }
              )
              .then((response) => {
                console.log(response.data);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {});
          }
        });

        putarr.map((item, index) => {
          if (
            item.Points !== "" ||
            item.Points !== null ||
            item.Points !== undefined ||
            item.Points !== " " ||
            item.Weight !== "" ||
            item.Weight !== null ||
            item.Weight !== undefined
          ) {
            let playload = {
              id: item.id,
              Weight: item.Weight,
              Points: item.Points,
              values: "",
              Created_By: createrId,
              Updated_By: createrId,
            };
            axios
              .put(
                `${BASE_URL}/useradmin/UserAdminDealWeightSetupCRUD/?id=${item.id}`,
                playload,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "accesstoken"
                    )}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    db: localStorage.getItem("DBName"),
                  },
                }
              )
              .then((res) => {
                console.log("put", res.data);
                // if(res.data.id === data.id){
                //   toast.success("Updated Successfully");
                //   // Predictionrecords()
                // }else{
                //   toast.error("Something went wrong");
                // }
                // setdatas(res.data.results)
              })
              .catch((error) => {
                console.log("ds", error);
              });
          } else {
            toast.error("Enter Valid Points");
          }
        });
      } else {
        toast.error("Total shall be 1");
      }
    }
  };

  return (
    <>
      <JumboContentLayoutMain>
        <Setting />
        <Typography sx={[ComponentHeading]}>Deal Weight Setup</Typography>
        <form>
          <div>
            <Div
              sx={{
                display: "flex",
                justifyContent: "end",
                mt: -3,
                mr: 0.5,
                height: "40px",
              }}
            ></Div>
            <div>
              <Div sx={{ m: 1, ml: 0, mr: 0, p: 2, minHeight: "366px" }}>
                <Div sx={{ display: "block" }}>
                  <form>
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMin={"55vh"}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                      id="no-more-tables"
                    >
                      <TableContainer>
                        <Table stickyHeader className="table" sx={{ mt: 2 }}>
                          <TableHead sx={[CRMTableHead, FontStyle]}>
                            <CRMTableRow>
                              <CRMTableCell>Weight</CRMTableCell>
                              <CRMTableCell>Points Name</CRMTableCell>
                            </CRMTableRow>
                          </TableHead>

                          <TableBody>
                            {rows.length > 0 &&
                              rows.map((item, index) => {
                                return (
                                  <CRMTableRow key={index}>
                                    <CRMTableCell>
                                      <TextField
                                        sx={{
                                          height: "35px",
                                          width: "290px",
                                          color: "#000000",
                                          mt: 1,
                                        }}
                                        className="col-md-5 input-box"
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        value={item.Weight}
                                        disabled={true}
                                      />
                                    </CRMTableCell>

                                    <CRMTableCell data-title="Points">
                                      <TextField
                                        sx={{ height: "35px", width: "190px" }}
                                        className="col-md-5 input-box"
                                        id="outlined-basic"
                                        variant="outlined"
                                        autoComplete="off"
                                        name="Points"
                                        value={item.Points}
                                        onChange={(e) => {
                                          handleAddChange(index, e);
                                        }}
                                      />
                                    </CRMTableCell>

                                    {/* <TableCell data-title="Action">
                                  <Div >
                                    

                                    <Button
                                      color="error"
                                    //   onClick={(e) => handleRemoveRow(index,data)}

                                    >
                                      <MdDelete size={24} />
                                    </Button>
                                  </Div>
                                </TableCell> */}
                                  </CRMTableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </JumboScrollbar>
                    <Div>
                      <Div sx={{ mt: 2, ml: 4, color: "#F22323" }}>
                        * Total shall be 1
                      </Div>
                    </Div>

                    <Div
                      sx={{
                        mt: 0,
                        justifyContent: "end",
                        alignItems: "center",
                        display: "flex",
                        mr: 5,
                        mb: 4,
                      }}
                    >
                      <CustomButton
                        onClick={handlesaveweight}
                        label="Save"
                        type="submit"
                        Icon="saveIcon"
                        sx={{ mr: 1 }}
                      />
                      <Cancelbutton label="Cancel" />
                    </Div>
                  </form>
                </Div>
              </Div>
            </div>
          </div>
        </form>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>
  );
}

export default DealWeightsetup;
