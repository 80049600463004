import React, { useEffect, useState } from "react";
import EditWorkFlowSetup from "./EditWorkflow/EditWorkFlowSetup";
import { useSelector } from "react-redux";
import { selectUserRolePermissions } from "app/redux/actions/fetchUserRolePermissions";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { useQuery } from "react-query";
import {
  CustomButton,
  DeletedIcon,
  DialogBox,
  EditIcon,
} from "../widgets/StylesComponents";
import {
  Colors,
  componentTitle,
  deletedWarnigMsg,
  DisplayFlex,
} from "../widgets/CRMStyles";
import { Box, Pagination, Table, TableBody, Typography } from "@mui/material";
import SearchBar from "../widgets/SearchField";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { AutoHeight } from "app/redux/auth/localStorage";
import CustomTableHeader from "../widgets/CustomTableHeader";
import { CRMTableCell, CRMTableRow } from "../widgets/CRMTable";
import Div from "@jumbo/shared/Div";
import { red } from "@mui/material/colors";
import WorkFlowsetup from "./CreateWorkflow/WorkFlowsetup";

const ListWorkflowRule = () => {
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const { userRolePermissions, formPermissions, modulePermissions } =
    useSelector(selectUserRolePermissions);

  const [datas, setDatas] = useState([]);
  const [showlist, setShowList] = useState(true);
  const [showcreate, setShowCreate] = useState(false);
  const [showedits, setShowEdits] = useState(false);
  const [deleteRow, setdeleteRow] = useState(false);
  const [open, setOpen] = useState(null);

  const [update, setUpdate] = useState({});
  const [selectedRow, setSelectedRow] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [colums, SetColums] = useState({
    CategoriesID: true,
    CategoriesName: true,
    Description: true,
  });

  // Checking the Pagination
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalRecords = datas.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  // For Table Head
  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Module", label: "Module", bool: true, width: "130px" },
    { sort: "Rule_Name", label: "Rule Name", bool: true, width: "150px" },
    { sort: "Description", label: "Description", bool: true },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDatas(newPage);
  };

  // Search Functionality

  const settingOptions = (bool) => {
    setAnchorEl(bool);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  // delete function
  const deleteItem = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/user/WorkFlowRuleCRUD/?id=${selectedRow?.id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response) {
        toast.success(response?.data, {
          position: "top-right",
          autoClose: 100,
        });

        refetchgetDataslists();
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // Search Functionality

  const SearchData = (e) => {
    let data = e.target.value;
    data !== "" ? handleSearch(data) : getDatas(page);
  };

  const handleSearch = (search) => {
    console.log("search : ", search);
    axios
      .get(`${BASE_URL}/user/WorkFlowRuleSearch/?search=${search}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        //  console.log(res.data);
        // alert('vinoth')
        setDatas(res?.data);
      })
      .catch((error) => {});
  };

  // get all datas list
  const getDatas = async (page) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/WorkFlowRuleAdditional/?page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const {
    data: getDataslists,
    isError: isErrorgetDataslists,
    isLoading: isLoadinggetDataslists,
    refetch: refetchgetDataslists,
  } = useQuery(["getgetDataslists", page], () => getDatas(page), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (Boolean(getDataslists)) {
      if (getDataslists?.results?.length > 0) {
        setCount(getDataslists?.count);
        setDatas(getDataslists?.results);
      }
      //   setOpen(false);
      //   setShowCreate(false);
      //   setActiveStep(0);
      //   setruleStep(0);
    }
  }, [getDataslists]);

  return (
    <JumboContentLayoutMain>
      <Div
       sx={{ display: showlist == true ? "block" : "none" }}
      >
        <Typography
          id="Typography-root"
          sx={[componentTitle]}
          // sx={[componentTitle, { transform: "translateY(-20px)" }]}
        >
          Work Flow
        </Typography>

        <Box
          sx={[
            DisplayFlex,
            { justifyContent: { md: "right" }, flexWrap: "wrap" },
          ]}
        >
          <Box sx={[DisplayFlex, { bgcolor: "#fff", gap: "10px" }]}>
            <SearchBar
              width={"250px !important"}
              border={"1px solid lightgray"}
              SearchData={(e) => SearchData(e)}
              // SearchData={(e)=>setSearchValue(e.target.value)}
            />
            {/* {Array.isArray(userRolePermissions) &&
              userRolePermissions.includes(1113) && ( */}
                <CustomButton
                  label="Create"
                  Icon="createIcon"
                  onClick={() => {
                    setShowCreate(true);
                    setShowList(false);
                  }}
                />
               {/* )} */}
          </Box>
        </Box>

        <Box sx={{ mt: 2 }}>
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={AutoHeight(1.6)}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader>
              <CustomTableHeader
                headerMenuItems={columnMenuItems}
                setHeadermenuItems={setColumnMenuItems}
                sortDatas={datas}
                setDatas={setDatas}
              />
              <TableBody>
                {datas?.length > 0 &&
                  datas?.map((row, idx) => {
                    const { Module, Rule_Name, Description } = row;
                    return (
                      <CRMTableRow key={idx}>
                        <CRMTableCell data-title="Module">
                          {Module}
                        </CRMTableCell>
                        <CRMTableCell data-title="Rule Name">
                          {Rule_Name}
                        </CRMTableCell>
                        <CRMTableCell data-title="Description">
                          {Description}
                        </CRMTableCell>

                        <CRMTableCell
                          align="center"
                          data-title="Action"
                          sx={[
                            DisplayFlex,
                            {
                              cursor: "pointer",
                              mb: 1,
                              justifyContent: { xs: "left", md: "center" },
                            },
                          ]}
                        >
                          <EditIcon
                            onClick={() => {
                              setSelectedRow(update);
                              // setAddField({
                              //     id: row?.id,
                              //     CategoryId: row?.ProductCategory_Id,
                              //     CategoryName: row?.Category_Name,
                              //     Description: row.Category_Description,
                              // });
                              setUpdate(row);
                              setShowList(false);
                              setShowEdits(true);
                              setOpen(null);
                            }}
                            color={
                              Array.isArray(userRolePermissions) &&
                              userRolePermissions.includes(1114)
                                ? Colors?.Mblue
                                : "gray"
                            }
                            // disabled={
                            //   Array.isArray(userRolePermissions) &&
                            //   userRolePermissions.includes(1114)
                            //     ? false
                            //     : true
                            // }
                          />
                          <DeletedIcon
                            onClick={() => {
                              // setSelectedRow(update);
                              setSelectedRow(row);
                              setdeleteRow(true);
                            }}
                            color={
                              Array.isArray(userRolePermissions) &&
                              userRolePermissions.includes(1115)
                                ? red[400]
                                : "gray"
                            }
                            // disabled={
                            //   Array.isArray(userRolePermissions) &&
                            //   userRolePermissions.includes(1115)
                            //     ? false
                            //     : true
                            // }
                          />
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar>

          {/* pagination */}
          <Div
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {count > 10 && (
              <Pagination
                color="success"
                count={Math?.ceil(count / 10)}
                page={page}
                onChange={(e, newValue) => handleChangePage(e, newValue)}
                size="small"
                showFirstButton
                showLastButton
              />
            )}
          </Div>
        </Box>
      </Div>

      {/* delete dialog box */}
      <DialogBox
        open={deleteRow}
        Content={deletedWarnigMsg}
        onClickNo={() => {
          setdeleteRow(false);
        }}
        onClickYes={() => {
          deleteItem();
          setdeleteRow(false);
        }}
      />

      {showcreate && (
        <WorkFlowsetup
          setShowCreate={setShowCreate}
          setShowList={setShowList}
          getDatas={getDatas}
          page={page}
        />
      )}

      {showedits && (
        <EditWorkFlowSetup
          showedits={showedits}
          setShowEdits={setShowEdits}
          setShowList={setShowList}
          update={update}
          getDatas={refetchgetDataslists}
          page={page}
        />
      )}
    </JumboContentLayoutMain>
  );
};

export default ListWorkflowRule;
