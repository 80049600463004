import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ar";
import "moment/locale/en-gb";
import "moment/locale/en-in";

// import {calendarData, cultures} from "./data";
import Typography from "@mui/material/Typography";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import { HiOutlineSearch } from "react-icons/hi";
import { calendarData } from "app/pages/calendars/data";
import CalendarWrapper from "app/pages/calendars/CalendarWrapper";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid";
import EventPopOver from "./EventPopOver";
import useStateRef from "react-usestateref";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import "./CalendarView.css";

const CalendarView = () => {
  //GCAPI
  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const gapi = window.gapi;
  const google = window.google;

  //   const CLIENT_ID = process.env.REACT_APP_CALENDAR_CLIENT_ID;
  //   const API_KEY = process.env.REACT_APP_CALENDAR_API_KEY;

  //   const [clientid, setCLIENT_ID,CLIENT_ID] = useStateRef("");
  //   const [apikey, setAPI_KEY,API_KEY] = useStateRef("");

  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
  const SCOPES = "https://www.googleapis.com/auth/calendar";

  const [eventsArray, setEventsArray] = useState([]); //from Google Events
  const [anchorEl, setAnchorEl] = useState(null);
  const [tokClient, setTokClient, tokClientRef] = useStateRef("");
  const [accTok, setAccTok, accTokRef] = useStateRef(
    localStorage.getItem("access_token")
  );
  const [expIn, setExpIn, expInRef] = useStateRef(
    localStorage.getItem("expires_in")
  );

  const [mergedEvents, setMergedEvents] = useState([]);

  let gapiInited = false;
  let gisInited = false;

  const getCalendarData = () => {
    axios
      .get(
        `${BASE_URL}/user/GoogleCalendarConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        header
      )
      .then((res) => {
        console.log(res.data);
        if (res) {
          console.log("insie", res?.data?.Client_Id, res?.data?.API_Key);
          gisLoaded(res?.data?.Client_Id, res?.data?.API_Key);
          gapiLoaded(res?.data?.Client_Id, res?.data?.API_Key);
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  useEffect(() => {
    getCalendarData();
  }, []);

  const gapiLoaded = (CLIENT_ID, API_KEY) => {
    gapi.load("client", initializeGapiClient(API_KEY));
  };

  const initializeGapiClient = async (API_KEY) => {
    try {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
      gapiInited = true;

      if (accTokRef.current && expInRef.current) {
        gapi.client.setToken({
          access_token: accTokRef.current,
          expires_in: expInRef.current,
        });
        listUpcomingEvents();
      }
    } catch (error) {
      console.error("Error initializing gapi client:", error);
    }
  };

  const gisLoaded = (CLIENT_ID, API_KEY) => {
    let tokenClient;
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (response) => {
        console.log("response", response);
      },
    });
    setTokClient(tokenClient);
    gisInited = true;
  };

  const handleAuthClick = () => {
    console.log("tokenClientRef", tokClientRef.current);
    tokClientRef.current.callback = async (resp) => {
      if (resp.error) {
        throw resp;
      }
      await listUpcomingEvents();
      const { access_token, expires_in } = gapi.client.getToken();
      setAccTok(access_token);
      localStorage.setItem("access_token", access_token);
      setExpIn(expires_in);
      localStorage.setItem("expires_in", expires_in);
    };

    if (!(accTokRef.current && expInRef.current)) {
      tokClientRef.current.requestAccessToken({ prompt: "consent" });
    } else {
      tokClientRef.current.requestAccessToken({ prompt: "" });
    }
  };

  const handleSignoutClick = () => {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken("");
      // localStorage.clear();
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_in");
      window.location.reload();
    }
  };

  const formatDateToYYYYMMDD = (originalDateString) => {
    // Parse the original date string into a JavaScript Date object
    const originalDate = new Date(originalDateString);

    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
    const day = originalDate.getDate().toString().padStart(2, "0");

    const formattedDateString = `${year}-${month}-${day}`;
    return formattedDateString;
  };

  const listUpcomingEvents = async () => {
    let response;
    try {
      const request = {
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      };
      response = await gapi.client.calendar.events.list(request);
    } catch (err) {
      console.error("error", err);
      return;
    }

    const events = response.result.items;
    const convertedResponseItem = events.map((item) => {
      console.log("item.start", item.start);
      return {
        id: item.id,
        title: item.summary,
        date: item.start.dateTime,
        time: item.start.dateTime,
        location: item.location,
        description: item.description,
        remainders: item.attendees,
        start: formatDateToYYYYMMDD(item.start.dateTime),
        end: formatDateToYYYYMMDD(item.end.dateTime),
        isDeleted: item.Is_Deleted,
        createdDate: item.Created_Date,
        updatedDate: item.Updated_Date,
        organizationId: item.Organization_Id,
        createdBy: item.Created_By,
        updatedBy: item.Updated_By,
      };
    });
    setEventsArray(convertedResponseItem);
  };

  //
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDate = today.getDate();
  // const { events } = calendarData;

  // const [events, setEvents, eventsRef] = useStateRef(calendarData.events)
  const [events, setEvents, eventsRef] = useStateRef([]); //from CRM DB
  const [updatedEvents, setUpdatedEvents] = useState();
  const localizer = momentLocalizer(moment);
  const { t } = useTranslation();
  const [culture, setCulture] = useState("en");
  const [value, setValue] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [startDateObject, setStartDateObject] = useState();
  const [endDateObject, setEndDateObject] = useState();
  const [selectedDateFromCalendar, setSelectedDateFromCalendar] =
    useState(null);
  const [toggleSave, setToggleSave] = useState(false);
  const [isEditingMode, setIsEditingMode, isEditingModeRef] =
    useStateRef(false);
  const [viewEvent, setViewEvent, viewEventRef] = useStateRef(false);

  const handleSelectEvent = (e) => {
    setViewEvent(true);
    if (viewEventRef.current) {
      setIsEditingMode(false);
    }
    setSelectedEvent(e);
    setAnchorEl(e.currentTarget);
    setIsOpen(true);
  };
  const handleSelectSlot = (slotInfo) => {
    if (selectedDateFromCalendar) {
      setStartDateObject(selectedDateFromCalendar);
    } else {
      setStartDateObject(slotInfo.start);
      setEndDateObject(slotInfo.end);
    }

    setIsOpen(true);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    setIsOpen(false);
    setSelectedEvent(null);
    setIsEditingMode(false);
  };
  const getToggleSaveStatus = (toggleStatus) => {
    setToggleSave(toggleStatus);
  };

  const fetchInitialData = async () => {
    let actionUrl = "user/UserTaskCalendarAdditional/";
    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const APIURL = `${BASE_URL}/${actionUrl}`;
    const params = {
      Is_Deleted: "False",
    };
    await axios
      .get(APIURL, {
        params: params,
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const convertedData =
          res.data &&
          res.data.map((item) => {
            const inputStartDate = item.Start_Date;
            // const [startYear, startMonth, startDay] = inputStartDate.split("-").map(Number);
            // const startDate = new Date(startYear, startMonth - 1, startDay);
            const inputEndDate = item.End_Date;
            // const [endYear, endMonth, endDay] = inputEndDate.split("-").map(Number);
            // const endDate = new Date(endYear, endMonth - 1, endDay);
            return {
              id: item.id,
              title: item.summary,
              date: item.Created_Date,
              location: item.location,
              description: item.Description,
              remainders: item.attendees,
              start: item.start,
              end: item.end,
              isDeleted: item.Is_Deleted,
              createdDate: item.Created_Date,
              updatedDate: item.Updated_Date,
              organizationId: item.Organization_Id,
              createdBy: item.Created_By,
              updatedBy: item.Updated_By,
            };
          });
        setEvents(convertedData);
      })
      .catch((error) => {
        console.log("ErrorFetchingData", error);
      });
  };

  useEffect(() => {
    fetchInitialData();
    listUpcomingEvents();
  }, [toggleSave]);

  useEffect(() => {
    setMergedEvents([...events, ...eventsArray]);
  }, [events, eventsArray, toggleSave]);

  return (
    <JumboContentLayoutMain>
      <Div>
        {console.log("mergedEvents", mergedEvents)}
        {console.log("eventsArray", eventsArray)}
        <div>
          {!accTokRef.current && !expInRef.current ? (
            <div>
              {" "}
              <Button
                id="authorize_button"
                onClick={handleAuthClick}
                variant="contained"
              >
                Sign in to access Google Calendar
              </Button>
            </div>
          ) : (
            <Div>
              <JumboContentLayoutMain>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Typography variant="h2" sx={{ fontWeight: 600, mb: 2 }}>
                      Event Calendar View
                    </Typography>
                  </div>
                  <div className="justify-content-end">
                    <Button
                      id="signout_button"
                      onClick={handleSignoutClick}
                      variant="contained"
                    >
                      Sign Out
                    </Button>
                  </div>
                </div>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            setSelectedDateFromCalendar(newValue);
                            setIsOpen(true);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <CalendarWrapper>
                      <Calendar
                        localizer={localizer}
                        events={mergedEvents}
                        step={60}
                        defaultDate={
                          new Date(currentYear, currentMonth, currentDate)
                        }
                        style={{ height: 600 }}
                        onSelectEvent={(e) => {
                          handleSelectEvent(e);
                        }}
                        onSelectSlot={handleSelectSlot}
                        selectable={true}
                      />
                      <EventPopOver
                        anchorEl={anchorEl}
                        onClose={handleClosePopover}
                        event={selectedEvent}
                        open={isOpen}
                        events={events}
                        startDateObject={selectedDateFromCalendar}
                        endDateObject={selectedDateFromCalendar}
                        toggleSave={toggleSave}
                        sendToggleSaveStatus={getToggleSaveStatus}
                        isEditingMode={isEditingModeRef.current}
                      ></EventPopOver>
                    </CalendarWrapper>
                  </Grid>
                </Grid>
              </JumboContentLayoutMain>
            </Div>
          )}
        </div>
      </Div>
    </JumboContentLayoutMain>
  );
};

export default CalendarView;
