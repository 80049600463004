import React, { useCallback, useEffect, useState } from "react";
import Div from "@jumbo/shared/Div";
import { Card, Grid, Typography, Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import {
  avatarColors,
  ButtonStyle,
  cardHeight,
  componentTitle,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { IoIosMailOpen, IoIosSend } from "react-icons/io";
import { FaMailBulk } from "react-icons/fa";
import { GiClick } from "react-icons/gi";
import { PiEnvelopeOpenFill, PiHandTapFill } from "react-icons/pi";
import { RiMailCloseFill } from "react-icons/ri";
import Counter, { CustomButton, NoRecordsPage } from "app/pages/widgets/StylesComponents";

import PieChart, {
  Series,
  Label,
  Connector,
  Legend,
  Export,
} from "devextreme-react/pie-chart";
import { useDrawingArea } from "@mui/x-charts";
import { AutoHeight } from "app/redux/auth/localStorage";
import { useQuery, useQueryClient } from 'react-query';
import moment from "moment";
import PiecChartRount from "./PiecChartRount";



const datatable = [
  {
    cid: "001",
    cname: "Campaign Name 1",
    cdate: "Drafted on Mar 30,2023 11.30Am",
    recipients: "",
    openers: "",
    clickers: "",
    unsub: "",
    status: "draft",
    arch: false,
  },
];

function customizeText(arg) {
  return `${arg.valueText}%`;
}

const StatisticsRightCardList = () => {
  // Get the Statistics Report

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const [startdate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [enddate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [ratesPct, setRatesPct] = useState([{ label: 'Opened Rate', value: 0.00 },
  { label: 'Clicked Rate', value: 0.00 },
  { label: 'Not Opened Rate', value: 0.00 }]);


  const [datas, setDatas] = useState([
    { value: 0, label: "Total Sent" },
    { value: 0, label: "Total Recipients" },
    { value: 0, label: "Total Clicked" },
    { value: 0, label: "Total Opened" },
  ]);
  const queryClient = useQueryClient()

  const handleSubmit = async (e) => {
    e.preventDefault();
    getStatiticsListsRefetch()
    queryClient.invalidateQueries("getStatiticsLists")
  }

  const getData = async () => {
    if (!startdate || !enddate) {
      // If startdate or enddate is missing, exit early
      return;
    }
    let list = [...datas];
    let listRates = [...ratesPct];

    try {
      var payload = {
        Sent_Date: [startdate, enddate],
      };

      const [response, response1, response2, response3] = await Promise.all([
        axios.post(`${BASE_URL}/user/UserCampaignStatics/`, payload, { headers: { db: item, Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" } }),
        axios.put(`${BASE_URL}/user/UserCampaignStatics/`, payload, { headers: { db: item, Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" } }),
        axios.put(`${BASE_URL}/user/UserCampaignStatics1/`, payload, { headers: { db: item, Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" } }),
        axios.post(`${BASE_URL}/user/UserCampaignStatics2/`, payload, { headers: { db: item, Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" } })
      ]);
      listRates[0].value = parseFloat(response3?.data?.Mail_Opened_Percentage)?.toFixed(2);
      listRates[1].value = parseFloat(response3?.data?.Mail_Cliekced_Percentage)?.toFixed(2);
      listRates[2].value = parseFloat(response3?.data?.Mail_NotOpened_Percentage)?.toFixed(2);

      list[0].value = response?.data?.Total_Mail_Sent_Count || 0;
      list[1].value = response1?.data?.Total_Recipients || 0;
      list[2].value = response2?.data?.No_of_Mail_Clicked;
      list[3].value = response2?.data?.No_Of_Times_Mail_Opened;


      // setDatas(list);
      // setRatesPct(listRates);
      return {
        datas: list,
        RatesDatas: listRates
      }
    } catch (error) {
      console.error(error);
    }
  }

  const { data: getStatiticsLists, isLoading: getStatiticsListsLoading, isError: getStatiticsListsError, refetch: getStatiticsListsRefetch } = useQuery(
    ['getStatiticsLists'],
    getData,
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (getStatiticsLists) {
      setDatas(getStatiticsLists?.datas);
      setRatesPct(getStatiticsLists?.RatesDatas);
    }
  }, [getStatiticsLists]);

  console.log("getStatiticsLists", datas, ratesPct);

  return (
    <>
      <JumboContentLayoutMain>
        <Typography sx={[componentTitle, { transform: "translateY(-20px)" }]}>
          Statistics{" "}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Div
            sx={[
              DisplayFlex,
              {
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: { md: "right" },
              },
            ]}
          >
            <Div>
              <Typography sx={[FontStyle]}>From</Typography>
              <TextField
                className="input-box2 mt-1"
                id="outlined-basic"
                type="date"
                value={startdate}
                onChange={(e) => {
                  setEndDate("");
                  setStartDate(e.target.value);
                }}
                variant="outlined"
              />
            </Div>
            <Div>
              <Typography sx={[FontStyle]}>To</Typography>
              <TextField
                className="input-box2 mt-1"
                id="outlined-basic"
                type="date"
                disabled={!startdate}
                value={enddate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                inputProps={{
                  min:
                    startdate &&
                    new Date(startdate)?.toISOString()?.split("T")[0],
                }}
                variant="outlined"
              />
            </Div>
            {/* </Typography> */}
            <Div sx={{ transform: { md: "translateY(10px)" } }}>
              <CustomButton
                type="submit"
                label="Show Results"
                Icon="filterIcon"
                width={"150px"}
              />
            </Div>
          </Div>
        </form>

        <Box sx={{ my: 2 }}>
          <Div>
            <Grid container spacing={4}>
              {datas?.map((item, idx) => {
                return (
                  <Grid key={idx} item xs={6} md={3}>
                    <Div
                      sx={{
                        borderRadius: "10px",
                        p: 3,
                        backgroundColor: avatarColors[idx],
                        height: cardHeight,
                        boxSizing: "border-box !important",
                      }}
                    >
                      <Div sx={[DisplayFlex, { gap: "10px" }]}>
                        <Div
                          sx={[
                            DisplayFlex,
                            {
                              justifyContent: "center",
                              height: "60px",
                              width: "90px",
                              borderRight: "1px solid lightgray",
                              // backgroundColor: avatarLightColors[idx],
                            },
                          ]}
                        >
                          {item?.label === "Total Sent" && (
                            <IoIosSend style={{ color: "white", fontSize: 25 }} />
                          )}
                          {item?.label === "Total Recipients" && (
                            <FaMailBulk
                              style={{ color: "white", fontSize: 25 }}
                            />
                          )}
                          {item?.label === "Total Opened" && (
                            <IoIosMailOpen
                              style={{ color: "white", fontSize: 25 }}
                            />
                          )}
                          {item?.label === "Total Clicked" && (
                            <GiClick style={{ color: "white", fontSize: 25 }} />
                          )}
                        </Div>
                        <Div sx={{ width: "70%" }}>
                          <Typography
                            id="Typography-root"
                            sx={{
                              color: "whitesmoke",
                              fontWeight: 600,
                              textAlign: "center",
                              fontSize: "30px",
                            }}
                          >
                            <Counter maximum={item?.value} speed={50} />
                            {/* {} */}
                          </Typography>
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle,
                              {
                                textAlign: "center",
                                // transform: "translateX(50%)",
                                color: "whitesmoke",
                                fontWeight: 600,
                              },
                            ]}
                          >
                            {item?.label?.toUpperCase()}
                          </Typography>
                        </Div>
                      </Div>
                    </Div>
                  </Grid>
                );
              })}
            </Grid>
          </Div>

          <Div sx={{ mt: { md: "5%" } }}>
            <PiecChartRount data={ratesPct} />
          </Div>

        </Box>

      </JumboContentLayoutMain>
    </>
  );
};

export default StatisticsRightCardList;
