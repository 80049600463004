import React, { useState, useContext } from "react";
import { EmailsContext } from "../../Context/EmailsContext";
import "./EmailsList.css";
import { Card, Typography } from "@mui/material";

const EmailsList = (props) => {
  const { setEmailData } = useContext(EmailsContext);
  const [isRead, setIsRead] = useState(false);

  const {
    id,
    username,
    date,
    emailAddress,
    emailSubject,
    emailType,
    importance,
    emailBody1,
    emailBody2,
    emailBody3,
    emailSignature,
  } = props;

  const passEmailData = (e) => {
    e.preventDefault();
    setEmailData([props]);
    setIsRead(!isRead);
  };

  return (
    <>
      <div className="email-card" onClick={passEmailData}>
        <div>
          <Typography className="emailcard-username">{username}</Typography>
          <div className="flex items-center justify-between flex-wrap">
            <Typography className="emailcard-subject" title={emailSubject}>
              {emailSubject?.length > 30
                ? emailSubject?.substring(0, 31) + "..."
                : emailSubject}
            </Typography>
            <Typography className="emailcard-date">{date}</Typography>
          </div>
          <p className="emailcard-body">
            {emailBody1?.length > 40
              ? emailBody1.substring(0, 41) + "..."
              : emailBody1}
          </p>
        </div>
      </div>
    </>
  );
};

export default EmailsList;
