import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import { FaFileInvoice } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePayment,
  MdOutlineFileUpload,
} from "react-icons/md";
import { RiRefreshFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { apiConfig } from "app/services/config";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import {
  ButtonStyle,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  input_label,
} from "../../widgets/CRMStyles";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
  errorFilter,
  requiredSpan,
} from "app/pages/widgets/StylesComponents";

let Transaction = [{ value: "Online" }, { value: "Cash" }, { value: "UPI" }];

const EditSubscription = ({
  setEditSubscription,
  scrollHeight,
  setListSubscription,
  editdatas,
  GetSubscriptionRecords,
}) => {
  const [Plan, setPlan] = useState([]);
  const [customerDatas, setCustomerDatas] = useState([]);
  const [errors, setErrors] = useState({});
  const [subscid, setsubscid] = useState("");
  const [cusname, setcusname] = useState("");
  const [planname, setplanname] = useState("");
  const [subscname, setsubscname] = useState("");
  const [noofuser, setnoofuser] = useState("");
  const [unitprice, setunitprice] = useState("");
  const [amount, setamount] = useState("");

  const [subscperiod, setsubscperiod] = useState("");
  // const [transtype, settranstype] = useState("");

  const [fields, setFields] = useState({
    cusname: "",
    planname: "",
    subscname: "",
    unitprice: "",
    taxamount: "",
    subscperiod: "",
    amount: "",
  });

  const [taxamount, settaxamount] = useState("");
  const [save, setSave] = useState(false);

  const getPlanRecords = () => {
    axios
      .get(BASE_URL + "/superadmin/planlist/?page=1", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((response) => {
        console.log("plan", response.data);
        setPlan(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCustomerRecords = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/AccountFullList/?Is_Deleted=False`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      console.log("Accountlist", response.data);
      setCustomerDatas(response.data || []);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    getPlanRecords();
    getCustomerRecords();
  }, []);

  const handleClickBack = () => {
    setsubscid("");
    setcusname("");
    setplanname("");
    setsubscname("");
    setnoofuser("");
    setunitprice("");
    setamount("");

    setsubscperiod("");
    // settranstype("");
    settaxamount("");
    setEditSubscription(false);
    setListSubscription(true);
    setErrors({});
  };

  const handleEditSubscription = async () => {
    let error = errorFilter(fields);
    setErrors(error);

    if (Object.keys(error)?.length > 0) {
      toast.warning(
        `some fields are empty ${Object.keys(error)?.map((item) => item)}${
          Object.keys(error)?.length > 0 && ", "
        }`,
        {
          position: "top-right",
          autoClose: 500,
        }
      );
    } else {
      let payload = {
        id: editdatas?.id,
        Subscription_Id: fields?.subscid,
        Customer_Name: fields?.cusname,
        Customer_Id: fields?.customerId,
        Plan_Id: fields?.planId,
        Plan_Name: fields?.planname,
        No_Of_Users: fields?.noofuser,
        UnitPrice: fields?.unitprice,
        Subscription_Name: fields?.subscname,
        SubscriptionPeriod: fields?.subscperiod,
        TaxAmount: fields?.taxamount,
        Transaction_Type: "",
        SuscriptionAmount: fields?.amount,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
      console.log("payload", payload);
      await axios
        .put(`${BASE_URL}/superadmin/subscription/`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("2", res.data);
          if (res) {
            toast.success("Subscription is Updated successfully");
            handleClickBack();
            GetSubscriptionRecords();
          } else {
            toast.error("Check your data");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Somthing Went Wrong");
        });
    }
  };

  useEffect(() => {
    if (editdatas) {
      console.log(
        "editdatas : ",
        editdatas,
        editdatas?.Customer_Id?.Contact_Name,
        editdatas?.Plan_Id?.Plan_Name
      );

      setFields((prev) => ({
        ...prev,
        subscid: editdatas?.Subscription_Id,
        cusname: editdatas?.Customer_Id?.Contact_Name,
        customerId: editdatas?.Customer_Id?.id,
        planname: editdatas?.Plan_Id?.Plan_Name,
        planId: editdatas?.Plan_Id?.id,
        subscname: editdatas?.Subscription_Name,
        noofuser: editdatas?.No_Of_Users,
        unitprice: editdatas?.UnitPrice,
        amount: editdatas?.SuscriptionAmount,
        subscperiod: editdatas?.SubscriptionPeriod,
        taxamount: editdatas?.TaxAmount,
      }));
    }
  }, [editdatas]);

  return (
    <>
      <Grid container columns={12} spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>
            Subscription Id {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={subscid}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="# Subscription ID"
            variant="outlined"
          />{" "}
          <div className="red">{errors?.seriesValue}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>
            Subscription Name {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Subscription Name"
            variant="outlined"
            value={fields?.subscname}
            onChange={(e) => {
              setFields((prev) => ({ ...prev, subscname: e.target.value }));
            }}
          />{" "}
          <div className="red">{errors?.subscname}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>
            Customer Name {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className="w-100  ">
            <Autocomplete
              className="search-select3 col-12 w-100"
              options={customerDatas}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.Contact_Name) {
                  return option?.Contact_Name;
                }
                return "";
              }}
              value={fields?.cusname}
              defaultValue={cusname}
              onChange={(item, newvalue) => {
                setFields((prev) => ({
                  ...prev,
                  cusname: newvalue?.Contact_Name,
                  customerId: newvalue?.id,
                }));
              }}
              id="outlined-basic"
              renderInput={(params) => (
                <TextField {...params} placeholder="Customer Name" />
              )}
            />
          </FormControl>{" "}
          <div className="red">{errors?.cusname}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Plan Name {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className="w-100  ">
            <Autocomplete
              className="search-select3 col-12 w-100"
              options={Plan}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.Plan_Name) {
                  return option?.Plan_Name;
                }
                return "";
              }}
              value={fields?.planname}
              onChange={(item, newvalue) => {
                setFields((prev) => ({
                  ...prev,
                  planname: newvalue?.Plan_Name,
                  planId: newvalue?.id,
                  unitprice: Number(newvalue?.UnitPrice || 0),
                  noofuser: newvalue?.No_Of_Users,
                  amount: Number(newvalue?.UnitPrice || 0),
                }));
              }}
              id="outlined-basic"
              renderInput={(params) => (
                <TextField {...params} placeholder="Plan Name" />
              )}
            />
          </FormControl>{" "}
          <div className="red">{errors?.planname}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>No of Users {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={fields?.noofuser}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="No of Users"
            variant="outlined"
            // onChange={(e) => {
            //   setplanname(e.target.value);
            // }}
          />{" "}
          <div className="red">{errors?.noofuser}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Price {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={fields?.unitprice}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            type="number"
            placeholder="Price"
            variant="outlined"
            // onChange={(e) => {
            //   setnoofmonth(e.target.value);
            // }}
          />{" "}
          <div className="red">{errors?.unitprice}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Tax Amount {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Tax Amount"
            type="number"
            variant="outlined"
            value={fields?.taxamount}
            onChange={(e) => {
              setFields((prev) => ({
                ...prev,
                taxamount: e.target.value,
                amount: Boolean(e.target.value)
                  ? Number(e.target.value) + Number(prev?.unitprice || 0)
                  : Number(prev?.unitprice || 0),
              }));
            }}
          />{" "}
          <div className="red">{errors?.taxamount}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>
            Subscription Period {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            type="number"
            placeholder="Subscription Period "
            variant="outlined"
            value={fields?.subscperiod}
            onChange={(e) => {
              setFields((prev) => ({ ...prev, subscperiod: e.target.value }));
            }}
          />{" "}
          <div className="red">{errors?.subscperiod}</div>
        </Grid>

        {/* <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Transaction Type {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className="w-100  ">
            <Autocomplete
              className="search-select3 col-12 w-100"
              options={["Online", "Cash", "UPI"]}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option) {
                  return option;
                }
                return "";
              }}
              value={transtype}
              onChange={(item, newvalue) => {
                settranstype(newvalue);
              }}
              id="outlined-basic"
              renderInput={(params) => (
                <TextField {...params} placeholder="Transaction Type" />
              )}
            />
          </FormControl>
        </Grid> */}

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Amount {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={fields?.amount}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Amount "
            type="number"
            variant="outlined"
          />{" "}
          <div className="red">{errors?.amount}</div>
        </Grid>
      </Grid>

      <Div
        sx={{
          my: 3,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 3,
        }}
      >
        <CustomButton
          label="Update"
          Icon="saveIcon"
          onClick={handleEditSubscription}
        />
        <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
      </Div>

      <DialogBox
        open={save}
        onClickYes={() => {
          setSave(false);
          handleClickBack();
        }}
        onClickNo={() => setSave(false)}
      />
    </>
  );
};

export default EditSubscription;
