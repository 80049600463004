/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Menu,
  useMediaQuery,
  ListItemText,
  ListItemIcon,
  List,
  Popover,
  Grid,
  Box,
  Autocomplete,
  TextField,
  ListItemButton,
  DialogTitle,
  FormControl,
  DialogContent,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "react-toastify/dist/ReactToastify.css";
import {
  CRMTableHead,
  componentTitle,
  DisplayFlex,
  FontStyle,
  Colors,
  deletedWarnigMsg,
  dialogTitle,
  input_label,
  deleteCampaign,
  deleteCampaignPosting,
  deleteCampaignPostingEdit,
  deleteCampaignPostingPromotion,
} from "app/pages/widgets/CRMStyles";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import axios from "axios";
import { BASE_URL, BASE_URL1 } from "app/services/auth-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
import SocialMediaEditCampaign from "./SocialMediaEditCampaign";
import {
  AvatarCircle,
  DialogBox,
  CustomButton,
  CustomIcons,
  avatarColorFun,
  Cancelbutton,
  SelectDropdown,
  fetchTransactionSeries,
  Dragabledialog,
  Customgrid,
  DialogBoxCampaign,
  CRMDateField,
  errorFilter,
} from "app/pages/widgets/StylesComponents";
import { useQuery } from "react-query";
import { TiEdit } from "react-icons/ti";
import { FaGear } from "react-icons/fa6";
import { BiFilterAlt } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import OverviewPage from "app/pages/components/OverviewPage";
import { Delete } from "@mui/icons-material";
import { truncate } from "lodash";
import { FiStopCircle } from "react-icons/fi";
import uploadimage from "../../../../assets/images/box-1.png";
import SearchBar from "app/pages/widgets/SearchField";

const SocialMediaCampaign = () => {
  // Create Lead Questions
  const matches = useMediaQuery("(min-width:759px)");
  const [tabValue, setTabValue] = useState(0);

  const [loading, setloading] = useState(false);
  const hasMounted = useRef(false);

  const token = localStorage.getItem("accesstoken");

  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const item = "crmuseradmin1";
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [errors, setErrors] = useState({});

  const [openAnchor, setOpenAnchor] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [update, setUpdate] = useState(0);
  const [overview, setOverview] = useState(false);
  const [npostingdate, setNPostingDate] = useState("");

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Promo_Id", label: "Promo Id", bool: true },
    { sort: "Promo_Code", label: "Promo Code", bool: true },
    { sort: "Promo_Name", label: "Promo Name", bool: true },
    { sort: "Promo_Date", label: "Promo Date", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "Promo_Content", label: "Promo Content", bool: true },
    { sort: "SocialMedia_Type", label: "SocialMedia Type", bool: true },
    { sort: "Posting_Date", label: "Posting Date", bool: true },
  ]);

  const fileInputRef = useRef(null);
  const [userInfo, setUserInfo] = useState("");

  const getUserInfo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/LinkdinCRUD/`, {
        params: {
          UserId: localStorage.getItem("UserId"),
        },
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      console.log("UserInfo", response?.data);
      // setUserInfo(response?.data);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  //  Getting the List of Lead Question Additional
  const [datas, setDatas] = useState([]);

  const getData = async () => {
    console.log("Getting");
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");
    setloading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/user/SocialMediaPromotionallAdditional/?page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setDatas((prevDatas) => {
        const updatedDatas = new Set([...prevDatas, ...res?.data?.results]);
        return Array.from(updatedDatas);
      });
      setCount(res?.data?.count);

      console.log("results in contacts", res.data.results);
      // setDatas(response?.data?.results || []);
      // return response?.data?.results || [];
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // Handle the error here, show a message, log it, etc.
    } finally {
      setloading(false);
    }
  };

  const getMyData = async (page) => {
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");
    setloading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/user/SocialMediaPromotionAdditional/?Created_By=${localStorage.getItem(
          "UserId"
        )}&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setDatas((prevDatas) => {
        const updatedDatas = new Set([...prevDatas, ...res?.data?.results]);
        return Array.from(updatedDatas);
      });

      setCount(res?.data?.count);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setloading(false);
    }
  };

  // get lookup datas
  const [lookupdatas, setLookupDatas] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);

      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  // Lead Generation

  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [base64, setbase64] = useState(null);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [selectedFile, setSelectedFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [filePath, setFilePath] = useState("");
  const [namefile, setNameFile] = useState("");
  const [seriesValue, setSeriesValue] = useState("");

  const filterItems = ["Linkedin", "Facebook", "Instagram"];
  const [promo, setPromos] = useState({
    promoname: "",
    promocode: "",
    promodate: "",
    promocontent: "",
    daystorun: "",
    type: "",
    postingtype: [],
    postingdate: "",
  });

  const handleChangePromo = (e) => {
    setPromos({ ...promo, [e.target.name]: e.target.value });
  };

  // For the popup
  const [save, setSave] = useState(false);

  // Handle Submit

  const handleSubmit = (e) => {
    let error = errorFilter({ ...promo, namefile });
    if (promo?.postingtype?.length == 0) {
      error.postingtype = "posting type";
    }
    setErrors(error);

    e.preventDefault();
    console.log("`${BASE_URL}${filePath}", `${BASE_URL}/media/${namefile}`);
    if (Object.keys(error)?.length > 0) {
      toast.warn(
        `Some fields are invalid ${Object.keys(error)?.map((er) => er)}`
      );
    } else {
      var payload = {
        Promo_Id: seriesValue == "Manual" && promo?.promoId,
        Promo_Name: promo?.promoname,
        Promo_Code: promo?.promocode,
        Promo_Date: promo?.promodate,
        Promo_Content: promo?.promocontent,
        Days_to_Run: promo?.daystorun,
        File: ba64,
        FileName: namefile,
        sub: userInfo?.sub,
        url: `${BASE_URL}/media/${namefile}`,
        // url: "https://backend.crmfarm.app/demo/media/facebook.png",
        SocialMedia_Type: promo?.postingtype,
        Posting_Date: promo?.postingdate,
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        UserId: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };
      console.log("payload", payload);
      axios
        .post(`${BASE_URL}/user/SocialMediaPromotionCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("check", res);
          toast.success("Created successfully", {
            position: "top-right",
            autoClose: 100,
          });
          setPage(1);
          setDatas([]);
          // getSocialMeadiaListsRefetch();
          if (tabValue == 0) {
            getData();
          } else {
            getMyData();
          }
          setOpenAddNotes(false);
          resetFunction();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  // For Table Modifications

  // Edit And Delete Menu Functionality
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  // Handle Update Function For the Update

  const [openEditContact, setOpenEditContact] = useState(false);
  const [openEditContact1, setOpenEditContact1] = useState(false);
  const [promotion, setPromotion] = useState(false);

  const handleUpdate = (data) => {
    setUpdate(data);
  };

  const handleClickEditContact = (update) => {
    //  // // // console.log("In Edit",update)
    setOpenEditContact(true);
  };

  //  For Pagination Table

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [filter, setfilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = datas?.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // For Dialog Popup

  const [showDialog, setShowDialog] = useState(false);

  const [ba64, setBa64] = useState("");

  // Delete Popup Functionality

  // For filter Functionality
  const [pron, setPron] = useState("");
  const [proc, setProc] = useState("");
  const [procon, setProcon] = useState("");
  const [scoialMedia, setSocialMedia] = useState("");
  const [fromdate, setFromdate] = useState(new Date().toISOString());

  const [todate, setToDate] = useState(new Date().toISOString());
  const [statefil, setStatefil] = useState("");

  const filterSubmit = async () => {
    console.log("fromdate", todate, fromdate);

    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    setloading(true);

    try {
      axios
        .post(
          `${BASE_URL}/user/SocialMediaPromotionFilter/`,
          {
            Promo_Name: pron,
            Promo_Id: proc,
            // SocialMedia_Type: scoialMedia,
            // Promo_Content: procon,
            Created_Date: [fromdate, todate],
            page: page,
          },
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);

          if (res?.data?.results) {
            setfilter(true);

            setDatas((prevDatas) => {
              return page === 1
                ? res?.data?.results
                : Array.from(new Set([...prevDatas, ...res?.data?.results]));
            });

            setCount(res?.data?.count);
          }
          setShowFilter(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const RefreshData = () => {
    setfilter(false);
    setloading(false);
    setDatas([]);
    setPron("");
    setProc("");
    setProcon("");
    setFromdate("");
    setToDate("");
    setSocialMedia("");
    setPage(1);
    // if (tabValue == 0) {
    //   getData();
    // } else {
    //   getMyData();
    // }

    if (tabValue === 0) {
      console.log("page", { page });

      getData();
    } else if (tabValue === 1) {
      console.log("page", { page });

      getMyData();
    }

    setShowFilter(false);

    // getSocialMeadiaListsRefetch(page);
  };

  const [showDelete, setShowDelete] = useState(null);
  const [showDelete1, setShowDelete1] = useState(null);

  const [number, setNumber] = useState(1);

  const Deletedata = () => {
    axios
      .delete(`${BASE_URL}/user/SocialMediaPromotionCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setPage(1);
        setDatas([]);
        // getSocialMeadiaListsRefetch();
        if (tabValue == 0) {
          getData();
        } else {
          getMyData();
        }
        handleCloseAction();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // Upload Image
  const handleFileUpload = async (imageUrl, fileName) => {
    console.log("fileName", fileName);
    let token = localStorage.getItem("accesstoken");
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex);
    const uniqueFileName = `${nameWithoutExtension}-${Math.random()
      .toString(36)
      .substring(2, 8)}${extension}`;
    console.log("uniqueFileName", uniqueFileName);
    setNameFile(uniqueFileName);
    const payload = {
      FileName: uniqueFileName,
      File: imageUrl,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Urls: "",
      Description: "Test File",
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: headers,
      })
      .then((res) => {
        console.log("Image upload response", res);
        if (res) {
          getFileUrlByFileName(res?.data?.FileName);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // Search Functionality

  const SearchData = (e) => {
    console.log(
      "handleSearchange:",
      e.target.value,
      Boolean(e.target.value !== "")
    );
    Boolean(e.target.value !== "")
      ? handleSearch(e.target.value)
      : tabValue == 0
      ? getData(page)
      : getMyData(page);
  };

  const handleSearch = async (search) => {
    await axios
      .get(
        `${BASE_URL}/user/ContactSearch/?search=${search}&Created_By=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        //  // // // console.log(res.data);
        setDatas(res.data || []);
        setCount(res?.data?.length || 0);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getFileUrlByFileName = (fileName) => {
    console.log("get file clicked");
    let token = localStorage.getItem("accesstoken");
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(`${BASE_URL}/user/UserFilesandUrlsCRUD/?FileName=${fileName}`, {
        headers: headers,
      })
      .then((res) => {
        console.log("get file", res?.data?.File);
        if (res) {
          setFilePath(res?.data?.File);
        }
      })
      .catch((error) => {
        console.error("get file error", error);
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("fileee", selectedFile?.name);
    if (selectedFile) {
      const reader = new FileReader();
      console.log("readerrr", reader);
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        setBa64(base64);
        console.log("base64", base64);
        handleFileUpload(base64, selectedFile?.name);
      };
      reader.readAsDataURL(selectedFile);
    }
    setSelectedFile(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setImagePreview(previewURL);
    // handleRegisterUpload()
    setIsImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setIsImagePreviewOpen(false);
    setImagePreview(null);
  };

  const resetFunction = () => {
    setPromos({
      promoname: "",
      promocode: "",
      promodate: "",
      promocontent: "",
      daystorun: "",
      type: "",
      postingtype: [],
      postingdate: "",
    });
  };

  const {
    data: getUserInfoLists,
    isLoading: getUserInfoListsLoading,
    isError: getUserInfoListsError,
    refetch: getUserInfoListsRefetch,
  } = useQuery(["getUserInfoLists"], getUserInfo, { staleTime: Infinity });

  // const {
  //   data: getSocialMeadiaLists,
  //   isLoading: getSocialMeadiaListsLoading,
  //   isError: getSocialMeadiaListsError,
  //   refetch: getSocialMeadiaListsRefetch,
  // } = useQuery(["getSocialMeadiaLists"], getData, { staleTime: Infinity });

  const {
    data: getlookupList,
    isLoading: getlookupListLoading,
    isError: getlookupListError,
    refetch: getlookupDatareFecth,
  } = useQuery(["GetLookupListData"], getlookupData, { staleTime: Infinity });

  useEffect(() => {
    if (getUserInfoLists) {
      setUserInfo(getUserInfoLists);
    }
  }, [getUserInfoLists]);

  // useEffect(() => {
  //   if (getSocialMeadiaLists) {
  //     // setDatas(getSocialMeadiaLists);
  //   }
  // }, [getSocialMeadiaLists]);

  //  My List and All List Function

  // sorting functions

  const sortData = (e, value) => {
    const sortedData = [...datas].sort((a, b) =>
      value === "A-Z"
        ? a?.Promo_Name?.localeCompare(b?.Promo_Name)
        : b?.Promo_Name?.localeCompare(a?.Promo_Name)
    );
    setDatas(sortedData);
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100 &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      setPage(1);
    }
  }, []);

  useEffect(() => {
    setDatas([]);
    setPage(1);
  }, [tabValue]);

  useEffect(() => {
    if (tabValue == 0 && filter == false) {
      console.log("page", { page });
      getData(page);
    } else if (tabValue == 1 && filter == false) {
      console.log("page", { page });
      getMyData(page);
    } else if (filter == true) {
      console.log("page", { page });

      filterSubmit();
    }
  }, [page, tabValue, filter]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(async () => {
    const series = await fetchTransactionSeries("Social_Campaign");
    console.log("Social_Campaign series", series);
    setSeriesValue(series);
  }, []);

  // Lookup Data

  useEffect(() => {
    if (getlookupList) {
      let SocialMediaType = getlookupList?.find(
        (item) => item?.Lookupname == "SocialMedia Type"
      );
      console.log("SocialMediaType", SocialMediaType);
      setLookupDatas(SocialMediaType?.Values || []);
    }
  }, [getlookupList]);

  //  get Current Date

  //  Stop Promotion
  const [promotionflag, setPromotionFlag] = useState(false);

  const stopPromotion = (id) => {
    axios
      .get(`${BASE_URL}/user/ScoialMediaPromotionStop/?id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        setPromotionFlag(true);
        toast.success("Promotion Stopped successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setPage(1);
        setDatas([]);
        if (tabValue == 0) {
          getData();
        } else {
          getMyData();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  console.log("response", { tabValue, page });

  return (
    <>
      <Dragabledialog
        maxWidth="lg"
        open={openAddNotes}
        // onClose={() => setOpenAppointment(false)}
      >
        <DialogTitle
          sx={[dialogTitle, DisplayFlex]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Create Social Campaign
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <Customgrid columnCount={4}>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>
                Promo Id
                <span className="required">
                  {seriesValue == "Automatic" ? "" : "*"}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="# Promo Id"
                variant="outlined"
                name="promoId"
                value={promo?.promoId}
                onChange={handleChangePromo}
                disabled={seriesValue == "Automatic" ? true : ""}
              />
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Promo Name</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Promo Name"
                variant="outlined"
                name="promoname"
                value={promo?.promoname}
                onChange={handleChangePromo}
              />
              {errors?.promoname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promoname} Promo Name{" "}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>
                Promo Code<span className="required"></span>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Promo Code"
                variant="outlined"
                name="promocode"
                value={promo?.promocode}
                onChange={handleChangePromo}
              />
              {errors?.promocode && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promocode} Promo Code{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>
                Promo Date<span className="required"></span>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                type="date"
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                variant="outlined"
                name="promodate"
                value={promo?.promodate}
                onChange={handleChangePromo}
              />
              {errors?.promodate && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promodate} Promo Date{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Promo Content</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Promo Content"
                variant="outlined"
                name="promocontent"
                value={promo?.promocontent}
                onChange={handleChangePromo}
              />
              {errors?.promocontent && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promocontent} Promo Content{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Days to Run</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                type="number"
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Days To Run"
                variant="outlined"
                name="daystorun"
                value={promo?.daystorun}
                onChange={handleChangePromo}
              />
              {errors?.daystorun && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.daystorun} Days to Run{" "}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Posting Date</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                type="datetime-local"
                id="outlined-basic"
                placeholder="Posting Date"
                variant="outlined"
                name="postingdate"
                value={promo?.postingdate}
                onChange={handleChangePromo}
              />
              {errors?.promoname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promoname} Promo Name{" "}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>SocialMedia Type</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Autocomplete
                multiple
                options={filterItems || []}
                className={`${
                  promo?.postingtype?.length <= 1 && "search-select"
                } col-10`}
                sx={{ backgroundColor: " #ffffff !important" }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option) {
                    return option;
                  }
                  return "";
                }}
                value={filterItems?.filter((opt) =>
                  promo?.postingtype?.includes(opt)
                )}
                onChange={(e, newValue) => {
                  console.log("newvalue", newValue);

                  setPromos({
                    ...promo,
                    postingtype: newValue?.map((opt) => opt),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Social Media Type"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}></Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              {" "}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Image</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Div
                className="card"
                sx={{
                  width: "200px",
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => setBa64(null)}
                  sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: "165px",
                    marginTop: "-5px",
                  }}
                >
                  {Boolean(ba64) ? (
                    <MdClose style={{ color: "tomato" }} />
                  ) : (
                    <Div sx={{ pt: 2 }}></Div>
                  )}
                </IconButton>
                {Boolean(ba64) ? (
                  <img
                    alt="image"
                    src={`data:image/png;base64,${ba64}`}
                    width={100}
                    height={100}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    alt="image"
                    src={uploadimage}
                    width={120}
                    height={120}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
                <Div>
                  <Button
                    component="label"
                    name="imageFile"
                    sx={[
                      FontStyle,
                      {
                        // mt: { sm: 4 },
                        // ml: { xs: 2, md: 4 },
                        color: "#00BFFF",
                        textTransform: "capitalize",
                        width: "100px !important",
                      },
                    ]}
                  >
                    Upload Image
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{
                        backgroundColor: "transparent",
                      }}
                      name="companyLogo"
                    />
                  </Button>
                </Div>
              </Div>
            </Grid>
          </Customgrid>

          <Grid container align="center" mt={2} spacing={2} pb={2}>
            <Grid item align="right" xs={6}>
              <CustomButton
                label="Save"
                Icon="saveIcon"
                onClick={handleSubmit}
              />
            </Grid>
            <Grid item align="left" xs={6}>
              <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dragabledialog>

      <JumboContentLayoutMain>
        <Box>
          {/* <Typography sx={[componentTitle]}>Social Media Campaign</Typography> */}
          <Box sx={{ mb: 2 }}>
            <Box className="flex items-center justify-between flex-wrap">
              <Typography
                sx={[componentTitle, { color: Colors?.gray, fontWeight: 700 }]}
              >
                Social Media Campaign List
              </Typography>

              <Div className="flex items-center justify-between gap-2">
                <SelectDropdown
                  className="search-select3"
                  defaultValue={"All List"}
                  onChange={(e, value) => {
                    setTabValue(value === "All List" ? 0 : 1);
                    setPage(1);
                    console.log("value", value);
                  }}
                  options={["All List", "My List"]}
                />

                <SelectDropdown
                  className="search-select3"
                  defaultValue={"A-Z"}
                  onChange={sortData}
                  options={["A-Z", "Z-A"]}
                  width="70px"
                  clearButton={false}
                />
                {matches && (
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Div>
            </Box>
          </Box>
          <Box className="mt-3">
            <Grid container spacing={2}>
              <Grid
                item
                xs={10.5}
                md={6}
                display={"flex"}
                alignItems={"center"}
              >
                <IconButton
                  sx={{ color: Colors?.Mblue }}
                  onClick={(e) => setShowFilter(e.currentTarget)}
                >
                  <BiFilterAlt size={22} />
                </IconButton>
                <Typography
                  sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                >
                  ( {count} ) Social Media
                </Typography>
              </Grid>
              {!matches && (
                <Grid item xs={1} md={1}>
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              )}

              <Popover
                open={Boolean(showFilter)}
                anchorEl={showFilter}
                onClose={() => setShowFilter(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
              >
                <Box>
                  <Div
                    sx={[
                      DisplayFlex,
                      CRMTableHead,
                      dialogTitle,
                      { px: 2, justifyContent: "space-between" },
                    ]}
                  >
                    <Typography id="Typography-root" sx={[dialogTitle]}>
                      Filters
                    </Typography>
                    <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                      <GrPowerReset size={16} />
                    </IconButton>
                  </Div>

                  <Div style={{ padding: "1rem" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Promo Name
                        </Typography>

                        <TextField
                          className={`col-12 input-box2`}
                          id="outlined-basic"
                          placeholder="Enter Promo Name"
                          value={pron}
                          onChange={(e) => setPron(e.target.value)}
                          InputProps={{
                            style: {
                              borderRadius: "3px",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Promo Code
                        </Typography>
                        <TextField
                          className={`col-12 input-box2`}
                          id="outlined-basic"
                          placeholder="Enter Promo Code"
                          value={proc}
                          onChange={(e) => setProc(e.target.value)}
                          InputProps={{
                            style: {
                              borderRadius: "3px",
                            },
                          }}
                        />
                      </Grid>

                      {/* <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Promo Content
                        </Typography>
                        <TextField
                          className={`col-12 input-box2`}
                          id="outlined-basic"
                          placeholder="Enter Promo Content"
                          value={procon}
                          onChange={(e) => setProcon(e.target.value)}
                          InputProps={{
                            style: {
                              borderRadius: "3px",
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Social Media Type
                        </Typography>
                        <FormControl className="col-12">
                          <Autocomplete
                            className="search-select3 col-12"
                            // {...register("selectedCountry1")}
                            options={lookupdatas || []}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.value) {
                                return option?.value;
                              }
                              return "";
                            }}
                            value={scoialMedia}
                            onChange={(e, newValue) => {
                              setSocialMedia(newValue?.value);
                            }}
                            sx={[FontStyle]}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Scoial Media Type"
                                sx={[FontStyle]}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          From Date
                        </Typography>
                        <CRMDateField
                          type="date"
                          className="col-12 input-box"
                          value={fromdate}
                          onChange={(e) => {
                            setFromdate(e.target.value);
                          }}
                          id="createdDate"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          To Date
                        </Typography>
                        <CRMDateField
                          value={todate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          type="date"
                          className="col-12 input-box"
                          id="lastDate"
                        />
                      </Grid>
                    </Grid>

                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        gap: "10px",
                      }}
                    >
                      <CustomButton
                        onClick={filterSubmit}
                        type="submit"
                        label="Apply"
                        Icon="filterIcon"
                      />
                      <Cancelbutton onClick={() => setShowFilter(null)} />
                    </Div>
                  </Div>
                </Box>
              </Popover>

              <Grid item xs={12} md={6}>
                <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                  <SearchBar
                    // SearchData={SearchData}
                    SearchData={(e) => SearchData(e)}
                    width={"250px !important"}
                    color={"black"}
                    className=""
                  />
                  <CustomButton
                    onClick={() => setOpenAddNotes(true)}
                    label="Create"
                    Icon="createIcon"
                  />
                </Div>
              </Grid>
            </Grid>
          </Box>

          <List sx={{ mt: 0 }}>
            {datas?.length > 0 &&
              datas?.map((data, idx) => {
                // if (idx < 2) {

                let image =
                  (BASE_URL +
                    data?.File?.split("https://backend.crmfarm.app")[1] ||
                    data?.File) ??
                  "";
                let Question = data?.Question?.substring(
                  3,
                  data?.Question?.length - 4
                );
                return (
                  <Div
                    // onMouseOver={() => setFadeEdit(idx)}
                    // onMouseLeave={() => setFadeEdit(null)}
                    className=""
                    key={idx}
                    sx={{
                      minHeight: "124px",

                      p: { xs: 0.5, sm: 2 },
                      px: { xs: 0.5, md: 7 },
                      my: 2,

                      cursor: "default",
                    }}
                  >
                    <Grid
                      container
                      alignItems={"center"}
                      spacing={2}
                      sx={{ pr: "5%" }}
                    >
                      <Grid item xs={12} md={1.5}>
                        <div
                          style={{ marginTop: "6px" }}
                          onClick={() => {
                            let obj = {};
                            Object.entries(data)?.map(([key, value]) => {
                              columnMenuItems?.filter((item) => {
                                if (item?.sort === key) {
                                  if (key === "Question") {
                                    obj[key] = data[key]?.substring(
                                      3,
                                      data[key]?.length - 4
                                    );
                                    return;
                                  }
                                  if (
                                    item.sort == "Created_Date" ||
                                    item.sort == "Updated_Date" ||
                                    item.sort == "Promo_Date" ||
                                    item.sort == "Posting_Date"
                                  ) {
                                    obj[key] = new Date(data[key])
                                      ?.toISOString()
                                      .split("T")[0];
                                    return;
                                  }
                                  obj[key] = data[key];
                                  console.log("sadasdasds", obj);
                                }
                              });
                            });
                            setOverview(obj);
                          }}
                        >
                          <AvatarCircle
                            size="90px"
                            fontSize="30px"
                            bgColor={avatarColorFun(idx)}
                            title={!Boolean(data?.File) && data?.Promo_Name}
                            image={Boolean(data?.File) ? image : false}
                          />
                        </div>
                      </Grid>

                      <Grid container spacing={1} item xs={12} md={10.5}>
                        <Grid item xs={12}>
                          <Box
                            sx={[
                              DisplayFlex,
                              {
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Typography
                              sx={[
                                {
                                  fontSize: "18px",
                                  fontWeight: 700,
                                  color: `${Colors?.Mblue}`,
                                },
                              ]}
                            >
                              {data?.Promo_Name || "No Name"}
                            </Typography>
                            <Div sx={[DisplayFlex]}>
                              <CustomButton
                                onClick={(e) => {
                                  setOpenAnchor(e.target);
                                  setUpdate(data);
                                }}
                                sx={{ borderRadius: "25px" }}
                                label="Action"
                                bgColor={Colors?.Mblue}
                                Icon="downArrowIcon"
                                endIcon={true}
                              />
                            </Div>
                          </Box>
                        </Grid>
                        {columnMenuItems?.map((Menu, subIdx) => {
                          let value =
                            Menu.sort === "Created_Date" ||
                            Menu.sort === "Updated_Date" ||
                            // Menu.sort === "Promo_Date" ||
                            Menu.sort === "Posting_Date"
                              ? new Date(data[Menu?.sort])
                                  ?.toISOString()
                                  .split("T")[0]
                              : Menu?.sort === "Question"
                              ? data[Menu?.sort]?.length > 30
                                ? data[Menu?.sort]?.substring(3, 30) + "..."
                                : data[Menu?.sort]?.substring(
                                    3,
                                    data[Menu?.sort]?.length - 4
                                  )
                              : data[Menu?.sort] ?? "-";
                          return (
                            <>
                              {Menu?.bool && (
                                <Grid key={subIdx} item xs={12} md={4}>
                                  <Typography
                                    sx={[
                                      { fontSize: "14px", color: "#828282" },
                                    ]}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        color: "#5e5e5e",
                                      }}
                                    >
                                      {Menu?.label} :
                                    </span>{" "}
                                    {value}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Div>
                );
                // }
              })}
          </List>

          {/* gear options */}
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            sx={{
              mt: 5,
              maxHeight: "300px",
              scrollBehavior: "smooth",
            }}
          >
            <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
              Choose Fields
            </Typography>
            <FormGroup sx={{ p: 2 }}>
              {columnMenuItems?.length > 0 &&
                columnMenuItems?.map((column, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="info"
                          size="small"
                          checked={column?.bool}
                          onChange={(e) => {
                            let value = [...columnMenuItems];
                            value[index].bool = !column?.bool;
                            setColumnMenuItems(value);
                          }}
                        />
                      }
                      label={
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {column?.label}
                        </Typography>
                      }
                    />
                  );
                })}
            </FormGroup>
          </Menu>

          {/* openAnchor Action popover */}
          <Popover
            id={Boolean(openAnchor) ? "simple-popover" : undefined}
            open={Boolean(openAnchor)}
            anchorEl={openAnchor}
            onClose={() => {
              setOpenAnchor(null);

              // setIsLoading(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List sx={{ p: 1 }}>
              <ListItemButton
                disabled={update?.Is_Stoped}
                onClick={() => {
                  const currentDateString = new Date()
                    .toISOString()
                    .split("T")[0];
                  const currentDate = new Date(currentDateString);
                  let newpostdate = new Date(update?.Posting_Date)
                    ?.toISOString()
                    .split("T")[0];

                  if (currentDate >= new Date(newpostdate)) {
                    setOpenEditContact1(true);
                  } else {
                    setOpenEditContact(true);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: Colors?.Mblue,
                    minWidth: "25px !important",
                  }}
                >
                  <TiEdit
                    style={{
                      fontSize: 18,
                      fontWeight: 900,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography sx={[FontStyle]}>Edit</Typography>}
                />
              </ListItemButton>

              <ListItemButton
                disabled={update?.Is_Stoped}
                onClick={() => {
                  setNumber(update?.id);

                  stopPromotion(update?.id);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: Colors?.Mblue,
                    minWidth: "25px !important",
                  }}
                >
                  <FiStopCircle
                    style={{
                      fontSize: 18,
                      fontWeight: 900,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={[FontStyle]}>Stop Promotion</Typography>
                  }
                />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  setNumber(update?.id);
                  console.log("update posting", update?.Posting_Date);
                  const currentDateString = new Date()
                    .toISOString()
                    .split("T")[0];
                  const currentDate = new Date(currentDateString);
                  let newpostdate = new Date(update?.Posting_Date)
                    ?.toISOString()
                    .split("T")[0];
                  console.log("newpostdate", newpostdate);

                  if (currentDate >= new Date(newpostdate)) {
                    setShowDelete1(true);
                  } else {
                    setShowDelete(true);
                  }

                  setNPostingDate(newpostdate);
                }}
                sx={{ borderRadius: "5px" }}
              >
                <ListItemIcon
                  sx={{ minWidth: "25px !important", color: "tomato" }}
                >
                  {CustomIcons?.deleteIcon}
                </ListItemIcon>
                <ListItemText
                  primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                />
              </ListItemButton>
            </List>
          </Popover>
        </Box>
        {/* <Div
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Pagination
            color="success"
            count={Math.ceil(count / 10)}
            page={page}
            onChange={(e, newValue) => handleChangePage(e, newValue)}
            size="small"
            showFirstButton
            showLastButton
          />
        </Div> */}
        <DialogBox
          open={showDelete}
          Content={deleteCampaign}
          onClickNo={() => setShowDelete(false)}
          onClickYes={() => {
            setShowDelete(false);
            Deletedata();
          }}
        />

        <DialogBoxCampaign
          open={showDelete1}
          Content={deleteCampaignPosting}
          onClickNo={() => setShowDelete1(false)}
          onClickYes={() => {
            setShowDelete1(false);
          }}
        />

        <DialogBoxCampaign
          open={openEditContact1}
          Content={deleteCampaignPostingEdit}
          onClickNo={() => setOpenEditContact1(false)}
          onClickYes={() => {
            setOpenEditContact1(false);
          }}
        />

        <DialogBoxCampaign
          open={promotion}
          Content={deleteCampaignPostingPromotion}
          onClickNo={() => setPromotion(false)}
          onClickYes={() => {
            setPromotion(false);
          }}
        />

        {/* overview */}
        <OverviewPage
          label={"Social Media Campaign"}
          datas={overview}
          open={Boolean(overview)}
          setOpen={setOverview}
          onClose={() => setOverview(false)}
        />

        {/* close dialog box */}
        <DialogBox
          open={save}
          onClickYes={() => {
            setSave(false);
            setOpenAddNotes(false);
            resetFunction();
          }}
          onClickNo={() => setSave(false)}
        />

        {/* delete close dialog box */}
        {/* 
        <DialogBox
          open={showDelete}
          Content={deletedWarnigMsg}
          onClickYes={() => {
            setShowDelete(false);
            Deletedata();
          }}
          onClickNo={() => setShowDelete(false)}
        /> */}

        <Div>
          <SocialMediaEditCampaign
            getData={getData}
            update={update}
            openEditContact={openEditContact}
            setOpenEditContact={setOpenEditContact}
          />
        </Div>
      </JumboContentLayoutMain>
    </>
  );
};

export default SocialMediaCampaign;
