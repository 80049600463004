// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-szutfi-MuiTableCell-root.MuiTableCell-body {
    margin-bottom: 0px !important;
}

.css-1oyfslj-MuiListItemIcon-root {
    min-width: 56px;
    color: #475259 !important;
}

.css-1sg2p09-MuiListItemText-root {
    color: #2a978f !important;
    font-size: small;
    font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/widgets/Tab.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".css-szutfi-MuiTableCell-root.MuiTableCell-body {\n    margin-bottom: 0px !important;\n}\n\n.css-1oyfslj-MuiListItemIcon-root {\n    min-width: 56px;\n    color: #475259 !important;\n}\n\n.css-1sg2p09-MuiListItemText-root {\n    color: #2a978f !important;\n    font-size: small;\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
