// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gmail {
    background-color: whitesmoke;
    height: 100px;
    /* width: 1000px; */
}

.login>img {
    /* object-fit: contain; */
    height: 50px;
}


.login {
    height: 20px
}`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/GmailAPI/GmailAPI.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;;AAGA;IACI;AACJ","sourcesContent":[".gmail {\n    background-color: whitesmoke;\n    height: 100px;\n    /* width: 1000px; */\n}\n\n.login>img {\n    /* object-fit: contain; */\n    height: 50px;\n}\n\n\n.login {\n    height: 20px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
