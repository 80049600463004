import Div from "@jumbo/shared/Div";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ButtonStyle,
  CRMTableHead,
  ComponentHeading,
  ComponentHeadingINV,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  FontStyleINV,
  generateRandomColor,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import React, { memo, useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import {
  CustomButton,
  formatIndianNumber,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import numberToWords from "number-to-words";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { capitalizeFirstLetter } from "devextreme-react/cjs/core/helpers";

function SubscriptionInvoice({
  openInvoice,
  datas,
  setOpenInvoice,
  Buttons = true,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [organizationData, setorganisation] = useState({});
  const [pdfname, setpdfname] = useState("");
  const [loading, setLoading] = useState(false);
  const reportTemplateRef = useRef(null);

  // get datas
  const token = localStorage.getItem("accesstoken");
  const UserId = localStorage.getItem("UserId");
  const item = "crmuseradmin1";

  const header = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const handleDownload = useReactToPrint({
    content: () => reportTemplateRef?.current,
    documentTitle: `Customer Report ${new Date().toJSON().slice(0, 10)}`,
    onPrintError: (error) => console.log("error", error),
    pageStyle: `
            @page {
              size: landscape;
            }
          `,
    removeAfterPrint: true, // Remove content after printing
  });

  const sendInvoiceMail = async (type) => {
    setLoading(true);
    try {
      const contentElement = reportTemplateRef.current;

      const canvas = await html2canvas(contentElement);
      const imageData = canvas.toDataURL("image/jpeg", 1.0);

      const pdf = new jsPDF("portrait", "mm", "a4");
      const a4Width = 210;
      const a4Height = 297;
      const margin = 10;

      // Create an Image object to get the natural dimensions
      const img = new Image();
      img.src = imageData;
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      // Calculate aspect ratio
      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;
      const imgAspectRatio = imgWidth / imgHeight;

      // Calculate dimensions to fit within A4 while preserving aspect ratio
      let newWidth = a4Width - margin * 2;
      let newHeight = newWidth / imgAspectRatio;

      if (newHeight > a4Height - margin * 2) {
        newHeight = a4Height - margin * 2;
        newWidth = newHeight * imgAspectRatio;
      }

      // Add the image to the PDF
      pdf.addImage(imageData, "JPEG", margin, margin, newWidth, newHeight);

      const customFilename = `${generateRandomColor()}${generateRandomColor()}${pdfname}.pdf`;

      // Convert the PDF to base64
      const base64Data = pdf.output("datauristring").split(",")[1];

      const payload = {
        File: base64Data,
        FileName: customFilename,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Urls: "null",
        Description: "Test File",
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };

      const response = await axios.post(
        `${BASE_URL}/user/UserFilesandUrlsCRUD/`,
        payload,
        header
      );

      console.log("Uploaded PDF:", response.data);

      setSelectedFiles(response.data);

      // Send email with the generated PDF attached
      await MailSendFun(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage);
      } else {
        console.error("An error occurred while sending the email:", error);
        toast.error(`${error}`);
      }
      setLoading(false);
    }
  };

  const mailBodyContent = async (selectedFiles) => {
    console.log("mailBodyContent selectedFiles", selectedFiles);
    return `<div style="width: 100%; font-family: Arial, sans-serif;">
  <div>
    <h4 className="text-muted">Dear Sir,</h4>
    <div class='card-text'>
    <p>I hope this mail finds you well. I am writing to request a invoice for our organization for the following Subscription.</p>
    <br />
    <div class='card-text'>
    <p><strong>Customer Details:</strong></p>
    <ul>
      <li><strong>Customer Name:</strong> ${
        datas?.Customer_Id?.Contact_Name
      }</li>
      <li><strong>Organization Name:</strong>${
        datas?.Customer_Id?.Organization_Name || "-"
      }</li>
      <li><strong>Total Amount:</strong> ₹${datas?.NetAmount}</li>
    </ul>
    
  </div>
 
  <br/>

  <p>Click the button to download the attachment</p>
      <ul>
      <div  style="margin:10px 0;">
       <li><strong>File Name :</strong>${selectedFiles?.FileName}</li>
        <a href="${BASE_URL}${selectedFiles?.File}" download=${
      selectedFiles?.File
    } target="_blank">
          <Button>
            Download
          </Button>
        </a>
      </div>

      <li><strong>Terms & Condition:</strong>${datas?.Payment_Terms || "-"}</li>
     
      </ul>
      <br/>
      <p>We appreciate your consideration of Foyer as your subscription provider. We look forward to the possibility of
       working with you and meeting your [subscription] needs.</p>
       <br/>
     
      <p>Best Regards,</p>
      <p>${localStorage.getItem("Username")},</p>
    </div>
  </div>
  </div>`;
  };

  const MailSendFun = async (PDF_URL_Res) => {
    try {
      console.log("selectedFiles in Mailsendfun", selectedFiles);
      const payload = {
        Created_By: parseInt(localStorage.getItem("UserId")),
        To: datas?.Customer_Id?.Mail,
        mailsubject: `Subscription ${datas?.id}`, // Use template literals for string interpolation
        Body: await mailBodyContent(PDF_URL_Res),
        filename: generateRandomColor() + PDF_URL_Res?.FileName,
      };

      console.log("mailpayload", payload);

      const response = await axios.post(
        `${BASE_URL}/user/QuotationMailSending/`,
        payload,
        header
      );

      if (response) {
        toast.success("Mail Sent Successfully");
        setLoading(false);
        setOpenInvoice(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        console.error("An error occurred while sending the email:", error);
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
      setLoading(false);
    }
  };

  const organization = async (sub, item, type) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/useradmin/CRMorganizationCRUD/?id=${localStorage.getItem(
          "OrganizationId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      if (res.data) {
        setorganisation(res.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    organization();
  }, []);

  console.log("Email Invoice : ", datas);
  return (
    <DialogContent sx={{ background: "#FFFFFF", p: "0px" }}>
      <Card
        sx={{
          borderRadius: "5px",
          p: 3,
          minHeight: "80vh",
        }}
      >
        {Buttons && (
          <Div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <CustomButton
              disabled={loading}
              onClick={() => sendInvoiceMail(openInvoice)}
              label="Sent"
              Icon="sendIcon"
              endIcon={true}
            />

            <CustomButton
              onClick={() => handleDownload()}
              label="Download"
              Icon="downloadIcon"
              endIcon={true}
              bgColor={"#E1E4E5"}
              textColor={"#000"}
              width={"135px"}
            />
          </Div>
        )}

        <Div ref={reportTemplateRef}>
          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            {...(true ? { timeout: 1300 } : {})}
          >
            <Div
              sx={[
                {
                  display: "flex !important",
                  justifyContent: "space-between",
                  p: 3,
                  mt: 2,
                  minHeight: "150px",
                  backgroundColor: "#ECF9FF",
                  boxShadow: "2px 2px 5px 0px #d6dedf",
                },
              ]}
            >
              <Div>
                <img
                  src={require("../../assets/images/Part_1.png")}
                  alt="Foyer Logo"
                  width={130}
                  height={50}
                />
                <Typography variant="h4" sx={{ fontWeight: 600, mt: 1 }}>
                  {organizationData?.Organization_Name?.toLocaleUpperCase()}
                </Typography>
              </Div>
              <Div sx={{ textAlign: "center" }}>
                <Typography
                  variant="h1"
                  fontWeight={600}
                  sx={{ color: "#5AB2FF" }}
                >
                  Invoice
                </Typography>
              </Div>
              <Div>
                <Typography
                  variant="h3"
                  sx={[{ fontWeight: "600 !important" }]}
                >
                  Invoice by
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                  sx={[{ color: "#000 !important" }]}
                >
                  {organizationData?.Organization_Name?.toLocaleUpperCase()}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                  sx={[{ color: "#000 !important" }]}
                >
                  {organizationData?.Organization_Address?.Building_Name}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                  sx={[{ color: "#000 !important" }]}
                >
                  {organizationData?.Organization_Address?.Building_Street}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                  sx={[{ color: "#000 !important" }]}
                >
                  {organizationData?.Organization_Address?.Building_Area},
                  {organizationData?.Organization_Address?.Building_City}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px" }}
                  sx={[{ color: "#000 !important" }]}
                >
                  GST NO : {organizationData?.Organization_GST ?? "-"}
                </Typography>
              </Div>
            </Div>
          </Grow>
          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            {...(true ? { timeout: 1300 } : {})}
          >
            <Div
              sx={[
                {
                  display: "flex !important",
                  p: 3,
                  mt: 1,
                  minHeight: "150px",
                  justifyContent: "space-between",
                  boxShadow: "2px 2px 5px 0px #d6dedf",
                },
              ]}
            >
              <Div className="col-4">
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    borderLeft: "4px solid green",
                    fontWeight: "900",
                    p: 1,
                    mb: 2,
                  }}
                >
                  Customer Details
                </Typography>
                <Div sx={{ display: "flex !important" }}>
                  <Div className="col-6">
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Customer Name
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Building/Door No
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Area Name
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Street Name
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      City
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      State
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Country
                    </Typography>
                  </Div>
                  <Div className="col-1">
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                  </Div>
                  <Div className="col-6">
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.Contact_Name)
                        ? datas?.Customer_Id?.Contact_Name
                        : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.Address?.BuildingDoorNo)
                        ? datas?.Customer_Id?.Address?.BuildingDoorNo
                        : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.Address?.AreaName)
                        ? datas?.Customer_Id?.Address?.AreaName
                        : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.Address?.StreetName)
                        ? datas?.Customer_Id?.Address?.StreetName
                        : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.City)
                        ? datas?.Customer_Id?.City
                        : "-"}{" "}
                      - {datas?.Customer_Id?.Address?.Pincode}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.State)
                        ? datas?.Customer_Id?.State
                        : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Customer_Id?.Country)
                        ? datas?.Customer_Id?.Country
                        : "-"}{" "}
                    </Typography>
                  </Div>
                </Div>
              </Div>
              <Div className="col-4"></Div>
              <Div className="col-4">
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    borderLeft: "4px solid green",
                    fontWeight: "900",
                    p: 1,
                    mb: 2,
                  }}
                >
                  Invoice Details
                </Typography>
                <Div sx={{ display: "flex !important" }}>
                  <Div className="col-6">
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Invoice Id
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Invoice Date
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Invoice Amount
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Tax Amount
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      Net Amount
                    </Typography>
                  </Div>
                  <Div className="col-1">
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                    <Typography variant="h4"> : </Typography>
                  </Div>
                  <Div className="col-6">
                    <Typography variant="h4">
                      {Boolean(datas?.Invoice_Id) ? datas?.Invoice_Id : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {Boolean(datas?.Invoice_Date)
                        ? datas?.Invoice_Date?.split("T")[0]
                        : "-"}
                    </Typography>
                    <Typography variant="h4">
                      {toLocaleCurrency(datas?.InvoiceAmount || 0)}
                    </Typography>

                    <Typography variant="h4">
                      {toLocaleCurrency(datas?.TaxAmount || 0)}
                    </Typography>
                    <Typography variant="h4">
                      {toLocaleCurrency(datas?.NetAmount || 0)}
                    </Typography>
                  </Div>
                </Div>
              </Div>
            </Div>
          </Grow>

          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            {...(true ? { timeout: 1300 } : {})}
          >
            <Div
              sx={{
                height: "auto",
                backgroundColor: "#ECF9FF",
                p: 2,
                boxShadow: "2px 2px 5px 0px #d6dedf",
              }}
            >
              <Div
                sx={[
                  {
                    display: "flex !important",
                    justifyContent: "space-between",
                  },
                ]}
              >
                <Div className="col-4">
                  <Div sx={{ display: "flex !important" }}>
                    <Div className="col-6">
                      <Typography
                        sx={[
                          ComponentHeading,
                          {
                            fontSize: "14px !important",
                            mt: 1,
                            color: "#000 !important",
                          },
                        ]}
                      >
                        Term and Condition
                      </Typography>
                      <Typography sx={[FontStyleINV, { mt: 1 }]}>
                        {datas?.Terms_Condition || "-"}
                      </Typography>
                    </Div>
                  </Div>
                </Div>
                <Div className="col-4"></Div>
                <Div className="col-4">
                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      fontWeight: "900",
                      mb: 2,
                    }}
                  >
                    Our Bank Details
                  </Typography>
                  <Div sx={{ display: "flex !important" }}>
                    <Div className="col-6">
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Beneficiary A/C Name
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Beneficiary A/C No.
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Beneficiary Bank Name
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        IFSC CODE
                      </Typography>
                    </Div>
                    <Div className="col-1">
                      <Typography variant="h4"> : </Typography>
                      <Typography variant="h4"> : </Typography>
                      <Typography variant="h4"> : </Typography>
                      <Typography variant="h4"> : </Typography>
                    </Div>
                    <Div className="col-6">
                      <Typography variant="h4"></Typography>
                      <Typography variant="h4"></Typography>
                      <Typography variant="h4"></Typography>
                      <Typography variant="h4"></Typography>
                      <Typography variant="h4"></Typography>
                    </Div>
                  </Div>
                </Div>
              </Div>

              <Div sx={{ mt: 2 }}>
                <Typography
                  sx={[
                    {
                      fontSize: "13px !important",
                      fontWeight: "400 !important",
                      color: "#C0C0C0",
                    },
                  ]}
                >
                  For any enquiries,email us on{" "}
                  <span
                    style={{ color: "black", fontWeight: "600 !important" }}
                  >
                    {organizationData?.Organization_Mail}
                  </span>{" "}
                  or call us on{" "}
                  <span
                    style={{ color: "black", fontWeight: "600 !important" }}
                  >
                    {
                      organizationData?.Organization_Contact_Number
                        ?.Org_Contact_NO_1
                    }
                  </span>
                </Typography>
              </Div>
            </Div>
          </Grow>
        </Div>
      </Card>
    </DialogContent>
  );
}

export default SubscriptionInvoice;
