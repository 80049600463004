import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  Card,
  Box,
  Stack,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { CloseIcon, SettingsIcon } from "app/icons/PngIcons";
import React, { useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState, useRef } from "react";
import { BsArrowDownUp } from "react-icons/bs";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEdit from "@mui/icons-material/ModeEdit";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadFromWebEdit from "./LeadFromWebEdit";
import colors from "Colors";
import { CRMTableCell, CRMTableRow } from "../widgets/CRMTable";
import {
  Colors,
  DisplayFlex,
  FontStyle,
  avatarColors,
} from "../widgets/CRMStyles";
import {
  AvatarCircle,
  DialogBox,
  DeletedIcon,
  EditIcon,
} from "../widgets/StylesComponents";
import CustomTableHeader from "../widgets/CustomTableHeader";
import { AutoHeight } from "app/redux/auth/localStorage";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { useQuery, useQueryClient } from "react-query";

const GenerateLeads = ({
  scrollHeight,
  setGenerateLeads,
  setLeadlist,
  updateid,
  setUpdateId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // For Settings Icon Function
  const [datas, setDatas] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [save, setSave] = useState(false);
  const [deleteRow, setdeleteRow] = useState(false);

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "LeadFirstName", label: "Lead Name", bool: true },
    { sort: "PhoneNo", label: "Phone", bool: true },
    { sort: "EmailId", label: "Email", bool: true },
    { sort: "Lead_Owner", label: "Lead Owner", bool: true },
    { sort: "LeadIndustry", label: "Industry Type", bool: true },
    { sort: "CompanyName", label: "Company Name", bool: true },
    { sort: "City", label: "City", bool: true },
    { sort: "State", label: "State", bool: true },
    { sort: "Country", label: "Country", bool: true },
  ]);

  const [showDelete, setShowDelete] = useState(false);

  const matches = useMediaQuery("(min-width:759px)");

  const [daletingData, setDaletingData] = useState("");
  //  For Pagination Table
  const [page, setPage] = useState(1);
  const count = 10;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = datas?.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  // For Asecnding Order

  const [showsort, setShowsort] = useState(true);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortData = (key, direction) => {
    const sortedData = [...datas];

    sortedData.sort((a, b) => {
      const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setDatas(sortedData);
  };

  // for Generating Leads
  const [scrap, setScrap] = useState(false);
  const [location, setLocation] = useState("");
  const [industry, setIndustry] = useState("");

  // generate Leads

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const payload = {
        Location: location,
        Industry: industry,
        Organization_Id: localStorage.getItem("OrganizationId"),
        UserId: localStorage.getItem("UserId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };

      const response = await axios.post(
        `${BASE_URL}/user/LeadsGenrate/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("res.data.results", response.data);
      toast.success("Lead Scarped SuccessFully", {
        position: "top-right",
        autoClose: 100,
      });
      // getData();
      setIsLoading(false);
      setScrap(false);
    } catch (error) {
      console.error("An error occurred:", error.message);
      setIsLoading(false);
      // Handle the error as needed (e.g., show an error message to the user)
    } finally {
      // Stop loading, whether it was successful or not
    }
  };

  console.log("updateid", updateid);

  const updateData = async (updateid) => {
    try {
      const response1 = await axios.get(
        `${BASE_URL}/user/UserLeadListCRUD/?id=${updateid}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // console.log("resghh", response1.data.results[0].Leads);
      // setDatas(response1.data.results[0].Leads);
      // handleCloseAction();
      return response1?.data?.results;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  // Handle Update For Update Data
  const [update, setUpdate] = useState(0);

  //  Delete Data
  const [number, setNumber] = useState(null);

  const Deletedata = async (data) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/user/LeadCRUD/?id=${number}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setScrap(false);
        setSave(false);
        setAnchorElAction(false);
        getLeadListsRefetch();
      } else {
        console.error("Failed to delete data:", response);
      }
    } catch (error) {
      console.error("An error occurred while deleting data:", error);
    }
  };

  // For Edit Functionality
  const [openAddLead, setOpenAddLead] = useState(false);

  // Web Update
  const [webupdate, setWebUpdate] = useState("");

  const handleWebUpdate = (data) => {
    console.log("data", data);
    setWebUpdate(data);
  };

  // List of Country
  const [lookupdata, setLookupdata] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const Loader = () => (
    <div className="loader">
      <CircularProgress />
    </div>
  );

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUp"], getlookupData, { staleTime: Infinity });

  const {
    data: getLeadLists,
    isLoading: getLeadListsLoading,
    isError: getLeadListsError,
    refetch: getLeadListsRefetch,
  } = useQuery(["getLeadLists", updateid], () => updateData(updateid), {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (getUserLoopUp) {
      setLookupdata(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type")
      );
    }
  }, [getUserLoopUp]);

  useEffect(() => {
    if (getLeadLists) {
      setDatas(getLeadLists[0]?.Leads);
      handleCloseAction();
    }
  }, [getLeadLists]);

  console.log("getLeadLists", getLeadLists, datas);
  return (
    <>
      <Div>
        <div style={{ position: "relative" }}>
          <Dialog
            open={scrap}
            onClose={() => setScrap(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "18px",
                backgroundColor: colors?.TableHeader,
                color: "white",
                pt: 2,
              }}
            >
              <Typography sx={{ fontSize: "18px", verticalAlign: "middle" }}>
                Scrap Lead From Web
              </Typography>
              <IconButton
                onClick={() => setScrap(false)}
                sx={{ float: "right" }}
              >
                <CloseIcon size={24} color="white" />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Card>
                    <Box m={3}>
                      <Stack direction={"column"} spacing={1} m={2}>
                        <Stack>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "black",
                              mb: 0.2,
                            }}
                          >
                            Location
                          </Typography>
                          <TextField
                            className="col-md-12 input-box2 mt-1"
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                            value={location}
                            id="outlined-basic"
                            placeholder="Location"
                            variant="outlined"
                          />
                        </Stack>
                        <Stack>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "black",
                              mb: 0.2,
                            }}
                          >
                            Industry type
                          </Typography>
                          <Autocomplete
                            className="search-select3"
                            getOptionLabel={(options) => {
                              return options["value"];
                            }}
                            getOptionValue={(options) => {
                              return options["value"];
                            }}
                            options={lookupdata?.Values}
                            onChange={(e, newValue) => {
                              console.log("lookupdata", lookupdata.Values);
                              console.log("newValue", newValue);
                              setIndustry(newValue?.value);
                            }}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  borderRadius: "5px",
                                }}
                                placeholder="Industry type"
                              />
                            )}
                          />
                        </Stack>
                        <Stack></Stack>
                      </Stack>
                      <Div
                        sx={{
                          display: "flex",
                          float: "right",
                          my: 2,
                        }}
                      >
                        <Button
                          type="submit"
                          sx={{
                            alignContent: "center",
                            justifyContent: "center",
                            mr: 2,
                            backgroundColor: colors?.TableHeader,
                            p: 1,
                            borderRadius: "5px",
                            color: "#fff",
                            ":hover": {
                              backgroundColor: colors?.TableHeader,
                              color: "#fff",
                            },
                          }}
                        >
                          Script it Now
                        </Button>
                        <Button
                          className="cancel-button"
                          autoFocus
                          onClick={() => {
                            setScrap(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Div>
                      {isLoading && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.7)", // You can adjust the background color and opacity
                            zIndex: 1, // Ensure it's above the dialog
                          }}
                        >
                          <Loader />
                        </div>
                      )}
                    </Box>
                  </Card>
                </DialogContentText>
              </DialogContent>
            </form>
          </Dialog>
        </div>

        <Div
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <Div
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              my: 2,
            }}
          >
            <IconButton
              sx={{
                fontSize: "13px",
                backgroundColor: "rgba(185, 185, 185,1)",
              }}
              onClick={() => {
                setGenerateLeads(false);
                setLeadlist(true);
              }}
            >
              <AiOutlineArrowLeft color={colors?.TableHeader} />
            </IconButton>
            <Typography
              variant="subtitle"
              sx={{
                fontWeight: 900,
                fontSize: "16px",
                color: "#000000",
              }}
            >
              Lead generation (Web)
            </Typography>
          </Div>
          {/* <Stack direction={"row"} spacing={2}>
            <Button
              className="dialog-save-btn"
              onClick={() => {
                setScrap(true);
              }}
            >
              Scrap It Now
            </Button>
          </Stack> */}
        </Div>

        <JumboScrollbar
          autoHeight={true}
          autoHideTimeout={4000}
          autoHeightMin={AutoHeight(1.6)}
          autoHide={true}
          hideTracksWhenNotNeeded
          id="no-more-tables"
        >
          <Table className="">
            <CustomTableHeader
              headerMenuItems={columnMenuItems}
              setHeadermenuItems={setColumnMenuItems}
              setDatas={setDatas}
              sortDatas={datas}
            />

            <TableBody className="table-body" sx={{ mt: 2 }}>
              {datas?.length > 0 &&
                datas
                  ?.slice(
                    page == 1 ? page * 0 : (page - 1) * 10,
                    page == 1 ? page * 0 + 10 : (page - 1) * 10 + 10
                  )
                  ?.map((data, index) => {
                    let avatar =
                      index >= 10
                        ? avatarColors[index % 10]
                        : avatarColors[index];

                    // console.log("datadata :",data)
                    return (
                      <CRMTableRow>
                        {columnMenuItems[0]?.bool && (
                          <CRMTableCell
                            data-title="Contact Name"
                            sx={{ cursor: "pointer" }}
                          >
                            <FormControlLabel
                              control={
                                <Div sx={{ mx: 1 }}>
                                  <AvatarCircle
                                    title={data?.LeadFirstName}
                                    bgColor={avatar}
                                  />
                                </Div>
                              }
                              label={
                                <Typography
                                  sx={[
                                    FontStyle,
                                    { ml: { xs: 1.5, md: 0, lg: 0 } },
                                  ]}
                                >
                                  {data?.LeadFirstName}
                                </Typography>
                              }
                            />
                          </CRMTableCell>
                        )}
                        {columnMenuItems[1]?.bool && (
                          <CRMTableCell data-title="Phone No">
                            <Div sx={[DisplayFlex]}>
                              <Typography
                                sx={{
                                  display: {
                                    xs: "none !important",
                                    md: "block !important",
                                    lg: "block !important",
                                  },
                                }}
                              >
                                <IoCallOutline
                                  line
                                  size={16}
                                  color={Colors?.Mblue}
                                />
                              </Typography>
                              {data?.PhoneNo?.split(":")[1] || data?.PhoneNo}
                            </Div>
                          </CRMTableCell>
                        )}

                        {columnMenuItems[2]?.bool && (
                          <CRMTableCell
                            data-title="Email Id"
                            sx={[DisplayFlex, { flexWrap: "nowrap" }]}
                          >
                            <Div sx={[DisplayFlex]}>
                              <Typography
                                sx={{
                                  display: {
                                    xs: "none !important",
                                    md: "block !important",
                                    lg: "block !important",
                                  },
                                }}
                              >
                                <CiMail size={16} color={Colors?.Mblue} />
                              </Typography>
                              {data?.EmailId}
                            </Div>
                          </CRMTableCell>
                        )}

                        {columnMenuItems[3]?.bool && (
                          <CRMTableCell data-title="Compnay Name">
                            {data?.Lead_Owner}
                          </CRMTableCell>
                        )}
                        {columnMenuItems[4]?.bool && (
                          <CRMTableCell data-title="Industry Type">
                            {data?.LeadIndustry}
                          </CRMTableCell>
                        )}
                        {columnMenuItems[5]?.bool && (
                          <CRMTableCell data-title="Compnay Name">
                            {data?.CompanyName}
                          </CRMTableCell>
                        )}
                        {columnMenuItems[6]?.bool && (
                          <CRMTableCell data-title="City">
                            {data?.City}
                          </CRMTableCell>
                        )}
                        {columnMenuItems[7]?.bool && (
                          <CRMTableCell data-title="State">
                            {data?.State}
                          </CRMTableCell>
                        )}
                        {columnMenuItems[8]?.bool && (
                          <CRMTableCell data-title="Country">
                            {data?.Country}
                          </CRMTableCell>
                        )}
                        <CRMTableCell
                          align="center"
                          data-title="Action"
                          sx={[
                            DisplayFlex,
                            {
                              cursor: "pointer",
                              mb: 1,
                              justifyContent: matches ? "center" : "left",
                            },
                          ]}
                        >
                          <EditIcon
                            onClick={() => {
                              setOpenAddLead(true);
                              setUpdate(data);
                              setNumber(data?.id);
                              handleWebUpdate(data);
                            }}
                          />

                          <DeletedIcon
                            onClick={() => {
                              setNumber(data?.id);
                            }}
                          />
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
            </TableBody>
            <DialogBox
              open={showDelete}
              onClickNo={() => setShowDelete(false)}
              onClickYes={() => {
                setShowDelete(true);
                Deletedata();
              }}
            />
          </Table>
        </JumboScrollbar>

        <Div
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Pagination
            color="success"
            count={Math.ceil(datas?.length / 10)}
            page={page}
            onChange={(e, newValue) => handleChangePage(e, newValue)}
            size="small"
            showFirstButton
            showLastButton
          />
        </Div>

        <></>
        <>
          <LeadFromWebEdit
            setOpenAddLead={setOpenAddLead}
            openAddLead={openAddLead}
            webupdate={webupdate}
            getLeadListsRefetch={getLeadListsRefetch}
            anchorElAction={anchorElAction}
            setAnchorElAction={setAnchorElAction}
          />
        </>
      </Div>

      {/* delete dialog box */}
      <DialogBox
        open={deleteRow}
        onClickNo={() => setdeleteRow(false)}
        onClickYes={() => {
          setdeleteRow(false);
          Deletedata(daletingData);
        }}
      />
    </>
  );
};

export default GenerateLeads;
