import Div from '@jumbo/shared/Div'
import React, { useEffect, useState } from 'react'
import CreateIcon from '@mui/icons-material/Create';
import { Box, IconButton, Input, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FBPostDialog from './FBPostDialog';
import ImageDialog from './ImageDialog';
import VideoDialog from './VideoDialog';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import FBContactForm from './FBContactForm';

const FBFeed = ({ FBpageAccessToken, setFBPageAccessToken }) => {

    const [isPostDialogOpen, setIsPostDialogOpen] = useState(false)
    const [isImageDialogOpen, setIsImageDialogOpen] = useState(false)
    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false)
    const [isFBContactFormOpen, setIsFBContactFormOpen] = useState(false)
    const [pageAccessToken, setPageAccessToken] = useState(FBpageAccessToken)

    useEffect(() => {
        setPageAccessToken(FBpageAccessToken)
    }, [FBpageAccessToken])

    return (
        <Div sx={{ backgroundColor: 'white', height: '30%', width: '500px', borderRadius: '3%' }}>
            {console.log("videoDialog", isVideoDialogOpen)}
            <Div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: '#EEF5FF' }}>
                <Div sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton><CreateIcon /></IconButton>
                    <Typography >Make Post</Typography>
                </Div>
                <Div sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton><ImageIcon /></IconButton>
                    <Typography >Photo/Video Album</Typography>
                </Div>
                <Div sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton><VideocamIcon /></IconButton>
                    <Typography >Live Video</Typography>
                </Div>
            </Div>
            <Div sx={{ display: 'flex', alignItems: 'center', margin: '10px', borderBottom: '2px solid #e6e3e3', padding: '10px' }}>
                <AccountCircleIcon sx={{ fontSize: '40px' }} />
                <Input onClick={() => { setIsPostDialogOpen(true) }} type='text' placeholder={`what's on your mind?`} sx={{ ":before": { border: 'none', ":hover": { borderBottom: 'none !important' } }, ":after": { border: 'none' }, ":hover": { border: 'none', borderWidth: 0 }, outline: 'none', borderWidth: 0 }} ></Input>
            </Div>
            <Div sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <Div onClick={() => { setIsImageDialogOpen(true) }} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <IconButton><ImageIcon /></IconButton>
                    <Typography>Photos</Typography>
                </Div>
                {/* <Div onClick={() => { setIsVideoDialogOpen(true) }} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <IconButton><VideoFileIcon /></IconButton>
                    <Typography>Videos</Typography>
                </Div> */}
                <Div onClick={() => { setIsFBContactFormOpen(true) }} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                    <IconButton><ContactMailIcon /></IconButton>
                    <Typography>Contact Form</Typography>
                </Div >
                {/* <Div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <IconButton><TagFacesIcon /></IconButton>
                    <Typography>Feeling/Activity</Typography>
                </Div> */}
                <Div>
                    <IconButton><MoreHorizIcon /></IconButton>
                </Div>
            </Div>
            {isPostDialogOpen && <FBPostDialog isPostDialogOpen={isPostDialogOpen} setPostDialogOpen={setIsPostDialogOpen} pageAccessToken={pageAccessToken}></FBPostDialog>}
            {isImageDialogOpen && <ImageDialog isImageDialogOpen={isImageDialogOpen} setIsImageDialogOpen={setIsImageDialogOpen} pageAccessToken={pageAccessToken}></ImageDialog>}
            {isVideoDialogOpen && <VideoDialog isVideoDialogOpen={isVideoDialogOpen} setIsVideoDialogOpen={setIsVideoDialogOpen} pageAccessToken={pageAccessToken}></VideoDialog>}
            {isFBContactFormOpen && <FBContactForm isFBContactFormOpen={isFBContactFormOpen} setIsFBContactFormOpen={setIsFBContactFormOpen} pageAccessToken={pageAccessToken}></FBContactForm>}
        </Div>
    )
}

export default FBFeed