import React from 'react'
import GCAPI from './GCAPI'

const GoogleCalendar = () => {
    
    return (
        <div>
            <GCAPI></GCAPI>
        </div>
    )
}

export default GoogleCalendar