import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain'
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Select,
  IconButton,
  TextareaAutosize,

} from "@mui/material";
import Setting from '../Setting';
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import React, { useEffect, useState } from 'react'
import { MdDelete } from "react-icons/md";
import { TableBody } from '@material-ui/core';
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import InputAdornment from '@mui/material/InputAdornment';
import { ButtonStyle, ComponentHeading, DialogBoxCancelButton, DialogBoxSaveButton, FontStyle } from 'app/pages/widgets/CRMStyles';
import { Cancelbutton, CustomButton } from 'app/pages/widgets/StylesComponents';

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 55,
    label: '55',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 65,
    label: '65',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 85,
    label: '85',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 95,
    label: '95',
  },
  {
    value: 100,
    label: '100',
  },
];
const minDistance = 10;
function LeadStatus() {
  const [value, setValue] = useState([0, 10]);
  const [colddatas, setcolddatas] = useState([])
  const [warmdatas, setwarmdatas] = useState([])
  const [hotdatas, sethotdatas] = useState([])
  const [coldvalue, setcoldvalue] = useState("")
  const [warmvalue, setwarmvalue] = useState("")
  const [hotvalue, sethotvalue] = useState("")
  const [datas, setdatas] = useState([])
  const [id, setid] = useState("")
  const createrId = localStorage.getItem("UserId");


  const [coldbut, setcoldbut] = useState(true)
  const [warmbut, setwarmbut] = useState(false)
  const [hotbut, sethotbut] = useState(false)

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  }

  const getStatusRecord = () => {
    axios
      .get(`${BASE_URL}/useradmin/UserAdminStatusQuestionsAdditional/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "db": localStorage.getItem("DBName")
        },
      })
      .then((res) => {
        console.log("Status Question", res.data.results);
        let arr = res.data.results
        let userarr = []
        arr.map((item, index) => {
          if (item.Created_By == createrId) {
            userarr.push(item)
          }
        })
        console.log("user", userarr)
        userarr.map((item, index) => {
          if (item.Status === "Cold") {
            setcolddatas(item)
            setcoldbut(true)
            setwarmbut(false)
            sethotbut(false)
            setValue([item.ScoreRange[0], item.ScoreRange[1]])
            const a = item.ScoreRange[0] + "-" + item.ScoreRange[1]
            setcoldvalue(a)
          }
          if (item.Status === "Warm") {
            setwarmdatas(item)
            const a = item.ScoreRange[0] + "-" + item.ScoreRange[1]
            setwarmvalue(a)
          }
          if (item.Status === "Hot") {
            sethotdatas(item)
            const a = item.ScoreRange[0] + "-" + item.ScoreRange[1]
            sethotvalue(a)
          }
        })
        // console.log(startvalue.ScoreRange[0])
        // setValue([startvalue.ScoreRange[0],startvalue.ScoreRange[1]])


      })
      .catch((error) => {
        console.log("ds", error);
      });
  }


  useEffect(() => {
    getStatusRecord()
  }, [])

  const handleUpdateLeadStatus = (e) => {
    e.preventDefault();

    if (value[0] == "" || value[0] === undefined || value[0] === null ||
      value[1] == "" || value[1] === undefined || value[1] === null) {
      toast.error("Invalid Data ");
    } else {

      if (value[0] < 30 && value[1] <= 30) {
        if (colddatas.id === undefined || colddatas.id === null || colddatas.id === "") {
          console.log("coldpost")
          let playload = {
            ScoreRange: [
              value[0],
              value[1]
            ],
            Status: "Cold",
            Created_By: createrId,
            Updated_By: createrId
          }
          axios
            .post(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("put", res.data);
              if (res.data.id !== undefined) {
                getStatusRecord()
                toast.success("Cold Score Created Successfully");
              } else {
                toast.error("Something went wrong");
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        } else {
          let playload = {
            id: colddatas.id,
            ScoreRange: [
              value[0],
              value[1]
            ],
            Status: colddatas.Status,
            Created_By: createrId,
            Updated_By: createrId
          }
          axios
            .put(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("put", res.data);
              if (res.data.id === colddatas.id) {
                getStatusRecord()
                toast.success("Cold Score Updated Successfully");
              } else {
                toast.error("Something went wrong");
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        }

      }
      else if (value[0] >= 30 && value[0] < 70 && value[1] > 30 && value[1] <= 70) {
        if (warmdatas.id === undefined || warmdatas.id === null || warmdatas.id === "") {
          console.log("warmpost")
          let playload = {
            ScoreRange: [
              value[0],
              value[1]
            ],
            Status: "Warm",
            Created_By: createrId,
            Updated_By: createrId
          }
          axios
            .post(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("put", res.data);
              if (res.data.id !== undefined) {
                getStatusRecord()
                toast.success("Warm Score Created Successfully");
              } else {
                toast.error("Something went wrong");
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        } else {
          let playload = {
            id: warmdatas.id,
            ScoreRange: [
              value[0],
              value[1]
            ],
            Status: warmdatas.Status,
            Created_By: createrId,
            Updated_By: createrId
          }
          axios
            .put(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("put", res.data);
              if (res.data.id === warmdatas.id) {
                getStatusRecord()
                toast.success("Warm Score Updated Successfully");
              } else {
                toast.error("Something went wrong");
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        }

      }

      else if (value[0] >= 70 && value[0] < 100 && value[1] > 70 && value[1] <= 100) {
        if (hotdatas.id === undefined || hotdatas.id === null || hotdatas.id === "") {
          console.log("hotpost")
          let playload = {
            ScoreRange: [
              value[0],
              value[1]
            ],
            Status: "Hot",
            Created_By: createrId,
            Updated_By: createrId
          }
          axios
            .post(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("put", res.data);
              if (res.data.id !== undefined) {
                getStatusRecord()
                toast.success("Hot Score Created Successfully");
              } else {
                toast.error("Something went wrong");
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        } else {
          let playload = {
            id: hotdatas.id,
            ScoreRange: [
              value[0],
              value[1]
            ],
            Status: hotdatas.Status,
            Created_By: createrId,
            Updated_By: createrId
          }
          axios
            .put(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
                "Content-Type": "application/json",
                Accept: "application/json",
                "db": localStorage.getItem("DBName")
              },
            })
            .then((res) => {
              console.log("put", res.data);
              if (res.data.id === hotdatas.id) {
                getStatusRecord()
                toast.success("Hot Score Updated Successfully");
              } else {
                toast.error("Something went wrong");
              }
              // setdatas(res.data.results)

            })
            .catch((error) => {
              console.log("ds", error);
            });
        }

      } else {
        toast.error("Invalid Data")
      }

    }
  }

  const handlePostLeadStatus = () => {
    console.log("Post")
    if (value[0] == "" || value[0] === undefined || value[0] === null ||
      value[1] == "" || value[1] === undefined || value[1] === null) {
      toast.error("Invalid Data ");
    } else {

      if (value[0] < 30 && value[1] <= 30) {
        console.log("cold")
        let playload = {
          ScoreRange: [
            value[0],
            value[1]
          ],
          Status: colddatas.Status,
          Created_By: createrId,
          Updated_By: createrId
        }
        axios
          .post(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              "db": localStorage.getItem("DBName")
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === colddatas.id) {
              getStatusRecord()
              toast.success("Cold Score Updated Successfully");
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)

          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
      else if (value[0] >= 30 && value[0] < 70 && value[1] > 30 && value[1] <= 70) {
        console.log("warm")
        console.log("put")
        let playload = {
          ScoreRange: [
            value[0],
            value[1]
          ],
          Status: warmdatas.Status,
          Created_By: createrId,
          Updated_By: createrId
        }
        axios
          .post(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              "db": localStorage.getItem("DBName")
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === warmdatas.id) {
              getStatusRecord()
              toast.success("Warm Score Updated Successfully");
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)

          })
          .catch((error) => {
            console.log("ds", error);
          });
      }
      else if (value[0] >= 70 && value[0] < 100 && value[1] > 70 && value[1] <= 100) {
        console.log("Hot")
        console.log("put")
        let playload = {
          ScoreRange: [
            value[0],
            value[1]
          ],
          Status: hotdatas.Status,
          Created_By: createrId,
          Updated_By: createrId
        }
        axios
          .post(`${BASE_URL}/useradmin/UserAdminStatusQuestionsCRUD/`, playload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
              "db": localStorage.getItem("DBName")
            },
          })
          .then((res) => {
            console.log("put", res.data);
            if (res.data.id === hotdatas.id) {
              getStatusRecord()
              toast.success("Hot Score Updated Successfully");
            } else {
              toast.error("Something went wrong");
            }
            // setdatas(res.data.results)

          })
          .catch((error) => {
            console.log("ds", error);
          });
      } else {
        toast.error("Invalid Data")
      }

    }
  }

  const handlecold = () => {
    setcoldbut(true)
    setwarmbut(false)
    sethotbut(false)
    setValue([colddatas.ScoreRange[0], colddatas.ScoreRange[1]])
  }

  const handlewarm = () => {
    setcoldbut(false)
    setwarmbut(true)
    sethotbut(false)
    setValue([warmdatas.ScoreRange[0], warmdatas.ScoreRange[1]])

  }


  const handlehot = () => {
    setcoldbut(false)
    setwarmbut(false)
    sethotbut(true)
    setValue([hotdatas.ScoreRange[0], hotdatas.ScoreRange[1]])
  }


  return (
    <>
      <JumboContentLayoutMain>
        <Setting />
        <Typography sx={[ComponentHeading]}>
          Lead Scoring Rules Setup
        </Typography>
        <form >
          <div>
            <Div sx={{
              display: "flex",
              justifyContent: "end",
              mt: -3,
              mr: 0.5,
              height: "40px",
            }}
            >

            </Div>
            <div>
              <Grid container alignItems={'center'} sx={{ mt: 3 }}>
                <Grid item xs={4} md={4} >
                  <Div sx={{ height: 15, background: 'linear-gradient(to right ,#2932AC, #4ADDEE, #3CBF5F)', borderBottomLeftRadius: 10, borderTopLeftRadius: 10 }}>

                  </Div>
                  <Div sx={[FontStyle, { display: 'flex', justifyContent: 'center', mt: 2 }]}>
                    Cold Score Less Than (30)
                  </Div>
                </Grid>

                <Grid item xs={4} md={4} >
                  <Div sx={{ height: 15, background: 'linear-gradient(to right , #3CBF5F,#F4F965)' }}>

                  </Div>
                  <Div sx={[FontStyle, { display: 'flex', justifyContent: 'center', mt: 2 }]}>
                    Warm Score Upto (73)
                  </Div>
                </Grid>

                <Grid item xs={4} md={4} >
                  <Div sx={{ height: 15, background: 'linear-gradient(to right , #F4F965,#E51414)', borderBottomRightRadius: 10, borderTopRightRadius: 10 }}>

                  </Div>
                  <Div sx={[FontStyle, { display: 'flex', justifyContent: 'center', mt: 2 }]}>
                    Hot Score Upto (99)
                  </Div>
                </Grid>

              </Grid>

              <Div sx={{ mt: 3 }}>
                <Typography sx={[ComponentHeading,]} >
                  Scores
                </Typography>

                <Div sx={[FontStyle, { display: 'flex', justifyContent: 'center', mt: 2 }]}>
                  <Box sx={{ width: 950, height: 10 }}>
                    <Slider
                      getAriaLabel={() => 'Temperature range'}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      //  getAriaValueText={valuetext}
                      marks={marks}
                    />
                  </Box>
                </Div>
              </Div>

              <Grid container sx={{ mt: 5 }}>
                <Grid item xs={4} md={4} >

                  <Div sx={{ display: 'flex', justifyContent: 'center', mt: 5, flexDirection: 'row' }}>
                    <Button onClick={handlecold}>
                      <Typography sx={[FontStyle, { fontWeight: 400, color: coldbut === true ? "#10BBE5" : "#000" }]}>
                        Cold
                      </Typography>

                    </Button>
                    <Div sx={{ mt: 1 }}>
                      <Typography sx={[FontStyle,]} >
                        (Score less than 30 )
                      </Typography>

                    </Div>
                  </Div>
                </Grid>

                <Grid item xs={4} md={4} >

                  <Div sx={{ display: 'flex', justifyContent: 'center', mt: 5, flexDirection: 'row' }}>
                    <Button onClick={handlewarm}>
                      <Typography sx={[FontStyle, { fontWeight: 400, color: warmbut === true ? "#10BBE5" : "#000" }]}>
                        Warm
                      </Typography>

                    </Button>
                    <Div sx={{ mt: 1 }}>
                      <Typography sx={[FontStyle,]} >
                        (Score  30 to 70 )
                      </Typography>

                    </Div>
                  </Div>
                </Grid>

                <Grid item xs={4} md={4} >

                  <Div sx={{ display: 'flex', justifyContent: 'center', mt: 5, flexDirection: 'row' }}>
                    <Button onClick={handlehot}>
                      <Typography sx={[FontStyle, { fontWeight: 400, color: hotbut === true ? "#10BBE5" : "#000" }]}>
                        Hot
                      </Typography>

                    </Button>
                    <Div sx={{ mt: 1 }}>
                      <Typography sx={[FontStyle,]} >
                        (Score 70 to 99 )
                      </Typography>

                    </Div>
                    {/* <TextField
                           label="Hot"
                           id="outlined-start-adornment"
                           sx={{ m: 1, width: '25ch' }}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">70-99</InputAdornment>,
                             }}
                         /> */}
                  </Div>
                </Grid>

              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={4} md={4} >

                  <Div sx={{ display: 'flex', justifyContent: 'center' }} >
                    <TextField
                      label="Cold"
                      value={coldvalue}
                      //  id="outlined-start-adornment"
                      sx={{
                        m: 1, width: '25ch', '& .MuiInputBase-input': {
                          color: '#000000 !important', // Change the text field value color here
                        },
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">0-30</InputAdornment>,
                        style: { color: '#cdcdcd' }
                      }}
                    />
                  </Div>
                </Grid>

                <Grid item xs={4} md={4} >

                  <Div sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TextField
                      label="Warm"
                      value={warmvalue}
                      id="outlined-start-adornment"
                      sx={{
                        m: 1, width: '25ch', '& .MuiInputBase-input': {
                          color: '#000000 !important', // Change the text field value color here
                        },
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">30-70</InputAdornment>,
                      }}
                    />
                  </Div>
                </Grid>

                <Grid item xs={4} md={4} >

                  <Div sx={{ display: 'flex', justifyContent: 'center' }} >
                    <TextField
                      label="Hot"
                      value={hotvalue}
                      id="outlined-start-adornment"
                      sx={{
                        m: 1, width: '25ch', '& .MuiInputBase-input': {
                          color: '#000000 !important', // Change the text field value color here
                        },
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">70-99</InputAdornment>,
                      }}
                    />
                  </Div>
                </Grid>

              </Grid>

              <Div sx={{ mt: 5, display: "flex", justifyContent: 'flex-end', mr: 3, mb: 4 }}>
                <CustomButton
                  onClick={handleUpdateLeadStatus}
                  label="Save"
                  type="submit"
                  Icon="saveIcon"
                  sx={{ mr: 1 }}
                />
                <Cancelbutton
                  label="Cancel"
                />

              </Div>







            </div>
          </div>
        </form>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>

  )
}

export default LeadStatus