import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  Step,
  StepLabel,
  Stepper,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import productCategories from "../../../mock/productCataloque";
import { filter } from "lodash";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import Div from "@jumbo/shared/Div/Div";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  Colors,
  ComponentHeading,
  dialogTitle,
  DisplayFlex,
  FontStyle,
} from "../../widgets/CRMStyles";
import { AutoHeight } from "app/redux/auth/localStorage";
import {
  Cancelbutton,
  CustomButton,
  DeletedIcon,
  DialogBox,
  fetchTransactionSeries,
} from "../../widgets/StylesComponents";
import { useQuery } from "react-query";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_contacts) =>
        _contacts.CategoriesName.toLowerCase().indexOf(query.toLowerCase()) !==
        -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const WorkFlowsetup = ({ setShowCreate, setShowList, page, getDatas }) => {
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [selectedRow, setSelectedRow] = useState({});

  const [addField, setAddField] = useState({
    CategoryName: "",
    Description: "",
  });
  const [errors, setErrors] = useState([]);

  // start functions

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = productCategories.map((n) => n.CategoriesName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleOpenMenu = (e, update) => {
    setSelectedRow(update);
    // console.log("select rows :: ", update)
    setAddField({
      id: update.id,
      CategoryId: update?.ProductCategory_Id,
      CategoryName: update?.Category_Name,
      Description: update.Category_Description,
    });
  };

  const filteredContacts = applySortFilter(
    productCategories,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredContacts.length && !!filterName;

  const clear = () => {
    setAddField({
      CategoryName: "",
      Description: "",
    });
  };

  // Create Work FLow Setup
  const [closeCreateDialog, setCloseCreateDialog] = useState(false);

  // For defining the Users List Stepper Function For the Next

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Work Flow", "Work Flow Triger", "Work Flow Action"];
  const [seriesValue, setSeriesValue] = useState("");

  const handleClickBack = () => {
    setActiveStep(activeStep - 1);
    if (activeStep === 0) {
      setActiveStep(0);
      setCloseCreateDialog(true);
      setShowList(true);
      setShowCreate(false);
    }
  };

  const handleClickNext = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      // setActiveStep(0);
      onSubmit();
    }
  };

  // For WorkFlow Lookups

  const Module = ["Contacts", "Leads", "Deals", "Accounts"];

  const Records = ["Create", "Edit", "Create or Edit", "Delete"];

  const Period = ["Monthly", "Yearly"];

  const Score = ["Increased", "Decreased", "Updated"];

  const Deal = ["Lead Score", "Deal Probability", "Deal Score"];

  const Criteria = [
    "All records that matches criteria",
    "All Records",
    "Records that not mached criteria",
  ];

  const Actions = ["Instant Actions", "Sheduled Actions"];

  const SheduledActions = [
    "Send Email Notification",
    "Create Task",
    "Updated Field",
  ];

  const modifiedvalue = ["is", "isn't", "contains", "start with", "ends with"];

  const modifiedEditvalue = [
    "any value",
    "the value",
    "a value containing",
    "a value start with",
    "a value ends with",
  ];

  const createexcute = ["any", "all"];

  const excutevalues = ["Created Time", "Modified Time"];

  const excutewhen = ["1 Days after", "1 Week after", "1 Month after"];

  const recurvalue = ["Once", "Every Week", "Every Month", "Every Year"];

  const [TaskList, setTaskList] = useState([
    "Create Task",
    "Email Notification",
    "Update Field",
  ]);

  const [mainrecord, setmainrecord] = useState(true);
  const [maindate, setmaindate] = useState(true);
  const [mainscore, setmainscore] = useState(true);

  const [actionrecord, setactionrecord] = useState(false);
  const [actionrecord1, setactionrecord1] = useState(false);
  const [actiondate, setactiondate] = useState(false);
  const [actiondate1, setactiondate1] = useState(false);
  const [actionrule, setactionrule] = useState(false);
  const [Createrecord, setCreaterecord] = useState(false);
  const [Editrecord, setEditrecord] = useState(false);
  const [CreateEditrecord, setCreateEditrecord] = useState(false);
  const [Deleterecord, setDeleterecord] = useState(false);

  const [modifyrecord, setmodifyrecord] = useState(false);
  const [closeRules, setCloseRules] = useState(false);

  const [modifiedrows, setmodifiedrows] = useState([
    {
      selectedfield: "",
      modifiedfield: "",
      enterfield: "",
    },
  ]);

  const [modifiedrows1, setmodifiedrows1] = useState([
    {
      selectedfield: "",
      modifiedfield: "",
      enterfield: "",
    },
  ]);

  const [Excuterows1, setExcuterows1] = useState([]);
  const [Excuterows2, setExcuterows2] = useState([]);

  const [actionrecords, setactionrecords] = useState("");
  const [actiondatevalue, setactiondatevalue] = useState("");

  const handlemainenable = (e) => {
    if (e.target.value === "Action Based on the Records") {
      setAddFields({ ...addfields, basedonrecords: e.target.value });
      setmainrecord(false);
      setmaindate(true);
      setmainscore(true);
    } else if (e.target.value === "Action Based on the Date") {
      setAddFields({ ...addfields, basedondate: e.target.value });
      setmainrecord(true);
      setmaindate(false);
      setmainscore(true);
    } else if (e.target.value === "Action Based on the Score") {
      setAddFields({ ...addfields, score: e.target.value });
      setmainrecord(true);
      setmaindate(true);
      setmainscore(false);
    }
  };

  const RecordOverviewFun = (e, newValue) => {
    setactionrecords(newValue);
    if (newValue === "Create") {
      setRecordType(newValue);
      setCreaterecord(true);
      setEditrecord(false);
      setCreateEditrecord(false);
      setDeleterecord(false);
      setactionrecord(true);
    } else if (newValue === "Edit") {
      setRecordType(newValue);
      setCreaterecord(false);
      setEditrecord(true);
      setCreateEditrecord(false);
      setDeleterecord(false);
      setactionrecord(true);
    } else if (newValue === "Create or Edit") {
      setRecordType(newValue);
      setCreaterecord(false);
      setEditrecord(false);
      setCreateEditrecord(true);
      setDeleterecord(false);
      setactionrecord(true);
    } else if (newValue === "Delete") {
      setRecordType(newValue);
      setCreaterecord(false);
      setEditrecord(false);
      setCreateEditrecord(false);
      setDeleterecord(true);
      setactionrecord(true);
    }
  };

  const recordmodifyfun = (e) => {
    console.log("value1", e.target.value);
    setCreteria(e.target.value);
    if (e.target.value == `${addfields?.module} matching certain conditions`) {
      setmodifyrecord(true);
    } else if (
      e.target.value == `${addfields?.module} not matching certain conditions`
    ) {
      setmodifyrecord(true);
    } else {
      setmodifyrecord(false);
    }
  };

  const [showedit, setShowEdit] = useState(false);

  const recordmodifyeditfun = (e) => {
    console.log("value1", e.target.value);
    setCreteria(e.target.value);
    if (e.target.value == `${addfields?.module} matching certain conditions`) {
      setmodifyrecord(true);
      setShowEdit(true);
    } else if (
      e.target.value == `${addfields?.module} not matching certain conditions`
    ) {
      setmodifyrecord(true);
    } else {
      setShowEdit(false);
    }
  };

  const [showspecific, setShowSpecific] = useState(false);

  const recordmodifyfun1 = (e) => {
    console.log("value check", e.target.value);
    if (e.target.value == "Any Field gets method") {
      setFieldType(e.target.value);
      setShowSpecific(false);

      setmodifyrecord(true);
    } else if (e.target.value == "Specific Field(s) gets method") {
      setFieldType(e.target.value);
      setmodifyrecord(true);
      setShowSpecific(true);
    }
  };

  const handlemodalAddRowcreate = () => {
    const newRow = {
      selectedfield: "",
      modifiedfield: "",
      enterfield: "",
    };
    setmodifiedrows([...modifiedrows, newRow]);
  };

  const handlemodalAddRowEdit = () => {
    const newRow = {
      selectedfield: "",
      modifiedfield: "",
      enterfield: "",
    };
    setmodifiedrows1([...modifiedrows1, newRow]);
  };

  const handlemodalAddRowUpdate = () => {
    const newRow = {
      updatedfield: "",
      updatevalue: "",
    };
    setModifiedUpdate([...modifiedupdate, newRow]);
  };

  const handleDeleteRowcreate = (index) => {
    const updatedRows = modifiedrows.filter((_, i) => i !== index);
    console.log("updatedRows", updatedRows);
    setmodifiedrows(updatedRows);
  };

  const handleDeleteRowUpdate = (index) => {
    const updatedRows2 = modifiedupdate.filter((_, i) => i !== index);
    console.log("updatedRows", updatedRows2);
    setModifiedUpdate(updatedRows2);
  };

  const handleDeleteRowedit = (index) => {
    const updatedRows1 = modifiedrows1.filter((_, i) => i !== index);
    console.log("updatedRows", updatedRows1);
    setmodifiedrows1(updatedRows1);
  };

  const RecordOverviewdateFun = (e, newValue) => {
    setactiondatevalue(newValue);
    setactiondate(true);
  };

  const handleAddRowExcute1 = () => {
    const newRow = {
      Field: "",
      value: "",
      valuetype: true,
      newValue: "",
    };
    setExcuterows1([...Excuterows1, newRow]);
  };

  const handleDeleteacion = (index) => {
    const updatedRows = Excuterows1.filter((_, i) => i !== index);
    console.log("updatedRows", updatedRows);
    setExcuterows1(updatedRows);
  };

  const handleAddRowExcute2 = () => {
    const newRow = {
      Field: "",
      value: "",
      valuetype: true,
      newValue: "",
    };
    setExcuterows2([...Excuterows2, newRow]);
  };

  const handleDeleteacion2 = (index) => {
    const updatedRows = Excuterows2.filter((_, i) => i !== index);
    console.log("updatedRows", updatedRows);
    setExcuterows2(updatedRows);
  };

  // Use State for the WorkFlow Conditions

  const [addfields, setAddFields] = useState({
    module: "",
    rulename: "",
    description: "",
    basedonrecords: "",
    basedondate: "",
    mentiondate: "",
    score: "",
    criteria: "",
    scheduledaction: "",
    action: "",
  });

  // For JSON Format for the Record Create & Edit and Delete  Generation

  const [recordtype, setRecordType] = useState(null);

  const [createRepeat, setCreateRepeat] = useState(false);

  const [creatvalue, setCreateValue] = useState("");

  const [fieldType, setFieldType] = useState("");

  const [creteria, setCreteria] = useState("");

  // Json Format For the Record Created Date and Updated Date

  const [timetype, setTimetype] = useState("");

  const [excutionperiod, setExcutionPeriod] = useState("");

  const [excutiontime, setExcutionTime] = useState("");

  const [recur, setRecur] = useState("");

  // JSON Format for the Reord Sore

  const [scorestatus, setScoreStatus] = useState("");

  const [scoremodule, setScoreModule] = useState("");

  // Json Format FOr theWOrkFlow Cretiria

  const [actiontype, setActionType] = useState("");
  const [actiondetail, setActionDetail] = useState(false);

  // for Create Task

  const [scheduletype, setScheduleType] = useState("");
  const [showtaskpopup, setShowTaskPopup] = useState({
    task: false,
    email: false,
    updatedField: false,
  });

  const [selectedItem, setSelectedItem] = useState([]);

  // Based on the Module Content need to change

  useEffect(() => {
    if (addfields?.module == "Contacts") {
      setSelectedItem([
        "Name",
        "Company_Name",
        "Mobile_Number",
        "Email_Id",
        "Country",
        "State",
        "City",
      ]);
    } else if (addfields?.module == "Leads") {
      setSelectedItem([
        "LeadFirstName",
        "LeadType",
        "CompanyName",
        "PhoneNo",
        "EmailId",
        "WebsiteURL",
        "BuildingDoorNo",
        "StreetName",
        "AreaName",
        "City",
        "State",
        "Country",
        "LeadSource",
        "LeadScore",
        "Lead_Status",
        "Lead_Industry",
        "Job_Title",
      ]);
    } else if (addfields?.module == "Deals") {
      setSelectedItem([
        "Account_Name",
        "Lead_Name",
        "CompanyName",
        "ActualDealAmount",
        "Deal_Stage",
        "Deal_Probablity",
        "Contact_Person",
        "Deal_Type",
        "Next_Step",
      ]);
    } else if (addfields?.module == "Accounts") {
      setSelectedItem([
        "Lead_Name",
        "Phone_Number",
        "Mail",
        "Land_Line",
        "Turn_Over",
        "Industry",
        "Website",
        "Country",
        "State",
        "City",
        "Location",
        "Name",
      ]);
    }
  }, [addfields?.module]);

  useEffect(() => {
    if (recordtype == "Delete") {
      setTaskList(["Email Notification"]);
    } else {
      setTaskList(["Create Task", "Email Notification", "Update Field"]);
    }
  }, [recordtype]);

  // Get the Assign Task Get List
  const getDateList = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/user/AssignTaskDateList/?UserName=${localStorage.getItem(
          "Username"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: item,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);
  const manufacturer = [
    { name: "Meeting" },
    { name: "Appointment" },
    { name: "Task" },
  ];
  const MailSender = [
    { name: "Users" },
    { name: "Contacts" },
    { name: "Leads" },
  ];
  const [tasktype, settasktype] = useState("");
  const [taskname, settaskname] = useState("");
  const [assignto, setassignto] = useState(
    `${userDetails && userDetails?.username}`
  );
  const [description, setdescription] = useState("");
  const [allodate, setallodate] = useState("");
  const [deaddate, setdeaddate] = useState("");

  // Module for the EmailNotification Payload
  const [mailmoduletype, setMailModuleType] = useState("");

  const [templatedata, setTemplateData] = useState([]);

  const [tempobj, setTempObj] = useState([]);

  const [maillist, setMailList] = useState({
    contact: "",
    lead: "",
    user: "",
  });
  const [selectmail, setSelectMail] = useState({});

  const [scheduleddate, setScheduleDate] = useState(false);

  const [scheduled, setScheduled] = useState(false);

  // Get the Template Additional
  const TemplateData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/CRMUserTemplateAdditional/`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  // Get the Mail List By Module Wise
  const MailListData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/ModulesMailList/`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // Assuming setDatas is a state update function
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  // Update Field Setup

  const [updateName, setUpdateName] = useState("");
  const [modifiedupdate, setModifiedUpdate] = useState([
    {
      updatedfield: "",
      updatevalue: "",
    },
  ]);

  // CRUD functions

  const onSubmit = async () => {
    const payload = {
      WorkFlowRule_Id: seriesValue === "Manual" && addfields?.WorkFlowId,
      Module: addfields?.module,
      Rule_Name: addfields?.rulename,
      Description: addfields?.description,
      Action_Basedon_Record: addfields?.basedonrecords,
      Action_Basedon_date: addfields?.basedondate,
      Action_Basedon_Score: addfields?.score,
      Based_on_Record_Details: [
        {
          Edit_Repeat: creatvalue,
          Record_Type: recordtype,
          Field_Type: fieldType,
          Create_Specific_Field: modifiedrows,
          Edit_Specified_Field: modifiedrows,
          Conditon_Creteria: creteria,
          Edit_Extra_Condition: modifiedrows1,
        },
      ],
      Based_on_Action_DateDetails: [
        {
          Based_On_TimeField: timetype,
          Excution_Time: excutionperiod,
          Time: excutiontime,
          Recur: recur,
          Conditon_Creteria: creteria,
          Create_Specific_Field: modifiedrows,
        },
      ],
      Based_onthe_ScoreDetails: [
        {
          Score_Status: scorestatus,
          Score_Module: scoremodule,
          Conditon_Creteria: creteria,
          Create_Specific_Field: modifiedrows,
        },
      ],
      Actions: actiontype,
      Action_Details: [
        {
          ScheduledDate: scheduled,
          Task_Type: scheduletype,
          Task_Payload: {
            Task_Type: tasktype,
            Task_Name: taskname,
            Assign_toUsers: assignto,
            Description: description,
            Allocated_Date: allodate,
            Deadline_Date: deaddate,
            Status: "In Assisgned",
            Assign_To: localStorage.getItem("UserId"),
            Created_Date: new Date().toISOString().split("T")[0],
            Updated_Date: new Date().toISOString().split("T")[0],
            Organization_Id: localStorage.getItem("OrganizationId"),
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          },
          Email_Payload: {
            mailmoduletype: mailmoduletype,
            temp_obj: tempobj,
            Email_Id: selectmail?.MailId,
            Created_By: localStorage.getItem("UserId"),
            Organization_Id: localStorage.getItem("OrganizationId"),
          },
          Update_Payload: {
            updateName: updateName,
            updatemodule: addfields?.module,
            modifiedupdate: modifiedupdate,
          },
        },
      ],
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };
    console.error("payload : ", payload);
    try {
      await axios
        .post(`${BASE_URL}/user/WorkFlowRuleCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("submit res", res.data);
          toast.success("Created Successfully", {
            position: "top-right",
            autoClose: 100,
          });
          getDatas(page);
          setShowCreate(false);
          setShowList(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // Edit page Components

  // use Query functins -------------------------------------------------------------------------------------------
  const {
    data: getTemplateData,
    isError: isErrorTemplateData,
    isLoading: isLoadingTemplateData,
    refetch: refetchTemplateData,
  } = useQuery(["getTemplateDatalist"], TemplateData, { staleTime: Infinity });
  const {
    data: getMailListData,
    isError: isErrorMailListData,
    isLoading: isLoadingMailListData,
    refetch: refetchMailListData,
  } = useQuery(["getMailListDatalist"], MailListData, { staleTime: Infinity });
  const {
    data: getDateDataList,
    isError: isErrorgetDateList,
    isLoading: isLoadinggetDateList,
    refetch: refetchgetDateList,
  } = useQuery(["getgetDateList"], getDateList, { staleTime: Infinity });

  useEffect(() => {
    if (Boolean(getTemplateData)) {
      setTemplateData(getTemplateData);
    }
  }, [getTemplateData]);

  useEffect(() => {
    if (Boolean(getMailListData)) {
      setMailList({
        ...maillist,
        contact: getMailListData?.Contact,
        lead: getMailListData?.LeadList,
        user: getMailListData?.User,
      });
    }
  }, [getMailListData]);

  useEffect(() => {
    if (Boolean(getDateDataList)) {
      setallodate(
        getDateDataList?.Date[
          getDateDataList?.Date.length - 1
        ]?.early_finish?.slice(1, 10)
      );
    }
  }, [getDateDataList]);

  useEffect(async () => {
    const series = await fetchTransactionSeries("WorkFlow_Rule");
    console.log("WorkFlow_Rule series", series);
    setSeriesValue(series);
  }, []);

  return (
    <>
      <Div>
        {/* Create WorkFlow */}
        <Dialog
          scroll="body"
          PaperProps={{
            sx: {
              borderRadius: "5px",
              boxShadow: "0px 4px 4px 5px rgba(0, 0, 0, 0.25)",
            },
          }}
          open={actionrecord}
          // onClose={() => { setShowAddPriceBook(false);  setOpen(); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          fullWidth
        >
          {Createrecord === true && (
            <DialogTitle
              id="alert-dialog-title"
              sx={[dialogTitle, DisplayFlex, { bgcolor: "#dcdcdc !important" }]}
            >
              Create Action Records
            </DialogTitle>
          )}
          {Editrecord === true && (
            <DialogTitle
              id="alert-dialog-title"
              sx={[dialogTitle, DisplayFlex, { bgcolor: "#dcdcdc !important" }]}
            >
              Edit Action Records
            </DialogTitle>
          )}
          {CreateEditrecord === true && (
            <DialogTitle
              id="alert-dialog-title"
              sx={[dialogTitle, DisplayFlex, { bgcolor: "#dcdcdc !important" }]}
            >
              Create and Edit Action Record
            </DialogTitle>
          )}
          {Deleterecord === true && (
            <DialogTitle
              id="alert-dialog-title"
              sx={[dialogTitle, DisplayFlex, { bgcolor: "#dcdcdc !important" }]}
            >
              Delete Action Record
            </DialogTitle>
          )}

          {Createrecord === true && (
            <Div>
              <DialogContent>
                <Box>
                  <Div>
                    <Typography sx={[FontStyle]}>
                      Which {addfields?.module} would you like to apply the rule
                      to?
                    </Typography>
                  </Div>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    onChange={(e) => {
                      recordmodifyfun(e);
                    }}
                    defaultValue="top"
                  >
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          flexWrap: "wrap",
                          gap: "10px",
                        },
                      ]}
                    >
                      <FormControlLabel
                        value={`${addfields?.module} matching certain conditions`}
                        control={<Radio color="info" size="small" />}
                        label={`${addfields?.module} matching certain conditions`}
                      />

                      <FormControlLabel
                        value={`All ${addfields?.module}`}
                        control={<Radio color="info" size="small" />}
                        label={`All ${addfields?.module}`}
                      />
                    </Div>
                  </RadioGroup>
                  {modifyrecord === true && (
                    <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                      <JumboScrollbar
                        autoHeight={true}
                        autoHideTimeout={4000}
                        autoHeightMax={250}
                        autoHide={true}
                        hideTracksWhenNotNeeded
                        id="no-more-tables"
                      >
                        {modifiedrows &&
                          modifiedrows.map((data, index) => {
                            // console.log("data", data);
                            return (
                              <Grid
                                container
                                key={index + 937}
                                sx={{ my: 0.5 }}
                              >
                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={selectedItem}
                                      name="Field"
                                      onChange={(e, newValue) => {
                                        let updatedValue = [...modifiedrows];
                                        updatedValue[index].selectedfield =
                                          newValue;
                                        setmodifiedrows(updatedValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Field"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={modifiedvalue}
                                      name="category"
                                      // value={Product_Name}
                                      // defaultValue={Product_Name}
                                      onChange={(e, newValue) => {
                                        let update = [...modifiedrows];
                                        update[index].modifiedfield = newValue;
                                        setmodifiedrows(update);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Value"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} lg={3.5}>
                                  {/* {data.valuetype === true &&  */}
                                  <TextField
                                    name="Product_Id"
                                    id="Product_Id"
                                    // value={Product_Id}
                                    // disabled={modifiedrows[index].modifiedfield == "any value"}
                                    onChange={(e) => {
                                      let updates = [...modifiedrows];
                                      updates[index].enterfield =
                                        e.target.value;
                                      setmodifiedrows(updates);
                                    }}
                                    className="input-box"
                                    autoComplete="off"
                                    placeholder="Enter Value"
                                  />
                                  {/* } */}
                                </Grid>
                                <Grid item xs={12} lg={1.5}>
                                  <DeletedIcon
                                    onClick={() => {
                                      handleDeleteRowcreate(index);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                      </JumboScrollbar>
                      <Div>
                        <Button
                          sx={{
                            color: Colors?.Mblue,
                            mt: 1,
                          }}
                          onClick={handlemodalAddRowcreate}
                        >
                          + Add Fields
                        </Button>
                      </Div>
                    </Div>
                  )}

                  <Grid container align="center" spacing={2} my={2}>
                    <Grid item align="right" xs={6}>
                      <CustomButton
                        label="Save"
                        Icon="saveIcon"
                        onClick={() => setactionrecord(false)}
                      />
                    </Grid>
                    <Grid item align="left" xs={6}>
                      <Cancelbutton
                        onClick={() => {
                          setactionrecord1(true);
                          setmodifyrecord(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </Div>
          )}
          {Editrecord === true && (
            <DialogContent>
              <Box>
                <Div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="info"
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            setCreateValue(e.target.value);
                            setCreateRepeat(!createRepeat);
                          }}
                          value="Repeat this workflow when ever a is edit"
                          checked={createRepeat}
                        />
                      }
                      sx={[FontStyle, {}]}
                      label="Repeat this workflow when ever a  is edit"
                    />
                  </FormGroup>
                </Div>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  onChange={(e) => {
                    recordmodifyfun1(e);
                  }}
                  defaultValue="top"
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        flexWrap: "wrap",
                        gap: "10px",
                      },
                    ]}
                  >
                    <FormControlLabel
                      sx={[FontStyle, {}]}
                      value="Any Field gets method"
                      label="Any Field gets method"
                      control={<Radio color="info" size="small" />}
                    />
                    <FormControlLabel
                      sx={[FontStyle, {}]}
                      value="Specific Field(s) gets method"
                      label="Specific Field(s) gets method"
                      control={<Radio color="info" size="small" />}
                    />
                  </Div>
                </RadioGroup>

                {modifyrecord === true && (
                  <Div>
                    {showspecific == true && (
                      <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                        <JumboScrollbar
                          autoHeight={true}
                          autoHideTimeout={4000}
                          autoHeightMax={250}
                          autoHide={true}
                          hideTracksWhenNotNeeded
                          id="no-more-tables"
                        >
                          {modifiedrows1 &&
                            modifiedrows1?.map((data, index) => {
                              // console.log("data", data);
                              return (
                                <Grid
                                  container
                                  key={index + 937}
                                  sx={{ my: 0.5 }}
                                >
                                  <Grid item xs={12} lg={3.5}>
                                    <Typography sx={[FontStyle, {}]}>
                                      <Autocomplete
                                        className="search-select"
                                        options={selectedItem}
                                        name="Field"
                                        onChange={(e, newValue) => {
                                          let updatedValueedit = [
                                            ...modifiedrows1,
                                          ];
                                          updatedValueedit[
                                            index
                                          ].selectedfield = newValue;
                                          setmodifiedrows1(updatedValueedit);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="category"
                                            placeholder="Select Field"
                                          />
                                        )}
                                      />
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} lg={3.5}>
                                    <Typography sx={[FontStyle, {}]}>
                                      <Autocomplete
                                        className="search-select"
                                        options={modifiedvalue}
                                        name="category"
                                        // value={Product_Name}
                                        // defaultValue={Product_Name}
                                        onChange={(e, newValue) => {
                                          let update = [...modifiedrows1];
                                          update[index].modifiedfield =
                                            newValue;
                                          setmodifiedrows1(update);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="category"
                                            placeholder="Select Value"
                                          />
                                        )}
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} lg={3.5}>
                                    {/* {data.valuetype === true &&  */}
                                    <TextField
                                      name="Product_Id"
                                      id="Product_Id"
                                      // value={Product_Id}
                                      // disabled={modifiedrows[index].modifiedfield == "any value"}
                                      onChange={(e) => {
                                        let updates = [...modifiedrows1];
                                        updates[index].enterfield =
                                          e.target.value;
                                        setmodifiedrows1(updates);
                                      }}
                                      className="input-box"
                                      autoComplete="off"
                                      placeholder="Enter Value"
                                    />
                                    {/* } */}
                                  </Grid>
                                  <Grid item xs={12} lg={1.5}>
                                    {/* <IconButton
                                    color="error"
                                    onClick={() => {
                                      handleDeleteRowcreate(index);
                                    }}
                                  >
                                    <DeleteIcon color="red" />
                                  </IconButton> */}

                                    <DeletedIcon
                                      onClick={() => {
                                        handleDeleteRowedit(index);
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </JumboScrollbar>
                        <Div>
                          <Button
                            sx={[
                              FontStyle,
                              {
                                color: `${Colors?.Mblue} !important`,
                                mt: 1,
                              },
                            ]}
                            onClick={handlemodalAddRowEdit}
                          >
                            + Add Fileds
                          </Button>
                        </Div>
                      </Div>
                    )}
                    <Div>
                      <Div sx={{ mt: 2 }}>
                        <Typography sx={[FontStyle]}>
                          Which {addfields?.module} would you like to apply the
                          rule to?
                        </Typography>
                      </Div>
                      <RadioGroup
                        aria-label="position"
                        name="position"
                        onChange={(e) => {
                          recordmodifyeditfun(e);
                        }}
                        defaultValue="top"
                      >
                        <Div
                          sx={[
                            DisplayFlex,
                            {
                              flexWrap: "wrap",
                              gap: "10px",
                            },
                          ]}
                        >
                          <FormControlLabel
                            value={`${addfields?.module} matching certain conditions`}
                            control={<Radio color="info" size="small" />}
                            label={`${addfields?.module} matching certain conditions`}
                          />
                          <FormControlLabel
                            value={`All ${addfields?.module}`}
                            control={<Radio color="info" size="small" />}
                            label={`All ${addfields?.module}`}
                          />
                        </Div>
                      </RadioGroup>
                      <Div>
                        {showedit == true && (
                          <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                            <JumboScrollbar
                              autoHeight={true}
                              autoHideTimeout={4000}
                              autoHeightMax={250}
                              autoHide={true}
                              hideTracksWhenNotNeeded
                              id="no-more-tables"
                            >
                              {modifiedrows &&
                                modifiedrows.map((data, index) => {
                                  return (
                                    <Grid
                                      container
                                      key={index + 937}
                                      sx={{ my: 0.5 }}
                                    >
                                      <Grid item xs={12} lg={3.5}>
                                        <Typography sx={[FontStyle, {}]}>
                                          <Autocomplete
                                            className="search-select"
                                            options={selectedItem}
                                            name="Field"
                                            onChange={(e, newValue) => {
                                              let updatedValue = [
                                                ...modifiedrows,
                                              ];
                                              updatedValue[
                                                index
                                              ].selectedfield = newValue;
                                              setmodifiedrows(updatedValue);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name="category"
                                                placeholder="Select Field"
                                              />
                                            )}
                                          />
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={12} lg={3.5}>
                                        <Typography sx={[FontStyle, {}]}>
                                          <Autocomplete
                                            className="search-select"
                                            options={modifiedvalue}
                                            name="category"
                                            // value={Product_Name}
                                            // defaultValue={Product_Name}
                                            onChange={(e, newValue) => {
                                              let update = [...modifiedrows];
                                              update[index].modifiedfield =
                                                newValue;
                                              setmodifiedrows(update);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                name="category"
                                                placeholder="Select Value"
                                              />
                                            )}
                                          />
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} lg={3.5}>
                                        {/* {data.valuetype === true &&  */}
                                        <TextField
                                          name="Product_Id"
                                          id="Product_Id"
                                          // value={Product_Id}
                                          // disabled={modifiedrows[index].modifiedfield == "any value"}
                                          onChange={(e) => {
                                            let updates = [...modifiedrows];
                                            updates[index].enterfield =
                                              e.target.value;
                                            setmodifiedrows(updates);
                                          }}
                                          className="input-box"
                                          autoComplete="off"
                                          placeholder="Enter Value"
                                        />
                                        {/* } */}
                                      </Grid>
                                      <Grid item xs={12} lg={1.5}>
                                        <DeletedIcon
                                          onClick={() => {
                                            handleDeleteRowcreate(index);
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </JumboScrollbar>
                            <Div>
                              <Button
                                sx={[
                                  FontStyle,
                                  {
                                    color: `${Colors?.Mblue} !important`,
                                    mt: 1,
                                  },
                                ]}
                                onClick={handlemodalAddRowcreate}
                              >
                                + Add Fields
                              </Button>
                            </Div>
                          </Div>
                        )}
                      </Div>
                    </Div>
                  </Div>
                )}

                <Grid container align="center" spacing={2} my={2}>
                  <Grid item align="right" xs={6}>
                    <CustomButton
                      label="Save"
                      Icon="saveIcon"
                      type="submit"
                      // onClick={() => submitFun("submit")}
                    />
                  </Grid>
                  <Grid item align="left" xs={6}>
                    <Cancelbutton
                      onClick={() => {
                        setactionrecord1(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          )}
          {CreateEditrecord === true && (
            <Div>
              <DialogContent>
                <Box>
                  <Div>
                    <Typography sx={[FontStyle]}>
                      Which {addfields?.module} would you like to apply the rule
                      to?
                    </Typography>
                  </Div>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    onChange={(e) => {
                      recordmodifyfun(e);
                    }}
                    defaultValue="top"
                  >
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          flexWrap: "wrap",
                          gap: "10px",
                        },
                      ]}
                    >
                      <FormControlLabel
                        value={`${addfields?.module} matching certain conditions`}
                        control={<Radio color="info" size="small" />}
                        label={`${addfields?.module} matching certain conditions`}
                      />
                      <FormControlLabel
                        value={`All ${addfields?.module}`}
                        control={<Radio color="info" size="small" />}
                        label={`All ${addfields?.module}`}
                      />
                    </Div>
                  </RadioGroup>
                  {modifyrecord === true && (
                    <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                      <JumboScrollbar
                        autoHeight={true}
                        autoHideTimeout={4000}
                        autoHeightMax={250}
                        autoHide={true}
                        hideTracksWhenNotNeeded
                        id="no-more-tables"
                      >
                        {modifiedrows &&
                          modifiedrows?.map((data, index) => {
                            // console.log("data", data);
                            return (
                              <Grid
                                container
                                key={index + 937}
                                sx={{ my: 0.5 }}
                              >
                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={selectedItem}
                                      name="Field"
                                      onChange={(e, newValue) => {
                                        let updatedValuece = [...modifiedrows];
                                        updatedValuece[index].selectedfield =
                                          newValue;
                                        setmodifiedrows(updatedValuece);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Field"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={modifiedvalue}
                                      name="category"
                                      // value={Product_Name}
                                      // defaultValue={Product_Name}
                                      onChange={(e, newValue) => {
                                        let updatece = [...modifiedrows];
                                        updatece[index].modifiedfield =
                                          newValue;
                                        setmodifiedrows(updatece);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Value"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} lg={3.5}>
                                  {/* {data.valuetype === true &&  */}
                                  <TextField
                                    name="Product_Id"
                                    id="Product_Id"
                                    // value={Product_Id}
                                    // disabled={modifiedrows[index].modifiedfield == "any value"}
                                    onChange={(e) => {
                                      let updatesce = [...modifiedrows];
                                      updatesce[index].enterfield =
                                        e.target.value;
                                      setmodifiedrows(updatesce);
                                    }}
                                    className="input-box"
                                    autoComplete="off"
                                    placeholder="Enter Value"
                                  />
                                  {/* } */}
                                </Grid>
                                <Grid item xs={12} lg={1.5}>
                                  <DeletedIcon
                                    onClick={() => {
                                      handleDeleteRowcreate(index);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                      </JumboScrollbar>
                      <Div>
                        <Button
                          sx={[
                            FontStyle,
                            {
                              color: `${Colors?.Mblue} !important`,
                              mt: 1,
                            },
                          ]}
                          onClick={handlemodalAddRowcreate}
                        >
                          + Add Fields
                        </Button>
                      </Div>
                    </Div>
                  )}

                  <Grid container align="center" spacing={2} my={2}>
                    <Grid item align="right" xs={6}>
                      <CustomButton
                        label="Save"
                        Icon="saveIcon"
                        onClick={() => setactionrecord(false)}
                      />
                    </Grid>
                    <Grid item align="left" xs={6}>
                      <Cancelbutton
                        onClick={() => {
                          setactionrecord1(true);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </Div>
          )}
          {Deleterecord === true && (
            <Div>
              <DialogContent>
                <Box>
                  <Div>
                    <Typography sx={[FontStyle]}>
                      Which {addfields?.module} would you like to apply the rule
                      to?
                    </Typography>
                  </Div>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    onChange={(e) => {
                      recordmodifyfun(e);
                    }}
                    defaultValue="top"
                  >
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          flexWrap: "wrap",
                          gap: "10px",
                        },
                      ]}
                    >
                      <FormControlLabel
                        value={`${addfields?.module} matching certain conditions`}
                        control={<Radio color="info" size="small" />}
                        label={`${addfields?.module} matching certain conditions`}
                      />
                      <FormControlLabel
                        value={`All ${addfields?.module}`}
                        control={<Radio color="info" size="small" />}
                        label={`All ${addfields?.module}`}
                      />
                    </Div>
                  </RadioGroup>
                  {modifyrecord === true && (
                    <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                      <JumboScrollbar
                        autoHeight={true}
                        autoHideTimeout={4000}
                        autoHeightMax={250}
                        autoHide={true}
                        hideTracksWhenNotNeeded
                        id="no-more-tables"
                      >
                        {modifiedrows &&
                          modifiedrows?.map((data, index) => {
                            console.log("data", data);
                            return (
                              <Grid
                                container
                                key={index + 937}
                                sx={{ my: 0.5 }}
                              >
                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      sx={{
                                        minWidth: "180px !important",
                                        paddingRight: "5px",
                                      }}
                                      options={selectedItem}
                                      name="Field"
                                      onChange={(e, newValue) => {
                                        let updatedValued = [...modifiedrows];
                                        updatedValued[index].selectedfield =
                                          newValue;
                                        setmodifiedrows(updatedValued);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Field"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={modifiedvalue}
                                      name="category"
                                      // value={Product_Name}
                                      // defaultValue={Product_Name}
                                      onChange={(e, newValue) => {
                                        let updated = [...modifiedrows];
                                        updated[index].modifiedfield = newValue;
                                        setmodifiedrows(updated);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Value"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} lg={3.5}>
                                  {/* {data.valuetype === true &&  */}
                                  <TextField
                                    name="Product_Id"
                                    id="Product_Id"
                                    // value={Product_Id}
                                    // disabled={modifiedrows[index].modifiedfield == "any value"}
                                    onChange={(e) => {
                                      let updatesd = [...modifiedrows];
                                      updatesd[index].enterfield =
                                        e.target.value;
                                      setmodifiedrows(updatesd);
                                    }}
                                    className="input-box"
                                    autoComplete="off"
                                    placeholder="Enter Value"
                                  />
                                  {/* } */}
                                </Grid>
                                <Grid item xs={12} lg={1.5}>
                                  <DeletedIcon
                                    onClick={() => {
                                      handleDeleteRowcreate(index);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                      </JumboScrollbar>
                      <Div>
                        <Button
                          sx={[
                            FontStyle,
                            {
                              color: `${Colors?.Mblue} !important`,
                              mt: 1,
                            },
                          ]}
                          onClick={handlemodalAddRowcreate}
                        >
                          + Add Fields
                        </Button>
                      </Div>
                    </Div>
                  )}

                  <Grid container align="center" spacing={2} my={2}>
                    <Grid item align="right" xs={6}>
                      <CustomButton
                        label="Save"
                        Icon="saveIcon"
                        onClick={() => setactionrecord(false)}
                      />
                    </Grid>
                    <Grid item align="left" xs={6}>
                      <Cancelbutton
                        label="Cancel"
                        onClick={() => {
                          setactionrecord1(true);
                          setmodifyrecord(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </Div>
          )}
        </Dialog>

        {/* create action date */}
        <Dialog
          scroll="body"
          open={actiondate}
          // onClose={() => { setShowAddPriceBook(false);  setOpen(); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={[dialogTitle, DisplayFlex, {}]}
          >
            Create Action Date
          </DialogTitle>

          <Div>
            <DialogContent>
              <Box>
                <Grid container>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, { marginTop: "5px" }]}>
                      Based on which Date/Time field this rule should execute
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, {}]}>
                      <Autocomplete
                        className="search-select"
                        options={excutevalues}
                        onChange={(e, newValue) => {
                          setTimetype(newValue);
                        }}
                        name="Field"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="category"
                            placeholder="Select "
                          />
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <Typography
                      sx={[FontStyle, { marginTop: "5px" }]}
                    ></Typography>
                  </Grid>
                </Grid>
                <Div sx={{ marginTop: "8px" }}></Div>
                <Grid container spacing={2}>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, { marginTop: "5px" }]}>
                      Define execution date should execute
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, {}]}>
                      <Autocomplete
                        className="search-select"
                        options={excutewhen}
                        onChange={(e, newValue) => {
                          setExcutionPeriod(newValue);
                        }}
                        name="Field"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="category"
                            placeholder="Select Field"
                          />
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, { marginTop: "5px" }]}>
                      the date in {timetype} field
                    </Typography>
                  </Grid>
                </Grid>
                <Div sx={{ marginTop: "8px" }}></Div>

                <Grid container spacing={2}>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, { marginTop: "5px" }]}>
                      Execution time
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={8}>
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      // onChange={(e)=>{recordmodifyfun(e);}}
                      defaultValue="top"
                    >
                      <Div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value={`Same as the Time in ${timetype} Time field`}
                          onChange={(e) => {
                            setExcutionTime(e.target.value);
                          }}
                          control={<Radio color="info" size="small" />}
                        />
                        <Typography sx={[FontStyle, {}]}>
                          Same as the Time in {timetype} field
                        </Typography>
                      </Div>
                      <Div sx={{ marginTop: "5px" }}></Div>

                      <Div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          value="Modified"
                          control={<Radio color="info" size="small" />}
                        />
                        <Div sx={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            type="time"
                            className="input-box"
                            id="outlined-basic"
                            variant="outlined"
                            autoComplete="off"
                            name="score"
                            onChange={(e) => {
                              console.log("setExcutionTime");
                              setExcutionTime(e.target.value);
                            }}
                          />
                          <Typography
                            sx={[
                              FontStyle,
                              { marginTop: "5px", marginLeft: "3px" },
                            ]}
                          >
                            Asia/Kolkata
                          </Typography>
                        </Div>
                      </Div>
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Div sx={{ marginTop: "8px" }}></Div>
                <Grid container spacing={2}>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, { marginTop: "5px" }]}>
                      Recur
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <Typography sx={[FontStyle, {}]}>
                      <Autocomplete
                        className="search-select"
                        options={recurvalue}
                        name="Field"
                        onChange={(e) => {
                          setRecur(e.target.value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="category"
                            placeholder="Select Field"
                          />
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={2} lg={4}>
                    <Typography
                      sx={[FontStyle, { marginTop: "5px" }]}
                    ></Typography>
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    padding: 2,
                  }}
                >
                  <Div>
                    <Typography sx={[FontStyle]}>
                      Which {addfields?.module} would you like to apply the rule
                      to?
                    </Typography>
                  </Div>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    onChange={(e) => {
                      recordmodifyfun(e);
                    }}
                    defaultValue="top"
                  >
                    <Div
                      sx={[
                        DisplayFlex,
                        {
                          flexWrap: "wrap",
                          gap: "10px",
                        },
                      ]}
                    >
                      <FormControlLabel
                        value={`${addfields?.module} matching certain conditions`}
                        control={<Radio color="info" size="small" />}
                        label={`${addfields?.module} matching certain conditions`}
                      />
                      <FormControlLabel
                        value={`All ${addfields?.module}`}
                        control={<Radio color="info" size="small" />}
                        label={`All ${addfields?.module}`}
                      />
                    </Div>
                  </RadioGroup>
                  {modifyrecord === true && (
                    <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                      <JumboScrollbar
                        autoHeight={true}
                        autoHideTimeout={4000}
                        autoHeightMax={250}
                        autoHide={true}
                        hideTracksWhenNotNeeded
                        id="no-more-tables"
                      >
                        {modifiedrows &&
                          modifiedrows.map((data, index) => {
                            // console.log("data", data);
                            return (
                              <Grid container key={index + "o23"}>
                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={selectedItem}
                                      name="Field"
                                      onChange={(e, newValue) => {
                                        let updatedValueda = [...modifiedrows];
                                        updatedValueda[index].selectedfield =
                                          newValue;
                                        setmodifiedrows(updatedValueda);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Field"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={modifiedvalue}
                                      name="category"
                                      // value={Product_Name}
                                      // defaultValue={Product_Name}
                                      onChange={(e, newValue) => {
                                        let updateda = [...modifiedrows];
                                        updateda[index].modifiedfield =
                                          newValue;
                                        setmodifiedrows(updateda);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Value"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} lg={3.5}>
                                  {/* {data.valuetype === true &&  */}
                                  <TextField
                                    name="Product_Id"
                                    id="Product_Id"
                                    // value={Product_Id}
                                    // disabled={modifiedrows[index].modifiedfield == "any value"}
                                    onChange={(e) => {
                                      let updatesda = [...modifiedrows];
                                      updatesda[index].enterfield =
                                        e.target.value;
                                      setmodifiedrows(updatesda);
                                    }}
                                    className="input-box"
                                    autoComplete="off"
                                    placeholder="Enter Value"
                                  />
                                  {/* } */}
                                </Grid>
                                <Grid item xs={12} lg={1.5}>
                                  <DeletedIcon
                                    onClick={() => {
                                      handleDeleteRowcreate(index);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                      </JumboScrollbar>
                      <Div>
                        <Button
                          sx={[
                            FontStyle,
                            {
                              color: `${Colors?.Mblue} !important`,
                              mt: 1,
                            },
                          ]}
                          onClick={handlemodalAddRowcreate}
                        >
                          + Add Fields
                        </Button>
                      </Div>
                    </Div>
                  )}
                </Div>

                <Grid container align="center" spacing={2} my={2}>
                  <Grid item align="right" xs={6}>
                    <CustomButton
                      label="Save"
                      Icon="saveIcon"
                      onClick={() => setactiondate(false)}
                    />
                  </Grid>
                  <Grid item align="left" xs={6}>
                    <Cancelbutton
                      label="Cancel"
                      onClick={() => {
                        setactiondate1(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </Div>
        </Dialog>

        <DialogBox
          open={actiondate1}
          onClickYes={() => {
            setactiondate1(false);
            setactiondate(false);
            // reset();
            //
            // setOpen();
            // handleReset();
          }}
          onClickNo={() => {
            setactiondate1(false);
          }}
        />

        {/* Create action rule */}
        <Dialog
          scroll="body"
          open={actionrule}
          // onClose={() => { setShowAddPriceBook(false);  setOpen(); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"md"}
          fullWidth
        >
          <Div>
            <DialogContent>
              <Box>
                {/* <Div sx={{ marginLeft: '5px' }}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox color="info" onChange={(e) => {
                      console.log("e.target.value", e.target.value)
                      setCreateValue(e.target.value)
                      setCreateRepeat(!createRepeat)
                    }} checked={createRepeat}
                      value="Repeat this workflow when ever a is Create"
                    />} sx={[FontStyle,]} label="Repeat this workflow when ever a is Create" />
                  </FormGroup>
                </Div> */}
                <Div>
                  <Typography sx={[FontStyle]}>
                    Which {addfields?.module} would you like to apply the rule
                    to?
                  </Typography>
                </Div>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  onChange={(e) => {
                    recordmodifyfun(e);
                  }}
                  defaultValue="top"
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        flexWrap: "wrap",
                        gap: "10px",
                      },
                    ]}
                  >
                    <FormControlLabel
                      value={`${addfields?.module} matching certain conditions`}
                      control={<Radio color="info" size="small" />}
                      label={`${addfields?.module} matching certain conditions`}
                    />

                    <FormControlLabel
                      value={`All ${addfields?.module}`}
                      control={<Radio color="info" size="small" />}
                      label={`All ${addfields?.module}`}
                    />
                  </Div>
                </RadioGroup>
                {modifyrecord === true && (
                  <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMax={250}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                      id="no-more-tables"
                    >
                      {modifiedrows &&
                        modifiedrows?.map((data, index) => {
                          console.log("data", data);
                          return (
                            <Grid container key={index + 937}>
                              <Grid item xs={12} lg={3.5}>
                                <Typography sx={[FontStyle, {}]}>
                                  <Autocomplete
                                    className="search-select"
                                    options={selectedItem}
                                    name="Field"
                                    onChange={(e, newValue) => {
                                      let updatedValueru = [...modifiedrows];
                                      updatedValueru[index].selectedfield =
                                        newValue;
                                      setmodifiedrows(updatedValueru);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="category"
                                        placeholder="Select Field"
                                      />
                                    )}
                                  />
                                </Typography>
                              </Grid>

                              <Grid item xs={12} lg={3.5}>
                                <Typography sx={[FontStyle, {}]}>
                                  <Autocomplete
                                    className="search-select"
                                    options={modifiedvalue}
                                    name="category"
                                    // value={Product_Name}
                                    // defaultValue={Product_Name}
                                    onChange={(e, newValue) => {
                                      let updateru = [...modifiedrows];
                                      updateru[index].modifiedfield = newValue;
                                      setmodifiedrows(updateru);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name="category"
                                        placeholder="Select Value"
                                      />
                                    )}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item xs={12} lg={3.5}>
                                {/* {data.valuetype === true &&  */}
                                <TextField
                                  sx={{
                                    minWidth: "120px !important",
                                    paddingRight: "5px",
                                  }}
                                  name="Product_Id"
                                  id="Product_Id"
                                  // value={Product_Id}
                                  // disabled={modifiedrows[index].modifiedfield == "any value"}
                                  onChange={(e) => {
                                    let updatesru = [...modifiedrows];
                                    updatesru[index].enterfield =
                                      e.target.value;
                                    setmodifiedrows(updatesru);
                                  }}
                                  className="input-box"
                                  autoComplete="off"
                                  placeholder="Enter Value"
                                />
                                {/* } */}
                              </Grid>
                              <Grid item xs={12} lg={1.5}>
                                <DeletedIcon
                                  onClick={() => {
                                    handleDeleteRowcreate(index);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                    </JumboScrollbar>
                    <Div>
                      <Button
                        sx={{
                          color: Colors?.Mblue,
                          mt: 1,
                        }}
                        onClick={handlemodalAddRowcreate}
                      >
                        + Add Fields
                      </Button>
                    </Div>
                  </Div>
                )}

                <Grid container align="center" spacing={2} my={2}>
                  <Grid item align="right" xs={6}>
                    <CustomButton
                      label="Save"
                      Icon="saveIcon"
                      onClick={() => setactionrule(false)}
                    />
                  </Grid>
                  <Grid item align="left" xs={6}>
                    <Cancelbutton
                      label="Cancel"
                      onClick={() => {
                        setCloseRules(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </Div>
        </Dialog>

        <DialogBox
          open={closeRules}
          onClickYes={() => {
            setactionrule(false);
            setmodifyrecord(false);
            setCloseRules(false);
          }}
          onClickNo={() => {
            setCloseRules(false);
          }}
        />

        <DialogBox
          open={actionrecord1}
          onClickYes={() => {
            setactionrecord1(false);
            setactionrecord(false);
            setCreaterecord(false);
            setEditrecord(false);
            setCreateEditrecord(false);
            setDeleterecord(false);
            // reset();
            //
            // setOpen();
            // handleReset();
          }}
          onClickNo={() => {
            setactionrecord1(false);
          }}
        />

        {/* For Create Work Flow Conditions */}
        <Div
          sx={{
            // display: showcreate === true ? "block" : "none",
            px: { xs: "5%", md: "10%" },
            minHeight: "300px !important",
          }}
        >
          <Div
            sx={{
              minHeight: { xs: "100%", md: "350px" },
              height: AutoHeight(1.6),
            }}
          >
            <Div>
              <Typography
                sx={[
                  ComponentHeading,
                  {
                    mt: 0.2,
                    // ml: 5,
                    color: "black !important",
                  },
                ]}
              >
                Create New Rule
              </Typography>
            </Div>

            {activeStep == 0 && (
              <>
                <Div>
                  <Typography
                    variant="h3"
                    sx={[
                      // FontStyle,
                      {
                        // mt: 2,
                        ml: { xs: 0, md: 0 },
                        color: "black !important",
                      },
                    ]}
                  >
                    Work Flow
                  </Typography>

                  <Grid
                    container
                    mt={4}
                    pl={{ xs: 4, md: 8 }}
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                      >
                        WorkFlow Rule Id{" "}
                        <span className="required">
                          {seriesValue == "Automatic" ? "" : "*"}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <TextField
                        className="input-box col-12 col-md-6 col-lg-6"
                        value={addfields?.WorkFlowId}
                        onChange={(e) => {
                          setAddFields({
                            ...addfields,
                            WorkFlowId: e.target.value,
                          });
                        }}
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                        placeholder="WorkFlow Rule Id"
                        disabled={seriesValue == "Automatic" ? true : ""}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                      >
                        Module
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <Autocomplete
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                        name="module"
                        className="search-select3 col-12 col-md-6 col-lg-6"
                        options={Module}
                        value={addfields?.module}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        onChange={(e, newValue) => {
                          console.log("newvalue", newValue);
                          setAddFields({ ...addfields, module: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="module"
                            {...params}
                            placeholder="Module"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                      >
                        Rule Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <TextField
                        className="input-box col-12 col-md-6 col-lg-6"
                        value={addfields?.rulename}
                        onChange={(e) => {
                          setAddFields({
                            ...addfields,
                            rulename: e.target.value,
                          });
                        }}
                        placeholder="Rule Name"
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Typography
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                      >
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <TextField
                        className="multiline-box col-12 col-md-6 col-lg-6"
                        rows={3}
                        multiline
                        value={addfields?.description}
                        onChange={(e) => {
                          setAddFields({
                            ...addfields,
                            description: e.target.value,
                          });
                        }}
                        placeholder="Description"
                        sx={[
                          FontStyle,
                          {
                            mt: 0.2,
                            color: "black !important",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Div>
              </>
            )}

            {activeStep == 1 && (
              <>
                <Div>
                  <Typography
                    sx={[
                      FontStyle,
                      {
                        mt: 2,
                        // ml: 10,
                        color: "black !important",
                      },
                    ]}
                  >
                    Work Flow Triger
                  </Typography>
                </Div>

                <Grid
                  container
                  mt={4}
                  pl={{ xs: 4, md: 8 }}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControlLabel
                      onChange={(e) => {
                        handlemainenable(e);
                      }}
                      value="Action Based on the Records"
                      checked={!mainrecord}
                      control={
                        <Radio name="position" color="info" size="small" />
                      }
                      label="Action Based on the Records"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Div sx={{ width: "100%", p: 1 }}>
                      <Autocomplete
                        disabled={mainrecord}
                        sx={[
                          FontStyle,
                          {
                            mt: 0.4,
                            color: "black !important",
                          },
                        ]}
                        className="search-select col-12 col-md-6 col-lg-6"
                        options={Records}
                        value={actionrecords}
                        onChange={(e, newValue) => {
                          RecordOverviewFun(e, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="" />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControlLabel
                      onChange={(e) => {
                        handlemainenable(e);
                      }}
                      checked={!maindate}
                      value="Action Based on the Date"
                      control={
                        <Radio name="position" color="info" size="small" />
                      }
                      label="Action Based on the Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Div sx={{ width: "100%", p: 1 }}>
                      <Autocomplete
                        disabled={maindate}
                        sx={[
                          FontStyle,
                          {
                            mt: 0.4,
                            color: "black !important",
                          },
                        ]}
                        className="search-select col-12 col-md-6 col-lg-6"
                        options={Period}
                        onChange={(e, newValue) => {
                          RecordOverviewdateFun(e, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="" />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControlLabel
                      onChange={(e) => {
                        handlemainenable(e);
                      }}
                      value="Action Based on the Score"
                      checked={!mainscore}
                      control={
                        <Radio name="position" color="info" size="small" />
                      }
                      label=" Action Based on the Score"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Div sx={{ width: "100%", p: 1 }}>
                      <Autocomplete
                        disabled={mainscore}
                        sx={[
                          FontStyle,
                          {
                            mt: 0.4,
                            color: "black !important",
                          },
                        ]}
                        className="search-select col-12 col-md-6 col-lg-6"
                        options={Score}
                        onChange={(e, newValue) => {
                          setScoreStatus(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="" />
                        )}
                      />
                    </Div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Typography
                      sx={[
                        FontStyle,
                        {
                          mt: 3,
                          ml: 8,
                          color: "black !important",
                        },
                      ]}
                    >
                      Mention Score Field
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Div sx={{ width: "100%", p: 1 }}>
                      <Autocomplete
                        disabled={mainscore}
                        sx={[
                          FontStyle,
                          {
                            mt: 0.4,
                            color: "black !important",
                          },
                        ]}
                        className="search-select col-12 col-md-6 col-lg-6"
                        options={Deal}
                        onChange={(e, newValue) => {
                          setScoreModule(newValue);
                          setactionrule(true);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="" />
                        )}
                      />
                    </Div>
                  </Grid>
                </Grid>
              </>
            )}

            {activeStep == 2 && (
              <>
                <Div
                  x={{
                    width: "100% !important",
                  }}
                >
                  <Typography
                    sx={[
                      FontStyle,
                      {
                        mt: 0.2,
                        // ml: 12,
                        color: "black !important",
                      },
                    ]}
                  >
                    Work Flow Action
                  </Typography>
                </Div>

                <Grid container mt={4} pl={{ xs: 4, md: 8 }} paddingTop={2}>
                  <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Autocomplete
                        sx={[
                          FontStyle,
                          {
                            mt: 0.4,
                            color: "black !important",
                          },
                        ]}
                        className="search-select col-12 col-md-6 col-lg-6"
                        options={["Instant Action", "Scheduled Action"]}
                        onClick={() => setActionDetail(false)}
                        onChange={(e, newValue) => {
                          setActionType(newValue);
                          setActionDetail(true);
                          if (!Boolean(newValue)) {
                            setActionDetail(false);
                          } else if (newValue == "Scheduled Action") {
                            setActionDetail(false);
                            setScheduleDate(true);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose a Action"
                          />
                        )}
                      />
                      <Div
                        sx={{
                          position: "absolute",
                          top: "110%",
                          left: 0,
                          // transform: "translateX(-50%)",
                        }}
                      >
                        <Div
                          sx={{
                            width: "200px",
                            boxShadow: "0px 0px 5px gray",
                            borderRadius: "5px",
                            display: actiondetail ? "" : "none",
                          }}
                          open={actiondetail}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          maxWidth="xs"
                          xs={12}
                        >
                          {TaskList?.map((data, index) => {
                            return (
                              <>
                                <Div
                                  key={index}
                                  onClick={() => {
                                    if (data == "Create Task") {
                                      setScheduleType(data);
                                      setShowTaskPopup({
                                        ...showtaskpopup,
                                        task: true,
                                      });
                                    } else if (data == "Email Notification") {
                                      setScheduleType(data);
                                      setShowTaskPopup({
                                        ...showtaskpopup,
                                        email: true,
                                      });
                                    } else if (data == "Update Field") {
                                      setScheduleType(data);
                                      setShowTaskPopup({
                                        ...showtaskpopup,
                                        updatedField: true,
                                      });
                                    } else {
                                      setShowTaskPopup({
                                        ...showtaskpopup,
                                        task: false,
                                      });
                                      setShowTaskPopup({
                                        ...showtaskpopup,
                                        email: false,
                                      });
                                      setShowTaskPopup({
                                        ...showtaskpopup,
                                        updatedField: false,
                                      });
                                    }
                                  }}
                                  sx={{
                                    p: 1.5,
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "ghostwhite",
                                      borderRadius: "5px",
                                    },
                                  }}
                                >
                                  {data}
                                </Div>
                              </>
                            );
                          })}
                        </Div>
                      </Div>
                    </Div>
                  </Grid>
                </Grid>
                {/* Create Task Popup Page */}
              </>
            )}
          </Div>

          {/* Create Task */}
          <Div>
            <Dialog
              scroll="body"
              PaperProps={{
                sx: {
                  borderRadius: "5px",
                  boxShadow: "0px 4px 4px 5px rgba(0, 0, 0, 0.25)",
                },
              }}
              open={showtaskpopup.task}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth={"xs"}
              fullWidth
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={[dialogTitle, DisplayFlex]}
              >
                Create Task
              </DialogTitle>

              <DialogContent>
                <Grid container>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Task Type{" "}
                    </Typography>
                    <FormControl className="col-12 ">
                      <Autocomplete
                        className="col-12 search-select"
                        options={manufacturer}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option && option?.name) {
                            return option?.name;
                          }
                          return "";
                        }}
                        value={tasktype}
                        onChange={(e, newValue) => {
                          settasktype(newValue?.name || "");
                          // functiondate(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="tasktype"
                            className="col-12"
                            placeholder="Select Task Type"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Task Name
                    </Typography>
                    <TextField
                      className="col-12 input-box"
                      id="outlined-basic"
                      placeholder="Task Name"
                      variant="outlined"
                      value={taskname}
                      onChange={(e) => {
                        settaskname(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Assign To{" "}
                    </Typography>
                    <FormControl className="col-12 ">
                      <TextField
                        className="col-12 input-box"
                        id="outlined-basic"
                        variant="outlined"
                        value={assignto}
                      />
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={6} xl={6} >
                  <Typography id="Typography-root" sx={[FontStyle]}>Date </Typography>
                  <TextField
                    type="date"
                    className="col-12 input-box"
                    id="outlined-basic"
                    placeholder="Allocated Date"
                    variant="outlined"
                    value={allodate}
                    inputProps={{
                      min: new Date().toISOString()?.split("T")[0]
                    }}
                    onChange={(e) => { setallodate(e.target.value) }}
                  />
                </Grid> */}

                  {/* <Grid item xs={12} md={6} lg={6} xl={6} >
                  <Typography id="Typography-root" sx={[FontStyle]}>Start Time</Typography>
                  <TextField
                    type="time"
                    className="col-12 input-box"
                    id="outlined-basic"
                    placeholder="Allocated Date"
                    variant="outlined"
                    value={starttime}
                    onChange={(e) => { setstarttime(e.target.value) }}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6} xl={6} >
                  <Typography id="Typography-root" sx={[FontStyle]}>End Time</Typography>
                  <TextField
                    type="time"
                    className="col-12 input-box"
                    id="outlined-basic"
                    placeholder="Allocated Date"
                    variant="outlined"
                    value={endtime}
                    onChange={(e) => { setendtime(e.target.value) }}
                  />
                </Grid> */}

                  <Grid item xs={12} md={6} lg={6} xl={6} className="row">
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Allocated Date
                    </Typography>
                    <TextField
                      type="date"
                      className="col-12 input-box"
                      id="outlined-basic"
                      placeholder="Allocated Date"
                      variant="outlined"
                      value={allodate}
                      onChange={(e) => {
                        setallodate(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6} className="row">
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Estimated Date
                    </Typography>
                    <TextField
                      type="date"
                      className="col-12 input-box"
                      id="outlined-basic"
                      placeholder="Deadline Date"
                      variant="outlined"
                      value={deaddate}
                      onChange={(e) => {
                        setdeaddate(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Typography id="Typography-root" sx={[FontStyle]}>
                      Description
                    </Typography>
                    <TextField
                      className="col-12 multiline-box"
                      multiline
                      rows={3}
                      id="outlined-basic"
                      placeholder="Write a description..."
                      variant="outlined"
                      value={description}
                      onChange={(e) => {
                        setdescription(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                    justifyContent: "center !important",
                  }}
                >
                  <CustomButton
                    label="Save"
                    onClick={() => {
                      setShowTaskPopup(false);
                    }}
                    Icon="saveIcon"
                  />

                  <Cancelbutton
                    onClick={() => {
                      setShowTaskPopup(false);
                    }}
                  />
                </Div>
              </DialogContent>
            </Dialog>
          </Div>

          {/* Email Notification */}
          <Div>
            <Dialog
              scroll="body"
              PaperProps={{
                sx: {
                  borderRadius: "5px",
                  boxShadow: "0px 4px 4px 5px rgba(0, 0, 0, 0.25)",
                },
              }}
              open={showtaskpopup.email}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth={"xs"}
              fullWidth
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={[
                  dialogTitle,
                  DisplayFlex,
                  { bgcolor: "#dcdcdc !important" },
                ]}
              >
                Email Notification
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2} sx={{ p: "10px" }}>
                  <Grid item xs={12} md={6} lg={12} xl={12} p={2}>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {" "}
                          Choose a Module for Recipients{" "}
                        </Typography>
                      </Div>
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <FormControl className="col-12">
                          <Autocomplete
                            className="col-12 search-select"
                            options={MailSender}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.name) {
                                return option?.name;
                              }
                              return "";
                            }}
                            value={mailmoduletype}
                            onChange={(e, newValue) => {
                              setMailModuleType(newValue?.name || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="tasktype"
                                className="col-12"
                                placeholder=""
                              />
                            )}
                          />
                        </FormControl>
                      </Div>
                    </Div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ p: "10px" }}>
                  <Grid item xs={12} md={6} lg={12} xl={12} p={2}>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {" "}
                          Choose a Recipient{" "}
                        </Typography>
                      </Div>
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <FormControl className="col-12">
                          <Autocomplete
                            className="col-12 search-select"
                            options={
                              mailmoduletype == "Users"
                                ? maillist.user || []
                                : mailmoduletype == "Contacts"
                                ? maillist.contact || []
                                : mailmoduletype == "Leads"
                                ? maillist.lead || []
                                : []
                            }
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.Name) {
                                return option?.Name;
                              }
                              return "";
                            }}
                            onChange={(e, newValue) => {
                              setSelectMail(newValue);
                              console.log("maillist", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="tasktype"
                                className="col-12"
                                placeholder=""
                              />
                            )}
                          />
                        </FormControl>
                      </Div>
                    </Div>
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ p: "10px" }}>
                  <Grid item xs={12} md={6} lg={12} xl={12}>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {" "}
                          Select a Template{" "}
                        </Typography>
                      </Div>
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <FormControl className="col-12">
                          <Autocomplete
                            className="col-12 search-select"
                            options={templatedata}
                            getOptionLabel={(option) => option?.Template_Name}
                            value={templatedata?.Template_Name}
                            onChange={(e, newValue) => {
                              console.log("newValue", newValue);
                              setTempObj(newValue || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="tasktype"
                                className="col-12"
                                placeholder=""
                              />
                            )}
                          />
                        </FormControl>
                      </Div>
                    </Div>
                  </Grid>
                </Grid>
                <Div
                  sx={[
                    DisplayFlex,
                    {
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 2,
                      justifyContent: "center !important",
                    },
                  ]}
                >
                  <CustomButton
                    label="Save"
                    onClick={() => {
                      setShowTaskPopup(false);
                    }}
                    Icon="saveIcon"
                  />
                  <Cancelbutton
                    label="Cancel"
                    onClick={() => {
                      setShowTaskPopup(false);
                    }}
                  />
                </Div>
              </DialogContent>
            </Dialog>
          </Div>

          {/* Update Field */}
          <Div>
            <Dialog
              scroll="body"
              PaperProps={{
                sx: {
                  borderRadius: "5px",
                  boxShadow: "0px 4px 4px 5px rgba(0, 0, 0, 0.25)",
                },
              }}
              open={showtaskpopup.updatedField}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth={"sm"}
              fullWidth
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={[
                  dialogTitle,
                  DisplayFlex,
                  { bgcolor: "#dcdcdc !important" },
                ]}
              >
                Update Filed
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12} md={6} lg={12} xl={12}>
                    <Div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly !important",
                      }}
                    >
                      <Div sx={{ width: "35% !important" }}>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {" "}
                          Name{" "}
                        </Typography>
                      </Div>
                      <Div
                        sx={{
                          width: "100% !important",
                        }}
                      >
                        <TextField
                          onChange={(e) => {
                            setUpdateName(e.target.value);
                          }}
                          value={updateName}
                          className="input-box"
                          placeholder=""
                        />
                      </Div>
                    </Div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6} lg={12} xl={12}>
                    <Div sx={{ mt: 2, border: "1px solid lightgrey" }}>
                      <JumboScrollbar
                        autoHeight={true}
                        autoHideTimeout={4000}
                        autoHeightMax={250}
                        autoHide={true}
                        hideTracksWhenNotNeeded
                        id="no-more-tables"
                      >
                        {modifiedupdate &&
                          modifiedupdate?.map((data, index) => {
                            return (
                              <Grid
                                container
                                key={index + 937}
                                sx={{ my: 0.5 }}
                              >
                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    Update
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} lg={3.5}>
                                  <Typography sx={[FontStyle, {}]}>
                                    <Autocomplete
                                      className="search-select"
                                      options={selectedItem}
                                      name="Field"
                                      onChange={(e, newValue) => {
                                        let updatedValue1 = [...modifiedupdate];
                                        updatedValue1[index].updatedfield =
                                          newValue;
                                        setModifiedUpdate(updatedValue1);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name="category"
                                          placeholder="Select Field"
                                        />
                                      )}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} lg={3.5}>
                                  <TextField
                                    name="Product_Id"
                                    onChange={(e) => {
                                      let updates1 = [...modifiedupdate];
                                      updates1[index].updatevalue =
                                        e.target.value;
                                      setModifiedUpdate(updates1);
                                    }}
                                    className="input-box"
                                    autoComplete="off"
                                    placeholder="Enter Value"
                                  />
                                </Grid>
                                <Grid item md={2} lg={1}>
                                  <DeletedIcon
                                    onClick={() => {
                                      handleDeleteRowUpdate(index);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          })}
                      </JumboScrollbar>
                      <Div>
                        <Button
                          sx={{
                            color: Colors?.Mblue,
                            mt: 1,
                          }}
                          onClick={handlemodalAddRowUpdate}
                        >
                          + Add Fields
                        </Button>
                      </Div>
                    </Div>
                  </Grid>
                </Grid>

                <Div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: 2,
                    justifyContent: "center !important",
                  }}
                >
                  <CustomButton
                    Icon="saveIcon"
                    label="Next"
                    onClick={() => {
                      setShowTaskPopup(false);
                    }}
                  />
                  <Cancelbutton
                    onClick={() => {
                      setShowTaskPopup(false);
                    }}
                  />
                </Div>
              </DialogContent>
            </Dialog>
          </Div>

          {/* Scheduled Date */}
          <Dialog
            open={scheduleddate}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            xs={12}
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={[
                  FontStyle,
                  {
                    lineHeight: 2,
                  },
                ]}
              >
                <Div sx={{ width: "100%" }}>
                  <Typography id="Typography-root">Scheduled Date</Typography>
                </Div>
                <Div sx={{ width: "100%" }}>
                  <TextField
                    type="datetime-local"
                    onChange={(e) => {
                      setScheduled(e.target.value);
                    }}
                    value={scheduled}
                    className="input-box"
                    placeholder=""
                  />
                </Div>
              </DialogContentText>
              <Div
                sx={[
                  DisplayFlex,
                  { justifyContent: "center", gap: "10px", my: 2 },
                ]}
              >
                <CustomButton
                  Icon="nextIcon"
                  label="Next"
                  onClick={() => {
                    setActionDetail(true);
                    setScheduleDate(false);
                  }}
                />
                <Cancelbutton
                  onClick={() => {
                    setScheduleDate(false);
                  }}
                />
              </Div>
            </DialogContent>
          </Dialog>
        </Div>

        <Div
          className="row"
          sx={{
            mt: { xs: 3, md: 5 },
            px: { xs: "5%", md: "10%" },
            overflow: "scroll",
            mb: 2,
          }}
        >
          <Div className="col-2 col-md-1" sx={{ mb: { sm: 2, md: 0 } }}>
            <Cancelbutton
              onClick={handleClickBack}
              label={activeStep === 0 ? "Cancel" : "Back"}
              Icon={activeStep === 0 ? "closeIcon" : "backIcon"}
            />
          </Div>

          {/* code for item master stepper footer */}
          <Div
            className="col-8 col-md-9"
            sx={{ position: "relative", bottom: "0px" }}
          >
            <Stepper
              color={Colors?.Mblue}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps?.map((label, index) => {
                return (
                  <Step key={index}>
                    <StepLabel color={Colors?.Mblue}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Div>

          <Div className="col-2 col-md-2">
            <CustomButton
              onClick={handleClickNext}
              label={activeStep === steps.length - 1 ? "Finish" : "Next"}
              Icon={activeStep === steps.length - 1 ? "saveIcon" : "nextIcon"}
            />
          </Div>
        </Div>

        {/* close Create dialog */}
        <DialogBox
          open={closeCreateDialog}
          onClickNo={() => setCloseCreateDialog(false)}
          onClickYes={() => {
            setShowCreate(false);
            setShowList(true);
            setCloseCreateDialog(false);
          }}
        />

        {/* edit component */}
        {/* <WorkFlowsetupEdit selectedRow={selectedRow} modifiedrows={modifiedrows}/> */}
      </Div>
    </>
  );
};

export default WorkFlowsetup;
