/* eslint-disable array-callback-return */
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Avatar,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import {
  AvatarFont,
  Colors,
  ComponentHeading,
  DisplayFlex,
  FontStyle,
  TableHeaderType,
  avatarLightColors,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { CustomScrollBar, LoadingFullPageSkel } from "app/pages/widgets/StylesComponents";
import {
  loadingfullpage,
  setLoadingFullPageFunc,
} from "app/redux/auth/Loadingskeleton";
import { AutoHeight } from "app/redux/auth/localStorage";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import * as XLSX from 'xlsx';


function SalesForecast() {
  // const loading = useSelector(loadingfullpage);
  const dispatch = useDispatch();

  const [columnMenuItems, setColumnMenuItems] = useState([]);
  const [status, setStatus] = useState(false);


  const [datas, setDatas] = useState([]);

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";


  const [leadsource, setLeadscource] = useState([]);

  const [getSalesperson_name, setgetSalesperson_name] = useState("")
  const [stageName, setstageName] = useState("")
  const [companyname, setcompanyname] = useState("")
  const [currentDate, setCurrentDate] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [customDate, setCustomDate] = useState(false);

  const [printPageState, setPrintPageState] = useState(false);
  const [gearbox, setgearbox] = useState(true);
  const [current, setCurrent] = useState(true);
  const [printPDFForm, setprintPDFForm] = useState(false);
  const [PdfButton, setPdfButton] = useState(false);
  const [ExcelButton, setExcelButton] = useState(false);

  const showPdfForm = () => {
    setCurrent(false);
    setprintPDFForm(true);
    setStatus(true);
    setgearbox(false);
    setPrintPageState(true)
    setPdfButton(true);
    setExcelButton(false);
  };



  const periodOptions = [
    'Previous Week',
    'Previous Month',
    'Previous Quarter',
    'Previous Year',
    'This Week',
    'This Month',
    'This Quarter',
    'This Year',
    'Last One Year',
    'Last Six Month',
    'Last Three Month',
    'Custom Date',
  ];

  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let TodayDate = `${year}-${month < 10 ? "0" + month : month}-${day}`;

  const handlePeriodChange = (event, newValue) => {
    setSelectedPeriod(newValue);
    setSelectedDate(null);
    setfromDate("");
    settoDate("");

    let date;

    let startDate; // for previous
    let endDate;

    switch (newValue) {

      case 'This Week':

        startDate = moment().startOf('week');
        endDate = moment().endOf('week');

        let ThisWeekStart = startDate?.format('YYYY-MM-DD');
        let ThisWeekEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(ThisWeekStart);
        setCurrentDate(ThisWeekEnd);

        setCustomDate(false);
        return;

      case 'This Month':

        startDate = moment().startOf('month');
        endDate = moment().endOf('month');

        let ThisMonthStart = startDate?.format('YYYY-MM-DD');
        let ThisMonthEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(ThisMonthStart);
        setCurrentDate(ThisMonthEnd);

        setCustomDate(false);
        return;

      case 'This Quarter':
        startDate = moment().startOf('quarter');
        endDate = moment().endOf('quarter');

        let ThisQuarterStart = startDate?.format('YYYY-MM-DD');
        let ThisQuarterEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(ThisQuarterStart);
        setCurrentDate(ThisQuarterEnd);

        setCustomDate(false);
        return;

      case 'This Year':
        startDate = moment().startOf('year');
        endDate = moment().endOf('year');

        let ThisYearStart = startDate?.format('YYYY-MM-DD');
        let ThisYearEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(ThisYearStart);
        setCurrentDate(ThisYearEnd);

        setCustomDate(false);
        return;

      case 'Previous Week':
        startDate = moment().subtract(1, 'week').startOf('week');
        endDate = moment().subtract(1, 'week').endOf('week');

        let PrevWeekStart = startDate?.format('YYYY-MM-DD');
        let PrevWeekEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(PrevWeekStart);
        setCurrentDate(PrevWeekEnd);

        setCustomDate(false);
        return;

      case 'Previous Month':
        startDate = moment().subtract(1, 'month').startOf('month');
        endDate = moment().subtract(1, 'month').endOf('month');

        let PrevMonthStart = startDate?.format('YYYY-MM-DD');
        let PrevMonthEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(PrevMonthStart);
        setCurrentDate(PrevMonthEnd);

        setCustomDate(false);
        return;

      case 'Previous Quarter':
        startDate = moment().subtract(1, 'quarter').startOf('quarter');
        endDate = moment().subtract(1, 'quarter').endOf('quarter');

        let PrevQuarterStart = startDate?.format('YYYY-MM-DD');
        let PrevQuarterEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(PrevQuarterStart);
        setCurrentDate(PrevQuarterEnd);

        setCustomDate(false);
        return;

      case 'Previous Year':
        startDate = moment().subtract(1, 'year').startOf('year');
        endDate = moment().subtract(1, 'year').endOf('year');

        let MomentDateStart = startDate?.format('YYYY-MM-DD');
        let MomentDateEnd = endDate?.format('YYYY-MM-DD');

        setSelectedDate(MomentDateStart);
        setCurrentDate(MomentDateEnd);

        setCustomDate(false);
        return;

      case 'Last One Year':
        date = moment().subtract(1, 'year');
        setCustomDate(false);
        break;

      case 'Last Six Month':
        date = moment().subtract(6, 'months');
        setCustomDate(false);
        break;

      case 'Last Three Month':
        date = moment().subtract(3, 'months');
        setCustomDate(false);
        break;

      case 'Custom Date':
        setCustomDate(true);
        break;
      default:
        date = null;
    }
    let MomentDate = date?.format('YYYY-MM-DD');
    setSelectedDate(MomentDate);
    setCurrentDate(TodayDate)
  };

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Karthik", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      setLeadscource(response?.data?.find((op) => op?.Lookupname === "Lead Source")?.Values || [])
      // return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const newfilter = async () => {
    try {

      if (
        selectedDate !== "" && selectedDate !== undefined && selectedDate !== null && currentDate !== ""
        && currentDate !== undefined && currentDate !== null) {


        let payload = {

          fromdate: selectedDate,
          todate: currentDate,
          Is_Deleted: false
        }

        await axios.post(`${BASE_URL}/user/SalesForcastReportStageWise/`, payload, {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            console.log("ressss", res.data);


            setDatas(res?.data?.Result)

          })
      }

      if (
        fromDate !== "" && fromDate !== null && fromDate !== undefined &&
        toDate !== "" && toDate !== null && toDate !== undefined
      ) {
        let payload = {
          fromdate: fromDate,
          todate: toDate,
          Is_Deleted: false
        }

        await axios.post(`${BASE_URL}/user/SalesForcastReportStageWise/`, payload, {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            console.log("ressss1", res.data);


            setDatas(res?.data?.Result)



          }).catch((error) => {
            console.log(error)
          })


      }
    } catch (error) {
      console.log(error);
    }
  }




  useEffect(() => {
    const fetchData = async () => {
      await newfilter();
      await newfilter();
    };
    fetchData();
  }, [selectedDate, fromDate, toDate, getSalesperson_name, stageName]);

  useMemo(() => {
    if (status) {
      setColumnMenuItems([
        { sort: "Account_Name", label: "Sales Stage", bool: status },
        { sort: "", label: "Total Pipeline Value", bool: status },
        { sort: "Contact_Person", label: "Number of Opportunities", bool: status },
        { sort: "Amount", label: "Average Opportunity Value", bool: status },
        { sort: "Deal_Stage", label: "Probability Weighted Value", bool: status },
        { sort: "Customer Name", label: "Time Spent in Stage", bool: status },
        { sort: "Last Contacted", label: "Sales Rep Assigned", bool: status },
        { sort: "Days until Close", label: "Win Rate", bool: status },
        { sort: "Next Steps", label: "Lost Rate", bool: status },

      ])
    } else {
      setColumnMenuItems([
        { sort: "Account_Name", label: "Sales Stage", bool: true },
        { sort: "", label: "Total Pipeline Value", bool: true },
        { sort: "Contact_Person", label: "Number of Opportunities", bool: true },
        { sort: "Amount", label: "Average Opportunity Value", bool: true },
        { sort: "Deal_Stage", label: "Probability Weighted Value", bool: false },
        { sort: "Customer Name", label: "Time Spent in Stage", bool: false },
        { sort: "Last Contacted", label: "Sales Rep Assigned", bool: false },
        { sort: "Days until Close", label: "Win Rate", bool: false },
        { sort: "Next Steps", label: "Lost Rate", bool: false },

      ])
    }
  }, [status])

  //print excel

  function ExportToExcel(type) {
    var elt = document.getElementById('tbl_exporttable_to_xls');
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    XLSX.writeFile(wb, "MyFileName." + type || ('MySheetName.' + (type || 'xlsx')));
  }

  // PDF Print
  const componentPDF = useRef();

  // Function to handle actions after printing
  const handleAfterPrint = () => {
    console.log('Print completed!');
    setStatus(false);
    setgearbox(true);
    setPrintPageState(false);
  };

  const SaveAsPDF = useReactToPrint({
    content: () => componentPDF?.current,
    documentTitle: 'user data',
    pageStyle: `
@page {
  size: landscape;
}
`,
    // onBeforePrint:hanldeRemovePrinter,
    onAfterPrint: handleAfterPrint, // Action after printing
    onPrintError: (error) => console.log("error", error),
  });

  useEffect(() => {
    if (printPageState) {
      // Delay SaveAsPDF until printPageState is true
      SaveAsPDF();
    }
  }, [printPageState]);


  useEffect(() => {
    getlookupData()
  }, []);

  return (
    <JumboContentLayoutMain>
      {/* {loading === "loading" && <LoadingFullPageSkel />} */}

      <Div >
        <Div
          sx={[
            DisplayFlex,
            { justifyContent: "space-between", flexWrap: "wrap" },
          ]}
        >
          <Typography sx={[ComponentHeading, {}]}>
            Sales Forecast by Stage Report
          </Typography>
        </Div>

        <Div sx={{ mt: 2, mb: { xs: 3, md: 1 } }}>
          <Grid container columnSpacing={2} xs={12}>
            {/* <Grid container columnSpacing={2} item xs={12} md={12} lg={6}>

              <Grid item className="col-12 col-md-6">
                <Typography className="col-12 input-label">
                  Choose Lead Source
                </Typography>
                <Autocomplete
                  className="search-select"
                  options={leadsource || []}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.value) {
                      return option?.value;
                    }
                    return "";
                  }}
                  value={getSalesperson_name}
                  onChange={(e, newValue) => {
                    console.log("newwwww", newValue)
                    setgetSalesperson_name(newValue?.value)
                  }}
                  id="outlined-basic"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        borderRadius: "10px",
                      }}
                      placeholder="Lead Source"
                    />
                  )}
                />
              </Grid>

              <Grid item className="col-12 col-md-6">
                <Typography className="col-12 input-label">
                  Choose a Stage
                </Typography>
                <Autocomplete
                  className="col-12 search-select"
                  options={[
                    { stage: "Deals Enquiry Stage" },
                    { stage: "Deals Qualifying Stage" },
                    { stage: "Deals Proposal Stage" },
                    { stage: "Deals Demo Stage" },
                    { stage: "Deals Negotiation Stage" },
                    { stage: "Deals Lost Stage" },
                  ]}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.stage) {
                      return option?.stage;
                    }
                    return "";
                  }}
                  value={stageName}
                  onChange={(e, newValue) =>
                    setstageName(newValue?.stage)
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Choose a Stage" />}
                />
              </Grid>

            </Grid> */}


            <Grid container columnSpacing={2} item xs={12} md={12} lg={6}>

              <Grid item className="col-12 col-md-6">
                <Typography className="col-12 input-label">
                  Choose a Period<span className="required">*</span>
                </Typography>
                <Autocomplete
                  className="col-12 search-select"
                  value={selectedPeriod}
                  options={periodOptions}
                  onChange={handlePeriodChange}
                  renderInput={(params) => <TextField {...params} placeholder="Choose a Period" />}
                />
              </Grid>

              <Grid item className="col-6 col-md-3" style={{ display: customDate ? "block" : "none" }}>
                <Typography className="col-6 input-label">
                  From<span className="required">*</span>
                </Typography>
                <TextField
                  type={"date"}
                  className="col-12 search-select"
                  value={fromDate}
                  onChange={(e) => setfromDate(e.target.value)}
                />
              </Grid>

              <Grid item className="col-6 col-md-3" style={{ display: customDate ? "block" : "none" }}>
                <Typography className="col-6 input-label">
                  To
                </Typography>
                <TextField
                  type={"date"}
                  className="col-12 search-select"
                  value={toDate}
                  onChange={(e) => settoDate(e.target.value)}
                />
              </Grid>

              <Grid item className="col-12 col-md-6">
                <Typography className="col-12 input-label">
                  Save as
                </Typography>

                <Autocomplete
                  className="col-12 search-select"
                  options={[
                    { value: "PDF" },
                    { value: "Excel" },
                  ]}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option?.value) {
                      return option?.value;
                    }
                    return "";
                  }}
                  // value={stageName}
                  onChange={(e, newValue) =>
                    newValue?.value === "PDF" ?
                      showPdfForm() : ExportToExcel('xlsx')
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Pdf" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Div>
        <Div ref={componentPDF}>
          <CustomScrollBar
            stateValue={!printPageState}
            childrens={
              <div style={{ margin: "1rem 0rem" }}>
                <TableContainer>
                  <Table stickyHeader>
                    <CustomTableHeader
                      headerMenuItems={columnMenuItems}
                      setHeadermenuItems={setColumnMenuItems}
                      setDatas={setDatas}
                      sortDatas={datas}
                      transformText={TableHeaderType}
                      showGear={gearbox}
                    />

                    <TableBody>
                      {datas.length > 0 ? (
                        datas?.map((data, index) => {

                          return (
                            <CRMTableRow>
                              {columnMenuItems[0]?.bool && (
                                <CRMTableCell
                                  data-title="Prospect"
                                >
                                  {data?.Stage}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[1]?.bool && (
                                <CRMTableCell data-title="Opportunity">
                                  {data?.Total_Pipeline_Value?.toFixed(2)}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[2]?.bool && (
                                <CRMTableCell data-title="Contact Person">
                                  {data?.Number_of_Opportunities}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[3]?.bool && (
                                <CRMTableCell
                                  data-title="Deal Value"
                                >
                                  {data?.Average_Opportunity_Value?.toFixed(2)}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[4]?.bool && (
                                <CRMTableCell data-title="Status">

                                  {data?.Probability_Weighted_Value} %

                                </CRMTableCell>
                              )}
                              {columnMenuItems[5]?.bool && (
                                <CRMTableCell
                                  data-title="Actual Earnings"
                                >
                                  {data?.Time_Spent_in_Stage}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[6]?.bool && (
                                <CRMTableCell data-title="Comments">
                                  {data?.Sales_Rep_Assigned}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[7]?.bool && (
                                <CRMTableCell data-title="Comments">
                                  {data?.Win_Rate} %
                                </CRMTableCell>
                              )}
                              {columnMenuItems[8]?.bool && (
                                <CRMTableCell data-title="Comments">
                                  {data?.Loss_Rate} %
                                </CRMTableCell>
                              )}
                            </CRMTableRow>
                          );
                        })
                      ) : (
                        <CRMTableRow>
                          <CRMTableCell
                            align="center"
                            sx={{ color: Colors?.blue }}
                            colSpan={8}
                          >
                            No Records
                          </CRMTableCell>
                        </CRMTableRow>
                      )}
                    </TableBody>
                  </Table>

                </TableContainer>
              </div>
            }
            fromDate={fromDate ? fromDate : selectedDate}
            toDate={toDate ? toDate : currentDate}
            formName={"Sales Forecast By Stage"}
          />
        </Div>

      </Div>

      <div>
        <table id="tbl_exporttable_to_xls" style={{ display: "none" }}>
          <thead>

            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>Opportunity Aging Report</th>
              <th></th>
              <th></th>
            </tr>

            <tr>
              <th></th>
              <th></th>
              <th>From : {fromDate ? fromDate : selectedDate}</th>
              <th></th>
              <th>To : {toDate ? toDate : currentDate}</th>
              <th></th>
            </tr>

            <tr>
              <th>Sales Stage</th>
              <th>Total Pipeline Value </th>
              <th>Number of Opportunities </th>
              <th>Average Opportunity Value </th>
              <th >Probability Weighted Value </th>
              <th>Time Spent in Stage</th>
              <th>Sales Rep Assigned </th>
              <th>Win Rate </th>
              <th>Lost Rate </th>
            </tr>

          </thead>
          <tbody>
            {datas.length > 0 ? (
              datas?.map((data, index) => {

                return (
                  <tr>

                    <td>
                      {data?.Stage}
                    </td>

                    <td>
                      {data?.Total_Pipeline_Value?.toFixed(2)}
                    </td>

                    <td >
                      {data?.Number_of_Opportunities}
                    </td>

                    <td

                    >
                      {data?.Average_Opportunity_Value?.toFixed(2)}
                    </td>

                    <td >

                      {data?.Probability_Weighted_Value} %

                    </td>

                    <td

                    >
                      {data?.Time_Spent_in_Stage}
                    </td>

                    <td>
                      {data?.Sales_Rep_Assigned}
                    </td>

                    <td>
                      {data?.Win_Rate} %
                    </td>

                    <td>
                      {data?.Loss_Rate} %
                    </td>

                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  align="center"
                  sx={{ color: Colors?.blue }}
                  colSpan={8}
                >
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </JumboContentLayoutMain>
  );
}

export default SalesForecast;
