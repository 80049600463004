import {
  Colors,
  avatarColors,
  componentTitle,
  deletedWarnigMsg,
  dialogFontSize,
  dialogTitle,
  input_label,
} from "../widgets/CRMStyles";
import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Divider,
  Stack,
  Paper,
  InputBase,
  Autocomplete,
  FormControl,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Avatar,
  FormGroup,
  Pagination,
  Checkbox,
  Box,
  useMediaQuery,
  Tooltip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "react-toastify/dist/ReactToastify.css";
import { MailIcon, PhoneIcon, SettingsIcon } from "app/icons/PngIcons";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { CRMTableCell, CRMTableRow } from "../widgets/CRMTable";
import { BsArrowDownUp } from "react-icons/bs";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEdit from "@mui/icons-material/ModeEdit";
import {
  Cancelbutton,
  DialogBox,
  CustomButton,
  DeletedIcon,
  EditIcon,
  NoRecordsPage,
  Dragabledialog,
  Customgrid,
  errorFilter,
  AvatarCircle,
  avatarColorFun,
  SelectDropdown,
  formatDate,
  CustomIcons,
  fetchTransactionSeries,
  CustomCKEditor,
} from "../widgets/StylesComponents";
import CustomTableHeader from "../widgets/CustomTableHeader";
import { AutoHeight } from "app/redux/auth/localStorage";
import { useQuery, useQueryClient } from "react-query";
import { TiEdit } from "react-icons/ti";
import { FaGear } from "react-icons/fa6";
import { BiFilterAlt } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { City, Country, State } from "country-state-city";
import OverviewPage from "../components/OverviewPage";
import "../leads/Lead.css";
import LeadQuestionEdit from "./LeadQuestionEdit";

const LeadQuestions = () => {
  const token = localStorage.getItem("accesstoken");

  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const item = "crmuseradmin1";
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [errors, setErrors] = useState({});

  const matches = useMediaQuery("(min-width:600px)");

  const onChange = (content) => {
    setEditorContent(content);
  };

  //  Getting the List of Lead Question Additional
  const [datas, setDatas] = useState([]);

  //  For Pagination Table
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [seriesValue, setSeriesValue] = useState("");
  const [LeadQuestionId, setLeadQuestionId] = useState("");

  // For Dialog Popup
  const [openAnchor, setOpenAnchor] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [update, setUpdate] = useState(0);
  const [overview, setOverview] = useState(false);

  // Delete Popup Functionality
  const [deleteDatas, setDeleteDatas] = useState(null);
  const [showDelete, setShowDelete] = useState(null);
  const [number, setNumber] = useState(1);

  const [Loading, setLoading] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    leadQuatationListsRefetch(newPage);
  };

  const getData = async (page) => {
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    try {
      const response = await axios.get(
        `${BASE_URL}/user/LeadQualifyQuestionsAllAdditional/?page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("results in contacts", response.data.results);
      const data = response.data.results || [];
      const count = response.data?.count || 0;
      return { data, count };
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  };

  // Lead Generation
  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Fields for Answer

  const [name, setName] = useState([
    {
      Answer: "",
      Points: "",
      Updated_By: localStorage.getItem("UserId"),
      Created_By: localStorage.getItem("UserId"),
    },
    {
      Answer: "",
      Points: "",
      Updated_By: localStorage.getItem("UserId"),
      Created_By: localStorage.getItem("UserId"),
    },
    {
      Answer: "",
      Points: "",
      Updated_By: localStorage.getItem("UserId"),
      Created_By: localStorage.getItem("UserId"),
    },
    {
      Answer: "",
      Points: "",
      Updated_By: localStorage.getItem("UserId"),
      Created_By: localStorage.getItem("UserId"),
    },
  ]);

  // Get Lead Industry

  const [leadindustry, setLeadindustry] = useState("");

  const [indus, setIndus] = useState("");
  const [Question, setQuestion] = useState("");

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  //  For the popup

  const [save, setSave] = useState(false);

  // Handle Submit

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = errorFilter({ indus: indus, editorContent });

    if (Boolean(seriesValue !== "Automatic") && !Boolean(LeadQuestionId)) {
      error.LeadQuestionId = "Lead Question Id Id is required";
    } else if (
      Boolean(seriesValue !== "Automatic") &&
      !Boolean(LeadQuestionId?.length == 11)
    ) {
      error.LeadQuestionId = "Lead Question Id Id must be 11 digits.";
    }

    let filter = name?.filter((o, i) => {
      if (!Boolean(o?.Answer) || !Boolean(o?.Points)) {
        if (!error.Answer) {
          error.Answer = [];
        }
        error.Answer.push(i);
      }
    });

    let bool = name?.every((o) => Boolean(o?.Answer) && o?.Points);

    console.log("indus.value", error, bool, filter);
    setErrors(error);
    // console.log("payload", payload);
    if (Object.keys(error).length > 0 || !bool) {
      toast.error("Please Check the all the fields are Filled", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      let payload = {
        LeadQuestion_Id: seriesValue === "Manual" && LeadQuestionId,
        Question_Tittle: "",
        Industry_Type: indus?.value,
        Question: editorContent,
        Industry_Id: indus.id,
        Answer_Id: name,
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };

      await axios
        .post(`${BASE_URL}/user/LeadQualifyQuestionsCRUD/`, payload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("check", res);
          toast.success("Created successfully", {
            position: "top-right",
            autoClose: 100,
          });
          leadQuatationListsRefetch();
          setOpenAddNotes(false);

          cleardata();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  // For Table Modifications

  const [columnMenuItems, setColumnMenuItems] = useState([
    // { sort: "Industry_Type", label: "Industry Type", bool: true },
    { sort: "Question", label: "Question", bool: true },
    { sort: "LeadQuestion_Id", label: "Question Id", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
  ]);

  // For Asecnding Order

  const [showsort, setShowsort] = useState(true);

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas].sort((a, b) =>
      value === "A-Z"
        ? a?.Industry_Type?.localeCompare(b?.Industry_Type)
        : b?.Industry_Type?.localeCompare(a?.Industry_Type)
    );
    setDatas(sortedData);
  };

  // For filter Functionality
  const [contry, setContry] = useState("");
  const [cty, setCty] = useState("");
  const [fromdate, setFromdate] = useState(new Date().toISOString());
  const [todate, setToDate] = useState(new Date().toISOString());
  const [statefil, setStatefil] = useState("");

  const filterSubmit = async (e) => {
    console.log("fromdate", todate, fromdate);
    e.preventDefault();
    try {
      axios
        .get(
          `${BASE_URL}/user/LeadQualifyQuestionsFilter/?Industry_Type=${indus}&fromdate=${fromdate}&todate=${todate}&Question=${Question}&page=${page}`,
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          setDatas(res?.data?.results || []);
          setShowFilter(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const RefreshData = () => {
    setQuestion("");
    setIndus("");
    setFromdate("");
    setToDate("");
    // leadQuatationListsRefetch(page);
    setDatas(leadQuatationLists?.data);
    setShowFilter(false);
  };

  const cleardata = () => {
    setEditorContent("");
    setIndus("");
    setName([
      {
        Answer: "",
        Points: "",
        Updated_By: localStorage.getItem("UserId"),
        Created_By: localStorage.getItem("UserId"),
      },
      {
        Answer: "",
        Points: "",
        Updated_By: localStorage.getItem("UserId"),
        Created_By: localStorage.getItem("UserId"),
      },
      {
        Answer: "",
        Points: "",
        Updated_By: localStorage.getItem("UserId"),
        Created_By: localStorage.getItem("UserId"),
      },
      {
        Answer: "",
        Points: "",
        Updated_By: localStorage.getItem("UserId"),
        Created_By: localStorage.getItem("UserId"),
      },
    ]);
  };

  // Edit And Delete Menu Functionality
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  // Handle Update Function For the Update

  const [openEditContact, setOpenEditContact] = useState(false);

  const handleUpdate = (data) => {
    setUpdate(data);
  };

  const handleClickEditContact = (update) => {
    setNumber(update?.id);
    setOpenEditContact(true);
    handleUpdate(update);
  };

  const Deletedata = () => {
    axios
      .delete(`${BASE_URL}/user/LeadQualifyQuestionsCRUD/?id=${number}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast.success("Deleted successfully", {
          position: "top-right",
          autoClose: 100,
        });
        leadQuatationListsRefetch(page);
        handleCloseAction();
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleRecall = (value) => {
    if (value) {
      // leadQuatationListsRefetch(1)
    }
  };

  const {
    data: leadQuatationLists,
    isLoading: leadQuatationListsLoading,
    isError: leadQuatationListsError,
    refetch: leadQuatationListsRefetch,
  } = useQuery(["leadQuatationLists", page], async () => await getData(page), {
    staleTime: Infinity,
    keepPreviousData: true,
  });

  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight - 5 && !Loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [Loading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  useEffect(() => {
    if (getUserLoopUp) {
      setLeadindustry(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type") || []
      );
    }
  }, [getUserLoopUp]);

  useEffect(() => {
    // if (leadQuatationLists) {
    //   setDatas(leadQuatationLists?.data || []);
    //   setCount(leadQuatationLists?.count || 0);
    // }

    if (leadQuatationLists) {
      setDatas((prevDatas) => {
        const updatedDatas = new Set([
          ...prevDatas,
          ...leadQuatationLists?.data,
        ]);
        return Array.from(updatedDatas);
      });
      let Counts =
        leadQuatationLists?.data?.length == 0
          ? datas?.length
          : leadQuatationLists?.count || 0;
      setCount(Counts);
    }
  }, [leadQuatationLists]);

  useEffect(async () => {
    const series = await fetchTransactionSeries("LeadQualify_Questions");
    console.log("LeadQualify_Questions series", series);
    setSeriesValue(series);
  }, []);

  console.log("leadQuatationLists", { leadQuatationLists });

  return (
    <>
      <JumboContentLayoutMain>
        <Box>
          <Typography
            sx={[componentTitle]}
            style={{
              backgroundColor: "#efefef ",
              padding: "15px",
              borderRadius: "5px",
            }}
          >
            Lead Questions
          </Typography>
          <Box className="mt-3">
            <Grid container spacing={2}>
              <Grid
                item
                xs={10.5}
                md={6}
                display={"flex"}
                alignItems={"center"}
              >
                <IconButton
                  sx={{ color: Colors?.Mblue }}
                  onClick={(e) => setShowFilter(e.currentTarget)}
                >
                  <BiFilterAlt size={22} />
                </IconButton>
                <Typography
                  sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                >
                  ( {count} ) Questions
                </Typography>
              </Grid>
              {!matches && (
                <Grid item xs={1} md={1}>
                  <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                    <FaGear style={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              )}

              {/* Filter popover  */}

              <Popover
                open={Boolean(showFilter)}
                anchorEl={showFilter}
                onClose={() => setShowFilter(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
              >
                <Box>
                  <Div
                    sx={[
                      DisplayFlex,
                      CRMTableHead,
                      dialogTitle,
                      { px: 2, justifyContent: "space-between" },
                    ]}
                  >
                    <Typography id="Typography-root" sx={[dialogTitle]}>
                      Filters
                    </Typography>
                    <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                      <GrPowerReset size={16} />
                    </IconButton>
                  </Div>

                  <form style={{ padding: "1rem" }} onSubmit={filterSubmit}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Country
                        </Typography>
                        <Autocomplete
                          className="col-12 search-select3"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={Country.getAllCountries() || []}
                          value={contry?.name}
                          onChange={(e, newValue) => {
                            setContry(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Country" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          State
                        </Typography>
                        <Autocomplete
                          className=" col-12 search-select3"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          value={statefil?.name}
                          options={
                            State.getStatesOfCountry(contry?.isoCode) || []
                          }
                          onChange={(e, newValue) => {
                            setStatefil(newValue);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.name === value || value === ""
                          }
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="State" />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          City
                        </Typography>
                        <Autocomplete
                          className="col-12 search-select3"
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option?.name) {
                              return option?.name;
                            }
                            return "";
                          }}
                          options={
                            City.getCitiesOfState(
                              statefil?.countryCode,
                              statefil?.isoCode
                            ) || []
                          }
                          value={cty?.name}
                          onChange={(e, newValue) => {
                            setCty(newValue);
                          }}
                          id="outlined-basic"
                          renderInput={(params) => (
                            <TextField {...params} placeholder="City" />
                          )}
                        />
                      </Grid> */}

                      <Grid item xs={12} md={12}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Industry Type
                        </Typography>
                        <TextField
                          type="text"
                          className="col-12 input-box"
                          autoComplete="off"
                          placeholder="Industry Type"
                          value={indus}
                          onChange={(e) => {
                            setIndus(e.target.value);
                          }}
                          sx={{ mt: -0.5 }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          Question
                        </Typography>
                        <TextField
                          multiline
                          rows={3}
                          type="text"
                          id="outlined-basic"
                          className="w-full"
                          variant="outlined"
                          autoComplete="off"
                          placeholder="Enter Question"
                          value={Question}
                          onChange={(e) => {
                            setQuestion(e.target.value);
                          }}
                          sx={{
                            fontSize: "14px !important",
                            backgroundColor: "#ffffff !important",
                            // font-size: 14px !important;

                            // line-height: 38px !important;
                            // background-color: #ffffff !important;
                          }}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          From Date
                        </Typography>
                        <TextField
                          type="date"
                          className="col-12 input-box"
                          value={fromdate}
                          onChange={(e) => {
                            setFromdate(e.target.value);
                          }}
                          id="createdDate"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                        >
                          To Date
                        </Typography>
                        <TextField
                          value={todate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          type="date"
                          className="col-12 input-box"
                          id="lastDate"
                        />
                      </Grid>
                    </Grid>

                    <Div
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        gap: "10px",
                      }}
                    >
                      {/* <Button
                    type="submit"
                    sx={[DialogBoxSaveButton, ButtonStyle, FontStyle]}
                  >
                    Apply
                  </Button> */}
                      <CustomButton
                        type="submit"
                        label="Apply"
                        Icon="filterIcon"
                      />
                      <Cancelbutton onClick={() => setShowFilter(null)} />
                    </Div>
                  </form>
                </Box>
              </Popover>

              {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                  <Typography
                    sx={[
                      componentTitle,
                      { color: Colors?.gray, fontWeight: 700 },
                    ]}
                  ></Typography>
                  <Div className="flex items-center justify-center gap-2">
                    <SelectDropdown
                      className="search-select3"
                      defaultValue={"A-Z"}
                      onChange={sortData}
                      options={["A-Z", "Z-A"]}
                      width="70px"
                      clearButton={false}
                    />
                    {matches && (
                      <IconButton
                        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                      >
                        <FaGear style={{ fontSize: 18 }} />
                      </IconButton>
                    )}
                  </Div>

                  <CustomButton
                    onClick={() => setOpenAddNotes(true)}
                    label="Create"
                    Icon="createIcon"
                  />
                </Div>
              </Grid>
            </Grid>
          </Box>

          <List sx={{ mt: 0 }}>
            {datas?.length > 0 &&
              datas?.map((data, idx) => {
                // if (idx < 2) {

                let image =
                  (BASE_URL +
                    data?.File?.split("https://backend.crmfarm.app")[1] ||
                    data?.File) ??
                  "";
                let Question = data?.Question?.substring(
                  3,
                  data?.Question?.length - 4
                );
                return (
                  <Div
                    // onMouseOver={() => setFadeEdit(idx)}
                    // onMouseLeave={() => setFadeEdit(null)}
                    className=""
                    key={idx}
                    sx={{
                      minHeight: "124px",

                      p: { xs: 0.5, sm: 2 },
                      px: { xs: 0.5, md: 7 },
                      my: 2,

                      bgcolor: "white",
                      borderRadius: { xs: "5px", md: "10px" },
                      ":hover": {
                        backgroundColor: Colors?.lightgray,
                      },
                      cursor: "default",
                    }}
                  >
                    <Grid
                      container
                      alignItems={"center"}
                      spacing={2}
                      sx={{ pr: "5%" }}
                    >
                      <Grid item xs={12} md={1.5}>
                        <div
                          style={{ marginTop: "6px" }}
                          onClick={() => {
                            let obj = {};
                            Object.entries(data)?.map(([key, value]) => {
                              columnMenuItems?.filter((item) => {
                                if (item.sort === key) {
                                  if (key === "Question") {
                                    obj[key] = data[key]?.substring(
                                      3,
                                      data[key]?.length - 4
                                    );
                                    return;
                                  }
                                  obj[key] = data[key];

                                  console.log("obj[key]", key, obj);
                                }
                              });
                            });
                            console.log("columnMenuItems", obj);
                            setOverview(obj);
                          }}
                        >
                          <AvatarCircle
                            size="90px"
                            fontSize="30px"
                            bgColor={avatarColorFun(idx)}
                            title={!Boolean(data?.File) && data?.Industry_Type}
                            image={Boolean(data?.File) ? image : false}
                          />
                        </div>
                      </Grid>

                      <Grid container spacing={1} item xs={12} md={10.5}>
                        <Grid item xs={12}>
                          <Box
                            sx={[
                              DisplayFlex,
                              {
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                              },
                            ]}
                          >
                            <Typography
                              sx={[
                                {
                                  fontSize: "18px",
                                  fontWeight: 700,
                                  color: `${Colors?.Mblue}`,
                                },
                              ]}
                            >
                              {data?.Industry_Type || "No Name"}
                            </Typography>
                            <Div sx={[DisplayFlex]}>
                              <CustomButton
                                onClick={(e) => {
                                  setOpenAnchor(e.target);
                                  setUpdate(data);
                                }}
                                sx={{ borderRadius: "25px" }}
                                label="Action"
                                bgColor={Colors?.Mblue}
                                Icon="downArrowIcon"
                                endIcon={true}
                              />
                            </Div>
                          </Box>
                        </Grid>
                        {columnMenuItems?.map((Menu, subIdx) => {
                          let value =
                            Menu?.sort === "Created_Date" ||
                            Menu?.sort === "Updated_Date"
                              ? formatDate(data[Menu?.sort])
                              : Menu?.sort === "Question"
                              ? data[Menu?.sort]?.length > 30
                                ? data[Menu?.sort]?.substring(3, 30) + "..."
                                : data[Menu?.sort]?.substring(
                                    3,
                                    data[Menu?.sort]?.length - 4
                                  )
                              : data[Menu?.sort] ?? "-";
                          return (
                            <>
                              {Menu?.bool && (
                                <Grid key={subIdx} item xs={12} md={4}>
                                  <Typography
                                    sx={[
                                      { fontSize: "14px", color: "#828282" },
                                    ]}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        color: "#5e5e5e",
                                      }}
                                    >
                                      {Menu?.label} :
                                    </span>{" "}
                                    {value}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Div>
                );
                // }
              })}
          </List>

          {/* gear options */}
          <Menu
            id="menu-appbar"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(null)}
            sx={{
              mt: 5,
              maxHeight: "300px",
              scrollBehavior: "smooth",
            }}
          >
            <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
              Choose Fields
            </Typography>
            <FormGroup sx={{ p: 2 }}>
              {columnMenuItems?.length > 0 &&
                columnMenuItems?.map((column, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="info"
                          size="small"
                          checked={column?.bool}
                          onChange={(e) => {
                            let value = [...columnMenuItems];
                            value[index].bool = !column?.bool;
                            setColumnMenuItems(value);
                          }}
                        />
                      }
                      label={
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          {column?.label}
                        </Typography>
                      }
                    />
                  );
                })}
            </FormGroup>
          </Menu>

          {/* openAnchor Action popover */}
          <Popover
            id={Boolean(openAnchor) ? "simple-popover" : undefined}
            open={Boolean(openAnchor)}
            anchorEl={openAnchor}
            onClose={() => {
              setOpenAnchor(null);
              // setIsLoading(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List sx={{ p: 1 }}>
              <ListItemButton
                onClick={() => {
                  setOpenEditContact(true);
                  setOpenAnchor(null);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: Colors?.Mblue,
                    minWidth: "25px !important",
                  }}
                >
                  <TiEdit
                    style={{
                      fontSize: 18,
                      fontWeight: 900,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography sx={[FontStyle]}>Edit</Typography>}
                />
              </ListItemButton>

              <ListItemButton
                onClick={() => {
                  setNumber(update?.id);
                  setShowDelete(true);
                }}
                sx={{ borderRadius: "5px" }}
              >
                <ListItemIcon
                  sx={{ minWidth: "25px !important", color: "tomato" }}
                >
                  {CustomIcons?.deleteIcon}
                </ListItemIcon>
                <ListItemText
                  primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                />
              </ListItemButton>
            </List>
          </Popover>
        </Box>
        {/* <Div
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Pagination
            color="success"
            count={Math.ceil(count / 10)}
            page={page}
            onChange={(e, newValue) => handleChangePage(e, newValue)}
            size="small"
            showFirstButton
            showLastButton
          />
        </Div> */}
        <DialogBox
          open={showDelete}
          Content={deletedWarnigMsg}
          onClickNo={() => setShowDelete(false)}
          onClickYes={() => {
            setShowDelete(false);
            Deletedata();
          }}
        />

        {/* overview */}
        <OverviewPage
          label={"Lead Question"}
          datas={overview}
          open={Boolean(overview)}
          setOpen={setOverview}
          onClose={() => setOverview(false)}
        />

        {/* Dialog box for rg */}

        <Dragabledialog
          maxWidth="lg"
          open={openAddNotes}
          // onClose={() => setOpenNotes(false)}
        >
          <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Create Lead Questions
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <Customgrid>
                <Grid item>
                  <Typography sx={[input_label]}>
                    Lead Question Id{" "}
                    <span className="required">
                      {seriesValue == "Automatic" ? "" : "*"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl className="col-12">
                    <TextField
                      sx={{
                        "& fieldset": {
                          borderRadius: "5px",
                        },
                        MinWidth: "325px",
                        width: "100%",
                      }}
                      className={`col-12 input-box2`}
                      id="outlined-basic"
                      name="LeadQuestionId"
                      value={LeadQuestionId}
                      onChange={(e) => setLeadQuestionId(e.target.value)}
                      placeholder="# Lead Question Id"
                      variant="outlined"
                      disabled={seriesValue == "Automatic" ? true : ""}
                    />
                    {errors?.LeadQuestionId && (
                      <Div style={{ color: "red" }}>
                        {errors?.LeadQuestionId}
                      </Div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item>
                  <Typography sx={[input_label]}>Industry Type</Typography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    value={indus}
                    className="search-select3 col-12"
                    options={(leadindustry && leadindustry?.Values) || []}
                    onChange={(e, newValue) => {
                      console.log("newValue", newValue);
                      setIndus(newValue);
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Industry Type" />
                    )}
                  />
                  {errors?.indus && (
                    <Typography
                      sx={[{ fontSize: "12px", color: "red !important" }]}
                    >
                      Industry Type {errors.indus}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={[input_label]}>Enter the Question</Typography>
                </Grid>
                <Grid item>
                  <CustomCKEditor
                    onChange={onChange}
                    initData={editorContent}
                  />
                  {errors?.editorContent && (
                    <Typography
                      sx={[{ fontSize: "12px", color: "red !important" }]}
                    >
                      Question {errors.editorContent}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography sx={[input_label]}>Answer & Points</Typography>
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    {name?.length > 0 &&
                      name?.map((data, index) => {
                        return (
                          <Grid container spacing={2}>
                            <Grid item sx={{ my: 0.5 }} xs={12} md={10.5}>
                              <Div
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingTop: 1,
                                  gap: "10px !important",
                                  width: "100%",
                                }}
                              >
                                <FormControlLabel
                                  value={index}
                                  control={<Radio color="info" size="small" />}
                                />
                                <Div sx={{ width: "100%" }}>
                                  <TextField
                                    className=" w-full input-box2"
                                    id="outlined-basic"
                                    placeholder="Enter the answer"
                                    variant="outlined"
                                    value={name[index].ans}
                                    sx={{ width: "100%" }}
                                    onChange={(e) => {
                                      let ch = [...name];
                                      ch[index].Answer = e.target.value;
                                      setName(ch);
                                    }}
                                    disabled={selectedValue != index}
                                  />
                                  {errors?.Answer?.some((o) => o === index) && (
                                    <Typography
                                      sx={[
                                        {
                                          fontSize: "12px",
                                          color: "red !important",
                                        },
                                      ]}
                                    >
                                      Answer & Points is required
                                    </Typography>
                                  )}
                                </Div>
                              </Div>
                            </Grid>
                            <Grid item sx={{ py: 0.5 }} xs={12} md={1.5}>
                              <Div sx={{ paddingTop: 1.5 }}>
                                <TextField
                                  className=" input-box2"
                                  id="outlined-basic"
                                  variant="outlined"
                                  sx={{
                                    width: "100%",
                                  }}
                                  onChange={(e) => {
                                    let ch1 = [...name];
                                    ch1[index].Points = e.target.value;
                                    setName(ch1);
                                  }}
                                  disabled={selectedValue != index}
                                />
                              </Div>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </RadioGroup>
                </Grid>
              </Customgrid>

              <Div sx={[DisplayFlex, { my: 2, justifyContent: "center" }]}>
                <CustomButton label="Save" Icon="saveIcon" type="submit" />
                <Cancelbutton
                  label="Cancel"
                  onClick={() => {
                    setSave(true);
                    cleardata();
                  }}
                />
              </Div>
            </form>
          </DialogContent>
        </Dragabledialog>

        {/* Contact Notes Edit Operation */}

        <LeadQuestionEdit
          openEditContact={openEditContact}
          setOpenEditContact={setOpenEditContact}
          update={update}
          setDatas={setDatas}
          getData={getData}
          handleRecall={handleRecall}
          refetch={leadQuatationListsRefetch}
        />

        {/* close dialog box */}
        <DialogBox
          open={save}
          onClickNo={() => setSave(false)}
          onClickYes={() => {
            setSave(false);
            setOpenAddNotes(false);
          }}
        />
      </JumboContentLayoutMain>
    </>
  );
};

export default LeadQuestions;
