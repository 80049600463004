import React, { useState } from "react";
import "./Navbar.css";
import { SiMinutemailer } from "react-icons/si";
import { RiAccountCircleFill } from "react-icons/ri";
import { Colors, StyledTab, StyledTabs } from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import { HiOutlineBars3 } from "react-icons/hi2";
import { IconButton, Typography } from "@mui/material";
import { navdatas } from "./navbarData";
import { useEmailContext } from "../../Context/EmailsContext";

const Navbar = () => {
  const { setSidebarView } = useEmailContext();
  const [tabvalue, setTabvalue] = useState(0);

  return (
    <>
      <nav className="navbar">
        <div className="nav-items">
          <h3>
            <img src={require("../../assets/outlook.png")} width={"30px"} />{" "}
            <span style={{ fontSize: "16px", color: Colors?.Mblue }}>CRM</span>{" "}
            <span
              style={{ fontSize: "16px" }}
              className="text-[rgb(18 224 231)]"
            >
              Outlook
            </span>
          </h3>
          <h4>
            <SearchBar bgcolor={"#ffff"} />
          </h4>
        </div>

        <div className="top-bar">
          <div className="top-bar-1">
            <div>
              <IconButton onClick={() => setSidebarView((prev) => !prev)}>
                <HiOutlineBars3
                  style={{ fontSize: "25px", color: "#242424" }}
                />
              </IconButton>
            </div>
            <div>
              <StyledTabs
                value={tabvalue}
                onChange={(e, newValue) => {
                  setTabvalue(newValue);
                }}
                aria-label="ant example"
              >
                <StyledTab
                  label={
                    <Typography
                      sx={{
                        color: tabvalue === 0 ? Colors?.Mblue : "#242424",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Home
                    </Typography>
                  }
                />
                <StyledTab
                  label={
                    <Typography
                      sx={{
                        color: tabvalue === 1 ? Colors?.Mblue : "#242424",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      View
                    </Typography>
                  }
                />
                <StyledTab
                  label={
                    <Typography
                      sx={{
                        color: tabvalue === 2 ? Colors?.Mblue : "#242424",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Help
                    </Typography>
                  }
                />
              </StyledTabs>
            </div>
          </div>

          <div className="top-bar-2">
            <div className="flex items-center gap-4 ">
              {navdatas?.home?.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="flex items-center gap-1 cursor-pointer p-[12px] hover:bg-[#f8f8f8]"
                  >
                    <span>{item?.icon}</span>
                    <span>{item?.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
