import {
  Autocomplete,
  IconButton,
  List,
  Grid,
  ListItem,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Popover,
  ListItemButton,
  FormControlLabel,
  Checkbox,
  FormGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  DialogTitle,
  DialogContent,
  FormControl,
  alpha,
  DialogContentText,
  Dialog,
  Button,
  DialogActions,
} from "@mui/material";

import moment from "moment";
import {
  deletedWarnigMsg,
  dialogFontSize,
  input_label,
  UploadBtn,
} from "app/pages/widgets/CRMStyles";

// import CloseIcon from "@mui/icons-material/Close"
import React, { useCallback, useEffect } from "react";
import * as yup from "yup";
import { useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiFilterAlt } from "react-icons/bi";
import { BASE_URL, Tenantname } from "app/services/auth-services";
import {
  CRMTableHead,
  Colors,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  componentTitle,
} from "app/pages/widgets/CRMStyles";
import { GrPowerReset } from "react-icons/gr";
import {
  AvatarCircle,
  Cancelbutton,
  DialogBox,
  CustomButton,
  InvalidateQueryUpdates,
  Div,
  CustomIcons,
  SelectDropdown,
  avatarColorFun,
  Dragabledialog,
  Customgrid,
  errorFilter,
  formatDate,
} from "app/pages/widgets/StylesComponents";
import { useSelector } from "react-redux";
import { isLoadings } from "app/redux/auth/Loadingskeleton";
import randomColor from "randomcolor";
import { useQuery, useQueryClient } from "react-query";
import { TiEdit } from "react-icons/ti";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { FaGear } from "react-icons/fa6";
import { RiMailSendLine } from "react-icons/ri";
import { MdClose, MdOutlineNoteAdd } from "react-icons/md";
import { TbActivity } from "react-icons/tb";
import TodayIcon from "@mui/icons-material/Today";
import ComposeEmail from "../contacts/extra-components/ComposeEmail";
import AppointmentModel from "../contacts/extra-components/AppointmentModel";
import NotesModel from "../contacts/extra-components/NotesModel";
import CreateQualifiedLeads from "./CreateQualifiedLeads";
import QualifiedLeadsEdit from "./QualifiedLeadsEdit";
import ActivityModel from "../contacts/extra-components/ActivityModel";
import SearchBar from "../widgets/SearchField";
import CloseIcon from "@mui/icons-material/Close";
import "./Lead.css";

const QualifiedLeads = () => {
  const [open, setOpen] = useState(null);
  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "CompanyName", label: "Company Name", bool: true },
    { sort: "LeadId", label: "Lead Id", bool: false },
    { sort: "EmailId", label: "Email Id", bool: true },
    { sort: "PhoneNo", label: "Phone No", bool: true },
    { sort: "Created_Date", label: "Created Date", bool: true },
    { sort: "Updated_Date", label: "Updated Date", bool: false },
    { sort: "LeadType", label: "Lead Source", bool: true },
    { sort: "LeadScore", label: "Lead Score", bool: true },
    { sort: "Lead_Status", label: "Lead Status", bool: true },
    { sort: "LeadGrowth", label: "Lead Growth", bool: true },
    { sort: "prediction", label: "Prediction", bool: false },
    { sort: "Lead_Industry", label: "Industry Type", bool: false },
    { sort: "City", label: "City", bool: false },
    { sort: "State", label: "State", bool: false },
    { sort: "Country", label: "Country", bool: true },
  ]);
  const matches = useMediaQuery("(min-width:759px)");

  const [File, setFile] = useState(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const navigate = useNavigate();

  const location = useLocation();

  let userId = localStorage.getItem("UserId");

  const [page, setPage] = useState(1);
  const [filter, setfilter] = useState(false);

  const [count, setCount] = useState(0);
  const [anchorElAction, setAnchorElAction] = useState(null);

  const [openUpload, setOpenUpload] = useState(false);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [deleteRow, setdeleteRow] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");

  const [showAllLead, setShowAllLead] = useState("leads");
  const [fadeEdit, setFadeEdit] = useState(false);
  const [openAnchor, setOpenAnchor] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [update, setUpdate] = useState({});

  const [openNotes, setOpenNotes] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [save, setSave] = useState(false);
  const [openDeal, setOpenDeal] = useState(false);
  const [isSortName, setIsSortName] = useState("");

  // deal states
  const [addField, setAddField] = useState({
    dealName: "",
    dealType: "",
    accId: "",
    accName: "",
    contactName: "",
    leadName: "",
    leadSource: "",
    dealAmount: "",
    turnAround: "",
    dealStage: "",
    dealOwner: "",
    dealProbability: "",
    dealNote: "",
    leadId: "",
  });
  const [errors, seterrors] = useState({});
  const [lookupDatas, setLookupDatas] = useState([]);
  const [userNameLists, setUserNameLists] = useState([]);
  const [openUploadContact, setOpenUploadContact] = useState(false);

  // tabs values state
  const [tabValue, setTabValue] = useState(0);
  const [openAddLead, setOpenAddLead] = useState(false);

  const [openEditLead, setOpenEditLead] = useState(false);

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);

  const option = [
    "Connected",
    "Customer Missed",
    "Voice mail",
    "Busy",
    "Disconnect",
    "Missed call",
    "Call waiting",
    "Confidence call",
    "Call attended",
    "Do Not Disturb Turned On",
    "Call Not Picked",
    "Called Back",
  ];

  const contaxtoptions = [
    "Solving Problem",
    "Making Decision",
    "Solution Achieved",
  ];
  const callconvoptions = [
    "Casual",
    "Tension",
    "Not Interested",
    "Friendly",
    "Confidancial",
  ];

  const BusyHroptions = ["Morning", "Evening", "After Noon"];

  //call usestates
  const [anchorEl, setAnchorEl] = useState(null);
  const [Loading, setLoading] = useState(false);

  const [showDial, setShowDial] = useState(false);
  const [calldata, setcalldata] = useState();
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [notes, setNotes] = useState("");
  const [statusc, setStatusc] = useState("");
  const [nextdate, setNextDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [callcontext, setCallContext] = useState("");
  const [busy, setBusy] = useState("");
  const [callconvstatus, setCallconvstatus] = useState("");
  const [loading, setloading] = useState(false);

  const hasMounted = useRef(false);
  // const files = acceptedFiles.map((file) => (
  //   <ListItem selected key={file.path} sx={{ width: "auto", mr: 1 }}>
  //     {file.path} - {file.size} bytes
  //   </ListItem>
  // ));

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // getQualifiedDataListsRefetch(newPage);
  };

  const handleUpload = () => {
    setOpenUpload(!openUpload);
  };
  const renderFilePreview = (file) => {
    if (file) {
      return <p>{file?.name}</p>;
    }
    return null;
  };
  const handleAddContact = () => {
    setOpenAddContact(true);
  };
  const handleAddLead = () => {
    setShowAllLead("create");
    setOpenAddLead(true);
  };

  const handleClickEditContact = (update) => {
    console.log("In Edit", update);
    setOpenEditLead(true);
    setWebUpdate(update);
  };

  //  For Filter

  const [showFilter, setShowFilter] = useState(false);

  const [datas, setDatas] = useState([]);

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const handleClickOverviewContact = (data) => {
    navigate(`/app/Lead/overview/${data?.id}`);
    // navigate(`/app/Lead/overview/${data?.id}`);

    // setOpenOverviewContact(true);
    // setShowAllLead(false);
  };

  // For Delete Functionality

  const [number, setNumber] = useState(1);

  const Deletedata = async () => {
    try {
      await axios
        .delete(`${BASE_URL}/user/UserQualifiedLeadsCRUD/?id=${update?.id}`, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then(() => {
          toast.success("Deleted successfully", {
            position: "top-right",
            autoClose: 100,
          });
          setDatas([]);
          setAnchorElAction(false);
          if (tabValue === 0) {
            console.log("page", { page });

            getAllData(page);
          } else {
            console.log("page", { page });

            getQualifiedData(page);
          }
          InvalidateQueryUpdates("getQualifiedDataLists");
        });
    } catch (error) {
      // Handle the error here
      console.error("Error deleting data:", error);
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // Get Qualified Lead List

  const getQualifiedData = async (page) => {
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");
    setloading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserQualifiedLeadsAdditional/?Is_Deleted=False&Created_By=${userId}&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setDatas((prevDatas) => {
        const updatedDatas = new Set([
          ...prevDatas,
          ...response?.data?.results,
        ]);
        return Array.from(updatedDatas);
      });
      setCount(response?.data?.count);
    } catch (error) {
      console.error("An error occurred:", error);
      throw error; // Re-throw the error to handle it in the component
    } finally {
      setloading(false);
    }
  };

  const getAllData = async (page = 1) => {
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    setloading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserQualifiedLeadsAdditional/?page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("results in contacts", response?.data?.results);

      setDatas((prevDatas) => {
        const updatedDatas = new Set([
          ...prevDatas,
          ...response?.data?.results,
        ]);
        return Array.from(updatedDatas);
      });

      setCount(response?.data?.count);
    } catch (error) {
      console.log("error", error);
      return [];
    } finally {
      setloading(false);
    }
  };

  //  For Edit Functionality

  // Web Update

  const [webupdate, setWebUpdate] = useState("");

  const handleWebUpdate = (data) => {
    // console.log("data", data);
    setOpenEditLead(true);
    setNumber(data.id);
    setWebUpdate(data);
  };

  // Search Functionality

  const SearchData = (e) => {
    console.log(
      "handleSearchange:",
      e.target.value,
      Boolean(e.target.value !== "")
    );
    Boolean(e.target.value !== "")
      ? handleSearch(e.target.value)
      : tabValue == 0
      ? getAllData(page)
      : getQualifiedData(page);
  };

  const handleSearch = async (search) => {
    await axios
      .get(`${BASE_URL}/user/UserQualifiedLeadsSearch/?search=${search}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        setDatas(res.data);
        setCount(res?.data?.length || 0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Download Template

  const downloadTemplate = () => {
    axios
      .get(`${BASE_URL}/user/download_excel_template_uploadLead/`, {
        responseType: "blob", // Important: responseType as 'blob' to handle binary data
      })
      .then((response) => {
        // Create a temporary URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        //  // // // console.log("url",url)
        // Create a link element
        const link = document.createElement("a");
        //  // // // console.log("link",link)
        link.href = url;
        //  // // // console.log("")
        link.setAttribute("download", "Lead_template.xlsx"); // Set the desired file name
        document.body.appendChild(link);

        // Click the link to trigger the download
        link.click();

        // Clean up resources after download
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        // Toast Message
        toast.success("Downloaded successfully", {
          position: "top-right",
          autoClose: 100,
        });
      })
      .catch(() => {
        // Handle any errors that occurred during the download process
        // console.error("Error downloading the template:", error);
      });
  };

  const upLoad = (e) => {
    if (File) {
      const file = File;
      //  // // // console.log("e.target.files[0]",e.target.files[0])
      if (file) {
        const formData = new FormData();
        formData.append("Lead_template", file);
        //  // // // console.log("formdata",formData)
        axios
          .post(`${BASE_URL}/user/Lead_upload/`, formData, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            setOpenUploadContact(false);
            setDatas([]);
            if (tabValue === 0 && filter == false) {
              console.log("page", { page });

              getAllData(page);
            } else if (tabValue === 1 && filter == false) {
              console.log("page", { page });
              getQualifiedData(page);
            }
            if (res.data.success_count > 0) {
              toast.success("File uploaded successfully", {
                position: "top-right",
                closeButton: true, // Allow manual close
                closeOnClick: true, // Close on click
              });
            } else {
              toast.error(`${res.data.failure_data[0].Error}`, {
                position: "top-right",
                closeButton: true,
                closeOnClick: true,
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      }
    }
  };

  // accounts functions
  // get accounts
  const [accounts, setAccounts] = useState([]);

  const headers = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const getAccounts = async () => {
    try {
      await axios
        .get(`${BASE_URL}/user/AccountAdditional/?Is_Deleted=False`, headers)
        .then((res) => {
          console.log("get Accounts List : ", res.data?.results);
          if (res.data?.results) {
            setAccounts(res.data?.results);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  // For Filter Functionality

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry1, setSelectedCountry1] = useState("");
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedCity1, setSelectedCity1] = useState("");
  const [indus, setIndus] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setToDate] = useState("");

  const filterSubmit = async () => {
    if (loading) return;
    if (count != 0 && !Boolean(Math.ceil(count / 10) >= page))
      return console.log("Out of Page");

    setloading(true);

    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserQualifiedLeadsFilter/?Industry_Type=${indus}&Country=${selectedCountry1}&State=${selectedState1}&City=${selectedCity1}&fromdate=${fromdate}&todate=${todate}&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response?.data?.results) {
        setfilter(true);

        setDatas((prevDatas) => {
          return page === 1
            ? response.data.results
            : Array.from(new Set([...prevDatas, ...response.data.results]));
        });

        setCount(response.data.count);
      }
      setShowFilter(false);
    } catch (error) {
      // Handle any network or other errors that may occur during the request
      console.error("An error occurred:", error);
    } finally {
      setloading(false);
    }
  };

  // For Lead Scoring For  Lead Every Day

  const makeAPICall = async (id) => {
    try {
      const queryParams = {
        id: id,
        UserId: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"), // Replace with the appropriate user ID.
      };

      // Make the API call for the current ID.
      const apiResponse = await axios.get(
        `${BASE_URL}/user/UpdatedLeadScoring/`,
        {
          params: queryParams,
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Handle the API response for the current ID.
      console.log(`API response for ID ${id}:`, apiResponse.data);
    } catch (error) {
      console.error(`An error occurred for ID ${id}:`, error);
    }
  };

  const dialogFilter = (event) => {
    setShowFilter(event.currentTarget);
    setPage(1);
  };

  const closeFilter = () => {
    setShowFilter(null);
  };

  // const {
  //   data: getQualifiedDataLists,
  //   isLoading: getQualifiedDataListsLoading,
  //   isError: getQualifiedDataListsError,
  //   refetch: getQualifiedDataListsRefetch,
  // } = useQuery(
  //   ["getQualifiedDataLists", tabValue, page],
  //   async () => {
  //     if (tabValue === 0) {
  //       return getAllData(page);
  //     } else if (tabValue === 1) {
  //       return getQualifiedData(page);
  //     }
  //     //  getQualifiedData(page)},
  //   },
  //   { staleTime: Infinity }
  // );

  const RefreshData = () => {
    setfilter(false);
    setSelectedCountry1("");
    setSelectedState1("");
    setIndus("");
    setFromdate("");
    setToDate("");
    setSelectedCity1("");
    if (tabValue === 0 && filter == false) {
      console.log("page", { page });

      getAllData(page);
    } else if (tabValue === 1 && filter == false) {
      console.log("page", { page });
      getQualifiedData(page);
    }
    setShowFilter(false);
  };

  useEffect(() => {
    getKnowlarityConfiguration();
    getAccounts();
  }, []);

  // useEffect(() => {
  //   if (getQualifiedDataLists) {
  //     // setDatas(getQualifiedDataLists?.data || []);
  //     // setCount(getQualifiedDataLists?.count);

  //     // setDatas((prevDatas) => new Set([...prevDatas, ...(getContactsLists.results || [])]));
  //     setDatas((prevDatas) => {
  //       const updatedDatas = new Set([
  //         ...prevDatas,
  //         ...getQualifiedDataLists?.data,
  //       ]);
  //       return Array.from(updatedDatas);
  //     });
  //     let Counts =
  //       getQualifiedDataLists?.data?.length == 0
  //         ? datas?.length
  //         : getQualifiedDataLists?.count || 0;
  //     setCount(Counts);

  //     // updating scoring

  //     const now = new Date();
  //     const startOfNextDay = new Date(now);
  //     startOfNextDay.setDate(now.getDate() + 1);
  //     startOfNextDay.setHours(0, 0, 0, 0);

  //     console.log("getQualifiedDataLists", getQualifiedDataLists);
  //     // Calculate the next day's date.
  //     const tomorrowFormatted = `${startOfNextDay.getFullYear()}-${String(
  //       startOfNextDay.getMonth() + 1
  //     ).padStart(2, "0")}-${String(startOfNextDay.getDate()).padStart(2, "0")}`;

  //     const idList = datas?.map((item) => item?.id);

  //     for (const id of idList) {
  //       console.log("id", id);
  //       makeAPICall(id, tomorrowFormatted);
  //     }
  //   }
  // }, [getQualifiedDataLists]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      setPage(1);
    }
  }, []);

  useEffect(() => {
    setDatas([]);
    setPage(1);
  }, [tabValue]);

  useEffect(() => {
    if (tabValue === 0 && filter == false) {
      console.log("page", { page });

      getAllData(page);
    } else if (tabValue === 1 && filter == false) {
      console.log("page", { page });
      getQualifiedData(page);
    } else if (filter == true) {
      console.log("page", { page });

      filterSubmit();
    }
  }, [page, tabValue, filter]);

  const [knowlaritydatas, setKnowlaritydatas] = useState([]);
  const [agentdetails, setagentdetails] = useState([]);

  const getKnowlarityConfiguration = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityConfigurationCRUD/?User_Id=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res?.data", res.data);
        setKnowlaritydatas(res?.data);
      })
      .catch((error) => {
        console.log("error");
      });
  };
  const getagentnumber = async (data) => {
    await axios
      .get(`https://kpi.knowlarity.com/Basic/v1/account/agent`, {
        headers: {
          // "Accept": "application/json",
          // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
          // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

          Accept: "application/json",
          "x-api-key": knowlaritydatas?.Header_Key,
          Authorization: knowlaritydatas?.API_Key,
        },
      })
      .then((res) => {
        console.log("agentres", res);
        const agentnum = res.data?.objects?.find(
          (item) => item?.phone === userDetails?.PhoneNo
        );
        // setagentdetails(agentnum)

        onGoingCallApi(data, agentnum);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  //  OnGoing Call API

  const onGoingCallApi = async (data, agentnum) => {
    console.log("dattaaa", data);
    let knopayload = {
      // k_number: "+918037125874",
      // agent_number: "+919344312970",
      // customer_number: "+918870791912",
      // caller_id: "+918040210001",
      // caller_name: datas?.Name,
      k_number: knowlaritydatas?.Knowlarity_Number,
      agent_number: agentnum?.country_code + agentnum?.phone,
      customer_number: "+91" + data?.PhoneNo,
      caller_id: knowlaritydatas?.Knowlarity_CallerId,
      caller_name: data?.Name,
    };
    console.log("knopayload", knopayload);
    await axios
      .post(
        `https://kpi.knowlarity.com/Basic/v1/account/call/makecall`,
        knopayload,
        {
          headers: {
            // "Accept": "application/json",
            // "x-api-key": "N5larz0zVcavTxs2Lcess4Gy3WtC7lsN1nrsJ4Bi",
            // "Authorization": "09049056-87dd-4867-bf9e-2cd0f3382be2",

            Accept: "application/json",
            "x-api-key": knowlaritydatas?.Header_Key,
            Authorization: knowlaritydatas?.API_Key,
          },
        }
      )
      .then((res) => {
        console.log("callres", res);
        setShowDial(true);
        setcalldata(data);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  console.log("agentdetails", agentdetails?.phone, agentdetails?.country_code);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const calllogs = () => {
    axios
      .get(
        `${BASE_URL}/user/KnowlarityCallLogsCRUD/?start_Date=2020-04-16&end_Date=&UserId=${localStorage.getItem(
          "UserId"
        )}&Module=Lead`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("calllogsresss", res.data);
        // setKnowlaritydatas(res?.data);
        // setcallogsdetails(res.data)
        const mynumberList = res?.data?.filter((item) =>
          item.Agent_Number.includes(userDetails?.PhoneNo)
        );

        const lastIndexData = mynumberList[0];
        console.log("lastIndexData", mynumberList, lastIndexData);

        setDuration(lastIndexData?.Call_Duration);

        if (lastIndexData?.Agent_Number?.includes("User Disconnected")) {
          setStatusc("User Disconnected");
        } else if (lastIndexData?.Agent_Number?.includes("Customer Missed")) {
          setStatusc("Customer Missed");
        } else {
          setStatusc("Connected");
        }

        setLoading(false);
        setAnchorEl(true);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    var payload = {
      Company_Name: calldata?.CompanyName,
      Receiver_Name: calldata?.LeadFirstName,
      Contact_Number: calldata?.PhoneNo,
      Selected_Date: moment().format("YYYY-MM-DD"),
      Next_SheduledTime: nextdate,
      Call_Duration: duration,
      Call_Context: callcontext,
      Call_Con_Status: callconvstatus,
      Call_Busy_hour: busy,
      Activity_Type: "Call",
      Activity_relates: "Lead",
      Details: notes,
      Select_Status: statusc,
      Lead_Id: Number(calldata?.id),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };

    axios
      .post(`${BASE_URL}/user/CallcenterActivityCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("check", res);
        toast.success("Created successfully", {
          position: "top-right",
          autoClose: 100,
        });
        setShowDial(false);
        handleClose();
        // for Updating the Contact List
        if (calldata?.No_of_Calls == null || "" || NaN) {
          var x = 1;
        } else {
          var x = parseInt(calldata?.No_of_Calls) + 1;
        }
        let payload1 = {
          id: Number(calldata?.id),
          Last_Call_Date: moment().format("YYYY-MM-DD"),
          Last_Called_By: userDetails && userDetails?.username,
          No_of_Calls: x,
        };
        console.log("check", payload1);
        axios
          .patch(`${BASE_URL}/user/ContactCRUD/`, payload1, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((re) => {
            console.log("res", re);
          })
          .catch((error) => {
            console.log("error", error);
          });
        // For Updateing the History of Changes
        let editUser = localStorage.getItem("Username");
        let histotypayload = {
          Document_Id: res.data.CallCenter_Id,
          Updated_Person: `${editUser} Created this Contact Phone History.`,
          Module_Name: "Contact Phone History",
          Contact_Id: Number(calldata?.id),
          Updated_By: parseInt(localStorage.getItem("UserId")),
        };
        console.log("histotypayload", histotypayload);
        axios
          .post(`${BASE_URL}/user/Updaterecordscrud/`, histotypayload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("Result", res.data);
          })
          .catch((res) => {
            console.log(res.message);
          });
        // Create Task
        // CreateTask(editUser);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // sorting functions
  const sortData = (e, value) => {
    const sortedData = [...datas]?.sort((a, b) =>
      value === "A-Z"
        ? a?.LeadFirstName?.localeCompare(b?.LeadFirstName)
        : b?.LeadFirstName?.localeCompare(a?.LeadFirstName)
    );
    setDatas(sortedData);
    console.log("sortedData", value, "A-Z", sortedData);
    setIsSortName(value);
  };

  useEffect(() => {
    if (Loading) {
      const timer = setTimeout(() => {
        calllogs();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [Loading]);

  // Scroll end auto api call

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100 &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading]);

  // deal add function
  const submitAddDeal = async () => {
    let errors = errorFilter(addField);
    seterrors(errors);

    if (Object.keys(errors).length === 0) {
      let payload = {
        Deal_Name: addField?.dealName,
        Deal_Type: addField?.dealType,
        Deal_Owner: addField?.dealOwner,
        Account_Id: addField?.accId,
        Account_Name: addField?.accName,
        Lead_Id: addField?.leadId,
        Lead_Name: addField?.leadName,
        // "Lead_Source": addField?.leadSource,
        Contact_Person: addField?.contactName,
        Amount: parseInt(addField?.dealAmount),
        MinimumTurnOver: parseInt(addField?.turnAround),
        Deal_Stage: addField?.dealStage,
        Deal_Probablity: addField?.dealProbability,
        Deal_Note: addField?.dealNote,
        Active_Flag: false,
        Is_Deleted: false,
        Created_Date: new Date().toISOString().split("T")[0],
        Updated_Date: new Date().toISOString().split("T")[0],
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };

      console.log("Payloads---", payload);
      try {
        await axios
          .post(`${BASE_URL}/user/DealCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            //  console.log(res.data);
            toast.success("Created successfully", {
              position: "top-right",
              autoClose: 100,
            });
            setOpenDeal(false);
            InvalidateQueryUpdates("DealsLists");

            let editUser = localStorage.getItem("Username");
            let histotypayload = {
              Document_Id: res?.data?.Deal_Id,
              Updated_Person: `${editUser} Created this Deal.`,
              Updated_Time: `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
              Module_Name: "Deal",
              Deal_Id: res?.data?.id,
              Updated_By: parseInt(localStorage.getItem("UserId")),
            };
            console.log("histotypayload", histotypayload);
            axios
              .post(`${BASE_URL}/user/DealHistorycrud/`, histotypayload, {
                headers: {
                  db: item,
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              })
              .then((res) => {
                console.log("Result", res.data);
              })
              .catch((res) => {
                console.log(res.message);
              });
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      }
    }
  };

  // get lookup datas
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/useradmin/UsersList/`, {
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log("Users List", response.data);
      // setUserNameLists(response.data);
      return response?.data;
    } catch (error) {
      console.log("Error in getting user list", error);
    }
  };

  // react-query functions
  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });

  const {
    data: getTerUserLists,
    isLoading: getTerUserListsLoading,
    isError: getTerUserListsError,
    refetch: getTerUserListsRefetch,
  } = useQuery(
    ["getTerUserLists"],
    fetchUserData, // Assuming 1 as the initial page number
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (getTerUserLists) {
      setUserNameLists(getTerUserLists);
    }
  }, [getTerUserLists]);

  useEffect(() => {
    if (getUserLoopUp) {
      let dealType = getUserLoopUp?.find(
        (item) => item?.Lookupname === "Deal Type"
      );
      let leadSource = getUserLoopUp?.find(
        (item) => item?.Lookupname === "Lead Source"
      );
      console.log("getLookupDatas ", getUserLoopUp);
      setLookupDatas(dealType?.Values);
    }
  }, [getUserLoopUp]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  console.log("File", File);
  return (
    <>
      <JumboContentLayoutMain>
        <ToastContainer />
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display:
                showAllLead === "leads" &&
                location?.pathname === "/leads/qualifiedLeads"
                  ? ""
                  : "none",
            }}
          >
            <Box sx={{ mb: 2 }} id="heads">
              <Box className="flex items-center justify-between flex-wrap">
                <Typography
                  sx={[
                    componentTitle,
                    { color: Colors?.gray, fontWeight: 700 },
                  ]}
                >
                  Qualified Lead List
                </Typography>

                <Div className="flex items-center justify-between gap-2">
                  <SelectDropdown
                    className="search-select3"
                    defaultValue={"All Leads"}
                    onChange={(e, value) => {
                      setTabValue(value === "All Leads" ? 0 : 1);
                      console.log("value", value);
                    }}
                    options={["All Leads", "My Leads"]}
                  />

                  <SelectDropdown
                    className="search-select3"
                    defaultValue={"A-Z"}
                    onChange={sortData}
                    options={["A-Z", "Z-A"]}
                    width="70px"
                    clearButton={false}
                  />
                  {matches && (
                    <IconButton
                      onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    >
                      <FaGear style={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                </Div>
              </Box>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={10.5}
                  md={6}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <IconButton
                    sx={{ color: Colors?.Mblue }}
                    onClick={dialogFilter}
                  >
                    <BiFilterAlt size={22} />
                  </IconButton>
                  <Typography
                    sx={[dialogTitle, { color: Colors?.gray, fontWeight: 400 }]}
                  >
                    ( {count} ) Leads
                  </Typography>
                </Grid>
                {!matches && (
                  <Grid item xs={1} md={1}>
                    <IconButton
                      onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    >
                      <FaGear style={{ fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                )}

                {/* Filter popover  */}

                <Popover
                  open={Boolean(showFilter)}
                  anchorEl={showFilter}
                  onClose={closeFilter}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
                >
                  <Box>
                    <Div
                      sx={[
                        DisplayFlex,
                        CRMTableHead,
                        dialogTitle,
                        { px: 2, justifyContent: "space-between" },
                      ]}
                    >
                      <Typography sx={[dialogTitle]}>Filters</Typography>
                      <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                        <GrPowerReset size={16} />
                      </IconButton>
                    </Div>
                    <Div style={{ padding: "1rem" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Country
                          </Typography>
                          <Autocomplete
                            className="col-12 search-select3"
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.name) {
                                return option?.name;
                              }
                              return "";
                            }}
                            options={Country.getAllCountries() || []}
                            value={selectedCountry1}
                            onChange={(e, newValue) => {
                              setSelectedCountry(newValue);
                              setSelectedCountry1(newValue?.name);
                            }}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Country" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            State
                          </Typography>
                          <Autocomplete
                            className=" col-12 search-select3"
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.name) {
                                return option?.name;
                              }
                              return "";
                            }}
                            value={selectedState1}
                            options={
                              State.getStatesOfCountry(
                                selectedCountry?.isoCode
                              ) || []
                            }
                            onChange={(e, newValue) => {
                              setSelectedState(newValue);
                              setSelectedState1(newValue?.name);
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value || value === ""
                            }
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="State" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            City
                          </Typography>
                          <Autocomplete
                            className="col-12 search-select3"
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option && option?.name) {
                                return option?.name;
                              }
                              return "";
                            }}
                            options={
                              City.getCitiesOfState(
                                selectedState?.countryCode,
                                selectedState?.isoCode
                              ) || []
                            }
                            value={selectedCity1}
                            onChange={(e, newValue) => {
                              console.log("newValue", newValue);
                              setSelectedCity(newValue);
                              setSelectedCity1(newValue?.name);
                            }}
                            id="outlined-basic"
                            renderInput={(params) => (
                              <TextField {...params} placeholder="City" />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Industry Type
                          </Typography>
                          <TextField
                            type="text"
                            className="col-12 input-box2"
                            autoComplete="off"
                            placeholder="Industry Type"
                            value={indus}
                            onChange={(e) => {
                              setIndus(e.target.value);
                            }}
                            sx={{ mt: -0.5 }}
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            From Date
                          </Typography>
                          <TextField
                            type="date"
                            className="col-12 input-box2"
                            value={fromdate}
                            onChange={(e) => {
                              setFromdate(e.target.value);
                            }}
                            id="createdDate"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            To Date
                          </Typography>
                          <TextField
                            value={todate}
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            type="date"
                            className="col-12 input-box2"
                            id="lastDate"
                          />
                        </Grid>
                      </Grid>

                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                          gap: "10px",
                        }}
                      >
                        {/* <Button
                    type="submit"
                    sx={[DialogBoxSaveButton, ButtonStyle, FontStyle]}
                  >
                    Apply
                  </Button> */}
                        <Cancelbutton onClick={closeFilter} />
                        <CustomButton
                          onClick={filterSubmit}
                          type="submit"
                          label="Apply"
                          Icon="filterIcon"
                        />
                      </Div>
                    </Div>
                  </Box>
                </Popover>

                {/* <Grid item xs={12} md={4}>
                <Div sx={{ width: { md: window.innerWidth / 3 } }}>
                  <SearchBar
                    SearchData={SearchData}
                    width={"100%"}
                    color={"black"}
                  />
                </Div>
              </Grid> */}
                <Grid item xs={12} md={6}>
                  <Div
                    className="gap-3"
                    sx={[DisplayFlex, { justifyContent: "right" }]}
                  >
                    <SearchBar
                      // SearchData={SearchData}
                      SearchData={(e) => SearchData(e)}
                      width={"250px !important"}
                      color={"black"}
                      className=""
                    />

                    <CustomButton
                      width={"120px"}
                      onClick={() => setOpenUploadContact(true)}
                      bgColor="#708090"
                      label="Import"
                      Icon="downloadIcon"
                    />
                    {/* <div>
                      <input
                        type="file"
                        id="uploadFile22"
                        style={{ display: "none" }}
                        onChange={upLoad}
                      />
                      <label htmlFor="uploadFile22">
                        <Typography
                          sx={[
                            FontStyle,
                            {
                              width: "100px",
                              height: "33px",
                              px: "10px",
                              color: "white",
                              borderRadius: "5px",
                              backgroundColor: "#708090",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: alpha("#708090", 0.8),
                                boxShadow: "none",
                              },
                            },
                          ]}
                          className="flex items-center gap-2"
                        >
                          {CustomIcons?.uploadIcon}
                          Upload
                        </Typography>
                      </label>
                    </div> */}
                    <CustomButton
                      onClick={handleAddLead}
                      label="Create"
                      Icon="createIcon"
                      endIcon={true}
                    />
                  </Div>
                </Grid>
              </Grid>
            </Box>
            <List sx={{ mt: 0 }}>
              {datas?.length > 0 &&
                datas?.map((data, idx) => {
                  // if (idx < 2) {

                  let image =
                    (BASE_URL +
                      data?.File?.split("https://backend.crmfarm.app")[1] ||
                      data?.File) ??
                    "";

                  return (
                    <Div
                      onMouseOver={() => setFadeEdit(idx)}
                      onMouseLeave={() => setFadeEdit(null)}
                      className=""
                      key={idx}
                      sx={{
                        minHeight: "124px",
                        p: { xs: 0.5, sm: 2 },
                        px: { xs: 0.5, md: 7 },
                        my: 2,
                        bgcolor: "white",
                        borderRadius: { xs: "5px", md: "10px" },
                        ":hover": {
                          boxsShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
                        },
                        cursor: "default",
                      }}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        spacing={2}
                        sx={{ pr: "5%" }}
                      >
                        <Grid item xs={12} md={1.5}>
                          <div
                            style={{ marginTop: "6px" }}
                            onClick={() => handleClickOverviewContact(data)}
                          >
                            <AvatarCircle
                              size="90px"
                              fontSize="30px"
                              bgColor={avatarColorFun(idx)}
                              title={
                                !Boolean(data?.File) && data?.LeadFirstName
                              }
                              image={Boolean(data?.File) ? image : false}
                            />
                          </div>
                        </Grid>

                        <Grid container spacing={1} item xs={12} md={10.5}>
                          <Grid item xs={12}>
                            <Box
                              sx={[
                                DisplayFlex,
                                {
                                  justifyContent: "space-between",
                                  flexWrap: "wrap",
                                },
                              ]}
                            >
                              <Typography
                                sx={[
                                  {
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    color: `${Colors?.Mblue}`,
                                  },
                                ]}
                              >
                                {data?.LeadFirstName +
                                  " " +
                                  data?.LeadLastName ?? ""}
                              </Typography>
                              <Div sx={[DisplayFlex]}>
                                <Div
                                  className={` ${
                                    idx === fadeEdit ? "fade-in-right" : ""
                                  } `}
                                  sx={{
                                    display: {
                                      xs: "none !important",
                                      sm:
                                        fadeEdit === idx
                                          ? "flex !important"
                                          : "none !important",
                                      md:
                                        data?.Created_By === userId
                                          ? "flex !important"
                                          : "none",
                                    },
                                  }}
                                >
                                  <Div
                                    sx={[
                                      FontStyle,
                                      DisplayFlex,
                                      {
                                        width: "120px",
                                        cursor: "pointer",
                                        transform: "translateX(-50px)",
                                        color: Colors?.Mblue,
                                      },
                                    ]}
                                    onClick={() => {
                                      setUpdate(data);
                                      setShowAllLead("edit");
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: "25px !important",
                                        color: Colors?.Mblue,
                                      }}
                                    >
                                      <TiEdit
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 900,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          sx={[
                                            FontStyle,
                                            { color: Colors?.Mblue },
                                          ]}
                                        >
                                          Edit Lead
                                        </Typography>
                                      }
                                    />
                                  </Div>
                                </Div>
                                <CustomButton
                                  onClick={(e) => {
                                    setOpenAnchor(e.target);
                                    setUpdate(data);
                                  }}
                                  sx={{ borderRadius: "25px" }}
                                  label="Action"
                                  bgColor={Colors?.Mblue}
                                  Icon="downArrowIcon"
                                  endIcon={true}
                                />
                              </Div>
                            </Box>
                          </Grid>
                          {columnMenuItems?.map(
                            (Menu, subIdx) =>
                              Menu?.bool && (
                                <Grid key={subIdx} item xs={12} md={4}>
                                  <Typography
                                    sx={[
                                      { fontSize: "14px", color: "#828282" },
                                    ]}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 700,
                                        color: "#5e5e5e",
                                      }}
                                    >
                                      {Menu?.label} :
                                    </span>{" "}
                                    {Menu?.sort === "Created_Date" ||
                                    Menu?.sort === "Updated_Date"
                                      ? formatDate(data[Menu?.sort])
                                      : data[Menu?.sort] || "-"}
                                  </Typography>
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Grid>
                    </Div>
                  );
                  // }
                })}
            </List>
            {/* gear options */}
            <Menu
              id="menu-appbar"
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
              sx={{
                mt: 5,
                maxHeight: "300px",
                scrollBehavior: "smooth",
              }}
            >
              <Typography sx={[dialogTitle, { p: 2, mb: -2 }]}>
                Choose Fields
              </Typography>
              <FormGroup sx={{ p: 2 }}>
                {columnMenuItems?.length > 0 &&
                  columnMenuItems?.map((column, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            color="info"
                            size="small"
                            checked={column?.bool}
                            onChange={(e) => {
                              let value = [...columnMenuItems];
                              value[index].bool = !column?.bool;
                              setColumnMenuItems(value);
                            }}
                          />
                        }
                        label={
                          <Typography sx={[FontStyle]}>
                            {column?.label}
                          </Typography>
                        }
                      />
                    );
                  })}
              </FormGroup>
            </Menu>

            {/* openAnchor Action popover */}
            <Popover
              id={Boolean(openAnchor) ? "simple-popover" : undefined}
              open={Boolean(openAnchor)}
              anchorEl={openAnchor}
              onClose={() => {
                setOpenAnchor(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List sx={{ p: 1 }}>
                <ListItemButton
                  sx={[
                    FontStyle,
                    {
                      transform: {
                        xs: "translateX(0px)",
                        sm: "translateX(-50px)",
                      },
                      color: Colors?.Mblue,
                      display: {
                        sm: "none !important",
                        xs: "flex !important",
                      },
                    },
                  ]}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "25px !important",
                      color: Colors?.Mblue,
                    }}
                  >
                    <TiEdit
                      style={{ fontSize: { xs: 18, md: 25 }, fontWeight: 900 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Edit Contact</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenNotes(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <MdOutlineNoteAdd style={{ fontSize: 20 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Notes</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenSendMail(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <RiMailSendLine style={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Send Mail</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenActivity(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TbActivity style={{ fontSize: 18 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Activity</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenAppointment(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TodayIcon sx={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography sx={[FontStyle]}>Add Appointment</Typography>
                    }
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setOpenDeal(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon sx={{ minWidth: "25px !important", color: "" }}>
                    <TodayIcon sx={{ fontSize: 17 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography sx={[FontStyle]}>Add Deal</Typography>}
                  />
                </ListItemButton>

                <ListItemButton
                  onClick={() => {
                    setShowDelete(true);
                    setOpenAnchor(false);
                  }}
                  sx={{ borderRadius: "5px" }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "25px !important", color: "tomato" }}
                  >
                    {CustomIcons?.deleteIcon}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography sx={[FontStyle]}>Delete</Typography>}
                  />
                </ListItemButton>
              </List>
            </Popover>
          </Box>
        </Box>

        {/* Create Contact */}
        {/* <Dragabledialog
        maxWidth="md"
        open={showAllLead === "create"}
        // onClose={() => setOpenNotes(false)}
      > */}
        {showAllLead === "create" && (
          <CreateQualifiedLeads
            setOpenAddLead={setShowAllLead}
            openAddLead={openAddLead}
            tabValue={tabValue}
            getAllData={getAllData}
            getQualifiedData={getQualifiedData}
            // getQualifiedDataListsRefetch={getQualifiedDataListsRefetch}
            setSave={setSave}
          />
        )}
        {/* </Dragabledialog> */}

        {/* Edit Contact */}
        {showAllLead === "edit" && (
          <QualifiedLeadsEdit
            setOpenEditLead={setShowAllLead}
            openEditLead={openEditLead}
            webupdate={update}
            tabValue={tabValue}
            getAllData={getAllData}
            getQualifiedData={getQualifiedData}
            // getQualifiedDataListsRefetch={getQualifiedDataListsRefetch}
            anchorElAction={anchorElAction}
            setAnchorElAction={setAnchorElAction}
            setSave={setSave}
          />
        )}

        {/* Add Note Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openNotes}
          // onClose={() => setOpenNotes(false)}
        >
          <NotesModel
            name={{
              label: "Lead Name",
              value: update?.LeadFirstName + update?.LeadLastName || "",
            }}
            endpoint={"UserLeadNotesCRUD"}
            historyEndpoint={{
              point: "UserQualifiedLeadsHistorycrud",
              payload: { Lead_Id: update?.id },
            }}
            extraPayload={{ Lead_Id: update?.id }}
            data={update}
            setOpenNotes={setOpenNotes}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Activities Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openActivity}
          // onClose={() => setOpenActivity(false)}
        >
          <ActivityModel
            name={{
              label: "Lead Name",
              value: update?.LeadFirstName + update?.LeadLastName || "",
            }}
            endpoint={"UserQualifiedLeadsActivitiesCRUD"}
            historyEndpoint={{
              point: "UserQualifiedLeadsHistorycrud",
              payload: { Lead_Id: update?.id },
            }}
            extraPayload={{ Lead_Id: update?.id }}
            data={update}
            setOpenActivity={setOpenActivity}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Appointment Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openAppointment}
          // onClose={() => setOpenAppointment(false)}
        >
          <AppointmentModel
            name={{
              label: "Lead Name",
              value: update?.LeadFirstName + update?.LeadLastName || "",
            }}
            historyEndpoint={{
              point: "UserQualifiedLeadsHistorycrud",
              payload: { Lead_Id: update?.id },
            }}
            extraPayload={{ Lead_Id: update?.id }}
            endpoint={"UserLeadMeetingCRUD"}
            data={update}
            setOpenAppointment={setOpenAppointment}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Send Mail Dialog Box */}
        <Dragabledialog
          maxWidth="lg"
          open={openSendMail}
          // onClose={() => setOpenSendMail(false)}
        >
          <ComposeEmail
            extraPayload={{ Lead_Id: update?.id }}
            endpoint={"UserMailMasterUpdateCRUD"}
            data={update}
            setOpenSendMail={setOpenSendMail}
            setOpen={setSave}
          />
        </Dragabledialog>

        {/* Add Deal */}
        <Dragabledialog
          maxWidth="lg"
          open={openDeal}
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogTitle
            sx={[DisplayFlex, dialogFontSize]}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            Create Deal
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <Customgrid columnCount={4}>
              <Grid item>
                <Typography sx={[input_label]}> Deal Name </Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Enter Deal Name"
                  value={addField.dealName}
                  onChange={(e) =>
                    setAddField({ ...addField, dealName: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealName && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealName} deal name{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Customer Name </Typography>
              </Grid>

              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={accounts}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.Contact_Name) {
                        return option?.Contact_Name;
                      }
                      return "";
                    }}
                    value={addField?.accName}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        accId: newValue?.id,
                        accName: newValue?.Contact_Name,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Customer Name" />
                    )}
                  />
                </FormControl>
                {errors?.accName && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.accName} account name
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography sx={[input_label]}> Lead Name </Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={datas}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.LeadFirstName) {
                        return option?.LeadFirstName;
                      }
                      return "";
                    }}
                    value={addField?.leadName}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        leadSource: newValue?.LeadSource,
                        leadId: newValue?.id,
                        leadName: newValue?.LeadFirstName,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Lead Name" />
                    )}
                  />
                </FormControl>
                {errors?.lead && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.lead} deal name{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Lead Source</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <TextField
                    className={`col-12 input-box2`}
                    id="outlined-basic"
                    placeholder="Lead Source"
                    value={addField.leadSource}
                    // onChange={(e) => setAddField({ ...addField, l: e.target.value })}
                    InputProps={{
                      style: {
                        borderRadius: "3px",
                      },
                    }}
                  />
                </FormControl>
                {errors?.leadSource && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.leadSource} lead source{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Amount</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Deal Amount"
                  type=""
                  value={addField.dealAmount}
                  onChange={(e) =>
                    setAddField({ ...addField, dealAmount: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealAmount && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealAmount} deal amount{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Stage</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={[
                      { stage: "Deals Enquiry Stage" },
                      { stage: "Deals Qualifying Stage" },
                      { stage: "Deals Proposal Stage" },
                      { stage: "Deals Demo Stage" },
                      { stage: "Deals Negotiation Stage" },
                      { stage: "Deals Lost Stage" },
                    ]}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.stage) {
                        return option?.stage;
                      }
                      return "";
                    }}
                    value={addField?.dealStage}
                    onChange={(e, newValue) => {
                      setAddField({ ...addField, dealStage: newValue?.stage });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Deal Stage" />
                    )}
                  />
                </FormControl>
                {errors?.dealStage && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealStage} deal stage{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Owner</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Typography sx={[FontStyle]}></Typography>
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={userNameLists}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.username) {
                        return option?.username;
                      }
                      return "";
                    }}
                    value={addField?.dealOwner}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        dealOwner: newValue?.username,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Deal Stage" />
                    )}
                  />
                </FormControl>
                {errors?.dealStage && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealStage} deal stage{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Type</Typography>
              </Grid>
              <Grid item>
                <FormControl className="col-12">
                  <Autocomplete
                    className="search-select3 col-12"
                    // {...register("selectedCountry1")}
                    options={lookupDatas}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.value) {
                        return option?.value;
                      }
                      return "";
                    }}
                    value={addField?.dealType}
                    onChange={(e, newValue) => {
                      setAddField({ ...addField, dealType: newValue?.value });
                    }}
                    sx={[FontStyle]}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Deal Type"
                        sx={[FontStyle]}
                      />
                    )}
                  />
                </FormControl>

                {errors?.dealType && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealType} deal type{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Contact Person</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Contact Name"
                  value={addField.contactName}
                  onChange={(e) =>
                    setAddField({ ...addField, contactName: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />

                {errors?.contactName && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.contactName} Contact Name{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Minimum turn around</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Minimum turn around"
                  value={addField.turnAround}
                  onChange={(e) =>
                    setAddField({ ...addField, turnAround: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.turnAround && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.turnAround} turn around{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Probability %</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Deal Probability %"
                  value={addField.dealProbability}
                  onChange={(e) =>
                    setAddField({
                      ...addField,
                      dealProbability: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealProbability && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealProbability} deal probability{" "}
                  </Typography>
                )}
              </Grid>

              <Grid item>
                <Typography sx={[input_label]}>Deal Note</Typography>
              </Grid>
              <Grid item>
                <TextField
                  className={`col-12 input-box2`}
                  id="outlined-basic"
                  placeholder="Deal Note"
                  value={addField.dealNote}
                  onChange={(e) =>
                    setAddField({ ...addField, dealNote: e.target.value })
                  }
                  InputProps={{
                    style: {
                      borderRadius: "3px",
                    },
                  }}
                />
                {errors?.dealNote && (
                  <Typography sx={[FontStyle, { color: "red !important" }]}>
                    {errors?.dealNote} deal note{" "}
                  </Typography>
                )}
              </Grid>
            </Customgrid>

            <Grid container align="center" mt={2} spacing={2} pb={2}>
              <Grid item align="right" xs={6}>
                <CustomButton
                  label="Save"
                  Icon="saveIcon"
                  onClick={submitAddDeal}
                />
              </Grid>
              <Grid item align="left" xs={6}>
                <Cancelbutton onClick={() => setSave("deal")} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dragabledialog>

        {/* for upload a contact */}
        <Dialog
          open={openUploadContact}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={[
              CRMTableHead,
              DisplayFlex,
              {
                bgcolor: "#dcdcdc !important",
                justifyContent: "space-between",
              },
            ]}
          >
            <Typography>Upload Contact</Typography>
            <Div sx={{ p: 0 }}>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenUploadContact(false);
                  // setCsvFile(null);
                  // setImportedRows(null);
                  // setNotImportedRows(null);
                }}
              >
                <CloseIcon
                  sx={{ color: "black", fontSize: "22px !important" }}
                />
              </IconButton>
            </Div>
          </DialogTitle>
          <DialogContent>
            <Div>
              <DialogContentText id="alert-dialog-description">
                <Typography sx={[FontStyle]}>
                  Before Import Contacts you must confirm the data in the
                  prescribed template which shall be used to import data to the
                  CRMFarm. <br />
                  <br />
                  If you don't have the template please download the template
                  here.
                </Typography>
                {/* <ButtonGroup
                  onClick={downloadTemplate}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button className="plus-button">Download</Button>
                  <Button variant="contained" className="icon-button">
                    <MdDownload size={24} />
                  </Button>
                </ButtonGroup> */}
                {/* <Div sx={{ display: "flex", justifyContent: "end" }}>
                  <CustomButton
                    onClick={downloadTemplate}
                    label="Download"
                    Icon="downloadIcon"
                  />
                </Div> */}
                <Div sx={{ display: "flex", mt: 2, justifyContent: "center" }}>
                  <Button
                    sx={[UploadBtn, FontStyle]}
                    onClick={downloadTemplate}
                  >
                    Download Template
                  </Button>
                </Div>
                <Typography variant="caption">Import</Typography>
              </DialogContentText>
              <br />
              <Div
                {...getRootProps()}
                style={{
                  border: "2px dashed #EEEEEE",
                  borderRadius: "5px",
                  padding: "30px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </Div>
              <br />
              <Div>
                <Typography variant="h3">Uploaded Files</Typography>
                {File ? (
                  <Div
                    sx={{
                      display: "flex",
                      gap: 1,
                      p: 0.5,
                      alignItems: "center",
                      justifyContent: "space-around",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "4px",
                      width: "40%",
                    }}
                  >
                    <Typography variant="h4" sx={{ pt: 0.8 }}>
                      {renderFilePreview(File)}
                    </Typography>
                    <IconButton
                      size="small"
                      color="primary"
                      // onClick={() => removeFile()}
                      sx={{ p: 0 }}
                    >
                      <MdClose />
                    </IconButton>
                  </Div>
                ) : (
                  <Div>No File</Div>
                )}
              </Div>
              <br />
              {/* {notImportedRows?.length > 0 && (
                <Div>
                  <Grid container>
                    <Grid item xs={3.5}>
                      Uploaded Contacts
                    </Grid>
                    <Grid item xs={0.5}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {importedRows?.length}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3.5}>
                      Not Uploaded Contacts
                    </Grid>
                    <Grid item xs={0.5}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {notImportedRows?.length}
                    </Grid>
                  </Grid>
                  <br />
                  <Typography variant="h3">Reason :</Typography>
                  {notImportedRows?.map((importData, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={3.5}>
                          Row Number {importData?.row_number}
                        </Grid>
                        <Grid item xs={0.5}>
                          :
                        </Grid>
                        <Grid item xs={7}>
                          {importData?.Error}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Div>
              )} */}
            </Div>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <CustomButton onClick={upLoad} label="Upload" Icon="uploadIcon" />
          </DialogActions>
        </Dialog>

        {/* delete popup */}

        <DialogBox
          open={showDelete}
          Content={deletedWarnigMsg}
          onClickNo={() => setShowDelete(false)}
          onClickYes={() => {
            // setSave(false);
            setShowDelete(false);
            // reset();
            Deletedata();
            setOpenAnchor(null);
          }}
        />

        {/* Close dialog box */}
        <DialogBox
          open={save}
          onClickNo={() => setSave(false)}
          onClickYes={() => {
            switch (save) {
              case "note":
                setOpenNotes(false);
                break;
              case "activity":
                setOpenActivity(false);
                break;
              case "appointment":
                setOpenAppointment(false);
                break;
              case "email":
                setOpenSendMail(false);
                break;

              case "deal":
                setOpenDeal(false);
                break;
              case "create":
                setShowAllLead("leads");
                break;
              case "edit":
                setShowAllLead("leads");
                break;
              default:
                break;
            }
            setSave(false);
          }}
        />
      </JumboContentLayoutMain>
    </>
  );
};

export default QualifiedLeads;
