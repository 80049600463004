/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  TextField,
  Typography,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Grid,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import "react-toastify/dist/ReactToastify.css";
import {
  DisplayFlex,
  FontStyle,
  dialogTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import axios from "axios";
import { BASE_URL, BASE_URL1 } from "app/services/auth-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
import {
  Cancelbutton,
  CustomButton,
  Customgrid,
  DialogBox,
  Dragabledialog,
  fetchTransactionSeries,
} from "app/pages/widgets/StylesComponents";
import uploadimage from '../../../../assets/images/box-1.png';
import { useQuery } from "react-query";


const SocialMediaEditCampaign = ({
  getData,
  update,
  openEditContact,
  setOpenEditContact,
}) => {
  // Create Lead Questions

  console.log("update", update);

  const fileInputRef = useRef(null);

  const token = localStorage.getItem("accesstoken");
  const [userInfo, setUserInfo] = useState("");


  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const item = "crmuseradmin1";
  // Lead Generation
  const filterItems = ["Linkedin", "Facebook", "Instagram"];

  const [base64, setbase64] = useState(null);

  const [promo, setPromos] = useState({
    promoId: update?.Promo_Id,
    promoname: update?.Promo_Name,
    promocode: update?.Promo_Code,
    promodate: update?.Promo_Date,
    promocontent: update?.Promo_Content,
    Generated_Id:update?.Generated_Id,
    daystorun: update?.Days_to_Run,
    type: update?.File,
    postingtype: update?.SocialMedia_Type,
    postingdate: update?.Posting_Date,
  });

  useEffect(() => {
    setPromos({
      promoId: update?.Promo_Id,
      promoname: update?.Promo_Name,
      promocode: update?.Promo_Code,
      promodate: update?.Promo_Date,
      promocontent: update?.Promo_Content,
      daystorun: update?.Days_to_Run,
      Generated_Id:update?.Generated_Id,
      type: update?.File,
      postingtype: update?.SocialMedia_Type,
      postingdate: update?.Posting_Date,
    });
    setNameFile(update?.FileName)
    if (update?.File) {
      let image =
        (BASE_URL +
          update?.File?.split("https://backend.crmfarm.app")[1] ||
          update?.File) ??
        "";



      console.log("image", image)

      fetch(`${BASE_URL}${image}`)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const base64 = btoa(
            new Uint8Array(buffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          console.log("base64", base64)
          setBa64(base64);
          // const urlParts = res.data.Service_Image.split("/");
          // console.log("urlParts", urlParts);
          // setImageName(urlParts[urlParts.length - 1]);
          // setSelectedImage(urlParts[urlParts.length - 1]);
        }).catch((error) => {
          console.log(error);
        });
    }

  }, [update]);

  const handleChangePromo = (e) => {
    setPromos({ ...promo, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({});

  // For the popup

  const [save, setSave] = useState(false);

  const getUserInfo = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/LinkdinCRUD/`, {
        params: {
          UserId: localStorage.getItem("UserId"),
        },
        headers: {
          db: localStorage.getItem("DBName"),
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      console.log("UserInfo", response?.data);
      // setUserInfo(response?.data);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // Handle Submit

  const handleSubmit = (e) => {
    e.preventDefault();
    var payload = {
      id:update?.id,
      Promo_Id:promo?.promoId,
      Promo_Name: promo?.promoname,
      Promo_Code: promo?.promocode,
      Promo_Date: promo?.promodate,
      Promo_Content: promo?.promocontent,
      Days_to_Run: promo?.daystorun,
      File: ba64,
      FileName: namefile,
      sub: userInfo?.sub,
      Generated_Id:promo?.Generated_Id,
      SocialMedia_Type: promo?.postingtype,
      Posting_Date: promo?.postingdate,
      UserId: localStorage.getItem("UserId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
      Organization_Id: localStorage.getItem("OrganizationId"),
    };

    console.log("payload", payload);

    axios
      .put(`${BASE_URL}/user/SocialMediaPromotionCRUD/`, payload, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("check", res);
        toast.success("Social Media Edited successfully", {
          position: "top-right",
          autoClose: 100,
        });
        getData();
        setOpenEditContact(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  // Edit And Delete Menu Functionality
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  const [ba64, setBa64] = useState(update?.File);

  console.log("ba6444444444444", ba64)

  //  Upload Image
  const [selectedFile, setSelectedFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [namefile, setNameFile] = useState(update?.FileName);

  const handleFileUpload = async (imageUrl, fileName) => {
    console.log("fileName", fileName);
    let token = localStorage.getItem("accesstoken");
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex);
    const uniqueFileName = `${nameWithoutExtension}-${Math.random()
      .toString(36)
      .substring(2, 8)}${extension}`;
    console.log("uniqueFileName", uniqueFileName);
    setNameFile(uniqueFileName);
    const payload = {
      FileName: uniqueFileName,
      File: imageUrl,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Urls: "",
      Description: "Test File",
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: headers,
      })
      .then((res) => {
        console.log("Image upload response", res);
        if (res) {
          getFileUrlByFileName(res?.data?.FileName);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const getFileUrlByFileName = (fileName) => {
    console.log("get file clicked");
    let token = localStorage.getItem("accesstoken");
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(`${BASE_URL}/user/UserFilesandUrlsCRUD/?FileName=${fileName}`, {
        headers: headers,
      })
      .then((res) => {
        console.log("get file", res?.data?.File);
        if (res) {
          setFilePath(res?.data?.File);
        }
      })
      .catch((error) => {
        console.error("get file error", error);
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("fileee", selectedFile?.name);
    if (selectedFile) {
      const reader = new FileReader();
      console.log("readerrr", reader);
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        setBa64(base64);
        console.log("base64", base64);
        handleFileUpload(base64, selectedFile?.name);
      };
      reader.readAsDataURL(selectedFile);
    }
    setSelectedFile(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setImagePreview(previewURL);
    // handleRegisterUpload()
    setIsImagePreviewOpen(true);
  };

  const [seriesValue, setSeriesValue] = useState("");

  useEffect(async () => {
    const series = await fetchTransactionSeries("Social_Campaign");
    console.log("Social_Campaign series", series);
    setSeriesValue(series);
  }, []);

  const {
    data: getUserInfoLists,
    isLoading: getUserInfoListsLoading,
    isError: getUserInfoListsError,
    refetch: getUserInfoListsRefetch,
  } = useQuery(["getUserInfoLists"], getUserInfo, { staleTime: Infinity });

  useEffect(() => {
    if (getUserInfoLists) {
      setUserInfo(getUserInfoLists);
    }
  }, [getUserInfoLists]);

  console.log("promolist",promo)
  return (
    <>
      {/* <JumboContentLayoutMain>
       
      </JumboContentLayoutMain> */}
      <Dragabledialog
        maxWidth="lg"
        open={openEditContact}
      // onClose={() => setOpenAppointment(false)}
      >
        <DialogTitle
          sx={[dialogTitle, DisplayFlex]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Edit Social Campaign
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <Customgrid columnCount={4}>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>
                Promo Id
                <span className="required">
                  {seriesValue == "Automatic" ? "" : "*"}
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="# Promo Id"
                variant="outlined"
                name="promoId"
                value={promo?.promoId}
                onChange={handleChangePromo}
                disabled={seriesValue == "Automatic" ? true : ""}
              />
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Promo Name</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Promo Name"
                variant="outlined"
                name="promoname"
                value={promo?.promoname}
                onChange={handleChangePromo}
              />
              {errors?.promoname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promoname} Promo Name{" "}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>
                Promo Code<span className="required"></span>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Promo Code"
                variant="outlined"
                name="promocode"
                value={promo?.promocode}
                onChange={handleChangePromo}
              />
              {errors?.promocode && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promocode} Promo Code{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>
                Promo Date<span className="required"></span>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                type="date"
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                variant="outlined"
                name="promodate"
                value={promo?.promodate}
                onChange={handleChangePromo}
              />
              {errors?.promodate && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promodate} Promo Date{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Promo Content</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Promo Content"
                variant="outlined"
                name="promocontent"
                value={promo?.promocontent}
                onChange={handleChangePromo}
              />
              {errors?.promocontent && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promocontent} Promo Content{" "}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Days to Run</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                type="number"
                autoComplete="off"
                className="input-box col-10"
                id="outlined-basic"
                placeholder="Days To Run"
                variant="outlined"
                name="daystorun"
                value={promo?.daystorun}
                onChange={handleChangePromo}
              />
              {errors?.daystorun && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.daystorun} Days to Run{" "}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Posting Date</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                autoComplete="off"
                className="input-box col-10"
                type="datetime-local"
                id="outlined-basic"
                placeholder="Posting Date"
                variant="outlined"
                name="postingdate"
                value={promo?.postingdate}
                onChange={handleChangePromo}
              />
              {errors?.promoname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  {errors?.promoname} Promo Name{" "}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>SocialMedia Type</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Autocomplete
                multiple
                options={filterItems || []}
                className={`${promo?.postingtype?.length <= 1 && "search-select"
                  } col-10`}
                sx={{ backgroundColor: " #ffffff !important" }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option) {
                    return option;
                  }
                  return "";
                }}
                value={filterItems?.filter((opt) =>
                  promo?.postingtype?.includes(opt)
                )}
                onChange={(e, newValue) => {
                  console.log("newvalue", newValue);

                  setPromos({
                    ...promo,
                    postingtype: newValue?.map((opt) => opt),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Social Media Type"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}></Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              {" "}
            </Grid>

            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Typography sx={[input_label]}>Image</Typography>
            </Grid>
            <Grid
              item
              sx={{
                padding: "10px",
              }}
            >
              <Div
                className="card "
                sx={{
                  width: "200px",
                  height: "240px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => setBa64(null)}
                  sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: "165px",
                    marginTop: "-5px",
                  }}
                >
                  {Boolean(ba64) ? (
                    <MdClose style={{ color: "tomato" }} />
                  ) : (
                    <Div sx={{ pt: 2 }}></Div>
                  )}
                </IconButton>
                {Boolean(ba64) ? (

                  <img
                    alt="image"
                    src={`data:image/png;base64,${ba64}`}
                    width={100}
                    height={100}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <img
                    alt="image"
                    src={uploadimage}
                    width={120}
                    height={120}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
                <Div>
                  <Button
                    component="label"
                    name="imageFile"
                    sx={[
                      FontStyle,
                      {
                        // mt: { sm: 4 },
                        // ml: { xs: 2, md: 4 },
                        color: "#00BFFF",
                        textTransform: "capitalize",
                        width: "100px !important",
                      },
                    ]}
                  >
                    Upload Image
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{
                        backgroundColor: "transparent",
                      }}
                      name="companyLogo"
                    />
                  </Button>
                </Div>
              </Div>
            </Grid>
          </Customgrid>

          <Grid container align="center" mt={2} spacing={2} pb={2}>
            <Grid item align="right" xs={6}>
              <CustomButton label="Update" Icon="saveIcon" onClick={handleSubmit} />
            </Grid>
            <Grid item align="left" xs={6}>
              <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
            </Grid>
          </Grid> 
        </DialogContent>
      </Dragabledialog>
      <DialogBox
        open={save}
        onClickYes={() => {
          setSave(false);
          setOpenEditContact(false);
        }}
        onClickNo={() => setSave(false)}
      />
    </>
  );
};

export default SocialMediaEditCampaign;
