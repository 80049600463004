import { io } from "socket.io-client";
import { apiConfig } from "./config";
import axios from "./config";
const authService = {};

authService.getCurrentUser = async (username, header) => {
  console.log("username", username);
  console.log("header", header);
  const data = await axios.get(
    `${BASE_URL}/superadmin/user/?username=${username}`,
    header
  );

  return data;
};

authService.signIn = async (username, password, header) => {
  const data = await axios.post(
    `${BASE_URL}/auth_token`,
    {
      username,
      password,
    },
    header
  );

  return data;
};

const currentURL = window.location.href;
// Split the URL by '/'
export const segments = currentURL.split("/");
// Get the second segment (index 1)
// export const Tenantname = segments[segments.length > 3 ? 3 : 1].trim();

export const Tenantname = currentURL.split(".")[0].split("//")[1];

console.log("Tenantname:", Tenantname);

export const BASE_URL1 = `https://127.0.0.1:8000/`;

export const BASE_URLIMAGE = `https://127.0.0.1:8000`;

export const BASE_URL = `https://backend.crmfarm.in`;

export const Super_Admin_URL = `https://backend.crmfarm.in`;

export const BASE_URL2 = `https://3.85.209.92:8000/`;

export const socket = io("https://chat.crmfarm.app", {
  transports: ["websocket", "polling"],
});

// export const localSocket = io("https://localhost:8082", {
//   transports: ["websocket", "polling"],
// });
export default authService;
