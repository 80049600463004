import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { CaretDown, MagnifyingGlass, Phone, VideoCamera } from "phosphor-react";
import { HiOutlinePhone } from "react-icons/hi2";
import { GoDeviceCameraVideo } from "react-icons/go";
// import { faker } from "@faker-js/faker";
// import { ToggleSidebar } from "../../redux/slices/app";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "../../hook/useResponsive";
import { FontStyle } from "app/pages/widgets/CRMStyles";
import { StartAudioCall } from "app/redux/chat/audioCall";
import { getSelectChatUser } from "../../dashboard/SelectChatUserSlice";
import { memo } from "react";
import { useStateContext } from "app/contexts/ContextProvider";
import { BASE_URL } from "app/services/auth-services";
import { AvatarCircle } from "app/pages/widgets/StylesComponents";
import ProfileInfo from "./ProfileInfo";
import { useChatContext } from "../../context/Chatcontext";
// import { StartAudioCall } from "../../redux/slices/audioCall";
// import { StartVideoCall } from "../../redux/slices/videoCall";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Conversation_Menu = [
  {
    title: "Contact info",
  },
  {
    title: "Mute notifications",
  },
  {
    title: "Clear messages",
  },
  {
    title: "Delete chat",
  },
];

const ChatHeader = () => {
  // const dispatch = useDispatch();
  const { selectUser } = useChatContext();
  const [toggleProfileInfo, setToggleProfileInfo] = useState(false);

  const isMobile = useResponsive("between", "md", "xs", "sm");
  // const theme = useTheme();

  // const [conversationMenuAnchorEl, setConversationMenuAnchorEl] =
  //   React.useState(null);
  // const openConversationMenu = Boolean(conversationMenuAnchorEl);
  // const handleClickConversationMenu = (event) => {
  //   setConversationMenuAnchorEl(event.currentTarget);
  // };
  // const handleCloseConversationMenu = () => {
  //   setConversationMenuAnchorEl(null);
  // };

  return (
    <>
      <Box
        p={2}
        width={"100%"}
        sx={{
          // backgroundColor:
          //   theme.palette.mode === "light"
          //     ? "#F8FAFF"
          //     : theme.palette.background,
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack
          alignItems={"center"}
          direction={"row"}
          sx={{ width: "100%", height: "100%" }}
          justifyContent="space-between"
        >
          <Stack
            onClick={() => {
              // dispatch(ToggleSidebar());
            }}
            spacing={2}
            direction="row"
          >
            <Box>
              <StyledBadge
                onClick={(e) => setToggleProfileInfo(e.currentTarget)}
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant={
                  !selectUser?.Group_Name &&
                  selectUser?.User_Login_Status &&
                  "dot"
                }
              >
                <AvatarCircle
                  fontSize="12px"
                  size="35px"
                  title={
                    (!Boolean(selectUser?.User_Photo) &&
                      selectUser?.username) ||
                    selectUser?.Group_Name
                  }
                  image={
                    Boolean(selectUser?.User_Photo) &&
                    BASE_URL + selectUser?.User_Photo
                  }
                />
              </StyledBadge>
            </Box>
            <Stack spacing={0.2}>
              <Typography align="left">
                {selectUser?.username || selectUser?.Group_Name}
              </Typography>
              {!selectUser?.Assign_User && (
                <Typography sx={{ fontSize: "10px" }} align="left">
                  {selectUser?.User_Login_Status ? "Online" : "Offline"}
                </Typography>
              )}
              <Typography align="left" sx={{ fontSize: "10px" }}>
                {selectUser?.Assign_User && "You, "}{" "}
                {selectUser?.Assign_User?.map((user, index) => {
                  if (
                    index < 8 &&
                    user?.id !== Number(localStorage.getItem("UserId"))
                  ) {
                    return (
                      <span
                        style={{
                          padding: index === 0 ? "0px 5px 0px 0px" : "0px 5px",
                        }}
                      >
                        {user?.username
                          ?.substring(0, 11)
                          .concat(user?.username?.length > 11 ? "..." : ",") ||
                          "no name "}
                        {index === 7 && "  , . . ."}
                      </span>
                    );
                  }
                })}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={isMobile ? 1 : 2}
          >
            {/* <IconButton
              onClick={() => {
                // dispatch(StartVideoCall(current_conversation.user_id));
              }}
            >
              <GoDeviceCameraVideo size={18} />
            </IconButton> */}
            <IconButton
              onClick={() => {
                // dispatch(StartAudioCall(selectUser, "audio"));
              }}
            >
              <HiOutlinePhone size={18} />
            </IconButton>
          </Stack>
        </Stack>
      </Box>

      <ProfileInfo open={toggleProfileInfo} setOpen={setToggleProfileInfo} />
    </>
  );
};

export default memo(ChatHeader);
