import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const FBContactForm = ({ isFBContactFormOpen, setIsFBContactFormOpen, pageAccessToken }) => {
    const [open, setOpen] = useState(isFBContactFormOpen)
    const [formLink, setFormLint] = useState('https://forms.gle/jRrJ53vutz7WCbHW8')
    const [token, setToken] = useState(pageAccessToken)

    useEffect(() => {
        setToken(pageAccessToken)
    }, [pageAccessToken])


    const handleClose = () => {
        setIsFBContactFormOpen(false)
    }

    const handlePost = () => {

        // let token = "EAADn3m1nbtgBOZCKPeIa8lZBLhw8pPcZBHHtJ2K9XFYR4B9wj1klamnwwCoZBWi8Er9mCy3A2ElSeIVomZBwzlQyY25Jng1T01qC4QpSewCnLkbtHQJ2ATAQoRdgFMfbtTrMnNqQcGy0sGl6yzfFflvjv7PrXqZCaRFZAOZAvw61fZAwtSXSry0FJd2RRMAhZCcFbwkZAjBfMrg924Lzn0jMiCKqcQCFyb8SR9FyrcZD"
        const Headers = {
            Authorization: `Bearer ${pageAccessToken}`,
            "Content-Type": "application/json"
        }
        const payload = {
            "message": formLink,
            "published": "true"
        }
        axios.post('https://graph.facebook.com/v18.0/251682828032268/feed', payload, {
            headers: Headers
        }).then((res) => {
            console.log("fb post response", res)
            if (res?.status == 200) {
                toast.success("Posted Successfully")
                handleClose()
            }
        }).catch((error) => {
            console.error("FB Post error", error)
        })

    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "500px", minHeight: '300px' } }}
        >
            <DialogTitle>Contact Form</DialogTitle>
            <DialogContent sx={{ marginTop: '10px' }}>
                <TextField
                    style={{ width: '100%', height: '100%', marginTop: '10px', marginBottom: '10px' }}
                    InputProps={{ style: { fontSize: '16px' } }}
                    placeholder="Form Link"
                    value={formLink}
                    onChange={(e) => { setFormLint(e.target.value) }}
                />
            </DialogContent>
            <DialogActions>
                <Button className='dialog-cancel-btn' onClick={handleClose}>Close</Button>
                <Button className='dialog-save-btn' onClick={handlePost} >Post</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FBContactForm