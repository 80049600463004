import React from "react";
import {
  Stack,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Divider,
  useTheme,
  alpha,
  Slide,
} from "@mui/material";

import Div from "@jumbo/shared/Div";
import {
  DisplayFlex,
  FontStyle,
  PrimaryColor,
} from "app/pages/widgets/CRMStyles";
import { PiDotsThreeVertical } from "react-icons/pi";
import { MdPhoneMissed } from "react-icons/md";
import { VscCallIncoming, VscCallOutgoing } from "react-icons/vsc";
import { SlCallEnd } from "react-icons/sl";
import { FaFileArchive } from "react-icons/fa";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { BASE_URL } from "app/services/auth-services";
import { UserId } from "app/redux/auth/localStorage";
import Zip from "../../../assets/images/zip.png";
import Exe from "../../../assets/images/exe.png";
import Pdf from "../../../assets/images/pdf.png";
import useResponsive from "../hook/useResponsive";
import { Image } from "antd";

// file download function
const handleDownload = async (msg) => {
  try {
    let url = BASE_URL + msg?.File;
    let fileName = url?.split("/").pop(); // Get the last part of the URL as the filename

    const response = await fetch(url); // Fetch the file from the URL
    const blob = await response.blob(); // Get the blob data

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the dynamically created link
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle errors
  }
};

// image, pdf, text etc view function
const ViewImage = (msg) => {
  window.open(BASE_URL + msg?.File, "_blank");
};
// after down arrow style
const afterStyle = (senderId) => {
  // const theme = useTheme();

  return (
    <Div
      sx={{
        ":after": {
          width: 0,
          position: "absolute",
          content: "''",
          height: 0,
          bottom: -10,
          [`${senderId != UserId ? "left" : "right"}`]: 8,
          borderTop: "0px solid ",
          borderBottom: "11px solid  transparent",
          // borderLeft: "none",
          [`${
            senderId != UserId ? "borderLeft" : "borderRight"
          }`]: `11px solid  ${
            senderId == UserId ? "#E1E4E5" : "rgba(0, 159, 142,0.5)"
          }`,
        },
        zIndex: 0,
        // display: chat?.Meassage !== "" ? "" : 'none'
      }}
    ></Div>
  );
};
const MessageOption = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {/* <DotsThreeVertical
        size={20}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      /> */}
      <PiDotsThreeVertical
        size={20}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack spacing={1} px={1}>
          {/* {Message_options.map((msg) => (
            <MenuItem onClick={handleClose}>{msg?.title}</MenuItem>
          ))} */}
        </Stack>
      </Menu>
    </>
  );
};
const TextMsg = ({ msg, menu }) => {
  const theme = useTheme();
  // console.log("l?.SenderId e== UserId", msg, UserId);
  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Stack
          sx={{ position: "relative", my: 1.5 }}
          direction="row"
          justifyContent={msg?.SenderId == UserId ? "end" : "start"}
        >
          <Box
            px={1}
            py={1}
            sx={{
              backgroundColor:
                msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
              borderRadius: 1.5,
              [`${
                msg?.SenderId != UserId
                  ? "borderBottomLeftRadius"
                  : "borderBottomRightRadius"
              }`]: 0,
              maxWidth: "80%",
              mx: 1,
            }}
          >
            <Typography
              sx={[
                FontStyle,
                {
                  color: msg?.SenderId != UserId ? "#fff" : "#000",
                  p: 0.5,
                },
              ]}
            >
              {msg?.Meassage}
            </Typography>
          </Box>
          {afterStyle(msg?.SenderId)}
          {menu && <MessageOption />}
        </Stack>
      </Slide>
      {/* <div dangerouslySetInnerHTML={{ '__html': arrow }}></div> */}
    </>
  );
};
const MediaMsg = ({ msg, menu }) => {
  const theme = useTheme();
  // responsive
  const isMobile = useResponsive("between", "md", "xs", "sm");

  const getEmbedUrl = (url) => {
    const videoID = url.split("v=")[1]?.split("&")[0];
    return `https://www.youtube.com/embed/${videoID}`;
  };
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          p={0.5}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            width: "max-content",
            mx: 1,
          }}
        >
          <Stack spacing={1}>
            <Image
              width={"100%"}
              height={"100%"}
              style={{
                borderRadius: "10px",
              }}
              preview={{
                destroyOnClose: true,
                imageRender: () => (
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: " 100%",
                      border: "none",
                    }}
                    src={getEmbedUrl(msg?.Meassage) || ""}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                ),

                toolbarRender: () => null,
              }}
              src={getEmbedUrl(msg?.Meassage) || ""}
            />

            {/* <iframe
              width={isMobile ? "100%" : "560"}
              height={isMobile ? "100%" : "315"}
              src={`${getEmbedUrl(msg?.Meassage)}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}

            {/* <a
              href={msg?.Meassage}
              target="_blank"
              variant="body2"
              color={msg?.SenderId != UserId ? theme.palette.text : "#000"}
            >
              {msg?.Meassage}
            </a> */}
          </Stack>
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const ImageMsg = ({ msg, menu }) => {
  const theme = useTheme();
  let msgType = msg?.File?.split(".");
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          p={0.3}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            [`${
              msg?.SenderId != UserId
                ? "borderBottomLeftRadius"
                : "borderBottomRightRadius"
            }`]: 0,
            width: "max-content",
            mx: 1,
          }}
        >
          <Stack
            sx={{
              backgroundColor: alpha("#ffff", 0.1),
              borderRadius: 1,
            }}
            p={0.5}
          >
            <Div sx={[DisplayFlex, { justifyContent: "center" }]}>
              <Image
                style={{ backgroundColor: alpha("#ABF1BC", 0.1) }}
                width={200}
                src={BASE_URL + msg?.File}
                alt={msg?.File?.split("/")[2]}
              />

              {/* <img
                onClick={() => ViewImage(msg)}
                style={{ backgroundColor: alpha("#ABF1BC", 0.1) }}
                src={BASE_URL + msg?.File}
                width={"300px"}
                height={"300px"}
                alt={msg?.File?.split("/")[2]}
              /> */}
            </Div>
            <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
              <Typography
                sx={[
                  FontStyle,
                  {
                    color:
                      msg?.SenderId != UserId ? theme.palette.text : "#fff",
                    p: 0.5,
                  },
                ]}
              >
                {msg?.File?.split("/")[2].length > 20
                  ? msg?.File?.split("/")[2].substring(0, 20) +
                    "..." +
                    msgType[msgType?.length - 1]
                  : msg?.File?.split("/")[2]}
              </Typography>
              <IconButton>
                <AiOutlineCloudDownload
                  onClick={() => handleDownload(msg)}
                  size={16}
                  color={msg?.SenderId != UserId ? "skyblue" : "ghostwhite"}
                />
              </IconButton>
            </Div>
          </Stack>
          {Boolean(msg?.Meassage) && (
            <Typography
              sx={[
                FontStyle,
                {
                  color: msg?.SenderId != UserId ? "#fff" : "#000",
                  p: 0.5,
                },
              ]}
            >
              {msg?.Meassage}
            </Typography>
          )}
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const VideoMsg = ({ msg, menu }) => {
  const theme = useTheme();
  let msgType = msg?.File?.split(".");
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          p={0.3}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            [`${
              msg?.SenderId != UserId
                ? "borderBottomLeftRadius"
                : "borderBottomRightRadius"
            }`]: 0,
            width: "max-content",
            mx: 1,
          }}
        >
          <Stack
            // direction="row"
            // alignItems="center"
            // gap={3}
            sx={{
              backgroundColor: alpha("#ffff", 0.1),
              borderRadius: 1,
            }}
            p={0.5}
          >
            {/* <Image size={48} />Image */}
            <video
              controls
              src={BASE_URL + msg?.File}
              width={"300px"}
              height={"300px"}
              alt={"no Image"}
            />
            {
              <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                <Typography
                  sx={[
                    FontStyle,
                    {
                      color:
                        msg?.SenderId != UserId ? theme.palette.text : "#fff",
                      p: 0.5,
                    },
                  ]}
                >
                  {msg?.File?.split("/")[2].length > 20
                    ? msg?.File?.split("/")[2].substring(0, 20) +
                      "..." +
                      msgType[msgType?.length - 1]
                    : msg?.File?.split("/")[2]}
                </Typography>
                <IconButton>
                  <AiOutlineCloudDownload
                    onClick={() => handleDownload(msg)}
                    size={16}
                    color={msg?.SenderId != UserId ? "skyblue" : "ghostwhite"}
                  />
                </IconButton>
              </Div>
            }
          </Stack>
          {Boolean(msg?.Meassage) && (
            <Typography
              sx={[
                FontStyle,
                {
                  color: msg?.SenderId != UserId ? "#fff" : "#000",
                  p: 0.5,
                },
              ]}
            >
              {msg?.Meassage}
            </Typography>
          )}
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const AudioMsg = ({ msg, menu }) => {
  const theme = useTheme();
  let msgType = msg?.File?.split(".");
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            [`${
              msg?.SenderId != UserId
                ? "borderBottomLeftRadius"
                : "borderBottomRightRadius"
            }`]: 0,
            width: "max-content",
            mx: 1,
          }}
        >
          <Stack
            // direction="row"
            // alignItems="center"
            // gap={3}
            sx={{
              backgroundColor: alpha("#ffff", 0.1),
              borderRadius: 1,
            }}
            p={0.5}
          >
            {/* <Image size={48} />Image */}
            <audio
              controls
              src={BASE_URL + msg?.File}
              width={"300px"}
              height={"300px"}
              alt={"no Image"}
            />
            {
              <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                <Typography
                  sx={[
                    FontStyle,
                    {
                      color:
                        msg?.SenderId != UserId ? theme.palette.text : "#fff",
                      p: 0.5,
                    },
                  ]}
                >
                  {msg?.File?.split("/")[2].length > 20
                    ? msg?.File?.split("/")[2].substring(0, 20) +
                      "..." +
                      msgType[msgType?.length - 1]
                    : msg?.File?.split("/")[2]}
                </Typography>
                <IconButton>
                  <AiOutlineCloudDownload
                    onClick={() => handleDownload(msg)}
                    size={16}
                    color={msg?.SenderId != UserId ? "skyblue" : "ghostwhite"}
                  />
                </IconButton>
              </Div>
            }
          </Stack>

          {Boolean(msg?.Meassage) && (
            <Typography
              sx={[
                FontStyle,
                {
                  color: msg?.SenderId != UserId ? "#fff" : "#000",
                  p: 0.5,
                },
              ]}
            >
              {msg?.Meassage}
            </Typography>
          )}
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const OthersMsg = ({ msg, menu }) => {
  const theme = useTheme();
  console.log("images", BASE_URL, msg?.File);
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          p={0.3}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            [`${
              msg?.SenderId != UserId
                ? "borderBottomLeftRadius"
                : "borderBottomRightRadius"
            }`]: 0,
            width: "max-content",
            mx: 1,
          }}
        >
          <Stack
            // direction="row"
            // alignItems="center"
            // gap={3}
            sx={{
              backgroundColor: alpha("#ffff", 0.1),
              borderRadius: 1,
            }}
            p={0.5}
          >
            {/* <Image size={48} />Image */}
            <img
              src={BASE_URL + msg?.File}
              width={"300px"}
              height={"300px"}
              alt={"no Image"}
            />
            {/* <Div sx={[DisplayFlex]}> */}
            {/* <FaFileArchive size={18} color="lightgray"/> */}
            {/* <Typography sx={[FontStyle,{color:msg?.SenderId != UserId ? theme.palette.text : "#fff"}]}>{msg?.File}</Typography> */}
            {/* </Div> */}
            <IconButton sx={[DisplayFlex, { justifyContent: "right" }]}>
              <AiOutlineCloudDownload
                onClick={() => handleDownload(msg)}
                size={16}
                color={msg?.SenderId != UserId ? "skyblue" : "ghostwhite"}
              />
            </IconButton>
          </Stack>
          <Typography
            sx={[
              FontStyle,
              {
                color: msg?.SenderId != UserId ? "#fff" : "#000",
                p: 0.5,
              },
            ]}
          >
            {msg?.Meassage}
          </Typography>
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const DocMsg = ({ msg, menu }) => {
  const theme = useTheme();
  // msg?.File?.split(".")
  let msgType = msg?.File?.split(".");
  let type = msgType[msgType?.length - 1];
  console.log("asdhbsdb", BASE_URL + msg?.File);
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          p={0.3}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            [`${
              msg?.SenderId != UserId
                ? "borderBottomLeftRadius"
                : "borderBottomRightRadius"
            }`]: 0,
            width: "max-content",
            mx: 1,
          }}
        >
          <Stack
            sx={{
              backgroundColor: alpha("#ffff", 0.1),
              borderRadius: 1,
            }}
            p={0.5}
          >
            {/* <Image size={48} />Image */}
            <Div>
              {type?.toLowerCase() == "zip" ||
              type?.toLowerCase() == "exe" ||
              type?.toLowerCase() == "msi" ||
              type?.toLowerCase() == "pdf" ? (
                <Div sx={{ textAlign: "center" }}>
                  {/* <FaFileArchive size={30} color="lightgray" /> */}
                  {
                    <img
                      style={{
                        margin: "20px",
                        cursor: type?.toLowerCase() == "pdf" && "pointer",
                      }}
                      onClick={() =>
                        type?.toLowerCase() == "pdf" && ViewImage(msg)
                      }
                      src={
                        type?.toLowerCase() == "exe" ||
                        type?.toLowerCase() == "msi"
                          ? Exe
                          : type?.toLowerCase() == "pdf"
                          ? Pdf
                          : Zip
                      }
                      width={50}
                      height={50}
                    />
                  }
                  <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                    <Typography
                      sx={[
                        FontStyle,
                        {
                          color:
                            msg?.SenderId != UserId
                              ? theme.palette.text
                              : "#fff",
                          p: 0.5,
                        },
                      ]}
                    >
                      {msg?.File?.split("/")[2].length > 20
                        ? msg?.File?.split("/")[2].substring(0, 20) +
                          "..." +
                          msgType[msgType?.length - 1]
                        : msg?.File?.split("/")[2]}
                    </Typography>
                    <IconButton>
                      <AiOutlineCloudDownload
                        onClick={() => handleDownload(msg)}
                        size={16}
                        color={
                          msg?.SenderId != UserId ? "skyblue" : "ghostwhite"
                        }
                      />
                    </IconButton>
                  </Div>
                </Div>
              ) : (
                <Div>
                  <embed
                    onClick={() => {
                      ViewImage(msg);
                    }}
                    src={BASE_URL + msg?.File}
                  ></embed>
                  <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                    <Typography
                      sx={[
                        FontStyle,
                        {
                          color:
                            msg?.SenderId != UserId
                              ? theme.palette.text
                              : "#fff",
                          p: 0.5,
                        },
                      ]}
                    >
                      {msg?.File?.split("/")[2].length > 20
                        ? msg?.File?.split("/")[2].substring(0, 20) +
                          "..." +
                          msgType[msgType?.length - 1]
                        : msg?.File?.split("/")[2]}
                    </Typography>
                    <IconButton>
                      <AiOutlineCloudDownload
                        onClick={() => handleDownload(msg)}
                        size={16}
                        color={
                          msg?.SenderId != UserId ? "skyblue" : "ghostwhite"
                        }
                      />
                    </IconButton>
                  </Div>
                </Div>
              )}
            </Div>
          </Stack>
          {Boolean(msg?.Meassage) && (
            <Typography
              sx={[
                FontStyle,
                {
                  color: msg?.SenderId != UserId ? "#fff" : "#000",
                  p: 0.5,
                },
              ]}
            >
              {msg?.Meassage}
            </Typography>
          )}
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const LinkMsg = ({ msg, menu }) => {
  const theme = useTheme();
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        sx={{ position: "relative", my: 1.5 }}
        justifyContent={msg?.SenderId != UserId ? "start" : "end"}
      >
        <Box
          p={0.5}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId
                ? alpha(theme.palette.background.default, 1)
                : "rgba(0, 159, 142,0.5)",
            borderRadius: 1.5,
            width: "max-content",
            mx: 1,
          }}
        >
          <a
            href={msg?.Meassage}
            target="_blank"
            variant="body2"
            color={msg?.SenderId != UserId ? theme.palette.text : "#fff"}
          >
            {msg?.Meassage}
          </a>
        </Box>

        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const CallMsg = ({ msg, menu }) => {
  const theme = useTheme();

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        sx={{ position: "relative", my: 1.5 }}
        direction="row"
        justifyContent={msg?.SenderId == UserId ? "end" : "start"}
      >
        <Box
          p={0.3}
          sx={{
            backgroundColor:
              msg?.SenderId != UserId ? "rgba(0, 159, 142,0.5)" : "#E1E4E5",
            borderRadius: 1.5,
            [`${
              msg?.SenderId != UserId
                ? "borderBottomLeftRadius"
                : "borderBottomRightRadius"
            }`]: 0,
            width: "max-content",
            mx: 1,
          }}
        >
          <Div
            sx={[
              DisplayFlex,
              {
                p: 1,
                gap: "10px !important",
                backgroundColor: "rgba(200,200,200,0.1)",
              },
            ]}
          >
            <Div>
              <IconButton
                disabled
                sx={{
                  bgcolor: `${
                    msg?.SenderId == UserId ? "#f6f6f6" : "lightgray"
                  } !important`,
                  p: 1.5,
                }}
              >
                {(msg?.Audio_Answer === "declined" ||
                  msg?.Video_Answer === "declined") && (
                  <MdPhoneMissed size={18} color={"tomato"} />
                )}
                {/* {(msg?.Audio_Answer === "" || msg?.Video_Answer==="") && <VscCallOutgoing size={18} />} */}
                {/* {(msg?.Audio_Answer === "" || msg?.Video_Answer==="") && <VscCallIncoming size={18} />} */}
                {(msg?.Audio_Answer === "callend" ||
                  msg?.Video_Answer === "callend") && <SlCallEnd size={18} />}
              </IconButton>
            </Div>
            <Div>
              <Typography
                sx={[
                  FontStyle,
                  {
                    color:
                      msg?.SenderId != UserId ? theme.palette.text : "#fff",
                    p: 0.5,
                  },
                ]}
              >
                {msg?.Audio_Answer !== "" ? "Voice Call" : "Video Call"}
              </Typography>

              {(msg?.Audio_Answer === "declined" ||
                msg?.Video_Answer === "declined") && (
                <Typography
                  sx={[
                    FontStyle,
                    { color: msg?.SenderId != UserId ? "#fff" : "#000" },
                  ]}
                >
                  {msg?.SenderId == UserId ? "No answer" : "Missed call"}
                </Typography>
              )}
            </Div>
          </Div>
        </Box>
        {afterStyle(msg?.SenderId)}
        {menu && <MessageOption />}
      </Stack>
    </Slide>
  );
};
const Timeline = ({ msg, trans }) => {
  const theme = useTheme();
  let time = new Date(msg?.SendedDate).toLocaleTimeString([], {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={msg?.SenderId == UserId ? "right" : "left"}
      >
        {/* <Divider width="46%" /> */}
        <Typography
          sx={{
            transform: `translate(${
              msg?.SenderId == UserId ? "-15px" : "15px"
            } , -10px)`,
            fontSize: "8px",
            color: "gray",
          }}
        >
          {time}
        </Typography>
        {/* <Divider width="46%" /> */}
      </Stack>
    </Slide>
  );
};

export {
  Timeline,
  MediaMsg,
  LinkMsg,
  DocMsg,
  TextMsg,
  CallMsg,
  VideoMsg,
  OthersMsg,
  AudioMsg,
  ImageMsg,
};
