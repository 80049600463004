import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import CloseIcon from "@mui/icons-material/Close";
import React, { memo, useEffect } from "react";
import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import WebQualify from "./lead generation/WebQualify";
import { Country, State, City } from "country-state-city";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  dialogFontSize,
  dialogTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import {
  Cancelbutton,
  DialogBox,
  CustomButton,
  Dragabledialog,
  Customgrid,
  errorFilter,
} from "app/pages/widgets/StylesComponents";
// import ContactEdit from "app/pages/accounts/AccountEdit";
import { useQuery, useQueryClient } from "react-query";

const LeadFromEnquiryEdit = ({
  scrollHeight,
  openAddLead,
  setOpenAddLead,
  webupdate,
  getEnquiryListsRefetch,
  anchorElAction,
  setAnchorElAction,
}) => {
  console.log("webupdate", webupdate);
  //  Initilization For Country,state,city

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [save, setSave] = useState(false);
  const [errors, setErrors] = useState({});

  const [fname, setFname] = useState(webupdate.LeadFirstName);
  const [lname, setLname] = useState(webupdate.LeadLastName);
  const [cname, setCname] = useState(webupdate.CompanyName);
  const [phone, setPhone] = useState(webupdate.PhoneNo);
  const [url, setUrl] = useState(webupdate.WebsiteURL);
  const [leadtype, setLeadType] = useState(webupdate.LeadType);
  const [selectedCountry1, setSelectedCountry1] = useState(webupdate.Country);
  const [selectedState1, setSelectedState1] = useState(webupdate.State);
  const [selectedCity1, setSelectedCity1] = useState(webupdate.City);
  const [areaname, setAreaName] = useState(webupdate.AreaName);
  const [strname, setStrname] = useState(webupdate.StreetName);
  const [email, setEmail] = useState(webupdate.EmailId);
  const [BuildingNo, setBuildingNo] = useState(webupdate.BuildingDoorNo);
  const [des, setDes] = useState(webupdate.LeadDescription);
  const [turnover, setTurnOver] = useState(webupdate.LeadCompanyTurnOver);
  const [noofemployee, setNoofEmployee] = useState(webupdate.LeadNoOfEmployees);
  const [location, setLocation] = useState(webupdate.LeadLocation);
  const [leadsource1, setLeadscource1] = useState(webupdate.LeadSource);
  const [jobtitle1, setJobtitle1] = useState(webupdate.Job_Title);
  const [leadindustry1, setLeadindustry1] = useState(webupdate.LeadIndustry);
  const [leadstatus1, setLeadStatus1] = useState(webupdate.Lead_Status);

  useEffect(() => {
    setFname(webupdate?.LeadFirstName || "");
    setLname(webupdate?.LeadLastName || "");
    setCname(webupdate?.CompanyName || "");
    setPhone(webupdate?.PhoneNo || "");
    setUrl(webupdate?.WebsiteURL || "");
    setLeadType(webupdate?.LeadType || "");
    setSelectedCountry1(webupdate?.Country || "");
    setSelectedState1(webupdate?.State || "");
    setSelectedCity1(webupdate?.City || "");
    setAreaName(webupdate?.AreaName || "");
    setStrname(webupdate?.StreetName || "");
    setEmail(webupdate?.EmailId || "");
    setBuildingNo(webupdate?.BuildingDoorNo || "");
    setDes(webupdate?.LeadDescription || "");
    setTurnOver(webupdate?.LeadCompanyTurnOver || "");
    setNoofEmployee(webupdate?.LeadNoOfEmployees || "");
    setLocation(webupdate?.LeadLocation || "");
    setJobtitle1(webupdate?.Job_Title || "");
    setLeadscource1(webupdate?.LeadSource || "");
    setLeadindustry1(webupdate?.LeadIndustry || "");
    setLeadStatus1(webupdate?.Lead_Status || "");
  }, [webupdate]);

  // For Validation Purpose
  const validationSchema = yup.object().shape({
    fname: yup.string().required("Lead First Name is required"),
    lname: yup.string().required("Lead Last Name is required"),
    cname: yup.string().required("Company Name is Required"),
    phone: yup.string().required("Mobile Number is required"),
    // url: yup.string().required("Website  is required"),
    selectedCity1: yup.string().required("City is required"),
    selectedCountry1: yup.string().required("Country is Required"),
    selectedState1: yup.string().required("State is Required"),
    areaname: yup.string().required("Area Name is required"),
    strname: yup.string().required("Street Name is required"),
    email: yup.string().required("Email is required"),
    BuildingNo: yup.string().required("Buildingt Name is required"),
    // des: yup.string().required("Description is required"),
    // location: yup.string().required("Location is required"),
    jobtitle1: yup.string().required("Job Tittle is Required"),
    leadsource1: yup.string().required("Lead Source is Required"),
    leadindustry1: yup.string().required("Lead Industry is Required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { error },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";
  const onsubmit = async () => {
    var updatepayload = {
      id: webupdate?.id,
      EnqleadId: webupdate?.EnqleadId,
      Salutation: webupdate?.Salutation,
      LeadFirstName: fname,
      LeadLastName: lname,
      CompanyName: cname,
      PhoneNo: phone,
      EmailId: email,
      WebsiteURL: webupdate?.WebsiteURL,
      BuildingDoorNo: BuildingNo,
      StreetName: strname,
      AreaName: areaname,
      City: selectedCity1,
      State: selectedState1,
      Country: selectedCountry1,
      LeadSource: leadsource1,
      LeadIndustry: leadindustry1,
      Job_Title: jobtitle1,
      Lead_Status: leadstatus1,
      LeadLocation: location,
      LeadCompanyTurnOver: turnover,
      LeadNoOfEmployees: noofemployee,
      Decissionmaker: webupdate?.Decissionmaker,
      LeadDescription: webupdate?.LeadDescription,
      LeadType: webupdate?.LeadType,
      LeadMLQStatus: webupdate?.LeadMLQStatus,
      LeadSLQStatus: webupdate?.LeadSLQStatus,
      LeadQualifyStatus: webupdate?.LeadQualifyStatus,
      LeadMLQuestions: webupdate?.LeadMLQuestions,
      LeadSLQuestions: webupdate?.LeadSLQuestions,
      Is_blocked: webupdate?.Is_blocked,
      Created_Date: webupdate?.Created_Date,
      Updated_Date: webupdate?.Updated_Date,
      Organization_Id: webupdate?.Organization_Id,
      Created_By: webupdate?.Created_By,
      Updated_By: webupdate?.Updated_By,
    };

    let error = errorFilter({
      fname,
      lname,
      cname,
      phone,
      // url,
      leadtype,
      selectedCountry1,
      selectedState1,
      selectedCity1,
      areaname,
      strname,
      email,
      BuildingNo,
      // des,
      turnover,
      noofemployee,
      location: areaname,
      jobtitle1,
      leadsource1,
      leadindustry1,
      leadstatus1,
    });

    console.log("payload", updatepayload);
    setErrors(error);
    if (Object.keys(error).length > 0) {
      toast.error("Please Check the all the fields are Filled", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      await axios
        .put(`${BASE_URL}/user/UserLeadfromenquiryCRUD/`, updatepayload, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res.data", res.data);
          setOpenAddLead(false);
          getEnquiryListsRefetch(1);
          toast.success("Updated successfully", {
            position: "top-right",
            autoClose: 100,
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  // Geeting the Job Tittle From UserAdmin Table

  const [jobtitle, setJobtitle] = useState([]);
  const [leadstatus, setLeadStatus] = useState([]);
  const [leadindustry, setLeadindustry] = useState([]);
  const [leadsource, setLeadscource] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      // Find and return the relevant lookup data
      // const leadIndustry = response?.data?.find(
      //   (op) => op?.Lookupname === "Industry Type"
      // );
      // const leadStatus = response?.data?.find(
      //   (op) => op?.Lookupname === "Lead Status"
      // );
      // const jobTitle = response?.data?.find(
      //   (op) => op?.Lookupname === "Job Title"
      // );
      // const leadSource = response?.data?.find(
      //   (op) => op?.Lookupname === "Lead Source"
      // );

      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      throw error; // Re-throw the error to handle it in the component
    }
  };

  const HandleCancel = () => {
    setSave(false);
    setOpenAddLead(false);
    reset();
    setFname("");
    setLname("");
    setCname("");
    setPhone("");
    setUrl("");
    setLeadType("");
    setSelectedCountry1("");
    setSelectedState1("");
    setSelectedCity1("");
    setAreaName("");
    setStrname("");
    setEmail("");
    setBuildingNo("");
    setDes("");
    setTurnOver("");
    setNoofEmployee("");
    setLocation("");
    setJobtitle1("");
    setLeadscource1("");
    setLeadindustry1("");
    setLeadStatus1("");
    setAnchorElAction(null);
  };

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUp"], getlookupData, { staleTime: Infinity });

  useEffect(() => {
    if (getUserLoopUp) {
      setLeadindustry(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type")
      );
      setLeadStatus(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Status")
      );
      setJobtitle(getUserLoopUp?.find((op) => op?.Lookupname === "Job Title"));
      setLeadscource(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Source")
      );
      console.log("Setting", getUserLoopUp);
    }
  }, [getUserLoopUp]);

  return (
    <>
      <Dragabledialog open={openAddLead} maxWidth="lg">
        <DialogTitle
          sx={[DisplayFlex, dialogFontSize]}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          Edit Enquiry ( website )
        </DialogTitle>

        <DialogContent sx={{ pt: 2 }}>
          <Customgrid columnCount={4}>
            <Grid item>
              <Typography sx={[input_label]}>Enquiry Id</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2  ${
                  errors.name ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="# Enquiry Id"
                variant="outlined"
                value={webupdate?.EnqleadId}
                InputProps={{
                  sx: { borderRadius: "5px", width: "100%" }
                }}
                disabled
              />
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>First Name</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2  ${
                  errors.name ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead First Name"
                variant="outlined"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.fname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  First name {errors.fname}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Last Name</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.lname ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead Last Name"
                variant="outlined"
                value={lname}
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.lname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Last name {errors.lname}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Job Title</Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value || value === ""
                }
                value={jobtitle1}
                defaultValue={jobtitle1}
                options={jobtitle?.Values || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setJobtitle1(newValue?.value);
                  setValue("jobtitle1", newValue?.value);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Job Title"
                  />
                )}
              />

              {errors?.jobtitle1 && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Job title {errors.jobtitle1}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Company Name</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2  ${
                  errors.cname ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Company Name"
                onChange={(e) => {
                  setCname(e.target.value);
                }}
                variant="outlined"
                value={cname}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.cname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Company name {errors.cname}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Phone Number</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.phone ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Phone No"
                variant="outlined"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.phone && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Phone number {errors.phone}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>E-mail Id</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.email ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Email Id"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />

              {errors?.topic && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Email Id {errors.email}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Building Name / No</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2  ${
                  errors.BuildingNo ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Building DoorNo"
                variant="outlined"
                value={BuildingNo}
                onChange={(e) => {
                  setBuildingNo(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.BuildingNo && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Building no {errors.BuildingNo}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Street Name</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.strname ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Street Name"
                variant="outlined"
                value={strname}
                onChange={(e) => {
                  setStrname(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.strname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Street name {errors.strname}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Area Name</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2  ${
                  errors.BuildingNo ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Area Name"
                variant="outlined"
                value={areaname}
                onChange={(e) => {
                  setAreaName(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.areaname && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Area name {errors.areaname}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Country</Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.name) {
                    return option?.name;
                  }
                  return "";
                }}
                options={Country.getAllCountries() || []}
                value={selectedCountry1}
                onChange={(e, newValue) => {
                  setValue("selectedCountry1", newValue?.name);
                  console.log("newValue", newValue);
                  setSelectedCountry(newValue);
                  setSelectedCountry1(newValue?.name);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Country" />
                )}
              />
              {errors?.selectedCountry1 && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Country {errors.selectedCountry1}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>State</Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.name) {
                    return option?.name;
                  }
                  return "";
                }}
                value={selectedState1}
                options={
                  State.getStatesOfCountry(selectedCountry?.isoCode) || []
                }
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setSelectedState(newValue);
                  setValue("selectedState1", newValue?.name);
                  setSelectedState1(newValue?.name);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value || value === ""
                }
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "5px",

                      width: "100%",
                    }}
                    placeholder="State"
                  />
                )}
              />
              {errors?.selectedState1 && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  State {errors.selectedState1}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>City</Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.name) {
                    return option?.name;
                  }
                  return "";
                }}
                options={
                  City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  ) || []
                }
                value={selectedCity1}
                onChange={(e, newValue) => {
                  setValue("selectedCity1", newValue?.name);
                  console.log("newValue", newValue);
                  setSelectedCity(newValue);
                  setSelectedCity1(newValue?.name);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="City" />
                )}
              />
              {errors?.selectedCity1 && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  City {errors.selectedCity1}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Source</Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value || value === ""
                }
                value={leadsource1}
                options={(leadsource && leadsource?.Values) || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setLeadscource1(newValue?.value);
                  setValue("leadsource1", newValue?.value);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Lead Source"
                  />
                )}
              />
              {errors?.leadsource1 && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Source {errors.leadsource1}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Company Turn Over</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.turnover ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead CompanyTurn Over"
                variant="outlined"
                name="turnover"
                value={turnover}
                onChange={(e) => {
                  setTurnOver(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",

                    width: "100%",
                  },
                }}
              />
              {errors?.turnover && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Company turn over {errors.turnover}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Lead No Of Employees</Typography>
            </Grid>

            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.noofemployee ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead No Of Employees"
                variant="outlined"
                name="noofemployee"
                value={noofemployee}
                onChange={(e) => {
                  setNoofEmployee(e.target.value);
                }}
                InputProps={{
                  sx: {
                    borderRadius: "5px",
                    width: "100%",
                  },
                }}
              />
              {errors?.noofemployee && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  No of employees {errors.noofemployee}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>Industry</Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                value={leadindustry1}
                options={(leadindustry && leadindustry?.Values) || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setLeadindustry1(newValue?.value);
                  setValue("leadindustry1", newValue?.value);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Lead Industry"
                  />
                )}
              />
              {errors?.leadindustry1 && (
                <Typography
                  sx={[{ fontSize: "12px", color: "red !important" }]}
                >
                  Industry {errors.leadindustry1}
                </Typography>
              )}
            </Grid>
          </Customgrid>

          <Grid container mt={2} align="center" spacing={2} pb={2}>
            <Grid item align="right" xs={6}>
              <CustomButton
                onClick={onsubmit}
                label="Update"
                type="submit"
                Icon="saveIcon"
              />
            </Grid>
            <Grid item align="left" xs={6}>
              <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dragabledialog>

      {/* close dialog box */}
      <DialogBox
        open={save}
        onClickNo={() => setSave(false)}
        onClickYes={() => HandleCancel()}
      />
    </>
  );
};

export default memo(LeadFromEnquiryEdit);
