import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import React, { useState } from "react";
import {
  DeleteSalesTarget,
  postSalesTarget,
  useGetSalesPersonTarget,
  useTerritoryList,
  useTotalSalesTarget,
} from "../TerritoryAPI";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import {
  CRMTableHead,
  Colors,
  DisplayFlex,
  FontStyle,
  componentTitle,
  deletedWarnigMsg,
  input_label,
} from "app/pages/widgets/CRMStyles";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
import {
  CustomButton,
  DialogBox,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { AutoHeight } from "app/redux/auth/localStorage";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { HiMiniPlus } from "react-icons/hi2";
import { IoCreate } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import CreateSalesTarget from "./CreateSalesTarget";
import TableSkelton from "app/pages/components/skeleton/Skeleton";
import VSkeleton from "app/pages/components/skeleton/Skeleton";
import SetSalesperesonTarget from "./SetSalesperesonTarget";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";

const SalesTarget = () => {
  const columnItems = [
    { label: "Created Date", bool: true },
    { label: "Target Id", bool: true },
    { label: "Territory Name", bool: true },
    { label: "Year", bool: true },
    { label: "Action", bool: true, width: "90px" },
  ];

  // const { datas, isSalesTargetListLoading } = SalesTargetList();

  const { salesPersonTarget, refetchSalesPersonTarget } =
    useGetSalesPersonTarget();

  const { SalesTargetList, isSalesTargetListLoading, refetchSalesTargetList } =
    useTotalSalesTarget();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState("list");
  const [update, setUpdate] = useState({});
  const [save, setSave] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("Toggle 2: ", SalesTargetList);

  // delete persons
  const deleteRelatedPersonTarget = async (id) => {
    try {
      await axios
        .delete(`${BASE_URL}/user/SalesPersonTargetCRUD/`, {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          refetchSalesPersonTarget();
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // delete record
  const token = localStorage.getItem("accesstoken");
  const handleDeleteSalesTarget = async () => {
    let territory = salesPersonTarget?.find(
      (item) => item?.Territery_Id === update?.Territery_Id
    );
    setLoading(true);
    if (Boolean(territory)) {
      deleteRelatedPersonTarget(territory?.id);
    }

    try {
      await axios
        .delete(`${BASE_URL}/user/SalesTargetCRUD/`, {
          params: {
            id: update?.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("Deleted ", update?.Territery_Name, " Territory ", res);
          toast.success("Deleted ", update?.Territery_Name, " Territory ", {
            position: "top-right",
            autoClose: 100,
          });
          refetchSalesTargetList();
          setLoading(false);
          setAnchorEl(null);
          setSave(false);
          setUpdate({});
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
          setLoading(false);
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  const renderRow = (data, level = 0) => {
    return (
      <React.Fragment key={data?.id}>
        <CRMTableRow>
          <CRMTableCell data-title="Created Date" align="left">
            <Typography sx={[FontStyle]}>{data?.Updated_Date}</Typography>
          </CRMTableCell>
          <CRMTableCell data-title="Target Id" align="left">
            <Typography sx={[FontStyle]}>{data?.Target_Id}</Typography>
          </CRMTableCell>

          <CRMTableCell data-title="Territery Name">
            <Typography
              sx={[
                FontStyle,
                {
                  display: "flex",
                  alignItems: "center",
                  // paddingLeft: level * 2,
                  cursor: "pointer",
                },
              ]}
            >
              {data?.Territery_Name}
            </Typography>
          </CRMTableCell>
          <CRMTableCell data-title="Year" align="left">
            <Typography sx={[FontStyle]}>
              {data?.Created_Date?.split("-")[0] +
                " - " +
                (Number(data?.Created_Date?.split("-")[0]?.substring(2)) + 1)}
            </Typography>
          </CRMTableCell>

          <CRMTableCell align="center">
            <IconButton
              size="medium"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setUpdate(data);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </CRMTableCell>
        </CRMTableRow>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(false)}
        >
          <MenuItem onClick={() => setSave(true)}>
            <ListItemIcon>
              <MdDelete style={{ fontSize: 18, color: "tomato" }} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography sx={[FontStyle]}>Delete</Typography>}
            />
          </MenuItem>
        </Menu>

        {/* {isOpen && data?.children?.map((child) => renderRow(child, level + 1))} */}
      </React.Fragment>
    );
  };

  return (
    <JumboContentLayoutMain>
      <Box sx={{ display: open === "list" ? "" : "none" }}>
        <Box className="flex items-center justify-between my-2">
          <Typography sx={[componentTitle, { mb: 2 }]}>Sales Target</Typography>
          <CustomButton
            label="Create"
            Icon="createIcon"
            disabled={Boolean(isSalesTargetListLoading)}
            onClick={() => setOpen("Create")}
          />
        </Box>

        <Box
          sx={{
            display: !Boolean(isSalesTargetListLoading) ? "block" : "none",
          }}
        >
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={AutoHeight(1.6)}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table stickyHeader>
              <CustomTableHeader
                headerMenuItems={columnItems}
                showGear={false}
              />
              <TableBody>
                {Boolean(SalesTargetList) &&
                  SalesTargetList?.map((element) => renderRow(element))}
              </TableBody>
            </Table>
          </JumboScrollbar>
        </Box>

        <Box
          sx={{ display: Boolean(isSalesTargetListLoading) ? "block" : "none" }}
        >
          <VSkeleton type="table" />
        </Box>
      </Box>

      <Box sx={{ display: open === "Create" ? "block" : "none" }}>
        <CreateSalesTarget open={open} setOpen={setOpen} />
      </Box>

      <Box sx={{ display: open === "Edit" ? "block" : "none" }}>
        <CreateSalesTarget open={open} setOpen={setOpen} update={update} />
      </Box>

      {/* <Box sx={{ display: open === "Person Create" ? "block" : "none" }}>
        <SetSalesperesonTarget open={open} setOpen={setOpen} update={update} />
      </Box>

      <Box
        sx={{
          display: open === "Person Edit" ? "block" : "none",
          overflowY: "hidden",
        }}
      >
        <SetSalesperesonTarget open={open} setOpen={setOpen} update={update} />
      </Box> */}

      {/* Delete dialogbox */}
      <DialogBox
        Content={deletedWarnigMsg}
        Subcontent="Note: Deleting this sales target will also delete the associated sales person's target."
        open={save}
        disabled={loading ? true : false}
        onClickNo={() => setSave(false)}
        onClickYes={() => {
          handleDeleteSalesTarget();
          // deleteRelatedPersonTarget();
        }}
      />
    </JumboContentLayoutMain>
  );
};

export default SalesTarget;
