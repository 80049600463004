import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ImageIcon from "@mui/icons-material/Image";
import Div from "@jumbo/shared/Div";
import { useRef } from "react";
import axios from "axios";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL, BASE_URLIMAGE } from "app/services/auth-services";
import { toast } from "react-toastify";

const PostDialog = ({ postDialogOpen, setPostDialogOpen, userInfo }) => {
  const token = localStorage.getItem("accesstoken");
  const [open, setOpen] = useState(postDialogOpen);
  const [name, setName] = useState("");
  const [textInput, setTextInput] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [filePath, setFilePath] = useState("");
  const handleClose = () => {
    setPostDialogOpen(false);
  };
  const fileInputRef = useRef(null);
  const [userInformation, setUserInformation] = useState(userInfo);
  const [uploadRes, setUploadRes] = useState("");
  const [textValue, setTextValue] = useState("");
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [binaryData, setBinaryData] = useState("");

  useEffect(() => {
    setUserInformation(userInfo);
  }, [userInfo]);

  const handlePost = async () => {
    // let imageUrl = `C:\Users\user\Pictures\Saved Pictures`
    const payload = {
      UserId: localStorage.getItem("UserId"),
      sub: userInformation?.sub,
      textValue: textValue,
      url: `${BASE_URL}${filePath}`,
    };
    await axios
      .post(`${BASE_URL}/user/LinkdinImageCRUD/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("Posts response", res);
        if (res?.status === 200) {
          toast.success(res?.data?.status);
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage = error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleFileUpload = async (imageUrl, fileName) => {
    let token = localStorage.getItem("accesstoken");
    const extensionIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex);
    const uniqueFileName = `${nameWithoutExtension}-${Math.random()
      .toString(36)
      .substring(2, 8)}${extension}`;

    const payload = {
      FileName: uniqueFileName,
      File: imageUrl,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Urls: "",
      Description: "Test File",
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    await axios
      .post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
        headers: headers,
      })
      .then((res) => {
        console.log("Image upload response", res);
        if (res) {
          getFileUrlByFileName(res?.data?.FileName);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage = error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const getFileUrlByFileName = (fileName) => {
    console.log("get file clicked");
    let token = localStorage.getItem("accesstoken");

    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    axios
      .get(`${BASE_URL}/user/UserFilesandUrlsCRUD/?FileName=${fileName}`, {
        headers: headers,
      })
      .then((res) => {
        console.log("get file", res?.data?.File);
        if (res) {
          setFilePath(res?.data?.File);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage = error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("fileee", selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      console.log("readerrr", reader);
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        console.log("base64", base64);

        handleFileUpload(base64, selectedFile?.name);
      };
      reader.readAsDataURL(selectedFile);
    }
    setSelectedFile(selectedFile);
    const previewURL = URL.createObjectURL(selectedFile);
    setImagePreview(previewURL);
    setIsImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setIsImagePreviewOpen(false);
    setImagePreview(null);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { minWidth: "800px", minHeight: "500px" } }}
    >
      <DialogContent sx={{ marginTop: "10px" }}>
        {console.log("image Preview", imagePreview)}
        {console.log("binaryData", binaryData)}
        <AccountCircleIcon sx={{ fontSize: "40px" }} />
        <TextField
          multiline
          rows={8}
          style={{ width: "100%", height: "100%", marginTop: "10px" }}
          InputProps={{ style: { fontSize: "16px" } }}
          placeholder="What do you want to talk about?"
          value={textValue}
          onChange={(e) => {
            setTextValue(e.target.value);
          }}
        />
        <Div sx={{ marginTop: "20px" }}>
          <IconButton
            style={{ width: "50px", height: "50px" }}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <ImageIcon />
            <Input
              type="file"
              inputRef={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </IconButton>
          {imagePreview && (
            <Div sx={{ position: "relative", maxWidth: "30%" }}>
              <img
                src={imagePreview}
                alt="Image Preview"
                style={{ marginTop: "10px", width: "80%" }}
              />
              {isImagePreviewOpen && (
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={handleCloseImagePreview}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Div>
          )}
        </Div>
      </DialogContent>
      <DialogActions>
        <Button className="dialog-cancel-btn" onClick={handleClose}>
          Close
        </Button>
        <Button className="dialog-save-btn" onClick={handlePost}>
          Post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostDialog;
