import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Paper,
  Slide,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import { MdCall, MdEdit, MdEmail, MdSave } from "react-icons/md";
import { FaSave, FaUserCircle } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import Draggable from "react-draggable";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  ButtonStyle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

const MapConfiguration = ({ open3, setOpen3 }) => {
  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [id, setId] = useState("");
  const [mailId, setMailId] = useState("");
  const [mailId1, setMailId1] = useState("");
  const [mailPassword, setMailPassword] = useState("");
  const [apikey, setApikey] = useState("");
  const [clientid, setClientId] = useState("");

  // update mail configuration

  const handleSubmitMailConfigure = (e) => {
    e.preventDefault();
    if (!id) {
      var payload = {
        API_Key: apikey,
        MailId: mailId,
        MailPassword: mailPassword,
        User_Id: parseInt(localStorage.getItem("UserId")),
        Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };
      axios
        .post(`${BASE_URL}/user/MapConfigurationCRUD/`, payload, header)
        .then((res) => {
          //  console.log("res", res);

          if (res?.status === 200 && res.data?.id) {
            toast.success("Map Configured Successfully");
            setOpen3(false);
          } else if (mailId1 === mailId) {
            toast.warning("Your Map is Already Configured");
          } else if (mailId1 != mailId) {
            toast.warning("Please Configure Your Mail Id");
          } else {
            toast.error("Check Your Mail and Password");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    } else {
      var payload = {
        id: id,
        API_Key: apikey,
        MailId: mailId,
        MailPassword: mailPassword,
        Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
        User_Id: parseInt(localStorage.getItem("UserId")),
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };
      axios
        .put(`${BASE_URL}/user/MapConfigurationCRUD/`, payload, header)
        .then((res) => {
          //  console.log("res", res);
          if (res?.status === 201 && res.data?.id) {
            toast.success("Map Configured Updated Successfully");
            setOpen3(false);
          } else if (mailId1 === mailId) {
            toast.warning("Your Mail is Already Configured");
          } else if (mailId1 != mailId) {
            toast.warning("Please Configure Your Mail Id");
          } else {
            toast.error("Check Your Mail and Password");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  useEffect(async () => {
    if (userId) {
      await axios
        .get(`${BASE_URL}/user/MapConfigurationCRUD/?User_Id=${userId}`, header)
        .then((res) => {
          console.log(res);
          if (res) {
            setId(res.data?.id);
            setMailId(res.data?.MailId);
            setMailId1(res.data?.MailId);
            setMailPassword(res.data?.MailPassword);
            setApikey(res.data?.API_Key);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <Dialog open={open3} onClose={() => setOpen3(false)}>
        <form onSubmit={handleSubmitMailConfigure}>
          <Div sx={{ p: 3, width: "500px" }}>
            <Typography variant="h3">Map Configuration</Typography>
            <DialogContent>
              <Grid container>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Mail Id <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box col-12 ps-2"
                      name="mailId"
                      value={mailId}
                      onChange={(e) => setMailId(e.target.value)}
                      placeholder="Enter Your Mail Id"
                      sx={{ minWidth: "350px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Mail Password <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box col-12 ps-2"
                      type={showPassword ? "text" : "password"}
                      name="mailPassword"
                      value={mailPassword}
                      onChange={(e) => setMailPassword(e.target.value)}
                      placeholder="Enter Your Mail Password"
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ minWidth: "300px" }}
                    />
                  </Div>
                </Grid>

                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      API Key <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box col-12 ps-2"
                      name="apikey"
                      value={apikey}
                      onChange={(e) => setApikey(e.target.value)}
                      placeholder="Enter API Key"
                      sx={{ minWidth: "320px" }}
                    />
                  </Div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              {id ? (
                // <Button
                //   type="submit"
                //   sx={[DialogBoxSaveButton, FontStyle, ButtonStyle]}
                // >
                //   Update
                // </Button>
                <CustomButton label="Update" type="submit" Icon="saveIcon" />
              ) : (
                // <Button
                //   type="submit"
                //   sx={[DialogBoxSaveButton, FontStyle, ButtonStyle]}
                // >
                //   Submit
                // </Button>
                <CustomButton label="Submit" type="submit" Icon="saveIcon" />
              )}

              {/* <Button sx={[DialogBoxCancelButton, FontStyle, ButtonStyle]}
              onClick={()=>{
                setOpen3(false);
                
              }}>
                Cancel

              </Button> */}
              <Cancelbutton label="Cancel" onClick={() => setOpen3(false)} />
            </DialogActions>
          </Div>
        </form>
      </Dialog>
    </>
  );
};

export default MapConfiguration;
