import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import { FaFileInvoice } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePayment,
  MdOutlineFileUpload,
} from "react-icons/md";
import { RiRefreshFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { Country } from "country-state-city";
import axios from "axios";
import { apiConfig } from "app/services/config";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import {
  ButtonStyle,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  input_label,
} from "../../widgets/CRMStyles";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
  errorFilter,
  requiredSpan,
} from "app/pages/widgets/StylesComponents";

let Plantype = [{ value: "Paid" }, { value: "Free" }, { value: "TrailPlan" }];

let Module = [
  { value: "Contact" },
  { value: "Lead" },
  { value: "Deal" },
  { value: "Account" },
];

const EditPlan = ({
  setListPlan,
  scrollHeight,
  setEditPlan,
  editdatas,
  getPlanRecords,
}) => {
  let Countries = Country.getAllCountries();

  const [Modaldata, setModaldata] = useState([]);

  const [planid, setplanid] = useState("");
  const [plantype, setplantype] = useState("");
  const [module, setmodule] = useState("");
  const [planname, setplanname] = useState("");
  const [country, setcountry] = useState("");
  const [code, setcode] = useState("");
  const [noofcontact, setnoofcontact] = useState("");
  const [noofmonth, setnoofmonth] = useState("");
  const [price, setprice] = useState("");
  const [storage, setstorage] = useState("");
  const [addstorage, setaddstorage] = useState("");
  const [noofuser, setnoofuser] = useState("");
  const [fees, setfees] = useState("");
  const [plandetails, setplandetails] = useState("");
  const [trailperiod, settrailperiod] = useState();
  const [addondatas, setaddondatas] = useState([]);
  const [addons, setAddons] = useState([]);
  const [save, setSave] = useState(false);

  const [errors, setErrors] = useState({});

  const handleClickBack = () => {
    setplantype("");
    setmodule("");
    setplanname("");
    setcountry("");
    setcode("");
    setnoofcontact("");
    setnoofmonth("");
    setprice("");
    setstorage("");
    setaddstorage("");
    setnoofuser("");
    setfees("");
    setplandetails("");
    setEditPlan(false);
    setListPlan(true);
  };

  // console.log(Countries)

  const handleEditPlan = () => {
    let error = errorFilter({
      plantype,
      planname,
      country,
      noofmonth,
      price,
      code,
      storage,
      noofuser,
      addons,
    });

    if (addons?.length === 0) {
      error.addons = "This field is required";
    }
    setErrors(error);

    if (Object.keys(error)?.length > 0) {
      toast.warning(
        `some fields are empty ${Object.keys(error)?.map((item) => item)}${
          Object.keys(error)?.length > 0 && ", "
        }`,
        {
          position: "top-right",
          autoClose: 500,
        }
      );
    } else {
      let payload = {
        id: editdatas?.id,
        Plan_Id: planid,
        Plan_Name: planname,
        Noofcontacts: 0,
        Addstorage: 0,
        Noofmonths: noofmonth,
        Country: country,
        UnitPrice: price,
        Trialperiod: trailperiod,
        Plantype: plantype,
        Setupfees: fees,
        Currency_Type: code,
        No_Of_Users: noofuser,
        AllocatedStorage: storage,
        // Addstorage: addstorage,
        PlanDetails: plandetails,
        Is_Active_Flg: true,
        AllocatedAddons: addons?.map((addon) => addon),
        // Package_Id: 1,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
      console.log("payload", editdatas, payload);
      axios
        .put(`${BASE_URL}/superadmin/plan/`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("2", res.data);
          if (res) {
            toast.success("Plan is Updated successfully");
            handleClickBack();
            getPlanRecords();
          } else {
            toast.error("Check your data");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Somthing Went Wrong");
        });
    }
  };

  const getaddonrecords = async () => {
    await axios
      .get(BASE_URL + "/superadmin/AddonAdditional/?Is_Deleted=False", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((response) => {
        console.log("Add on Price", response.data);
        setaddondatas(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getaddonrecords();
  }, []);

  useEffect(() => {
    if (Boolean(editdatas)) {
      setAddons(editdatas?.AllocatedAddons);
      setplanid(editdatas?.Plan_Id);
      setplantype(editdatas?.Plantype);
      // setmodule(editdatas?.Modules);
      setplanname(editdatas?.Plan_Name);
      setcountry(editdatas?.Country);
      setcode(editdatas?.Currency_Type);
      // setnoofcontact(editdatas?.Noofcontacts);
      setnoofmonth(editdatas?.Noofmonths);
      setprice(editdatas?.UnitPrice);
      setstorage(editdatas?.AllocatedStorage);
      // setaddstorage(editdatas?.Addstorage);
      setnoofuser(editdatas?.No_Of_Users);
      setfees(editdatas?.Setupfees);
      setplandetails(editdatas?.PlanDetails);
    }
  }, [editdatas]);

  return (
    <>
      <Grid container columns={12} spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Plan Id {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={planid}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="# Plan ID"
            variant="outlined"
          />{" "}
          <div className="red">{errors?.seriesValue}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Plan Type {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className="w-100  ">
            <Autocomplete
              className="search-select3 col-12 w-100"
              options={Plantype}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.value) {
                  return option?.value;
                }
                return "";
              }}
              value={plantype}
              onChange={(item, newvalue) => {
                if (newvalue?.value === "Free") {
                  setfees(0);
                  setprice(0);
                }
                setplantype(newvalue?.value);
              }}
              id="outlined-basic"
              renderInput={(params) => (
                <TextField {...params} placeholder="Plan Type" />
              )}
            />
          </FormControl>{" "}
          <div className="red">{errors?.plantype}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Plan Name {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={planname}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Plan Name"
            variant="outlined"
            onChange={(e) => {
              setplanname(e.target.value);
            }}
          />{" "}
          <div className="red">{errors?.planname}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Country {requiredSpan}</Typography>
        </Grid>
        {console.log("Country : ", country)}

        <Grid item xs={12} md={4}>
          <FormControl className="col-12 w-100">
            <Autocomplete
              className="w-100 search-select3 col-12"
              id="outlined-basic"
              name="country"
              options={Countries}
              getOptionLabel={(option) => option?.name}
              value={Countries.find((c) => c.name === country)}
              defaultValue={country}
              onChange={(item, newvalue) => {
                console.log("countrycountry : ", newvalue?.name);
                setcountry(newvalue?.name);
                setcode(newvalue?.currency);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Country" />
              )}
            />
          </FormControl>{" "}
          <div className="red">{errors?.country}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>
            Country Code {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          {/* <FormControl className="col-12">
            <Autocomplete
              className="w-100 search-select3 col-12 "
              id="outlined-basic"
              name="country"
              options={Countries}
              getOptionLabel={(option) => option?.currency}
              value={Countries.find((item) => item?.currency === code?.currency)}
              onChange={(item, newvalue) => {
                setcode(newvalue?.currency);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Country Code" />
              )}
            />
          </FormControl> */}
          <TextField
            value={code}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Country Code"
            variant="outlined"
            // onChange={(e) => {
            //   setplanname(e.target.value);
            // }}
          />{" "}
          <div className="red">{errors?.code}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>No of Month {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={noofmonth}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            type="number"
            placeholder="No of Month"
            inputProps={{
              min: 0,
            }}
            variant="outlined"
            onChange={(e) => {
              setnoofmonth(e.target.value);
            }}
          />{" "}
          <div className="red">{errors?.noofmonth}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Price {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={price}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Price "
            inputProps={{
              min: 0,
            }}
            type="number"
            variant="outlined"
            disabled={plantype === "Free"}
            onChange={(e) => {
              setprice(e.target.value);
            }}
          />{" "}
          <div className="red">{errors?.price}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Storage {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={storage}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            type="number"
            placeholder="Storage"
            inputProps={{
              min: 0,
            }}
            variant="outlined"
            onChange={(e) => {
              setstorage(e.target.value);
            }}
          />{" "}
          <div className="red">{errors?.storage}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>No of User {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={noofuser}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="No of User"
            type="number"
            variant="outlined"
            inputProps={{
              min: 0,
            }}
            onChange={(e) => {
              setnoofuser(e.target.value);
            }}
          />{" "}
          <div className="red">{errors?.noofuser}</div>
        </Grid>

        {plantype == "Trial Plan" && (
          <Grid item xs={12} md={2}>
            <Typography sx={[input_label]}>
              Trial Period {requiredSpan}
            </Typography>
          </Grid>
        )}

        {plantype == "Trial Plan" && (
          <Grid item xs={12} md={4}>
            <TextField
              value={trailperiod}
              className="w-100 col-12 input-box2"
              id="outlined-basic"
              placeholder="Trial Period"
              variant="outlined"
              onChange={(e) => {
                settrailperiod(e.target.value);
              }}
            />{" "}
            <div className="red">{errors?.trailperiod}</div>
          </Grid>
        )}

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Addons {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl className="col-12">
            <Autocomplete
              className="w-100 search-select3 col-12"
              id="outlined-basic"
              name="addons"
              multiple
              options={addondatas}
              getOptionLabel={(option) => option?.Name || ""}
              value={addons
                ?.map((item) => {
                  return (
                    addondatas?.find((addon) => addon?.id === item) || null
                  );
                })
                .filter((item) => item !== null)}
              onChange={(event, newValue) => {
                setAddons(newValue);
                console.log("Setting", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Addons" />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Setup Fees</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={fees}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Setup Fees"
            type="number"
            variant="outlined"
            inputProps={{
              min: 0,
            }}
            disabled={plantype === "Free"}
            onChange={(e) => {
              setfees(e.target.value);
            }}
          />{" "}
          <div className="red">{errors?.addons}</div>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography sx={[input_label]}>Plan Details</Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            value={plandetails}
            className="w-100 col-12 input-box2"
            id="outlined-basic"
            placeholder="Plan Details"
            variant="outlined"
            onChange={(e) => {
              setplandetails(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Div
        sx={{
          my: 3,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 3,
        }}
      >
        <CustomButton label="Update" Icon="saveIcon" onClick={handleEditPlan} />
        <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
      </Div>

      <DialogBox
        open={save}
        onClickYes={() => {
          setSave(false);
          handleClickBack();
        }}
        onClickNo={() => setSave(false)}
      />
    </>
  );
};

export default EditPlan;
