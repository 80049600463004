import React from "react";
import { useEffect, useRef, useState } from "react";
import Div from "@jumbo/shared/Div";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import {
  Autocomplete,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
  FormControl,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Menu,
} from "@mui/material";
import {
  ButtonStyle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  dialogFontSize,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import {
  Cancelbutton,
  DialogBox,
  CustomButton,
  errorFilter,
  Customgrid,
} from "app/pages/widgets/StylesComponents";

export default function EditDemo({
  openEdit,
  update,
  setOpenEdit,
  getDataList,
  setAnchorElAction,
  anchorElAction,
  scrollHeight,
  id,
}) {
  console.log("updateCheck", update);
  const item = "crmuseradmin1";
  const token = localStorage.getItem("accesstoken");
  // Initialize the Field
  const [demofields, setDemoFields] = useState({
    topic: update?.Demo_topic,
    demoduration: update?.Demo_Duration,
    whatdemo: update?.What_Demo,
    whichdemo: update?.Which_demo,
    demoperson: update?.Who_demo,
    whomtodemo: update?.Whom_to_demo,
    avrgRes: update?.Avg_responsetime,
    feedbackstatus: update?.Feedback_Status,
    ResponseStatus: update?.Response_Status,
    feedback: update?.Feedback,
    response: update?.Response,
    date: update?.Date,
  });

  // For Update Functionality
  const [page, setPage] = useState(1);
  const [closeDemo, setCloseDemo] = useState(false);
  const [errors, setErrors] = useState({});

  const FeedBackStatus = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const Reset = () => {
    setDemoFields({
      ...demofields,
      topic: "",
      demoduration: "",
      whatdemo: "",
      whichdemo: "",
      demoperson: "",
      whomtodemo: "",
      avrgRes: "",
      feedbackstatus: { label: "", value: "" },
      ResponseStatus: { label: "", value: "" },
      feedback: "",
      response: "",
      date: "",
    });
    setErrors({});
  };

  // OnSubmit Functionality
  const FunctionLeadSubmit = async () => {
    let error = errorFilter({
      ...demofields,
      ResponseStatus: demofields?.ResponseStatus?.label,
      feedbackstatus: demofields?.feedbackstatus?.label,
    });

    if (Object.keys(error).length > 0) {
      toast.error("Please Check the all the fields are Filled", {
        position: "top-right",
        autoClose: 700,
      });
    } else {
      const payload = {
        id: update?.id,
        LeadDemo_Id: update?.LeadDemo_Id,
        Demo_topic: demofields?.topic,
        Date: demofields?.date,
        Demo_Duration: demofields?.demoduration,
        What_Demo: demofields?.whatdemo,
        Which_demo: demofields?.whichdemo,
        Who_demo: demofields?.demoperson,
        Whom_to_demo: demofields?.whomtodemo,
        Avg_responsetime: demofields?.avrgRes,
        Feedback_Status: demofields?.feedbackstatus?.value,
        Response_Status: demofields?.ResponseStatus?.value,
        Feedback: demofields?.feedback,
        Response: demofields?.response,
        Lead_Id: Number(id),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };
      console.log("Patch payload : ", payload);

      try {
        await axios
          .put(`${BASE_URL}/user/LeadDemoCRUD/`, payload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("update res", res.data);
            toast.success("Created successfully", {
              position: "top-right",
              autoClose: 100,
            });
            getDataList();
            setOpenEdit(false);
            setAnchorElAction(null);
            Reset();
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      }
    }
    setErrors(error);
  };

  // For Update the Contact
  useEffect(() => {
    setDemoFields({
      ...demofields,
      topic: update?.Demo_topic,
      demoduration: update?.Demo_Duration,
      whatdemo: update?.What_Demo,
      whichdemo: update?.Which_demo,
      demoperson: update?.Who_demo,
      whomtodemo: update?.Whom_to_demo,
      avrgRes: update?.Avg_responsetime,
      feedbackstatus: {
        label: update?.Feedback_Status ? "Yes" : "No",
        value: update?.Feedback_Status,
      },
      ResponseStatus: {
        label: update?.Response_Status ? "Yes" : "No",
        value: update?.Response_Status,
      },
      feedback: update?.Feedback,
      response: update?.Response,
      date: update?.Date,
    });
  }, [update]);

  return (
    <>
      <DialogTitle
        sx={[DisplayFlex, dialogFontSize]}
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        Edit Lead Demo
      </DialogTitle>
      <DialogContent
        sx={{
          pt: 0,
        }}
      >
        <Customgrid columnCount={4}>
          <Grid>
            <Typography sx={[input_label]}>Demo Id</Typography>
          </Grid>

          <Grid item>
            <TextField
              className="input-box2 col-12"
              value={update?.LeadDemo_Id}
              placeholder={"# Demo Id"}
              disabled
            />
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Demo Topic </Typography>
          </Grid>

          <Grid item>
            <TextField
              value={demofields?.topic}
              placeholder={"Topic"}
              className="input-box2 col-12"
              onChange={(e) => {
                setDemoFields({ ...demofields, topic: e.target.value });
              }}
            />
            {errors?.topic && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Demo Topic {errors.topic}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>What Demo </Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"What Demo"}
              className="input-box2 col-12"
              value={demofields?.whatdemo}
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  whatdemo: e.target.value,
                });
              }}
            />
            {errors?.whatdemo && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                What Demo {errors.whatdemo}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Which Demo </Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"Which Demo"}
              className="input-box2 col-12"
              value={demofields?.whichdemo}
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  whichdemo: e.target.value,
                });
              }}
            />
            {errors?.whichdemo && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Which Demo {errors.whichdemo}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Demo Person</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"Demo Person"}
              value={demofields?.demoperson}
              className="input-box2 col-12"
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  demoperson: e.target.value,
                });
              }}
            />
            {errors?.demoperson && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Demo Person {errors.demoperson}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Whom to demo </Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"Whom to demo"}
              value={demofields?.whomtodemo}
              className="input-box2 col-12"
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  whomtodemo: e.target.value,
                });
              }}
            />
            {errors?.whomtodemo && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Whom to demo {errors.whomtodemo}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Demo Duration </Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"Demo Duration"}
              value={demofields?.demoduration}
              className="input-box2 col-12"
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  demoduration: e.target.value,
                });
              }}
              InputProps={{
                endAdornment: (
                  <Typography
                    sx={{
                      paddingLeft: "100px !important",
                      transform: "translateY(5px)",
                    }}
                  >
                    Minutes
                  </Typography>
                ),
              }}
            />
            {errors?.demoduration && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Demo Duration {errors.demoduration}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Average Response Time</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"Average Response Time"}
              value={demofields?.avrgRes}
              className="input-box2 col-12"
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  avrgRes: e.target.value,
                });
              }}
            />
            {errors?.avrgRes && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Average Response Time {errors.avrgRes}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography sx={[input_label]}>Feed Back Status </Typography>
          </Grid>
          <Grid item>
            <Autocomplete
              // {...register('bookingType',)}
              className="search-select3 col-12"
              name="Feed-Back-Status"
              options={FeedBackStatus}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.label) {
                  return option?.label;
                } else {
                  return "";
                }
              }}
              value={demofields?.feedbackstatus.label}
              defaultValue={demofields?.feedbackstatus.label}
              onChange={(e, newValue) => {
                setDemoFields({
                  ...demofields,
                  feedbackstatus: newValue,
                });
              }}
              renderInput={(params) => <TextField {...params} sx={{}} />}
            />
            {errors?.feedbackstatus && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Feed Back Status {errors.feedbackstatus}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Response Status</Typography>
          </Grid>
          <Grid item>
            <Autocomplete
              className="search-select3 col-12"
              name="response-status"
              autocomplete="off"
              options={FeedBackStatus}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option && option?.label) {
                  return option?.label;
                } else {
                  return "";
                }
              }}
              value={demofields?.ResponseStatus?.label}
              defaultValue={demofields?.ResponseStatus?.label}
              onChange={(e, newValue) => {
                setDemoFields({
                  ...demofields,
                  ResponseStatus: newValue,
                });
              }}
              renderInput={(params) => <TextField {...params} sx={{}} />}
            />
            {errors?.ResponseStatus && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Response Status {errors.ResponseStatus}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Feed Back</Typography>
          </Grid>
          <Grid item>
            <TextField
              placeholder={"Feed back"}
              value={demofields?.feedback}
              className="input-box2 col-12"
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  feedback: e.target.value,
                });
              }}
            />
            {errors?.feedback && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Feed Back {errors.feedback}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography sx={[input_label]}>Response</Typography>
          </Grid>
          <Grid item>
            <TextField
              className="input-box2 col-12"
              placeholder={"Response "}
              value={demofields?.response}
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  response: e.target.value,
                });
              }}
            />
            {errors?.response && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Response {errors.response}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography sx={[input_label]}>Date</Typography>
          </Grid>
          <Grid item>
            <TextField
              type="date"
              placeholder={"Whom to demo"}
              className="input-box2 col-12"
              value={demofields?.date}
              onChange={(e) => {
                setDemoFields({
                  ...demofields,
                  date: e.target.value,
                });
              }}
            />
            {errors?.date && (
              <Typography sx={[{ fontSize: "12px", color: "red !important" }]}>
                Date {errors.date}
              </Typography>
            )}
          </Grid>
        </Customgrid>

        <Div sx={[DisplayFlex, { my: 2, justifyContent: "center" }]}>
          <CustomButton
            Icon="saveIcon"
            onClick={() => FunctionLeadSubmit()}
            type="submit"
            label="Update"
          />
          <Cancelbutton
            label="Cancel"
            onClick={() => {
              if (
                demofields?.topic === update?.Demo_topic &&
                demofields?.demoduration === update?.Demo_Duration &&
                demofields?.whatdemo === update?.What_Demo &&
                demofields?.whichdemo === update?.Which_demo &&
                demofields?.demoperson === update?.Who_demo &&
                demofields?.whomtodemo === update?.Whom_to_demo &&
                demofields?.avrgRes === update?.Avg_responsetime &&
                demofields?.feedbackstatus?.label === update?.Feedback_Status
                  ? "Yes"
                  : "No" &&
                    demofields?.ResponseStatus?.label ===
                      update?.Response_Status
                  ? "Yes"
                  : "No" &&
                    demofields?.feedback === update?.Feedback &&
                    demofields?.response === update?.Response &&
                    demofields?.date === update?.Date
              ) {
                setOpenEdit(false);
              } else {
                setCloseDemo(true);
              }
            }}
          />
        </Div>
      </DialogContent>

      {/* close Demo */}
      <DialogBox
        open={closeDemo}
        onClickNo={() => setCloseDemo(false)}
        onClickYes={() => {
          setCloseDemo(false);
          setOpenEdit(false);
          setErrors({});
        }}
      />
    </>
  );
}
