export const mailTemplates = `<pre>
Dear [Customer Name],



             Welcome to the CRM FARM family! We are thrilled to have you on board and excited to introduce you to the comprehensive suite of tools and features our software offers. CRM FARM, developed by Foyer Technology, is designed to revolutionize how you manage your customer relationships and streamline your business operations.



What Can CRM FARM Do for You?


          > Home - Dashboard & Notifications: Your central hub for accessing everything you need. Stay informed with real-time notifications and a customizable dashboard tailored to your businessneeds.

          > Marketing Module: Manage contacts, leads, deals, and campaignswith ease. Our marketing tools help you track customer interactions,automate campaigns, and turn leads into loyal customers.

          > Sales Module: From managing accounts to configuring products, handling quotations, and overseeing the entire sales process, CRM FARM covers every aspect of sales management. Our territory management feature ensures                       that your sales team can focus on the right customers in the right locations.

          > Project Management Module: Effectively plan and execute projects with features like milestones, deliverables, resource management, and task tracking. Keep your projects on time and within budget while ensuring every team                   member is aligned.

          > Task Management: Stay organized with task lists, status updates, Gantt charts, and calendars. CRM FARM makes it easy to prioritize and track progress on all your tasks.

          > Workflow Automation: Streamline your processes with customizable workflows that reduce manual effort and ensure consistency across your operations.

          > Comprehensive Reporting: Gain insights with reports on sales performance, lead and opportunity management, customer engagement, marketing effectiveness, and customer retention. Our new sales funnel reports provide a                     visual breakdown of your sales pipeline, helping you make data driven decisions.

          > Advanced Analytics: Our analytics module offers in-depth views of sales by customer, activity overview, sales goals, lead sources, call durations, and much more. The sales KPI dashboard provides aquick overview of your key                         performance indicators, helping you stayon track.

          > Custom Form Module: Create customized forms to capture exactly the data you need, whether it is customer details, vendor information, or employee records.

          > Customer Engagement: Enhance your relationship with your customers through our dedicated customer engagement module, designed to help you deliver personalized experiences and build long-lasting connections.



Why Choose CRM FARM?


              CRM FARM isn’t just another CRM tool; it’s a powerful solution designed to grow with your business. Our software is built to handle the unique challenges of your industry, helping you drive efficiency, improve customer satisfaction, and ultimately, boost your bottom line.


              Thank you for choosing CRM FARM. We are committed to your success and look forward to supporting you on this journey. Should you have any questions or need assistance, our support team is just a click away.


Warm regards,


[Your Name]
[Your Position]
Foyer Technology
[Contact Information]
[Website URL]
</pre>`;
