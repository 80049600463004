import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ButtonGroup,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { TiCancel } from "react-icons/ti";
import { BiArrowBack, BiSkipNext } from "react-icons/bi";
import React, { useEffect, useContext, useCallback, useState } from "react";
import Imag1 from "../../img/Image1.png";
import Imag2 from "../../img/Image2.png";
import Imag3 from "../../img/Image3.png";
import Imag4 from "../../img/Image4.png";
import Imag5 from "../../img/Image5.png";
import Imag6 from "../../img/Image6.png";
import Imag7 from "../../img/Image7.png";
import Imag8 from "../../img/Image8.png";
import Imag9 from "../../img/Image9.png";
import Imag10 from "../../img/Image10.png";
import Imag11 from "../../img/Image11.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { HiOutlineSearch } from "react-icons/hi";
import Div from "@jumbo/shared/Div/Div";
import { FaFlagCheckered } from "react-icons/fa";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { EditIcon } from "app/icons/PngIcons";
import CampaignEditTemplate from "./CampaignEditTemplate";
import { CheckCircleOutline, Subject } from "@mui/icons-material";
import { HiOutlineLocationMarker } from "react-icons/hi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  CloseIcon,
  MailIcon,
  PhoneIcon,
  SettingsIcon,
} from "app/icons/PngIcons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import "react-toastify/dist/ReactToastify.css";
import CampaignContactList from "./CampaignContactList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import { Navigate } from "react-router-dom";
import {
  ButtonStyle,
  CRMTableHead,
  componentTitle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  PrimaryColor,
  UploadBtn,
  Colors,
} from "app/pages/widgets/CRMStyles";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { format, subDays } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import { AutoHeight } from "app/redux/auth/localStorage";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
} from "app/pages/widgets/StylesComponents";

function createData(phone, email, leadscore, leadsource) {
  return { phone, email, leadscore, leadsource };
}

const CreateDraftCampaign = ({
  scrollHeight,
  setOpenEditDraftCampaign,
  openEditDraftCampaign,
  update,
  getMainData,
  DraftData,
  setTabValue

}) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["SetUp", "Design", "Recipient", "Confirmation"];

  // For Creating the Payload First Page

  let userDetails = localStorage.getItem("UserDetails");
  userDetails = JSON.parse(userDetails);

  const [campaignname, setCampaignName] = useState(update?.Campaign_Name);
  const [previewtext, setPreviewtext] = useState(update?.Preview_text);
  const [campaignid, setCampaignid] = useState(update?.Campaign_Id);
  const [subjectline, setSubjectline] = useState(update?.Subject_Line);

  const [fromname, setFromName] = useState(
    `${userDetails && userDetails?.username}`
  );
  const [frommail, setFromMail] = useState(
    `${userDetails && userDetails?.email}`
  );
  const [tempid, setTempid] = useState(update.Template_Id);
  const [id, setId] = useState(1);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [allLocationList, setAllLocationList] = useState([]);
  const [save, setSave] = useState(false);



  const [listPage, setListPage] = useState(true);
  const [contactpage, setContactpage] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [transfer, setTransfer] = useState("");

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  // For getting the Templates
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [tempcontent, setTempContent] = useState([]);

  const fetchTemplates = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/useradmin/CRMUserCampaignTemplate/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("templates", res.data);

      setTemplates(res.data);

      setSelectedTemplates([
        res.data?.find((tem) => tem.id === Number(update.Template_Id)),
      ]);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  // Checkig the Template

  console.log("selectedTemplates", selectedTemplates);
  // Email contents Modifyion when we click on that tim
  const replacements = {
    "[Your Name]": userDetails?.username || "no name",
    "[Your Position/Title]": "Software Engineer Trainee",
    "[Your Company]": "Foyer",
    "[Contact Number]": "9965808178",
    "[Email Address]": userDetails?.email || "no email",
    "[Website]": "Foyertech.com",
    "[website link]": "wwww.foyertech.com",
    "[Contact Name]": "Sir/Madam",
  };

  const applyReplacements = (content) => {
    let modifiedContent = content;
    for (const [placeholder, value] of Object.entries(replacements)) {
      modifiedContent = modifiedContent.split(placeholder).join(value);
    }
    console.log("Replacements applied", modifiedContent);
    return modifiedContent;
  };

  const handleTemplateClick = (template) => {
    const decodedContent = template?.Template_Content?.replace(
      /&#(\d+);/g,
      (match, dec) => String.fromCharCode(dec)
    );
    setTempContent([applyReplacements(decodedContent)]);
    setSelectedTemplates([template]); // Select only the clicked template
    setTempid(template.id);
    console.log("template", template);
  };

  // Getting the Lookup data
  const [lookupdata, setLookupdata] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      response.data?.find((op) => {
        if (op?.Lookupname === "Industry Type") {
          setLookupdata(op?.Values?.map((v) => v?.value) || []);
        }
      });
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed s(e.g., show an error message to the user)
    }
  };

  // For Create Contact List Functionlaity and States
  const [openCreateList, setOpenCreateList] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [industry, setIndustry] = useState("");

  const nameRegex = /^(?!\s*$)(?!null$)(?!undefined$).+/;
  const handleSubmit = async (e) => {
    e.preventDefault();
    let dubListName = true;
    contactdata?.some((contact) => {
      if (contact?.ListName?.toLowerCase() === name?.toLowerCase()) {
        dubListName = false;
      }
    });
    if (dubListName) {
      if (
        nameRegex.test(name) &&
        nameRegex.test(industry) &&
        nameRegex.test(location)
      ) {
        try {
          var payload = {
            ListName: name,
            Industry: industry,
            Location: location,
            Organization_Id: localStorage.getItem("OrganizationId"),
            Created_By: localStorage.getItem("UserId"),
            Updated_By: localStorage.getItem("UserId"),
          };
          console.log("payload", payload);
          const response = await axios.post(
            `${BASE_URL}/user/UserContactListCRUD/`,
            payload,
            {
              headers: {
                db: item,
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          console.log("res.data.results", response.data);
          if (response) {
            setName("");
            setLocation("");
            setIndustry("");

            setOpenCreateList(false);
            toast.success("Created successfully", {
              position: "top-right",
              autoClose: 100,
            });
            getContactData();
          }
        } catch (error) {
          console.error("An error occurred:", error.message);
          if (error.response && error.response.status === 403) {
            const errorMessage =
              error.response.data.details ||
              "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        }
      } else {
        toast.error("some fields are empty", {
          position: "top-right",
          autoClose: 100,
        });
      }
    } else {
      toast.error(`${name} already exists in the list`, {
        position: "top-right",
        autoClose: 100,
      });
    }
  };

  // delete contact list
  const deleteContactList = async (e) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/user/UserContactListCRUD/?id=${e}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response) {
        toast.success("delete successfully", {
          position: "top-right",
          autoClose: 100,
        });
      }
      getContactData();
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // For Getting the Sender Mail Ids

  const [selectedContactLists, setSelectedContactLists] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [ListName, setListName] = useState("");

  const handleContactListCheckboxClick = (contactList) => {
    const isSelected = selectedContactLists.includes(contactList.id);
    setListName(contactList?.ListName);
    if (isSelected) {
      setSelectedContactLists((prevSelected) =>
        prevSelected.filter((id) => id !== contactList.id)
      );
    } else {
      console.log("contactList.id", contactList);
      setSelectedContactLists((prevSelected) => {
        const updatedSelected = [...prevSelected, contactList.id];
        getEmailsFromSelectedContactLists(updatedSelected, contactList); // Call this function after updating the state
        return updatedSelected;
      });
    }
  };

  // Function to get all email addresses from selected contact lists
  const getEmailsFromSelectedContactLists = (updatedSelected, contactList) => {
    const selectedEmails1 = [];
    console.log("chc", contactList);
    updatedSelected.forEach((selectedId) => {
      const selectedContactList = contactdata.find(
        (list) => list.id === selectedId
      );

      if (selectedContactList) {
        selectedContactList.Contact.forEach((contact) => {
          selectedEmails1.push({ email: contact.Email_Id, checked: true });
        });
      }
    });
    setSelectedEmails(selectedEmails1);
  };

  // Getting the Contact List Based on the Location

  const [contactdata, setContactdata] = useState([]);

  const getContactData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserContactListAllAdditional/?Is_Deleted=False&Created_By=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // Uncomment the console.log line if you want to log the response data
      console.log("Contact List", response.data.results);
      console.log("Contact_Id", update.Contact_Id);
      setContactdata(response.data.results);
      console.log(
        "response?.data?.results?.find((temp)=>temp.id === Number(1))"
      );
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  var todaydate = moment(
    `${update.Schedule_Date}T${update.Schedule_Time}`
  ).format("YYYY-MM-DDTHH:mm:ss");

  // For Scheduling purpose
  const [isCollapsed, setCollapsed] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(`${todaydate}`);
  const [scheduleOption, setScheduleOption] = useState("now");

  const yesterdayDateTime = format(
    subDays(new Date(), 0),
    "yyyy-MM-dd'T'HH:mm:ss"
  );

  const handleScheduleOptionChange = (event) => {
    setScheduleOption(event.target.value);
    if (event.target.value === "now") {
      setScheduleDate(yesterdayDateTime);
    }
  };

  const handleScheduleButtonClick = () => {
    // Handle scheduling logic here based on scheduleOption and scheduleDate
    // You can perform actions like scheduling a campaign
    console.log("Scheduled for:", scheduleOption, scheduleDate);
    setCollapsed(false);
  };

  // For Routing in the handle Click button

  const [showdraft, setShowDraft] = useState(false);

  const navigate = useNavigate();

  // back button
  const handleClickBack = () => {
    setActiveStep(activeStep - 1);
    if (activeStep === 0) {
      setActiveStep(0);
      setShowDraft(true);
    } else if (activeStep === 2) {
      setShowDraft(false);
      setContactpage(false);
    } else {
      setShowDraft(false);
      setContactpage(false);
    }
  };

  // next button
  const handleClickNext = () => {
    if (activeStep === 0) {
      if (
        campaignname === "" ||
        // previewtext === "" ||
        subjectline === "" ||
        fromname === "" ||
        frommail === ""
      ) {
        toast.error("Please Check the Data Entered", {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        console.log("check the data");
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 1) {
      if (
        selectedTemplates === "" ||
        selectedTemplates.length === 0 ||
        selectedTemplates === undefined
      ) {
        toast.error("Please Check the template selected", {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 2) {
      if (
        selectedContactLists === "" ||
        selectedContactLists.length === 0 ||
        selectedContactLists === undefined
      ) {
        toast.error("Please Check the contact list selected", {
          position: "top-right",
          autoClose: 100,
        });
      } else if (activeStep === 2) {
        setShowDraft(false);
        setContactpage(true);
      } else {
        setActiveStep(activeStep + 1);
        setContactpage(false);
      }
    }
    if (activeStep === 3) {
      setActiveStep(3);
      campaignSubmit();
    }
  };

  const campaignSubmit = async () => {
    //  console.log("Template_Content",selectedTemplates[0].Template_Content)

    let checkedEmails = [];
    selectedEmails?.map((element) => {
      if (element.checked === true) {
        checkedEmails.push(element?.email);
      }
    });

    try {
      let payload = {
        Subject: Subject,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Contact_Id: selectedContactLists,
        Campaign_Name: campaignname,
        Subject_Line: subjectline,
        Preview_text: previewtext,
        From_Email: frommail,
        Template_Id: tempid,
        Schedule_Date: scheduleDate.split("T")[0],
        Schedule_Time: scheduleDate.split("T")[1],
        Template_body: tempcontent,
        From_Name: fromname,
        Recipients: checkedEmails,
        id: id,
      };
      console.log("payload", payload);
      const response = await axios.post(
        `${BASE_URL}/user/UserCampaignCRUD/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      toast.success("Created successfully", {
        position: "top-right",
        autoClose: 100,
      });
      getMainData(1);
      DraftData(1);
      console.log("response", response.data);

      if (response) {
        axios
          .delete(`${BASE_URL}/user/UserDraftCampaignCRUD/?id=${update.id}`, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            getMainData();

            DraftData();
            setOpenEditDraftCampaign(false);
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              const errorMessage =
                error.response.data.details ||
                "You don't have permission to do it";
              console.log(errorMessage);
              toast.error(errorMessage, {
                position: "top-right",
                autoClose: 100,
              });
            } else {
              toast.error(`${error}`, {
                position: "top-right",
                autoClose: 100,
              });
            }
          });
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // Saving the Datas into the Draft
  const DraftSubmit = async () => {
    let checkedEmails = selectedEmails?.map((element) => {
      if (element.checked) {
        return element?.email;
      }
    });
    try {
      var payload = {
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Template_Id: id,
        Campaign_Name: campaignname,
        Subject_Line: subjectline,
        Preview_text: previewtext,
        From_Email: frommail,
        Schedule_Date: scheduleDate.split("T")[0] || todaydate.split("T")[0],
        Schedule_Time: scheduleDate.split("T")[1] || todaydate.split("T")[1],
        Template_body: tempcontent || "",
        From_Name: fromname,
        Recipients: checkedEmails || "",
      };
      console.log("payload", payload);
      const response = await axios.post(
        `${BASE_URL}/user/UserDraftCampaignCRUD/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("res.data.results", response.data);
      setOpenEditDraftCampaign(false);
      DraftData();
      toast.success("Draft Saved Successfully", {
        position: "top-right",
        autoClose: 100,
      });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // get all contact locations
  const allContactLocations = async () => {
    try {
      axios
        .get(`${BASE_URL}/user/ContactAdditional/?Is_Deleted=False`, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          let localLocal = [];

          if (res.data.results?.length > 0) {
            res.data.results?.forEach((result) => {
              if (!localLocal?.includes(result?.City)) {
                localLocal.push(result?.City);
              }
            });

            console.log("results in contacts", localLocal);
            setAllLocationList(localLocal);
          }
        });
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };


  useEffect(() => {
    fetchTemplates();
    getlookupData();
    allContactLocations();
  }, []);



  useEffect(() => {
    getContactData();
    let extractedEmails = update?.Recipients?.match(
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g
    );
    let email = [];
    extractedEmails?.map((ema) => {
      email.push({ email: ema, checked: true });
    });
    console.log("emailsss", update);
    setSelectedEmails(email);
    setSelectedContactLists(update?.Contact_Id || []);
  }, []);

  const [mailCount, setMailCount] = useState(0);
  useEffect(() => {
    let checkedCount = selectedEmails?.filter(
      (element) => element?.checked
    ).length;
    setMailCount(checkedCount);
  }, [selectedEmails]);



  useEffect(() => {
    console.log("updateDatasd", update)
    setCampaignName(update.Campaign_Name);
    setPreviewtext(update.Preview_text);
    setSubjectline(update.Subject_Line);
    setFromName(update.From_Name);
    setFromMail(update.From_Email);
    setCampaignid(update.DraftCampaign_Id);
    setTempid(update.Template_Id);
  }, [update]);



  return (
    <>
      <Box
        sx={[
          DisplayFlex,
          {
            justifyContent: "center",
            display: listPage === true ? "block" : "none",
          },
        ]}
      >
        <Box sx={{ width: { md: "60%" } }}>
          <Div sx={{ display: listPage === true ? "block" : "none" }}>
            <form onSubmit={campaignSubmit}>
              <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
                <Typography id="Typography-root" sx={[componentTitle]}>
                  Set up your campaign
                </Typography>
                <div style={{ margin: "10px 0px" }} />

                <Div
                  sx={{
                    height: { xs: "100%", md: window.innerHeight / 2 },
                    minHeight: "250px",
                  }}
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        minHeight: "200px",
                        justifyContent: "center !important",
                      },
                    ]}
                  >
                    <Grid container spacing={3}>
                      <Grid item container md={6} lg={6} xs={12}>
                        <Grid item md={4} lg={4} xs={12}>
                          <Typography id="Typography-root" sx={[FontStyle]}>
                            Draft Campaign Id
                          </Typography>
                        </Grid>
                        <Grid item md={8} lg={8} xs={12}>
                          <TextField
                            sx={{
                              "& fieldset": {
                                borderRadius: "3px",
                              },
                            }}
                            value={update?.DraftCampaign_Id}
                            className="col-8 input-box"
                            placeholder="# Draft Campaign Id"
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                      </Grid>

                      <Grid item container md={6} lg={6} xs={12}>
                        <Grid item md={4} lg={4} xs={12}>
                          <Typography id="Typography-root" sx={[FontStyle]}>
                            Campaign Name
                            <span style={{ color: "rgba(255, 18, 18, 1)" }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item md={8} lg={8} xs={12}>
                          <TextField
                            sx={{
                              "& fieldset": {
                                borderRadius: "3px",
                              },
                            }}
                            value={campaignname}
                            onChange={(e) => {
                              setCampaignName(e.target.value);
                            }}
                            className="col-8 input-box"
                            placeholder="Enter your Campaign Name"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item container md={6} lg={6} xs={12}>
                        <Grid item md={4} lg={4} xs={12}>
                          <Typography id="Typography-root" sx={[FontStyle]}>
                            Subject Line{" "}
                            <span style={{ color: "rgba(255, 18, 18, 1)" }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item md={8} lg={8} xs={12}>
                          <TextField
                            sx={{
                              "& fieldset": {
                                borderRadius: "3px",
                              },
                            }}
                            value={subjectline}
                            onChange={(e) => {
                              setSubjectline(e.target.value);
                            }}
                            className="col-8 input-box"
                            placeholder="Enter Subject line for the email content which the recipient sees first "
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item container md={6} lg={6} xs={12}>
                        <Grid item md={4} lg={4} xs={12}>
                          <Typography id="Typography-root" sx={[FontStyle]}>
                            Preview text{" "}
                            <span style={{ color: "rgba(255, 18, 18, 1)" }}>
                              *
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item md={8} lg={8} xs={12}>
                          <TextField
                            sx={{
                              "& fieldset": {
                                borderRadius: "3px",
                              },
                            }}
                            value={previewtext}
                            onChange={(e) => {
                              setPreviewtext(e.target.value);
                            }}
                            className="col-8 input-box"
                            placeholder="Enter a short note to give an overview of the email content"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item container md={6} lg={6} xs={12}>
                        <Grid item md={4} lg={4} xs={12}>
                          <Typography id="Typography-root" sx={[FontStyle]}>
                            From Email
                          </Typography>
                        </Grid>
                        <Grid item md={8} lg={8} xs={12}>
                          <TextField
                            sx={{
                              "& fieldset": {
                                borderRadius: "3px",
                              },
                            }}
                            value={frommail}
                            className="col-8 input-box"
                            placeholder="Enter the Mail"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid item container md={6} lg={6} xs={12}>
                        <Grid item md={4} lg={4} xs={12}>
                          <Typography id="Typography-root" sx={[FontStyle]}>
                            From Name
                          </Typography>
                        </Grid>
                        <Grid item md={8} lg={8} xs={12}>
                          <TextField
                            sx={{
                              "& fieldset": {
                                borderRadius: "3px",
                              },
                            }}
                            value={fromname}
                            className="col-8 input-box"
                            placeholder="Enter the Name"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Div>
                </Div>
              </Box>

              <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
                <Div
                  sx={{
                    height: { xs: "100%", md: window.innerHeight / 2 },
                    minHeight: "250px",
                  }}
                >
                  <Typography
                    id="Typography-root"
                    align="left"
                    sx={[componentTitle]}
                  >
                    Mail Content
                  </Typography>

                  <Div>
                    <Typography
                      id="Typography-root"
                      // onClick={handleCLickTempGal}
                      sx={[
                        FontStyle,
                        {
                          color: Colors?.Mblue,
                          cursor: "pointer",
                          mt: 2,
                        },
                      ]}
                    >
                      All Template
                    </Typography>
                  </Div>
                  <Stack mt={2}>
                    {/* My Templates Section */}
                    {templates && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            height: { xs: "100%", md: window.innerHeight / 1.5 },
                            minHeight: "200px",
                          }}
                        >
                          {templates &&
                            templates?.map((template) => (
                              <Grid
                                item
                                key={template.id}
                                lg={2}
                                md={2}
                                xs={6}
                                xl={2}
                              >
                                <Paper
                                  sx={{
                                    height: "150px",
                                    minWidth: "50px",
                                    width: "150px",
                                    p: 2,
                                    position: "relative",
                                  }}
                                >
                                  <Div sx={{ textAlign: "center" }}>
                                    <img
                                      style={{ pl: "-10px !important" }}
                                      src="/images/icon-docs.png"
                                      width={50}
                                      height={50}
                                      alt="crm"
                                    />

                                    <Div sx={[FontStyle, {}]}>
                                      {template?.Template_Name}
                                    </Div>
                                  </Div>
                                  <Div sx={{ textAlign: "center", mt: 2 }}>
                                    <CustomButton
                                      label="Use"
                                      Icon=""
                                      onClick={() => {
                                        setListPage(false);
                                        setEditPage(true);
                                        setTransfer(template);
                                      }}
                                    />

                                    <FormControlLabel
                                      sx={{
                                        position: "absolute",
                                        top: -10,
                                        left: 0,
                                      }}
                                      control={
                                        <Checkbox
                                          color="info"
                                          checked={selectedTemplates?.some(
                                            (t) => t?.id === template?.id
                                          )}
                                          onChange={() =>
                                            handleTemplateClick(template)
                                          }
                                        />
                                      }
                                    />
                                    {/* // <CheckCircleOutline sx={{color:"green",position:"absolute",top:1,left:1}} /> // Display a checkmark icon for selected items */}
                                  </Div>
                                </Paper>
                              </Grid>
                            ))}
                        </Grid>
                      </>
                    )}
                  </Stack>
                </Div>
              </Box>

              <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
                <Div
                  sx={{
                    height: { xs: "100%", md: window.innerHeight / 2 },
                    minHeight: "250px",
                  }}
                >
                  <Div
                    sx={[
                      DisplayFlex,
                      {
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        width: "100%",
                      },
                    ]}
                  >
                    <Typography
                      id="Typography-root"
                      align="left"
                      sx={[componentTitle]}
                    >
                      Choose your contact list(s)
                    </Typography>

                    <CustomButton
                      label="Create List"
                      Icon="createIcon"
                      width={"130px"}
                      onClick={() => setOpenCreateList(true)}
                    />
                  </Div>

                  <Card
                    sx={{
                      border: "0px solid white",
                      marginTop: "20px",

                      // marginLeft: { md: "30px" },
                      // marginRight: { md: "80px" },
                    }}
                  >
                    <JumboScrollbar
                      autoHeight={true}
                      autoHideTimeout={4000}
                      autoHeightMin={window.innerHeight / 1.5}
                      autoHide={true}
                      hideTracksWhenNotNeeded
                    // id="no-more-tables"
                    >
                      <Table>
                        <TableHead
                          sx={[
                            CRMTableHead,
                            FontStyle,
                            { p: { xs: 0.5, sm: 1 } },
                          ]}
                        >
                          <CRMTableRow>
                            <CRMTableCell align="left">List Name</CRMTableCell>

                            <CRMTableCell align="left">Location</CRMTableCell>
                            <CRMTableCell align="left">Contacts</CRMTableCell>

                            <CRMTableCell align="center">Del</CRMTableCell>
                          </CRMTableRow>
                        </TableHead>
                        <TableBody>
                          {contactdata &&
                            contactdata?.map((row, index) => (
                              <CRMTableRow key={index}>
                                <CRMTableCell align="left">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color="info"
                                        checked={selectedContactLists?.includes(
                                          row?.id
                                        )}
                                        onChange={() => {
                                          handleContactListCheckboxClick(row);
                                        }}
                                      />
                                    }
                                    label={
                                      <>
                                        <Typography
                                          id="Typography-root"
                                          sx={{
                                            display: {
                                              xs: "none",
                                              sm: "block",
                                            },
                                            color: Colors?.Mblue,
                                          }}
                                        >
                                          {row?.ListName}
                                        </Typography>
                                        <Typography
                                          id="Typography-root"
                                          sx={{
                                            display: {
                                              xs: "block",
                                              sm: "none",
                                            },
                                          }}
                                        >
                                          {row?.ListName?.length > 7
                                            ? row?.ListName?.substring(0, 7) +
                                            "..."
                                            : row?.ListName}
                                        </Typography>
                                      </>
                                    }
                                  />
                                </CRMTableCell>
                                <CRMTableCell align="left">
                                  {row?.Location}
                                </CRMTableCell>
                                <CRMTableCell align="left">
                                  {row?.TotalLeads}
                                </CRMTableCell>
                                <CRMTableCell align="center">
                                  <IconButton
                                    onClick={() => setDeleteDialog(row?.id)}
                                  >
                                    <DeleteIcon
                                      sx={{ color: "red", fontSize: 16 }}
                                    />
                                  </IconButton>
                                </CRMTableCell>
                              </CRMTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </JumboScrollbar>
                  </Card>
                </Div>
              </Box>

              <Box
                sx={{
                  display: activeStep === 3 ? "block" : "none",
                  // height: window.innerHeight/1.45,
                  minHeight: '100%'
                }}
              >
                <Div sx={[DisplayFlex, { justifyContent: "space-between" }]}>
                  <Typography
                    id="Typography-root"
                    align="left"
                    sx={[componentTitle]}
                  >
                    Confirmation
                  </Typography>

                  <Div sx={{ position: "relative" }}>
                    <CustomButton
                      label="Schedule"
                      Icon=""
                      onClick={() => {
                        setCollapsed(!isCollapsed);
                      }}
                    />
                    <Div
                      className="card filter-box1 mt-1"
                      id="openFilter"
                      sx={{
                        p: 1.5,
                        position: "absolute",
                        right: 0,
                        top: 30,
                        transition: "all 1s ease-in-out",
                        minWidth: "300px",
                        zIndex: 1000,
                        fontWeight: 700,
                        display: isCollapsed === true ? `block` : `none`,
                      }}
                    >
                      <Typography sx={[FontStyle]}>
                        Would you like to schedule this campaign?
                      </Typography>
                      <RadioGroup
                        value={scheduleOption}
                        onChange={handleScheduleOptionChange}
                      >
                        <FormControlLabel
                          value="now"
                          control={<Radio color="info" />}
                          label={
                            <Typography id="Typography-root" sx={[FontStyle]}>
                              Now
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="later"
                          control={<Radio color="info" />}
                          label={
                            <Typography id="Typography-root" sx={[FontStyle]}>
                              Schedule Later
                            </Typography>
                          }
                        />
                      </RadioGroup>
                      {scheduleOption === "later" && (
                        <TextField
                          sx={{ my: 1 }}
                          id="scheduleDate"
                          type="datetime-local"
                          value={scheduleDate}
                          className="input-box col-12"
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            setScheduleDate(e.target.value);
                          }}
                          InputLabelProps={{
                            step: 1,
                          }}
                        />
                      )}

                      <Div sx={[DisplayFlex, { justifyContent: "center" }]}>
                        <CustomButton
                          label="Schedule"
                          Icon=""
                          onClick={handleScheduleButtonClick}
                        />
                      </Div>
                    </Div>
                  </Div>
                </Div>

                {/* Setup Table */}
                <Table sx={{ mt: 2 }}>
                  <TableHead sx={[CRMTableHead, FontStyle]}>
                    <CRMTableRow>
                      <CRMTableCell align="left">
                        <CheckCircleIcon
                          sx={{ color: "green", width: "22px", height: "22px" }}
                        />{" "}
                        Setup
                      </CRMTableCell>
                      <CRMTableCell align="left"></CRMTableCell>
                      <CRMTableCell align="right">
                        <Typography
                          id="Typography-root"
                          onClick={() => setActiveStep(0)}
                          sx={{ mr: 2, color: PrimaryColor, cursor: "pointer" }}
                        >
                          Return to this step
                        </Typography>
                      </CRMTableCell>
                    </CRMTableRow>
                  </TableHead>
                  <TableBody>
                    <CRMTableRow>
                      <CRMTableCell align="left">Subject</CRMTableCell>
                      <CRMTableCell align="left">:&nbsp;</CRMTableCell>
                      <CRMTableCell align="left">{subjectline}</CRMTableCell>
                    </CRMTableRow>

                    <CRMTableRow>
                      <CRMTableCell align="left">From </CRMTableCell>
                      <CRMTableCell align="left">:</CRMTableCell>
                      <CRMTableCell align="left">{frommail} </CRMTableCell>
                    </CRMTableRow>

                    <CRMTableRow>
                      <CRMTableCell align="left">Content</CRMTableCell>
                      <CRMTableCell align="left">:</CRMTableCell>
                      <CRMTableCell align="left">{previewtext}</CRMTableCell>
                    </CRMTableRow>
                  </TableBody>
                </Table>

                {/* Design Table */}
                <Table sx={{ mt: 2 }}>
                  <TableHead sx={[CRMTableHead, FontStyle]}>
                    <CRMTableRow>
                      <CRMTableCell align="left">
                        <Div
                          sx={[
                            DisplayFlex,
                            { justifyContent: "space-between" },
                          ]}
                        >
                          <Div sx={[FontStyle]}>
                            <CheckCircleIcon
                              sx={{
                                color: "green",
                                width: "22px",
                                height: "22px",
                              }}
                            />{" "}
                            Design
                          </Div>
                          <Typography
                            id="Typography-root"
                            onClick={() => setActiveStep(1)}
                            sx={{
                              mr: 2,
                              color: PrimaryColor,
                              cursor: "pointer",
                            }}
                          >
                            Return to this step
                          </Typography>
                        </Div>
                      </CRMTableCell>
                    </CRMTableRow>
                  </TableHead>
                  <JumboScrollbar
                    autoHeight={true}
                    autoHideTimeout={4000}
                    autoHeightMin={scrollHeight ? scrollHeight : 250}
                    autoHide={true}
                    hideTracksWhenNotNeeded
                  // id="no-more-tables"
                  >
                    <TableBody>
                      <CRMTableRow>
                        <CRMTableCell align="left">
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { padding: "10px !important" }]}
                            dangerouslySetInnerHTML={{ __html: tempcontent }}
                          ></Typography>
                        </CRMTableCell>
                      </CRMTableRow>
                    </TableBody>
                  </JumboScrollbar>
                </Table>

                {/* Recipient table */}
                <Table sx={{ mt: 2 }}>
                  <TableHead sx={[CRMTableHead, FontStyle]}>
                    <CRMTableRow>
                      <CRMTableCell align="left">
                        <Div
                          sx={[
                            DisplayFlex,
                            { justifyContent: "space-between" },
                          ]}
                        >
                          <Div sx={[FontStyle]}>
                            <CheckCircleIcon
                              sx={{
                                color: "green",
                                width: "22px",
                                height: "22px",
                              }}
                            />{" "}
                            Recipients
                          </Div>
                        </Div>
                      </CRMTableCell>
                      <CRMTableCell align="left"></CRMTableCell>
                      <CRMTableCell align="right">
                        <Typography
                          id="Typography-root"
                          onClick={() => setActiveStep(2)}
                          sx={{ mr: 2, color: PrimaryColor, cursor: "pointer" }}
                        >
                          Return to this step
                        </Typography>
                      </CRMTableCell>
                      {/* <CRMTableCell></CRMTableCell> */}
                    </CRMTableRow>
                  </TableHead>
                  <TableBody>
                    <CRMTableRow>
                      <CRMTableCell align="left">Mail Lists</CRMTableCell>
                      <CRMTableCell align="left">:&nbsp;</CRMTableCell>
                      <CRMTableCell align="left">
                        <JumboScrollbar
                          autoHeight={true}
                          autoHideTimeout={4000}
                          autoHeightMax={scrollHeight ? scrollHeight : 150}
                          autoHide={true}
                          hideTracksWhenNotNeeded
                        // id="no-more-tables"
                        >
                          {selectedEmails &&
                            selectedEmails?.map((data, index) => {
                              if (data?.checked === true) {
                                return (
                                  <Typography
                                    key={index}
                                    sx={[
                                      FontStyle,
                                      {
                                        display: "inline-flex",
                                        flexWrap: "wrap",
                                      },
                                    ]}
                                  >
                                    {data?.email}
                                    {selectedEmails?.length > 1 && ", "}&nbsp;
                                  </Typography>
                                );
                              }
                            })}
                        </JumboScrollbar>
                      </CRMTableCell>
                    </CRMTableRow>

                    <CRMTableRow>
                      <CRMTableCell align="left">Segment </CRMTableCell>
                      <CRMTableCell align="left">:</CRMTableCell>
                      <CRMTableCell align="left">
                        [Unique Email readers whenever]
                      </CRMTableCell>
                    </CRMTableRow>
                  </TableBody>
                </Table>
              </Box>

              {/* New Bottom Design */}
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  md={1.5}
                  sx={[
                    DisplayFlex,
                    { flexWrap: "wrap", justifyContent: "left" },
                  ]}
                  justifyContent={"left"}
                >
                  <Cancelbutton
                    Icon={activeStep === 0 ? "closeIcon" : "backIcon"}
                    onClick={() => handleClickBack()}
                    label={activeStep === 0 ? "Cancel" : "Back"}
                  />
                </Grid>

                <Grid item xs={12} md={9} justifyContent={"center"}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps?.map((label, index) => {
                      return (
                        <Step key={index}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={1.5}
                  sx={[
                    DisplayFlex,
                    { flexWrap: "wrap", justifyContent: "right" },
                  ]}
                  justifyContent={"right"}
                >
                  <CustomButton
                    Icon={
                      activeStep === steps.length - 1 ? "saveIcon" : "nextIcon"
                    }
                    onClick={() => handleClickNext()}
                    label={activeStep === steps.length - 1 ? "Finish" : "Next"}
                  />
                </Grid>
              </Grid>
            </form>
          </Div>
          <>
            {editPage && (
              <CampaignEditTemplate
                setListPage={setListPage}
                setEditPage={setEditPage}
                transfer={transfer}
              />
            )}
          </>
          <>
            {/* contactpage  */}
            <DialogBox
              Title={ListName}
              Content={
                <>
                  <Div>
                    <Typography
                      sx={[
                        FontStyle,
                        {
                          color:
                            mailCount > 39
                              ? "tomato !important"
                              : "black !important",
                        },
                      ]}
                    >
                      Select Mails ( {mailCount} / 40 )
                    </Typography>
                  </Div>
                  <CampaignContactList
                    setSelectedEmails={setSelectedEmails}
                    selectedEmails={selectedEmails}
                    setListPage={setListPage}
                    setContactpage={setContactpage}
                    transfer={selectedContactLists}
                  />
                  <Div
                    sx={[
                      DisplayFlex,
                      { flexWrap: "wrap", justifyContent: "center" },
                    ]}
                  >
                    <Cancelbutton
                      label="Cancel"
                      onClick={() => setContactpage(false)}
                    />
                    <CustomButton
                      onClick={() => {
                        let isTrue = selectedEmails?.some(
                          (email) => email?.checked === true
                        );
                        if (isTrue) {
                          if (mailCount <= 40) {
                            setActiveStep(activeStep + 1);
                            setContactpage(false);
                          } else {
                            toast.error(
                              "Sending restricted to a maximum of 40 emails",
                              {
                                position: "top-right",
                                autoClose: 2000,
                              }
                            );
                          }
                        } else {
                          toast.error(
                            "Selecting at least one email is mandatory",
                            {
                              position: "top-right",
                              autoClose: 2000,
                            }
                          );
                        }
                      }}
                      label="Next"
                      Icon="nextIcon"
                    />
                  </Div>
                </>
              }
              open={contactpage}
              Buttons={false}
              maxWidth="sm"
              fullWidth={true}
            />
          </>

          {/* Dialog Fox for the Create Lead List */}
          <DialogBox
            Title="Create Contact List"
            Content={
              <form onSubmit={handleSubmit}>
                <DialogContentText id="alert-dialog-description">
                  <Box>
                    <Div direction={"column"} spacing={1}>
                      <Div sx={{ mb: 1 }}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.2 }]}
                        >
                          List Name
                        </Typography>
                        <TextField
                          className={`col-12 input-box`}
                          id="outlined-basic"
                          placeholder="Enter List Name"
                          variant="outlined"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                          InputProps={{
                            style: {
                              borderRadius: "5px",
                            },
                          }}
                        />
                      </Div>

                      <Div sx={{ my: 1 }}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.2 }]}
                        >
                          Location
                        </Typography>
                        <Autocomplete
                          options={allLocationList || []}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option) {
                              return option;
                            }
                            return "";
                          }}
                          value={location}
                          defaultValue={location}
                          onChange={(e, newValue) => {
                            console.log("newValue", newValue);
                            setLocation(newValue);
                          }}
                          id="outlined-basic"
                          className="search-select col-12"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: "5px",
                              }}
                              placeholder="Location"
                            />
                          )}
                        />
                      </Div>

                      <Div sx={{ my: 1 }}>
                        <Typography
                          id="Typography-root"
                          sx={[FontStyle, { mb: 0.2 }]}
                        >
                          Industry Type
                        </Typography>
                        <Autocomplete
                          options={lookupdata}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option && option) {
                              return option;
                            }
                            return "";
                          }}
                          value={industry}
                          defaultValue={industry}
                          onChange={(e, newValue) => {
                            setIndustry(newValue);
                          }}
                          id="outlined-basic"
                          className="search-select col-12"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: "5px",
                              }}
                              placeholder="Industry Type"
                            />
                          )}
                        />
                      </Div>
                    </Div>
                    <Grid container align="left" mt={2} spacing={2}>
                      <Grid item align="right" xs={6}>
                        <CustomButton
                          label="Save"
                          type="submit"
                          Icon="saveIcon"
                        />
                      </Grid>
                      <Grid item align="left" xs={6}>
                        <Cancelbutton
                          label="Cancel"
                          onClick={() => setSave(true)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContentText>
              </form>
            }
            open={openCreateList}
            Buttons={false}
            maxWidth="xs"
            fullWidth={true}
          />

          {/* close dialog */}
          <DialogBox
            open={save}
            onClickNo={() => {
              setSave(false);
            }}
            onClickYes={() => {
              setSave(false);
              setOpenCreateList(false);
              // setErrors({})
            }}
          />

          {/* delete contact list dialog  */}

          {/* close dialog */}
          <DialogBox
            open={deleteDialog}
            onClickNo={() => {
              setDeleteDialog(false);
            }}
            onClickYes={() => {
              setDeleteDialog(false);
              deleteContactList(deleteDialog);
            }}
          />
          <ToastContainer />

          <DialogBox
            Content=" The campaign has not been saved. You can save the Draft to Work on the Later"
            open={activeStep === 0 && showdraft}
            onClickYes={() => DraftSubmit()}
            onClickNo={() => {
              setShowDraft(false);
              setOpenEditDraftCampaign(false);
              setTabValue(0)

            }}
            Label1="Save draft"
            BtnWidth1="120px"
            Label2="Cancel"
            Icon1="saveIcon"
            maxWidth="xs"
            fullWidth={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default CreateDraftCampaign;
