import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Menu,
  FormGroup,
  Pagination,
  Box,
  Popover,
  Autocomplete,
  DialogContent,
} from "@mui/material";
import React, { useReducer, useRef } from "react";
import { useState } from "react";
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import { FaFileInvoice } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePayment,
  MdOutlineFileUpload,
} from "react-icons/md";
import { RiAiGenerate, RiMailSendFill, RiRefreshFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ViewInvoice from "./ViewInvoice";
// import ChangeplanApply from "./ChangeplanApply";
import { apiConfig } from "app/services/config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "app/services/auth-services";
import { SettingsIcon } from "app/icons/PngIcons";

import { BsArrowDownUp } from "react-icons/bs";
import { useEffect } from "react";
import {
  Colors,
  ComponentHeading,
  componentTitle,
  CRMTableHead,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  dialogTitle,
  DisplayFlex,
  FontStyle,
  generateRandomColor,
  StyledTab,
  StyledTabs,
} from "../../widgets/CRMStyles";
import SearchBar from "../../widgets/SearchField";
import { CRMTableCell, CRMTableRow } from "../../widgets/CRMTable";
import {
  axiosInstance,
  Cancelbutton,
  CustomButton,
  CustomTooltip,
  DialogBox,
  Dragabledialog,
  errorFilter,
  NoRecordsPage,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import {
  invoiceFilterState,
  reducerFilter,
} from "app/pages/settings/filter-reducers/FilterReducers";
import { GrPowerReset } from "react-icons/gr";
import { IoMdPrint } from "react-icons/io";
import { AutoHeight } from "app/redux/auth/localStorage";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SubscriptionInvoice from "../subscription/SubscriptionInvoice";
import CreatePayments from "../payments/CreatePayments";
import { handleError } from "app/pages/components/ErrorHandler/handleError";

const InvoiceList = ({ scrollHeight }) => {
  const [datas, setdatas] = useState([]);
  const [mydatas, setMydatas] = useState([]);
  const [editdatas, seteditdatas] = useState([]);

  const [openInvoice, setOpenInvoice] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [showGenerateInvoice, setShowGenerateInvoice] = useState(false);
  const [customerDatas, setCustomerDatas] = useState([]);

  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [value, setValue] = useState("1");
  const [listInvoice, setListInvoice] = useState(true);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [changeplan, setChangePlan] = useState(false);

  const [showcustomername, setshowcustomername] = useState(true);
  const [showinvoiceno, setshowinvoiceno] = useState(true);
  const [showinvoicedate, setshowinvoicedate] = useState(true);
  const [showinvoiceamount, setshowinvoiceamount] = useState(true);
  const [showstatus, setshowstatus] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [searchFiled, setSearchFiled] = useState("");

  const reportTemplateRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [pdfname, setpdfname] = useState("");
  const [loading, setLoading] = useState(false);

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Updated_Date", label: "Date", bool: true },
    { sort: "Invoice_Id", label: "Invoice Id", bool: true },
    { sort: "Invoice_Date", label: "Invoice Date", bool: true },
    { sort: "InvoiceAmount", label: "Invoice Amount", bool: true },
    { sort: "TaxAmount", label: "Tax Amount", bool: true },
    { sort: "NetAmount", label: "Net Amount", bool: true },
    { sort: "Is_Archived", label: "Payment Status", bool: true },
    // { sort: "Tax_Amount", label: "Tax Amount", bool: true },
    // { sort: "SuscriptionAmount", label: "Amount", bool: true },
  ]);

  // API Headars
  const token = localStorage.getItem("accesstoken");
  const UserId = localStorage.getItem("UserId");
  const item = "crmuseradmin1";

  const header = {
    headers: {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  // For Asecnding Order
  const [showsort, setShowsort] = useState(true);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [myCount, setMyCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = count;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);

    const handledata = await handleError(
      axiosInstance.get(
        `/superadmin/InvoiceDetailsAdditional/?Is_Deleted=False&Organization_Id=${localStorage.getItem(
          "OrganizationId"
        )}&page=${page}`
      )
    );
    if (handledata) {
      setdatas(handledata?.results || []);
      setCount(handledata?.count || 0);
    }
  };

  // Filter Start --------------------------------------------------------

  const [filterState, filterDispatch] = useReducer(
    reducerFilter,
    invoiceFilterState
  );

  const resetFilter = () => {
    filterDispatch({
      InvoiceAmount: "",
      TaxAmount: "",
      NetAmount: "",
      Fromdate: "",
      Todate: "",
    });
  };

  const handleFilterNameChange = (e) => {
    const { name, value } = e.target;
    filterDispatch({ type: name, payload: value });
    console.log("Filter : ", name, value);
  };

  const filterData = async () => {
    let payload = {
      InvoiceAmount: filterState?.InvoiceAmount,
      TaxAmount: filterState?.TaxAmount,
      NetAmount: filterState?.NetAmount,
      fromdate: filterState?.Fromdate,
      todate: filterState?.Todate,
      Created_Date: new Date().toISOString().split("T")[0],
      Updated_Date: new Date().toISOString().split("T")[0],
      page: page,
    };

    let error = errorFilter(payload);

    if (Object.keys(error).length > 0) {
      toast.warning(
        `some fields are empty ${Object.keys(error)?.map((item) => item)}${
          Object.keys(error)?.length > 0 && ", "
        }`,
        {
          position: "top-right",
          autoClose: 500,
        }
      );
    } else {
      const handledata = await handleError(
        axiosInstance.post(`/superadmin/invoicefilter/`, payload)
      );

      console.log(handledata?.results || []);
      setdatas(handledata?.results || []);
      setCount(handledata?.count || 0);
      setShowFilter(false);
      setSearchFiled("");
      setTabValue(3);
    }
  };

  const RefreshData = () => {
    setShowFilter(null);
    setTabValue(0);
    resetFilter();
    getInvoiceRecords();
  };

  // Filter End ------------------------------------------------------------

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    setVisitedPages(visitedPages);
  };

  const ShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const ChangePlan = (data) => {
    seteditdatas(data);
    setCreateInvoice(true);
    setListInvoice(false);
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //code for table sorting
  const sortData = (key, direction) => {
    const sortedData = [...datas];

    sortedData.sort((a, b) => {
      const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setdatas(sortedData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
    setShowsort(false);
  };

  const getInvoiceRecords = async () => {
    const handledata = await handleError(
      axiosInstance.get(
        `/superadmin/InvoiceDetailsAdditional/?Is_Deleted=False&page=${page}`
      )
    );

    if (handledata) {
      setdatas(handledata?.results || []);
      setCount(handledata?.count || 0);
    }
  };

  const getMyInvoiceRecords = async () => {
    const handledata = await handleError(
      axiosInstance.get(
        `/superadmin/Invoicemylist/?page=${page}&Created_By=${localStorage.getItem(
          "UserId"
        )}`
      )
    );

    if (handledata) {
      setMydatas(handledata?.results || []);
      setMyCount(handledata?.count || 0);
    }
  };

  const GetSubscriptionRecords = async (item, type) => {
    const handledata = await handleError(
      axiosInstance.get(`/superadmin/subscriptionlist/?page=${page}`)
    );

    if (handledata) {
      let sub = handledata?.results?.find(
        (i) => i?.id == item?.Subscription_Id
      );
      organization(sub, item, type);
    }
  };

  const organization = async (sub, item, type) => {
    const handledata = await handleError(
      axiosInstance.get(
        `/useradmin/CRMorganizationCRUD/?id=${localStorage.getItem(
          "OrganizationId"
        )}`
      )
    );

    if (handledata) {
      setOpenInvoice({
        type: type,
        Customer_Id: sub?.Customer_Id,
        organization: handledata,
        ...item,
      });
    }
  };

  const handleSearch = async (searchstring) => {
    const handledata = await handleError(
      axiosInstance.put(`/superadmin/InvoiceDetailsAdditional/`, {
        search: searchstring,
        Is_Deleted: false,
        page,
      })
    );

    if (handledata) {
      setdatas(handledata?.results || []);
      setCount(handledata?.count || []);
    }
  };

  useEffect(() => {
    resetFilter();
    if (tabValue == 0) {
      setSearchFiled("");
      getInvoiceRecords();
    } else if (tabValue == 1) {
      setSearchFiled("");
      getMyInvoiceRecords();
    }
  }, [tabValue]);

  useEffect(() => {
    if (
      Object.values(filterState)?.some((state) => Boolean(state)) &&
      !Boolean(searchFiled)
    ) {
      setSearchFiled("");
      filterData();
    } else if (tabValue === 0) {
      getInvoiceRecords();
    } else if (tabValue === 1) {
      getMyInvoiceRecords();
    } else if (tabValue === 2) {
      if (Object.values(filterState)?.some((state) => Boolean(state))) {
        resetFilter();
      }
      handleSearch(searchFiled);
    }
  }, [page, searchFiled]);

  useEffect(() => {
    getMyInvoiceRecords();
  }, []);

  return (
    <>
      <JumboContentLayoutMain>
        <Div>
          <Box>
            <Box sx={{ mb: 2 }}>
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item xs={12} md={8} sx={[FontStyle]}>
                  <Typography
                    sx={[
                      componentTitle,
                      { color: Colors?.gray, fontWeight: 700, mb: 2 },
                    ]}
                  >
                    Invoice List
                  </Typography>
                </Grid>
              </Grid>

              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={10.5}
                    md={6}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <IconButton
                      sx={{ color: Colors?.Mblue }}
                      onClick={(event) => setShowFilter(event.currentTarget)}
                    >
                      <BiFilterAlt size={22} />
                    </IconButton>
                    <Div>
                      {tabValue === 2 || tabValue === 3 ? (
                        <Typography
                          sx={{ fontSize: "18px" }}
                          className="text-[#494949]"
                        >
                          {count == 0 ? "No results" : count}{" "}
                          {tabValue === 3
                            ? " found for your filers."
                            : " found for your search."}
                        </Typography>
                      ) : (
                        <StyledTabs
                          value={tabValue}
                          onChange={(e, newValue) => {
                            setTabValue(newValue);
                            console.log("newValue : ", newValue);
                          }}
                          aria-label="ant example"
                        >
                          <StyledTab label={`(${count}) All List`} />
                          <StyledTab label={`(${myCount}) My List`} />
                        </StyledTabs>
                      )}
                    </Div>
                  </Grid>

                  {/* Filter popover  */}
                  <Popover
                    open={Boolean(showFilter)}
                    anchorEl={showFilter}
                    onClose={ShowFilter}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ minWidth: "400px", width: { xs: "100%", md: "40%" } }}
                  >
                    <Box>
                      <Div
                        sx={[
                          DisplayFlex,
                          CRMTableHead,
                          dialogTitle,
                          { px: 2, justifyContent: "space-between" },
                        ]}
                      >
                        <Typography id="Typography-root" sx={[dialogTitle]}>
                          Filters
                        </Typography>
                        <IconButton sx={{ mr: -1 }} onClick={RefreshData}>
                          <GrPowerReset size={16} />
                        </IconButton>
                      </Div>

                      <Grid container spacing={2} sx={{ padding: "1rem" }}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Invoice Amount
                          </Typography>
                          <TextField
                            type="number"
                            className={`col-12 input-box2`}
                            autoComplete="off"
                            placeholder=" Invoice Amount"
                            name="InvoiceAmount"
                            inputProps={{ min: 0 }}
                            value={filterState?.InvoiceAmount}
                            onChange={handleFilterNameChange}
                            id="createdDate"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Tax Amount
                          </Typography>
                          <TextField
                            type="number"
                            className={`col-12 input-box2`}
                            autoComplete="off"
                            placeholder="Tax Amount"
                            name="TaxAmount"
                            inputProps={{ min: 0 }}
                            value={filterState?.TaxAmount}
                            onChange={handleFilterNameChange}
                            id="createdDate"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            Net Amount
                          </Typography>
                          <TextField
                            type="number"
                            className={`col-12 input-box2`}
                            autoComplete="off"
                            placeholder="Net Amount"
                            name="NetAmount"
                            inputProps={{ min: 0 }}
                            value={filterState?.NetAmount}
                            onChange={handleFilterNameChange}
                            id="createdDate"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            From Date
                          </Typography>
                          <TextField
                            type="date"
                            className={`col-12 input-box2`}
                            autoComplete="off"
                            placeholder=" From Date"
                            name="Fromdate"
                            value={filterState?.Fromdate}
                            onChange={handleFilterNameChange}
                            id="createdDate"
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Typography
                            id="Typography-root"
                            sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                          >
                            To Date
                          </Typography>
                          <TextField
                            type="date"
                            className={`col-12 input-box2`}
                            autoComplete="off"
                            placeholder="  To Date"
                            id="lastDate"
                            name="Todate"
                            value={filterState?.Todate}
                            disabled={!Boolean(filterState?.Fromdate)}
                            inputProps={{
                              min:
                                Boolean(filterState?.Fromdate) &&
                                new Date(filterState?.Fromdate)
                                  ?.toISOString()
                                  ?.split("T")[0],
                            }}
                            onChange={handleFilterNameChange}
                          />
                        </Grid>
                      </Grid>

                      <Div
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          my: 2,
                          gap: "10px",
                        }}
                      >
                        <Cancelbutton onClick={ShowFilter} />

                        <CustomButton
                          onClick={() => filterData()}
                          type="submit"
                          label="Apply"
                          Icon="filterIcon"
                        />
                      </Div>
                    </Box>
                  </Popover>

                  <Grid item xs={12} md={6}>
                    <Div sx={[DisplayFlex, { justifyContent: "right" }]}>
                      <SearchBar
                        width={"250px !important"}
                        border={"1px solid lightgray"}
                        value={searchFiled}
                        SearchData={(e) => {
                          setSearchFiled(e.target.value);
                          if (Boolean(e.target.value)) {
                            setTabValue(2);
                          } else {
                            setTabValue(0);
                          }
                        }}
                      />
                    </Div>
                  </Grid>
                </Grid>
              </Box>

              <Div sx={{ mt: { xs: 0, md: 1.5 } }}>
                <JumboScrollbar
                  autoHeight={true}
                  autoHideTimeout={4000}
                  autoHeightMin={AutoHeight(1.6)}
                  autoHide={true}
                  hideTracksWhenNotNeeded
                  id="no-more-tables"
                >
                  <Table stickyHeader sx={{ mt: 2, mb: 2 }}>
                    <CustomTableHeader
                      headerMenuItems={columnMenuItems}
                      setHeadermenuItems={setColumnMenuItems}
                      setDatas={setdatas}
                      sortDatas={datas}
                    />
                    {(tabValue !== 1 ? datas : mydatas)?.length > 0 && (
                      <TableBody sx={{ mt: 2 }}>
                        {(tabValue !== 1 ? datas : mydatas)
                          ?.slice(visitedPages * 10, visitedPages * 10 + 10)
                          ?.map((data, index) => {
                            return (
                              <CRMTableRow key={index}>
                                {columnMenuItems[0]?.bool && (
                                  <CRMTableCell data-title="Date" align="left">
                                    {data?.Updated_Date?.split("T")[0]}
                                  </CRMTableCell>
                                )}

                                {columnMenuItems[1]?.bool && (
                                  <CRMTableCell
                                    data-title="Invoice Id"
                                    align="left"
                                  >
                                    {data?.Invoice_Id}
                                  </CRMTableCell>
                                )}

                                {columnMenuItems[2]?.bool && (
                                  <CRMTableCell
                                    data-title="Invoice Date"
                                    align="left"
                                  >
                                    {data?.Invoice_Date?.split("T")[0]}
                                  </CRMTableCell>
                                )}

                                {columnMenuItems[3]?.bool && (
                                  <CRMTableCell
                                    data-title="Invoice Amount"
                                    align="left"
                                  >
                                    {toLocaleCurrency(data?.InvoiceAmount || 0)}
                                  </CRMTableCell>
                                )}

                                {columnMenuItems[4]?.bool && (
                                  <CRMTableCell
                                    data-title="Tax Amount"
                                    align="left"
                                  >
                                    {toLocaleCurrency(data?.TaxAmount || 0)}
                                  </CRMTableCell>
                                )}
                                {columnMenuItems[5]?.bool && (
                                  <CRMTableCell
                                    data-title="Net Amount"
                                    align="left"
                                  >
                                    {toLocaleCurrency(data?.NetAmount || 0)}
                                  </CRMTableCell>
                                )}
                                {columnMenuItems[6]?.bool && (
                                  <CRMTableCell
                                    data-title="Payment Status"
                                    align="left"
                                    style={{
                                      color: !data?.Is_Archived
                                        ? "orangered"
                                        : "green",
                                    }}
                                  >
                                    {!data?.Is_Archived ? "Unpaid" : "Paid"}
                                  </CRMTableCell>
                                )}

                                <CRMTableCell
                                  align="center"
                                  data-title="Action"
                                  sx={[
                                    DisplayFlex,
                                    {
                                      cursor: "pointer",
                                      mb: 1,
                                      // justifyContent: matches ? "center" : "left",
                                    },
                                  ]}
                                >
                                  <CustomTooltip
                                    value="View Invoice"
                                    type="children"
                                  >
                                    <IconButton
                                      disabled={loading}
                                      size="small"
                                      onClick={() => {
                                        GetSubscriptionRecords(data, "view");
                                      }}
                                    >
                                      <FaFileInvoice
                                        style={{
                                          fontSize: 16,
                                          color: !loading
                                            ? Colors?.Mblue
                                            : "gray",
                                        }}
                                      />
                                    </IconButton>
                                  </CustomTooltip>

                                  <CustomTooltip
                                    value="Invoice Send"
                                    type="children"
                                  >
                                    <IconButton
                                      disabled={loading}
                                      size="small"
                                      onClick={() => {
                                        GetSubscriptionRecords(data, "send");
                                      }}
                                    >
                                      <RiMailSendFill
                                        color={
                                          !loading ? Colors?.Mblue : "gray"
                                        }
                                        style={{ fontSize: 16 }}
                                      />
                                    </IconButton>
                                  </CustomTooltip>

                                  {!data?.Is_Archived && (
                                    <CustomTooltip
                                      value="Create Payment"
                                      type="children"
                                    >
                                      <IconButton
                                        disabled={loading}
                                        size="small"
                                        onClick={() => {
                                          setOpenCreateModal(data);
                                        }}
                                      >
                                        <MdOutlinePayment
                                          color={
                                            !loading ? Colors?.Mblue : "gray"
                                          }
                                          style={{ fontSize: 16 }}
                                        />
                                      </IconButton>
                                    </CustomTooltip>
                                  )}
                                </CRMTableCell>
                              </CRMTableRow>
                            );
                          })}
                      </TableBody>
                    )}
                  </Table>

                  {datas?.length === 0 && <NoRecordsPage title={"Invoice"} />}
                </JumboScrollbar>
              </Div>
              {/* code for pagination */}
              <Div
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Pagination
                  color="success"
                  count={totalPages}
                  page={page}
                  onChange={(e, newValue) => handleChangePage(e, newValue)}
                  size="small"
                  showFirstButton
                  showLastButton
                />
              </Div>
            </Box>
          </Box>
        </Div>

        {/* Create Lookup modal */}
        <Dragabledialog
          maxWidth="lg"
          open={openCreateModal}
          title={
            openCreateModal?.type === "put" ? "Edit Payment" : "Create Payment"
          }
          // onClose={() => setOpenAppointment(false)}
        >
          <DialogContent sx={{ p: 1 }}>
            <CreatePayments
              setOpen={setOpenCreateModal}
              // setDatas={setListSubscription}
              type={"invoice"}
              datas={openCreateModal}
              GetRecords={getInvoiceRecords}
            />
          </DialogContent>
        </Dragabledialog>

        <Dragabledialog
          maxWidth="lg"
          open={openInvoice}
          title="Invoice Preview"
          onClose={() => setOpenInvoice(false)}
        >
          <SubscriptionInvoice
            reportTemplateRef={reportTemplateRef}
            organisationData={openInvoice?.organization}
            Buttons={openInvoice?.type === "view" ? false : true}
            openInvoice={openInvoice}
            setOpenInvoice={setOpenInvoice}
            datas={openInvoice}
          />
        </Dragabledialog>
      </JumboContentLayoutMain>
      <ToastContainer />
    </>
  );
};

export default InvoiceList;
