import { AutoHeight } from 'app/redux/auth/localStorage';
import PieChart, {
    Series,
    Label,
    Connector,
    Legend,
    Export,
} from "devextreme-react/pie-chart";
import React from 'react'

function customizeText(arg) {
    return `${arg.valueText}%`;
}
function PiecChartRount({ data }) {
    console.log("datasss", data);
    const key = JSON.stringify(data);
    return (
        <PieChart
            key={key}
            id="pie"
            dataSource={data}
            palette="Bright"
            title=""
            height={AutoHeight(2.5) < 250 ? 250 : AutoHeight(2.5)}
        >
            <Series argumentField="label" valueField="value">
                <Label visible={true} customizeText={customizeText}>
                    <Connector visible={true} width={1} />
                </Label>
            </Series>

            <Legend
                orientation="horizontal"
                itemTextPosition="right"
                horizontalAlignment="center"
                verticalAlignment="bottom"
                columnCount={4}
            />

            <Export enabled={false} />
        </PieChart>
    )
}

export default PiecChartRount