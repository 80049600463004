import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import {
  DisplayFlex,
  FontStyle,
  componentTitle,
  input_label,
} from "app/pages/widgets/CRMStyles";
import {
  postSalesPersonTarget,
  postSalesTarget,
  useGetSalesPersonTarget,
  useTerritoryList,
  useTotalSalesTarget,
} from "../TerritoryAPI";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import {
  Cancelbutton,
  CustomButton,
  fetchTransactionSeries,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { Months } from "./SalesPersonDatas";
import SalesTargetLists from "./SalesPersonDatas";
import Div from "@jumbo/shared/Div";
import { BASE_URL } from "app/services/config";
import axios from "axios";

const CreateSalesTarget = ({ open, setOpen, update }) => {
  const token = localStorage.getItem("accesstoken");

  const [error, setError] = useState({});
  const { SalesTargetList } = useTotalSalesTarget();
  const { salesPersonTarget } = useGetSalesPersonTarget();
  const [territoryName, setTerritoryName] = useState("");
  const [territoryFilter, setTerritoryFilter] = useState(SalesTargetList || []);
  const { territoryList } = useTerritoryList();
  const [data, setData] = useState([]);
  const { datas, isLoading } = SalesTargetLists();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [seriesValue, setSeriesValue] = useState("");
  const [targetId, setTargetId] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");

  const reset = useCallback(() => {
    const initialData = {};
    Months.forEach((month) => {
      initialData[month] = 0;
    });
    setData(initialData);
  }, []);

  const handleSubmit = async () => {
    const error = {};

    Object.entries(territoryName?.Target)?.map(([kay, val], i) => {
      let value = 0;
      data?.map((item, ind) => {
        value += Number(item?.Target?.[kay]);
      });

      if (value !== val) {
        error[kay] = true;
      }
      console.log(" => ", error, kay, value, val);
    });

    if (Boolean(seriesValue !== "Automatic") && !Boolean(targetId)) {
      error.targetId = "Target Id is required";
    } else if (
      Boolean(seriesValue !== "Automatic") &&
      !Boolean(targetId?.length == 11)
    ) {
      error.targetId = "Target Id must be 11 digits.";
    }

    setError(error);
    if (Object.keys(error)?.length === 0) {
      let payload = {};
      if (open === "Create") {
        payload = {
          PersonTarget_Id: seriesValue === "Manual" && targetId,
          Territery_Name: territoryName?.Territery_Name,
          Target: data,
          Territery_Id: territoryName?.Territery_Id,
          Year: companyDetails?.Fiscal_Year?.Calendar_FinancialYear,
          Target_Id: territoryName?.id,
          Is_Deleted: false,
          User_Id: data?.map((itm) => itm?.id),
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
      } else {
        payload = {
          id: update?.id,
          Territery_Name: territoryName?.Territery_Name,
          Target: data,
          Territery_Id: territoryName?.Territery_Id,
          Year: update?.Year,
          Target_Id: territoryName?.id,
          Is_Deleted: false,
          User_Id: data?.map((itm) => itm?.id),
          Organization_Id: localStorage.getItem("OrganizationId"),
          Created_By: localStorage.getItem("UserId"),
          Updated_By: localStorage.getItem("UserId"),
        };
      }

      console.log(" Organization : ", update, territoryName);
      setLoading(true);
      const res =
        open?.toLowerCase() === "create"
          ? await postSalesPersonTarget("post", payload)
          : await postSalesPersonTarget("put", payload);

      if (res) {
        toast.success(
          `${
            open?.toLowerCase() === "create" ? "Created" : "Updated"
          } successfully`
        );
        queryClient.invalidateQueries("updateSalesPersonTarget");
        setLoading(false);
        setOpen("list");
      } else {
        toast.error(
          `${
            open?.toLowerCase() === "create" ? "Creation" : "Update"
          } Unsuccessful`
        );
        setLoading(false);
      }
    } else {
      toast.warning(
        `The value should not be match sales target ${Object.keys(error)?.map(
          (key) => key
        )}`
      );
      setLoading(false);
    }
  };

  // handleOnChange;
  const handleOnChange = (event, newValue) => {
    let names = datas?.data?.find((itm) => itm?.id === newValue?.Territery_Id);
    let person = salesPersonTarget?.find(
      (itm) => itm?.Territery_Id === newValue?.Territery_Id
    );
    setTerritoryName(newValue || {});
    // console.log("keys names : ", names, newValue, datas?.data);
    if (!newValue) reset();
    if (person) {
      setData(person?.children);
    } else {
      setData(names?.children);
    }
  };

  // handleInputOnChange;

  const handleInputOnChange = (e, month, i) => {
    const value = Number(e.target.value);
    const totalValue = Number(territoryName?.Target[month]);

    let values = 0;

    data?.map((value, ind) => {
      if (ind !== i) {
        values += value?.Target[month];
      }
    });

    console.log("conditions:", totalValue, values + value, value, data);
    if (totalValue < values + value) {
      toast.warning(`The value should not greater than sales target ${month} `);
    } else {
      setData((prev) => {
        const updatedData = [...prev];
        const userData = updatedData[i];
        if (userData && userData?.Target) {
          userData.Target[month] = Number(value) ?? 0;
        }
        return updatedData;
      });
    }
  };

  useEffect(() => {
    console.log("filter : ", SalesTargetList, salesPersonTarget);
    if (open === "Create") {
      if (salesPersonTarget?.length > 0) {
        let filter = [];
        SalesTargetList?.forEach((item) => {
          salesPersonTarget?.map((terr) => {
            if (
              item?.Territery_Name?.toLowerCase() !==
              terr?.Territery_Name?.toLowerCase()
            ) {
              filter.push(item);
            }
            console.log("item : ", item, terr);
          });
        });
        setTerritoryFilter(filter);
      } else {
        setTerritoryFilter(SalesTargetList);
      }
      console.log("filter @  : ", salesPersonTarget, SalesTargetList);
    } else if (open === "Edit" && update) {
      // Set territory name and id from update
      let filter = [];
      SalesTargetList?.forEach((item) => {
        salesPersonTarget?.map((terr) => {
          console.log("filter wq : ", territoryList, terr);

          if (
            item?.Territory_Name?.toLowerCase() !==
              terr?.Territery_Name?.toLowerCase() ||
            terr?.Territery_Name?.toLowerCase() ===
              update?.Territery_Name?.toLowerCase()
          ) {
            filter.push(item);
          }
        });
      });

      let saleTar = SalesTargetList?.find(
        (find) => find?.Territery_Id === update?.Territery_Id
      );
      console.log("saleTar", saleTar, SalesTargetList, update?.Territery_Id);
      setTerritoryName(saleTar);

      setTerritoryFilter(filter);
      setTargetId(update?.PersonTarget_Id);
      setData(update?.Target);
    }

    // Log relevant state variables for debugging
    console.log("open:", open);
    console.log("update:", update);
    console.log("SalesTargetList:", SalesTargetList);
    console.log("territoryList:", territoryList);
  }, [open, update]);

  const getCompany = async () => {
    try {
      let res = await axios.get(
        `${BASE_URL}/useradmin/CRMorganizationCRUD/?id=${localStorage.getItem(
          "OrganizationId"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("resssss: ", res?.data);
      setCompanyDetails(res?.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(async () => {
    getCompany();

    const series = await fetchTransactionSeries("Sales_PersonTarget");
    console.log("Sales_PersonTarget series", series);
    setSeriesValue(series);
  }, []);

  return (
    <Box>
      <Typography sx={componentTitle} className="mt-1 mb-4">
        {open} Sales person Target
      </Typography>
      <div className="my-4 flex items-center gap-2 justify-between">
        <div className="flex items-center gap-2">
          {open === "Edit" && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6.1}>
                <Div sx={[DisplayFlex]}>
                  <Typography
                    id="Typography-root"
                    sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    className="col-3"
                  >
                    Sales Target Id
                  </Typography>
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                    }}
                    className={`col-6 input-box2`}
                    name="TargetId"
                    id="outlined-basic"
                    value={targetId}
                    onChange={(e) => {
                      setTargetId(e.target.value);
                    }}
                    placeholder="# Target Id"
                    variant="outlined"
                    disabled={seriesValue == "Automatic" ? true : ""}
                  />
                  {error?.targetId && (
                    <Div style={{ color: "red" }}>{error?.targetId}</Div>
                  )}
                </Div>
              </Grid>
              <Grid item xs={12} md={6} lg={6.1}>
                <Div sx={[DisplayFlex]}>
                  <Typography
                    id="Typography-root"
                    sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    className="col-3"
                  >
                    Select Territory
                  </Typography>
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                    }}
                    className={`col-6 input-box2`}
                    name="territoryName"
                    id="outlined-basic"
                    value={territoryName?.Territery_Name}
                    placeholder="Territory Name"
                    variant="outlined"
                    disabled
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={6} lg={6.1}>
                <Div sx={[DisplayFlex]}>
                  <Typography
                    id="Typography-root"
                    sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    className="col-3"
                  >
                    Financial Year
                  </Typography>
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                    }}
                    className={`col-6 input-box2`}
                    name="TargetId"
                    id="outlined-basic"
                    value={companyDetails?.Fiscal_Year?.Calendar_FinancialYear}
                    variant="outlined"
                    placeholder="Financial Year"
                  />
                </Div>
              </Grid>
            </Grid>
          )}
          {open === "Create" && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6.1}>
                <Div sx={[DisplayFlex]}>
                  <Typography
                    id="Typography-root"
                    sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    className="col-3"
                  >
                    Sales Target Id
                  </Typography>
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                    }}
                    className={`col-6 input-box2`}
                    name="TargetId"
                    id="outlined-basic"
                    value={targetId}
                    onChange={(e) => {
                      setTargetId(e.target.value);
                    }}
                    placeholder="# Target Id"
                    variant="outlined"
                    disabled={seriesValue == "Automatic" ? true : ""}
                  />
                  {error?.targetId && (
                    <Div style={{ color: "red" }}>{error?.targetId}</Div>
                  )}
                </Div>
              </Grid>
              <Grid item xs={12} md={6} lg={6.1}>
                <Div sx={[DisplayFlex]}>
                  <Typography
                    id="Typography-root"
                    sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    className="col-3"
                  >
                    Select Territory
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: { xs: "250px", md: "190px" } }}
                    className="search-select3 col-6"
                    type="search"
                    options={territoryFilter || []}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.TerritoryName) {
                        return option?.TerritoryName;
                      }
                      return "";
                    }}
                    value={territoryName?.Territery_Name || ""}
                    onChange={handleOnChange}
                    autoComplete="off"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        placeholder="Choose a territory"
                      />
                    )}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={6} lg={6.1}>
                <Div sx={[DisplayFlex]}>
                  <Typography
                    id="Typography-root"
                    sx={[FontStyle, { mb: 0.5, textAlign: "left" }]}
                    className="col-3"
                  >
                    Financial Year
                  </Typography>
                  <TextField
                    sx={{
                      "& fieldset": {
                        borderRadius: "5px",
                      },
                    }}
                    className={`col-6 input-box2`}
                    name="TargetId"
                    id="outlined-basic"
                    value={companyDetails?.Fiscal_Year?.Calendar_FinancialYear}
                    variant="outlined"
                    placeholder="Financial Year"
                  />
                </Div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      <Box>
        <Typography sx={FontStyle} className="my-3">
          Sales Revenue
        </Typography>
        {
          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={150}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table>
              <TableHead>
                <CRMTableRow>
                  <CRMTableCell sx={{ minWidth: "140px !important" }}>
                    Territory Name
                  </CRMTableCell>
                  {Months?.map((month, i) => (
                    <CRMTableCell key={i}>{month}</CRMTableCell>
                  ))}
                  <CRMTableCell>Total</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                <CRMTableRow>
                  <CRMTableCell sx={{ minWidth: "140px !important" }}>
                    {territoryName?.Territery_Name || " Not Selected "}
                  </CRMTableCell>
                  {Months?.map((month, i) => (
                    <CRMTableCell key={i}>
                      <Typography sx={[FontStyle]}>
                        {Object?.keys(territoryName)?.length > 0
                          ? toLocaleCurrency(
                              territoryName?.Target?.[Months[i]] || 0
                            )
                          : toLocaleCurrency(0)}{" "}
                      </Typography>
                    </CRMTableCell>
                  ))}
                  <CRMTableCell>
                    {Object?.keys(territoryName)?.length > 0
                      ? toLocaleCurrency(
                          Object?.values(territoryName?.Target)?.reduce(
                            (acc, val) => acc + Number(val),
                            0
                          )
                        )
                      : toLocaleCurrency(0)}
                  </CRMTableCell>
                </CRMTableRow>
              </TableBody>
            </Table>
          </JumboScrollbar>
        }
      </Box>

      {Object?.keys(territoryName)?.length > 0 && (
        <Box>
          <Typography sx={FontStyle} className="mb-2">
            Sales Person Revenue
          </Typography>
          {
            <JumboScrollbar
              autoHeight={true}
              autoHideTimeout={4000}
              autoHeightMin={200}
              autoHide={true}
              hideTracksWhenNotNeeded
              id="no-more-tables"
            >
              <Table>
                <TableHead>
                  <CRMTableRow>
                    <CRMTableCell sx={{ minWidth: "140px !important" }}>
                      Sales Person Name
                    </CRMTableCell>
                    {Months?.map((month, i) => (
                      <CRMTableCell
                        key={i}
                        style={{ color: error?.[month] && "tomato" }}
                      >
                        {month}
                      </CRMTableCell>
                    ))}
                    <CRMTableCell
                      tyle={{
                        color: Object.keys(error)?.length > 0 && "tomato",
                      }}
                    >
                      Total
                    </CRMTableCell>
                  </CRMTableRow>
                </TableHead>
                <TableBody>
                  {data?.length > 0 &&
                    data?.map((user, i) => {
                      // console.log("keys", user, i);

                      return (
                        <CRMTableRow key={i}>
                          <CRMTableCell sx={{ minWidth: "140px !important" }}>
                            {user?.salesperson}
                          </CRMTableCell>
                          {Months?.map((month, ci) => (
                            <CRMTableCell key={ci}>
                              <TextField
                                className="input-box2 w-full my-1"
                                type="number"
                                sx={{
                                  caret: "unset",
                                  minWidth: {
                                    xs: "140px !important",
                                    md: "80px !important",
                                  },
                                }}
                                onChange={(e) =>
                                  handleInputOnChange(e, month, i)
                                }
                                inputProps={{ min: 0 }}
                                value={user?.Target?.[month] || 0}
                              />
                            </CRMTableCell>
                          ))}
                          <CRMTableCell>
                            {toLocaleCurrency(
                              Object.values(user?.Target || {}).reduce(
                                (acc, val) => Number(acc) + Number(val),
                                0
                              )
                            )}
                          </CRMTableCell>
                        </CRMTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </JumboScrollbar>
          }
        </Box>
      )}

      <Div className="flex items-center gap-2" sx={{ mb: 5 }}>
        <Cancelbutton
          label="Cancel"
          Icon="cancelIcon"
          onClick={() => {
            setOpen("list");
            reset();
            setTerritoryName({});
          }}
        />
        <CustomButton
          label={loading ? "Wait..." : open === "Create" ? "Save" : "Update"}
          Icon={loading ? "" : "saveIcon"}
          disabled={loading || !Boolean(territoryName)}
          onClick={!loading && handleSubmit}
          type="submit"
        />
      </Div>
    </Box>
  );
};

export default memo(CreateSalesTarget);
