import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import CloseIcon from "@mui/icons-material/Close";
import React, { memo, useEffect } from "react";
import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Country, State, City } from "country-state-city";
import {
  CRMTableHead,
  DisplayFlex,
  dialogFontSize,
  input_label,
} from "../widgets/CRMStyles";

import { useQuery } from "react-query";
import {
  Cancelbutton,
  CustomButton,
  Customgrid,
  DialogBox,
  errorFilter,
} from "../widgets/StylesComponents";
import { AutoHeight } from "app/redux/auth/localStorage";

const QualifiedLeadsEdit = ({
  scrollHeight,
  openEditLead,
  setOpenEditLead,
  webupdate,
  setSave,
  getQualifiedDataListsRefetch,
  anchorElAction,
  setAnchorElAction,
  tabValue,
  getAllData,
  getQualifiedData,
}) => {
  console.log("webupdate", webupdate);
  //  Initilization For Country,state,city
  const [selectedCountry, setSelectedCountry] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [fname, setFname] = useState(webupdate?.LeadFirstName);
  const [lname, setLname] = useState(webupdate?.LeadLastName);
  const [cname, setCname] = useState(webupdate?.CompanyName);
  const [phone, setPhone] = useState(webupdate?.PhoneNo);
  const [url, setUrl] = useState(webupdate?.WebsiteURL);
  const [leadtype, setLeadType] = useState(webupdate?.LeadType);
  const [selectedCountry1, setSelectedCountry1] = useState(webupdate?.Country);
  const [selectedState1, setSelectedState1] = useState(webupdate?.State);
  const [selectedCity1, setSelectedCity1] = useState(webupdate?.City);
  const [areaname, setAreaName] = useState(webupdate?.AreaName);
  const [strname, setStrname] = useState(webupdate?.StreetName);
  const [email, setEmail] = useState(webupdate?.EmailId);
  const [BuildingNo, setBuildingNo] = useState(webupdate?.BuildingDoorNo);
  const [leadStatusName, setleadStatusName] = useState(webupdate?.Lead_Status);
  const [leadCycleStage, setleadCycleStage] = useState(
    webupdate?.LifeCycleStage
  );
  const [des, setDes] = useState(webupdate?.LeadDescription);
  const [turnover, setTurnOver] = useState(webupdate?.LeadCompanyTurnOver);
  const [noofemployee, setNoofEmployee] = useState(
    webupdate?.LeadNoOfEmployees
  );
  const [location, setLocation] = useState(webupdate?.LeadLocation);
  const [jobtitle1, setJobtitle1] = useState(webupdate?.Job_Title);
  const [leadindustry1, setLeadindustry1] = useState(webupdate?.Lead_Industry);
  const [leadstatus1, setLeadStatus1] = useState(webupdate?.Lead_Status);
  const [leadsource1, setLeadscource1] = useState(webupdate?.LeadSource);
  //  Newly Added Values
  const [jobrole, setJobrole] = useState(webupdate?.Job_Role);
  const [designation, setDesignation] = useState(webupdate?.Designation);
  const [decissionmaker, setDecissionmaker] = useState(
    webupdate?.Decissionmaker
  );
  const [yrofexp, setYrofexp] = useState(webupdate?.Yrs_of_Experience);
  const [headq, setHeadq] = useState(webupdate?.Head_Quarter);
  const [yearoffounded, setYearofFounded] = useState(
    webupdate?.Year_of_Founded
  );
  const [budget, setBudget] = useState(webupdate?.Budget);
  const [budgetAvai, setBudgetAvai] = useState(webupdate?.Budget_Availability);
  const [levelofdetail, setLevelofDetail] = useState(
    webupdate?.Level_of_detail_provided
  );
  const [Prepurchase, setPrepurchase] = useState(webupdate?.Previous_purchases);
  console.log("webupdate?.LeadLocation", webupdate);
  useEffect(() => {
    setFname(webupdate?.LeadFirstName);
    setLname(webupdate?.LeadLastName);
    setCname(webupdate?.CompanyName);
    setPhone(webupdate?.PhoneNo);
    setUrl(webupdate?.WebsiteURL);
    setLeadType(webupdate?.LeadType);
    setSelectedCountry1(webupdate?.Country);
    setSelectedState1(webupdate?.State);
    setSelectedCity1(webupdate?.City);
    setAreaName(webupdate?.AreaName);
    setStrname(webupdate?.StreetName);
    setEmail(webupdate?.EmailId);
    setBuildingNo(webupdate?.BuildingDoorNo);
    setDes(webupdate?.LeadDescription);
    setTurnOver(webupdate?.LeadCompanyTurnOver);
    setNoofEmployee(webupdate?.LeadNoOfEmployees);
    setLocation(webupdate?.LeadLocation);
    setJobtitle1(webupdate?.Job_Title);
    setLeadscource1(webupdate?.LeadSource);
    setLeadindustry1(webupdate?.Lead_Industry);
    // setLeadStatus1(webupdate?.Lead_Status);
    setDecissionmaker(webupdate?.Decissionmaker);
    setDesignation(webupdate?.Designation);
    setYrofexp(webupdate?.Yrs_of_Experience);
    setHeadq(webupdate?.Head_Quarter);
    setYearofFounded(webupdate?.Year_of_Founded);
    setBudget(webupdate?.Budget);
    setBudgetAvai(webupdate?.Budget_Availability);
    setLevelofDetail(webupdate?.Level_of_detail_provided);
    setPrepurchase(webupdate?.Previous_purchases);
    setJobrole(webupdate?.Job_Role);
    setleadCycleStage(webupdate?.LifeCycleStage);
    setleadStatusName(webupdate?.Lead_Status);
  }, [webupdate]);
  // For Validation Purpose
  const validationSchema = yup.object().shape({
    fname: yup.string().required("Lead First Name is required"),
    lname: yup.string().required("Lead Last Name is required"),
    cname: yup.string().required("Company Name is Required"),
    phone: yup.string().required("Mobile Number is required"),
    // url: yup.string().required("Website  is required"),
    selectedCity1: yup.string().required("City is required"),
    selectedCountry1: yup.string().required("Country is Required"),
    selectedState1: yup.string().required("State is Required"),
    areaname: yup.string().required("Area Name is required"),
    strname: yup.string().required("Street Name is required"),
    email: yup.string().required("Email is required"),
    BuildingNo: yup.string().required("Buildingt Name is required"),
    // des: yup.string().required("Description is required"),
    location: yup.string().required("Location is required"),
    jobtitle1: yup.string().required("Job Tittle is Required"),
    leadsource1: yup.string().required("Lead Source is Required"),
    leadindustry1: yup.string().required("Lead Industry is Required"),
    yrofexp: yup.string().required("Years of Experience is Required"),
    designation: yup.string().required("Designation is Required"),
    jobrole: yup.string().required("Job Role is Required"),
    headq: yup.string().required("Head Quarter is Required"),
    budget: yup.string().required("Budget is Required"),
    budgetAvai: yup.string().required("budget Availablility is Required"),
    Prepurchase: yup.string().required("Pre Purchase is Required"),
    // leadstatus1: yup.string().required("Lead Status is Required")
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { error },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const onsubmit = async () => {
    var updatepayload = {
      id: webupdate?.id,
      LeadId: webupdate?.LeadId,
      Salutation: webupdate?.Salutation,
      LeadFirstName: fname,
      LeadLastName: lname,
      CompanyName: cname,
      PhoneNo: phone,
      EmailId: email,
      LifeCycleStage: leadCycleStage,
      Lead_Status: leadStatusName,
      WebsiteURL: webupdate?.WebsiteURL,
      BuildingDoorNo: BuildingNo,
      StreetName: strname,
      AreaName: areaname,
      City: selectedCity1,
      State: selectedState1,
      Country: selectedCountry1,
      LeadSource: leadsource1,
      Lead_Industry: leadindustry1,
      LeadLocation: location,
      Job_Title: jobtitle1,
      Job_Role: jobrole,
      Designation: designation,
      Yrs_of_Experience: yrofexp,
      Head_Quarter: headq,
      Year_of_Founded: yearoffounded,
      Budget: budget,
      Budget_Availability: budgetAvai,
      Previous_purchases: Prepurchase,
      LeadCompanyTurnOver: parseInt(turnover),
      LeadNoOfEmployees: noofemployee,
      Decissionmaker: decissionmaker,
      LeadDescription: webupdate?.LeadDescription,
      LeadType: webupdate?.LeadType,
      LeadMLQStatus: webupdate?.LeadMLQStatus,
      LeadSLQStatus: webupdate?.LeadSLQStatus,
      LeadQualifyStatus: webupdate?.LeadQualifyStatus,
      LeadMLQuestions: webupdate?.LeadMLQuestions,
      LeadSLQuestions: webupdate?.LeadSLQuestions,
      Is_blocked: webupdate?.Is_blocked,
      Created_Date: webupdate?.Created_Date,
      Updated_Date: webupdate?.Updated_Date,
      Organization_Id: webupdate?.Organization_Id,
      Created_By: webupdate?.Created_By,
      Updated_By: webupdate?.Updated_By,
    };

    let error = errorFilter({
      fname,
      lname,
      cname,
      phone,
      email,
      leadCycleStage,
      leadStatusName,
      BuildingNo,
      strname,
      areaname,
      selectedCity1,
      selectedState1,
      selectedCountry1,
      leadsource1,
      leadindustry1,
      location,
      jobtitle1,
      jobrole,
      designation,
      yrofexp,
      headq,
      yearoffounded,
      budget,
      budgetAvai,
      Prepurchase,
      turnover,
      noofemployee,
      decissionmaker,
    });
    console.log("payload", error);
    if (Object.keys(error).length == 0) {
      console.log("payload3", error);
      try {
        await axios
          .put(`${BASE_URL}/user/UserQualifiedLeadsCRUD/`, updatepayload, {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log("res.data", res.data);
            setOpenEditLead("leads");
            if (tabValue === 0) {
              getAllData(1);
            } else if (tabValue === 1) {
              getQualifiedData(1);
            }
            setErrors({});
            toast.success("Updated successfully", {
              position: "top-right",
              autoClose: 100,
            });
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      }
    } else {
      console.log("Please", error);
    }
    setErrors(error);
  };
  // Geeting the Job Tittle From UserAdmin Table
  const [jobtitle, setJobtitle] = useState([]);
  const [leadstatus, setLeadStatus] = useState([]);
  const [leadindustry, setLeadindustry] = useState([]);
  const [leadsource, setLeadscource] = useState([]);
  const [desc, setDesc] = useState([]);
  const [jobrole1, setJobrole1] = useState([]);
  const [head, setHead] = useState([]);
  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  const LeadDemoDropDown = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/LeadDemoDropDown/`, {
        headers: {
          db: "crmuseradmin1",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response?.data?.Result;
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  const HandleCancel = () => {
    setSave(false);
    setOpenEditLead(false);
    reset();
    setFname("");
    setLname("");
    setCname("");
    setPhone("");
    setUrl("");
    setLeadType("");
    setSelectedCountry1("");
    setSelectedState1("");
    setSelectedCity1("");
    setAreaName("");
    setStrname("");
    setEmail("");
    setBuildingNo("");
    setDes("");
    setTurnOver("");
    setNoofEmployee("");
    setLocation("");
    setJobtitle1("");
    setLeadscource1("");
    setLeadindustry1("");
    setLeadStatus1("");
    setDesignation("");
    setDecissionmaker("");
    setYrofexp("");
    setHeadq("");
    setYearofFounded("");
    setBudget("");
    setBudgetAvai("");
    setLevelofDetail("");
    setPrepurchase("");
    setAnchorElAction(null);
  };
  const DecisionDropdown = ["Yes", "No"];
  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUpLists"], getlookupData, { staleTime: Infinity });
  const {
    data: getLeadDemoDropdwonlists,
    isLoading: getLeadDemoDropdwonlistsLoading,
    isError: getLeadDemoDropdwonlistsError,
    refetch: getLeadDemoDropdwonlistsRefetch,
  } = useQuery(["getLeadDemoDropdwonlists"], LeadDemoDropDown, {
    staleTime: Infinity,
  });
  useEffect(() => {
    if (getUserLoopUp) {
      setLeadStatus(
        getUserLoopUp?.find((op) => op?.Lookupname === "Lead Status") || []
      );
    }
  }, [getUserLoopUp]);
  useEffect(() => {
    if (getLeadDemoDropdwonlists) {
      setJobtitle(getLeadDemoDropdwonlists?.["Job Tittle"]);
      setLeadindustry(getLeadDemoDropdwonlists?.Industry);
      setLeadscource(getLeadDemoDropdwonlists?.["Lead Source"]);
      setDesc(getLeadDemoDropdwonlists?.Designation);
      setJobrole1(getLeadDemoDropdwonlists?.["Job Role"]);
      setHead(getLeadDemoDropdwonlists?.["Head Quarter"]);
    }
  }, [getLeadDemoDropdwonlists]);

  const cancelFunc = () => {
    if (
      fname === webupdate?.LeadFirstName &&
      lname === webupdate?.LeadLastName &&
      cname === webupdate?.CompanyName &&
      phone === webupdate?.PhoneNo &&
      url === webupdate?.WebsiteURL &&
      leadtype === webupdate?.LeadType &&
      selectedCountry1 === webupdate?.Country &&
      selectedState1 === webupdate?.State &&
      selectedCity1 === webupdate?.City &&
      areaname === webupdate?.AreaName &&
      strname === webupdate?.StreetName &&
      email === webupdate?.EmailId &&
      BuildingNo === webupdate?.BuildingDoorNo &&
      des === webupdate?.LeadDescription &&
      turnover === webupdate?.LeadCompanyTurnOver &&
      noofemployee === webupdate?.LeadNoOfEmployees &&
      location === webupdate?.LeadLocation &&
      jobtitle1 === webupdate?.Job_Title &&
      leadsource1 === webupdate?.LeadSource &&
      leadindustry1 === webupdate?.Lead_Industry &&
      decissionmaker === webupdate?.Decissionmaker &&
      leadCycleStage === webupdate?.LifeCycleStage &&
      leadStatusName === webupdate?.Lead_Status &&
      designation === webupdate?.Designation &&
      yrofexp === webupdate?.Yrs_of_Experience &&
      headq === webupdate?.Head_Quarter &&
      yearoffounded === webupdate?.Year_of_Founded &&
      budget === webupdate?.Budget &&
      budgetAvai === webupdate?.Budget_Availability &&
      levelofdetail === webupdate?.Level_of_detail_provided &&
      Prepurchase === webupdate?.Previous_purchases &&
      jobrole === webupdate?.Job_Role
    ) {
      setOpenEditLead("leads");
    } else {
      setSave("edit");
    }
    setErrors({});
  };

  return (
    <>
      <Typography sx={[dialogFontSize, { mb: 3 }]}>Edit Lead</Typography>

      <Box
        sx={{
          bgcolor: "white",
          padding: { xs: 1, md: 2 },
          borderRadius: "5px",
          border: "1px solid lightgray",
        }}
      >
        {/* <form onSubmit={handleSubmit(onsubmit)}> */}
        <JumboScrollbar
          autoHeight={true}
          autoHeightMin={AutoHeight(1.8)}
          autoHideTimeOut={4000}
        >
          <Customgrid columnCount={4}>
            <Grid item>
              <Typography sx={[input_label]}>Lead Id</Typography>
            </Grid>
            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.name ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead Id"
                value={webupdate?.LeadId}
                disabled
              />
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                First Name <span className="required">*</span>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                className={`col-12 input-box2 ${
                  errors.name ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                {...register("fname")}
                placeholder="Lead First Name"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value);
                }}
              />
              {errors?.fname && (
                <Div style={{ color: "red" }}>Fist name {errors.fname}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Last Name <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("lname")}
                className={`col-12 input-box2 ${
                  errors.lname ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead Last Name"
                value={lname}
                onChange={(e) => {
                  setLname(e.target.value);
                }}
              />
              {errors?.lname && (
                <Div style={{ color: "red" }}>Last name {errors.lname}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Job Title <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("jobtitle1")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value || value === ""
                }
                value={jobtitle1}
                defaultValue={jobtitle1}
                options={jobtitle || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setJobtitle1(newValue);
                  setValue("jobtitle1", newValue);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Job Title"
                  />
                )}
              />
              {errors.jobtitle1 && (
                <Div style={{ color: "red" }}>Job name {errors.jobtitle1}</Div>
              )}
            </Grid>
            {/* <Grid item >
                  <Typography id="Typography-root" sx={[input_label]}>
                    Job Role
                  </Typography>
                  <TextField
                    {...register("jobrole")}
                    className={`col-12 input-box2 ${
                      errors.jobrole ? "is-invalid" : ""
                    }`}
                    id="outlined-basic"
                    placeholder="Job Role"
                    
                    name="jobrole"
                    value={jobrole}
                    onChange={(e) => {
                      setJobrole(e.target.value);
                    }}
                    
                  />
                  <Div style={{ color: "red" }}>
                    {errors.jobrole}
                  </Div>
                </Grid> */}

            <Grid item>
              <Typography sx={[input_label]}>
                Job Role <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("jobrole")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value || value === ""
                }
                value={jobrole}
                defaultValue={jobrole}
                options={jobrole1 || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setJobrole(newValue);
                  setValue("jobrole", newValue);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Job Role"
                  />
                )}
              />
              {errors.jobrole && (
                <Div style={{ color: "red" }}>Job role {errors.jobrole}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Location <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("location")}
                className={`col-12 input-box2 ${
                  errors.location ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Location"
                name="location"
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
              {errors.location && (
                <Div style={{ color: "red" }}>Location {errors.location}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Designation <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("designation")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value || value === ""
                }
                value={designation}
                defaultValue={designation}
                options={desc || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setDesignation(newValue);
                  setValue("designation", newValue);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Job Title"
                  />
                )}
              />
              {errors.designation && (
                <Div style={{ color: "red" }}>
                  Designation {errors.designation}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Head Quarter <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("headq")}
                className={`col-12 input-box2 ${
                  errors.headq ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Head Quarter"
                name="headq"
                value={headq}
                onChange={(e) => {
                  setHeadq(e.target.value);
                }}
              />
              {errors.headq && (
                <Div style={{ color: "red" }}>Head quarter {errors.headq}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Year of Founded <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("yearoffounded")}
                className={`col-12 input-box2 ${
                  errors.yearoffounded ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Year of Founded"
                name="yearoffounded"
                value={yearoffounded}
                onChange={(e) => {
                  setYearofFounded(e.target.value);
                }}
              />
              {errors.yearoffounded && (
                <Div style={{ color: "red" }}>
                  Year of Founded {errors.yearoffounded}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Budget <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("budget")}
                className={`col-12 input-box2 ${
                  errors.budget ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Budget"
                name="budget"
                value={budget}
                onChange={(e) => {
                  setBudget(e.target.value);
                }}
              />
              {errors.budget && (
                <Div style={{ color: "red" }}>Budget {errors.budget}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Budget Availability <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("budgetAvai")}
                className={`col-12 input-box2 ${
                  errors.budgetAvai ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Budget Availability"
                name="budgetAvai"
                value={budgetAvai}
                onChange={(e) => {
                  setBudgetAvai(e.target.value);
                }}
              />
              {errors.budgetAvai && (
                <Div style={{ color: "red" }}>
                  Budget availability {errors.budgetAvai}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Previous Purchase <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("Prepurchase")}
                id="outlined-basic"
                value={Prepurchase}
                options={DecisionDropdown}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  if (newValue === "Yes") {
                    console.log("Check");
                    setPrepurchase(newValue);
                    setValue("Prepurchase", newValue);
                  } else {
                    setPrepurchase(newValue);
                    setValue("Prepurchase", newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "5px",

                      width: "100%",
                    }}
                    placeholder="Previous Purchase"
                  />
                )}
              ></Autocomplete>
              {errors.Decissionmaker && (
                <Div style={{ color: "red" }}>
                  Previous purchase {errors.Decissionmaker}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Company Name <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("cname")}
                className={`col-12 input-box2 ${
                  errors.cname ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Company Name"
                onChange={(e) => {
                  setCname(e.target.value);
                }}
                value={cname}
              />
              {errors.cname && (
                <Div style={{ color: "red" }}>Company name {errors.cname}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Lead Status <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                options={[
                  { status: "Open" },
                  { status: "In Progress" },
                  { status: "Nurture" },
                  { status: "Droped" },
                  { status: "Hot" },
                  { status: "Warm" },
                  { status: "Cool" },
                ]}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.status) {
                    return option?.status;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.status === value || value === ""
                }
                value={leadStatusName}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setleadStatusName(newValue?.status);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Lead Status"
                  />
                )}
              />
              {errors.jobtitle1 && (
                <Div style={{ color: "red" }}>
                  Lead status {errors.jobtitle1}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Life Cycle Stage <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                options={[
                  { status: "Contact" },
                  { status: "Subscriber" },
                  { status: "Lead" },
                  { status: "Opportunity" },
                  { status: "Customer" },
                  { status: "Demographic" },
                ]}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.status) {
                    return option?.status;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.status === value || value === ""
                }
                value={leadCycleStage}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setleadCycleStage(newValue?.status);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Cycle Stage"
                  />
                )}
              />
              {errors.leadCycleStag && (
                <Div style={{ color: "red" }}>
                  Cycle stage {errors.leadCycleStage}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Phone Number <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("phone")}
                className={`col-12 input-box2 ${
                  errors.phone ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Phone No"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              {errors.phone && (
                <Div style={{ color: "red" }}>Phone number {errors.phone}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Email Id <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("email")}
                className={`col-12 input-box2 ${
                  errors.email ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Email Id"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              {errors.email && (
                <Div style={{ color: "red" }}>E-mail id {errors.email}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Building Name / No <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("BuildingNo")}
                className={`col-12 input-box2 ${
                  errors.BuildingNo ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Building DoorNo"
                value={BuildingNo}
                onChange={(e) => {
                  setBuildingNo(e.target.value);
                }}
              />
              {errors.BuildingNo && (
                <Div style={{ color: "red" }}>
                  Building Name / No {errors.BuildingNo}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Street name <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("strname")}
                className={`col-12 input-box2 ${
                  errors.strname ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Street Name"
                value={strname}
                onChange={(e) => {
                  setStrname(e.target.value);
                }}
              />
              {errors.strname && (
                <Div style={{ color: "red" }}>Street name {errors.strname}</Div>
              )}
            </Grid>
            <Grid item>
              <Typography sx={[input_label]}>
                Area Name <span className="required">*</span>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                {...register("areaname")}
                className={`col-12 input-box2 ${
                  errors.BuildingNo ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Area Name"
                value={areaname}
                onChange={(e) => {
                  setAreaName(e.target.value);
                }}
              />
              {errors.areaname && (
                <Div style={{ color: "red" }}>Area name {errors.areaname}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Country <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("selectedCountry1")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.name) {
                    return option?.name;
                  }
                  return "";
                }}
                options={Country.getAllCountries()}
                value={selectedCountry1}
                onChange={(e, newValue) => {
                  setValue("selectedCountry1", newValue?.name);
                  console.log("newValue", newValue);
                  setSelectedCountry(newValue);
                  setSelectedCountry1(newValue?.name);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="Country" />
                )}
              />
              {errors.selectedCountry1 && (
                <Div style={{ color: "red" }}>
                  Country {errors.selectedCountry1}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                State <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("selectedState1")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.name) {
                    return option?.name;
                  }
                  return "";
                }}
                value={selectedState1}
                options={State.getStatesOfCountry(selectedCountry?.isoCode)}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setSelectedState(newValue);
                  setValue("selectedState1", newValue?.name);
                  setSelectedState1(newValue?.name);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value || value === ""
                }
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "5px",

                      width: "100%",
                    }}
                    placeholder="State"
                  />
                )}
              />
              {errors.selectedState1 && (
                <Div style={{ color: "red" }}>
                  State {errors.selectedState1}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                City <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("selectedCity1")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.name) {
                    return option?.name;
                  }
                  return "";
                }}
                options={City.getCitiesOfState(
                  selectedState?.countryCode,
                  selectedState?.isoCode
                )}
                value={selectedCity1}
                onChange={(e, newValue) => {
                  setValue("selectedCity1", newValue?.name);
                  console.log("newValue", newValue);
                  setSelectedCity(newValue);
                  setSelectedCity1(newValue?.name);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField {...params} placeholder="City" />
                )}
              />
              {errors.selectedCity1 && (
                <Div style={{ color: "red" }}>City {errors.selectedCity1}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Source <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("leadsource1")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value || value === ""
                }
                value={leadsource1}
                options={leadsource || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setLeadscource1(newValue);
                  setValue("leadsource1", newValue);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Lead Source"
                  />
                )}
              />
              {errors.leadsource1 && (
                <Div style={{ color: "red" }}>Source {errors.leadsource1}</Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Company Turn Over <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("turnover")}
                className={`col-12 input-box2 ${
                  errors.turnover ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead CompanyTurn Over"
                name="turnover"
                value={turnover}
                onChange={(e) => {
                  setTurnOver(e.target.value);
                }}
              />
              {errors.turnover && (
                <Div style={{ color: "red" }}>
                  Company turn over {errors.turnover}
                </Div>
              )}
            </Grid>
            <Grid item>
              <Typography sx={[input_label]}>
                Decission Maker <span className="required">*</span>
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("decissionmaker")}
                id="outlined-basic"
                value={decissionmaker == true ? "Yes" : "No"}
                options={DecisionDropdown}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  if (newValue === "Yes") {
                    console.log("Check");
                    setDecissionmaker(true);
                    setValue("decissionmaker", true);
                  } else {
                    setDecissionmaker(false);
                    setValue("decissionmaker", false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "5px",

                      width: "100%",
                    }}
                    placeholder="Decission maker"
                  />
                )}
              ></Autocomplete>
              {errors.Decissionmaker && (
                <Div style={{ color: "red" }}>
                  Decission maker {errors.Decissionmaker}
                </Div>
              )}
            </Grid>
            <Grid item>
              <Typography sx={[input_label]}>
                No of Employees <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("noofemployee")}
                className={`col-12 input-box2 ${
                  errors.noofemployee ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Lead No Of Employees"
                name="noofemployee"
                value={noofemployee}
                onChange={(e) => {
                  setNoofEmployee(e.target.value);
                }}
              />
              {errors.noofemployee && (
                <Div style={{ color: "red" }}>
                  No of employees {errors.noofemployee}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Year of Experience <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                {...register("yrofexp")}
                className={`col-12 input-box2 ${
                  errors.yrofexp ? "is-invalid" : ""
                }`}
                id="outlined-basic"
                placeholder="Years of Experience"
                name="yrofexp"
                value={yrofexp}
                onChange={(e) => {
                  setYrofexp(e.target.value);
                }}
              />
              {errors.yrofexp && (
                <Div style={{ color: "red" }}>
                  Years of experience {errors.yrofexp}
                </Div>
              )}
            </Grid>

            <Grid item>
              <Typography sx={[input_label]}>
                Industry <span className="required">*</span>
              </Typography>
            </Grid>

            <Grid item>
              <Autocomplete
                className="search-select3 col-12"
                {...register("leadindustry1")}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.value) {
                    return option?.value;
                  }
                  return "";
                }}
                value={leadindustry1}
                options={leadindustry || []}
                onChange={(e, newValue) => {
                  console.log("newValue", newValue);
                  setLeadindustry1(newValue);
                  setValue("leadindustry1", newValue);
                }}
                id="outlined-basic"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      borderRadius: "10px",
                    }}
                    placeholder="Lead Industry"
                  />
                )}
              />
              {errors.leadindustry1 && (
                <Div style={{ color: "red" }}>
                  Industry {errors.leadindustry1}
                </Div>
              )}
            </Grid>
          </Customgrid>
        </JumboScrollbar>
        <Grid container mt={2} align="center" spacing={2} pb={2}>
          <Grid item align="right" xs={6}>
            <CustomButton
              label="Update"
              onClick={onsubmit}
              type="submit"
              Icon="saveIcon"
            />
          </Grid>
          <Grid item align="left" xs={6}>
            <Cancelbutton
              onClick={() => {
                cancelFunc();
              }}
            />
          </Grid>
        </Grid>
        {/* </form> */}
      </Box>
    </>
  );
};

export default memo(QualifiedLeadsEdit);
