import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { BiEdit, BiFilterAlt } from "react-icons/bi";
import { HiOutlineSearch } from "react-icons/hi";
import { FaFileInvoice } from "react-icons/fa";
import {
  MdDelete,
  MdOutlinePayment,
  MdOutlineFileUpload,
} from "react-icons/md";
import { RiRefreshFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { apiConfig } from "app/services/config";
import { BASE_URL } from "app/services/auth-services";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import {
  ButtonStyle,
  ComponentHeading,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  DisplayFlex,
  FontStyle,
  input_label,
} from "../../widgets/CRMStyles";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
  errorFilter,
  fetchTransactionSeries,
  requiredSpan,
  toLocaleCurrency,
} from "app/pages/widgets/StylesComponents";
import { useQuery } from "react-query";

let Transaction = [{ value: "Online" }, { value: "Cash" }, { value: "UPI" }];

const CreatePayments = ({ setOpen, datas, GetRecords, type = "post" }) => {
  const [Plan, setPlan] = useState([]);
  const [customerDatas, setCustomerDatas] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);

  const [addFields, setAddFields] = useState({
    CustomerName: "",
    PaymentDate: "",
    InvoiceDate: "",
    InvoiceNo: "",
    PaidAmount: "",
    InvoiceAmount: "",
    ModeofPayment: "",
    PaymentDetails: "",
    TransactionNo: "",
  });

  const [seriesValue, setSeriesValue] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [save, setSave] = useState(false);
  const [errors, setErrors] = useState({});

  const handleClickBack = () => {
    setAddFields({
      CustomerName: "",
      PaymentDate: "",
      InvoiceDate: "",
      InvoiceNo: "",
      PaidAmount: "",
      InvoiceAmount: "",
      ModeofPayment: "",
      PaymentDetails: "",
      TransactionNo: "",
    });

    setOpen(false);
  };

  // onChange Invoice name
  const onChangeCustomer = (e, value) => {
    console.log("newValuesss", value);

    let list = { ...addFields };
    list.CustomerName = value?.Subscription_Id?.Customer_Name || "";
    // list.Customer_Id = value?.Customer_Id || ""
    list.InvoiceAmount = value?.NetAmount || 0;
    list.PaidAmount = value?.NetAmount || 0;
    list.InvoiceNo = value?.Invoice_Id || "";
    list.Invoice_Id = value?.id || "";
    list.InvoiceDate = value?.Invoice_Date?.split("T")[0] || "";
    setAddFields(list);
  };

  const getPlanRecords = () => {
    axios
      .get(BASE_URL + "/superadmin/planlist/?page=1", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((response) => {
        console.log("plan", response.data);
        setPlan(response.data.results);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // invoice datas
  const FetchInvoiceData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/superadmin/InvoiceFullList/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      setInvoiceData(response.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  // generateInvoice function
  const generateInvoice = async (invid) => {
    let payload = {
      id: invid,
      Is_Archived: true,
      Updated_Date: new Date().toISOString(),
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };

    try {
      const res = await axios.patch(
        `${BASE_URL}/superadmin/Invoice/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            Db: localStorage.getItem("DBName"),
          },
        }
      );
      if (res.data) {
        toast.success("Invoice is Created successfully");
        GetRecords();
        handleClickBack();
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleCreateSubscription = () => {
    let error = errorFilter(addFields);
    console.log("error", addFields, error);
    setErrors(error);

    if (Object.keys(error)?.length > 0) {
      toast.warning(
        `some fields are empty ${Object.keys(error)?.map((item) => item)}${
          Object.keys(error)?.length > 0 && ", "
        }`,
        {
          position: "top-right",
          autoClose: 500,
        }
      );
    } else {
      let payload = {
        Payment_Id:
          seriesValue === "Manual" || type == "put" ? addFields?.PaymentId : "",
        Invoice_Id: addFields?.Invoice_Id,
        Payment_Mode: addFields?.ModeofPayment,
        Customer_Name: addFields?.CustomerName,
        Payment_details: addFields?.PaymentDetails,
        Transaction_No: addFields?.TransactionNo,
        InvoiceAmount: addFields?.InvoiceAmount,
        TaxAmount: 0,
        NetAmount: 0,
        paid_amount: addFields?.PaidAmount,
        Payment_Date: addFields?.PaymentDate,
        Created_Date: new Date().toISOString(),
        Updated_Date: new Date().toISOString(),
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
      if (type === "put") {
        payload.id = datas?.id;
      }

      console.log("payload", payload);
      axios?.[type === "invoice" ? "post" : type](
        `${BASE_URL}/superadmin/Paymentdetails/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        }
      )
        .then((res) => {
          toast.success("Subscription is Created successfully");
          generateInvoice(addFields?.Invoice_Id);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Somthing Went Wrong");
        });
    }
  };

  useEffect(() => {
    const transactionSeries = async () => {
      const series = await fetchTransactionSeries("Payments");

      setSeriesValue(series);
    };
    transactionSeries();
    FetchInvoiceData();
  }, []);

  useEffect(() => {
    let invoice = invoiceData?.find(
      (inv) =>
        inv?.id ==
        (typeof datas?.Invoice_Id !== "string" ? datas?.Invoice_Id : datas?.id)
    );
    console.log("Invoice : ", invoice, invoiceData);

    if (type === "put" && datas) {
      setAddFields({
        PaymentId: datas?.Payment_Id || "",
        CustomerName: datas?.Customer_Name || "",
        PaymentDate: datas?.Payment_Date || "",
        InvoiceDate: invoice?.Invoice_Date?.split("T")[0],
        Invoice_Id: datas?.Invoice_Id || "",
        InvoiceNo: invoice?.Invoice_Id || "",
        TransactionNo: datas?.Transaction_No || "",
        PaidAmount: datas?.paid_amount || "",
        InvoiceAmount: datas?.InvoiceAmount || "",
        ModeofPayment: datas?.Payment_Mode || "",
        PaymentDetails: datas?.Payment_details || "",
      });
    } else if (type === "invoice") {
      setAddFields((prev) => ({
        ...prev,
        CustomerName: invoice?.Subscription_Id?.Customer_Name,
        InvoiceDate: datas?.Invoice_Date?.split("T")[0],
        Invoice_Id: datas?.id,
        InvoiceNo: datas?.Invoice_Id,
        PaidAmount: datas?.NetAmount,
        InvoiceAmount: datas?.NetAmount,
      }));
    }
  }, [datas, invoiceData]);

  console.log("Invoice : ", type, datas, invoiceData);

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Payment Id {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <FormControl className="col-12">
            <TextField
              sx={{
                "& fieldset": { borderRadius: "5px" },
                MinWidth: "325px",
                width: "100%",
              }}
              className={`col-12 input-box2`}
              name="PaymentId"
              id="outlined-basic"
              variant="outlined"
              value={addFields?.PaymentId}
              onChange={(e) =>
                setAddFields({ ...addFields, PaymentId: e.target.value })
              }
              placeholder="# Payment Id"
              disabled={seriesValue == "Automatic" ? true : ""}
            />
          </FormControl>
          {errors?.PaymentId && (
            <Div style={{ color: "red" }}>{errors?.PaymentId}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>
            Transaction No {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <FormControl className="col-12">
            <TextField
              sx={{
                "& fieldset": {
                  borderRadius: "5px",
                },
                MinWidth: "325px",
                width: "100%",
              }}
              value={addFields?.TransactionNo}
              onChange={(e) =>
                setAddFields({ ...addFields, TransactionNo: e.target.value })
              }
              className={`col-12 input-box2`}
              name="TransactionNo"
              id="outlined-basic"
              placeholder="Transaction No"
              variant="outlined"
            />
          </FormControl>
          {errors?.TransactionNo && (
            <Div style={{ color: "red" }}>{errors?.TransactionNo}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Invoice No {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          {type === "invoice" ? (
            <TextField
              sx={{
                "& fieldset": {
                  borderRadius: "5px",
                },
                MinWidth: "325px",
                width: "100%",
              }}
              value={addFields?.InvoiceNo}
              name="InvoiceNo"
              className={`col-12 input-box2`}
              id="outlined-basic"
              type="text"
              placeholder="Invoice No"
              variant="outlined"
            />
          ) : (
            <FormControl className="col-12">
              <Autocomplete
                className="search-select3"
                name="InvoiceNo"
                options={invoiceData}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option && option?.Invoice_Id) {
                    return option?.Invoice_Id;
                  }
                  return "";
                }}
                value={addFields?.InvoiceNo}
                onChange={(e, newValue) => {
                  onChangeCustomer(e, newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Invoice No" />
                )}
              />
            </FormControl>
          )}
          {errors?.InvoiceNo && (
            <Div style={{ color: "red" }}>{errors?.InvoiceNo}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Invoice Date {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
              MinWidth: "325px",
              width: "100%",
            }}
            value={addFields?.InvoiceDate}
            name="Invoice Date"
            className={`col-12 input-box2`}
            id="outlined-basic"
            type="date"
            placeholder="Invoice Date"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Customer Name {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
              MinWidth: "325px",
              width: "100%",
            }}
            value={addFields?.CustomerName}
            name="CustomerName"
            // onChange={(e) =>
            //   setAddFields({ ...addFields, CustomerName: e.target.value })
            // }
            className={`col-12 input-box2`}
            id="outlined-basic"
            placeholder="Customer Name"
            variant="outlined"
          />
          {errors?.CustomerName && (
            <Div style={{ color: "red" }}>{errors?.CustomerName}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Payment Date {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
              MinWidth: "325px",
              width: "100%",
            }}
            value={addFields?.PaymentDate}
            onChange={(e) =>
              setAddFields({ ...addFields, PaymentDate: e.target.value })
            }
            className={`col-12 input-box2`}
            name="PaymentDate"
            id="outlined-basic"
            type="date"
            placeholder="Payment Date"
            variant="outlined"
            inputProps={{
              min: new Date().toISOString().split("T")[0],
            }}
          />
          {errors?.PaymentDate && (
            <Div style={{ color: "red" }}>{errors?.PaymentDate}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>
            Invoice Amount {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
              MinWidth: "325px",
              width: "100%",
            }}
            value={addFields?.InvoiceAmount}
            name="InvoiceAmount"
            // onChange={(e) =>
            //   setAddFields({ ...addFields, InvoiceAmount: e.target.value || 0 })
            // }
            className={`col-12 input-box2`}
            id="outlined-basic"
            placeholder="Invoice Amount"
            variant="outlined"
          />
          {errors?.InvoiceAmount && (
            <Div style={{ color: "red" }}>{errors?.InvoiceAmount}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Paid Amount {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
              MinWidth: "325px",
              width: "100%",
            }}
            value={addFields?.PaidAmount}
            name="PaidAmount"
            type="number"
            onChange={(e) =>
              setAddFields({ ...addFields, PaidAmount: e.target.value })
            }
            className={`col-12 input-box2`}
            id="outlined-basic"
            placeholder="Paid Amount"
            variant="outlined"
          />
          {errors?.PaidAmount && (
            <Div style={{ color: "red" }}>{errors?.PaidAmount}</Div>
          )}
        </Grid>
        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>Payment Mode {requiredSpan}</Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <Autocomplete
            className="search-select3"
            name="PayMethod"
            options={[
              { PayMethod: "Credit Cards" },
              { PayMethod: "Debit Cards" },
              { PayMethod: "Cash" },
              { PayMethod: "Paper Checks" },
              { PayMethod: "eChecks" },
              { PayMethod: "Digital Payments" },
              { PayMethod: " Money Orders" },
            ]}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option && option?.PayMethod) {
                return option?.PayMethod;
              }
              return "";
            }}
            value={addFields?.ModeofPayment}
            onChange={(e, newValue) => {
              setAddFields({
                ...addFields,
                ModeofPayment: newValue?.PayMethod || "",
              });
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Payment Mode" />
            )}
          />
          {errors?.ModeofPayment && (
            <Div style={{ color: "red" }}>{errors?.ModeofPayment}</Div>
          )}
        </Grid>

        <Grid item xs={12} md={2} sx={{ py: 0.4, px: 1 }}>
          <Typography sx={input_label}>
            Payment Details {requiredSpan}
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} sx={{ py: 0.4, px: 1 }}>
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "5px",
              },
              MinWidth: "325px",
              width: "100%",
            }}
            value={addFields?.PaymentDetails}
            name="PaymentDetails"
            onChange={(e) =>
              setAddFields({ ...addFields, PaymentDetails: e.target.value })
            }
            className={`col-12 input-box2`}
            id="outlined-basic"
            placeholder="Payment Details"
            variant="outlined"
          />
          {errors?.PaymentDetails && (
            <Div style={{ color: "red" }}>{errors?.PaymentDetails}</Div>
          )}
        </Grid>
      </Grid>

      <Div
        sx={{
          my: 3,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 3,
        }}
      >
        <CustomButton
          label={type === "put" ? "Update" : "Save"}
          Icon="saveIcon"
          onClick={handleCreateSubscription}
        />
        <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
      </Div>

      <DialogBox
        open={save}
        onClickYes={() => {
          setSave(false);
          handleClickBack();
        }}
        onClickNo={() => setSave(false)}
      />
    </>
  );
};

export default CreatePayments;
