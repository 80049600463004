import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country } from "country-state-city";
import { toast, ToastContainer } from "react-toastify";
import { apiConfig } from "app/services/config";
import {
  ButtonStyle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  FontStyle,
  input_label,
} from "../../widgets/CRMStyles";
import { BASE_URL } from "app/services/auth-services";
import {
  Cancelbutton,
  CustomButton,
  DialogBox,
} from "app/pages/widgets/StylesComponents";

let Countries = Country.getAllCountries();

const EditAddon = ({ setListAddon, setEditAddon, Editdatas, scrollHeight }) => {
  const [addonid, setaddonid] = useState("");
  const [addonname, setaddonname] = useState("");
  const [addonuser, setaddonuser] = useState("");
  const [currency, setcurrency] = useState("");
  const [price, setprice] = useState("");
  const [save, setSave] = useState(false);

  const [Modaldata, setModaldata] = useState([]);

  const handleClickBack = () => {
    setEditAddon(false);
    setListAddon(true);
  };

  useEffect(() => {
    if (Editdatas != undefined && Editdatas.length != 0) {
      setModaldata(Editdatas);
      setaddonid(Editdatas?.Addon_Id);
      setaddonname(Editdatas?.Name);
      setaddonuser(Editdatas?.NoofUsers);
      setcurrency(Editdatas?.Currency_code);
      setprice(Editdatas?.Price);
      console.log("editdatas", Editdatas);
    }
  }, [Editdatas]);

  const handleUpdateStorage = () => {
    if (
      addonname === "" ||
      addonname === undefined ||
      addonname === null ||
      addonuser === "" ||
      addonuser === undefined ||
      addonuser === null ||
      // country === "" || country === undefined || country === null ||

      price === "" ||
      price === undefined ||
      price === null
    ) {
      toast.error("Invalid Datas");
    } else {
      let payload = {
        id: Modaldata.id,
        Addon_Id: addonid,
        Name: addonname,
        Price: price,
        NoofUsers: addonuser,
        // "Country": country,
        // Currency_code: currency,
        // Plan_Id: 1,
        Organization_Id: localStorage.getItem("OrganizationId"),
        // Subscription_Id: 1,
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
        Organization_Id: localStorage.getItem("OrganizationId"),
      };
      console.log("payload", payload);
      axios
        .put(`${BASE_URL}/superadmin/Addon/`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: localStorage.getItem("DBName"),
          },
        })
        .then((res) => {
          console.log("2", res.data);
          if (res) {
            toast.success("Storage is Updated successfully");
            handleClickBack();
          } else {
            toast.error("Check your data");
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Somthing Went Wrong");
        });
    }
  };

  return (
    <>
      <Grid container columns={12} spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Addon Id</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={addonid}
            className="w-100 input-box"
            id="outlined-basic"
            placeholder="Storage Name"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Addon Name</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={addonname}
            className="w-100 input-box"
            id="outlined-basic"
            placeholder="Storage Name"
            variant="outlined"
            onChange={(e) => {
              setaddonname(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>No Of User </Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={addonuser}
            className="w-100 input-box"
            id="outlined-basic"
            type="Storage Size"
            placeholder="Storage Size"
            variant="outlined"
            onChange={(e) => {
              setaddonuser(e.target.value);
            }}
          />
        </Grid>

        {/* <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Currency</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <FormControl className="w-100  ">
            <Autocomplete
              className="search-select w-100 "
              id="outlined-basic"
              name="currency"
              options={Countries}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.currency) {
                  return option.currency;
                }
              }}
              value={currency}
              onChange={(item, newvalue) => {
                setcurrency(newvalue?.currency);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Currency" />
              )}
            />
          </FormControl>
        </Grid> */}

        <Grid item xs={12} md={3}>
          <Typography sx={[input_label]}>Price</Typography>
        </Grid>

        <Grid item xs={12} md={9}>
          <TextField
            value={price}
            className="w-100 input-box"
            id="outlined-basic"
            type="number"
            placeholder="Price"
            variant="outlined"
            onChange={(e) => {
              setprice(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Div
        sx={{
          my: 3,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          gap: 3,
        }}
      >
        <CustomButton
          label="Update"
          Icon="saveIcon"
          onClick={handleUpdateStorage}
        />
        <Cancelbutton label="Cancel" onClick={() => setSave(true)} />
      </Div>

      <DialogBox
        open={save}
        onClickYes={() => {
          setSave(false);
          handleClickBack();
        }}
        onClickNo={() => setSave(false)}
      />
    </>
  );
};

export default EditAddon;
