import React, { useEffect } from "react";
import {
  Popover,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Box,
  Typography,
  Grid,
  Input,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import AlarmIcon from "@mui/icons-material/Alarm";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import useStateRef from "react-usestateref";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { SelectChangeEvent } from "@mui/material/Select";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EventPopOver = ({
  anchorEl,
  onClose,
  event,
  open,
  events,
  startDateObject,
  endDateObject,
  toggleSave,
  sendToggleSaveStatus,
  isEditingMode,
}) => {
  const gapi = window.gapi;
  const [title, setTitle] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateAndTime, setDateAndTime] = useState();
  const [recurring, setRecurring] = useState("don't repeat");
  const [notification, setNotification] = useState("don't remind me");
  const [location, setLocation] = useState("");
  const [eventsArray, setEventsArray] = useState([events]);
  const [startDay, setStartDay, startDayRef] = useStateRef();
  const [startMonth, setStartMonth, startMonthRef] = useStateRef();
  const [startYear, setStartYear, startYearRef] = useStateRef();
  const [endDay, setEndDay, endDayRef] = useStateRef();
  const [endMonth, setEndMonth, endMonthRef] = useStateRef();
  const [endYear, setEndYear, endYearRef] = useStateRef();
  const [startTime, setStartTime, startTimeRef] = useStateRef("");
  const [endTime, setEndTime, endTimeRef] = useStateRef("");
  const [isEditing, setIsEditing, isEditingRef] = useStateRef(false);
  const [editTitle, setEditTitle] = useState("");
  const [editStartTime, setEditStartTime] = useState("00:00");
  const [editEndTime, setEditEndTime] = useState("00:00");
  const [editStartHour, setEditStartHour] = useState();
  const [editStartMinute, setEditStartMinute] = useState();
  const [editStartDay, setEditStartDay] = useState();
  const [selectedAttendees, setSelectedAttendees, selectedAttendeesRef] =
    useStateRef([]);
  const [attendees, setAttendees] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [toggleSaveStatus, setToggleSaveStatus, toggleSaveStatusRef] =
    useStateRef(toggleSave);

  useEffect(() => {
    setIsEditing(isEditingMode);
    // setStartTime(dayjs(event?.time))
  }, []);

  const handleUpdateEvent = (id) => {
    let actionUrl = "user/UserTaskCalendarCRUD/";
    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const APIURL = `${BASE_URL}/${actionUrl}`;
    const headers = {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const payload = {
      id: id,
      summary: title,
      location: location,
      description: "",
      start: startDate,
      end: endDate,
      attendees: attendees,
      reminders: "",
      Is_Deleted: false,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    axios
      .put(APIURL, payload, { headers: headers })
      .then((res) => {
        if (res.status == 200) {
          onClose();
          setToggleSaveStatus(!toggleSaveStatusRef.current);
          sendToggleSaveStatus(toggleSaveStatusRef.current);
        }
      })
      .catch((error) => console.error("Error while updating", error));
  };
  const getContactList = () => {
    let actionUrl = "user/ContactAdditional/";
    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const APIURL = `${BASE_URL}/${actionUrl}`;
    // const APIURL = "http://139.59.44.185:8000/user/ContactAdditional/?Is_Deleted=False&Contact_Id=1"
    const params = {
      Is_Deleted: "False",
    };
    const headers = {
      db: item,
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    axios
      .get(APIURL, { params: params, headers: headers })
      .then((res) => {
        console.log("contactListResponse", res);
        setContactList(res.data.results);
      })
      .catch((error) => console.error("Error in ContactList", error));
  };

  useEffect(() => {
    getContactList();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const extractParts = (dateString) => {
    const dateParts = dateString.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);
    return {
      year,
      month,
      day,
    };
  };
  const extractDateParts = (startDateString, endDateString) => {
    if (startDateString) {
      const startDate = extractParts(startDateString);
      setStartYear(startDate.year);
      setStartMonth(startDate.month);
      setStartDay(startDate.day);
    }
    if (endDateString) {
      const endDate = extractParts(endDateString);
      setEndYear(endDate.year);
      setEndMonth(endDate.month);
      setEndYear(endDate.year);
    }
  };
  const extractTimeParts = (timeString) => {
    const timeParts = timeString.split(":");
    const hour = timeParts[0].padStart(2, 0);
    const minute = timeParts[1].padStart(2, 0);
    return {
      hour,
      minute,
    };
  };

  const convToDateObject = () => {
    setStartYear(startDateObject ? startDateObject.getFullYear() : "");
    setStartMonth(startDateObject ? startDateObject.getMonth() : "");
    setStartDay(startDateObject ? startDateObject.getDate() : "");

    setEndYear(endDateObject ? endDateObject.getFullYear() : "");
    setEndMonth(endDateObject ? endDateObject.getMonth() : "");
    setEndDay(endDateObject ? endDateObject.getDate() : "");
  };
  const formattedDate = (dateObject) => {
    const originalDate = new Date(dateObject);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const createEvent = () => {
    let actionUrl = "/user/UserTaskCalendarCRUD/";
    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const APIURL = `${BASE_URL}${actionUrl}`;
    // const APIURL = "http://139.59.44.185:8000/user/UserTaskCalendarCRUD/"
    const time = () => {
      const dateInput = startDate;
      const [year, month, day] = dateInput.split("-").map(Number);
      const {
        $H: hours,
        $m: minutes,
        $s: seconds,
        $ms: milliseconds,
      } = startTime;
      const combinedDate = new Date(
        year,
        month - 1,
        day,
        hours,
        minutes,
        seconds,
        Math.floor(milliseconds / 1000)
      );
      return combinedDate.toISOString();
    };
    const payload = {
      summary: title,
      location: location,
      description: "",
      start: startDate,
      end: endDate,
      attendees: attendees,
      reminders: "",
      Is_Deleted: false,
      Organization_Id: localStorage.getItem("OrganizationId"),
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      db: item,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(APIURL, payload, { headers: headers })
      .then((res) => {
        if (res.status == 200) {
          onClose();
          setToggleSaveStatus(!toggleSaveStatusRef.current);
          sendToggleSaveStatus(toggleSaveStatusRef.current);
        }
      })
      .catch((error) => {
        console.error("Error while creating an event", error);
      });
  };
  const sendNotification = () => {
    let actionUrl = "/user/MailsendingSpecifiedEvent/";
    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const APIURL = `${BASE_URL}${actionUrl}`;
    const payload = {
      id: 1,
      Schedule_Date: "2023-09-12T06:20:34Z",
      Schedule_Option: "5 Minutes Before",
      body: " Check",
      subject: "test",
      Recipients: [
        "ravimech476@gmail.com",
        "ravikumar.r@foyertech.com",
        "sam.imm11@gmail.com",
      ],
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      db: item,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(APIURL, payload, { headers: headers })
      .then((res) => {
        console.log("emailResponse", res);
      })
      .catch((error) => {
        console.error("Error while Sending notification", error);
      });
  };

  function convertToISOString(startDateStr, startTimeObj) {
    const [startDateYear, startDateMonth, startDateDay] = startDateStr
      .split("-")
      .map(Number);

    const startTimeHours = startTimeObj["$H"];
    const startTimeMinutes = startTimeObj["$m"];

    const startDate = new Date(
      Date.UTC(startDateYear, startDateMonth - 1, startDateDay)
    );
    startDate.setUTCHours(startTimeHours);
    startDate.setUTCMinutes(startTimeMinutes);

    const isoString = startDate.toISOString();

    return isoString;
  }

  const handleCreateEventInGoogle = () => {
    var event = {
      summary: title,
      location: location,
      description: "",
      start: {
        dateTime: convertToISOString(startDate, startTime), //date: object {"$L": "en"} //time 10:00 am
        timeZone: "UTC",
      },
      end: {
        dateTime: convertToISOString(startDate, endTime),
        timeZone: "UTC",
      },
      attendees: attendees,
      reminders: {
        useDefault: notification ? false : true,

        overrides: notification && [
          {
            method: "popup",
            minutes: notification,
          },
        ],
      },
      guestsCanSeeOtherGuests: true,
    };
    var request = gapi.client.calendar.events.insert({
      calendarId: "primary",
      resource: event,
      sendUpdates: "all",
    });
    request.execute(
      (event) => {
        window.open(event.htmlLink);
        setToggleSaveStatus(!toggleSaveStatusRef.current);
        sendToggleSaveStatus(toggleSaveStatusRef.current);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleSave = () => {
    createEvent();
    sendNotification();
    handleCreateEventInGoogle();
  };

  useEffect(() => {
    sendToggleSaveStatus(toggleSaveStatusRef.current);
  }, [toggleSaveStatus]);

  const deleteEvent = async (id) => {
    let actionUrl = "/user/UserTaskCalendarCRUD/";
    const token = localStorage.getItem("accesstoken");
    const item = "crmuseradmin1";
    const APIURL = `${BASE_URL}/${actionUrl}`;
    const params = {
      id: id,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
      db: item,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    await axios
      .delete(APIURL, { params: params, headers: headers })
      .then((res) => {
        console.log("deleteResponse", res);
        setToggleSaveStatus(!toggleSaveStatusRef.current);
        sendToggleSaveStatus(toggleSaveStatusRef.current);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage =
            error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });

    //Google calendar delete
    console.log("id", id);
    const token1 = localStorage.getItem("access_token");
    const headers1 = {
      Authorization: `Bearer ${token1}`,
    };
    await axios
      .delete(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events/${id}`,
        { headers: headers1 }
      )
      .then((res) => {
        console.log(`Event with ID ${id} deleted successfully.`);
        setToggleSaveStatus(!toggleSaveStatusRef.current);
        sendToggleSaveStatus(toggleSaveStatusRef.current);
      });
  };

  const clearForm = () => {
    setTitle("");
    setStartDate("");
    setEndDate("");
    setRecurring("don't repeat");
    setNotification("don't remind me");
    setLocation("");
    setStartTime("");
  };
  const handleDiscard = () => {
    clearForm();
    onClose();
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatTimeRange = (DateTime) => {
    const time = formatTime(DateTime);

    const hours = new Date(DateTime).getHours().toString().padStart(2, "0");
    const minutes = new Date(DateTime).getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  useEffect(() => {
    if (open) {
      setTitle(event?.title);
      setRecurring("don't repeat");
      setNotification("don't remind me");
    }
  }, [open]);

  const handleSelectAttendees = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAttendees(typeof value === "string" ? value.split(",") : value);
    const attendee = selectedAttendeesRef.current.map((attendee) => {
      return {
        email: attendee,
        responseStatus: "needsAction",
      };
    });
    console.log("attendee", attendee);
    setAttendees(attendee);
  };

  return (
    <div className="calendar-event-popover">
      {console.log("selectedAttendees", attendees)}
      {/* {console.log("startDateObject", startDate)} //2023-09-21 */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        style={{ msOverflowY: "scroll" }}
      >
        <Box
          sx={{
            padding: "15px",
            height: "250px",
          }}
        >
          {event ? (
            <div>
              <Typography variant="h2">Event Details</Typography>
              <div className="d-flex flex-row align-items-center">
                <div>
                  {" "}
                  <Typography variant="p">Title</Typography>
                </div>
                :
                <div>
                  {" "}
                  {isEditingRef.current ? (
                    <FormControl>
                      <Input
                        id="title-input"
                        type="text"
                        className="border-none"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.currentTarget.value);
                        }}
                      />
                    </FormControl>
                  ) : (
                    <Typography variant="p">{event.title}</Typography>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div>
                  <Typography variant="p">Start Date:</Typography>
                </div>
                <div>
                  {isEditingRef.current ? (
                    <FormControl>
                      <Input
                        type="date"
                        defaultValue={startDate}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormControl>
                  ) : (
                    <Typography variant="p">
                      {" "}
                      {formatTime(event.start)}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div>
                  <Typography variant="p">End Date:</Typography>
                </div>
                <div>
                  {isEditingRef.current ? (
                    <FormControl>
                      <Input
                        type="date"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      ></Input>
                    </FormControl>
                  ) : (
                    <Typography variant="p">
                      {" "}
                      {formatTime(event.end)}
                    </Typography>
                  )}
                </div>
              </div>
              {/* <div className='d-flex flex-row align-items-center'>
                            <div>Start Time:</div>                           
                            {isEditingRef.current ?

                                <div>
                                    <Input type='text' value={editStartTime} onChange={(e) => setEditStartTime(e.currentTarget.value)}></Input>
                                </div> : <div>{formatTimeRange(event.start)}</div>}
                        </div> */}
              {isEditingRef.current ? (
                ""
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => handleEditClick()}
                >
                  Edit event
                </Button>
              )}
              <IconButton
                color="error"
                onClick={() => {
                  deleteEvent(event.id);
                  onClose();
                  setToggleSaveStatus(!toggleSaveStatus);
                }}
              >
                <DeleteIcon />
              </IconButton>

              {isEditing ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    handleUpdateEvent(event.id);
                  }}
                >
                  Update
                </Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div>
              <Typography variant="h2">Add Event</Typography>
              <form>
                <TextField
                  name="Event_Tittle"
                  label="Title"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  margin="normal"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Invite Attendees</InputLabel>
                  <Select
                    multiple
                    label="Invite Attendees"
                    value={selectedAttendees}
                    // onChange={(e) => setSelectedAttendees(e.target.value)}
                    onChange={handleSelectAttendees}
                    sx={{ minWidth: 150 }}
                  >
                    {contactList.map((list, index) => {
                      return (
                        <MenuItem key={index} value={list.Email_Id}>
                          {list.Email_Id}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Box sx={{ flexGrow: 1 }} className="mt-1">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name="Start_Date"
                        label="startDate"
                        type="date"
                        fullWidth
                        defa
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          name="time"
                          label="Start Time"
                          value={dayjs(startTime)}
                          onChange={(newValue) => {
                            setStartTime(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }} className="mt-2">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name="End_Date"
                        label="endDate"
                        type="date"
                        fullWidth
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          name="time"
                          label="End Time"
                          value={dayjs(endTime)}
                          onChange={(newValue) => {
                            setEndTime(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>

                <FormControl fullWidth margin="normal">
                  <Select
                    name="Recuuring"
                    value={recurring}
                    onChange={(e) => setRecurring(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <AlarmIcon />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="don't repeat">Don't repeat</MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Notification</InputLabel>
                  <Select
                    name="Notification"
                    value={notification}
                    onChange={(e) => setNotification(e.target.value)}
                  >
                    {/* <MenuItem value="don't remind me">Don't remind me</MenuItem>
                                    <MenuItem value="At time of event">At time of event</MenuItem>
                                    <MenuItem value="5 Minutes Before">5 Minutes Before</MenuItem>
                                    <MenuItem value="15 Minutes Before">15 Minutes Before</MenuItem>
                                    <MenuItem value="30 Minutes Before">30 Minutes Before</MenuItem>
                                    <MenuItem value="1 hour Before">1 hour Before</MenuItem>
                                    <MenuItem value="2 hours Before">2 hours Before</MenuItem>
                                    <MenuItem value="1 day Before">1 day Before</MenuItem>
                                    <MenuItem value="1 week Before">1 week Before</MenuItem> */}
                    <MenuItem value={5}>{"5 minutes before"}</MenuItem>
                    <MenuItem value={10}>{"10 minutes before"}</MenuItem>
                    <MenuItem value={15}>{"15 minutes before"}</MenuItem>
                    <MenuItem value={30}>{"30 minutes before"}</MenuItem>
                    <MenuItem value={60}>{"1 hour before"}</MenuItem>
                    <MenuItem value={1440}>{"1 day before"}</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  name="Location"
                  label="Search for a location"
                  fullWidth
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                  margin="normal"
                />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="16px"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDiscard}
                    startIcon={<DeleteIcon />}
                  >
                    Discard
                  </Button>
                </Box>
              </form>
            </div>
          )}
        </Box>
      </Popover>
      
    </div>
  );
};

export default EventPopOver;
