import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ContactForm = ({ isContactFormOpen, setIsContactFormOpen, usrInfo }) => {
  const [open, setOpen] = useState(isContactFormOpen);
  const [formLink, setFormLint] = useState(
    "https://forms.gle/SxWF7P4RZzMKhuN68"
  );
  console.log("usrInfo Check", usrInfo);
  const [userInfo, setUserInfo] = useState(usrInfo);

  useEffect(() => {
    setUserInfo(usrInfo);
  }, [usrInfo]);

  const handleClose = () => {
    setIsContactFormOpen(false);
  };
  const handlePost = () => {
    let token = localStorage.getItem("accesstoken");

    const payload = {
      UserId: localStorage.getItem("UserId"),
      sub: userInfo?.sub,
      subject: formLink,
    };

    const headers = {
      db: localStorage.getItem("DBName"),
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    console.log("payload", payload);
    axios
      .post(`${BASE_URL}/user/LinkdinCRUD/`, payload, { headers: headers })
      .then((res) => {
        console.log("post response", res?.data?.status);
        if (res) {
          toast.success(res?.data?.status);
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage = error.response.data.details || "You don't have permission to do it";
          console.log(errorMessage);
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 100,
          });
        } else {
          toast.error(`${error}`, {
            position: "top-right",
            autoClose: 100,
          });
        }
      });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { minWidth: "500px", minHeight: "300px" } }}
    >
      <DialogTitle>Contact Form</DialogTitle>
      {console.log("userInfo", userInfo?.sub)}
      <DialogContent sx={{ marginTop: "10px" }}>
        <TextField
          style={{
            width: "100%",
            height: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          InputProps={{ style: { fontSize: "16px" } }}
          placeholder="Form Link"
          value={formLink}
          onChange={(e) => {
            setFormLint(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button className="dialog-cancel-btn" onClick={handleClose}>
          Close
        </Button>
        <Button className="dialog-save-btn" onClick={handlePost}>
          Post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactForm;
