import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";
import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import MailIcon from "@mui/icons-material/Mail";
import CreateEmailCampaign from "./campaign-right-side-card/email/CreateEmailCampaign";
import { Dataset } from "@mui/icons-material";
import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import {
  avatarColors,
  cardHeight,
  Colors,
  componentTitle,
  dialogTitle,
  DisplayFlex,
  FontStyle,
} from "../widgets/CRMStyles";
import Div from "@jumbo/shared/Div";
import { IoIosContacts, IoIosMailOpen, IoMdMailUnread } from "react-icons/io";
import { GiClick } from "react-icons/gi";
import Counter from "../widgets/StylesComponents";
import { useQuery, useQueryClient } from 'react-query';

const CampaignDashboard = () => {
  const [openCreateEmailCampaign, setOpenCreateEmailCampaign] = useState(false);

  // Get the all the Contact List

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const [datas, setDatas] = useState(["", "", "", ""]);

  const getData = async () => {
    try {
      const [res1, res2] = await Promise.all([
        axios.put(`${BASE_URL}/user/UserCampaignDashboard/`, {},
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        ),
        axios.post(`${BASE_URL}/user/UserCampaignDashboard/`, {},
          {
            headers: {
              db: item,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        ),
      ]);

      let list = [
        { label: "All Your Contacts", value: 0 },
        { label: "Mail Opened", value: 0, valuePct: 0.0 },
        { label: "Mail Not Opened", value: 0, valuePct: 0.0 },
        { label: "Mail Clicked", value: 0, valuePct: 0.0 },
      ];
      list[0].value = res1?.data?.Total_Contact || 0;
      list[1].value = res2?.data?.Total_Mail_Opened || 0;
      list[1].valuePct = parseFloat(res2?.data?.Mail_Opened_Percentage).toFixed(2) || 0.0;
      list[2].value = res2?.data?.Total_Mail_Not_Opened || 0;
      list[2].valuePct = parseFloat(res2?.data?.Mail_NotOpened_Percentage).toFixed(2) || 0.0;
      list[3].value = res2?.data?.Total_Mail_Clicked || 0;
      list[3].valuePct = parseFloat(res2?.data?.Mail_Cliekced_Percentage).toFixed(2) || 0.0;

      // setDatas(list);
      return list;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  const { data: getCompaignDashLists, isLoading: getCompaignDashListsLoading, isError: getCompaignDashListsError, refetch: getCompaignDashListsRefetch } = useQuery(
    ['getCompaignDashLists'],
    getData,
    { staleTime: Infinity }
  );

  useEffect(() => {
    if (getCompaignDashLists) {
      setDatas(getCompaignDashLists || []);
    }
  }, [getCompaignDashLists]);


  return (
    <>
      <JumboContentLayoutMain>
        {!openCreateEmailCampaign && (
          <>
            <Typography
              sx={[componentTitle, { transform: "translateY(-20px)" }]}
            >
              Campaign{" "}
            </Typography>

            <Box>
              <Grid container spacing={4}>
                {datas?.map((item, idx) => {
                  return (
                    <Grid key={idx} item xs={6} md={3}>
                      <Div
                        sx={{
                          borderRadius: "10px",
                          p: 3,
                          backgroundColor: avatarColors[idx],
                          height: cardHeight,
                          boxSizing: "border-box !important",
                        }}
                      >
                        <Div sx={[DisplayFlex, { gap: "10px" }]}>
                          <Div
                            sx={[
                              DisplayFlex,
                              {
                                justifyContent: "center",
                                height: "60px",
                                width: "90px",
                                borderRight: "1px solid lightgray",
                                // backgroundColor: avatarLightColors[idx],
                              },
                            ]}
                          >
                            {item?.label === "All Your Contacts" && (
                              <IoIosContacts
                                style={{ color: "white", fontSize: 35 }}
                              />
                            )}

                            {item?.label === "Mail Opened" && (
                              <Div>
                                <IoIosMailOpen
                                  style={{ color: "white", fontSize: 30 }}
                                />
                                <Typography
                                  id="Typography-root"
                                  sx={[
                                    FontStyle,
                                    {
                                      color: "whitesmoke", mt: 1,
                                      fontWeight: 600,
                                      textAlign: "center",
                                    },
                                  ]}
                                >
                                  <Counter
                                    maximum={item?.valuePct}
                                    speed={50}
                                  />
                                  %
                                </Typography>
                              </Div>
                            )}
                            {item?.label === "Mail Not Opened" && (
                              <Div>
                                <IoMdMailUnread
                                  style={{ color: "white", fontSize: 30 }}
                                />
                                <Typography
                                  id="Typography-root"
                                  sx={[
                                    FontStyle,
                                    {
                                      color: "whitesmoke", mt: 1,
                                      fontWeight: 600,
                                      textAlign: "center",
                                    },
                                  ]}
                                >
                                  <Counter
                                    maximum={item?.valuePct}
                                    speed={50}
                                  />
                                  %
                                </Typography>
                              </Div>
                            )}
                            {item?.label === "Mail Clicked" && (
                              <Div>
                                <GiClick
                                  style={{ color: "white", fontSize: 30 }}
                                />
                                <Typography
                                  id="Typography-root"
                                  sx={[
                                    FontStyle,
                                    {
                                      color: "whitesmoke", mt: 1,
                                      fontWeight: 600,
                                      textAlign: "center",
                                    },
                                  ]}
                                >
                                  <Counter
                                    maximum={item?.valuePct}
                                    speed={50}
                                  />
                                  %
                                </Typography>
                              </Div>
                            )}
                          </Div>
                          <Div sx={{ width: "70%" }}>
                            <Typography
                              id="Typography-root"
                              sx={{
                                color: "whitesmoke", mt: 1,
                                fontWeight: 600,
                                textAlign: "center",
                                fontSize: "30px",
                              }}
                            >
                              <Counter maximum={item?.value} speed={50} />
                              {/* {} */}
                            </Typography>
                            <Typography
                              id="Typography-root"
                              sx={[
                                FontStyle,
                                {
                                  textAlign: "center",
                                  // transform: "translateX(50%)",
                                  color: "whitesmoke", mt: 1,
                                  fontWeight: 600,
                                },
                              ]}
                            >
                              {item?.label?.toUpperCase()}
                            </Typography>
                          </Div>
                        </Div>
                      </Div>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Div sx={{ mt: { xs: 2, md: 4 } }}>
              <Card
                sx={{
                  borderRadius: "5px",
                  p: 1,
                }}
              >
                <Div>
                  <Typography
                    id="Typography-root"
                    sx={[dialogTitle, { color: "#746C70" }]}
                  >
                    Follow these first steps to send your first email campaign
                  </Typography>
                  <Div mt={2} ml={3}>
                    <Div sx={[DisplayFlex, { my: 1.5 }]}>
                      <Div>
                        <CheckCircleRoundedIcon
                          align="left"
                          sx={{ color: Colors?.Mblue, mr: 1 }}
                        />
                      </Div>
                      <Div>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          Complete your Profile Form
                        </Typography>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          Fill out your profile form to complete your
                        </Typography>
                      </Div>
                    </Div>
                    <Div sx={[DisplayFlex, { my: 1.5 }]}>
                      <Div>
                        <ContactPageRoundedIcon sx={{ color: Colors?.Mblue, mr: 1 }} />
                      </Div>

                      <Div>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          Import all of your contacts
                        </Typography>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          Upload your contacts in order to start sending them
                          email campaigns
                        </Typography>
                      </Div>
                    </Div>

                    <Div sx={[DisplayFlex, { my: 1.5 }]}>
                      <Div>
                        <MailIcon align="left" sx={{ color: Colors?.Mblue, mr: 1 }} />
                      </Div>
                      <Div>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          Schedule your first email campaign.
                        </Typography>
                        <Typography id="Typography-root" sx={[FontStyle]}>
                          Set up, design and schedule your email campaign
                        </Typography>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Card>
            </Div>
          </>
        )}
        {openCreateEmailCampaign && <CreateEmailCampaign />}
      </JumboContentLayoutMain>
    </>
  );
};

export default CampaignDashboard;
