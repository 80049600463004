import React from 'react';
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Label,
    Format,
    Legend,
    Export,
    Title,
} from 'devextreme-react/chart';

function onPointClick(e) {
    e.target.select();
}
function DealBarChart({ lineChartData }) {
    const grossProductData = [
        {
            state: 'Illinois',
            year2016: 803,
            year2017: 823,
            year2018: 863,
        },
        {
            state: 'Indiana',
            year2016: 316,
            year2017: 332,
            year2018: 332,
        },
        {
            state: 'Michigan',
            year2016: 452,
            year2017: 459,
            year2018: 470,
        },
        {
            state: 'Ohio',
            year2016: 621,
            year2017: 642,
            year2018: 675,
        },
        {
            state: 'Wisconsin',
            year2016: 290,
            year2017: 294,
            year2018: 301,
        },
    ];

    console.log("ksjfnkdjf", lineChartData);

    return (
        <Chart
            id="chart"
            //   title="Monthly Report"
            dataSource={lineChartData}
            onPointClick={onPointClick}
            height={387}
        >
            {/* <Title text="Monthly Report" horizontalAlignment='left' font={{ size: 16, weight: 600,family:'Lato, sans-serif'}} /> */}
            <CommonSeriesSettings
                argumentField="name"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
            >
                <Label visible={true}>
                    <Format
                        type="fixedPoint"
                        precision={0}
                    />
                </Label>
            </CommonSeriesSettings>
            <Series
                argumentField="name"
                valueField="current"
                name="current"
            />
            <Series
                valueField="previous"
                name="previous"
            />

            <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
            ></Legend>
            <Export enabled={false} />
        </Chart>
    );
}
export default DealBarChart;
