import JumboContentLayoutMain from '@jumbo/components/JumboContentLayout/JumboContentLayoutMain'
import React from 'react'
import Div from '@jumbo/shared/Div';
import { useState } from 'react';
import Instagram from './Instagram';


const InstagramAPI = () => {

    const [userInfo, setUserInfo] = useState('')
    const [id, setId] = useState('')
    const [userConfiguration, setUserConfiguration] = useState('')



    const [isOpenSignIn, setIsOpenSignIn] = useState(false)

    const [token, setToken] = useState('')
    return (
        <JumboContentLayoutMain>
          
            <Div sx={{ backgroundColor: 'whitesmoke', width: '100%', height: '100%',paddingTop:"50px" }}>
                {/* {
                    token ? <Feed /> : <Div sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button onClick={() => { setIsOpenSignIn(true) }} variant='contained'>LinkedIn Configuration</Button></Div>
                } */}
                <Div sx={{ display: 'flex', justifyContent: 'center' }}><Instagram /></Div>
            </Div>
        </JumboContentLayoutMain>
    )
}

export default InstagramAPI