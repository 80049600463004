import {
  Box,
  ButtonGroup,
  Fab,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
// import {
//   Camera,
//   File,
//   Image,
//   LinkSimple,
//   PaperPlaneTilt,
//   Smiley,
//   Sticker,
//   User,
// } from "phosphor-react";
import { AiFillCamera } from "react-icons/ai";
import { FaFile } from "react-icons/fa";
import { FaImage } from "react-icons/fa";
import { PiLinkSimpleLight } from "react-icons/pi";
import { IoPaperPlane } from "react-icons/io5";
import { PiSmileyWinkFill } from "react-icons/pi";
import { PiStickerFill } from "react-icons/pi";
import { FaUserAlt } from "react-icons/fa";
import { useTheme, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
// import { socket } from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "../../hook/useResponsive";
import Div from "@jumbo/shared/Div";
import { CloseIcon } from "app/icons/PngIcons";
import { MdOutlineEmojiEmotions, MdSend } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { FcVideoCall } from "react-icons/fc";
import { IoMdCall } from "react-icons/io";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL, socket } from "app/services/auth-services";
import {
  fetchSelectUserConversations,
  getMessagesUserStatus,
  getSelectChatUser,
  setMessages,
  setMsgStatus,
} from "../../dashboard/SelectChatUserSlice";
import { memo } from "react";
import { useStateContext } from "app/contexts/ContextProvider";
import { fetchMessages, sendMessage } from "../../api/chatAPI";
import { useChatContext } from "../../context/Chatcontext";

const Actions = [
  {
    color: "#4da5fe",
    icon: <FaImage size={24} />,
    y: 102,
    title: "Photo/Video",
  },
  {
    color: "#1b8cfe",
    icon: <PiStickerFill size={24} />,
    y: 172,
    title: "Stickers",
  },
  {
    color: "#0172e4",
    icon: <AiFillCamera size={24} />,
    y: 242,
    title: "Image",
  },
  {
    color: "#0159b2",
    icon: <FaFile size={24} />,
    y: 312,
    title: "Document",
  },
  {
    color: "#013f7f",
    icon: <FaUserAlt size={24} />,
    y: 382,
    title: "Contact",
  },
];

const Footer = () => {
  // const theme = useTheme();
  // const dispatch = useDispatch();
  const { selectUser, setConversations } = useChatContext();

  const [localFile, setLocalFile] = useState("");
  const [message, setMessage] = useState("");
  const [openPicker, setOpenPicker] = React.useState(false);
  const [fileName, setFileName] = useState("");
  const [base64Data, setBase64Data] = useState("");
  const [callingURI, setCallingURI] = useState("");

  const [sendLoader, setSendLoader] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const inputRef = useRef(null);

  // drag and drop functions

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  //   setDragging(true);
  // };

  // const handleDragLeave = () => {
  //   setDragging(false);
  // };

  const handleDrop = (e) => {
    e.preventDefault();
    // setDragging(false);

    const files = e.dataTransfer
      ? Array.from(e.dataTransfer.files)
      : e.target.files;

    if (files.length > 0) {
      const file = files[0];
      setFileName(file);

      const fileURL = URL.createObjectURL(file);
      setLocalFile(fileURL);

      const reader = new FileReader();

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentLoaded = (event.loaded / event.total) * 100;
          setLoadingProgress(percentLoaded);
        }
      };

      reader.onload = () => {
        setBase64Data(reader.result?.split(",")[1]);
        setLoadingProgress(100); // Set progress to 100% when loading is complete
      };

      reader.readAsDataURL(file);
    }
  };

  const isMobile = useResponsive("between", "md", "xs", "sm");

  //  emoji select function
  const handleEmojiClick = (emoji) => {
    if (emoji) {
      setMessage(message + emoji);
    }
  };
  // send message

  const sendUserMessage = async (type) => {
    const payload = {
      RecevierName: selectUser?.username,
      Meassage: message,
      File: base64Data || "",
      Jitci: "",
      Audio: null,
      Video_Answer: type === "video" ? "connecting" : "",
      Audio_Answer: type === "audio" ? "connecting" : "",
      File_Name: fileName?.name || "",
      Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
      SenderId: parseInt(localStorage.getItem("UserId")),
      SendedDate: new Date()?.toISOString(),
      Receiver_Id: selectUser?.id,
    };

    let endpoint = "UserChatMasterCRUD"; // user send message end point
    let id = selectUser?.id;
    if (Boolean(message?.trim()) || Boolean(base64Data?.trim())) {
      await sendMessage(endpoint, payload);
      setMessage("");
      setBase64Data("");
      setFileName("");
      setLocalFile("");
      setSendLoader(false);
      // getUserConversations(id);
    }
  };

  // video and audio call generated
  const generateURL = (type) => {
    let ran = "";
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < 50; i++) {
      ran += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    let meetingUrl = "";
    if (type === "audio") {
      meetingUrl = `https://meet.jit.si/FoyerTech/${ran}#config.prejoinPageEnabled=false&config.startWithAudioMuted=true&config.startWithVideoMuted=true&interfaceConfig.TOOLBAR_BUTTONS=["microphone"]`;
    } else {
      meetingUrl = `https://meet.jit.si/FoyerTech/${ran}`;
    }
    console.log("jitsi check", selectUser);
    // window.open(meetingUrl, '_blank')
    setCallingURI(meetingUrl);
    // meetingUrl !== "" && !selectUser?.Assign_User ? sendUserMessage(meetingUrl, type) : sendGroupMessage(meetingUrl, type)

    // console.log("meetingUrl", meetingUrl);
  };

  // socket.on("receive_notification_message", (data) => {
  //   if (data) {
  //     const currentUserId = Number(localStorage.getItem("UserId"));
  //     const selectedUserId = Number(selectUser?.id);
  //     const { senderId, receiverId } = data;

  //     // console.log(
  //     //   "receive_notification_message ---------- ",
  //     //   selectedUserId,
  //     //   receiverId,
  //     //   currentUserId,
  //     //   senderId,

  //     //   selectedUserId === receiverId && currentUserId === senderId,
  //     //   selectedUserId === senderId && currentUserId === receiverId
  //     // );

  //     // Check if the message is for the current conversation
  //     if (
  //       (selectedUserId === receiverId && currentUserId === senderId) ||
  //       (selectedUserId === senderId && currentUserId === receiverId)
  //     ) {
  //       console.log("response : ", data);
  //       setConversations(data?.results);
  //       setMessage("");
  //       setBase64Data("");
  //       setFileName("");
  //       setLocalFile("");
  //     }
  //   }

  //   setSendLoader(false);
  // });

  // const getUserConversations = async (id) => {
  //   // dispatch(setMsgStatus("idle"));
  //   try {
  //     let msg = await fetchMessages(id);
  //     setConversations(msg);
  //     setSendLoader(false);
  //     setMessage("");
  //     setBase64Data("");
  //     setFileName("");
  //     setLocalFile("");
  //   } catch (error) {
  //     console.error("Error fetching user conversations:", error);
  //     // Handle errors if needed
  //   }
  // };

  return (
    <>
      <ToastContainer />
      <Div
        sx={{
          border: "1px solid lightgray !important",
          px: 0.5,
          borderRadius: "3px",
          position: "relative",
          bottom: 0,
          backgroundColor: "white !important",
          width: "100%",
        }}
        className="relative h-full  p-2"
      >
        {localFile !== "" && (
          <Box className="absolute bottom-20">
            <Div
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#E5E4E2 !important",
                p: 1,
                position: "relative",
              }}
              className="h-[400px] w-[400px] p-3"
            >
              {console.log(
                "asdsnad",
                fileName,
                [
                  "zip",
                  "ZIP",
                  "rar",
                  "RAR",
                  "7z",
                  "7Z",
                  "tar",
                  "TAR",
                  "gz",
                  "GZ",
                  "EXE",
                  "exe",
                  "msi",
                  "MSI",
                ].includes(fileName?.name)
              )}
              <embed
                className="w-[380px] h-[380px]"
                src={localFile}
                type="application/pdf"
              />

              <IconButton
                onClick={() => {
                  setLocalFile("");
                  setLoadingProgress(0);
                  setFileName("");
                  setBase64Data("");
                }}
                sx={{ position: "absolute", top: 0, right: 5 }}
              >
                <CloseIcon color="tomato" />
              </IconButton>
            </Div>
          </Box>
        )}

        <InputBase
          style={{ px: 0.5, py: 1.5 }}
          placeholder="Text chat..."
          className="w-full"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          maxRows={5}
          autoFocus
          inputProps={{ "aria-label": "search google maps" }}
        />

        <Div
          sx={{
            borderTop: "1px solid ghostwhite",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ButtonGroup variant="text" aria-label="text button group">
            {openPicker && (
              <Div sx={{ position: "absolute", bottom: 60, left: 0 }}>
                {/* <Picker onEmojiClick={handleEmojiClick} /> */}
                <Picker
                  // theme={theme.palette.mode}
                  data={data}
                  onEmojiSelect={(emoji) => {
                    handleEmojiClick(emoji.native);
                  }}
                />
              </Div>
            )}

            <IconButton onClick={() => setOpenPicker(!openPicker)}>
              <MdOutlineEmojiEmotions
                style={{ cursor: "pointer", color: "darkgreen" }}
                size={18}
              />
            </IconButton>

            <IconButton>
              <input
                type="file"
                onChange={handleDrop}
                accept="image/*,video/*,audio/*,.pdf,.zip"
                id="fileInput"
                style={{ display: "none" }}
              />

              <label htmlFor="fileInput">
                <ImAttachment
                  style={{ cursor: "pointer", color: "darkgreen" }}
                  size={15}
                />
              </label>
            </IconButton>
            {/* <IconButton onClick={() => generateURL("video")} type="button">
              <FcVideoCall size={18} color="green" />
            </IconButton> */}
            <IconButton onClick={() => generateURL("audio")} type="button">
              <IoMdCall size={18} color="green" />
            </IconButton>
          </ButtonGroup>
          <Div sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {fileName?.size && (
              <Typography>{`${fileName?.size} KB`}</Typography>
            )}
            {fileName?.size && (
              <Typography>{loadingProgress?.toFixed(2)} %</Typography>
            )}
            <IconButton
              onClick={() => {
                setSendLoader(true);
                !selectUser?.Assign_User
                  ? sendUserMessage()
                  : sendUserMessage();
              }}
              disabled={
                message.trim() === "" &&
                base64Data === "" &&
                sendLoader === false
              }
            >
              {sendLoader == false ? (
                <MdSend
                  type="submit"
                  size={18}
                  color={
                    message.trim() !== "" || base64Data !== "" || sendLoader
                      ? "darkgreen"
                      : "#C0C0C0"
                  }
                />
              ) : (
                <div
                  class="spinner-grow text-success"
                  role="status"
                  style={{ width: "20px", height: "20px" }}
                ></div>
              )}
            </IconButton>
          </Div>
        </Div>
      </Div>
    </>

    // <Box
    //   sx={{
    //     position: "relative",
    //     backgroundColor: "transparent !important",
    //   }}
    // >
    //   <Box
    //     p={isMobile ? 1 : 2}
    //     width={"100%"}
    //     sx={{
    //       backgroundColor:
    //         theme.palette.mode === "light"
    //           ? "#F8FAFF"
    //           : theme.palette.background,
    //       boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
    //     }}
    //   >
    //     <Stack direction="row" alignItems={"center"} spacing={isMobile ? 1 : 3}>
    //       <Stack sx={{ width: "100%" }}>
    //         <Box
    //           style={{
    //             zIndex: 10,
    //             position: "fixed",
    //             display: openPicker ? "inline" : "none",
    //             bottom: 81,
    //             right: isMobile ? 20 : true ? 420 : 100,
    //           }}
    //         >
    //           <Picker
    //             theme={theme.palette.mode}
    //             data={data}
    //             onEmojiSelect={(emoji) => {
    //               handleEmojiClick(emoji.native);
    //             }}
    //           />
    //         </Box>
    //         {/* Chat Input */}
    //         <ChatInput
    //           inputRef={inputRef}
    //           value={message}
    //           setMessage={setMessage}
    //           openPicker={openPicker}
    //           setOpenPicker={setOpenPicker}
    //         />
    //       </Stack>
    //       <Box
    //         sx={{
    //           height: 48,
    //           width: 48,
    //           backgroundColor: theme.palette.primary.main,
    //           borderRadius: 1.5,
    //         }}
    //       >
    //         <Stack
    //           sx={{ height: "100%" }}
    //           alignItems={"center"}
    //           justifyContent="center"
    //         >
    //           <IconButton
    //             onClick={() => {
    //               // socket.emit("text_message", {
    //               //   message: linkify(message),
    //               //   // conversation_id: room_id,
    //               //   from: user_id,
    //               //   to: current_conversation.user_id,
    //               //   type: containsUrl(message) ? "Link" : "Text",
    //               // });
    //             }}
    //           >
    //             <IoPaperPlane color="#ffffff" />
    //           </IconButton>
    //         </Stack>
    //       </Box>
    //     </Stack>
    //   </Box>

    // </Box>
  );
};

export default memo(Footer);
