import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import {
  DisplayFlex,
  input_label,
  dialogFontSize,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { Grid, TextField, Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Cancelbutton,
  CustomButton,
  Customgrid,
  Div,
  errorFilter,
  TextEditor,
} from "app/pages/widgets/StylesComponents";
import { contactAddNoteSubmit, contactEditNoteSubmit } from "./APIs";
import { toast } from "react-toastify";
import { data01 } from "app/pages/charts/Pie/components/data";
import { fetchTransactionSeries } from "../../widgets/StylesComponents";
import ReactQuill from "react-quill";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function NotesModel({
  setOpen,
  label = "Add",
  data,
  setOpenNotes,
  endpoint,
  extraPayload,
  historyEndpoint,
  name,
  getData,
}) {
  const [FormId, setFormId] = React.useState("");
  const [noteId, setNoteId] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [seriesValue, setSeriesValue] = React.useState("");

  const handleEditorChange = (event, editor) => {
    const value = editor.getData();
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(value, "text/html");
    // const textContent = doc.body.textContent || "";
    setNotes(value);
  };
  const validate = () => {
    let error = {};

    if (Boolean(seriesValue !== "Automatic") && !Boolean(noteId)) {
      error.noteId = "Note Id is required";
    } else if (
      Boolean(seriesValue !== "Automatic") &&
      !Boolean(noteId?.length == 11)
    ) {
      error.noteId = "Note Id must be 11 digits.";
    }
    if (!Boolean(subject)) {
      error.subject = "is required";
    }
    if (!Boolean(notes)) {
      error.notes = "is required";
    }

    return error;
  };

  // < --------------------------------------- submit function -------------------------------- >
  const handleSubmit = () => {
    let error = validate();
    setErrors(error);

    console.log(
      "Submit ---------- - - - - - -- -  -- - - ->",
      extraPayload,
      data
    );

    if (Object.keys(error).length == 0 && Boolean(data?.id)) {
      label === "Add"
        ? contactAddNoteSubmit(
            { notes, subject, seriesValue, noteId, FormId },
            setOpenNotes,
            endpoint,
            historyEndpoint,
            extraPayload,
            getData
          )
        : contactEditNoteSubmit(
            {
              notes,
              subject,
              id: data?.id,
            },
            setOpenNotes,
            endpoint,
            historyEndpoint,
            extraPayload,
            getData
          );
      setOpen(false);
    } else if (!Boolean(data?.id)) {
      toast.error(error?.meesage, {
        position: "top-right",
        autoClose: 2000,
      });
    }
    console.log("2Error", error);
  };

  React.useEffect(async () => {
    let findFormId = "";
    let findFormKey = "";
    let findSeriesValue = "";

    if (label?.toLocaleLowerCase() === "edit") {
      setSubject(data?.Subject);
      setNotes(data?.Notes);
    }

    // for check transaction series

    if (name?.label == "Lead Name") {
      // Leads
      findFormId = data?.LeadNotes_Id;
      findFormKey = "LeadNotes_Id";
      findSeriesValue = "UserLeadNotes";
    } else if (name?.label == "Account Name") {
      // Accounts
      findFormId = data?.AccountNotes_Id;
      findFormKey = "AccountNotes_Id";
      findSeriesValue = "Account_Notes";
    } else if (name?.label == "Deal Name") {
      // Deals
      findFormId = data?.DealNotes_Id;
      findFormKey = "DealNotes_Id";
      findSeriesValue = "Deal_Notes";
    } else {
      // Contacts
      findFormId = data?.ContactNotes_Id;
      findFormKey = "ContactNotes_Id";
      findSeriesValue = "Contact_Notes";
    }

    setFormId(findFormKey);
    setNoteId(findFormId ?? "");
    const series = await fetchTransactionSeries(findSeriesValue || "");
    console.log("series", series);
    setSeriesValue(series);
  }, [data, name]);

  const cancelFunc = () => {
    if (label === "Add") {
      if (Boolean(subject?.trim()) || Boolean(notes?.trim())) {
        setOpen("note");
      } else {
        setOpenNotes(false);
      }
    } else {
      if (subject?.trim() === data?.Subject && notes?.trim() === data?.Notes) {
        setOpenNotes(false);
      } else {
        setOpen("note");
      }
    }
  };

  return (
    <React.Fragment>
      <DialogTitle
        sx={[DisplayFlex, dialogFontSize]}
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        {label} Note
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pt: 2 }}>
          <Customgrid>
            <Grid item>
              <Typography sx={[input_label]}>
                {name?.label || "Name"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={[FontStyle]}>{name?.value || " - "}</Typography>
            </Grid>
            <Grid item>
              <Typography sx={input_label}>
                Note Id{" "}
                <span className="required">
                  {seriesValue == "Automatic" ? "" : "*"}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                className="col-12 input-box2"
                value={noteId}
                sx={{
                  // border: errors["noteId"] ? "1px solid red !important" : "",
                  borderRadius: "5px",
                }}
                placeholder="# Note Id"
                onChange={(e) => setNoteId(e.target.value)}
                disabled={
                  seriesValue == "Automatic"
                    ? true
                    : false || label?.toLocaleLowerCase() === "edit"
                }
              />
              {errors?.noteId && (
                <Div style={{ color: "red" }}>noteId {errors?.noteId}</Div>
              )}
            </Grid>
            <Grid item>
              <Typography sx={input_label}>Subject</Typography>
            </Grid>
            <Grid item>
              <TextField
                className="col-12 input-box2"
                value={subject}
                sx={{
                  // border: errors["subject"] ? "1px solid red !important" : "",
                  borderRadius: "5px",
                }}
                onChange={(e) => setSubject(e.target.value)}
              />
              {errors?.subject && (
                <Div style={{ color: "red" }}>Subject {errors?.subject}</Div>
              )}
            </Grid>
            <Grid item>
              <Typography sx={input_label}>Note</Typography>
            </Grid>
            <Grid item>
              {/* <TextEditor value={notes} onChange={handleEditorChange} /> */}
              <CKEditor
                editor={ClassicEditor}
                content={notes}
                data={notes}
                onChange={(event, editor) => {
                  handleEditorChange(event, editor);
                }}
                className={errors["notes"] ? "editor-error" : ""}
              />
              {errors?.notes && (
                <Div style={{ color: "red" }}>Notes {errors?.notes}</Div>
              )}
            </Grid>
          </Customgrid>
        </DialogContentText>
        <DialogActions sx={[DisplayFlex, { justifyContent: "center" }]}>
          <CustomButton
            label={label === "Add" ? "Save" : "Update"}
            Icon="saveIcon"
            onClick={handleSubmit}
          />
          <Cancelbutton label="Cancel" onClick={cancelFunc} />
        </DialogActions>
      </DialogContent>
    </React.Fragment>
  );
}

export default React.memo(NotesModel);
