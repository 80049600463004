import React from 'react'
import "./InputOption.css"
import { IconButton } from '@mui/material'

const InputOption = ({ Icon, title, color }) => {
    return (
        <div className='inputOption'>
            <IconButton><Icon style={{ color: color }} /></IconButton>
            <h4>{title}</h4>
        </div>
    )
}

export default InputOption