import Div from '@jumbo/shared/Div';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const ArrowIndicator = ({ leadGrowth }) => (
  <Div sx={{
    fontSize:"20px",
    fontWeight:500
  }}>
    {leadGrowth === 'Up' && <FontAwesomeIcon icon={faArrowUp} />}
    {leadGrowth === 'Down' && <FontAwesomeIcon icon={faArrowDown} />}
  </Div>
);

export default ArrowIndicator;
