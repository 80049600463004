import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
import { BASE_URL } from "app/services/auth-services";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

// import itemServices from "app/services/item-master-services";

const rolesValidation = yup.object({
  role: yup.string("Enter your Role name").required("Role name is required"),
});

const CreateRoles = ({ setShowRole, setShowCreateRoles, getData }) => {
  const token = localStorage.getItem("accesstoken");
  // const token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjk5NTQyMTgxLCJlbWFpbCI6InJhdmlrdW1hci5yQGZveWVydGVjaC5jb20iLCJvcmlnX2lhdCI6MTY5OTUwNjE4MX0.2xk15P67jfHINEHFhpyzyhLPR2fzitpujX3wolEkrMM"

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const formRef = useRef();
  const [datas, setDatas] = useState([]);
  const [role, setRole] = useState(null);
  const [roleDescription, setRoleDescription] = useState("");
  const [value, setValue] = useState(1);

  // fun for tree view
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const [permissions, setPermissions] = useState([]);
  const [checkForm, setCheckForm] = useState([]);

  const [moduleId, setModuleId] = useState("");
  const [moduleId1, setModuleId1] = useState("");
  const [formId, setFormId] = useState("");
  const [formId1, setFormId1] = useState("");

  // state for configure permissions
  const [permissionsData, setPermissionsData] = useState([]);
  const [arrForModule, setArrForModule] = useState([]);
  const [arrForFormName, setArrForFormName] = useState([]);

  const [showCreateRole, setShowCreateRole] = useState(true);
  const [showConfigurePermission, setShowConfigurePermission] = useState(false);

  const RedirectToRolesTable = () => {
    setShowRole(true);
    setShowCreateRoles(false);
    setShowConfigurePermission(false);
    setRole("");
    setValue(1);
  };

  const ShowConfigurePermission = (e) => {
    switch (e?.value) {
      case 1:
        setShowConfigurePermission(false);
        setShowCreateRole(true);
        // console.log(e);
        setDatas(permissions);
        // console.log("MNMN", permissions);
        break;
      case 2:
        setShowConfigurePermission(true);
        setShowCreateRole(false);
        // console.log(e);
        break;
      default:
        setShowConfigurePermission(true);
        setShowCreateRole(false);
        break;
    }
    setValue(e?.label);
  };

  const RedirectToCreateRoles = (e) => {
    // e.preventDefault();
    setShowCreateRole(true);
    setShowConfigurePermission(false);
    // form.reset();
    // formRef.current.reset();
  };

  const getPermissionsList = async () => {
    // var permissionspayload = {
    //   Is_Deleted: false,
    // };

    await axios.post(
      `${BASE_URL}/useradmin/Formmasterlist/`,
      {},
      {
        headers: {
          // Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          db: localStorage.getItem("DBName"),
        },
      }
    )
      .then((res) => {
        console.log("ddsgsg", res?.data);
        setPermissionsData(res?.data);
        // setModuleName(res?.data?.ModuleName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const allPermissions = async () => {
    // axios.get(`${BASE_URL}/Erpapp/Allpermission/`, header).then((res) => {
    //   console.log(res);
    //   setPermissions(res.data);
    // });

    await axios
      .get(`${BASE_URL}/useradmin/PermissionList/`, header)
      .then((res) => {
        setPermissions(res?.data?.map((opt) => opt?.id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPermissionsList();
    allPermissions();
  }, [showConfigurePermission]);

  const handleCheckModuleName = (id) => (e, newValue) => {
    setModuleId(newValue);
    setModuleId1(e.target.value);
    console.log(e.target.value);

    const { value, checked } = e.target;
    if (checked) {
      if (arrForModule.indexOf(JSON.parse(value)) === -1)
        arrForModule.push(JSON.parse(value));
      console.log("arrForModule", arrForModule);
    } else {
      setArrForModule(
        arrForModule.filter((items) => items !== JSON.parse(value))
      );
      setArrForFormName(
        arrForFormName.filter((items) => items !== JSON.parse(value))
      );
      setDatas(datas.filter((items) => items !== JSON.parse(value)));
    }

    // if (checked) {
    //   if (arrForModule.indexOf(JSON.parse(value)) === -1)
    //     arrForModule.push(JSON.parse(value));
    //   console.log("arrForModule", arrForModule);
    // } else {
    //   setArrForModule(
    //     arrForModule.filter((items) => items !== JSON.parse(value))
    //   );
    // }

    // setCheckForm((prev) => {
    //   if (prev.includes(id)) {
    //     return prev.filter((x) => x !== id);
    //   } else {
    //     return [...prev, id];
    //   }
    // });
  };

  const handleCheckFormName = (id) => (e, newValue) => {
    setFormId(newValue);
    console.log(newValue);
    setFormId1(e.target.value);
    console.log(e.target.value);

    const { value, checked } = e.target;
    if (checked) {
      if (arrForFormName.indexOf(JSON.parse(value)) === -1)
        arrForFormName.push(JSON.parse(value));
      console.log("arr", arrForFormName);
    } else {
      setArrForFormName(
        arrForFormName.filter((items) => items !== JSON.parse(value))
      );
      setDatas(datas.filter((items) => items !== JSON.parse(value)));
    }

    setCheckForm((prev) => {
      if (prev.includes(id)) {
        return prev.filter((x) => x !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleCheckRoles = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      if (datas.indexOf(JSON.parse(value)) === -1)
        datas.push(JSON.parse(value));
      console.log("G", datas);
      // setIndex(index + 1);
    } else {
      setDatas(datas.filter((items) => items !== JSON.parse(value)));
    }
  };

  // code for create a user roles
  const createRoles = (role) => {
    var defaultPermissions = [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 61, 62, 63, 64, 313, 314, 315,
      316, 317, 318, 319, 320, 409, 410, 411, 412, 413, 414, 415, 416,
    ];

    var rolespayload = {
      name: role,
      Role_Name: role,
      Description: roleDescription,
      permissions: datas,
      FormNames: arrForFormName,
      Modelname: arrForModule,
      RolePermissions: [],
      FormPermissions: [],
      Company_Id: parseInt(localStorage.getItem("OrganizationId")),
      Created_By: parseInt(localStorage.getItem("UserId")),
      Updated_By: parseInt(localStorage.getItem("UserId")),
    };
    axios.post(`${BASE_URL}/useradmin/RoleCRUD/`, rolespayload, header)
      .then((res) => {
        console.log(res?.data);
        if (res?.data?.id) {
          setValue(1);
          setRoleDescription("");
          setRole("");
          setShowRole(true);
          setShowCreateRoles(false);
          setShowConfigurePermission(false);
          toast.success("Role is created successfully");
          getData();
        }
      }).catch((error) => {
        console.log(error);
        toast.error("You do not have permission to perform this action.");
      });
  };

  const form = document.getElementById("myForm");


  // const otherModules = permissionsData.filter(
  //   (module) => !module.ModuleName.includes("Report")
  // );

  const renderTreeItems = (modules) => {
    return modules?.map((data, index) => {
      // if (data?.FormId?.find((opt) => opt?.ModuleName === "User")) {

      // }
    });
  };

  return (
    <>
      {/* code for create role form */}
      <Div sx={{ display: showCreateRole === true ? "block" : "none", mt: 2 }}>
        <Typography variant="h3" sx={{ fontWeight: 600 }}>
          Create Role
        </Typography>
        <Formik
          validateOnChange={true}
          initialValues={{
            role: "",
          }}
          onSubmit={(data, { setSubmitting }) => {
            console.log(data);
            setSubmitting(true);
            createRoles(data?.role);
            setSubmitting(false);
          }}
          validationSchema={rolesValidation}
        >
          <Form>
            <Div sx={{ mt: 2, minHeight: "280px" }}>
              {/* <Div className="row mt-1">
                <Typography className="col-sm-12 col-md-4 col-lg-3 col-xl-2 input-label mt-1">
                  Business Unit
                </Typography>
                <Div className="col-12 col-md-7 col-lg-4 col-xl-4">
                  <Autocomplete
                    className="search-select"
                    name="partnerName"
                    getOptionLabel={(option) => option?.Partner_Name}
                    options={partners}
                    value={partners?.find(
                      (option) => option?.Partner_Name === partnerName
                    ) || null}
                    onChange={(e, newValue) => {
                      setPartnerName(newValue?.Partner_Name);
                      console.log(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Business Unit"
                        variant="outlined"
                        autoComplete="off"
                      />
                    )}
                  />
                </Div>
              </Div> */}

              <Div className="row mt-1">
                <Typography className="col-sm-12 col-md-4 col-lg-3 col-xl-2 input-label mt-1">
                  Role Name <span className="required">*</span>
                </Typography>

                <Div className="col-12 col-md-7 col-lg-4 col-xl-4">
                  <JumboTextField
                    type="text"
                    className="col-12 input-box2"
                    id="staticEmail"
                    placeholder="Enter Role Name"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </Div>
              </Div>

              <Div className="row mt-1">
                <Typography className="col-sm-12 col-md-4 col-lg-3 col-xl-2 input-label mt-1">
                  Permission <span className="required">*</span>
                </Typography>
                <Div className="col-12 col-md-7 col-lg-4 col-xl-4">
                  <FormControl className="col-sm-12 d-flex">
                    <Autocomplete
                      className="search-select3 col-12"
                      name="date"
                      options={[
                        { label: "All Permissions", value: 1 },
                        { label: "Configured Permission", value: 2 },
                      ]}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option && option?.label) {
                          return option?.label;
                        }
                        return "";
                      }}
                      value={value}
                      onChange={(e, newValue) => {
                        if (newValue?.value === 1) {
                          setShowConfigurePermission(false);
                          setShowCreateRole(true);
                          // console.log(e.target.value);
                          setDatas(permissions);
                        } else if (newValue?.value === 2) {
                          setShowConfigurePermission(true);
                          setShowCreateRole(false);
                        }
                        if (Boolean(newValue?.value)) {
                          setValue(newValue?.label);
                          ShowConfigurePermission(newValue);
                        } else {
                          setValue("");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="date"
                          placeholder="Choose a permission"
                        />
                      )}
                    />
                  </FormControl>
                </Div>
              </Div>

              <Div className="row mt-1">
                <Typography className="col-sm-12 col-md-4 col-lg-3 col-xl-2 input-label mt-1">
                  Role Description
                </Typography>
                <Div className="col-12 col-md-7 col-lg-4 col-xl-4">
                  <TextField
                    type="text"
                    rows={3}
                    multiline
                    className="col-12 multiline-box"
                    id="staticEmail"
                    placeholder="Enter Role Description"
                    name="roleDescription"
                    value={roleDescription}
                    onChange={(e) => setRoleDescription(e.target.value)}
                  />
                </Div>
              </Div>
            </Div>

            {/* code for save and cancel button */}
            <Div sx={{ mt: 5, display: "flex", justifyContent: "flex-start" }}>
              <CustomButton
                // onClick={() => handleSubmit()}
                label="Save"
                type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton
                label="Cancel"
                onClick={RedirectToRolesTable}
              />
            </Div>
          </Form>
        </Formik>
      </Div>

      {/* code for configure permission form */}
      <Div
        sx={{
          display: showConfigurePermission === true ? "block" : "none",
        }}
      >
        <Typography variant="h2" sx={{ mt: 2, fontWeight: 600 }}>
          Configure Permission
        </Typography>

        <Div sx={{ mt: 2, mb: 2 }}>
          <form ref={formRef}>
            <Div sx={{ minHeight: "400px" }}>
              {permissionsData?.map((data, index) => {
                if (data?.FormId?.find((opt) => opt?.ModuleName === "User")) {
                  return (
                    <TreeView
                      aria-label="reports"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      expanded={expanded}
                      selected={selected}
                      onNodeToggle={handleToggle}
                      onNodeSelect={handleSelect}
                      multiSelect
                      className="treeview-lists"
                    >
                      <TreeItem
                        key={index}
                        nodeId={data?.id}
                        label={
                          <div>
                            <Div sx={{ display: "flex", alignItems: "center" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="info"
                                    size="small"
                                    value={data?.id}
                                    checked={arrForModule?.includes(data?.id)}
                                    onChange={handleCheckModuleName(data?.id)}
                                  />
                                }
                              />
                              <FormLabel
                                sx={{
                                  color: "#000000",
                                  textTransform: "capitalize",
                                  fontSize: 14,
                                  ml: -2,
                                }}
                              >
                                {data?.ModuleName}
                              </FormLabel>
                            </Div>
                          </div>
                        }
                      >
                        <Div sx={{ display: "flex", flexWrap: "wrap" }}>
                          {permissionsData[index]?.FormId?.map((name, inx) => (
                            <Div sx={{ minWidth: "345px" }} key={inx}>
                              {name?.PermissionName?.length > 0 ? (
                                <TreeItem
                                  nodeId={name?.id}
                                  label={
                                    <Div sx={{ display: "flex", alignItems: "center" }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size="small"
                                            color="info"
                                            value={name?.id}
                                            checked={arrForFormName?.includes(name?.id)}
                                            onChange={handleCheckFormName(name?.id)}
                                            disabled={
                                              arrForModule.includes(
                                                data?.id
                                              )
                                                ? false
                                                : true
                                            }
                                          />
                                        }
                                      />
                                      <FormLabel
                                        sx={{
                                          color: "#000000",
                                          fontSize: 12,
                                          textTransform: "capitalize",
                                          ml: -2,
                                        }}
                                      >
                                        {name?.FormName}
                                      </FormLabel>
                                    </Div>
                                  }
                                >
                                  <Div sx={{ width: "330px" }}>
                                    <Grid container sx={{ ml: { xs: 2, md: 4 } }}>
                                      <Grid item>
                                        <FormGroup sx={{ textTransform: "capitalize" }}>
                                          {Object.keys(name?.PermissionName?.[0])
                                            .filter((key) => key.startsWith("name")) // Filter keys that start with 'name'
                                            .map((key) => name?.PermissionName?.[0][key])
                                            ?.map((permissionName, index) => (
                                              <FormControlLabel
                                                key={index}
                                                control={
                                                  <Checkbox
                                                    value={name?.ModulePermission?.[index]}
                                                    size="small"
                                                    color="info"
                                                    // onChange={(e) => handleCheckRoles(e, name?.id, name?.ModulePermission?.[index])}
                                                    onChange={handleCheckRoles}
                                                    disabled={
                                                      !arrForFormName.includes(name?.id)
                                                    }
                                                  />
                                                }
                                                label={permissionName}
                                              />
                                            ))}
                                        </FormGroup>
                                      </Grid>
                                    </Grid>
                                  </Div>
                                </TreeItem>
                              ) : (
                                <div>
                                  {/* Render a simple label without TreeItem when PermissionName is empty */}
                                  <Div sx={{ display: "flex", alignItems: "center", ml: 4 }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          value={name?.id}
                                          size="small"
                                          color="info"
                                          checked={arrForFormName?.includes(name?.id)}
                                          onChange={handleCheckFormName(name?.id)}
                                          disabled={
                                            arrForModule.includes(
                                              data?.id
                                            )
                                              ? false
                                              : true
                                          }
                                        />
                                      }
                                    />
                                    <FormLabel
                                      sx={{
                                        color: "#000000",
                                        fontSize: 12,
                                        ml: -2,
                                      }}
                                    >
                                      {name?.FormName}
                                    </FormLabel>
                                  </Div>
                                </div>
                              )}
                            </Div>
                          ))}
                        </Div>
                      </TreeItem>
                      {/* {renderTreeItems(permissionsData)} */}
                    </TreeView>
                  )
                }
              })}
            </Div>

            {/* code for save and cancel button */}
            <Div sx={{ mt: 5, display: "flex", justifyContent: "start" }}>
              <CustomButton
                onClick={RedirectToCreateRoles}
                label="Save"
                // type="submit"
                Icon="saveIcon"
                sx={{ mr: 1 }}
              />
              <Cancelbutton
                label="Cancel"
                onClick={() => {
                  // setOpenPermissions(false);
                  setShowCreateRoles(true);
                  setShowConfigurePermission(false);
                }}
              />
            </Div>
            <br />
          </form>
        </Div>
      </Div>
    </>
  );
};

export default CreateRoles;
