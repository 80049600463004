import React, { useEffect, useState } from "react";
import PieChart, {
  Legend,
  Series,
  Tooltip,
  Label,
  Connector,
  Export,
  Title,
} from "devextreme-react/pie-chart";

// Function to customize tooltip
function customizeTooltip(arg) {
  return {
    text: `${arg.argumentText}: ${arg.valueText}`,
  };
}

// DealPieChart component
function DealPieChart({ resource }) {
  const [datas, setDatas] = useState([
    { name: "Year To Month", val: 0 },
    { name: "Month To Date", val: 0 },
    { name: "Sales", val: 0 },
    { name: "Open Deals", val: 0 },
    { name: "Closed Deals", val: 0 },
  ]);

  useEffect(() => {
    setDatas(resource);
  }, [resource]);

  return (
    <PieChart id="pie" type="doughnut" dataSource={datas}>
      <Series argumentField="name" valueField="val">
        <Label
          visible={true}
          font={{ size: 14, color: "#ffffff" }} // Better font styling
        >
          <Connector visible={true} width={2} color="#ffffff" />
        </Label>
      </Series>
      <Export enabled={true} />
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="right"
        rowCount={1}
        font={{ size: 12, color: "#333333" }} // Modern legend styling
      />
      <Tooltip
        enabled={true}
        customizeTooltip={customizeTooltip}
        font={{ size: 12 }}
        border={{ visible: true, color: "#cccccc" }}
        color="#fff"
      />
    </PieChart>
  );
}

export default DealPieChart;
