import {
  LinearGauge,
  Scale,
  Label,
  ValueIndicator,
  RangeContainer,
  Range
} from "devextreme-react/linear-gauge";
import {
  Grid,
} from "@mui/material";
import { FontStyle } from "app/pages/widgets/CRMStyles";
import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";


function customizeText({ valueText }) {
  return `${valueText} `;
}
function DealOverView({id}) {

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const [datas, setDatas] = useState([])

  const getData = () => {
    axios
      .get(
        `${BASE_URL}/user/DealOverViewDetails/?Deal_Id=${id}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setDatas(res.data);
      }).catch((err)=>{
        console.log("err",err)
      });
  };

  useEffect(() => {
    getData();
  }, [])



  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} xl={4} lg={4}>
          <Div sx={[FontStyle, { pt: 6 }, { pb: 9.5 }]}>
            Call Connected
          </Div>
          <Div sx={[FontStyle, { pb: 10 }]}>
            Emils Responded
          </Div>
          <Div sx={[FontStyle, { pb: 10 }]}>Competitors</Div>
          {/* <Div sx={[FontStyle, { pb: 10 }]}>
            Price Negotiation
          </Div> */}
          <Div sx={[FontStyle, { pb: 10 }]}>
            Meetings
          </Div>
          <Div sx={[FontStyle, { pb: 10 }]}>Call Response</Div>
        </Grid>
        <Grid item xs={12} xl={6} lg={6}>
          <div id="gauge-demo">
            <LinearGauge id="c6" value={datas?.Connected} >
              <Scale startValue={0} endValue={datas?.Call_Total} tickInterval={datas?.Call_Total/2}>
                <Label customizeText={customizeText}></Label>
              </Scale>
              <RangeContainer width={20} offset={10} >
                <Range startValue={0} endValue={datas?.Call_Total/2} color="#92000A" />
                <Range startValue={datas?.Call_Total/2} endValue={datas?.Call_Total} color="#E6E200" />
              </RangeContainer>
              <ValueIndicator
                type="triangleMarker"
                color="#f05b41"
              ></ValueIndicator>
            </LinearGauge>
            <LinearGauge id="c6" value={datas?.ReceivedMail}>
              <Scale startValue={0} endValue={datas?.TotalMail} tickInterval={datas?.TotalMail/2}>
                <Label customizeText={customizeText}></Label>
              </Scale>
              <RangeContainer width={20} offset={10} >
                <Range startValue={0} endValue={datas?.TotalMail/2} color="#92000A" />
                <Range startValue={datas?.TotalMail/2} endValue={datas?.TotalMail} color="#E6E200" />
              </RangeContainer>
              <ValueIndicator
                type="triangleMarker"
                color="#f05b41"
              ></ValueIndicator>
            </LinearGauge>
            <LinearGauge id="c6" value={datas?.Competitor}>
              <Scale startValue={0} endValue={datas?.Competitor+1} tickInterval={datas?.Competitor/2}>
                <Label customizeText={customizeText}></Label>
              </Scale>
              <RangeContainer width={20} offset={10} >
                <Range startValue={0} endValue={datas?.Competitor/2} color="#92000A" />
                <Range startValue={datas?.Competitor/2} endValue={datas?.Competitor+1} color="#E6E200" />
              </RangeContainer>
              <ValueIndicator
                type="triangleMarker"
                color="#f05b41"
              ></ValueIndicator>
            </LinearGauge>
            {/* <LinearGauge id="c6" value={85}>
              <Scale startValue={0} endValue={100} tickInterval={50}>
                <Label customizeText={customizeText}></Label>
              </Scale>
              <RangeContainer width={20} offset={10} >
                <Range startValue={0} endValue={50} color="#92000A" />
                <Range startValue={50} endValue={100} color="#E6E200" />
              </RangeContainer>
              <ValueIndicator
                type="triangleMarker"
                color="#f05b41"
              ></ValueIndicator>
            </LinearGauge> */}
            <LinearGauge id="c6" value={datas?.TotalDealMeeting}>
              <Scale startValue={0} endValue={datas?.TotalDealMeeting} tickInterval={datas?.TotalDealMeeting/2}>
                <Label customizeText={customizeText}></Label>
              </Scale>
              <RangeContainer width={20} offset={10} >
                <Range startValue={0} endValue={datas?.TotalDealMeeting/2} color="#92000A" />
                <Range startValue={datas?.TotalDealMeeting/2} endValue={datas?.TotalDealMeeting} color="#E6E200" />
              </RangeContainer>
              <ValueIndicator
                type="triangleMarker"
                color="#f05b41"
              ></ValueIndicator>
            </LinearGauge>
            <LinearGauge id="c6" value={datas?.Called_Back}>
              <Scale startValue={0} endValue={datas?.Call_Total} tickInterval={datas?.Call_Total/2}>
                <Label customizeText={customizeText}></Label>
              </Scale>
              <RangeContainer width={20} offset={10} >
                <Range startValue={0} endValue={datas?.Call_Total/2} color="#92000A" />
                <Range startValue={datas?.Call_Total/2} endValue={datas?.Call_Total} color="#E6E200" />
              </RangeContainer>
              <ValueIndicator
                type="triangleMarker"
                color="#f05b41"
              ></ValueIndicator>
            </LinearGauge>
          </div>
        </Grid>
        <Grid item sx={12} xl={2} lg={2}>
          <Div sx={[FontStyle, { pt: 6 }, { pb: 9.5 }]}>
            ({datas?.Connected}/{datas?.Call_Total})
          </Div>
          <Div sx={[FontStyle, { pb: 10 }]}>
            ({datas?.ReceivedMail}/{datas?.TotalMail})
          </Div>
          <Div sx={[FontStyle, { pb: 10 }]}>            
          ({datas?.Competitor}/{datas?.Competitor+1})

          </Div>
          {/* <Div sx={[FontStyle, { pb: 10 }]}>
            (100/100)
          </Div> */}
          <Div sx={[FontStyle, { pb: 10 }]}>
            ({datas?.TotalDealMeeting}/{datas?.TotalDealMeeting})
          </Div>
          <Div sx={[FontStyle, { pb: 10 }]}>            
          ({datas?.Called_Back}/{datas?.Call_Total})
          </Div>
        </Grid>
      </Grid>
    </div>
  );
}
export default DealOverView;
