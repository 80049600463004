import {
  Box,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import {
  FontStyle,
  componentTitle,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";
import React, { memo, useEffect, useState } from "react";
import {
  postSalesPersonTarget,
  postSalesTarget,
  useTerritoryList,
} from "../TerritoryAPI";
import { Months } from "./SalesDatas";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import {
  Cancelbutton,
  CustomButton,
} from "app/pages/widgets/StylesComponents";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const SetSalesperesonTarget = ({ open, setOpen, update }) => {
  const [territoryName, setTerritoryName] = useState({});
  const [personData, setPersonData] = useState([]);

  const queriyClient = useQueryClient();

  const handleSubmit = async () => {
    const payload = {};
    if (update?.salesperson) {
      payload = {
        Territery_Name: territoryName.Territery_Name,
        Target: {
          total: 0,
        },
        Sales_Person_Target: {
          Target: [],
        },
        Territery_Id: territoryName?.id,
        Target_Id: territoryName?.id,
        User_Id: [localStorage.getItem("UserId")],
        Is_Deleted: false,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
    } else {
      payload = {
        Target: update?.Target,
        Territery_Name: territoryName?.TerritoryName,
        Territery_Id: territoryName?.id,
        Is_Deleted: false,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
    }

    if (open?.toLowerCase() === "create") {
      const res = "";
      if (update?.salesperson) {
        res = await postSalesPersonTarget("post", payload);
      } else {
        res = await postSalesTarget("post", payload);
      }
      if (res) {
        toast.success("Created successfully");
        queriyClient.invalidateQueries("updateSalesPersonTarget");
        setOpen("list");
      } else {
        toast.error("Created Unsuccessful");
      }
    } else if (open?.toLowerCase() === "edite") {
      const res = await postSalesTarget("put", payload);
      if (res) {
        toast.success("Update successfully");
        queriyClient.invalidateQueries("updateSalesPersonTarget");
        setOpen("list");
      } else {
        toast.error("Update Unsuccessful");
      }
    }
  };

  useEffect(() => {
    // if (Object.keys(update)?.length > 0) {
    // setTerritoryName({
    //   TerritoryName: update?.TerritoryName,
    //   id: update?.id,
    // });
    setPersonData(update?.children || []);
    // }
  }, [update]);

  console.log("Updated Person", update, personData);

  return (
    <>
      <Box>
        <Typography sx={[componentTitle]} className="mt-1 mb-4">
          {open?.split(" ")[1]} Sales Person Target
        </Typography>
        <div className="my-4  flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <Typography sx={[dialogTitle]}>Territory Name : </Typography>

            <Typography sx={[dialogTitle]} className="ml-2">
              {update?.TerritoryName}
            </Typography>
          </div>
          <div className="flex items-center gap-2">
            <Cancelbutton
              label="Cancel"
              Icon="cancelIcon"
              onClick={() => {
                setOpen("list");
                // reset();
                setTerritoryName({});
              }}
            />
            <CustomButton
              disabled={Object.keys(territoryName)?.length === 0}
              label={open === "Person Create" ? "Save" : "Update"}
              Icon="SaveIcon"
              onClick={handleSubmit}
            />
          </div>
        </div>

        <Box>
          <Typography sx={[FontStyle]} className="mb-2">
            Sales Revenue
          </Typography>

          <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={200}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table>
              <TableHead>
                <CRMTableRow>
                  {Months?.map((month, i) => (
                    <CRMTableCell key={i}>{month}</CRMTableCell>
                  ))}
                  <CRMTableCell>Total</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                {/* <CRMTableRow>
                  {Object.entries(update?.Target)?.map(([key, val], i) => {
                    console.log("keys", key, val);

                    return (
                      <CRMTableCell key={i}>
                        {" "}
                        {toLocaleCurrency(val)}
                      </CRMTableCell>
                    );
                  })}
                  <CRMTableCell>
                    {toLocaleCurrency(
                      Object.values(update?.Target)?.reduce(
                        (acc, val) => Number(acc) + Number(val),
                        0
                      )
                    )}
                  </CRMTableCell>
                </CRMTableRow> */}
              </TableBody>
            </Table>
          </JumboScrollbar>
        </Box>

        <Box>
          <Typography sx={[FontStyle]}>Sales Person Target</Typography>

          {/* <JumboScrollbar
            autoHeight={true}
            autoHideTimeout={4000}
            autoHeightMin={200}
            autoHide={true}
            hideTracksWhenNotNeeded
            id="no-more-tables"
          >
            <Table>
              <TableHead>
                <CRMTableRow>
                  <CRMTableCell>Sales Person</CRMTableCell>
                  {Months?.map((month, i) => (
                    <CRMTableCell key={i}>{month}</CRMTableCell>
                  ))}
                  <CRMTableCell>Total</CRMTableCell>
                </CRMTableRow>
              </TableHead>
              <TableBody>
                {personData?.length > 0 &&
                  personData?.map((user, i) => {
                    console.log("keys", user, i);
                    return (
                      <CRMTableRow key={i}>
                        <CRMTableCell>{user?.salesperson}</CRMTableCell>
                        {Object?.entries(user?.children)?.map(
                          ([key, child], ci) => {
                            return (
                              <CRMTableCell key={ci}>
                                <TextField
                                  className=" input-box2 w-full my-1"
                                  type="number"
                                  sx={{
                                    caret: "unset",
                                    minWidth: {
                                      xs: "140px !important",
                                      md: "80px !important",
                                    },
                                  }}
                                  onChange={(e) => {
                                    setPersonData((prev) => ({
                                      ...prev,
                                      [key]: Number(e.target.value),
                                    }));
                                  }}
                                  inputProps={{ min: 0 }}
                                  value={child[key] || 0}
                                />
                              </CRMTableCell>
                            );
                          }
                        )}
                        <CRMTableCell>
                          {toLocaleCurrency(
                            Object.values(personData)?.reduce(
                              (acc, val) => Number(acc) + Number(val),
                              0
                            )
                          )}
                        </CRMTableCell>
                      </CRMTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </JumboScrollbar> */}
        </Box>
      </Box>
    </>
  );
};

export default memo(SetSalesperesonTarget);
