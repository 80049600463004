import baseAxios from "axios";

const axios = baseAxios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      alert("Bad Request: Please check your inputs.");
    } else {
      alert("An unexpected error occurred.");
    }
    return Promise.reject(error);
  }
);

const currentURL = window.location.href;
export const Tenantname = currentURL.split(".")[0].split("//")[1];
export const BASE_URL = `https://backend.crmfarm.in/`;

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;

const apiConfig = {};
apiConfig.URL1 = "https://127.0.0.1:8000/";
apiConfig.URL = BASE_URL;

export { apiConfig };
