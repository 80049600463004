import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Popover, Typography } from '@mui/material';
import CreateEventPopover from './CreateEventPopover';
import useStateRef from 'react-usestateref';


const GCAPI = () => {
    const gapi = window.gapi;
    const google = window.google;

    const CLIENT_ID = process.env.REACT_APP_CALENDAR_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_CALENDAR_API_KEY;
    const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
    const SCOPES = "https://www.googleapis.com/auth/calendar";

    const [eventsArray, setEventsArray] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [tokClient, setTokClient, tokClientRef] = useStateRef('')
    const [accTok, setAccTok, accTokRef] = useStateRef(localStorage.getItem('access_token'))
    const [expIn, setExpIn, expInRef] = useStateRef(localStorage.getItem('expires_in'))

    let gapiInited = false;
    let gisInited = false;


    useEffect(() => {
        gisLoaded();
        gapiLoaded();
    }, []);


    const gapiLoaded = () => {
        gapi.load('client', initializeGapiClient);
    }



    const initializeGapiClient = async () => {
        await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
        });
        gapiInited = true;

        if (accTokRef.current && expInRef.current) {
            gapi.client.setToken({
                access_token: accTokRef.current,
                expires_in: expInRef.current,
            });
            listUpcomingEvents();
        }
    }

    const gisLoaded = () => {
        let tokenClient;
        tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: (response) => {
                console.log("response", response)
            },
        });
        setTokClient(tokenClient)
        gisInited = true;
    }


    const handleAuthClick = () => {
        console.log("tokenClientRef", tokClientRef.current)
        tokClientRef.current.callback = async (resp) => {
            if (resp.error) {
                throw resp;
            }
            await listUpcomingEvents();
            const { access_token, expires_in } = gapi.client.getToken();
            setAccTok(access_token)
            localStorage.setItem("access_token", access_token);
            setExpIn(expires_in)
            localStorage.setItem("expires_in", expires_in);
        };

        if (!(accTokRef.current && expInRef.current)) {
            tokClientRef.current.requestAccessToken({ prompt: 'consent' });
        } else {
            tokClientRef.current.requestAccessToken({ prompt: '' });
        }
    }

    const handleSignoutClick = () => {
        const token = gapi.client.getToken();
        if (token !== null) {
            google.accounts.oauth2.revoke(token.access_token);
            gapi.client.setToken('');
            localStorage.clear();
            window.location.reload()
        }
    }

    const listUpcomingEvents = async () => {
        let response;
        try {
            const request = {
                'calendarId': 'primary',
                'timeMin': (new Date()).toISOString(),
                'showDeleted': false,
                'singleEvents': true,
                'maxResults': 10,
                'orderBy': 'startTime',
            };
            response = await gapi.client.calendar.events.list(request);            
        } catch (err) {
            document.getElementById('content').innerText = err.message;
            return;
        }

        const events = response.result.items;
        setEventsArray(events)
        if (!events || events.length === 0) {
            document.getElementById('content').innerText = 'No events found.';
            return;
        }
    }

    const addManualEvent = () => {
        var event = {
            'kind': 'calendar#event',
            'summary': 'Birthday',
            'location': 'Tuticorin, Tamilnadu',
            'description': 'Party time',
            'start': {
                'dateTime': '2023-09-18T05:00:00.000Z',
                'timeZone': 'UTC',
            },
            'end': {
                'dateTime': '2023-09-18T06:00:00.000Z',
                'timeZone': 'UTC',
            },
            'recurrence': [
                'RRULE:FREQ=DAILY;COUNT=1',
            ],
            'attendees': [
                { 'email': 'sam.imm11@gmail.com', 'responseStatus': 'needsAction' },
            ],
            'reminders': {
                'useDefault': true,
            },
            "guestsCanSeeOtherGuests": true,
        };

        var request = gapi.client.calendar.events.insert({ 'calendarId': 'primary', 'resource': event, 'sendUpdates': 'all' });
        request.execute((event) => {
            window.open(event.htmlLink);
        }, (error) => {
            console.error(error);
        });
    }


    const handleCreateNewEvent = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const open = Boolean(anchorEl)
    const handleClose = () => {
        console.log("anchorE1", anchorEl)
        setAnchorEl(null)
    }
    function getTimeRange(startDate, endDate) {
        const localStartDate = new Date(startDate);
        const localEndDate = new Date(endDate);

        const startTime = formatTime(localStartDate);
        const endTime = formatTime(localEndDate);

        return `${startTime} – ${endTime}`;
    }
    function formatTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes}${ampm}`;
    }
    return (
        <div>
            {console.log("tokenClient", tokClientRef.current)}
            {console.log("eventsArray", eventsArray)}
            <Typography variant='p'>Listing Events:</Typography>
            {eventsArray && eventsArray.map((event) => {
                return (
                    <div key={event.id} className='mb-5'>
                        <Typography variant='h5'>Summary:{event.summary}</Typography>
                        <Typography variant='h5'>Organizer Email:{event.organizer.email}</Typography>
                        <Typography variant='h5'>Location:{event.location}</Typography>
                        <Typography variant='h5'>Description:{event.description}</Typography>
                        <Typography variant='h5'>Start:{new Date(event.start.dateTime).toDateString()}</Typography>
                        <Typography variant='h5'>End:{new Date(event.end.dateTime).toDateString()}</Typography>
                        <Typography variant='h5'> Time :{getTimeRange(event.start.dateTime, event.end.dateTime)}</Typography>
                        {console.log("dateTime", new Date(event.start.dateTime).toDateString())}
                    </div>
                )
            })}
            <div>
                {!accTokRef.current && !expInRef.current ? <Button id="authorize_button" onClick={handleAuthClick} variant="contained">Authorize</Button> : ''}
                {accTokRef.current && expInRef.current ? <Button id="signout_button" onClick={handleSignoutClick} variant="contained">Sign Out</Button> : ''}
                {accTokRef.current && expInRef.current ? <Button id="add_manual_event" onClick={addManualEvent} variant="contained">Add Event Manually</Button> : ''}

                <Typography id="content" variant='h5'></Typography>
            </div>

            <div>
                {accTokRef.current && expInRef.current ? <Button onClick={handleCreateNewEvent} variant="contained">Create Event</Button> : ''}
                <CreateEventPopover open={open} close={handleClose} anchorEl={anchorEl}></CreateEventPopover>
            </div>
        </div>
    );
}

export default GCAPI;
