import React, { useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import SendMail from "./SendMail";
import "./GmailAPI.css";
import axios from "axios";
import GmailInbox from "./GmailInbox";
import gmailIcon from "./../../../assets/images/gmail.png";
// import gmailIcon from './../../../assets/images/g-mail.png'
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import SearchBar from "app/pages/widgets/SearchField";
import { ButtonStyle, DialogBoxCancelButton, DialogBoxSaveButton, FontStyle } from "app/pages/widgets/CRMStyles";
import { useSelector } from "react-redux";
import { Key, apikey } from "app/redux/reducers/locationApp";
const GMAILAPI = () => {
  const gapi = window.gapi;
  const google = window.google;
  const keys = useSelector(Key)
  const Api_keys = useSelector(apikey)


  console.log("keys",keys)
  
  console.log("Api_keys",Api_keys)

  const CLIENT_ID = "772505371832-8g4t32jn0i6a4llc4houi1i107btht7b.apps.googleusercontent.com";
  const API_KEY = "AIzaSyB4wR1OCxHGZKZHlz3lBl6vZngvq08zDF0";


  // const CLIENT_ID = keys;
  // const API_KEY = Api_keys;
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest";
  const SCOPES = "https://www.googleapis.com/auth/gmail.readonly";

  const [tokClient, setTokClient, tokClientRef] = useStateRef("");
  const [composeOpen, setComposeOpen] = useState(false);
  const [accessToken, setAccessToken, accessTokenRef] = useStateRef("");
  const [users, setUsers] = useState("");
  const [headersArray, setHeadersArray] = useState([]);
  const [result, setResult] = useState([]);
  const [bodyData, setBodyData] = useState("");
  const [messageResultArray, setMessageResultArray] = useState([]);
  const [cookieValue, setCookieValue] = useState("");
  const cookieName = "gmail_access_token";
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredList, setFilteredList] = useState();

  let gapiInited = false;
  let gisInited = false;

  useEffect(() => {
    gapiLoaded();
    gisLoaded();
  }, []);
  useEffect(() => {
    setCookie(accessToken);
  }, [accessToken]);

  function setCookie(cvalue) {
    const d = new Date();
    const exminutes = 30;
    d.setTime(d.getTime() + exminutes * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cvalue + ";" + expires + ";path=/";
  }

  async function initializeGapiClient() {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;
    // maybeEnableButtons();
  }
  function checkCookie(cookieName) {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split("=");
      if (name === cookieName) {
        return true;
      }
    }
    return false;
  }

  function gapiLoaded() {
    gapi.load("client", initializeGapiClient);
  }
  function gisLoaded() {
    let tokenClient;
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (response) => {
        console.log("response", response);
      },
    });
    // console.log("tokenClient", tokenClient)
    tokenClient ? setTokClient(tokenClient) : setTokClient("");
    // console.log("TokenRef", tokClientRef.current)
    gisInited = true;
    // maybeEnableButtons();
  }
  // function maybeEnableButtons() {
  //     if (gapiInited && gisInited) {
  //         document.getElementById('authorize_button').style.visibility = 'visible';
  //     }
  // }
  // console.log("tokenClient", tokClientRef.current)
  const handleAuthClick = () => {
    tokClientRef.current.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      // await listLabels();
      await listMessages();
    };
    console.log("gapi.client.getToken()", gapi.client.getToken());

    if (gapi.client.getToken() === null) {
      tokClientRef.current.requestAccessToken({ prompt: "consent" });
    } else {
      tokClientRef.current.requestAccessToken({ prompt: "" });
    }
  };
  useEffect(() => {
    setAccessToken(
      gapi.client?.getToken() ? gapi.client.getToken().access_token : ""
    );
  }, [users]);

  const handleSignoutClick = () => {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken("");
      window.location.reload();
      setTokClient("");
    }
  };

  console.log("resultssssss", result.flat());
  async function listLabels() {
    let response;
    try {
      response = await gapi.client.gmail.users.labels.list({
        userId: "me",
      });
    } catch (err) {
      // document.getElementById('content').innerText = err.message;
      return;
    }
    const labels = response.result.labels;
    if (!labels || labels.length == 0) {
      // document.getElementById('content').innerText = 'No labels found.';
      return;
    }

    const output = labels.reduce(
      (str, label) => `${str}${label.name}\n`,
      "Labels:\n"
    );
    // document.getElementById('content').innerText = output;
  }

  async function getMessageInfo(messageId) {
    try {
      const response = await gapi.client.gmail.users.messages.get({
        userId: "me",
        id: messageId,
      });
      console.log("getMessage", response.result);
      let newValue = [];
      if (response.result) {
        //message
        setMessageResultArray((prev) => [...prev, response.result]);
        let headers = response.result.payload?.headers
          ? response.result.payload?.headers
          : "";
        console.log(
          "response.result.payload.body.data",
          response.result.payload.body
        );
        const messageDate = response.result.payload.body?.data
          ? response.result.payload.body.data
          : "";
        console.log("messageDate", messageDate);
        console.log(
          "response.result.payload.headers",
          response.result.payload.headers
        );
        headers.map((item) => {
          if (
            item.name === "From" ||
            item.name === "Subject" ||
            item.name === "Date"
          ) {
            newValue.push(item.value);
            result.push(item.value);
          }
        });
      }
      return newValue;
    } catch (err) {
      console.error(err);
      return null;
    }

    // const userId = 'me'
    // const url = `https://gmail.googleapis.com/gmail/v1/users/${userId}/messages/${messageId}`
    // const headers = {
    //     Authorization: `Bearer ${gapi.client.getToken().access_token}`
    // }
    // axios.get(url, { headers: headers })
    //     .then(res => { console.log("Message", res) })
    //     .catch(error => { console.error("MessageError", error) })
  }

  async function listMessages() {
    let response;
    try {
      response = await gapi.client.gmail.users.messages.list({
        userId: "me",
      });
      setUsers(gapi.client.gmail.users);
    } catch (err) {
      // document.getElementById('content').innerText = err.message;
      return;
    }

    const messages = response.result.messages;
    // console.log("messages", messages)
    if (!messages || messages.length === 0) {
      // document.getElementById('content').innerText = 'No messages found.';
      return;
    }

    for (const message of messages) {
      const messageInfo = await getMessageInfo(message.id);
      console.log("messageInfo", messageInfo);
      setResult((prev) => [...prev, messageInfo]);
      // console.log("messageInfo", messageInfo);
    }
  }

  const getFormData = (formData) => {
    console.log("formData", formData);
    setComposeOpen(false);
  };

  const handleComposeClick = () => {
    setComposeOpen(true);
  };

  const handleComposeCloseStatus = () => {
    setComposeOpen(false);
  };
  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    console.log(
      "messageResultArray",
      messageResultArray.filter((obj) => {
        return obj;
      })
    );
    let filteredArray = messageResultArray.filter((obj) => {
      const From = obj.payload.headers.filter((obj) => {
        return obj.name === "From" ? obj.value : "";
      }); //for From Value
      const Date = obj.payload.headers.filter((obj) => {
        return obj.name === "Date" ? obj.value : "";
      }); //for Date Value
      const Subject = obj.payload.headers.filter((obj) => {
        return obj.name === "Subject" ? obj.value : "";
      }); //for Subject Value
      console.log("Sub", Subject);
      return (
        obj.snippet.toLowerCase().includes(query.toLowerCase()) ||
        From[0].value.toLowerCase().includes(query.toLowerCase()) ||
        Date[0].value.toLowerCase().includes(query.toLowerCase()) ||
        Subject[0]?.value.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredList(filteredArray);
  };

  return (
    <JumboContentLayoutMain>
      <div className="gmail">
        {console.log("result", result)}
        {console.log("accessToken", accessToken)}
        {console.log("searchQuery", searchQuery)}
        <div className="d-flex justify-content-between p-3">
          <div className="login">
            <img src={gmailIcon} alt="gmail image"></img>
            <Typography align="center" sx={{ color: "gray" }} variant="h2">
              Gmail
            </Typography>
          </div>
          {accessToken && (
            <div>
              {/* <TextField
                id="search-bar"
                label="Search mail"
                variant="outlined"
                onChange={handleSearchQueryChange}
                sx={{ width: "500px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
              <SearchBar
                color="gray"
                border={"1px solid lightgray"}
                width={{ xs: "100%", md: "200px !important" }}
                SearchData={handleSearchQueryChange}
              />
            </div>
          )}

          <div className="d-flex justify-content-end">
            {!accessToken && (
              <Button
                sx={[FontStyle, DialogBoxSaveButton, ButtonStyle]}
                onClick={handleAuthClick}
                variant="contained"
              >
                Login
              </Button>
            )}
            {accessToken && (
              <>
                <Button
                  variant="contained"
                  sx={[FontStyle, DialogBoxSaveButton, ButtonStyle]}
                  onClick={handleComposeClick}
                >
                  + Compose
                </Button>
                <Button
                  sx={[FontStyle, DialogBoxCancelButton, ButtonStyle]}
                  className="ms-2"
                  onClick={handleSignoutClick}
                >
                  Log Out
                </Button>
              </>
            )}
          </div>
        </div>
        {accessToken && (
          <GmailInbox
            messages={result}
            messageResultArray={searchQuery ? filteredList : messageResultArray}
          ></GmailInbox>
        )}
        {composeOpen && (
          <SendMail
            sendFormData={getFormData}
            close={handleComposeCloseStatus}
          />
        )}
      </div>
    </JumboContentLayoutMain>
  );
};

export default GMAILAPI;
