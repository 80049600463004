// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menus-sidebar {
    margin-top: -20px !important;
}

.menuPadding {
    padding-left: 50px !important;
}



.css-nle7uj-MuiStack-root {
    padding: 3px !important;
    border-bottom: 1px solid #ebebeb8f !important;
}

.css-1hy0cpb.css-1sg2p09-MuiListItemText-root {
    color: #595959 !important;
}

.css-1hy0cpb {
    color: #030303 !important;
    background-color: #fff !important;
}

.css-1sg2p09-MuiListItemText-root {
    color: #4b4b4b !important;
}

.css-1hy0cpb,
a {
    color: #0000 !important;
}

.css-1hy0cpb a:focus {
    color: #000 !important;
}

.css-fyc981-MuiListItemText-root {
    flex: 1 1 auto !important;
    min-width: 0 !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    font-size: 12px !important;
    padding-left: -8px !important;
    color: #080808 !important;
}

.css-szutfi-MuiTableCell-root.MuiTableCell-body {
    margin-bottom: 0px !important;
}

.css-1oyfslj-MuiListItemIcon-root {
    min-width: 56px !important;
    color: #475259 !important;
}

.css-1sg2p09-MuiListItemText-root {
    color: #2a978f !important;
    font-size: small !important;
    font-weight: 700 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/shared/sidebars/Sidebar/menus.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,6BAA6B;AACjC;;;;AAIA;IACI,uBAAuB;IACvB,6CAA6C;AACjD;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IAIzB,iCAAiC;AAHrC;;AAMA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IAGI,yBAAyB;IACzB,uBAAuB;IACvB,0BAA0B;IAC1B,6BAA6B;IAC7B,0BAA0B;IAC1B,6BAA6B;IAC7B,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,2BAA2B;AAC/B","sourcesContent":[".menus-sidebar {\n    margin-top: -20px !important;\n}\n\n.menuPadding {\n    padding-left: 50px !important;\n}\n\n\n\n.css-nle7uj-MuiStack-root {\n    padding: 3px !important;\n    border-bottom: 1px solid #ebebeb8f !important;\n}\n\n.css-1hy0cpb.css-1sg2p09-MuiListItemText-root {\n    color: #595959 !important;\n}\n\n.css-1hy0cpb {\n    color: #030303 !important;\n}\n\n.css-1hy0cpb {\n    background-color: #fff !important;\n}\n\n.css-1sg2p09-MuiListItemText-root {\n    color: #4b4b4b !important;\n}\n\n.css-1hy0cpb,\na {\n    color: #0000 !important;\n}\n\n.css-1hy0cpb a:focus {\n    color: #000 !important;\n}\n\n.css-fyc981-MuiListItemText-root {\n    -webkit-flex: 1 1 auto !important;\n    -ms-flex: 1 1 auto !important;\n    flex: 1 1 auto !important;\n    min-width: 0 !important;\n    margin-top: 4px !important;\n    margin-bottom: 4px !important;\n    font-size: 12px !important;\n    padding-left: -8px !important;\n    color: #080808 !important;\n}\n\n.css-szutfi-MuiTableCell-root.MuiTableCell-body {\n    margin-bottom: 0px !important;\n}\n\n.css-1oyfslj-MuiListItemIcon-root {\n    min-width: 56px !important;\n    color: #475259 !important;\n}\n\n.css-1sg2p09-MuiListItemText-root {\n    color: #2a978f !important;\n    font-size: small !important;\n    font-weight: 700 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
