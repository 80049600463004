import { Card, Grid, Stack, Typography } from '@mui/material'
import { FontStyle } from 'app/pages/widgets/CRMStyles';
import React from 'react'

const DealsCard = ({salesamount,dealcount,dealamount}) => {
  return (
    <Stack>
      <Card sx={{ border:"1px solid lightgray", borderRadius: "3px" }}>
        <Grid container sx={{m:{xs:0,md:2}}}>
          <Grid item xs={6} sm={6} md={2.3} sx={{ p: 1 }}>
            <Card
              sx={{
                border:"1px solid lightgray",
                borderRadius: "5px",
                height: "112px",
                background: "linear-gradient(40deg, #effadb 0%, #e6dee9 100%)",
                width: "107px",
                display: "grid",
              }}
            >
              <Stack
                alignContent={"center"}
                justifyContent={"center"}
                display={"grid"}
              >
                <Typography
                  sx={[FontStyle]}
                >
                  Month
                </Typography>
                <Typography sx={[FontStyle,{textAlign:'center'}]}>
                  Dec
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={2.3} sx={{ p: 1 }}>
            <Card
              sx={{
                border:"1px solid lightgray",
                borderRadius: "5px",
                height: "112px",
                background: "linear-gradient(40deg, #E1ECF0 0%, #ffcc99 100%)",
                width: "107px",
                display: "grid",
              }}
            >
              <Stack
                alignContent={"center"}
                justifyContent={"center"}
                textAlign="center"
                display={"grid"}
              >
                <Typography
                  sx={[FontStyle]}
                >
                  No. of Deals
                </Typography>
                <Typography sx={[FontStyle]}>
                  {dealcount&&dealcount.Total_Deal_Count}
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={2.3} sx={{ p: 1 }}>
            <Card
              sx={{
                border:"1px solid lightgray",
                borderRadius: "5px",
                height: "112px",
                width: "107px",
                background: " linear-gradient(40deg, #FFE9EE 0%, #fef6e3 100%)",
                display: "grid",
              }}
            >
              <Stack
                alignContent={"center"}
                justifyContent={"center"}
                textAlign="center"
                display={"grid"}
              >
                <Typography
                  sx={[FontStyle]}
                >
                  Deal Amount
                </Typography>
                <Typography sx={[FontStyle]}>
                  ₹{dealamount&&dealamount.Deal_Amount_for_Month}
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={2.3} sx={{ p: 1 }}>
            <Card
              sx={{
                border:"1px solid lightgray",
                borderRadius: "5px",
                height: "112px",
                width: "107px",
                background: "linear-gradient(40deg, #D8E2DC 0%, #ffcccc 100%)",
                display: "grid",
              }}
            >
              <Stack
                alignContent={"center"}
                justifyContent={"center"}
                display={"grid"}
                textAlign="center"
              >
                <Typography
                  sx={[FontStyle]}
                >
                  Leads Converted to Sales
                </Typography>
                <Typography sx={[FontStyle]}>
                  ₹{dealamount&&dealamount.Deal_Amount_for_Month}
                
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={2.3} sx={{ p: 1,mr:{xs:1,md:3} }}>
            <Card
              sx={{
                border:"1px solid lightgray",
                borderRadius: "5px",
                height: "112px",
                width: "107px",
                background: " linear-gradient(40deg, #FFE5D9 0%, #ace0f9 100%)",
                display: "grid",
              }}
            >
              <Stack
                alignContent={"center"}
                justifyContent={"center"}
                display={"grid"}
                textAlign="center"
              >
                <Typography
                 sx={[FontStyle]}
                >
                  Sales Amount
                </Typography>
                <Typography sx={[FontStyle]}>
                ₹{salesamount&&salesamount.Sales_Amount_for_Month}
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
}

export default DealsCard;