import HandshakeIcon from "@mui/icons-material/Handshake";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import StyleIcon from "@mui/icons-material/Style";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  GiBoxUnpacking,
  GiHumanTarget,
  GiOrganigram,
  GiPencilRuler,
} from "react-icons/gi";
import {
  BsChatQuoteFill,
  BsEnvelopeAtFill,
  BsFileRuledFill,
  BsFillPatchQuestionFill,
  BsFillPinMapFill,
  BsPersonFillCheck,
  BsPersonFillSlash,
} from "react-icons/bs";
import { HiMiniHome, HiMiniMegaphone } from "react-icons/hi2";
import { AiFillBell, AiFillFolderAdd, AiFillSignal } from "react-icons/ai";
import {
  RiAccountPinCircleFill,
  RiContactsBookFill,
  RiContactsFill,
  RiFeedbackLine,
  RiFileList3Fill,
} from "react-icons/ri";
import { IoBag, IoChatbubblesSharp, IoStorefrontSharp } from "react-icons/io5";
import { TbSubtask } from "react-icons/tb";
import {
  MdAccountTree,
  MdBatchPrediction,
  MdCall,
  MdCampaign,
  MdConfirmationNumber,
  MdDiscount,
  MdLeaderboard,
  MdOutlineStorage,
} from "react-icons/md";
import { SiGoogleadsense, SiProducthunt } from "react-icons/si";
import { BiSolidDashboard } from "react-icons/bi";
import {
  FaCommentDots,
  FaEdit,
  FaHandsHelping,
  FaHouseUser,
  FaIdeal,
  FaPiedPiperPp,
  FaShippingFast,
} from "react-icons/fa";
import { TfiStatsUp } from "react-icons/tfi";
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import { FaArrowsToCircle } from "react-icons/fa6";
import { PiClockCountdownFill, PiTabsFill } from "react-icons/pi";
import { TbTargetArrow } from "react-icons/tb";
import CustomFormList from "app/pages/Addmoremodule/CustomFormList";
import SettingsIcon from "@mui/icons-material/Settings";
import { GrTransaction } from "react-icons/gr";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import "./menus.css";

let dynamicForm = await CustomFormList().then((form) => form);

console.log("dynamicForm:", dynamicForm);
const navConfig = [
  {
    id: null,
    title: "Home",
    path: `home`,
    type: "nav-item", // add type
    icon: (
      <HiMiniHome
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
  },
  {
    id: null,
    title: "Notification",
    path: `Home-Notifications`,
    // path: `sales-sales-Notifications`,
    type: "nav-item", // add type
    icon: (
      <AiFillBell
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
  },
  // {
  //   id: null,
  //   title: "Notification",
  //   path: `sales-sales-Notifications`,
  //   type: "nav-item", // add type
  //   icon: (
  //     <AiFillBell
  //       className="sideBarIcon"
  //       style={{ fontSize: 16, color: "#666565" }}
  //     />
  //   ),
  // },
  {
    id: 12,
    title: "Marketing",
    type: "collapsible", // add type
    path: `marketing`,
    icon: (
      <HiMiniMegaphone
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    children: [
      {
        id: null,
        title: "Contact Management",
        type: "collapsible", // add type
        path: `contact-management`,
        icon: (
          <RiContactsBookFill
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        children: [
          {
            id: 43,
            title: "Contacts",
            icon: (
              <RiContactsFill
                className="sideBarIcon"
                style={{ fontSize: 13, color: "#666565" }}
              />
            ),
            path: `app/contacts`,
            type: "nav-item", // add type
          },
          {
            id: 44,
            title: "Calls",
            icon: (
              <MdCall
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `app/call-centre`,
            type: "nav-item", // add type
          },
        ],
      },
      {
        id: null,
        title: "Lead Management",
        type: "collapsible", // add type
        path: `lead-management`,
        icon: (
          <SiGoogleadsense
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        children: [
          {
            id: 45,
            title: "Dashboard",
            type: "nav-item", // add type
            icon: (
              <BiSolidDashboard
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads`,
          },
          {
            id: 46,
            title: "Lead Generation",
            type: "nav-item", // add type
            icon: (
              <PiTabsFill
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/leadgeneration`,
          },
          {
            id: 47,
            title: "Lead Questions",
            path: `Lead-Questions`,
            type: "nav-item", // add type
            icon: (
              <BsFillPatchQuestionFill
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
          },
          {
            id: 48,
            title: "UnAsigned Leads",
            type: "nav-item", // add type
            icon: (
              <BsPersonFillSlash
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/unAsignedLeads`,
          },
          {
            id: 49,
            title: "Unqualified Leads",
            type: "nav-item", // add type
            icon: (
              <BsPersonFillSlash
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/unqualifiedLeads`,
          },
          {
            id: 50,
            title: "Qualified Leads",
            type: "nav-item", // add type
            icon: (
              <BsPersonFillCheck
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `leads/qualifiedLeads`,
          },
        ],
      },
      {
        id: null,
        title: "Deal Management",
        type: "collapsible", // add type
        path: `deal-management`,
        icon: <FaHandsHelping fontSize={14} color="#666565" />,
        children: [
          {
            id: 51,
            title: "Dashboard",
            type: "nav-item", // add type
            icon: (
              <BiSolidDashboard
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `deals-dashboard`,
          },
          {
            id: 69,
            title: "Customer List",
            type: "nav-item", // add type
            path: `customer-list`,
            icon: <LocalOfferIcon sx={{ fontSize: 15 }} />,
          },
          {
            id: 52,
            title: "Deals",
            type: "nav-item", // add type
            icon: (
              <FaIdeal
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `deals`,
          },
          {
            id: 53,
            title: "Pipeline deals",
            type: "nav-item", // add type
            icon: (
              <FaPiedPiperPp
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `deals-pipeline`,
          },
        ],
      },
      {
        id: null,
        title: "Campaign",
        type: "collapsible", // add type
        path: `campaigns`,
        icon: (
          <MdCampaign className="sideBarIcon" fontSize={18} color="#666565" />
        ),
        children: [
          {
            id: 54,
            title: "Dashboard",
            type: "nav-item", // add type
            icon: (
              <BiSolidDashboard
                className="sideBarIcon"
                style={{ fontSize: 14, color: "#666565" }}
              />
            ),
            path: `campaign-dashboard`,
          },
          {
            id: 55,
            title: "Email",
            type: "nav-item", // add type
            icon: (
              <BsEnvelopeAtFill
                className="sideBarIcon"
                style={{ fontSize: 13, color: "#666565" }}
              />
            ),
            path: `campaign-email`,
          },
          {
            id: 56,
            title: "Social Media Campaign",
            type: "nav-item", // add type
            icon: (
              <BsEnvelopeAtFill
                className="sideBarIcon"
                style={{ fontSize: 13, color: "#666565" }}
              />
            ),
            path: `SocialCampaign`,
          },
          // { title: "Templates", path: "/campaign-email-template` },
          // {
          //   id: 57,
          //   title: "Statistics",
          //   type: "nav-item", // add type
          //   icon: (
          //     <TfiStatsUp
          //       className="sideBarIcon"
          //       style={{ fontSize: 14, color: "#666565" }}
          //     />
          //   ),
          //   path: `campaign-statistics`,
          // },
        ],
      },
    ],
  },
  {
    id: null,
    title: "Customer",
    type: "collapsible", // add type
    path: `customers`,
    icon: (
      <IoStorefrontSharp
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    children: [
      {
        id: 71,
        title: "Additional Licence",
        type: "nav-item", // add type
        path: `customer-additoinal-licence`,
        icon: <BsChatQuoteFill sx={{ fontSize: 14 }} />,
      },
      {
        id: 59,
        title: "Addon Subscription",
        type: "nav-item", // add type
        path: `addon-subscription`,
        icon: <IoBag sx={{ fontSize: 14 }} />,
      },
      {
        id: 72,
        title: "Additional Storage",
        type: "nav-item", // add type
        path: `customer-additoinal-storage`,
        icon: <BsChatQuoteFill sx={{ fontSize: 14 }} />,
      },
      {
        id: 73,
        title: "Cancel Plan",
        type: "nav-item", // add type
        path: `customer-cancel-plan`,
        icon: <RiFileList3Fill sx={{ fontSize: 15 }} />,
      },
      {
        id: 74,
        title: "Renewal",
        type: "nav-item", // add type
        path: `customer-renewal`,
        icon: <PiClockCountdownFill sx={{ fontSize: 16 }} />,
      },
    ],
  },

  {
    id: 13,
    title: "Sales",
    type: "collapsible", // add type
    path: `sales`,
    icon: (
      <IoStorefrontSharp
        className="sideBarIcon"
        style={{ fontSize: 15, color: "#666565" }}
      />
    ),
    children: [
      {
        id: 59,
        title: "Plan",
        type: "nav-item", // add type
        icon: (
          <RiAccountPinCircleFill
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `plan`,
      },
      {
        id: 58,
        title: "Subscription",
        type: "nav-item", // add type
        icon: (
          <BiSolidDashboard
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `subscription`,
      },

      {
        id: 60,
        title: "Sales Invoice",
        type: "nav-item", // add type
        icon: (
          <RiFeedbackLine
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `sale-invoice`,
      },
      {
        id: 61,
        title: "Payments",
        type: "nav-item", // add type
        icon: (
          <RiAccountPinCircleFill
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `sales-payments`,
      },

      {
        id: 62,
        title: "Instance Request",
        type: "nav-item", // add type
        icon: (
          <FaCommentDots
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `sales-instance-request`,
      },
      {
        id: 70,
        title: "Instance Creation",
        type: "nav-item", // add type
        path: `customer-intance-creation`,
        icon: <StyleIcon sx={{ fontSize: 15 }} />,
      },
    ],
  },

  {
    id: null,
    title: "Territory Management",
    type: "collapsible", // add type
    path: `territory-management`,
    icon: <HandshakeIcon sx={{ fontSize: 15 }} />,
    children: [
      // {
      //   title: 'Organization',
      //   icon: <CgOrganisation className='sideBarIcon' style={{ fontSize: 13, color: "#666565", }} />,
      //   path:  `settings/organization-structure`
      // },
      {
        id: null,
        title: "Territory Hierarchy",
        type: "nav-item", // add type
        icon: (
          <GiOrganigram
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `territory-hierarchy`,
      },
      // { title: "Employee Organization", path: "/deals" },

      {
        id: 79,
        // id: null,
        title: "Territory",
        type: "nav-item", // add type
        icon: (
          <BsFillPinMapFill
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `territory-overview`,
      },

      // {
      //   title: "Sales Performance",
      //   icon: (
      //     <BsSpeedometer
      //       className="sideBarIcon"
      //       style={{ fontSize: 13, color: "#666565" }}
      //     />
      //   ),
      //   path: `sales-performance`,
      // },
      // { title: "Sales person score card", path: "/sales-person-score-card" },
      {
        id: 80,
        // id: null,
        title: "Sales target",
        type: "nav-item", // add type
        icon: (
          <TbTargetArrow
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `sales-target`,
      },
      {
        id: 81,
        // id: null,
        title: "Sales person target",
        type: "nav-item", // add type
        icon: (
          <GiHumanTarget
            className="sideBarIcon"
            style={{ fontSize: 13, color: "#666565" }}
          />
        ),
        path: `sales-person-target`,
      },
    ],
  },

  {
    id: 16,
    title: "Task Management",
    type: "collapsible", // add type
    // path: "/task/list-task",
    path: `task-managements`,
    icon: (
      <TbSubtask
        className="sideBarIcon"
        style={{ fontSize: 16, color: "#666565" }}
      />
    ),
    children: [
      {
        id: 88,
        title: "List Task",
        type: "nav-item", // add type
        path: `task-management`,
      },
      {
        id: 87,
        title: "Task Status",
        type: "nav-item", // add type
        path: `task-status`,
      },
      {
        id: 89,
        title: "Task Gantt Chart",
        type: "nav-item", // add type
        path: `task-gantt-chart`,
      },
      {
        id: 90,
        title: "Task Calendar",
        type: "nav-item", // add type
        path: `task-calendar`,
      },
    ],
  },
  {
    id: 17,
    title: "Work Flow ",
    type: "collapsible", // add type
    // path: "/task/list-task",
    path: `work-flows`,
    icon: (
      <GiPencilRuler
        className="sideBarIcon"
        style={{ fontSize: 14, color: "#666565" }}
      />
    ),
    children: [
      {
        id: 91,
        title: "Work Flow Rule",
        type: "nav-item", // add type
        icon: (
          <BsFileRuledFill
            className="sideBarIcon"
            style={{ fontSize: 14, color: "#666565" }}
          />
        ),
        path: `Work-Flow`,
      },
    ],
  },

  // {
  //   id: 19,
  //   type: "collapsible",
  //   title: "Reports",
  //   path: `reports`,
  //   icon: (
  //     <AiFillSignal
  //       className="sideBarIcon"
  //       style={{ fontSize: 14, color: "#666565" }}
  //     />
  //   ),
  //   children: [
  //     {
  //       id: null,
  //       type: "collapsible",
  //       title: "Sales Performance",
  //       path: `report/sales-performance`,
  //       children: [
  //         {
  //           id: 110,
  //           type: "nav-item",
  //           title: "Sales pipeline report",
  //           path: `reports/SalesPipeLineReport`,
  //         },
  //         // { id: 112,title: "Sales forecast report", path: `reports/SalesForecastReport` },
  //         // {
  //         // id: 113,
  //         //   title: "Sales rep performance report",
  //         //   path: `reports/SalesRepPerformanceReport`,
  //         // },
  //         {
  //           id: 114,
  //           type: "nav-item",
  //           title: "Pro/Ser performance report",
  //           path: `reports/ProAndSerPerformanceReport`,
  //         },
  //         {
  //           id: 115,
  //           type: "nav-item",
  //           title: "Sales activity report",
  //           path: `reports/SalesActivityReports`,
  //         },
  //       ],
  //     },
  //     {
  //       id: null,
  //       type: "collapsible",
  //       title: "Lead and opportunity Management",
  //       path: `report/lead-management`,
  //       children: [
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Lead Conversion report",
  //           path: `leads/addleads/LeadConversionreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Opportunity pipeline report",
  //           path: `leads/addleads/Opportunitypipelinereport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Sales forecast by stage report",
  //           path: `leads/addleads/Salesforecast`,
  //         },
  //         // {
  //         // id: null,
  //         //   title: "Deal size and win rate report",
  //         //   path:  `leads/addleads/DealSize`,
  //         // },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Sales funner report",
  //           path: `leads/addleads/SalesFunnelReport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Lead Source report",
  //           path: `leads/addleads/LeadSourceReports`,
  //         },
  //         // {
  //         // id: null,
  //         //   title: "Sales Pipeline report",
  //         //   path:  `leads/addleads/SalesPipelineReport`,
  //         // },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Opportunity aging report",
  //           path: `leads/addleads/OpportunityAgingReport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Conversion rate report",
  //           path: `leads/addleads/ConversionRateReport`,
  //         },
  //         // {
  //         // id: null,
  //         //   title: "Sales forecast report",
  //         //   path:  `leads/addleads`,
  //         // },
  //       ],
  //     },
  //     {
  //       id: null,
  //       type: "collapsible",
  //       title: "Customer Engagement",
  //       path: `report/customer-engagement`,
  //       children: [
  //         // {
  //         // id: null,
  //         // type: "nav-item",
  //         //   title: "Customer interaction history report ",
  //         //   path: `customer-support/CustomerInteractionHistoryReport`,
  //         // },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Email Campaign performance report",
  //           path: `customer-support/EmailCampaignPerformanceReport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Social media engagement report",
  //           path: `customer-support/SocialMediaEngagementReport`,
  //         },
  //         // {
  //         // id: null,
  //         //   title: "Customer satisfaction report",
  //         //   path: `customer-support/CustomerSatisfactionReport`,
  //         // },
  //         // {
  //         // id: null,
  //         //   title: "Website traffic report",
  //         //   path: `customer-support/WebsiteTrafficReport`,
  //         // },
  //       ],
  //     },

  //     // {
  //     // id: null,
  //     //   title: "Financial Metrics",
  //     //   children: [
  //     //     { id: null,title: "Revenue report ", path: `home` },
  //     //     { id: null,title: "Sales Performance report", path: `home` },
  //     //     { id: null,title: "Profit & Loss report", path: `home` },
  //     //     { id: null,title: "Cost analysis report", path: `home` },
  //     //     { id: null,title: "Catch flow report", path: `home` },
  //     //   ],
  //     // },
  //     //karthik add
  //     {
  //       id: null,
  //       type: "collapsible",
  //       title: "New Reports",
  //       path: `report/new-reports`,
  //       children: [
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Daily sales report",
  //           path: `reports/Dailysalesreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Weekly sales report",
  //           path: `reports/Weeklysalesreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Monthly sales report",
  //           path: `reports/Monthlysalesreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Customer Churn report",
  //           path: `reports/CustomerChurnreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Customer addition report",
  //           path: `reports/Customeradditionreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Customer retention report",
  //           path: `reports/Customerretentionreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Sales rep perform report",
  //           path: `reports/newreports/Salesrepperformreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Lead and Opportunity report",
  //           path: `reports/LeadandOpportunityreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "Sales performance report",
  //           path: `reports/Salesperformancereport`,
  //         },
  //       ],
  //     },
  //     {
  //       id: null,
  //       type: "collapsible",
  //       title: "Sales Funnel Reports",
  //       path: `report/sales-funnel-reports`,
  //       children: [
  //         {
  //           id: null,
  //           type: "nav-item",
  //           title: "sales Funnel report",
  //           path: `reports/salesFunnelreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item", // add type
  //           title: "Pipeline Development report",
  //           path: `reports/PipelineDevelopmentreport`,
  //         },
  //         {
  //           id: null,
  //           type: "nav-item", // add type
  //           title: "Stage Distribution CRM report",
  //           path: `reports/StageDistributionCRMreport`,
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   id: 18,
  //   title: "Analytics",
  //   type: "collapsible", // add type
  //   path: `analytics`,
  //   icon: (
  //     <AiFillSignal
  //       className="sideBarIcon"
  //       style={{ fontSize: 14, color: "#666565" }}
  //     />
  //   ),
  //   children: [
  //     // new rout start
  //     {
  //       id: 92,
  //       title: "Sales by Customer",
  //       type: "nav-item", // add type
  //       path: `analytics/salesbycustomer`,
  //     },
  //     {
  //       id: 93,
  //       title: "Activity Overview",
  //       type: "nav-item", // add type
  //       path: `analytics/activityoverview`,
  //     },
  //     {
  //       id: 94,
  //       title: "Sales Goals",
  //       type: "nav-item", // add type
  //       path: `analytics/SalesGoals`,
  //     },
  //     // {
  //     //   title: "Stage Conversion",
  //     //   path:  `analytics/stageconversionbyowner`,
  //     // },
  //     // {
  //     //   title: "Stage Duration Analysis",
  //     //   path:  `analytics/stagedurationanalysis`,
  //     // },
  //     // {
  //     //   title: "Time to First Action",
  //     //   path:  `analytics/TimetoFirstActionbyOwner`,
  //     // },
  //     {
  //       id: 95,
  //       title: "Lead Sources",
  //       type: "nav-item", // add type
  //       path: `analytics/LeadSources`,
  //     },
  //     {
  //       id: 96,
  //       title: "Call Duration",
  //       type: "nav-item", // add type
  //       path: `analytics/CallDuration`,
  //     },
  //     {
  //       id: 97,
  //       title: "Call Length Vs Time Of Day",
  //       type: "nav-item", // add type
  //       path: `analytics/CallLengthVsTimeOfDay`,
  //     },
  //     // {
  //     // id: null,
  //     //   title: "CRM DashBoard",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/CRMDashBoard`,
  //     // },
  //     {
  //       id: 98,
  //       title: "Sales KPI Dashboard",
  //       type: "nav-item", // add type
  //       path: `analytics/SalesKPIDashboard`,
  //     },
  //     {
  //       id: 99,
  //       title: "Churn Analysis Dashboard",
  //       type: "nav-item", // add type
  //       path: `analytics/ChurnAnalysisDashboard`,
  //     },
  //     {
  //       id: 100,
  //       title: "Sales Dashboard",
  //       type: "nav-item", // add type
  //       path: `analytics/SalesDashboard`,
  //     },
  //     // {
  //     //   title: "Key Performance Indicators",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/KeyPerformanceIndicator`,
  //     // },
  //     {
  //       id: 101,
  //       title: "Sales Opportunities Overview",
  //       type: "nav-item", // add type
  //       path: `analytics/SalesOpportunitiesOverview`,
  //     },
  //     {
  //       id: 102,
  //       title: "Opportunities Individual Report",
  //       type: "nav-item", // add type
  //       path: `analytics/OpportunitiesIndividualReport`,
  //     },
  //     {
  //       id: 103,
  //       title: "Activity Individual Report",
  //       type: "nav-item", // add type
  //       path: `analytics/ActivityIndividualReport`,
  //     },
  //     // {
  //     //   title: "Customer Rentention Dashboard",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/CustomerRententionDashboard`,
  //     // },
  //     // {
  //     //   title: "Lead Performance",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/LeadPerformance`,
  //     // },
  //     {
  //       id: 104,
  //       title: "Call Activities",
  //       type: "nav-item", // add type
  //       path: `analytics/CallActivities`,
  //     },
  //     // {
  //     //   title: "Monthly Sales Board",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/MonthlySalesDashboard`,
  //     // },
  //     // {
  //     //   title: "Sales Product Performance Dashboard",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/SalesProductPerformance`,
  //     // },
  //     // {
  //     //   title: "Executive Sales Performance Dashboard",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/ExecutiveSalesPerformance`,
  //     // },
  //     // {
  //     //   title: "Total Sales And Growth",
  //     // type: 'nav-item', // add type
  //     //   path:  `analytics/TotalSalesAndGrowth`,
  //     // },
  //     {
  //       id: 105,
  //       title: "Sales Volume By Channel",
  //       type: "nav-item", // add type
  //       path: `analytics/SalesVolumeByChannel`,
  //     },
  //     {
  //       id: 106,
  //       title: "Monthly Sales Activity Report",
  //       type: "nav-item", // add type
  //       path: `analytics/MonthlySalesActivityReport`,
  //     },
  //     {
  //       id: 107,
  //       title: "Monthly Sales Call Report Template",
  //       type: "nav-item", // add type
  //       path: `analytics/MonthlySalesCallReport`,
  //     },
  //     {
  //       id: 108,
  //       title: "Simple Monthly Sales Report",
  //       type: "nav-item", // add type
  //       path: `analytics/SimpleMonthlySalesReport`,
  //     },
  //     {
  //       id: 109,
  //       title: "Monthly Retail Sales",
  //       type: "nav-item", // add type
  //       path: `analytics/MonthlyRetailSales`,
  //     },

  //     // new rout end
  //     // {
  //     //   title: "Form 1",
  //     //   path: "/analytics",
  //     // },
  //     // {
  //     //   title: "Form 2",
  //     //   path: "/analytics",
  //     // },
  //     // {
  //     //   title: "Form 3",
  //     //   path: "/analytics",
  //     // },
  //     // {
  //     //   title: "Form 4",
  //     //   path: "/analytics",
  //     // },
  //     // {
  //     //   title: "Form 5",
  //     //   path: "/analytics",
  //     // },
  //     // {
  //     //   title: "Form 6",
  //     //   path: "/analytics",
  //     // },
  //     // {
  //     //   title: "Form 7",
  //     //   path: "/analytics",
  //     // },
  //   ],
  // },

  // {
  //   id: null,
  //   title: "Custom Form",
  //   type: "collapsible", // add type
  //   path: `custom-form`,
  //   icon: (
  //     <HiMiniMegaphone
  //       className="sideBarIcon"
  //       style={{ fontSize: 15, color: "#666565" }}
  //     />
  //   ),
  //   children: [...dynamicForm],
  // },

  // {
  //   id: 21,
  //   title: "Customer Engagement",
  //   type: "nav-item", // add type
  //   icon: (
  //     <HiMiniMegaphone
  //       className="sideBarIcon"
  //       style={{ fontSize: 15, color: "#666565" }}
  //     />
  //   ),
  //   path: `customerengagementlist`,
  // },

  // settings
  {
    id: null,
    title: "Settings",
    type: "collapsible",
    path: `settings`,
    icon: <SettingsIcon style={{ fontSize: 16, color: "#666565" }} />,
    children: [
      {
        id: 63,
        path: `settings/edit-company-details`,
        title: "Edit Company Details",
        type: "nav-item",
        icon: <FaEdit style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 64,
        path: `settings/roles&users`,
        title: "Roles & Users",
        type: "nav-item",
        icon: <FaHouseUser style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 65,
        path: `settings/lead-scoring`,
        title: "Lead Scoring",
        type: "nav-item",
        icon: <MdLeaderboard style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 66,
        path: `settings/lead-values`,
        title: "Lead Scoring Values",
        type: "nav-item",
        icon: <MdLeaderboard style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 67,
        path: `settings/lead-prediction`,
        title: "Lead Prediction",
        type: "nav-item",
        icon: <MdBatchPrediction style={{ fontSize: 18, marginLeft: 15 }} />,
      },
      {
        id: 68,
        path: `settings/lead-Scoringsetup`,
        title: "Lead Status",
        type: "nav-item",
        icon: <MdBatchPrediction style={{ fontSize: 18, marginLeft: 15 }} />,
      },
      {
        id: 69,
        path: `settings/deal-WeightSetup`,
        title: "Deal Weight Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 70,
        path: `settings/deal-FactorSetup`,
        title: "Deal Factor Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 71,
        path: `settings/deal-PredictSetup`,
        title: "Deal Predict Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 72,
        path: `settings/deal-probability`,
        title: "Deal Probability",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 73,
        path: `settings/Setup`,
        title: "Auto Responder Setup",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 74,
        path: `template`,
        title: "Templates",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      // {
      //   id: 75,
      //   path: `settings/TaxSetup`,
      //   title: "Tax Setup",
      //   type: "nav-item",
      //   icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      // },
      // {
      //   id: 76,
      //   path: `settings/FactorySetup`,
      //   title: "Factory Setup",
      //   type: "nav-item",
      //   icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      // },
      // {
      //   id: 77,
      //   path: `InvoiceTemplate`,
      //   title: "Invoice Template",
      //   type: "nav-item",
      //   icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      // },
      {
        id: 78,
        path: `SalesCallActivity`,
        title: "Sales Person Target",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 79,
        path: `AddAgent`,
        title: "Add Agent",
        type: "nav-item",
        icon: <FaIdeal style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 80,
        path: `settings/storage-price`,
        title: "Storage Price",
        type: "nav-item",
        icon: <MdOutlineStorage style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 81,
        path: `settings/addon-price`,
        title: "Addon Price",
        type: "nav-item",
        icon: <AiFillFolderAdd style={{ fontSize: 16, marginLeft: 15 }} />,
      },

      {
        id: 75,
        path: `settings/system-preference`,
        title: "System Preference",
        type: "nav-item",
        icon: <GrTransaction style={{ fontSize: 16, marginLeft: 15 }} />,
      },
      {
        id: 76,
        path: `settings/lookup-list`,
        title: "Lookup List",
        type: "nav-item",
        icon: <FindInPageIcon style={{ fontSize: 16, marginLeft: 15 }} />,
      },
    ],
  },
];

export default navConfig;
