import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import Setting from "../Setting";
// import settingServices from "app/services/setting-api-services";
import axios, { Axios } from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import CKEditor from "@ckeditor/ckeditor5-react";
import {
  ButtonStyle,
  componentTitle,
  DialogBoxCancelButton,
  DialogBoxSaveButton,
  dialogTitle,
  DisplayFlex,
  FontStyle,
} from "app/pages/widgets/CRMStyles";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

const CampaignEditTemplate = ({
  setListPage,
  setEditPage,
  transfer,
  fetchTemplates,
}) => {
  const token = localStorage.getItem("accesstoken");

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const [id, setId] = useState("");
  const [templateId, setTemplateId] = useState("TEM000000001");

  const editorConfig = {
    placeholder: "Enter text here...", // Replace with your desired placeholder text
  };

  // Creating Templates

  const [tempname, setTempName] = useState(transfer.Template_Name);
  const [temptype, setTemptype] = useState(transfer.Template_Type);
  // const[tempcontent,setTempContent]=useState(null);
  const [editorContent, setEditorContent] = useState(transfer.Template_Content);

  useEffect(() => {
    setTempName(transfer.Template_Name);
    setTemptype(transfer.Template_Type);
    setEditorContent(transfer.Template_Content);
  }, [transfer]);

  const onChange = (evt) => {
    console.log("eve", evt);
    var newContent = evt.editor.getData();
    console.log("newContent", newContent);
    setEditorContent(newContent);
  };

  const handleTemplateSubmit = async (e) => {
    e.preventDefault();

    var payload = {
      id: transfer.id,
      UserTemplate_Id: transfer.UserTemplate_Id,
      Template_Type: temptype,
      Template_Name: tempname,
      Template_Content: editorContent,
      Created_By: localStorage.getItem("UserId"),
      Updated_By: localStorage.getItem("UserId"),
    };
    console.log("payload", payload);
    try {
      const res = await axios.put(
        `${BASE_URL}/useradmin/CRMUserTemplateCRUD/`,
        payload,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      //  console.log("res", res);
      setListPage(true);
      setEditPage(false);
      fetchTemplates();
      // Add any success handling code here, such as showing a success message
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  return (
    <>
      {/* <Setting /> */}
      <Typography sx={[dialogTitle]}>Templates</Typography>

      <form onSubmit={handleTemplateSubmit}>
        <Div sx={{ minHeight: "350px" }}>
          <Grid container sx={{ mt: 3 }}>
            <Grid item xs={12} className="row" sx={{ p: "0px !important" }}>
              <Typography
                id="Typography-root"
                className="col-2"
                sx={[FontStyle, { mt: 1 }]}
              >
                Template Name
              </Typography>
              <TextField
                className="col-3 input-box"
                id="outlined-basic"
                name="templateName"
                value={tempname}
                onChange={(e) => {
                  setTempName(e.target.value);
                }}
                placeholder="Template Name"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} className="row" sx={{ mt: 1 }}>
              <Typography
                id="Typography-root"
                className="col-2"
                sx={[FontStyle, { mt: 1 }]}
              >
                Template Type
              </Typography>
              <FormControl className="col-6">
                <Autocomplete
                  options={["Campaign", "Email", "Document"]}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option && option) {
                      return option;
                    }
                    return "";
                  }}
                  value={temptype}
                  onChange={(e, newValue) => {
                    setTemptype(newValue);
                  }}
                  id="outlined-basic"
                  className="search-select col-6"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        borderRadius: "5px",
                      }}
                      placeholder="select Template Type"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={9} lg={9} sx={{ mt: 3 }}>
              <CKEditor
                content={editorContent}
                events={{
                  // blur: onBlur,
                  // afterPaste: afterPaste,
                  change: onChange,
                }}
              />
            </Grid>
          </Grid>
          <Div
            sx={[
              DisplayFlex,
              { mt: 5, justifyContent: "flex-start", gap: "10px !important" },
            ]}
          >
            <CustomButton type="submit" label="Save" Icon="saveIcon" />

            <Cancelbutton
              label="Cancel"
              onClick={() => {
                setListPage(true);
                setEditPage(false);
              }}
            />
          </Div>
        </Div>
      </form>
    </>
  );
};

export default CampaignEditTemplate;
