import React from "react";
import { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  Card,
  FormControl,
  Box,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import Div from "@jumbo/shared/Div";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { BASE_URL } from "app/services/auth-services";
import {
  DisplayFlex,
  FontStyle,
  avatarColors,
  dialogTitle,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import {
  AvatarCircle,
  Cancelbutton,
  CustomButton,
  htmlToText,
  SelectDropdown,
} from "app/pages/widgets/StylesComponents";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

const DealClosedActivity = ({ id }) => {
  const [height, setHeight] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const header = {
    headers: {
      db: "crmuseradmin1",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  // For Choose Period Purpose

  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [fromDate1, setfromDate1] = useState("");
  const [toDate1, settoDate1] = useState("");
  const [customDate, setCustomDate] = useState(false);
  const [currentDate, setCurrentDate] = useState("");

  const periodOptions = [
    "Previous Week",
    "Previous Month",
    "Previous Quarter",
    "Previous Year",
    "This Week",
    "This Month",
    "This Quarter",
    "This Year",
    "Last One Year",
    "Last Six Month",
    "Last Three Month",
    "Custom Date",
  ];

  let date1 = new Date();
  let day = date1.getDate();
  let month = date1.getMonth() + 1;
  let year = date1.getFullYear();
  let TodayDate = `${year}-${month < 10 ? "0" + month : month}-${day}`;

  const handlePeriodChange = (event, newValue) => {
    setSelectedPeriod(newValue);
    setSelectedDate(null);
    setfromDate1("");
    settoDate1("");

    let date1;

    let startDate1; // for previous
    let endDate1;

    switch (newValue) {
      case "This Week":
        startDate1 = moment().startOf("week");
        endDate1 = moment().endOf("week");

        let ThisWeekStart = startDate1?.format("YYYY-MM-DD");
        let ThisWeekEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(ThisWeekStart);
        setCurrentDate(ThisWeekEnd);

        setCustomDate(false);

        return;

      case "This Month":
        startDate1 = moment().startOf("month");
        endDate1 = moment().endOf("month");

        let ThisMonthStart = startDate1?.format("YYYY-MM-DD");
        let ThisMonthEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(ThisMonthStart);
        setCurrentDate(ThisMonthEnd);

        setCustomDate(false);

        return;

      case "This Quarter":
        startDate1 = moment().startOf("quarter");
        endDate1 = moment().endOf("quarter");

        let ThisQuarterStart = startDate1?.format("YYYY-MM-DD");
        let ThisQuarterEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(ThisQuarterStart);
        setCurrentDate(ThisQuarterEnd);

        setCustomDate(false);

        return;

      case "This Year":
        startDate1 = moment().startOf("year");
        endDate1 = moment().endOf("year");

        let ThisYearStart = startDate1?.format("YYYY-MM-DD");
        let ThisYearEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(ThisYearStart);
        setCurrentDate(ThisYearEnd);

        setCustomDate(false);

        return;

      case "Previous Week":
        startDate1 = moment().subtract(1, "week").startOf("week");
        endDate1 = moment().subtract(1, "week").endOf("week");

        let PrevWeekStart = startDate1?.format("YYYY-MM-DD");
        let PrevWeekEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(PrevWeekStart);
        setCurrentDate(PrevWeekEnd);

        setCustomDate(false);

        return;

      case "Previous Month":
        startDate1 = moment().subtract(1, "month").startOf("month");
        endDate1 = moment().subtract(1, "month").endOf("month");

        let PrevMonthStart = startDate1?.format("YYYY-MM-DD");
        let PrevMonthEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(PrevMonthStart);
        setCurrentDate(PrevMonthEnd);

        setCustomDate(false);

        return;

      case "Previous Quarter":
        startDate1 = moment().subtract(1, "quarter").startOf("quarter");
        endDate1 = moment().subtract(1, "quarter").endOf("quarter");

        let PrevQuarterStart = startDate1?.format("YYYY-MM-DD");
        let PrevQuarterEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(PrevQuarterStart);
        setCurrentDate(PrevQuarterEnd);

        setCustomDate(false);

        return;

      case "Previous Year":
        startDate1 = moment().subtract(1, "year").startOf("year");
        endDate1 = moment().subtract(1, "year").endOf("year");

        let MomentDateStart = startDate1?.format("YYYY-MM-DD");
        let MomentDateEnd = endDate1?.format("YYYY-MM-DD");

        setSelectedDate(MomentDateStart);
        setCurrentDate(MomentDateEnd);

        setCustomDate(false);

        return;

      case "Last One Year":
        date1 = moment().subtract(1, "year");
        setCustomDate(false);

        break;

      case "Last Six Month":
        date1 = moment().subtract(6, "months");
        setCustomDate(false);

        break;

      case "Last Three Month":
        date1 = moment().subtract(3, "months");
        setCustomDate(false);

        break;

      case "Custom Date":
        setCustomDate(true);
        break;
      default:
        date1 = null;
    }
    let MomentDate = date1?.format("YYYY-MM-DD");
    setSelectedDate(MomentDate);
    setCurrentDate(TodayDate);
  };

  const NotesFilter = async () => {
    if (
      selectedDate !== "" &&
      selectedDate !== undefined &&
      currentDate !== ""
    ) {
      console.log("selectedDate", selectedDate, currentDate);
      await axios
        .get(
          `${BASE_URL}/user/DealActivitiesFilter/?fromdate=${selectedDate}&todate=${currentDate}&Deal_Id=${Number(
            id
          )}`,
          header
        )
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((error) => {
          console.log("error", error);
        });

      //
    }
    if (fromDate1 !== "" && toDate1 !== "") {
      await axios
        .get(
          `${BASE_URL}/user/DealActivitiesFilter/?fromdate=${fromDate1}&todate=${toDate1}&Deal_Id=${Number(
            id
          )}`,
          header
        )
        .then((res) => {
          setDatas(res?.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  // Get the Contact List Notes
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${BASE_URL}/user/DealActivitiesClosed/?Deal_Id=${id}`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setDatas(res.data.results);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // Search Functionality
  const SearchData = (e) => {
    console.log(e.target.value);
    e.target.value !== "" ? handleSearch(e.target.value) : getData();
  };
  const handleSearch = (search) => {
    axios
      .get(
        `${BASE_URL}/user/DealClosedActivitiesSearch/?Deal_Id=${id}&search=${search}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDatas(res.data || []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await NotesFilter();
    };
    fetchData();
  }, [selectedDate, fromDate1, toDate1, id]);

  return (
    <>
      <Div>
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          // aria-labelledby="draggable-dialog-title"
        >
          <DialogContent>
            <Typography
              id="Typography-root"
              sx={[dialogTitle, { backgroundColor: "transparent !important" }]}
            >
              Closed Activities
            </Typography>
            <DialogContentText>
              <TextField
                className="input-box col-12 mb-1"
                size={"small"}
                placeholder={"Enter task name"}
              />

              <TextField
                multiline
                className="multiline-box col-12 mb-1"
                rows={5}
                maxRows={10}
                placeholder={"Enter task description..."}
              />
              <TextField
                className="input-box col-6 mb-1 mr-1"
                type="date"
                placeholder={"Expected Date"}
              />
              <TextField
                className="input-box col-6 mb-1 mr-1"
                type="time"
                placeholder={"Expected Time"}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Div
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <CustomButton label="Update" type="submit" Icon="saveIcon" />

              <Cancelbutton onClick={() => setShowDialog(false)} />
            </Div>
          </DialogActions>
        </Dialog>

        <Div>
          <Div
            sx={[
              DisplayFlex,
              {
                mt: 2,
                flexWrap: "wrap",
                gap: "0px !important",
                justifyContent: "space-between",
                alignItems: "center",
              },
            ]}
          >
            <Div sx={[DisplayFlex, { flexWrap: "wrap" }]}>
              <FormControl
                sx={{
                  width: "250px",
                }}
              >
                <SelectDropdown
                  value={selectedPeriod}
                  options={periodOptions || []}
                  onChange={handlePeriodChange}
                  placeholder="Choose a Period"
                />
              </FormControl>
            </Div>

            <Div sx={[DisplayFlex, { flexWrap: "wrap" }]}>
              {/* <SearchBar
                width={"200px !important"}
                border={"1px solid lightgray"}
                SearchData={SearchData}
              /> */}
            </Div>
          </Div>

          {Boolean(customDate) && (
            <Box sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap", mt: 2 }]}>
              <Div sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap" }]}>
                <FormControl>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    From
                  </Typography>
                  <TextField
                    sx={[FontStyle]}
                    type={"date"}
                    className="input-box"
                    value={fromDate1}
                    onChange={(e) => setfromDate1(e.target.value)}
                  />
                </FormControl>
              </Div>
              <Div sx={[DisplayFlex, { gap: "10px", flexWrap: "wrap" }]}>
                <FormControl>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    To
                  </Typography>
                  <TextField
                    sx={[FontStyle]}
                    type={"date"}
                    className="input-box"
                    value={toDate1}
                    onChange={(e) => settoDate1(e.target.value)}
                  />
                </FormControl>
              </Div>
            </Box>
          )}
        </Div>
        <JumboScrollbar
          autoHeight={true}
          autoHeightMax={window.innerHeight}
          autoHideTimeOut={2000}
        >
          {datas?.map((data, index) => {
            let avatar =
              index >= 10 ? avatarColors[index % 10] : avatarColors[index];

            return (
              <Timeline key={index} sx={{ m: 0, mt: 2, p: 0 }}>
                <TimelineItem
                  sx={{
                    "&::before": {
                      display: "none",
                    },
                  }}
                >
                  <TimelineSeparator>
                    <Badge color="info">
                      <AvatarCircle
                        fullTitle={true}
                        size="32px"
                        fontSize="10px"
                        bgColor={avatar}
                        title={moment(data.Expected_Date).format("MMM D")}
                      />
                    </Badge>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      p: (theme) => theme.spacing(0.5, 0),
                      ml: 2.5,
                    }}
                  >
                    {/* {icon1 === index && ( */}
                    <>
                      <Div
                        // className="card"
                        sx={{
                          p: 1,
                          backgroundColor:
                            height === index ? "#EAFCEF" : "#fbfcff",
                        }}
                      >
                        <Div
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                            textAlign: "start",
                            alignItems: "start",
                            justifyContent: "space-between",
                            // width:"70% !important"
                          }}
                        >
                          <Card
                            sx={{
                              backgroundColor:
                                height === index ? "#EAFCEF" : "#fbfcff",
                              height: height === index ? "fit-content" : "44px",
                              width: "100% !important",
                              flexWrap: "wrap",
                              // overflowX: "auto"
                            }}
                          >
                            {/* <Typography  id='Typography-root' sx={{fontSize:'14px'}}> Assigned To <span>: {data?.Contact_Id?.Name}</span>
                              </Typography> */}
                            <Typography
                              id="Typography-root"
                              sx={{ fontSize: "14px" }}
                            >
                              Task Name <span>: {data?.Task}</span>
                            </Typography>
                            <Typography
                              id="Typography-root"
                              sx={{ fontSize: "14px" }}
                            >
                              Description{" "}
                              <span>:{htmlToText(data?.Task_Description)}</span>
                            </Typography>
                            {/* <Typography  id='Typography-root' >Attachements <span> : <span >{data.file} <DownloadIcon /></span></span></Typography> */}
                          </Card>
                          <Div sx={{ mt: -0.56, display: "flex" }}>
                            <IconButton
                              onClick={() => {
                                index !== height
                                  ? setHeight(index)
                                  : setHeight(null);
                              }}
                              sx={{ p: 0 }}
                            >
                              {height !== index ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropUpIcon />
                              )}
                            </IconButton>
                          </Div>
                        </Div>
                      </Div>
                    </>
                    {/* )} */}
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            );
          })}
        </JumboScrollbar>
      </Div>
    </>
  );
};

export default DealClosedActivity;
