import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TextField,
  Typography,
  Pagination,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import React, { useEffect, memo } from "react";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import GenerateLeads from "./GenerateLeads";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DisplayFlex,
  FontStyle,
  dialogTitle,
  Colors,
  deletedWarnigMsg,
} from "../widgets/CRMStyles";
import { CRMTableRow, CRMTableCell } from "../widgets/CRMTable";
import {
  Cancelbutton,
  DialogBox,
  CustomButton,
  DeletedIcon,
} from "../widgets/StylesComponents";
import { AutoHeight, UserId } from "app/redux/auth/localStorage";
import CustomTableHeader from "../widgets/CustomTableHeader";
import { useQuery } from "react-query";

const LeadList = ({ scrollHeight }) => {
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // For Asecnding Order

  const [showsort, setShowsort] = useState(true);

  const [avatarColors, setAvatarColors] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const matches = useMediaQuery("(min-width:759px)");

  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "LeadListId", label: "Lead List Id", bool: true },
    { sort: "ListName", label: "List Name", bool: true },
    { sort: "TotalLeads", label: "Total Leads", bool: true },
    { sort: "ListTeritorry", label: "List Teritorry", bool: true },
  ]);

  const sortData = (key, direction) => {
    const sortedData = [...datas];

    sortedData.sort((a, b) => {
      const valueA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
      const valueB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setDatas(sortedData);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
    setShowsort(false);
  };

  // For Settings Icon Function

  const [contactname, setContactName] = useState(true);
  const [phone, setPhone] = useState(true);
  const [email, setEmail] = useState(true);
  const [country, setCountry] = useState(true);
  const [city, setCity] = useState(true);
  const [companyname, setCompanyName] = useState(true);
  const [industrytype, setIndustryType] = useState(false);
  const [state, setState] = useState(false);
  const [status, setStatus] = useState(false);
  const [id, setId] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [save, setSave] = useState(false);
  const [deleteRow, setdeleteRow] = useState(false);
  const [daletingData, setDaletingData] = useState("");

  //  For Pagination Table
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visitedPages, setVisitedPages] = useState(page - 1);
  const rowsPerPageOptions = [5, 10, 15, 20, 25, 50];
  const totalRecords = datas.length;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getLeasListsRefetch(newPage);
  };

  // Generating Color

  const generateRandomColorFromList = (list) => {
    const randomIndex = Math.floor(Math.random() * list.length);
    return list[randomIndex];
  };

  const generateRandomAvatarColors = async (list, colorList) => {
    const colors = await Promise.all(
      list.map(() => generateRandomColorFromList(colorList))
    );
    return colors;
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAction = (event) => {
    setAnchorElAction(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAction = () => {
    setAnchorElAction(null);
  };

  // Getting Lead List API

  const token = localStorage.getItem("accesstoken");
  const Organization_Id = localStorage.getItem("OrganizationId");

  const item = "crmuseradmin1";

  const getData = async (page) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/UserLeadListAdditional/?Organization_Id=${Organization_Id}&Created_By=${UserId}&Is_Deleted=False&page=${page}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      // setDatas(response?.data?.results);
      // setCount(response.data?.count || 0);
      return {
        datas: response?.data?.results,
        count: response.data?.count || 0,
      };
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  //    For Creating Lead List
  const [listname, setListname] = useState("");
  const [geography, setGeography] = useState("");

  // Scrap from the Web
  const [openCreateList, setOpenCreateList] = useState(false);

  // Generate Leads

  const [generateleads, setGenerateLeads] = useState(false);
  const [leadlist, setLeadlist] = useState(true);

  const [location, setLocation] = useState("");
  const [industry, setIndustry] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState(1);

  //  handle Update

  const [updateid, setUpdateId] = useState("");

  const handleUpdate1 = (data) => {
    setUpdateId(data.id);
    console.log("check", data.id);
  };

  // Create Lead List
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let payload = {
        ListName: name,
        Location: location,
        Industry: industry,
        Organization_Id: localStorage.getItem("OrganizationId"),
        Created_By: localStorage.getItem("UserId"),
        Updated_By: localStorage.getItem("UserId"),
      };
      console.log("payload", payload);
      const response = await axios.post(
        `${BASE_URL}/user/LeadsGenrate/`,
        payload,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("res.data.results", response.data);
      toast.success("Created successfully", {
        position: "top-right",
        autoClose: 100,
      });
      setOpenCreateList(false);
      setSave(false);
      setGenerateLeads(false);
      setIsLoading(false);
      getLeasListsRefetch(page);
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // Delete List

  const Deletedata = async (data) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/user/UserLeadListCRUD/?id=${number}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      toast.success("Deleted successfully", {
        position: "top-right",
        autoClose: 100,
      });
      setDaletingData(false);
      setAnchorElAction(false);
      getLeasListsRefetch(page);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data.details || "You don't have permission to do it";
        console.log(errorMessage);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 100,
        });
      } else {
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 100,
        });
      }
    }
  };

  // For Lead List

  const [listdata, setListdata] = useState([]);

  const ListsData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/LeadAdditional/?Is_Deleted=False&Created_By=${UserId}`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("response.data.results", response.data.results);
      return response?.data?.results;
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const [lookupdata, setLookupdata] = useState([]);

  const getlookupData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/Userlookuplist/`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("check", response.data);
      console.log(
        "check1",
        response?.data?.find((op) => op?.Lookupname === "Industry Type")
      );
      // setLeadindustry(response?.data?.find((op) => op?.Lookupname === "Industry Type") || []);
      return response?.data;
    } catch (error) {
      console.error("An error occurred:", error.message);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  // Handle Assign
  const handelAssign = () => {
    setIsLoading(true);
    axios
      .get(
        `${BASE_URL}/user/LeadFromWebAssignmentForm/?Created_By=${localStorage.getItem(
          "UserId"
        )}`,
        {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success(`${res.data}`, {
          position: "top-right",
          autoClose: 100,
        });
        getLeasListsRefetch(page);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
        toast.error(`${err.message}`, {
          position: "top-right",
          autoClose: 100,
        });
      });
  };

  const Loader = () => (
    <div className="loader">
      <CircularProgress />
    </div>
  );

  // get Lists All Data
  const {
    data: getLeasLists,
    isLoading: getLeasListsLoading,
    isError: getLeasListsError,
    refetch: getLeasListsRefetch,
  } = useQuery(["getLeasLists"], () => getData(page), { staleTime: Infinity });

  const {
    data: getLeadAddition,
    isLoading: getLeadAdditionLoading,
    isError: getLeadAdditionError,
    refetch: getLeadAdditionRefetch,
  } = useQuery(["getLeadAddition"], ListsData, { staleTime: Infinity });

  const {
    data: getUserLoopUp,
    isLoading: getUserLoopUpLoading,
    isError: getUserLoopUpError,
    refetch: getUserLoopUpRefetch,
  } = useQuery(["getUserLoopUp"], getlookupData, { staleTime: Infinity });

  useEffect(() => {
    if (getLeasLists) {
      setDatas(getLeasLists?.datas);
      setCount(getLeasLists?.count);
    }
  }, [getLeasLists]);

  useEffect(() => {
    if (getLeadAddition) {
      setOpenCreateList(false);
      setListdata(getLeadAddition?.map((opt) => opt?.LeadIndustry));
    }
  }, [getLeadAddition]);

  useEffect(() => {
    if (getUserLoopUp) {
      setLookupdata(
        getUserLoopUp?.find((op) => op?.Lookupname === "Industry Type") || []
      );
    }
  }, [getUserLoopUp]);

  return (
    <>
      {/* Popup For the  Creating Generate Lead API */}

      <Dialog
        open={openCreateList}
        // onClose={() => setOpenCreateList(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={[dialogTitle, DisplayFlex, { justifyContent: "space-between" }]}
        >
          Create Lead List
          {/* <IconButton
            onClick={() => setSave(true)}
            sx={{ float: "right", mr: -2.5 }}
          >
            <CloseIcon size={22} color="gray" />
          </IconButton> */}
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <Div>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    List Name
                  </Typography>
                  <TextField
                    className={`col-12 input-box2`}
                    id="outlined-basic"
                    placeholder="Enter List Name"
                    variant="outlined"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "3px",
                      },
                    }}
                  />
                </Div>

                <Div sx={{ my: 1 }}>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    Geography
                  </Typography>
                  <TextField
                    className={`col-12 input-box2`}
                    id="outlined-basic"
                    placeholder="Geography"
                    variant="outlined"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "3px",
                      },
                    }}
                  />
                </Div>

                <Div>
                  <Typography id="Typography-root" sx={[FontStyle]}>
                    Industry type
                  </Typography>

                  <Autocomplete
                    getOptionLabel={(options) => {
                      return options["value"];
                    }}
                    getOptionValue={(options) => {
                      return options["value"];
                    }}
                    options={lookupdata?.Values}
                    onChange={(e, newValue) => {
                      console.log("lookupdata", lookupdata.Values);
                      console.log("newValue", newValue);
                      setIndustry(newValue?.value);
                    }}
                    className="search-select3"
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "5px",
                        }}
                        placeholder="Industry type"
                      />
                    )}
                  />
                </Div>

                <Grid container mt={2} align="center" spacing={2} pb={2}>
                  <Grid item align="right" xs={6}>
                    <CustomButton
                      Icon="saveIcon"
                      label="Generate"
                      type="submit"
                    />
                  </Grid>
                  <Grid item align="left" xs={6}>
                    <Cancelbutton
                      label="Cancel"
                      onClick={() => setSave(true)}
                    />
                  </Grid>
                </Grid>
                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.7)", // You can adjust the background color and opacity
                      zIndex: 1, // Ensure it's above the dialog
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </Box>
            </DialogContentText>

            {/* close dialog box */}

            <DialogBox
              open={save}
              onClickYes={() => {
                setSave(false);
                setOpenCreateList(false);
              }}
              onClickNo={() => {
                setSave(false);
              }}
            />
          </form>
        </DialogContent>
      </Dialog>

      {/* Popup For the Scrap the API */}

      <Div sx={{ display: leadlist === true ? "block" : "none" }}>
        <Div
          sx={{
            my: 2,
            display: "flex",
            justifyContent: { md: "right" },
            alignItems: "center",
          }}
        >
          <Div
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CustomButton
              Icon=""
              label="Assign Lead Owner"
              width="140px"
              onClick={handelAssign}
            />

            <CustomButton
              Icon="createIcon"
              width="110px"
              label="Create List"
              onClick={() => setOpenCreateList(true)}
            />
          </Div>
        </Div>

        <JumboScrollbar
          autoHeight={true}
          autoHideTimeout={4000}
          autoHeightMin={AutoHeight(1.6)}
          autoHide={true}
          hideTracksWhenNotNeeded
          id="no-more-tables"
        >
          <Table>
            <CustomTableHeader
              headerMenuItems={columnMenuItems}
              setHeadermenuItems={setColumnMenuItems}
              setDatas={setDatas}
              sortDatas={datas}
            />

            <TableBody sx={{ mt: 2 }}>
              {datas
                ?.slice(
                  page == 1 ? page * 0 : (page - 1) * 10,
                  page == 1 ? page * 0 + 10 : (page - 1) * 10 + 10
                )
                ?.map((data, index) => {
                  return (
                    <CRMTableRow>
                      {columnMenuItems[0]?.bool && (
                        <CRMTableCell
                          data-title="Lead List Id"
                          sx={{ cursor: "pointer" }}
                        >
                          {data.LeadListId}
                        </CRMTableCell>
                      )}
                      {columnMenuItems[0]?.bool && (
                        <CRMTableCell
                          onClick={() => {
                            handleUpdate1(data);
                            setGenerateLeads(true);
                            setLeadlist(false);
                          }}
                          data-title="List Name"
                          sx={{ alignItems: "center", color: Colors?.Mblue }}
                        >
                          {data.ListName}
                        </CRMTableCell>
                      )}
                      {columnMenuItems[0]?.bool && (
                        <CRMTableCell data-title="Total Leads">
                          {data.TotalLeads}
                        </CRMTableCell>
                      )}
                      {columnMenuItems[0]?.bool && (
                        <CRMTableCell data-title="List Teritorry">
                          {data.ListTeritorry}
                        </CRMTableCell>
                      )}

                      <CRMTableCell
                        align="center"
                        data-title="Action"
                        sx={[
                          DisplayFlex,
                          {
                            cursor: "pointer",
                            mb: 1,
                            justifyContent: matches ? "center" : "left",
                          },
                        ]}
                      >
                        <DeletedIcon
                          onClick={() => {
                            setDaletingData(data);
                            setNumber(data.id);
                            setdeleteRow(true);
                          }}
                        />
                      </CRMTableCell>
                    </CRMTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </JumboScrollbar>
        {/* code for pagination */}
        <Div
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "Bottom",
          }}
        >
          <Pagination
            color="success"
            count={Math.ceil(datas?.length / 10)}
            page={page}
            onChange={(e, newValue) => handleChangePage(e, newValue)}
            size="small"
            showFirstButton
            showLastButton
          />
        </Div>
      </Div>

      <>
        {/* {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // You can adjust the background color and opacity
              zIndex: 1, // Ensure it's above the dialog
            }}
          >
            <Loader />
          </div>
        )} */}
      </>

      {/* For Generate Leads */}

      <>
        <Div sx={{ display: generateleads === true ? "block" : "none" }}>
          <GenerateLeads
            setLeadlist={setLeadlist}
            setGenerateLeads={setGenerateLeads}
            updateid={updateid}
          />
        </Div>
      </>
      {/* delete dialog box */}
      <DialogBox
        open={deleteRow}
        Content={deletedWarnigMsg}
        onClickYes={() => {
          setdeleteRow(false);
          Deletedata(daletingData);
        }}
        onClickNo={() => {
          setdeleteRow(false);
        }}
      />
    </>
  );
};

export default memo(LeadList);
