import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { useQuery } from "react-query";
import CircularProgress from "@mui/material/CircularProgress"; // Loading indicator
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import Div from "@jumbo/shared/Div";
import { NoRecordsPage } from "app/pages/widgets/StylesComponents";
import { DisplayFlex } from "app/pages/widgets/CRMStyles";
import { Visibility } from "@mui/icons-material";

// Custom hook for fetching data
const usePyramidData = () => {
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const getDatas = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/Dealdashboard/`, {
        headers: {
          db: item,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response?.data) {
        let data = [];
        Object.keys(response.data).forEach((key) => {
          let value = key?.split("_");
          data.push({
            name: `${value[0]} ${value[1]}`,
            value: response.data[key],
          });
        });
        return data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return []; // Return empty array on error
    }
  };

  return useQuery(["getPyramidLists"], getDatas, { staleTime: Infinity });
};

function PyramidDeals() {
  const { data: getPyramidLists, isLoading, isError } = usePyramidData();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (getPyramidLists) {
      console.log("Fetched data:", getPyramidLists); // Debugging line
      setData(getPyramidLists || []);
    }
  }, [getPyramidLists]);

  if (isLoading) {
    return (
      <Div
        sx={[
          DisplayFlex,
          { justifyContent: "center", alignItems: "center", height: "100%" },
        ]}
      >
        <CircularProgress color="primary" />
      </Div>
    );
  }

  if (isError) {
    return (
      <Div
        sx={[
          DisplayFlex,
          { justifyContent: "center", alignItems: "center", height: "100%" },
        ]}
      >
        <NoRecordsPage title={"Error loading data"} />
      </Div>
    );
  }

  if (data.length === 0) {
    return (
      <Div
        sx={[
          DisplayFlex,
          { justifyContent: "center", alignItems: "center", height: "100%" },
        ]}
      >
        <NoRecordsPage title={"No Data Available"} />
      </Div>
    );
  }

  return (
    <Div sx={{ width: "100%", height: "100%" }}>
      <Div style={{ width: "100%", height: "100%", position: "relative" }}>
        <FunnelChart
          data={data}
          colors={["#d1e0e0", "#a1c4c9", "#7f9a9f", "#4d6a6d", "#2e3c3d"]}
          label={({ name, value }) => `${name}: ${value}`}
          style={{ height: "378px", overflow: "scroll ", Visibility: "hidden" }}
        />
      </Div>
    </Div>
  );
}

export default PyramidDeals;
