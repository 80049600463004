import React from 'react'
import "./insta.css"
import CreateIcon from '@mui/icons-material/Create';
import InputOptions from './InputOptions';
import ImageIcon from '@mui/icons-material/Image';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { Button, IconButton } from '@mui/material';
import { useRef } from 'react';
import { BASE_URL } from 'app/services/auth-services';
import { toast } from 'react-toastify';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PostDialog from './PostDialog';



const Instagram = ({ usrInfo }) => {

    const [postDialogOpen, setPostDialogOpen] = useState(false)
    const [isArticleOpen, setIsArticleOpen] = useState(false)
    const [isContactFormOpen, setIsContactFormOpen] = useState(false)
    const formRef = useRef(null)
    const token = localStorage.getItem("accessToken")



    const [userInfo, setUserInfo] = useState(usrInfo)

    useEffect(() => {
        setUserInfo(usrInfo)
    }, [usrInfo])



 

  

    return (
        <div style={{ backgroundColor: '#dbdbdb' }}>
            <div className='feed'>
                <div className='feed_inputContainer'>
                    <div className='feed_input'>
                        <IconButton><CreateIcon /></IconButton>
                        <form >

                            <input type='text' name='postText' placeholder='Start a Post' />
                            <Button  className='dialog-save-btn' variant='contained' onClick={() => { setPostDialogOpen(true) }} >Post</Button>
                        </form>
                    </div>
                    <div className='feed_inputOptions'>
                        {/* <span onClick={() => { setPostDialogOpen(true) }} ><InputOptions Icon={ImageIcon} title='Photo' color='#70B5F9' /></span> */}

                        {/* <InputOption Icon={SubscriptionsIcon} title='Video' color='#E7A33E' /> */}
                        {/* <InputOption Icon={EventNoteIcon} title='Event' color='#COCBCD' /> */}
                   
                    </div>
                </div>
            </div>
            {postDialogOpen && <PostDialog postDialogOpen={postDialogOpen} setPostDialogOpen={setPostDialogOpen}  />}
        </div>

    )
}

export default Instagram