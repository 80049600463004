// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Move the arrow icon to the left */
.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 12px;
}
.ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 34px !important;
}

.ant-menu-submenu-title {
  padding-left: 28px;
}

.ant-menu-item .ant-menu-item-icon {
  margin-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/thirdPartyAPI/OutlookAPI/Components/Utils/Sidebarmenu/sidebarmenu.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["/* Move the arrow icon to the left */\n.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {\n  right: auto;\n  left: 12px;\n}\n.ant-menu-submenu > .ant-menu-submenu-title {\n  padding-left: 34px !important;\n}\n\n.ant-menu-submenu-title {\n  padding-left: 28px;\n}\n\n.ant-menu-item .ant-menu-item-icon {\n  margin-right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
