import Div from "@jumbo/shared/Div/Div";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import { CloseIcon } from "app/icons/PngIcons";
import React, { useState, useEffect, useRef } from "react";
import { FontStyle, dialogTitle } from "../widgets/CRMStyles";
import { AvatarCircle } from "../widgets/StylesComponents";
import { MdNotificationsActive } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    minWidth: "300px", // Custom width
    minHeight: "100px", // Custom height
    backgroundColor: "white",
    color: "black",
  },
  header: {
    padding: "8px",
    width: "300px",
    borderBottom: "1px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // gap: "290px",
  },
  headerLeft: {
    width: "300px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  main: {
    padding: "20px",
  },
}));

function TransitionLeft(props) {
  return (
    <Slide
      {...props}
      direction="left"
      timeout={{ enter: 5000, exit: 5000 }} // Adjust transition duration
      style={{
        transform: "translateX(-2000px)",
      }} // Adjust enter distance
    />
  );
}

const CustomNotification = ({
  // reminder,
  // setReminder,
  messages,
  onClose,
  expanded,
}) => {
  console.log("remain", messages);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);

  // Load sound file

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // <div
    //   style={{
    //     position: "fixed",
    //     bottom: `20px`,
    //     right: "20px",
    //     backgroundColor: "#ffffff",
    //     border: "1px solid #3c7c33",
    //     padding: "10px",
    //     borderRadius: "4px",
    //     boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    //     cursor: "pointer",
    //   }}
    // >

    //   <>
    //     <Box className="rounded-[10px]" sx={{ width: "300px" }}>
    //       <header className="p-1 border-b-[1px] border-[black] flex items-center justify-between gap-2">
    //         <div className="flex items-center gap-2">
    //           <MdNotificationsActive style={{ fontSize: 23 }} />
    //           <Typography sx={[dialogTitle]}>{messages?.TaskName}</Typography>
    //         </div>
    //         <IconButton>
    //           <IoCloseOutline
    //             style={{ fontSize: 18, color: "black" }}
    //             onClick={onClose}
    //           />
    //         </IconButton>
    //       </header>

    //       <main className="p-1">
    //         <Typography sx={{ fontSize: "16px", fontWeight: 600 }}></Typography>
    //         <Typography sx={[FontStyle]}>{messages?.message}</Typography>
    //       </main>
    //     </Box>
    //   </>

    // </div>
    <div>
      <Snackbar
        open={expanded}
        TransitionComponent={TransitionLeft}
        key={transition ? transition.name : ""}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // sx={{ position: "relative" }}
      >
        <SnackbarContent
          className={classes.snackbar}
          message={
            <div>
              <header className={classes.header}>
                <div className={classes.headerLeft}>
                  <MdNotificationsActive style={{ fontSize: 23 }} />
                  <Typography sx={{ fontWeight: 600, width: "200px" }}>
                    {messages?.TaskName}
                  </Typography>
                </div>
                <IconButton size="small" onClick={onClose}>
                  <IoCloseOutline style={{ fontSize: 18, color: "black" }} />
                </IconButton>
              </header>
              <main className={classes.main}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600 }}
                ></Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {messages?.message}
                </Typography>
              </main>
            </div>
          }
        />
      </Snackbar>
    </div>
  );
};

export default CustomNotification;
