import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain/JumboContentLayoutMain";

import {
  Autocomplete,
  Avatar,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { ToastContainer, toast } from "react-toastify";
import Div from "@jumbo/shared/Div/Div";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {
  AvatarFont,
  Colors,
  ComponentHeading,
  DialogHeader,
  DisplayFlex,
  FontStyle,
  avatarLightColors,
} from "app/pages/widgets/CRMStyles";
import SearchBar from "app/pages/widgets/SearchField";
import { CRMTableCell, CRMTableRow } from "app/pages/widgets/CRMTable";
import { MailIcon } from "app/icons/PngIcons";

import CustomTableHeader from "app/pages/widgets/CustomTableHeader";
import {
  Cancelbutton,
  DialogBox,
  CreateButton,
  DeletedIcon,
  EditIcon,
  CustomButton,
} from "app/pages/widgets/StylesComponents";
import { IoMdClose } from "react-icons/io";

const SalesPersonTarget = () => {
  const [columnMenuItems, setColumnMenuItems] = useState([
    { sort: "Caller_Name", label: "Caller Name", bool: true },
    { sort: "Month", label: "Month", bool: true },
    { sort: "Weekly_Call_Target", label: "Weekly Call Target", bool: true },
    { sort: "Weekly_Visit_Target", label: "Weekly Visit Target", bool: true },
  ]);
  const matches = useMediaQuery("(min-width:759px)");

  const [showDelete, setShowDelete] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogdelete, setShowDialogdelete] = useState();
  const [errors, setErrors] = useState({});
  const [save, setSave] = useState(false);
  const [userList, setUsersList] = useState([]);
  const [addField, setAddField] = useState({
    User_Name: "",
    Weekly_Call_Target: "",
    Weekly_Visit_Target: "",
    Month: "",
    userid: "",
  });

  const [Overviewnotification, setShowOverviewnotification] = useState(null);
  const [overviewdatas, setoverviewdatas] = useState([]);
  const [notificationdate, setnotificationdate] = useState("");
  const [notificationtime, setnotificationtime] = useState("");

  const [page, setPage] = useState(1);

  const [datas, setDatas] = useState([]);
  const [notilength, setnotilength] = useState("0");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //overview Notification

  const handleClickOverviewNotification = (event, data) => {
    const formattedDate = new Date(
      data?.Notification_Time
    ).toLocaleDateString();
    const formattedTime = new Date(
      data?.Notification_Time
    ).toLocaleTimeString();
    setnotificationdate(formattedDate);
    setnotificationtime(formattedTime);
    setShowOverviewnotification(event.currentTarget);
  };

  const handleCloseOverviewNotification = () => {
    setnotificationdate("");
    setnotificationtime("");
    setoverviewdatas([]);
    setShowOverviewnotification(false);
  };

  const results = [
    {
      id: 25,
      Month: "no month",
      Weekly_Call_Target: "16",
      Weekly_Visit_Target: "8",
      Caller_Name: "RaviRaj",
      Created_Date: "2023-11-29",
      Updated_Date: "2023-11-30",
      Created_By: 4,
      Updated_By: 4,
    },
  ];

  // get products
  const token = localStorage.getItem("accesstoken");
  const item = "crmuseradmin1";

  const getDatas = () => {
    axios
      .get(`${BASE_URL}/useradmin/CallcenterTargetListAdditional/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          Db: localStorage.getItem("DBName"),
        },
      })
      .then((response) => {
        // console.log("Notificationlist", response.data);
        // setnotilength(response.data?.length);
        setDatas(response.data?.results);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  //   get users list
  // get users datas
  const getUserData = async () => {
    try {
      await axios
        .get(`${BASE_URL}/useradmin/UsersList/`, {
          headers: {
            db: item,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("userDatas", res?.data);
          setUsersList(res?.data || []);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const CancelFun = () => {
    setAddField({
      User_Name: "",
      Weekly_Call_Target: "",
      Weekly_Visit_Target: "",
      Month: "",
      userid: "",
    });
    setShowDialog(false);
  };

  const onSubmit = async () => {
    if (showDialog?.type == "edit") {
      if (
        addField?.Month === "" ||
        addField?.Month === null ||
        addField?.Month === undefined ||
        addField?.Weekly_Call_Target === "" ||
        addField?.Weekly_Call_Target === null ||
        addField?.Weekly_Call_Target === undefined ||
        addField?.Weekly_Visit_Target === "" ||
        addField?.Weekly_Visit_Target === null ||
        addField?.Weekly_Visit_Target === undefined ||
        addField?.User_Name === "" ||
        addField?.User_Name === null ||
        addField?.User_Name === undefined
      ) {
        toast.error("Please fill the required fields");
      } else {
        let payload = {
          id: addField?.id,
          Month: addField?.Month,
          Weekly_Call_Target: addField?.Weekly_Call_Target,
          Weekly_Visit_Target: addField?.Weekly_Visit_Target,
          Caller_Name: addField.User_Name,
          UserId: addField?.userid,
          Updated_By: localStorage.getItem("UserId"),
          Created_By: localStorage.getItem("UserId"),
        };

        try {
          const res = await axios.put(
            `${BASE_URL}/useradmin/CallcenterTargetListscrud/`,
            payload,
            {
              headers: {
                db: "crmuseradmin1",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          //  console.log("res", res);
          getDatas();
          setAddField({
            User_Name: "",
            Weekly_Call_Target: "",
            Weekly_Visit_Target: "",
            Month: "",
            userid: "",
          });
          setShowDialog(false);
        } catch (error) {
          console.error("Error creating template:", error);
        }
      }
    } else if (showDialog?.type == "create") {
      if (
        addField?.Month === "" ||
        addField?.Month === null ||
        addField?.Month === undefined ||
        addField?.Weekly_Call_Target === "" ||
        addField?.Weekly_Call_Target === null ||
        addField?.Weekly_Call_Target === undefined ||
        addField?.Weekly_Visit_Target === "" ||
        addField?.Weekly_Visit_Target === null ||
        addField?.Weekly_Visit_Target === undefined ||
        addField?.User_Name === "" ||
        addField?.User_Name === null ||
        addField?.User_Name === undefined
      ) {
        toast.error("Please fill the required fields");
      } else {
        let payload = {
          Month: addField?.Month,
          Weekly_Call_Target: addField?.Weekly_Call_Target,
          Weekly_Visit_Target: addField?.Weekly_Visit_Target,
          Caller_Name: addField.User_Name,
          UserId: addField?.userid,
          Updated_By: localStorage.getItem("UserId"),
          Created_By: localStorage.getItem("UserId"),
        };

        try {
          const res = await axios.post(
            `${BASE_URL}/useradmin/CallcenterTargetListscrud/`,
            payload,
            {
              headers: {
                db: "crmuseradmin1",
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          //  console.log("res", res);

          getDatas();
          setAddField({
            User_Name: "",
            Weekly_Call_Target: "",
            Weekly_Visit_Target: "",
            Month: "",
            userid: "",
          });
          setShowDialog(false);
        } catch (error) {
          console.error("Error creating template:", error);
        }
      }
    }
  };

  const handledelete = async () => {
    console.log("pra", showDialogdelete);
    await axios
      .delete(
        `${BASE_URL}/useradmin/CallcenterTargetListscrud/?id=${showDialogdelete}`,
        {
          headers: {
            db: "crmuseradmin1",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setShowDelete(false);
        getDatas();
        toast.success(`Deleted successfully`);
      })
      .catch((error) => {
        console.log("hlo", error);
      });
  };

  useEffect(() => {
    getUserData();
    getDatas();
  }, []);

  return (
    <>
      <ToastContainer />
      <JumboContentLayoutMain>
        <Card>
          <Div
            sx={[
              DisplayFlex,
              {
                display: matches ? "flex !important " : "block !important",
                justifyContent: "space-between",
              },
            ]}
          >
            <Div sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
              <Typography sx={[ComponentHeading, {}]}>
                Sales Person Target
              </Typography>
            </Div>

            <Div sx={[DisplayFlex, { gap: "10px !important" }]}>
              <SearchBar
                color="gray"
                border={"1px solid lightgray"}
                width={"250px !important"}
              />
              <CreateButton onClick={() => setShowDialog({ type: "create" })} />
            </Div>
          </Div>

          <div style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
            <TableContainer sx={{ my: 1 }}>
              <JumboScrollbar
                autoHeight={true}
                autoHideTimeout={4000}
                autoHeightMin={"65vh"}
                autoHide={true}
                hideTracksWhenNotNeeded
                id="no-more-tables"
              >
                <Table className="table" sx={{ mt: 0 }}>
                  <CustomTableHeader
                    headerMenuItems={columnMenuItems}
                    setHeadermenuItems={setColumnMenuItems}
                    setDatas={setDatas}
                    sortDatas={datas}
                  />
                  <TableBody className="table-body" sx={{ mt: 2 }}>
                    {datas.length > 0 &&
                      datas
                        // ?.slice(page == 1? page * 0 : (page-1) * count, page == 1? page * 0 + count : (page-1) * count + count)
                        ?.map((data, index) => {
                          let avatar =
                            index >= 10
                              ? avatarLightColors[index % 10]
                              : avatarLightColors[index];

                          return (
                            <CRMTableRow>
                              {columnMenuItems[0]?.bool && (
                                <CRMTableCell
                                  data-title="Caller Name"
                                  sx={[
                                    DisplayFlex,
                                    {
                                      cursor: "pointer",
                                      maxWidth: "280px",
                                      color: `${Colors?.blue} !important`,
                                    },
                                  ]}
                                >
                                  <Avatar
                                    size="small"
                                    sx={[
                                      AvatarFont,
                                      {
                                        bgcolor: avatar,
                                        color: "black",
                                        width: 23,
                                        height: 23,
                                        display: {
                                          xs: "none !important",
                                          md: "flex !important",
                                          lg: "flex !important",
                                        },
                                      },
                                    ]}
                                  >
                                    {data &&
                                      data?.Caller_Name?.substring(
                                        0,
                                        2
                                      ).toUpperCase()}
                                  </Avatar>
                                  {data?.Caller_Name}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[1].bool && (
                                <CRMTableCell data-title="Notification Id">
                                  {data?.Month}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[2].bool && (
                                <CRMTableCell data-title="Notification Msg">
                                  {data?.Weekly_Call_Target}
                                </CRMTableCell>
                              )}
                              {columnMenuItems[3].bool && (
                                <CRMTableCell data-title="Notification Source">
                                  {data?.Weekly_Visit_Target}
                                </CRMTableCell>
                              )}

                              <CRMTableCell
                                data-title="Action"
                                sx={{
                                  cursor: "pointer",
                                  textAlign: matches ? "center" : "left",
                                }}
                              >
                                <EditIcon
                                  onClick={() => {
                                    setAddField({
                                      ...addField,
                                      Month: data?.Month,
                                      Weekly_Call_Target:
                                        data?.Weekly_Call_Target,
                                      Weekly_Visit_Target:
                                        data?.Weekly_Visit_Target,
                                      User_Name: data?.Caller_Name,
                                      userid: data?.UserId,
                                      id: data?.id,

                                      User: {
                                        username: data?.Caller_Name,
                                        id: data?.id,
                                      },
                                    });
                                    console.log("addField", addField);
                                    setShowDialog({ type: "edit" });
                                  }}
                                />
                                <DeletedIcon
                                  onClick={() => {
                                    setShowDialogdelete(data?.id);
                                    setShowDelete(true);
                                  }}
                                />
                              </CRMTableCell>
                            </CRMTableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </JumboScrollbar>

              {/* close dialog */}
              <DialogBox
                onClickNo={() => setShowDelete(false)}
                onClickYes={() => {
                  handledelete();
                }}
                open={showDelete}
              />
            </TableContainer>
          </div>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            color="primary"
            count={datas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}

          {/* code for pagination */}
          <Div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Pagination
              color="success"
              count={Math?.ceil(datas.length / 10)}
              page={page}
              onChange={(e, newValue) => handleChangePage(e, newValue)}
              size="small"
              showFirstButton
              showLastButton
            />
          </Div>
        </Card>

        {/* Create And Edit */}
        <Dialog
          open={showDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={[
              DialogHeader,
              DisplayFlex,
              {
                bgcolor: "#dcdcdc !important",
                justifyContent: "space-between",
              },
            ]}
          >
            {showDialog?.type == "edit"
              ? "Edit Sales Person Targets"
              : "Create Sales Person Targets"}
            {/* <IconButton
              onClick={() => {
                handleCloseOverviewNotification();
              }}
              sx={{ padding: "5px", mr: -3 }}
            >
              <IoMdClose
                style={{
                  fontSize: 25,
                  fontWeight: 900,
                  color: "black",
                }}
              />
            </IconButton> */}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid container gap={1} sx={{ mt: 3 }}>
                <Grid item xs={12} md={12} className="row">
                  <Typography
                    sx={[FontStyle]}
                    className="input-label col-sm-12 col-md-4"
                  >
                    User Name <span className="required"> * </span>
                  </Typography>
                  <Autocomplete
                    className="search-select col-md-8"
                    // {...register("selectedCountry1")}
                    options={userList || []}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.username) {
                        return option?.username;
                      }
                      return "";
                    }}
                    value={addField?.User_Name}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        userid: newValue?.id,
                        User_Name: newValue?.username,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="User Name" />
                    )}
                  />

                  {/* {errors?.dealName && (
                    <Typography sx={[FontStyle, { color: "red !important" }]}>
                      {errors?.dealName} deal name{" "}
                    </Typography>
                  )} */}
                </Grid>

                <Grid item xs={12} md={12} className="row">
                  <Typography
                    sx={[FontStyle]}
                    className="input-label col-sm-12 col-md-4"
                  >
                    Month <span className="required"> * </span>
                  </Typography>

                  <Autocomplete
                    className="search-select col-md-8"
                    // {...register("selectedCountry1")}
                    options={[
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ]}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option) {
                        return option;
                      }
                      return "";
                    }}
                    value={addField?.Month}
                    onChange={(e, newValue) => {
                      setAddField({
                        ...addField,
                        Month: newValue,
                      });
                    }}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Month" />
                    )}
                  />
                  {/* {errors?.dealName && (
                    <Typography sx={[FontStyle, { color: "red !important" }]}>
                      {errors?.dealName} deal name{" "}
                    </Typography>
                  )} */}
                </Grid>

                <Grid item xs={12} md={12} className="row">
                  <Typography
                    sx={[FontStyle]}
                    className="input-label col-sm-12 col-md-4"
                  >
                    Weekly Call Target <span className="required"> * </span>
                  </Typography>
                  <TextField
                    className={` col-md-8 input-box`}
                    // sx={{px:1}}

                    id="outlined-basic"
                    placeholder="Enter Weekly Call Target"
                    value={addField.Weekly_Call_Target}
                    onChange={(e) =>
                      setAddField({
                        ...addField,
                        Weekly_Call_Target: e.target.value,
                      })
                    }
                    InputProps={{
                      style: {
                        borderRadius: "3px",
                        width: "325px",
                        marginLeft: "10px",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} className="row">
                  <Typography
                    sx={[FontStyle]}
                    className="input-label col-sm-12 col-md-4"
                  >
                    Weekly Visit Target <span className="required"> * </span>
                  </Typography>
                  <TextField
                    className={` col-md-8 input-box`}
                    id="outlined-basic"
                    // sx={{px:1}}

                    placeholder="Enter Weekly Visit Target"
                    value={addField.Weekly_Visit_Target}
                    onChange={(e) =>
                      setAddField({
                        ...addField,
                        Weekly_Visit_Target: e.target.value,
                      })
                    }
                    InputProps={{
                      style: {
                        borderRadius: "3px",
                        width: "325px",
                        marginLeft: "10px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
            <DialogActions>
              <Div sx={[DisplayFlex, { justifyContent: "center", mt: 2 }]}>
                <CustomButton
                  label={showDialog?.type == "edit" ? "Update" : "Save"}
                  type="submit"
                  Icon="saveIcon"
                  onClick={showDialog?.type == "edit" ? onSubmit : onSubmit}
                />

                <Cancelbutton
                  onClick={() => {
                    setSave(true);
                    // console.log("addfield",addField);
                  }}
                />
              </Div>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {/* cancel */}
        <DialogBox
          onClickNo={() => setSave(false)}
          onClickYes={() => {
            CancelFun();
            setSave(false);
          }}
          open={save}
        />
      </JumboContentLayoutMain>
    </>
  );
};

export default SalesPersonTarget;
