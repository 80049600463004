import "./outlook.css";
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import { EmailsProvider } from "./Context/EmailsContext";
import EmailsContainer from "./containers/EmailsContainer/EmailsContainer";
// import { data } from "./data/data";

function OutlookApp() {
  return (
    <EmailsProvider>
      <div className="outlookapp">
        <div>
          <Navbar />
          <main className="main-container">
            <Sidebar />
            <EmailsContainer />
          </main>
        </div>
      </div>
    </EmailsProvider>
  );
}

export default OutlookApp;
