import Div from '@jumbo/shared/Div'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { BASE_URL } from 'app/services/auth-services';
import { toast } from 'react-toastify';


const VideoDialog = ({ isVideoDialogOpen, setIsVideoDialogOpen, pageAccessToken }) => {

    const [open, setOpen] = useState(isVideoDialogOpen)
    const fileInputRef = useRef(null)
    const [videoPreview, setVideoPreview] = useState('')
    const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false)
    const [filePath, setFilePath] = useState('')
    const [isVideoUploaded, setIsVideoUploaded] = useState(false)
    const [isPosting, setIsPosting] = useState(false);
    const [token, setToken] = useState(pageAccessToken)

    useEffect(() => {
        setToken(pageAccessToken)
    }, [pageAccessToken])


    const handleClose = () => {
        setIsVideoDialogOpen(false)
    }

    const handleCloseImagePreview = () => {
        setIsVideoPreviewOpen(false);
        setVideoPreview(null);
    }

    const handleFileUpload = async (videoURL, fileName) => {
        let token = localStorage.getItem("accesstoken")
        const uniqueFileName = `${fileName}-${Math.random().toString(36).substring(2, 8)}`;

        const payload = {
            "FileName": uniqueFileName,
            "File": videoURL,
            "Organization_Id": localStorage.getItem("OrganizationId"),
            "Urls": "",
            "Description": "Test File",
            "Created_By": localStorage.getItem("OrganizationId"),
            "Updated_By": localStorage.getItem("OrganizationId")
        }
        const headers = {
            "db": localStorage.getItem('DBName'),
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json"
        }
        await axios.post(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, payload, {
            headers: headers
        }).then((res) => {
            getFileUrlByFileName(res.data?.FileName)
            console.log("Video upload response", res)
        }).catch((error) => {
            console.error("Video upload error", error)
        })

    }

    const getFileUrlByFileName = (fileName) => {
        console.log("get file clicked")
        let token = localStorage.getItem("accesstoken")

        const headers = {
            "db": localStorage.getItem('DBName'),
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json"
        }

        axios.get(`${BASE_URL}/user/UserFilesandUrlsCRUD/`, {
            params: {
                FileName: fileName
            },
            headers: headers
        }).then((res) => {
            console.log("get file", res)
            if (res?.status === 200) {
                setFilePath(res.data?.File)

            }
        }).catch((error) => {
            console.error("get file error", error)
        })
    }

    const handleFileChange = (e) => {

        const file = e.target.files[0];
        console.log("fileee", file)
        if (file) {
            const reader = new FileReader();
            console.log("readerrr", reader)
            reader.onloadend = () => {
                const base64 = reader.result.split(',')[1];
                console.log("base64", base64)
                handleFileUpload(base64, file?.name)
            };
            reader.readAsDataURL(file);
        }
        const previewURL = URL.createObjectURL(file);
        console.log("previewURL", previewURL)
        setVideoPreview(previewURL)
        setIsVideoPreviewOpen(true)
    }



    const handlePost = () => {
        setIsPosting(true)
        // let token = "EAADn3m1nbtgBO8NI9NUgh7eP1zi8KbjDNa60aVFZAX7rlaZAUE6jbiaobPC00BGH5JcGanjv6zEJGhZBcrM2xOdF0wrnDbUJLcVRL162GquKVIeeMPrCmxNkoSpzjWpe6LQq0ZCUbrQ3ZArSUEIEXfAKMwTd2GEsbgw2iSIMIeeMIzqvrXr4n4hJpBrZCp8mx6bnNEswanc7ZCke5McDCVoMZA5G10n9QnkZD"
        const Headers = {
            "Content-Type": "multipart/form-data"
        }
        const formData = new FormData()
        formData.append("access_token", pageAccessToken)
        formData.append("file_url", `${BASE_URL}${filePath}`)

        axios.post('https://graph-video.facebook.com/v18.0/251682828032268/videos', formData, { headers: Headers })
            .then((res) => {
                console.log("video post response", res)
                if (res?.status == 200) {
                    setIsVideoUploaded(true)
                    toast.success("Posted Successfully")
                    handleClose()
                }
            })
            .catch((error) => {
                console.error("Video post error response", error)
            }).finally(() => {
                setIsPosting(false)
            })
    }



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "500px", minHeight: '300px' } }}
        >
            <DialogTitle className='dialog-head' >Post Video</DialogTitle>
            <DialogContent sx={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
                <Div sx={{ border: `${videoPreview ? 'none' : '1px solid gray'}`, width: '50%' }}>
                    <Div sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer', position: 'relative' }}>
                        {/* {videoPreview ? <img src={videoPreview} alt='Image' style={{ width: '60%' }}></img> : <AddBoxIcon onClick={() => { fileInputRef.current.click() }} />} */}

                        {videoPreview ?
                            <video width="100%" height="100%" controls>
                                <source src={videoPreview} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> :
                            <AddBoxIcon onClick={() => { fileInputRef.current.click() }} />
                        }


                        {isVideoPreviewOpen &&
                            <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleCloseImagePreview}>
                                <CancelIcon />
                            </IconButton>}
                    </Div>
                </Div>
                <Input
                    type="file"
                    inputRef={fileInputRef}
                    sx={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="video/*"
                />
            </DialogContent >
            {!isVideoUploaded && isPosting ?
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                :
                ''
            }
            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button className='dialog-close-btn' onClick={handleClose}>Close</Button>
                <Button className='dialog-save-btn' onClick={handlePost} >Post</Button>
            </DialogActions>
        </Dialog >
    )
}

export default VideoDialog